import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert
} from "react-bootstrap";

import Card from "components/Card/LoginCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum, SoftwareTypeEnum } from "utils/constant.js";
import NetworkService from "services/NetworkService";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      errorCode: 1,
      ShowProgressBar: false,
      email: "",
      password: "",
      confirmPassword: "",
      emailState: "",
      passwordState: "",
      confirmPasswordState: "",
      first_name: "",
      last_name: "",
      phonenumber: "",
      phonenumberState: "",
      phonenumberErrorMessage: "",
      firstNameState: "",
      firstNameErrorMessage: "",
      lastNameState: "",
      lastNameErrorMessage: "",
      emailErrorMessage: null,
      passwordErrorMessage: null,
      IsForgotPassword: false,
      IsSingupNow: false,
      IsForgotPasswordLinkSent: false,
      isPasswordFormValid: false,
      title: "Customer - Sign In",
      category: "Sign in with your Instant Invoice Account ",
      invoiceToken: "",

    };
  }

  linkClick(e, name) {
    if (name == "ForgotPassword") {
      this.setState({ title: "Forgot Password" });
      this.setState({ category: "Enter email to recover your password" });
      this.setState({ IsForgotPassword: true });
      this.setState({ IsSingupNow: false });
    }
    else if (name == "SingupNow") {
      this.setState({ title: "Create Account" });
      this.setState({ category: "Register for free and manage your Invoices and Payments" });
      this.setState({ IsSingupNow: true });
      this.setState({ IsForgotPassword: false });
    }
    else {
      this.setState({ IsSingupNow: false });
      this.setState({ IsForgotPassword: false });
      this.setState({ title: "Customer - Sign In" });
      this.setState({ category: "Sign in with your Instant Invoice Account " });
    }
    this.setState({ email: "" });
    this.setState({ password: "" });
    this.setState({ confirmPassword: "" });
    this.setState({ first_name: "" });
    this.setState({ last_name: "" });
    this.setState({ phonenumber: "" });
    this.setState({ firstNameState: "" });
    this.setState({ lastNameState: "" });
    this.setState({ emailState: "" });
    this.setState({ passwordState: "" });
    this.setState({ phonenumberState: "" });
    this.setState({ confirmPasswordState: "" });
    this.setState({ emailErrorMessage: null });
    this.setState({ passwordErrorMessage: null });
    this.setState({ phonenumberErrorMessage: null });
    this.setState({ firstNameErrorMessage: null });
    e.preventDefault();
  }

  forgotPasswordSubmitClick = (event) => {
    if (this.isValidated()) {
      this.setState({ ShowProgressBar: true });
      localStorage.clear();
      this._postForgotPassword({ "email": this.state.email, "invoice_token": this.state.invoiceToken });
    }
  }

  createAccountSubmitClick = (event) => {
    if (this.isValidated()) {
      localStorage.clear();
      console.log('Ente valid');
      const payload = {
        "ref_id": this.state.invoiceToken,
        "first_name": this.state.first_name,
        "last_name": this.state.last_name,
        "phone_number": this.state.phonenumber,
        "email": this.state.email,
        "password": this.state.password,
        "confirm_password": this.state.confirmPassword,
      }

      this._postSignupRequest(payload);
    }
  }

  componentDidMount() {
    document.getElementById("loaderDiv").style.display = "none";
    let emailAddres = localStorage.getItem("CustomerLoginEmail");
    if(emailAddres != null && emailAddres != "" && emailAddres != undefined){
      this.setState({
        email: emailAddres,
        emailErrorMessage: null,
        emailState: "valid"
      });
    }
    localStorage.clear();
    this.state.invoiceToken = this.extractTokenFromUrl();
  }

  extractTokenFromUrl = () => {
    const url = window.location.href;
    const path = url.split("=");
    const token = (path[1] || "");
    return token;
  };

  handleLoginEmail(event) {
    this.setState({
      email: event.target.value
    });
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(event.target.value) === false) {
      this.setState({
        emailErrorMessage: (
          <small className="text-danger">
            Email is required and format should be <i>john@doe.com</i>.
          </small>
        )
      });
      this.setState({
        emailState: "error"
      });
    }
    else {
      this.setState({ emailErrorMessage: null });
      this.setState({ emailState: "valid" });
    }
  }

  handleLoginPassword(event) {
    this.setState({
      password: event.target.value
    });
    if (event.target.value.length < 6) {
      this.setState({
        passwordErrorMessage: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
          </small>
        )
      });
      this.setState({ passwordState: "error" });
    }
    else {
      this.setState({ passwordErrorMessage: null });
      this.setState({ passwordState: "valid" });

    }
  }

  ValidatePasswords() {
    if (this.state.passwordState === "" || this.state.passwordState === "error") {
      this.setState({
        passwordErrorMessage: (
          <small className="text-danger">
            password is required.
          </small>
        )
      });
      this.state.isPasswordFormValid = false;
    }
    else {
      this.setState({ passwordErrorMessage: null });
      this.state.isPasswordFormValid = true;
    }

    if (this.state.confirmPasswordState === "" || this.state.confirmPasswordState === "error") {
      this.setState({
        confirmPasswordErrorMessage: (
          <small className="text-danger">
            Confirm Password is Required.
          </small>
        )
      });
      this.state.isPasswordFormValid = false;
    }
    else {
      if (this.state.passwordState === "valid" && this.state.password != this.state.confirmPassword) {
        this.setState({
          confirmPasswordErrorMessage: (
            <small className="text-danger">
              Password and Confirm Password do not match.
            </small>
          )
        });
        this.state.isPasswordFormValid = false;
      }
      else {
        this.setState({ confirmPasswordErrorMessage: null });
        this.state.isPasswordFormValid = true;
      }
    }
    return this.state.isPasswordFormValid;
  }

  handleLoginSubmit() {
    if (this.isValidated()) {
      this.setState({ ShowProgressBar: true });
      localStorage.clear();
      this.postLogin(this.state);
      // this.props.history.push('/admin/dashboard');   
    }
    else {
      console.log('invalid email or password');
    }
  }

  isValidated() {
    let isValid = true;
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(this.state.email) === false) {
      this.setState({
        emailErrorMessage: (
          <small className="text-danger">
            Email is required and format should be <i>john@doe.com</i>.
          </small>
        )
      });
      isValid = false;
    }
    else {
      this.setState({ emailErrorMessage: null });
    }

    if (this.state.IsForgotPassword === false && this.state.IsSingupNow === false) {
      if (this.state.password < 6) {
        this.setState({
          passwordErrorMessage: (
            <small className="text-danger">
              You must enter a password of at least 6 characters.
            </small>
          )
        });
        isValid = false;
      }
      else {
        this.setState({ passwordErrorMessage: null });
      }
    }
    else if (this.state.IsSingupNow === true) {
      if (this.state.firstNameState === "" || this.state.firstNameState === "error") {
        this.setState({
          firstNameErrorMessage: (
            <small className="text-danger">
              First Name is Required.
            </small>
          )
        });
        isValid = false;
      }
      else {
        this.setState({ firstNameErrorMessage: null });
      }

      if (this.state.lastNameState === "" || this.state.lastNameState === "error") {
        this.setState({
          lastNameErrorMessage: (
            <small className="text-danger">
              Last Name is Required.
            </small>
          )
        });
        isValid = false;
      }
      else {
        this.setState({ lastNameErrorMessage: null });
      }
      if (!this.ValidatePasswords()) {
        isValid = false;
      }
    }
    else {
      this.setState({ passwordErrorMessage: null });
    }
    return isValid;
  }

  handleKeyPressInput(e) { //= event => {
    var charCode = (e.which) ? e.which : e.keyCode;
    //alert(charCode);
    //97-122, 32,65-90'
    if (charCode === 13 && this.isValidated()) {
      if (this.state.IsForgotPassword === true) {
        this.forgotPasswordSubmitClick();
      }
      else {
        this.handleLoginSubmit();
      }
    }
  };

  setResult(data) {
    let uuid = data.access_token;
    let full_name = data.full_name;
    //  global.SuccessfulLoggedIn = true;
    let processor = data.processor;
    let tokenize_key = null;
    if (processor != null) {
      if (processor.ProcessorType == "nmi") {
        tokenize_key = JSON.stringify({ "PPType": processor.ProcessorType, "PPToken": processor.ProcessorConfig.TokenizationKey })
      } else if (processor.ProcessorType == "cardconnect") {
        tokenize_key = JSON.stringify({ "PPType": processor.ProcessorType, "PPToken": processor.ProcessorConfig.URL })
      }
    }
    let software_type = data.software_type != null && data.software_type.value != null ? data.software_type.value : 1;
    let is_connected = data.software_type != null && data.software_type.is_connected != null ? data.software_type.is_connected : false;
    if (software_type == 1) {
      localStorage.setItem("is_qbo_connected", is_connected);
    }
    else if (software_type == 4) {
      localStorage.setItem("is_nmiinvoicing_connected", is_connected);
    }
    else if (software_type == 5) {
      localStorage.setItem("is_standalone_connected", is_connected);
    }
    localStorage.setItem(StorageEnum.UUID, uuid);
    localStorage.setItem(StorageEnum.FULLNAME, full_name);
    localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
    localStorage.setItem(StorageEnum.SOFTWARE_TYPE, software_type);
    let login_ref = data.type;
    localStorage.setItem("login_user_type", login_ref.name);
  }

  change(event, stateName, type, stateNameEqualTo) {
    const value = event.target.value;
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "valid" });
          this.setState({ [stateName + "ErrorMessage"]: null });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [stateName]: value
      }
    }));

  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  /** API'S CALLING */
  // POST request of login
  postLogin = (postData) => {
    this.setState({ errorMessage: "" });
    let url = BASE_URL + "api/v1/account/login/";
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "invoice_token": postData.invoiceToken,
        "email": postData.email,
        "password": postData.password
      })
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data != null) {
          this.setState({ errorMessage: data.statusmessage });
          this.setState({ errorCode: data.statuscode });
          this.setState({ ShowProgressBar: false });

          if (data.statuscode === 0) {
            let login_ref = data.data.type;
            // if ((login_ref.value == 3 && login_ref.name == "Merchant") || (login_ref.value == 5 && login_ref.name == "Staff")) {
            //   this.setResult(data.data);
            //   let softwareType = localStorage.getItem(StorageEnum.SOFTWARE_TYPE);
            //   if (softwareType == SoftwareTypeEnum.QuickBooksOnline) {
            //     if (localStorage.getItem("is_qbo_connected") == "false") {
            //       this.props.history.push('/invoice/accountingpackage');
            //     }
            //     else {
            //       this.props.history.push('/invoice/dashboard');
            //     }
            //   }
            //   else if (softwareType == SoftwareTypeEnum.QuickBooksDesktop) {
            //     this.props.history.push('/qbfinancial/dashboard');
            //   }
            //   else if (softwareType == SoftwareTypeEnum.QuickBooksPOS) {
            //     this.props.history.push('/qbpos/dashboard');
            //   }
            //   else if (softwareType == SoftwareTypeEnum.NMIInvoicing) {
            //     if (localStorage.getItem("is_nmiinvoicing_connected") == "false") {
            //       this.props.history.push('/nmi/accountingpackage');
            //     }
            //     else {
            //       this.props.history.push('/nmi/invoices');
            //     }
            //   }
            //   //this.props.history.push('/account/home');
            // }
            // else if (login_ref.value == 4 && login_ref.name == "Admin") {
            //   let uuid = data.data.access_token;
            //   let full_name = data.data.full_name;

            //   //  global.SuccessfulLoggedIn = true;
            //   localStorage.setItem(StorageEnum.UUID, uuid);
            //   localStorage.setItem(StorageEnum.FULLNAME, full_name);
            //   localStorage.setItem("login_user_type", "Admin");
            //   this.props.history.push('/admin/transactions');
            // }
            //else
            if (login_ref.value == 6 && login_ref.name == "Customer"){
              this.setResult(data.data);
              let customer_ref = data.data.customer_ref || null;

              const customer_id = customer_ref ? customer_ref.value : null;
              const customer_name = customer_ref ? customer_ref.name : null;

              localStorage.setItem(StorageEnum.CUSTOMER_ID, customer_id);
              localStorage.setItem(StorageEnum.CUSTOMER_NAME, customer_name);

              let softwareType = localStorage.getItem(StorageEnum.SOFTWARE_TYPE);
              if (softwareType == SoftwareTypeEnum.QuickBooksOnline) {
                if (localStorage.getItem("is_qbo_connected") == "false") {
                  // this.props.history.push('/invoice/accountingpackage');
                }
                else {
                  this.props.history.push('/customerportal/dashboard');
                }
              }
            }
            else {
              this.setState({ errorCode: 1 });
              this.setState({ errorMessage: "Invalid login credentials" });
            }
          } else {
            this.setState({ errorMessage: "Invalid login credentials" });
          }
        }
      })
      .catch(error => {
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        this.setState({ ShowProgressBar: false });
      });;
  }


  // POST request of forget password
  _postForgotPassword = (data) => {
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });

    NetworkService.postForgetPasswordRequest(data)
      .then(data => {
        this.setState({ errorMessage: data.statusmessage });
        this.setState({ errorCode: data.statuscode });
        this.setState({ ShowProgressBar: false });

        if (data.statuscode === 0) {
          this.setState({ isValidUser: true });
          this.setState({ IsForgotPasswordLinkSent: true });
        } else {
          this.setState({ isValidUser: false });
          this.setState({ IsForgotPasswordLinkSent: false });
          //setTimeout(this.closeMessage, 2000);  
        }
      }).catch(error => {
        this.setState({ isValidUser: false });
        this.setState({ IsForgotPasswordLinkSent: false });
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        this.setState({ ShowProgressBar: false });
      });
  }

  // POST request of Signup
  _postSignupRequest = (data) => {
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });

    NetworkService.postCustomerSignupRequest(data)
      .then(data => {
        this.setState({ ShowProgressBar: false });
        this.setState({ errorCode : data.statuscode });
        this.setState({ errorMessage: data.statusmessage });
        if (data.statuscode == 0) {
          this.setState({IsForgotPassword : false, IsSingupNow : false});
        } else {
          console.log(data.statusmessage);          
        }

      }).catch(error => {
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        this.setState({ ShowProgressBar: false });
      });
     
      setTimeout(() => {
          this.setState({ errorMessage: "" })
      }, 8000);
     
  }

  render() {
    const customButtonStyle = {
      minWidth: "100%",
      marginBottom: "20px"
    };
    const customLinkStyle = {
      color: "#0075c1",
      fontSize: "16px",
    }

    return (
      <Grid>       
        <Row>
          <Col md={6} sm={6} mdOffset={3} smOffset={3}>
            <form>
              {this.state.ShowProgressBar &&
                <div>
                  <br /><br />
                  <CustomProgressBar></CustomProgressBar>
                </div>
              }
              {this.state.errorMessage != "" &&
                <div>
                  <br /><br />
                  <Alert bsStyle={this.state.errorCode == "0" || this.state.errorCode == 0 ? "success" : "danger"}>
                    <span>
                      {this.state.errorMessage}
                    </span>
                  </Alert>
                </div>
              }
              <Card
                textLeft
                title={this.state.title}
                category={this.state.category}
                content={
                  <div>
                    {(this.state.IsForgotPassword === false && this.state.IsSingupNow === false) &&
                      <div>
                        <FormGroup>
                          <ControlLabel>Email address <span className="star">*</span></ControlLabel>
                          <FormControl
                            value={this.state.email}
                            placeholder="Enter email"
                            type="email"
                            className={this.state.emailState}
                            // onChange={event => this.handleLoginEmail(event)}
                            onChange={event => {
                              this.setState({ email: event.target.value });
                              this.setState({ emailErrorMessage: null });
                              this.setState({ emailState: "" });
                            }
                            }
                            onBlur={event => this.handleLoginEmail(event)}
                          />
                          {this.state.emailErrorMessage}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>Password  <span className="star">*</span></ControlLabel>
                          <FormControl
                            value={this.state.password}
                            placeholder="Password"
                            type="password" autoComplete="off"
                            className={this.state.passwordState}
                            onChange={event => this.handleLoginPassword(event)}
                            onBlur={event => this.handleLoginPassword(event)}
                            onKeyPress={event => this.handleKeyPressInput(event)}
                          />
                          {this.state.passwordErrorMessage}
                        </FormGroup>
                      </div>}
                    {this.state.IsForgotPassword === true &&
                      <div>
                        <FormGroup>
                          <ControlLabel>Email address <span className="star">*</span></ControlLabel>
                          <FormControl
                            value={this.state.email}
                            placeholder="Enter email"
                            type="email"
                            className={this.state.emailState}
                            // onChange={event => this.handleLoginEmail(event)}
                            onChange={event => {
                              this.setState({ email: event.target.value });
                              this.setState({ emailErrorMessage: null });
                              this.setState({ emailState: "" });
                            }
                            }
                            onBlur={event => this.handleLoginEmail(event)}
                            onKeyPress={event => this.handleKeyPressInput(event)}
                          />
                          {this.state.emailErrorMessage}
                        </FormGroup>
                      </div>
                    }
                    {this.state.IsSingupNow &&
                      <div>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>First Name: <span className="star">*</span></ControlLabel>
                              <FormControl
                                type="text"
                                placeholder="First Name"
                                name="firstName"
                                className={this.state.firstNameState}
                                value={this.state.first_name}
                                onChange={event => {
                                  this.setState({ first_name: event.target.value });
                                  this.change(event, "firstName", "length", 3);
                                }
                                }
                                onBlur={e => this.isValidated()}
                              />
                              {this.state.firstNameErrorMessage}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>Last Name: <span className="star">*</span></ControlLabel>
                              <FormControl
                                type="text"
                                placeholder="Last Name"
                                name="lastName"
                                className={this.state.lastNameState}
                                value={this.state.last_name}
                                onChange={event => {
                                  this.setState({ last_name: event.target.value });
                                  this.change(event, "lastName", "length", 3);
                                }
                                }
                                onBlur={e => this.isValidated()}
                              />
                              {this.state.lastNameErrorMessage}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>Email address <span className="star">*</span></ControlLabel>
                              <FormControl
                                value={this.state.email}
                                placeholder="Enter email"
                                type="email"
                                className={this.state.emailState}
                                onChange={event => {
                                  this.setState({ email: event.target.value });
                                  this.setState({ emailErrorMessage: null });
                                  this.setState({ emailState: "" });
                                }
                                }
                                onBlur={event => this.handleLoginEmail(event)}
                                onKeyPress={event => this.handleKeyPressInput(event)}
                              />
                              {this.state.emailErrorMessage}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>
                                Phone Number:
                              </ControlLabel>
                              <FormControl
                                type="number"
                                placeholder="Phone Number"
                                className={this.state.phonenumberState}
                                name="phonenumber"
                                value={this.state.phonenumber}
                                onChange={event => {
                                  this.setState({ phonenumber: event.target.value });
                                  this.change(event, "phonenumber", "length", 7)
                                }
                                }
                                onBlur={e => this.isValidated()}
                                type="number"
                              />
                              {this.state.phonenumberErrorMessage}

                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel> Password  <span className="star">*</span></ControlLabel>
                              <FormControl
                                value={this.state.password}
                                placeholder="Password"
                                autoComplete="off"
                                name="password"
                                type="password"
                                className={this.state.passwordState}
                                onChange={event => {
                                  this.setState({ password: event.target.value });
                                  this.change(event, "password", "length", 6)
                                }
                                }
                              />
                              {this.state.passwordErrorMessage}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <ControlLabel>Confirme Password  <span className="star">*</span></ControlLabel>
                              <FormControl
                                value={this.state.confirmPassword}
                                placeholder="Confirme Password"
                                autoComplete="off"
                                name="confirmPassword"
                                type="password"
                                className={this.state.confirmPasswordState}
                                onChange={event => {
                                  this.setState({ confirmPassword: event.target.value });
                                  this.change(event, "confirmPassword", "length", 6);
                                  this.ValidatePasswords();
                                }
                                }
                                onBlur={e => this.ValidatePasswords()}
                              />
                              {this.state.confirmPasswordErrorMessage}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    }
                  </div>


                }
                legend={
                  <div>
                    {(this.state.IsForgotPassword === false && this.state.IsSingupNow === false) &&
                      <div>
                        <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleLoginSubmit.bind(this)}>
                           SIGN IN
                        </Button>
                        <br />                     
                        {/* <a style={customLinkStyle} href="#" target="_blank" onClick={e => this.linkClick(e, "SingupNow")}> Singup Now</a> */}
                        <Button bsStyle="primary" style={{width:"48%", marginRight:"10px"}} onClick={e => this.linkClick(e, "ForgotPassword")}>
                          Forgot Password?
                        </Button>
                        <Button bsStyle="primary" style={{width:"48%"}} onClick={e => this.linkClick(e, "SingupNow")}>
                        Singup Now
                        </Button>
                      </div>
                    }
                    {(this.state.IsForgotPassword === true || this.state.IsSingupNow === true) &&
                      <div>
                        {this.state.IsForgotPassword === true &&
                          <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.forgotPasswordSubmitClick}>
                            SUBMIT
                          </Button>
                        }
                        {this.state.IsSingupNow === true &&
                          <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.createAccountSubmitClick}>
                            SUBMIT
                          </Button>
                        }
                        <br />
                        <a style={customLinkStyle} href="#" id="BackToLogin" value="BackToLogin" onClick={e => this.linkClick(e, "BackToLogin")}>
                          Back to Login
                            </a>
                      </div>
                    }
                  </div>
                }
                ftTextCenter
              />
              <br />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Login;