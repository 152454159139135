import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
    FormControl,
    Alert
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import MaterialTable, { MTableToolbar } from 'material-table';
import Datetime from "react-datetime";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import NetworkService from "services/NetworkService";
import Chip from '@material-ui/core/Chip';


class Payments extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            showVoidTransactionPopupModal: false,
            showRefundTransactionPopupModal: false,
            showSendReceiptPopupModal: false,
            ShowPopupProgressBar: false,
            errorCode: null,
            errorMessage: "",
            emailState: "valid",
            receiptModel: {
                customername: "",
                amount: 0.00,
                paymenttype: "",
                paymentnumber: "",
                customeremail: "",
            },
            voidModel: {
                customername: "",
                amount: 0.00,
                paymenttype: "",
                paymentnumber: ""
            },
            refundModel: {
                customername: "",
                amount: 0.00,
                paymenttype: "",
                paymentnumber: ""
            },
            amountToRefund: 0.00,
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: false,
            transactionStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                { value: "all", label: "All" },
                { value: "approved", label: "Approved" },
                { value: "declined", label: "Declined" },
            ],
            filter_transaction: { value: "all", label: "All" },
            data: [
            ],
            columns: [
                {
                    title: 'Trans ID',
                    field: 'tansactionid',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        width: 'calc(20%)',
                        textAlign: "left",
                    },
                    cellStyle: {
                        width: 'calc(20%)',
                        textAlign: "left"
                    }
                },
                {
                    title: 'Patient Name',
                    field: 'merchantDefinedField1',
                    headerStyle: {
                        width: 'calc(20%)',
                        textAlign: "left",
                    },
                    cellStyle: {
                        width: 'calc(20%)',
                        textAlign: "left"
                    }
                },
                {
                    title: 'Trans Date',
                    field: 'date',
                    headerStyle: {
                        textAlign: "left",
                    },
                    cellStyle: {                     
                        textAlign: "left"
                    }
                },
                {
                    title: 'Amount($)',
                    field: 'amount',
                    cellStyle: {                     
                        textAlign: "center"
                    },
                    
                },
                {
                    title: 'Sales Tax($)',
                    field: 'tax',
                    cellStyle: {                     
                        textAlign: "center"
                    }
                },
                {
                    title: 'Card Type',
                    field: 'cardtype',
                    cellStyle: {
                        textAlign: "center"
                    }
                },
                {
                    title: 'Last 4 Digits',
                    field: 'last4digit',
                    cellStyle: {                     
                        textAlign: "center"
                    }
                },
                // {
                //     title: 'Trans Type',
                //     field: 'transactiontype',
                //    // hidden: true,
                //     cellStyle: {                     
                //         textAlign: "center"
                //     }
                // },
                {
                    title: 'Status',
                    field: 'transactionstatus',
                    export: false,     
                },
                {
                    hidden: true,
                    title: 'HiddenStatus',
                    field: 'hiddentransactionstatus'
                },
                {
                    title: 'Invoice No.',
                    field: 'paymentnumber',
                    headerStyle: {
                        textAlign: "left",
                    },
                }
            ],
            subTransactionColumns: [
                {
                    title: 'Trans ID',
                    field: 'tansactionid',
                    cellStyle: {                     
                        textAlign: "center"
                    }
                },
                {
                    title: 'Date',
                    field: 'date',
                    cellStyle: {                     
                        textAlign: "center"
                    }
                },
                {
                    title: 'Amount($)',
                    field: 'amount',
                    cellStyle: {                     
                        textAlign: "center"
                    }
                },
                {
                    title: 'Trans Type',
                    field: 'transactiontype',
                    cellStyle: {                     
                        textAlign: "center"
                    }
                },
                {
                    title: 'Status',
                    field: 'transactionstatus',
                    cellStyle: {                     
                        textAlign: "center"
                    },
                    export: false,     
                },
            ],
            options: {
                //selection: true,
                pageSize: 10,
                exportButton: true,
                exportFileName: "Payments",
                exportAllData: true,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },               
                rowStyle: rowData => ({
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"
                   
                }),
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,
            },
            subTransactionOptions: {
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                paging: false,
                toolbar: false,
                sorting: false,
                draggable: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            detailPanel: [
                rowData => ({
                    disabled: !rowData.expandable,
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionExpandToolTip">Expand</Tooltip>}><i className="fa fa-plus" style={{ color: "#0075c1", display: !rowData.expandable ? "none" : "block" }}></i></OverlayTrigger>, //<UnfoldMore className={!rowData.expandable && classes.displayNone} />,
                    openIcon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionCollapseToolTip">Collapse</Tooltip>}><i className="fa fa-minus" style={{ color: "#0075c1" }}></i></OverlayTrigger>,
                    render: () => {
                        return (
                            <Row>
                                <Col md={10} mdOffset={1}>
                                    <MuiThemeProvider theme={this.theme}>
                                        <MaterialTable
                                            columns={this.state.subTransactionColumns}
                                            data={rowData.subTrans}
                                            options={this.state.subTransactionOptions}
                                            actions={this.state.subTransactionActions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        )
                    },
                })
            ],
            emailReceiptToolTip: <Tooltip id="emailReceiptToolTip">Email Receipt</Tooltip>,
            printReceiptToolTip: <Tooltip id="printReceiptToolTip">Print Receipt</Tooltip>,
            voidToolTip: <Tooltip id="voidToolTip">Void</Tooltip>,
            refundToolTip: <Tooltip id="refundToolTip">Refund</Tooltip>,
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.emailReceiptToolTip}>
                        <i className="fa fa-envelope" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleOpenSendReceiptPopup(rowData)
                },                
                // rowData => ({
                //     icon: () => <OverlayTrigger placement="top" overlay={this.state.refundToolTip}>
                //         <i className="fa fa-mail-reply-all" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     hidden: rowData.transactiontype == "CC Refund" || rowData.transactiontype == "CK Refund" || rowData.hiddentransactionstatus == "declined",  
                //     onClick: (event, rowData) => this.handleOpenRefundTransactionPopup(rowData)
                // }),
                // rowData => ({
                //     icon: (event, rowData) => <OverlayTrigger placement="top" overlay={this.state.voidToolTip}>
                //         <i className="fa fa-ban" style={{ color: "#0075c1"}}></i>
                //     </OverlayTrigger>,    
                //     hidden: rowData.subTrans.length > 0 || rowData.transactiontype == "CC Refund" || rowData.transactiontype == "CK Refund" || rowData.hiddentransactionstatus == "declined",  
                //     onClick: (event, rowData) => rowData.subTrans.length === 0 ? this.handleOpenVoidTransactionPopup(rowData) : alert("Cannot void already voided or refunded transaction.")                                       
                // })
                rowData => this.formatDate(rowData.date) == this.formatDate(new Date()) &&
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.voidToolTip}>
                        <i className="fa fa-ban" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleOpenVoidTransactionPopup(rowData) //rowData.subTrans.length === 0 ? this.handleOpenVoidTransactionPopup(rowData) : alert("Cannot void already voided or refunded transaction.")
                    

                },
                // rowData => this.formatDate(rowData.date) != this.formatDate(new Date()) &&
                // { 
                //     icon: () => <OverlayTrigger placement="top" overlay={this.state.refundToolTip}>
                //         <i className="fa fa-mail-reply-all" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => this.handleOpenRefundTransactionPopup(rowData)
                // }
            ],
            subTransactionActions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.emailReceiptToolTip}>
                        <i className="fa fa-envelope" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleOpenSendReceiptPopup(rowData)
                },               
            ],
        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.SetFromToDates();
        this.filterTransactions()
    }

    SetFromToDates() {       
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    /** HANDLER */
    fromDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ errorMessage: "" });
        this.filterTransactions();
    }

    filterTransactions = () => {
        this.setState({ ShowProgressBar: true });
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
            "status": this.state.filter_transaction.value
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');
        this._getPaymentListRequest(query);
    };

    handleOpenSendReceiptPopup(transaction) {        
        this.state.receiptModel = transaction;
        this.setState({ showSendReceiptPopupModal: true });
        if(transaction.customer_email == null || transaction.customer_email == "")
            {
                this.setState({emailState : "error"})
            }
    }

    handleSendReceiptEmail(e, receipt) {
        e.preventDefault();
        const customer_email = receipt.customer_email
        const payload = {
            "ToEmails": [customer_email],
            "Id": receipt.id,
            "Receipt": receipt,
        }
        this._postEmailReceiptRequest(payload);
    }

    handleOpenVoidTransactionPopup(transaction) {
        this.state.voidModel = transaction;
        this.setState({ showVoidTransactionPopupModal: true });
    }

    handleOpenRefundTransactionPopup(transaction) {
        this.state.refundModel = transaction;
        this.state.amountToRefund = this.state.refundModel.amount;
        this.setState({ showRefundTransactionPopupModal: true });
    }

    handleVoidTransactionSubmit(transaction) {
        const data = {
            "trans_amount": transaction.amount,
            "remain_amount": transaction.amount,
            "ref_id": transaction.ref_id,
            "trans_type": transaction.transactiontype
        }
        this._postVoidTransactionRequest(data);
    }

    handleRefundTransactionSubmit(transaction) {        
        const data = {
            "trans_amount": this.state.amountToRefund,
            "remain_amount":  transaction.amount,
            "ref_id": transaction.ref_id,
            "trans_type": transaction.transactiontype
        }
        this._postRefundTransactionRequest(data);
    }

    handleAmountToRefundChange = e => {
        const { name, value } = e.target;
        if (parseFloat(value) > parseFloat(this.state.refundModel.amount)) {
            return false;
        }
        this.setState({ amountToRefund: parseFloat(value).toFixed(2) });
    };

    validateEmail(event) {
        var value = event.target.value;
        this.setState(prevState => ({
        receiptModel: {
            ...prevState.receiptModel,
            customer_email: value
        }
        }));
        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        if (re.test(event.target.value) === false) {
        this.setState({
            emailErrorMessage: (
            <small className="text-danger">
                Invalid email address.
                </small>
            )
        });
        this.setState({
            emailState: "error"
        });
        }
        else {
        this.setState({ emailState: "valid" });
        this.setState({ emailErrorMessage: null });
        }

  }

    setPaymentListResult(result) {
        const newSeries = [];
        const salesData = [];
        const voidRefundData = [];
        var showCustomField1 = false;
        var showCustomField2 = false;
        result.map((data, index) => {           
            if(data.parent_ref_id === null || data.parent_ref_id === "")
            {
                salesData.push({
                    index: index,
                    expandable: false,
                    id: data.id,
                    tansactionid: data.ref_id,
                    customername: data.customer_name ? data.customer_name : data.payer_name,                    
                    merchantDefinedField1: data.MerchantDefinedField1 ? data.MerchantDefinedField1  + " " + (data.MerchantDefinedField2 ? data.MerchantDefinedField2 : "" ) : "" + " " + data.MerchantDefinedField2 ? data.MerchantDefinedField2 : "",
                    date: data.trans_date,
                    amount: data.trans_type == "CC Refund" ? `(${parseFloat(data.trans_amount).toFixed(2)})`:parseFloat(data.trans_amount).toFixed(2),
                    tax: parseFloat(data.tax).toFixed(2),
                    transactiontype: data.trans_type,
                    last4digit: data.last4digit,
                    cardtype: data.card_type,
                    transactionstatus: data.trans_status=="approved" ? <Chip style={{ backgroundColor: "#2196f3", color: "white", height: 25 }} label="Approved"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Declined"></Chip>,
                    hiddentransactionstatus:data.trans_status,
                    paymenttype: data.payment_type,
                    paymentnumber: data.MerchantDefinedField3 ? data.MerchantDefinedField3 : "",//data.doc_number,
                    ref_id: data.ref_id,
                    customer_email: data.customer_email,
                    subTrans: [],
                });
            }
            if(data.MerchantDefinedField1 != null && data.MerchantDefinedField1 != "")
            {
                showCustomField1 = true;
            }
            if(data.MerchantDefinedField1 != null && data.MerchantDefinedField1 != "")
            {
                showCustomField2 = true;
            }
        });

        result.map((vdata, index2) => {
            if(vdata.parent_ref_id !== null && vdata.parent_ref_id !== "")
            {
                voidRefundData.push({
                    index: index2,
                    expandable: false,
                    id: vdata.id,
                    tansactionid: vdata.ref_id,
                    customername: vdata.customer_name ? vdata.customer_name : vdata.payer_name,
                    date: vdata.trans_date,
                    amount: parseFloat(vdata.trans_amount).toFixed(2),           
                    transactiontype: vdata.trans_type,
                    transactionstatus: vdata.trans_status=="approved" ? <Chip style={{ backgroundColor: "#2196f3", color: "white", height: 25 }} label="Approved"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Declined"></Chip>,
                    paymenttype: vdata.payment_type,
                    paymentnumber: vdata.doc_number,
                    ref_id: vdata.ref_id,
                    parent_ref_id: vdata.parent_ref_id,
                    customer_email: vdata.customer_email
                });
            }
        });
        
        salesData.forEach(function(sale) {
            voidRefundData.forEach(function(voidRefund){
                if(sale.ref_id === voidRefund.parent_ref_id)
                {
                    sale.expandable = true;
                    sale.subTrans.push(voidRefund);
                }
            });
            newSeries.push(sale);
        });

        if(!showCustomField2)
        {
            this.state.columns[2].name = "";
            this.state.columns[2].hidden = true;
            this.state.columns[2].width = -1;
        }
        if(!showCustomField1)
        {
            this.state.columns[2].name = "";
            this.state.columns[2].hidden = true;
            this.state.columns[2].width = -1;
        }

        this.setState({ data: newSeries });
    }

    /** API CALLING */
    _getPaymentListRequest(params = "") {        
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing;
        NetworkService.getPaymentListRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null && data.statuscode == 0) {
                        this.setPaymentListResult(data.data.items);
                        this.setState({ ShowProgressBar: false });
                    }
                    else{
                        this.setState({ errorMessage: data.statusmessage });                        
                        this.setState({ ShowProgressBar: false });
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ ShowProgressBar: false });
            });

        setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 7000);
    }

    _postVoidTransactionRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing;
        NetworkService.postVoidPaymentRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showVoidTransactionPopupModal: false });
                if (data != null) {                   
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                             this.filterTransactions(); 
                        }                         
                    }
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showVoidTransactionPopupModal: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    _postRefundTransactionRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing;
        NetworkService.postRefundPaymentRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showRefundTransactionPopupModal: false });
                if (data != null) {                   
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.filterTransactions();     
                        }                       
                    }
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);

                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showRefunddTransactionPopupModal: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    
    _postEmailReceiptRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing;
        NetworkService.postEmailReceiptRequest(app_id, data)
        .then(data => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendReceiptPopupModal: false });
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                if (data.statuscode == 0) {
                    if (data.data != null) {
                    }
                }
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);

            } else {
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            this.setState({ showSendReceiptPopupModal: false });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }


    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage != "" &&
                                <div>                                
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date",  disabled: this.state.ShowProgressBar }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date", disabled: this.state.ShowProgressBar }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>Transaction Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="transactionStatus"
                                            value={this.state.filter_transaction}
                                            onChange={value => {
                                                this.setState({ filter_transaction: value });
                                            }
                                            }
                                            options={this.state.transactionStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} disabled={this.state.ShowProgressBar} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                         {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br/>
                                            </div>

                                        }
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            actions={this.state.actions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            detailPanel={this.state.detailPanel}

                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                            <Modal
                                show={this.state.showSendReceiptPopupModal}
                                onHide={() => this.setState({ showSendReceiptPopupModal: false })}
                            >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }
                                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                    <Modal.Title style={{ color: "white" }}>Send Receipt</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="modal-body text-center" >
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Customer : </h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>{this.state.receiptModel.customername}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Invoice Number :</h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>{this.state.receiptModel.paymentnumber}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Amount :</h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>${this.state.receiptModel.amount}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4} className="text-left">
                                                <h5>Customer Email :</h5>
                                            </Col>
                                            <Col md={8} >
                                                <FormGroup >
                                                    <FormControl
                                                        value={this.state.receiptModel.customer_email}
                                                        placeholder="Email"
                                                        type="email"
                                                        autoComplete="off"
                                                        className={this.state.sendInvoiceClassName}
                                                        onChange={event => this.validateEmail(event)}
                                                    />
                                                    {this.state.errorSendInvoiceEmail}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendReceiptPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd disabled={this.state.emailState != "valid"} onClick={e => this.handleSendReceiptEmail(e, this.state.receiptModel)}>Send</Button>
                                </Modal.Footer>

                            </Modal>
                            <Modal
                                show={this.state.showVoidTransactionPopupModal}
                                onHide={() => this.setState({ showVoidTransactionPopupModal: false })}
                            >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }

                                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                    <Modal.Title style={{ color: "white" }}>Void Transaction</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="modal-body text-center">
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Customer : </h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>{this.state.voidModel.customername}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Invoice Number :</h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>{this.state.voidModel.paymentnumber}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Amount :</h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>${this.state.voidModel.amount}</h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showVoidTransactionPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.handleVoidTransactionSubmit(this.state.voidModel)}>Submit</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={this.state.showRefundTransactionPopupModal}
                                onHide={() => this.setState({ showRefundTransactionPopupModal: false })}
                            >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }

                                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                    <Modal.Title style={{ color: "white" }}>Refund Transaction</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="modal-body text-center">
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Customer : </h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>{this.state.refundModel.customername}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Invoice Number :</h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>{this.state.refundModel.paymentnumber}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Amount :</h5>
                                            </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                <h5>${this.state.refundModel.amount}</h5>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} sm={12} md={4} className="text-left">
                                                <h5>Amount to Refund :</h5>
                                            </Col>
                                            <Col style={{ textAlign: "right" }} xs={12} sm={12} md={4}>
                                                <FormControl
                                                    value={parseFloat(this.state.amountToRefund).toFixed(2)}
                                                    type="number"
                                                    name="amountToRefund"
                                                    min="0.01"
                                                    style={{ textAlign: "right" }}
                                                    step="0.01"
                                                    onChange={this.handleAmountToRefundChange}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showRefundTransactionPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.handleRefundTransactionSubmit(this.state.refundModel)}>Submit</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Payments;