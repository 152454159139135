import React, { Component } from "react";
import { Grid, Row, Col, Media, FormControl, ControlLabel, FormGroup, Alert, Tooltip, Modal, OverlayTrigger } from "react-bootstrap";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StorageEnum, GATEWAY_API_URL, USER_NAME, PASSWORD } from "utils/constant.js";
import NetworkService from "services/NetworkService";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import Chip from '@material-ui/core/Chip';


class EditMerchant extends Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            ShowProgressBar: false,
            isMerchantInfoFormValid: true,
            isPaymentInfoFormValid: true,
            firstnameState: "valid",
            lastnameState: "valid",
            emailState: "valid",
            websiteState: "valid",
            companynameState: "valid",
            quickbooksversionState: "valid",
            monthlyinvoicevolumeState: "valid",
            phonenumberState: "valid",
            statusState: "valid",
            nmiSecurityKeyState: "valid",
            nmiTokenizationKeyState: "valid",
            nmiCollectCheckoutKeyState: "valid",
            paymentmethodState: "valid",
            firstnameErrorMessage: "",
            lastnameErrorMessage: "",
            emailErrorMessage: "",
            companynameErrorMessage: "",
            phonenumberErrorMessage: "",
            quickbooksversionErrorMessage: "",
            paymentmethodErrorMessage: "",
            monthlyinvoicevolumeErrorMessage: "",
            errorRegistrationMessage: "",
            statusErrorMessage: "",
            paymentMethodType: "",
            user: {
                firstname: "",
                lastname: "",
                email: "",
                website: "",
                quickbooksversion: "",
                monthlyinvoicevolume: "",
                paymentMethodType: "",
                companyname: "",
                phonenumber: "",
                status: "",
                image: "",
                selectedReseller: "",
                Processor: {
                    MethodID: "",
                    Configuration: {},
                    IsDefault: false,
                    IsStatus: false,
                    IsVoidRefund: false,
                    IsBankAccount: false,
                    Logo: null,
                    MethodName: "",
                    MethodType: "",
                    Details: "",
                    AuthorizeNet: {
                        ClientKey: "",
                        APILoginID: "",
                        TransactionKey: "",
                        supported_methods: {
                            ach: true,
                            creditcard: true,
                            debitcard: true
                        }

                    },
                    CardConnect: {
                        MerchantID: "",
                        UserName: "",
                        Password: "",
                        URL: "",
                        supported_methods: {
                            ach: true,
                            creditcard: true,
                            debitcard: true
                        },
                        Bolt: {
                            url: "",
                            authorization: "",
                            hsn: "",
                            Terminals:[]
                        }
                    },
                    NMI: {
                        SecurityKey: "",
                        TokenizationKey: "",
                        CollectCheckoutKey: "",
                        UserName: "",
                        Password: "",
                        supported_methods: {
                            ach: true,
                            creditcard: true,
                            threestep: false,
                            gatewayJS: false,
                            quickclick: false,
                        },
                        Terminals:[]
                    },
                    USAePay: {},
                    WePay: {},
                },
                Subusers: [
                    {
                        // id: "1",
                        // firstname: "John",
                        // lastname: "Albert",
                        // email: "asfsadf@adsf.ere",
                        // password: "fd#@$#sd",
                        // status: "Active"
                    }
                ]
            },
            ProcessorList: [],
            merchantID: "",
            nmiSecurityKeyErrorMessage: "",
            paymentMethodCheckBoxErrorMessage: "",
            nmiTokenizationKeyErrorMessage: "",
            nmiCollectCheckoutKeyErrorMessage: "",
            ccMerchantIDErrorMessage: "",
            ccUserNameErrorMessage: "",
            ccPasswordErrorMessage: "",
            ccMerchantIDState: "valid",
            ccUserNameState: "valid",
            ccPasswordState: "valid",     
            isImageChange: false,
            ShowPopupProgressBar: false,
            showCreateEditSubuserModal: false,
            showOnlineSubuserPermissionsModal: false,
            subuserFirstNameState: "",
            subuserLastNameState: "",
            subuserEmailState: "",
            subuserPasswordState: "",
            subuserEmailErrorMessage: "",
            subuserPasswordErrorMessage: "",
            isValidSubuser: true,
            paymentMethodType: true,
            subuserData: {
                id: "",
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                status: true
            },
            IsEditedSubuser: false,
            columns: [
                {
                    title: "First Name",
                    field: "firstname",
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: "Last Name",
                    field: "lastname",
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'Email',
                    field: 'email',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'Status',
                    field: 'status',
                    headerStyle: {
                        width: 'calc(20%)',
                        textAlign: "left",
                    },
                    cellStyle: {
                        width: 'calc(20%)',
                        textAlign: "left"
                    }
                }
            ],
            options: {
                search: false,
                //toolbar: false,
                paging: false,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="createSubuserToolTip">Add New Subuser</Tooltip>}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateSubuser(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>

                        </div>
                    </div>
                ),
            },
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editResendWelcomeEmailToolTip">Resend Welcome Email</Tooltip>}>
                        <i className="fa fa-envelope" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleResendEmail(event, rowData)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editSetDefaultPasswordToolTip">Set Default Password</Tooltip>}>
                        <i className="fa fa-key" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleSubUserDefaultPassword(event, rowData)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editPermissionsToolTip">Features Permission</Tooltip>}>
                        <i className="fa fa-th-large" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleSubuserPermissions(event, rowData)
                },                
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editSubuserToolTip">Edit Info</Tooltip>}>
                        <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleEditSubuser(event, rowData)
                }
            ],
            showOnlinePermissionsModal: false,
            showStandalonePermissionsModal: false,
            Permissions: null,
            SubuserPermissions: null,
            displayQuickBooksOnline: "none",
            displayStandaloneFeaturesButton: "none",
            add_terminal_list: [],
            selected_terminal_list: [],
            selected_terminal: {},
            terminal_row_count: 0,
            terminal_list: [
                {
                    value: "",
                    label: "Select Terminal",
                    isDisabled: true,
                },
                { value: "1", label: "Bolt", deviceName: "", serialNumber:"" },
                { value: "2", label: "Ingenico ISC-250", deviceName: "", serialNumber:"" }
            ],
            resellers: [] 
        }
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        else if (localStorage.getItem("login_user_type") != "Admin") {
            this.props.history.goBack();//go(-1);
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.state.merchantID = this.props.location.state ? this.props.location.state.merchantData : sessionStorage.getItem("_MID");
        this._resellerDropdownListingRequest();
        if (this.state.merchantID != null) {
            this._getMerchantDetailRequest(this.state.merchantID)

            this._getSubUserRequest(this.state.merchantID)
        }
    }

    _resellerDropdownListingRequest() {        
        this.setState({ ShowProgressBar: true });
        NetworkService.getResellersDropdownListingRequest()
            .then(data => {
                if (data != null) {
                    if (data.statuscode == 0) {
                         this.setState({ ShowProgressBar: false });
                        if (data.data != null) {
                            this.setResellerResult(data.data.items)
                        }
                    }
                } else {
                     this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);    
    }

    setResellerResult(data) {
        const resellerArr = []
        resellerArr.push({value: "", label: "Select Reseller/ISO", isDisabled: true});
        data.map(data => {
            resellerArr.push({ value: data.id, label: data.name || "", processorType : data.processor_type });
        });
        if(this.state.user.selectedReseller == "" && resellerArr.length >= 1)
        {
            this.setState(prevState => ({
                user: {
                    ...prevState.user,
                    selectedReseller: resellerArr[1]
                }
            }));       
        }
        this.state.resellers = resellerArr;
        this.setState({ resellers: resellerArr });
    }

    handleCreateSubuser(e) {
        this.setState({ IsEditedSubuser: false });
        const x = {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            status: true
        }
        this.setState({ subuserData: x });
        this.setState({ isValidSubuser: false });
        this.setState({ showCreateEditSubuserModal: true });
    }

    handleEditSubuser(e, subuser) {
        e.preventDefault();
        this.setState({ IsEditedSubuser: true })
        this.setState({ subuserEmailState: "valid" });
        this.setState({ subuserFirstNameState: "valid" });
        this.setState({ subuserLastNameState: "valid" });
        this.setState({ subuserPasswordState: "valid" });
        subuser.password = "";
        subuser.status = subuser.status === "Active" ? true : false;
        this.setState({ subuserData: subuser });
        this.setState({ showCreateEditSubuserModal: true });
    }

    handleSubuserPermissions(event, subuser){
        event.preventDefault();
        this.setState({
                SubuserPermissions:
                {
                    invoices: [{ createinvoice: subuser.permissions.invoices != undefined ? subuser.permissions.invoices[0].createinvoice : false },
                    { sendemail: subuser.permissions.invoices != undefined ? subuser.permissions.invoices[1].sendemail : false },
                    { sendsms: subuser.permissions.invoices != undefined ? subuser.permissions.invoices[2].sendsms : false },
                    { collectpayment: subuser.permissions.invoices != undefined ? subuser.permissions.invoices[3].collectpayment : false },
                    { recurring: subuser.permissions.invoices != undefined ? subuser.permissions.invoices[4].recurring : false }],
                    salesreceipt: [{ createsalesreceipt: subuser.permissions.salesreceipt != undefined ? subuser.permissions.salesreceipt[0].createsalesreceipt : false }],
                    payments: [{ viewpayments: subuser.permissions.payments != undefined ? subuser.permissions.payments[0].viewpayments : false }]
                }
            })
        this.setState({ subuserData: subuser });
        this.setState({ showOnlineSubuserPermissionsModal: true });        
    }

    handleCreateEditSubuser(e) {
        e.preventDefault();
        let payload = {
            "merchant_id": this.state.merchantID,
            "email": this.state.subuserData.email,
            "first_name": this.state.subuserData.firstname,
            "last_name": this.state.subuserData.lastname,
            "password": this.state.subuserData.password,
            "is_active": this.state.subuserData.status
        }
        this._postSubUserRequest(payload);
    }

    handleSubuserPermissionsSubmit(e) {
        e.preventDefault();
        let payload = {
            "merchant_id": this.state.merchantID,
            "user_id": this.state.subuserData.id,
            "permissions": JSON.stringify(this.state.SubuserPermissions)
        }
        this._postSubuserPermissionsRequest(payload);
    }

    handleSubUserDefaultPassword(e, subuser) {
        e.preventDefault();
        let params = { "email": subuser.email }
        this._defaultPasswordEmailRequest(params);
    }

    handleResendEmail(e, subuser) {
        // this.props.history.push({
        //     pathname: '/admin/editmerchant',
        //     state: { merchantData: merchant.id }
        // });
        e.preventDefault();
        let params = { "id": subuser.id }
        this._welcomeEmailRequest(params);
    }

    ValidateSubuser() {
        if (this.state.subuserEmailState == "valid" && this.state.subuserFirstNameState == "valid"
            && this.state.subuserLastNameState == "valid" && this.state.subuserPasswordState == "valid") {
            this.setState({ isValidSubuser: true });
        }
        else if (this.state.IsEditedSubuser && this.state.subuserEmailState == "valid" && this.state.subuserFirstNameState == "valid"
            && this.state.subuserLastNameState == "valid") {
            this.setState({ isValidSubuser: true });
        }
        else {
            this.setState({ isValidSubuser: false });
        }

    }

    onFirstNameBlur() {
        if (this.state.firstnameState === "" || this.state.firstnameState === "error") {
            this.setState({
                firstnameErrorMessage: (
                    <small className="text-danger">
                        First Name is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ firstnameState: "valid" });
            this.setState({ firstnameErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    onLastNameBlur() {
        if (this.state.lastnameState === "" || this.state.lastnameState === "error") {
            this.setState({
                lastnameErrorMessage: (
                    <small className="text-danger">
                        Last Name is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ lastnameState: "valid" });
            this.setState({ lastnameErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    onEmailBlur() {
        if (this.state.emailState === "" || this.state.emailState === "error") {
            this.setState({
                emailErrorMessage: (
                    <small className="text-danger">
                        Email is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ emailState: "valid" });
            this.setState({ emailErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    onCompanyNameBlur() {
        if (this.state.companynameState === "" || this.state.companynameState === "error") {
            this.setState({
                companynameErrorMessage: (
                    <small className="text-danger">
                        Company is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ companynameState: "valid" });
            this.setState({ companynameErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    onPhoneNumberBlur() {
        if (this.state.phonenumberState === "" || this.state.phonenumberState === "error") {
            this.setState({
                phonenumberErrorMessage: (
                    <small className="text-danger">
                        Phone Number is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ phonenumberState: "valid" });
            this.setState({ phonenumberErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    onQuickbooksversionChange(version) {
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                quickbooksversion: version
            }
        }));
        this.setState({
            quickbooksversionErrorMessage: null, quickbooksversionState: "valid"
        });

        if (version.value == "1") {  //for QuickBooks Online only
            this.setState({ displayQuickBooksOnline: "" });
            this.setState({ displayStandaloneFeaturesButton: "none" });
            this.setState({
                Permissions:
                {
                    invoices: [{ createinvoice: true }, { sendemail: true }, { sendsms: false }, { collectpayment: true }, { recurring: false }],
                    salesreceipt: [{ createsalesreceipt: true }],
                    payments: [{ viewpayments: true }]
                }
            })
        }
        else if(version.value == "4"){
            this.setState({ displayStandaloneFeaturesButton: "" });
            this.setState({ displayQuickBooksOnline: "none" });
            this.setState({
                Permissions:
                {
                    transactionReport:  [{ viewTransactionReport: false }]
                }
            })
        }
        else {
            this.setState({ displayQuickBooksOnline: "none" });
            this.setState({ Permissions: null });
        }
    }

    onQuickbooksversionBlur() {
        if (this.state.quickbooksversionState === "" || this.state.quickbooksversionState === "error") {
            this.setState({
                quickbooksversionErrorMessage: (
                    <small className="text-danger">
                        QuickBooks Version is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ quickbooksversionState: "valid" });
            this.setState({ quickbooksversionErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    handleOnlinePermissions(e) {
        e.preventDefault();

        this.setState({ showOnlinePermissionsModal: true });
    }

    handleStandalonePermissions(e){
        e.preventDefault();

        this.setState({ showStandalonePermissionsModal: true });
    }

    handlePermissionsChecked(e) {
        const name = e.target.name;
        const value = e.target.checked;
        let invoices = null;
        let invoice = null;
        let salesreceipts = null;
        let salesreceipt = null;
        let payments = null;
        let payment = null;
        let transactionReports = null;
        let transactionReport = null;
        switch (name) {
            case 'createinvoice':
                invoices = [...this.state.Permissions.invoices];
                salesreceipts = [...this.state.Permissions.salesreceipt];
                payments = [...this.state.Permissions.payments];
                invoice = { ...invoices[0] };
                invoice.createinvoice = value;
                invoices[0] = invoice;
                this.setState(prevState => ({
                    Permissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'sendemail':
                invoices = [...this.state.Permissions.invoices];
                salesreceipts = [...this.state.Permissions.salesreceipt];
                payments = [...this.state.Permissions.payments];
                invoice = { ...invoices[1] };
                invoice.sendemail = value;
                invoices[1] = invoice;
                this.setState(prevState => ({
                    Permissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'sendsms':
                invoices = [...this.state.Permissions.invoices];
                salesreceipts = [...this.state.Permissions.salesreceipt];
                payments = [...this.state.Permissions.payments];
                invoice = { ...invoices[2] };
                invoice.sendsms = value;
                invoices[2] = invoice;
                this.setState(prevState => ({
                    Permissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'collectpayment':
                invoices = [...this.state.Permissions.invoices];
                salesreceipts = [...this.state.Permissions.salesreceipt];
                payments = [...this.state.Permissions.payments];
                invoice = { ...invoices[3] };
                invoice.collectpayment = value;
                invoices[3] = invoice;
                this.setState(prevState => ({
                    Permissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'recurring':
                invoices = [...this.state.Permissions.invoices];
                salesreceipts = [...this.state.Permissions.salesreceipt];
                payments = [...this.state.Permissions.payments];
                invoice = { ...invoices[4] };
                invoice.recurring = value;
                invoices[4] = invoice;
                this.setState(prevState => ({
                    Permissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'createsalesreceipt':
                invoices = [...this.state.Permissions.invoices];
                salesreceipts = [...this.state.Permissions.salesreceipt];
                payments = [...this.state.Permissions.payments];
                salesreceipt = { ...salesreceipts[0] };
                salesreceipt.createsalesreceipt = value;
                salesreceipts[0] = salesreceipt;
                this.setState(prevState => ({
                    Permissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'viewpayments':
                invoices = [...this.state.Permissions.invoices];
                salesreceipts = [...this.state.Permissions.salesreceipt];
                payments = [...this.state.Permissions.payments];
                payment = { ...payments[0] };
                payment.viewpayments = value;
                payments[0] = payment;
                this.setState(prevState => ({
                    Permissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
                case 'viewtransactionreport':                   
                    transactionReports = [...this.state.Permissions.transactionReport];
                    transactionReport = { ...transactionReports[0] };
                    transactionReport.viewTransactionReport = value;
                    transactionReports[0] = transactionReport;
                    this.setState(prevState => ({
                        Permissions: {
                            ...prevState.transactionReport,                            
                            transactionReport: transactionReports
                        }
                    }));
                    break;
            default:
                break;
        }
        // this.setState({Permissions: items});
    }
    
    handleSubserPermissionsChecked(e) {
        const name = e.target.name;
        const value = e.target.checked;
        let invoices = null;
        let invoice = null;
        let salesreceipts = null;
        let salesreceipt = null;
        let payments = null;
        let payment = null;
        switch (name) {
            case 'createinvoice':
                invoices = [...this.state.SubuserPermissions.invoices];
                salesreceipts = [...this.state.SubuserPermissions.salesreceipt];
                payments = [...this.state.SubuserPermissions.payments];
                invoice = { ...invoices[0] };
                invoice.createinvoice = value;
                invoices[0] = invoice;
                this.setState(prevState => ({
                    SubuserPermissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'sendemail':
                invoices = [...this.state.SubuserPermissions.invoices];
                salesreceipts = [...this.state.SubuserPermissions.salesreceipt];
                payments = [...this.state.SubuserPermissions.payments];
                invoice = { ...invoices[1] };
                invoice.sendemail = value;
                invoices[1] = invoice;
                this.setState(prevState => ({
                    SubuserPermissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'sendsms':
                invoices = [...this.state.SubuserPermissions.invoices];
                salesreceipts = [...this.state.SubuserPermissions.salesreceipt];
                payments = [...this.state.SubuserPermissions.payments];
                invoice = { ...invoices[2] };
                invoice.sendsms = value;
                invoices[2] = invoice;
                this.setState(prevState => ({
                    SubuserPermissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'collectpayment':
                invoices = [...this.state.SubuserPermissions.invoices];
                salesreceipts = [...this.state.SubuserPermissions.salesreceipt];
                payments = [...this.state.SubuserPermissions.payments];
                invoice = { ...invoices[3] };
                invoice.collectpayment = value;
                invoices[3] = invoice;
                this.setState(prevState => ({
                    SubuserPermissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'recurring':
                invoices = [...this.state.SubuserPermissions.invoices];
                salesreceipts = [...this.state.SubuserPermissions.salesreceipt];
                payments = [...this.state.SubuserPermissions.payments];
                invoice = { ...invoices[4] };
                invoice.recurring = value;
                invoices[4] = invoice;
                this.setState(prevState => ({
                    SubuserPermissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'createsalesreceipt':
                invoices = [...this.state.SubuserPermissions.invoices];
                salesreceipts = [...this.state.SubuserPermissions.salesreceipt];
                payments = [...this.state.SubuserPermissions.payments];
                salesreceipt = { ...salesreceipts[0] };
                salesreceipt.createsalesreceipt = value;
                salesreceipts[0] = salesreceipt;
                this.setState(prevState => ({
                    SubuserPermissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            case 'viewpayments':
                invoices = [...this.state.SubuserPermissions.invoices];
                salesreceipts = [...this.state.SubuserPermissions.salesreceipt];
                payments = [...this.state.SubuserPermissions.payments];
                payment = { ...payments[0] };
                payment.viewpayments = value;
                payments[0] = payment;
                this.setState(prevState => ({
                    SubuserPermissions: {
                        ...prevState.invoices,
                        invoices: invoices,
                        salesreceipt: salesreceipts,
                        payments: payments
                    }
                }));
                break;
            default:
                break;
        }
        // this.setState({Permissions: items});
    }

    onMonthlyinvoicevolumeBlur() {
        if (this.state.monthlyinvoicevolumeState === "" || this.state.monthlyinvoicevolumeState === "error") {
            this.setState({
                monthlyinvoicevolumeErrorMessage: (
                    <small className="text-danger">
                        Invoice Volume is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ monthlyinvoicevolumeState: "valid" });
            this.setState({ monthlyinvoicevolumeErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    onStatusBlur() {
        if (this.state.statusState === "" || this.state.statusState === "error") {
            this.setState({
                statusErrorMessage: (
                    <small className="text-danger">
                        Status is Required.
                    </small>
                )
            });
            this.setState({ isMerchantInfoFormValid: false });
        }
        else {
            this.setState({ statusState: "valid" });
            this.setState({ statusErrorMessage: null });
            this.isMerchantInfoFormValidated();
        }
    }

    onPaymentmethodBlur() {
        if (this.state.paymentmethodState === "" || this.state.paymentmethodState === "error") {
            this.setState({
                paymentmethodErrorMessage: (
                    <small className="text-danger">
                        Payment Method is Required.
                    </small>
                )
            });
            this.setState({ isPaymentInfoFormValid: false });
        }
        else {
            this.setState({ paymentmethodState: "valid" });
            this.setState({ paymentmethodErrorMessage: null });
            this.isPaymentInfoFormValidated();
        }
    }

    onCCMerchantIDKeyBlur() {
        if (this.state.ccMerchantIDState === "" || this.state.ccMerchantIDState === "error" || this.state.user.Processor.CardConnect.MerchantID == "") {
            this.setState({
                ccMerchantIDErrorMessage: (
                    <small className="text-danger">
                        Merchant ID is required.
                    </small>
                )
            });
            this.setState({ isPaymentInfoFormValid: false });
        }
        else {
            this.setState({ ccMerchantIDState: "valid" });
            this.setState({ ccMerchantIDErrorMessage: null });
            this.isPaymentInfoFormValidated();
        }
    }

    onCCUserNameKeyBlur() {
        if (this.state.ccUserNameState === "" || this.state.ccUserNameState === "error") {
            this.setState({
                ccUserNameErrorMessage: (
                    <small className="text-danger">
                        User Name is required.
                    </small>
                )
            });
            this.setState({ isPaymentInfoFormValid: false });
        }
        else {
            this.setState({ ccUserNameState: "valid" });
            this.setState({ ccUserNameErrorMessage: null });
            this.isPaymentInfoFormValidated();
        }
    }
    
    onCCPasswordKeyBlur() {
        if (this.state.ccPasswordState === "" || this.state.ccPasswordState === "error") {
            this.setState({
                ccPasswordErrorMessage: (
                    <small className="text-danger">
                        Password is required.
                    </small>
                )
            });
            this.setState({ isPaymentInfoFormValid: false });
        }
        else {
            this.setState({ ccPasswordState: "valid" });
            this.setState({ ccPasswordErrorMessage: null });
            this.isPaymentInfoFormValidated();
        }
    }

    onNMISecurityKeydBlur() {
        if (this.state.nmiSecurityKeyState === "" || this.state.nmiSecurityKeyState === "error") {
            this.setState({
                nmiSecurityKeyErrorMessage: (
                    <small className="text-danger">
                        Security Key is Required.
                    </small>
                )
            });
            this.setState({ isPaymentInfoFormValid: false });
        }
        else {
            this.setState({ nmiSecurityKeyState: "valid" });
            this.setState({ nmiSecurityKeyErrorMessage: "" });
            this.isPaymentInfoFormValidated();
        }
    }

    onNMITokenizationKeydBlur() {
        if (this.state.nmiTokenizationKeyState === "" || this.state.nmiTokenizationKeyState === "error") {
            this.setState({
                nmiTokenizationKeyErrorMessage: (
                    <small className="text-danger">
                        Tokenization Key is Required.
                    </small>
                )
            });
            this.setState({ isPaymentInfoFormValid: false });
        }
        else {
            this.setState({ nmiTokenizationKeyState: "valid" });
            this.setState({ nmiTokenizationKeyErrorMessage: null });
            this.isPaymentInfoFormValidated();
        }
    }

    onNMICollectCheckoutKeyBlur() {
        if (this.state.nmiCollectCheckoutKeyState === "" || this.state.nmiCollectCheckoutKeyState === "error") {
            this.setState({
                nmiCollectCheckoutKeyErrorMessage: (
                    <small className="text-danger">
                        Collect Checkout Key is Required.
                    </small>
                )
            });
            this.setState({ isPaymentInfoFormValid: false });
        }
        else {
            this.setState({ nmiCollectCheckoutKeyState: "valid" });
            this.setState({ nmiCollectCheckoutKeyErrorMessage: null });
            this.isPaymentInfoFormValidated();
        }
    }

    
    onPaymentMethodCheckBoxChange() {
        if (this.state.paymentMethodType === "NMI")
        {
            if (this.state.user.Processor.NMI.supported_methods.ach === false && this.state.user.Processor.NMI.supported_methods.creditcard === false && this.state.user.Processor.NMI.supported_methods.debitcard === false) {
                this.setState({
                    paymentMethodCheckBoxErrorMessage: (
                        <small className="text-danger">
                            Atleast one transaction must be enable.
                        </small>
                    )
                });
                this.setState({ isPaymentInfoFormValid: false });
            }
            else {
                this.setState({ paymentMethodCheckBoxErrorMessage: "" });
                this.isPaymentInfoFormValidated();
            }
        }
        else if (this.state.paymentMethodType === "CardConnect") {
            if (this.state.ccMerchantIDState === "valid" && this.state.ccUserNameState === "valid" && this.state.ccPasswordState === "valid" 
                && this.state.user.Processor.CardConnect.supported_methods.ach === false && this.state.user.Processor.CardConnect.supported_methods.creditcard === false && this.state.user.Processor.NMI.supported_methods.debitcard === false) {
                this.setState({
                    paymentMethodCheckBoxErrorMessage: (
                        <small className="text-danger">
                            Atleast one transaction must be enable.
                        </small>
                    )
                });
                this.setState({ isPaymentInfoFormValid: false });
            }
            else {
                this.setState({ paymentMethodCheckBoxErrorMessage: "" });
                this.isPaymentInfoFormValidated();
            }
        }
    }

    isMerchantInfoFormValidated() {
        if (this.state.firstnameState === "valid" && this.state.lastnameState === "valid" && this.state.emailState === "valid"
            && this.state.companynameState === "valid" && this.state.phonenumberState === "valid" && this.state.quickbooksversionState === "valid"
            && this.state.monthlyinvoicevolumeState === "valid" && this.state.statusState === "valid") {
            this.setState({ isMerchantInfoFormValid: true });
        }
        else {
            this.setState({ isMerchantInfoFormValid: false });
            return false;
        }
        return this.state.isMerchantInfoFormValid;
    }

    isPaymentInfoFormValidated() {
        this.setState({ isPaymentInfoFormValid: false });         
        if (this.state.paymentmethodState === "valid") {
            if (this.state.paymentMethodType === "NMI") {
                if (this.state.nmiSecurityKeyState === "valid" && this.state.nmiTokenizationKeyState === "valid" &&
                    //(this.state.user.Processor.NMI.supported_methods.threestep == false || (this.state.user.Processor.NMI.supported_methods.threestep == true && this.state.nmiCollectCheckoutKeyState === "valid")) &&  
                    (this.state.user.Processor.NMI.supported_methods.gatewayJS == false || (this.state.user.Processor.NMI.supported_methods.gatewayJS == true && this.state.nmiCollectCheckoutKeyState === "valid")) &&                      
                    (this.state.user.Processor.NMI.supported_methods.ach == true || this.state.user.Processor.NMI.supported_methods.creditcard == true || this.state.user.Processor.NMI.supported_methods.debitcard == true )) {
                    this.setState({ isPaymentInfoFormValid: true });
                    return true;
                }
            }
            else if (this.state.paymentMethodType === "CardConnect") {
                if ((this.state.ccMerchantIDState === "valid" && this.state.ccUserNameState === "valid" && this.state.ccPasswordState === "valid") && (this.state.user.Processor.CardConnect.supported_methods.ach == true || this.state.user.Processor.CardConnect.supported_methods.creditcard == true || this.state.user.Processor.CardConnect.supported_methods.debitcard == true)) {
                    this.setState({ isPaymentInfoFormValid: true });
                    return true;
                }
            }
            // else if (this.state.paymentMethodType === "AuthorizeNet") {
            // }

        }        
        return this.state.isPaymentInfoFormValid;
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                [stateName]: value
            }
        }));

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handleCancelCreateMerchant(e) {
        this.props.history.push('/admin/merchants');
        e.preventDefault();
    }

    handleImageUploadMerchant(value) {
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                image: value
            }
        }));
        this.setState(prevState => ({
            isImageChange: {
                ...prevState.isImageChange,
                isImageChange: true
            }
        }));
    }

    validateSubuserEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            subuserData: {
                ...prevState.subuserData,
                email: value
            }
        }));
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            this.setState({
                subuserEmailErrorMessage: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )

            });
            this.setState({
                subuserEmailState: "error"
            });

        }
        else {
            this.setState({ subuserEmailErrorMessage: null });
            this.setState({ subuserEmailState: "valid" });
        }
    }

    ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                emailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });
            this.setState({
                emailState: "error"
            });
        }
        else {
            this.setState({ emailState: "valid" });
            this.setState({ emailErrorMessage: null });
        }
    }

    handleSetPaymentMethod(event) {
        const { label, value } = event;
        this.setState({ paymentMethodType: value });
        switch (value) {
            case "NMI":
                break;
            case "CardConnect":
                break;

            case "AuthorizeNet":
                break;
            default:
                break;
        }
    }

    createTerminalUI() {
        if(this.state.add_terminal_list != undefined)
        {
            return this.state.add_terminal_list.map((el, i) =>
                <div key={i}>
                    <Row>
                        <Col md={3}>
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                //value={this.state.selected_terminal_list[i].selected_terminal}
                                value={this.state.user.paymentMethodType.value == "NMI" ? this.state.terminal_list[2] : this.state.terminal_list[1]}
                                onChange={value => {
                                    let values = [...this.state.selected_terminal_list];
                                    values[i].selected_terminal = value;
                                    this.setState({ selected_terminal_list: values });
                                }}
                                options={this.state.terminal_list}
                                placeholder="Select Terminal"
                            />                       
                        </Col>
                        <Col md={3}>
                            <FormControl
                                type="text"
                                value={this.state.selected_terminal_list[i].deviceName}
                                placeholder="Device Name"
                                onChange={this.handlerTerminalName.bind(this, i)}
                            />                    
                        </Col>
                        <Col md={3}>
                            <FormControl
                                type="text"
                                value={this.state.selected_terminal_list[i].serialNumber}
                                placeholder="Serial Number/HSN"
                                onChange={this.handlerSerialNumberChange.bind(this, i)}
                            />
                        </Col>
                        <Col md={3}>
                            {/* <input type="text" value={this.state.selected_terminal_list[i].name} onChange={this.handlerTerminalName.bind(this, i)} /> */}
                            <Button className="btn btn-danger btn-round btn-icon" onClick={this.handlerRemoveTerminal.bind(this, i)} >
                                <i class="fa fa-trash" aria-hidden="true" ></i>
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    handlerTerminalName(i, event) {
        let values = [...this.state.selected_terminal_list];
        values[i].deviceName = event.target.value;
        this.setState({ values });
    }

    handlerSerialNumberChange(i, event) {
        let values = [...this.state.selected_terminal_list];
        values[i].serialNumber = event.target.value;
        this.setState({ values });
    }

    handlerAddTerminal(event) {
        const newRow = { selected_terminal:{}, deviceName: "", serialNumber: "", poiDeviceId: "", deviceNickname: "", registrationStatus: "unregistered", hsn: "", deviceLicense: "" }
        if(this.state.selected_terminal_list == undefined){
            this.state.selected_terminal_list = [];
        }
        this.state.selected_terminal_list.push(newRow);
        let addTerminals = this.state.selected_terminal_list;
        this.setState({add_terminal_list: addTerminals})
        event.preventDefault();
    }

    handlerRemoveTerminal(i) {
        let add_terminal_list = [...this.state.add_terminal_list];
        add_terminal_list.splice(i, 1);
        this.setState({ add_terminal_list });

        let selected_terminal_list = [...this.state.selected_terminal_list];
        selected_terminal_list.splice(i, 1);
        this.setState({ selected_terminal_list });
    }

    setResult(items) {
        let user = { ...this.state.user }
        user.firstname = items.user_detail.first_name || "";
        user.lastname = items.user_detail.last_name || "";
        user.email = items.user_detail.email || "";
        user.website = items.user_detail.website || "";
        user.status = { value: items.user_detail.is_active.value, label: items.user_detail.is_active.name }
        user.quickbooksversion = items.user_detail.accounting_software ? { value: items.user_detail.accounting_software.value, label: items.user_detail.accounting_software.name } : { value: null, label: null }
        user.monthlyinvoicevolume = items.user_detail.invoice_volume ? { value: items.user_detail.invoice_volume.value, label: items.user_detail.invoice_volume.name } : { value: null, label: null }
        var reseller = this.state.resellers[1];
        if(this.state.resellers.length > 1)
        {
            this.state.resellers.map(res =>{
                if(res.value == items.user_detail.reseller_id)                
                    reseller = res;                
            });
        }
        user.selectedReseller = reseller
        user.companyname = items.user_detail.company_name || "";
        user.phonenumber = items.user_detail.phone_number || "";
        user.image = items.user_detail.logo;
        if (user.quickbooksversion.value == "1") {
            this.setState({ displayQuickBooksOnline: "" })
            this.setState({displayStandaloneFeaturesButton: "none"})
            this.setState({
                Permissions:
                {
                    invoices: [{ createinvoice: items.permissions.invoices != undefined ? items.permissions.invoices[0].createinvoice : false },
                    { sendemail: items.permissions.invoices != undefined ? items.permissions.invoices[1].sendemail : false },
                    { sendsms: items.permissions.invoices != undefined ? items.permissions.invoices[2].sendsms : false },
                    { collectpayment: items.permissions.invoices != undefined ? items.permissions.invoices[3].collectpayment : false },
                    { recurring: items.permissions.invoices != undefined && items.permissions.invoices[4] != undefined ? items.permissions.invoices[4].recurring : false }],
                    salesreceipt: [{ createsalesreceipt: items.permissions.salesreceipt != undefined ? items.permissions.salesreceipt[0].createsalesreceipt : false }],
                    payments: [{ viewpayments: items.permissions.payments != undefined ? items.permissions.payments[0].viewpayments : false }]
                }
            })
        }
        else if(user.quickbooksversion.value == "4"){
            this.setState({ displayQuickBooksOnline: "none" })
            this.setState({displayStandaloneFeaturesButton: ""})
            this.setState({
                Permissions:
                {
                    transactionReport: [{ viewTransactionReport: items.permissions.transactionReport != undefined ? items.permissions.transactionReport[0].viewTransactionReport : false }]                    
                }
            })
        }

        let processor = user.Processor
        let processor_detail = items.processor_detail
        if (processor_detail != null) {

            switch (processor_detail.ProcessorType) {
                case "nmi":
                    if (processor_detail.ProcessorConfig != null) {
                        processor.NMI.SecurityKey = processor_detail.ProcessorConfig.SecurityKey
                        processor.NMI.Password = processor_detail.ProcessorConfig.Password
                        processor.NMI.TokenizationKey = processor_detail.ProcessorConfig.TokenizationKey
                        processor.NMI.CollectCheckoutKey = processor_detail.ProcessorConfig.CollectCheckoutKey
                        processor.NMI.UserName = processor_detail.ProcessorConfig.UserName
                        processor.NMI.supported_methods.ach = processor_detail.ProcessorConfig.supported_methods.ach
                        processor.NMI.supported_methods.creditcard = processor_detail.ProcessorConfig.supported_methods.creditcard
                        processor.NMI.supported_methods.debitcard = processor_detail.ProcessorConfig.supported_methods.debitcard
                        processor.NMI.supported_methods.threestep = processor_detail.ProcessorConfig.supported_methods.threestep
                        processor.NMI.supported_methods.gatewayJS = processor_detail.ProcessorConfig.supported_methods.gatewayJS
                        processor.NMI.supported_methods.quickclick = processor_detail.ProcessorConfig.supported_methods.quickclick  
                        processor.MethodID = processor_detail.ProcessorID;
                        this.setState({add_terminal_list: processor_detail.ProcessorConfig.Terminals});
                        this.setState({selected_terminal_list:  processor_detail.ProcessorConfig.Terminals});
                        processor.NMI.Terminals = processor_detail.ProcessorConfig.Terminals
                        user.paymentMethodType = { value: "NMI", label: "NMI" }
                        this.handleSetPaymentMethod(user.paymentMethodType)
                    }
                    break;

                case "cardconnect":
                    if (processor_detail.ProcessorConfig != null) {
                        processor.CardConnect.UserName = (processor_detail.ProcessorConfig.UserName == "" || processor_detail.ProcessorConfig.UserName == null) ? USER_NAME : processor_detail.ProcessorConfig.UserName
                        processor.CardConnect.Password = (processor_detail.ProcessorConfig.Password == "" || processor_detail.ProcessorConfig.Password == null) ? PASSWORD : processor_detail.ProcessorConfig.Password 
                        processor.CardConnect.MerchantID = processor_detail.ProcessorConfig.MerchantID
                        processor.CardConnect.URL = (processor_detail.ProcessorConfig.URL == "" || processor_detail.ProcessorConfig.URL == null) ? GATEWAY_API_URL : processor_detail.ProcessorConfig.URL
                        processor.CardConnect.supported_methods.ach = processor_detail.ProcessorConfig.supported_methods.ach
                        processor.CardConnect.supported_methods.creditcard = processor_detail.ProcessorConfig.supported_methods.creditcard
                        processor.CardConnect.supported_methods.debitcard = processor_detail.ProcessorConfig.supported_methods.debitcard
                        processor.MethodID = processor_detail.ProcessorID
                        if (processor_detail.ProcessorConfig.Bolt != null){
                            processor.CardConnect.Bolt.authorization = processor_detail.ProcessorConfig.Bolt.authorization
                            processor.CardConnect.Bolt.hsn = processor_detail.ProcessorConfig.Bolt.hsn
                            processor.CardConnect.Bolt.url = processor_detail.ProcessorConfig.Bolt.url
                        }
                        user.paymentMethodType = { value: "CardConnect", label: "Card Connect" }
                        this.handleSetPaymentMethod(user.paymentMethodType)  
                    }
                    break;
                case "AuthorizeNet":
                    break;

                default:
                    break;
            }
        }

        this.setState({ user })
    }

    setSubUserResult(items) {
        let user = { ...this.state.user }
        const newSeries = [];
        items.map(value => {
            const x =
            {
                id: value.id,
                firstname: value.first_name,
                lastname: value.last_name,
                email: value.email,
                password: value.password,
                status: value.is_active ? <Chip style={{ backgroundColor: "#2196f3", color: "white", height: 25 }} label="Active" variant="outlined"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="InActive"></Chip>,
                //NEED TO SET PERMISSIONS FROM SERVER..... AHSAN WILL DO
                permissions:
                {
                    invoices: [{ createinvoice: true }, { sendemail: true }, { sendsms: false }, { collectpayment: true }, { recurring: false }],
                    salesreceipt: [{ createsalesreceipt: true }],
                    payments: [{ viewpayments: true }]
                }                
            }

            newSeries.push(x)
        })
        user.Subusers = newSeries;
        this.setState({ user });
    }

    callingMerchantInfoUpdate() {
        if (this.isMerchantInfoFormValidated()) {
            let formData = new FormData();
            formData.append("user_detail", JSON.stringify({
                "user_id": this.state.merchantID,
                "first_name": this.state.user.firstname,
                "last_name": this.state.user.lastname,
                "email": this.state.user.email,
                "phone_number": this.state.user.phonenumber,
                "company_name": this.state.user.companyname,
                "website": this.state.user.website,
                "accounting_software": this.state.user.quickbooksversion.value,
                "invoice_volume": this.state.user.monthlyinvoicevolume.value,
                "reseller_id": this.state.user.selectedReseller.value,
                "is_active": this.state.user.status.value == 1 ? true : false
            }));
            formData.append("permissions", JSON.stringify(this.state.Permissions))
            formData.append("file", this.state.user.image);
            this._postMerchantInfoSubmit(formData)
        }
    }

    callingProcessorInfoUpdate() {
        if (this.isPaymentInfoFormValidated()) {
            let processor_detail = {}
            let formData = new FormData();
            const terminals = [];
            if(this.state.selected_terminal_list != undefined && this.state.selected_terminal_list.length > 0){
                this.state.selected_terminal_list.map(data => {
                    const x = {
                                deviceName: data.deviceName,
                                poiDeviceId: data.poiDeviceId !=null ? data.poiDeviceId:"",
                                serialNumber: data.serialNumber !=null ? data.serialNumber:"",
                                deviceLicense: data.deviceLicense !=null ? data.deviceLicense:"",
                                deviceNickname: data.deviceName,
                                registrationStatus: data.registrationStatus !=null ? data.registrationStatus:"unregistered",
                                hsn: data.serialNumber !=null ? data.serialNumber:"",
                                url: "",
                                authorization: ""
                    }            
                    terminals.push(x);
                });  
            }
            this.state.user.Processor[this.state.user.paymentMethodType.value].Terminals = terminals;
            if (this.state.user.paymentMethodType.value === "CardConnect") {
                formData.append("processor_detail", JSON.stringify({
                    "user_id": this.state.merchantID,
                    "method_id": 1,
                    "configuration": this.state.user.Processor.CardConnect,
                    "is_active": true
                }));
            } else if (this.state.user.paymentMethodType.value === "NMI") {
                formData.append("processor_detail", JSON.stringify({
                    "user_id": this.state.merchantID,
                    "method_id": 2,
                    "configuration": this.state.user.Processor.NMI,
                    "is_active": true
                }));
            }

           this._postProcessorInfoSubmit(formData)
        }
    }

    

    callingPermissionInfoUpdate() {
        let formData = new FormData();
        formData.append("permissions", JSON.stringify({
            "user_id": this.state.merchantID,
            "accounting_software": this.state.user.quickbooksversion.value,
            "permissions": this.state.Permissions
        }));
        this._postPermissionInfoSubmit(formData)
    }
    /*** API CALLING */

    /** GET request to fetch merchant specific information */
    _getMerchantDetailRequest = (mid) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        NetworkService.getMerchantDetailRequest(null, mid)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    // this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            if (data.data.items != undefined) {
                                this.setResult(data.data.items)
                            }
                        }
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postMerchantInfoSubmit = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorRegistrationMessage: "" });

        NetworkService.postMerchantDetailRequest(data)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.setState({ errorMessage: "Merchant's  information updated" });
                            if (data.data.items != undefined) {
                                this.setResult(data.data.items);
                            }
                        }
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postProcessorInfoSubmit = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorRegistrationMessage: "" });
        NetworkService.postMerchantDetailRequest(data)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.setState({ errorMessage: "Merchant's processor setting updated" });
                            if (data.data.items != undefined) {
                                this.setResult(data.data.items)
                            }
                        }
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postPermissionInfoSubmit = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorRegistrationMessage: "" });
        NetworkService.postMerchantDetailRequest(data)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ showOnlinePermissionsModal: false })

                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.setState({ errorMessage: "Merchant feature permissions updated" });
                            if (data.data.items != undefined) {
                                this.setResult(data.data.items)
                            }
                        }
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ showOnlinePermissionsModal: false })
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _getSubUserRequest = (mid) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorRegistrationMessage: "" });
        NetworkService.getSubuserRequest(mid)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this.setSubUserResult(data.data.items)
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postSubUserRequest = (data) => {
        this.setState({ ShowPopupProgressBar: true });
        NetworkService.postSubUserRequest(data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this._getSubUserRequest(this.state.merchantID)
                    }
                    this.setState({ showCreateEditSubuserModal: false });
                } else {
                    this.setState({ showCreateEditSubuserModal: false });
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showCreateEditSubuserModal: false });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postSubuserPermissionsRequest = (data) => {
        this.setState({ ShowPopupProgressBar: true });
        //NEED TO CALL FOR THIS POST... AHSAN WILL DO
        NetworkService.postSubUserPermissionsRequest(data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this._getSubUserRequest(this.state.merchantID)
                    }
                    this.setState({ showOnlineSubuserPermissionsModal: false });
                } else {
                    this.setState({ showOnlineSubuserPermissionsModal: false });
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showOnlineSubuserPermissionsModal: false });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _welcomeEmailRequest(body) {
        this.setState({ ShowProgressBar: true });
        NetworkService.postMerchantWelcomeEmailRequest(body)
            .then(data => {
                if (data != null) {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                } else {
                    this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);
    }

    _defaultPasswordEmailRequest(body) {
        this.setState({ ShowProgressBar: true });
        NetworkService.postDefaultPasswordEmailRequest(body)
            .then(data => {
                if (data != null) {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                } else {
                    this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);
    }


    render() {
        const marginBottomStyle = {
            marginBottom: "10px"
        };
        const editButtonToolTip = <Tooltip id="refundToolTip">Edit</Tooltip>;
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.ShowProgressBar &&
                                <div style={{ marginBottom: "2px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                            {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <br />
                            <Card
                                title="Merchant Info"
                                content={
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup >
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Template/Company Logo: <span className="star">*</span>
                                                            </div>
                                                            <Card
                                                                textCenter
                                                                content={
                                                                    <div className="text-center">
                                                                        {this.state.isImageChange ?
                                                                            <ImageUpload
                                                                                isMerchantLogo={false}
                                                                                parentComponent={data => this.handleImageUploadMerchant(data)} />
                                                                            :
                                                                            <ImageUpload
                                                                                isMerchantLogo={true}
                                                                                merchantLogo={this.state.user.image}
                                                                                parentComponent={data => this.handleImageUploadMerchant(data)} />
                                                                        }
                                                                    </div>
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={8}>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                First Name: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="First Name"
                                                                name="firstname"
                                                                className={this.state.firstnameState}
                                                                value={this.state.user.firstname}
                                                                onChange={event => {
                                                                    this.change(event, "firstname", "length", 2)
                                                                }
                                                                }
                                                                onBlur={e => this.onFirstNameBlur()}
                                                            />
                                                            {this.state.firstnameErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Last Name: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Last Name"
                                                                autoComplete="off"
                                                                name="lastname"
                                                                className={this.state.lastnameState}
                                                                value={this.state.user.lastname}
                                                                onChange={event =>
                                                                    this.change(event, "lastname", "length", 2)
                                                                }
                                                                onBlur={e => this.onLastNameBlur()}
                                                            />
                                                            {this.state.lastnameErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Email adress: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="email"
                                                                placeholder="Enter Email"
                                                                name="email"
                                                                className={this.state.emailState}
                                                                value={this.state.user.email}
                                                                onChange={event =>
                                                                    // this.setState({ user: { email: event.target.value } })
                                                                    this.ValidateEmailAddress(event)
                                                                }
                                                                onBlur={e => this.onEmailBlur()}
                                                            />
                                                            {this.state.emailErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Company: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Company"
                                                                name="companyname"
                                                                className={this.state.companynameState}
                                                                value={this.state.user.companyname}
                                                                onChange={event =>
                                                                    //this.setState({ user: { companyname: event.target.value } })
                                                                    this.change(event, "companyname", "length", 3)
                                                                }
                                                                onBlur={e => this.onCompanyNameBlur()}
                                                            />
                                                            {this.state.companynameErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Phone Number: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="number"
                                                                placeholder="Phone Number"
                                                                className={this.state.user.phonenumberState}
                                                                name="phonenumber"
                                                                value={this.state.user.phonenumber}
                                                                onChange={event =>
                                                                    //this.setState({ user: { phonenumber: event.target.value } })
                                                                    this.change(event, "phonenumber", "length", 7)
                                                                }
                                                                onBlur={e => this.onPhoneNumberBlur()}
                                                            />
                                                            {this.state.phonenumberErrorMessage}

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Website:
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Website"
                                                                name="website"
                                                                value={this.state.user.website}
                                                                onChange={event => {
                                                                    const value = event.target.value;
                                                                    this.setState(prevState => ({
                                                                        user: {
                                                                            ...prevState.user,
                                                                            website: value
                                                                        }
                                                                    }));
                                                                }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Monthly Invoice Volume: <span className="star">*</span>
                                                            </div>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="monthlyinvoicevolume"
                                                                value={this.state.user.monthlyinvoicevolume}
                                                                onChange={value =>
                                                                //this.setState({ user: { monthlyinvoicevolume: value } })
                                                                {
                                                                    this.setState(prevState => ({
                                                                        user: {
                                                                            ...prevState.user,
                                                                            monthlyinvoicevolume: value
                                                                        }
                                                                    }));
                                                                    this.setState({
                                                                        monthlyinvoicevolumeErrorMessage: null, monthlyinvoicevolumeState: "valid"
                                                                    });
                                                                }
                                                                }
                                                                onBlur={e => this.onMonthlyinvoicevolumeBlur()}
                                                                options={[
                                                                    {
                                                                        value: "",
                                                                        label: "Monthly Invoice Volume",
                                                                        isDisabled: true
                                                                    },
                                                                    { value: "1", label: "Less than $25K" },
                                                                    { value: "2", label: "$25K - $100K" },
                                                                    { value: "3", label: "$100K - $250K" },
                                                                    { value: "4", label: "more than $250K" }

                                                                ]}
                                                                placeholder="Monthly Invoice Volume"
                                                            />
                                                            {this.state.monthlyinvoicevolumeErrorMessage}

                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Application Version: <span className="star">*</span>
                                                            </div>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="quickbooksversion"
                                                                value={this.state.user.quickbooksversion}
                                                                onChange={e => { this.onQuickbooksversionChange(e) }}
                                                                onBlur={e => this.onQuickbooksversionBlur()}
                                                                options={[
                                                                    {
                                                                        value: "",
                                                                        label: "Application Version",
                                                                        isDisabled: true
                                                                    },
                                                                    { value: "1", label: "QuickBooks Online" },
                                                                    { value: "2", label: "QuickBooks Desktop" },
                                                                    { value: "3", label: "QuickBooks POS" },
                                                                    { value: "4", label: "Standalone Invoicing" }
                                                                ]}
                                                                placeholder="Application Version"
                                                            />
                                                            {this.state.quickbooksversionErrorMessage}


                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Status: <span className="star">*</span>
                                                            </div>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="status"
                                                                value={this.state.user.status}
                                                                onChange={value =>
                                                                //this.setState({ user: { monthlyinvoicevolume: value } })
                                                                {
                                                                    this.setState(prevState => ({
                                                                        user: {
                                                                            ...prevState.user,
                                                                            status: value
                                                                        }
                                                                    }));
                                                                    this.setState({
                                                                        statusErrorMessage: null, statusState: "valid"
                                                                    });
                                                                }
                                                                }
                                                                onBlur={e => this.onStatusBlur()}
                                                                options={[
                                                                    {
                                                                        value: "",
                                                                        label: "Select Status",
                                                                        isDisabled: true
                                                                    },
                                                                    { value: 0, label: "Active" },
                                                                    { value: 1, label: "Inactive" }

                                                                ]}
                                                                placeholder="Status"
                                                            />
                                                            {this.state.statusErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <div style={{ marginTop: '5px', fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Reseller/ISO:
                                                            </div>
                                                            <Select

                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="resellers"
                                                                value={this.state.user.selectedReseller}
                                                                onChange={value =>
                                                                    this.setState(prevState => ({
                                                                        user: {
                                                                            ...prevState.user,
                                                                            selectedReseller: value
                                                                        }
                                                                    }))                                                                  
                                                                }
                                                                options={this.state.resellers}
                                                                placeholder="Select Receller/ISO"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px", display: this.state.displayQuickBooksOnline }} onClick={e => this.handleOnlinePermissions(e)}>
                                                            <i className="fa fa-th-large"></i>Features Permission
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px", display: this.state.displayStandaloneFeaturesButton }} onClick={e => this.handleStandalonePermissions(e)}>
                                                            <i className="fa fa-th-large"></i>Features Permission
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <br />
                                        <br />
                                        <Row>
                                            <Col md={12}>
                                                <div style={{ textAlign: "right", marginBottom: "20px" }}>
                                                    <Button bsStyle="primary" fill onClick={e => this.callingMerchantInfoUpdate()} disabled={!this.state.isMerchantInfoFormValid}>
                                                        Update Merchant Info
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                }

                            />
                            <br />
                            <Card
                                title="Processor Info"
                                content={
                                    <div>
                                        <Row>

                                            <Col md={4}>
                                                <FormGroup style={marginBottomStyle}>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Payment Method/Processor: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        name="paymentmethod"
                                                        value={this.state.user.paymentMethodType}
                                                        onChange={event => {
                                                            this.handleSetPaymentMethod(event);
                                                            this.setState({
                                                                paymentmethodErrorMessage: null, paymentmethodState: "valid"
                                                            });
                                                            this.setState(prevState => ({
                                                                user: {
                                                                    ...prevState.user,
                                                                    paymentMethodType: event
                                                                }
                                                            }));
                                                        }
                                                        }
                                                        onBlur={e => this.onPaymentmethodBlur()}
                                                        options={[
                                                            {
                                                                value: "",
                                                                label: "Select Payment Method",
                                                                isDisabled: true
                                                            },
                                                            { value: "NMI", label: "NMI" },
                                                            { value: "CardConnect", label: "Card Connect" },
                                                            // { value: "AuthorizeNet", label: "Authorize Net" }

                                                        ]}
                                                        placeholder="Payment Method"
                                                    />
                                                    {this.state.paymentmethodErrorMessage}

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {this.state.paymentMethodType === "NMI" &&
                                            <div>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>                                                            
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Security Key: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                value={this.state.user.Processor.NMI.SecurityKey}
                                                                placeholder="Security Key"
                                                                className={this.state.nmiSecurityKeyState}
                                                                onChange={event => {
                                                                    this.change(event, "nmiSecurityKey", "length", 5)
                                                                    this.state.user.Processor.NMI.SecurityKey = event.target.value;
                                                                    const nmiModel = { ...this.state.user.Processor.NMI };
                                                                    nmiModel.SecurityKey = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.NMI, nmiModel })
                                                                }
                                                                }
                                                                onBlur={e => this.onNMISecurityKeydBlur()}
                                                            />
                                                            {this.state.nmiSecurityKeyErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>                                                            
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Tokenization Key: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                value={this.state.user.Processor.NMI.TokenizationKey}
                                                                placeholder="Tokenization Key"
                                                                className={this.state.nmiTokenizationKeyState}
                                                                onChange={event => {
                                                                    this.change(event, "nmiTokenizationKey", "length", 5)
                                                                    this.state.user.Processor.NMI.TokenizationKey = event.target.value;
                                                                    const nmiModel = { ...this.state.user.Processor.NMI };
                                                                    nmiModel.TokenizationKey = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.NMI, nmiModel })
                                                                }
                                                                }
                                                                onBlur={e => this.onNMITokenizationKeydBlur()}
                                                            />
                                                            {this.state.nmiTokenizationKeyErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    {this.state.user.Processor.NMI.supported_methods.gatewayJS == true &&                                                    
                                                        <Col md={4}>
                                                            <FormGroup style={marginBottomStyle}>
                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                    Collect Checkout Key: <span className="star">*</span>
                                                                </div>
                                                                <FormControl
                                                                    value={this.state.user.Processor.NMI.CollectCheckoutKey}
                                                                    placeholder="Collect Checkout Key"
                                                                    className={this.state.nmiCollectCheckoutKeyState}
                                                                    onChange={event => {
                                                                        this.change(event, "nmiCollectCheckoutKey", "length", 5)
                                                                        this.state.user.Processor.NMI.CollectCheckoutKey = event.target.value;
                                                                        const nmiModel = { ...this.state.user.Processor.NMI };
                                                                        nmiModel.CollectCheckoutKey = event.target.value;
                                                                        this.setState({ ...this.state.user.Processor.NMI, nmiModel })
                                                                    }
                                                                    }
                                                                    onBlur={e => this.onNMICollectCheckoutKeyBlur()}
                                                                />
                                                                {this.state.nmiCollectCheckoutKeyErrorMessage}
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="NMICreditCardCheckBox"
                                                                checked={this.state.user.Processor.NMI.supported_methods.creditcard}
                                                                labelText={"Allow Credit Card"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                       this.state.user.Processor.NMI.supported_methods.creditcard = event.target.checked;
                                                                       const nmiModel = { ...this.state.user.Processor.NMI };
                                                                       nmiModel.supported_methods.creditcard = event.target.checked;
                                                                       this.setState({ ...this.state.user.Processor.NMI, nmiModel }); 
                                                                       this.onPaymentMethodCheckBoxChange();                                                                    
                                                                    }

                                                                }}
                                                            />
                                                            {this.state.paymentMethodCheckBoxErrorMessage}
                                                        </FormGroup>
                                                    </Col>      
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="NMIDebitCardCheckBox"
                                                                checked={this.state.user.Processor.NMI.supported_methods.debitcard}
                                                                labelText={"Allow Debit Card"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                       this.state.user.Processor.NMI.supported_methods.debitcard = event.target.checked;
                                                                       const nmiModel = { ...this.state.user.Processor.NMI };
                                                                       nmiModel.supported_methods.debitcard = event.target.checked;
                                                                       this.setState({ ...this.state.user.Processor.NMI, nmiModel }); 
                                                                       this.onPaymentMethodCheckBoxChange();                                                                    
                                                                    }

                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>                                              
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="NMIACHCheckBox"
                                                                checked={this.state.user.Processor.NMI.supported_methods.ach}
                                                                labelText={"Allow ACH"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.NMI.supported_methods.ach = event.target.checked;
                                                                        const nmiModel = { ...this.state.user.Processor.NMI };
                                                                        nmiModel.supported_methods.ach = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.NMI, nmiModel });
                                                                        this.onPaymentMethodCheckBoxChange();
                                                                    }

                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>                                                    
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="NMIGatewayJSCheckBox"
                                                                checked={this.state.user.Processor.NMI.supported_methods.gatewayJS}
                                                                labelText={"Enable Authentication"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.NMI.supported_methods.gatewayJS = event.target.checked;
                                                                        const nmiModel = { ...this.state.user.Processor.NMI };
                                                                        nmiModel.supported_methods.gatewayJS = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.NMI, nmiModel });
                                                                        this.onPaymentMethodCheckBoxChange();
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col> 
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="NMIQuickClickCheckBox"
                                                                checked={this.state.user.Processor.NMI.supported_methods.quickclick}
                                                                labelText={"NMI QuickClick"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.NMI.supported_methods.quickclick = event.target.checked;
                                                                        const nmiModel = { ...this.state.user.Processor.NMI };
                                                                        nmiModel.supported_methods.quickclick = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.NMI, nmiModel });
                                                                        this.onPaymentMethodCheckBoxChange();
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>         
                                                    {/* <Col md={3}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="NMIThreeStepCheckBox"
                                                                checked={this.state.user.Processor.NMI.supported_methods.threestep}
                                                                labelText={"Enable 3 Step"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.NMI.supported_methods.threestep = event.target.checked;
                                                                        const nmiModel = { ...this.state.user.Processor.NMI };
                                                                        nmiModel.supported_methods.threestep = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.NMI, nmiModel });
                                                                        this.onPaymentMethodCheckBoxChange();
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>                                                     */}
                                                </Row>
                                                <Row>
                                                    <Col md={3}>
                                                        <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={e => this.handlerAddTerminal(e)}>
                                                            <i className="fa fa-usb"></i>Add Hardware/Terminal
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <br/>
                                                        {this.createTerminalUI()}
                                                    </Col>
                                                </Row>
                                            </div>}
                                        {this.state.paymentMethodType === "CardConnect" &&
                                            <div>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <ControlLabel>Merchant ID <span className="star">*</span></ControlLabel>
                                                            <FormControl
                                                                // value={this.state.Processor.CardConnect.MerchantID}
                                                                placeholder="Merchant ID"
                                                                value={this.state.user.Processor.CardConnect.MerchantID}
                                                                className={this.state.ccMerchantIDState}
                                                                onChange={event => {
                                                                    this.change(event, "ccMerchantID", "length", 5);
                                                                    this.state.user.Processor.CardConnect.MerchantID = event.target.value;
                                                                    const cardconnectModel = { ...this.state.user.Processor.CardConnect }
                                                                    cardconnectModel.MerchantID = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.CardConnect, cardconnectModel })
                                                                }
                                                                }
                                                                onBlur={e => this.onCCMerchantIDKeyBlur()}
                                                            />
                                                            {this.state.ccMerchantIDErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                User Name: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                className={this.state.ccUserNameState}
                                                                placeholder="User Name"
                                                                value={this.state.user.Processor.CardConnect.UserName}
                                                                onChange={event => {
                                                                    this.change(event, "ccUserName", "length", 5);
                                                                    this.state.user.Processor.CardConnect.UserName = event.target.value;
                                                                    const cardconnectModel = { ...this.state.user.Processor.CardConnect }
                                                                    cardconnectModel.UserName = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.CardConnect, cardconnectModel })
                                                                }
                                                                }
                                                                onBlur={e => this.onCCUserNameKeyBlur()}
                                                            />
                                                            {this.state.ccUserNameErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Password: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                className={this.state.ccPasswordState}
                                                                placeholder="Password"
                                                                value={this.state.user.Processor.CardConnect.Password}
                                                                onChange={event => {
                                                                    this.change(event, "ccPassword", "length", 5);
                                                                    this.state.user.Processor.CardConnect.Password = event.target.value;
                                                                    const cardconnectModel = { ...this.state.user.Processor.CardConnect }
                                                                    cardconnectModel.Password = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.CardConnect, cardconnectModel })
                                                                }
                                                                }
                                                                onBlur={e => this.onCCPasswordKeyBlur()}
                                                            />
                                                            {this.state.ccPasswordErrorMessage}
                                                        </FormGroup>
                                                    </Col> 
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <ControlLabel>Bolt API Key/Authorization</ControlLabel>
                                                            <FormControl
                                                                placeholder="API Key/Authorization"
                                                                value={this.state.user.Processor.CardConnect.Bolt.authorization}
                                                                onChange={event => {
                                                                    this.change(event, "CardConnectAuthorization", "length", 5);
                                                                    this.state.user.Processor.CardConnect.Bolt.authorization = event.target.value;
                                                                    const cardconnectModel = { ...this.state.user.Processor.CardConnect }
                                                                    cardconnectModel.Bolt.authorization = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.CardConnect, cardconnectModel })
                                                                }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <ControlLabel>Bolt Serial No/HSN</ControlLabel>
                                                            <FormControl
                                                                placeholder="Serial No/HSN"
                                                                value={this.state.user.Processor.CardConnect.Bolt.hsn}
                                                                onChange={event => {
                                                                    this.change(event, "CardConnectHSN", "length", 5);
                                                                    this.state.user.Processor.CardConnect.Bolt.hsn = event.target.value;
                                                                    const cardconnectModel = { ...this.state.user.Processor.CardConnect }
                                                                    cardconnectModel.Bolt.hsn = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.CardConnect, cardconnectModel })
                                                                }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="CardConnectCreditCardCheckBox"
                                                                checked={this.state.user.Processor.CardConnect.supported_methods.creditcard}
                                                                labelText={"Allow Credit Card"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.CardConnect.supported_methods.creditcard = event.target.checked;
                                                                        const ccModel = { ...this.state.user.Processor.CardConnect };
                                                                        ccModel.supported_methods.creditcard = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.CardConnect, ccModel });
                                                                        this.onPaymentMethodCheckBoxChange();
                                                                    }
                                                                }}
                                                            />
                                                            {this.state.paymentMethodCheckBoxErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="CardConnectDebitCardCheckBox"
                                                                checked={this.state.user.Processor.CardConnect.supported_methods.debitcard}
                                                                labelText={"Allow Debit Card"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.CardConnect.supported_methods.debitcard = event.target.checked;
                                                                        const ccModel = { ...this.state.user.Processor.CardConnect };
                                                                        ccModel.supported_methods.debitcard = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.CardConnect, ccModel });
                                                                        this.onPaymentMethodCheckBoxChange();
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="CardConnectACHCheckBox"
                                                                checked={this.state.user.Processor.CardConnect.supported_methods.ach}
                                                                labelText={"Allow ACH"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.CardConnect.supported_methods.ach = event.target.checked;
                                                                        const ccModel = { ...this.state.user.Processor.CardConnect };
                                                                        ccModel.supported_methods.ach = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.CardConnect, ccModel });
                                                                        this.onPaymentMethodCheckBoxChange();
                                                                    }

                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>}
                                        {this.state.paymentMethodType === "AuthorizeNet" &&
                                            <div>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <ControlLabel>Client Key <span className="star"> *</span>
                                                            </ControlLabel>
                                                            <FormControl
                                                                value={this.state.user.Processor.AuthorizeNet.ClientKey}
                                                                placeholder="Client Key"
                                                                onChange={event => {
                                                                    this.state.user.Processor.AuthorizeNet.ClientKey = event.target.value;
                                                                    this.setState({ authorizeNetClientKeyError: "" });
                                                                    const authorizeNetModel = { ...this.state.user.Processor.AuthorizeNet };
                                                                    authorizeNetModel.ClientKey = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.AuthorizeNet, authorizeNetModel })
                                                                }
                                                                }
                                                            />
                                                            {this.state.authorizeNetClientKeyError}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <ControlLabel>APILogin ID <span className="star"> *</span>
                                                            </ControlLabel>
                                                            <FormControl
                                                                value={this.state.user.Processor.AuthorizeNet.APILoginID}
                                                                placeholder="APILogin ID"
                                                                onChange={event => {
                                                                    this.state.user.Processor.AuthorizeNet.APILoginID = event.target.value;
                                                                    this.setState({ authorizeNetAPILoginIDError: "" });
                                                                    const authorizeNetModel = { ...this.state.user.Processor.AuthorizeNet };
                                                                    authorizeNetModel.APILoginID = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.AuthorizeNet, authorizeNetModel })
                                                                }
                                                                }
                                                            />
                                                            {this.state.authorizeNetAPILoginIDError}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <ControlLabel>Transaction Key
                                                            <span className="star"> *</span>
                                                            </ControlLabel>
                                                            <FormControl
                                                                value={this.state.user.Processor.AuthorizeNet.TransactionKey}
                                                                placeholder="Transaction Key"
                                                                onChange={event => {
                                                                    this.state.user.Processor.AuthorizeNet.TransactionKey = event.target.value;
                                                                    this.setState({ authorizeNetTransactionKeyError: "" });
                                                                    const authorizeNetModel = { ...this.state.user.Processor.AuthorizeNet };
                                                                    authorizeNetModel.TransactionKey = event.target.value;
                                                                    this.setState({ ...this.state.user.Processor.AuthorizeNet, authorizeNetModel })
                                                                }
                                                                }
                                                            />
                                                            {this.state.authorizeNetTransactionKeyError}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <CustomCheckbox
                                                                name="ANTaxCheckBox"
                                                                checked={this.state.user.Processor.AuthorizeNet.supported_methods.ach}
                                                                labelText={"Allow ACH Transactions"}
                                                                labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                formControlProps={{
                                                                    onChange: event => {
                                                                        this.state.user.Processor.AuthorizeNet.supported_methods.ach = event.target.checked;
                                                                        const anModel = { ...this.state.user.Processor.AuthorizeNet };
                                                                        anModel.supported_methods.ach = event.target.checked;
                                                                        this.setState({ ...this.state.user.Processor.AuthorizeNet, anModel });
                                                                    }

                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>}

                                        <br />
                                        <br />
                                        <Row>
                                            <Col md={12}>
                                                <div style={{ textAlign: "right", marginBottom: "20px" }}>
                                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelCreateMerchant(e)}>Go Back</Button>
                                                    <Button bsStyle="primary" fill onClick={e => this.callingProcessorInfoUpdate()} disabled={!this.state.isPaymentInfoFormValid}>
                                                        Update Processor Info
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.state.Permissions != null &&
                                            <Row>
                                                <Col md={12}>
                                                    {this.state.displayQuickBooksOnline != "none" &&  
                                                        <Modal
                                                            show={this.state.showOnlinePermissionsModal}
                                                            onHide={() => this.setState({ showOnlinePermissionsModal: false })}
                                                            // dialogClassName="modal-dialog-custom"
                                                            backdrop="static"
                                                        >
                                                            {this.state.ShowPopupProgressBar &&
                                                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                </div>
                                                            }
                                                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                                <Modal.Title style={{ color: "white" }}>Features Permission</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="modal-body text-center" >
                                                                    <Row>
                                                                        <Col md={6} className="text-left">
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "25px", color: "rgb(0, 117, 193)", lineHeight: "2em" }}>
                                                                                        Invoices
                                                                                </div>

                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="createinvoice"
                                                                                        checked={this.state.Permissions.invoices[0].createinvoice}
                                                                                        labelText={"Create Invoice"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="sendemail"
                                                                                        checked={this.state.Permissions.invoices[1].sendemail}
                                                                                        labelText={"Email Invoice"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="sendsms"
                                                                                        checked={this.state.Permissions.invoices[2].sendsms}
                                                                                        labelText={"Text/SMS Invoice"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="collectpayment"
                                                                                        checked={this.state.Permissions.invoices[3].collectpayment}
                                                                                        labelText={"Collect Payments"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="recurring"
                                                                                        checked={this.state.Permissions.invoices[4].recurring}
                                                                                        labelText={"Recurring"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md={6} className="text-left">
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "25px", color: "rgb(0, 117, 193)", lineHeight: "2em" }}>
                                                                                        Sales Receipts
                                                                                </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="createsalesreceipt"
                                                                                        checked={this.state.Permissions.salesreceipt[0].createsalesreceipt}
                                                                                        labelText={"Create Sales Receipt"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <br />
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "25px", color: "rgb(0, 117, 193)", lineHeight: "2em" }}>
                                                                                        Payments
                                                                                </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="viewpayments"
                                                                                        checked={this.state.Permissions.payments[0].viewpayments}
                                                                                        labelText={"View Payments"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button bsStyle="primary" fill wd onClick={() => this.setState({ showOnlinePermissionsModal: false })}>Close</Button>
                                                            </Modal.Footer>
                                                        </Modal>                                                    
                                                    }
                                                    {this.state.displayStandaloneFeaturesButton != "none" && 
                                                        <Modal
                                                            show={this.state.showStandalonePermissionsModal}
                                                            onHide={() => this.setState({ showStandalonePermissionsModal: false })}
                                                            // dialogClassName="modal-dialog-custom"
                                                            backdrop="static"                                                        >
                                                            {this.state.ShowPopupProgressBar &&
                                                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                </div>
                                                            }
                                                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                                <Modal.Title style={{ color: "white" }}>Features Permission</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="modal-body text-center" >
                                                                    <Row>                                                                        
                                                                        <Col md={6} className="text-left">
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "25px", color: "rgb(0, 117, 193)", lineHeight: "2em" }}>
                                                                                        Reports
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <CustomCheckbox
                                                                                        name="viewtransactionreport"
                                                                                        checked={this.state.Permissions.transactionReport[0].viewTransactionReport}
                                                                                        labelText={"View Transaction Report"}
                                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                                        formControlProps={{
                                                                                            onChange: (e) => { this.handlePermissionsChecked(e) }
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button bsStyle="primary" fill wd onClick={() => this.setState({ showStandalonePermissionsModal: false })}>Close</Button>
                                                            </Modal.Footer>
                                                        </Modal> 
                                                    }
                                                </Col>
                                            </Row>
                                        }

                                    </div>
                                }
                            />
                            {(this.state.user.quickbooksversion.value == 1 || this.state.user.quickbooksversion.value == 4) &&
                                <div>
                                    <br />
                                    <Card
                                        title="Subusers Info"
                                        content={
                                            <div>
                                                <Row>
                                                    <Col md={12}>
                                                        <MuiThemeProvider theme={this.theme}>
                                                            <MaterialTable
                                                                columns={this.state.columns}
                                                                data={this.state.user.Subusers}
                                                                options={this.state.options}
                                                                actions={this.state.actions}
                                                                onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                                                components={this.state.components}
                                                            />
                                                        </MuiThemeProvider>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Modal show={this.state.showCreateEditSubuserModal} onHide={() => this.setState({ showCreateEditSubuserModal: false })}>
                                                            {this.state.ShowPopupProgressBar &&
                                                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                </div>
                                                            }
                                                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                                <Modal.Title style={{ color: "white" }}>Subuser Info</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="modal-body text-center" >
                                                                    <Row>
                                                                        <Col md={4} className="text-left">
                                                                            <h5>First Name :</h5>
                                                                        </Col>
                                                                        <Col md={8} className="text-left">
                                                                            <FormControl
                                                                                type="text"
                                                                                placeholder="First Name"
                                                                                name="subuserFirstName"
                                                                                className={this.state.subuserFirstNameState}
                                                                                value={this.state.subuserData.firstname}
                                                                                onChange={event => {
                                                                                    this.change(event, "subuserFirstName", "length", 2);
                                                                                    const value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        subuserData: {
                                                                                            ...prevState.subuserData,
                                                                                            firstname: value
                                                                                        }
                                                                                    }));
                                                                                }
                                                                                }
                                                                                onBlur={event => this.ValidateSubuser()}
                                                                            />
                                                                            {this.state.subuserFirstNameErrorMessage}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col md={4} className="text-left">
                                                                            <h5>Last Name :</h5>
                                                                        </Col>
                                                                        <Col md={8} className="text-left">
                                                                            <FormControl
                                                                                type="text"
                                                                                placeholder="Last Name"
                                                                                name="subuserLastName"
                                                                                className={this.state.subuserLastNameState}
                                                                                value={this.state.subuserData.lastname}
                                                                                onChange={event => {
                                                                                    this.change(event, "subuserLastName", "length", 2);
                                                                                    const value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        subuserData: {
                                                                                            ...prevState.subuserData,
                                                                                            lastname: value
                                                                                        }
                                                                                    }));
                                                                                }
                                                                                }
                                                                                onBlur={event => this.ValidateSubuser()}
                                                                            />
                                                                            {this.state.subuserLastNameErrorMessage}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={4} className="text-left">
                                                                            <h5>Email :</h5>
                                                                        </Col>
                                                                        <Col md={8} >
                                                                            <FormControl
                                                                                value={this.state.subuserData.email}
                                                                                placeholder="Email"
                                                                                type="email"
                                                                                disabled={this.state.IsEditedSubuser}
                                                                                autoComplete="off"
                                                                                className={this.state.subuserEmailState}
                                                                                onChange={event => this.validateSubuserEmail(event)}
                                                                                onBlur={event => this.ValidateSubuser()}
                                                                            />
                                                                            {this.state.subuserEmailErrorMessage}
                                                                        </Col>
                                                                    </Row>
                                                                    {!this.state.IsEditedSubuser &&
                                                                        <Row>
                                                                            <Col md={4} className="text-left">
                                                                                <h5>Password :</h5>
                                                                            </Col>
                                                                            <Col md={8} className="text-left">
                                                                                <FormControl
                                                                                    type="text"
                                                                                    placeholder="Password"
                                                                                    name="subuserPassword"
                                                                                    className={this.state.subuserPasswordState}
                                                                                    value={this.state.subuserData.password}
                                                                                    onChange={event => {
                                                                                        this.change(event, "subuserPassword", "length", 6);
                                                                                        const value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                            subuserData: {
                                                                                                ...prevState.subuserData,
                                                                                                password: value
                                                                                            }
                                                                                        }));
                                                                                        this.ValidateSubuser();
                                                                                    }
                                                                                    }
                                                                                    onBlur={event => this.ValidateSubuser()}
                                                                                />
                                                                                {this.state.subuserPasswordErrorMessage}
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    <Row>
                                                                        <Col md={4} className="text-left">
                                                                            <h5>Active :</h5>
                                                                        </Col>
                                                                        <Col md={8} className="text-left">
                                                                            <FormGroup style={marginBottomStyle}>
                                                                                <CustomCheckbox
                                                                                    name="subuserActive"
                                                                                    checked={this.state.subuserData.status}
                                                                                    labelText={"Active"}
                                                                                    labelProps={{ style: { fontSize: "15px", marginTop: "5px" } }}
                                                                                    formControlProps={{
                                                                                        onChange: event => {
                                                                                            const subuserChecked = event.target.checked;
                                                                                            this.state.subuserData.status = event.target.checked;
                                                                                            this.setState(prevState => ({
                                                                                                subuserData: {
                                                                                                    ...prevState.subuserData,
                                                                                                    status: subuserChecked
                                                                                                }
                                                                                            }));
                                                                                        }

                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showCreateEditSubuserModal: false })}>Close</Button>
                                                                <Button bsStyle="primary" fill wd disabled={!this.state.isValidSubuser} onClick={e => this.handleCreateEditSubuser(e)}>Save</Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </Col>
                                                </Row>
                                                {this.state.SubuserPermissions != null &&
                                                    <Row>
                                                        <Col md={12}>
                                                            <Modal
                                                                show={this.state.showOnlineSubuserPermissionsModal}
                                                                onHide={() => this.setState({ showOnlineSubuserPermissionsModal: false })}
                                                                // dialogClassName="modal-dialog-custom"
                                                                backdrop="static"
                                                            >
                                                                {this.state.ShowPopupProgressBar &&
                                                                    <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                                        <CustomProgressBar></CustomProgressBar>
                                                                    </div>
                                                                }
                                                                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                                    <Modal.Title style={{ color: "white" }}>Edit Subuser Permissions</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="modal-body text-center" >
                                                                        <Row>
                                                                            <Col md={6} className="text-left">
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "25px", color: "rgb(0, 117, 193)", lineHeight: "2em" }}>
                                                                                            Invoices
                                                                                    </div>

                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <CustomCheckbox
                                                                                            name="createinvoice"
                                                                                            checked={this.state.SubuserPermissions.invoices[0].createinvoice}
                                                                                            labelText={"Create Invoice"}
                                                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                                                            formControlProps={{
                                                                                                onChange: (e) => { this.handleSubserPermissionsChecked(e) }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <CustomCheckbox
                                                                                            name="sendemail"
                                                                                            checked={this.state.SubuserPermissions.invoices[1].sendemail}
                                                                                            labelText={"Email Invoice"}
                                                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                                                            formControlProps={{
                                                                                                onChange: (e) => { this.handleSubserPermissionsChecked(e) }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <CustomCheckbox
                                                                                            name="sendsms"
                                                                                            checked={this.state.SubuserPermissions.invoices[2].sendsms}
                                                                                            labelText={"Text/SMS Invoice"}
                                                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                                                            formControlProps={{
                                                                                                onChange: (e) => { this.handleSubserPermissionsChecked(e) }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <CustomCheckbox
                                                                                            name="collectpayment"
                                                                                            checked={this.state.SubuserPermissions.invoices[3].collectpayment}
                                                                                            labelText={"Collect Payments"}
                                                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                                                            formControlProps={{
                                                                                                onChange: (e) => { this.handleSubserPermissionsChecked(e) }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <CustomCheckbox
                                                                                            name="recurring"
                                                                                            checked={this.state.SubuserPermissions.invoices[4].recurring}
                                                                                            labelText={"Recurring"}
                                                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                                                            formControlProps={{
                                                                                                onChange: (e) => { this.handleSubserPermissionsChecked(e) }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col md={6} className="text-left">
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "25px", color: "rgb(0, 117, 193)", lineHeight: "2em" }}>
                                                                                            Sales Receipts
                                                                                    </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <CustomCheckbox
                                                                                            name="createsalesreceipt"
                                                                                            checked={this.state.SubuserPermissions.salesreceipt[0].createsalesreceipt}
                                                                                            labelText={"Create Sales Receipt"}
                                                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                                                            formControlProps={{
                                                                                                onChange: (e) => { this.handleSubserPermissionsChecked(e) }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <br />
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "25px", color: "rgb(0, 117, 193)", lineHeight: "2em" }}>
                                                                                            Payments
                                                                                    </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <CustomCheckbox
                                                                                            name="viewpayments"
                                                                                            checked={this.state.SubuserPermissions.payments[0].viewpayments}
                                                                                            labelText={"View Payments"}
                                                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                                                            formControlProps={{
                                                                                                onChange: (e) => { this.handleSubserPermissionsChecked(e) }
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>

                                                                    </div>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showOnlineSubuserPermissionsModal: false })}>Close</Button>
                                                                    <Button bsStyle="primary" fill wd  onClick={(event) => this.handleSubuserPermissionsSubmit(event)}>Save</Button>                                                            
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        }
                                    />
                                </div>
                            }
                        </div>
                    }

                />

            </div>
        );
    }
}

export default EditMerchant;