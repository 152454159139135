/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Navbar
} from "react-bootstrap";
import instantinvoicelogo from "assets/img/instantinvoice/instantinvoicelogo.jfif";
// import Card from "components/Card/CardWithoutClass.jsx";
import HeaderLinks from "./HeaderLinks.jsx";
// import { style } from "@material-ui/system";

class AccountNavbar extends Component {
  constructor(props) {
    super(props);
    const { classes } = this.props;
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      width: window.innerWidth
    };
  }
  // function that sets the class to active of the active page
  activeRoute(routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // function that shows/hides sidebar on responsive
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }
  updateWidth() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWidth.bind(this));
  }
  handleHomeSubmit(e) {
    if (window.location.href.indexOf("login") > -1 || window.location.href.indexOf("resetpassword") > -1 || window.location.href.indexOf("register-page") > -1 || window.location.href.indexOf("home") > -1) {
      console.log(window.location.href);
    }
    else {
      window.location.href = '#/account/home';
    }
  }

  render() {
    return (
      <div>
        <Navbar
        fluid
        collapseOnSelect
        className={this.props.navbar ? "navbar-fixed" : ""}
      >
        <div style={{float:"left", marginLeft:"-15px"}}>
        {/* onClick={e => this.handleHomeSubmit(e)} */}
        <img src={instantinvoicelogo} width="210px"  height="75px" alt="Instant Invoice" />
        </div>
       
        {/* Here we import the links that appear in navbar */}
        {window.innerWidth > 992 ? (
          <Navbar.Collapse>
            <HeaderLinks />
          </Navbar.Collapse>
        ) : null}
      </Navbar>
      </div>
    );
  }
}

export default AccountNavbar;
