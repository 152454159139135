// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import {
  formatExpiry,
  isHighlighted
} from 'utils/formatter';
import isExpiryInvalid from 'utils/is-expiry-invalid';

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};

  &.is-invalid {
    border: 1px solid #ff3860;
    ${({ invalidStyled }) => ({ ...invalidStyled })};
  }
`;

const DangerText = styled.p`
  font-size: 1.2rem;
  margin: 5px 0 0 0;
  color: #ff3860;
  ${({ styled }) => ({ ...styled })};
`;

const BACKSPACE_KEY_CODE = 8;
global.isCardExpiryValid = false;
 
type Props = {
  cardExpiryInputRenderer: Function,
  onError?: Function,
  cardExpiryInputProps: Object,
  containerClassName: string,
  containerStyle: Object,
  dangerTextClassName: string,
  dangerTextStyle: Object,
  fieldClassName: string,
  fieldStyle: Object,
  inputComponent: Function | Object | string,
  invalidClassName: string,
  invalidStyle: Object,
  customTextLabels: Object,
  formControlProps:  PropTypes.object,
  classes: PropTypes.object.isRequired,
};


const inputRenderer = ({ inputComponent, props }: Object) => {
  const Input = inputComponent || 'input';
  return <Input {...props} />;
};

class ExpInput extends Component<Props, State> {
  cardExpiryField: any;

  static defaultProps = {
    cardExpiryInputRenderer: inputRenderer,
    cardExpiryInputProps: {},
    containerClassName: '',
    containerStyle: {},
    dangerTextClassName: '',
    dangerTextStyle: {},
    fieldClassName: '',
    fieldStyle: {},
    inputComponent: 'input',
    invalidClassName: 'is-invalid',
    invalidStyle: {},
    customTextLabels: {},
    formControlProps:{},
    classes: {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      errorText: null,
    };
  }



  isMonthDashKey = ({ key, target: { value } } = {}) => {
    return !value.match(/[/-]/) && /^[/-]$/.test(key);
  };

  checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  handleCardExpiryBlur = (
    { onBlur }: { onBlur?: ?Function } = { onBlur: null }
  ) => (e: SyntheticInputEvent<*>) => {
    const { customTextLabels } = this.props;
    const cardExpiry = e.target.value.split(' / ').join('/');
    const expiryError = isExpiryInvalid(
      cardExpiry,
      customTextLabels.expiryError
    );
    if (expiryError) {
      this.setFieldInvalid(expiryError, 'cardExpiry');
    }

    const { cardExpiryInputProps } = this.props;
    cardExpiryInputProps.onBlur && cardExpiryInputProps.onBlur(e);
    onBlur && onBlur(e);
  };

  handleCardExpiryChange = (
    { onChange }: { onChange?: ?Function } = { onChange: null }
  ) => (e: SyntheticInputEvent<*>) => {
    const { customTextLabels } = this.props;

    this.cardExpiryField.value = formatExpiry(e);
    
    if(this.cardExpiryField.value != null)
    {
      const value = this.cardExpiryField.value.split(' / ').join('/');
      document.getElementById("card-expiry").value = value;
   

    this.setFieldValid();
    const expiryError = isExpiryInvalid(value, customTextLabels.expiryError);
    if(expiryError)
    {
      global.isCardExpiryValid = false;
    }
    else
    {
      global.isCardExpiryValid = true;
    }
    if (value.length > 4) {
      if (expiryError) {
        this.setFieldInvalid(expiryError, 'cardExpiry');         
        } 
      }
     }

    const { cardExpiryInputProps } = this.props;
    cardExpiryInputProps.onChange && cardExpiryInputProps.onChange(e);
    onChange && onChange(e);
  };

  handleCardExpiryKeyPress = (e: any) => {
    const value = e.target.value;

    if (!this.isMonthDashKey(e)) {
      this.checkIsNumeric(e);
    }
    if (value && !isHighlighted()) {
      const valueLength = value.split('/').join('').length;
      if (valueLength >= 4) {
        e.preventDefault();
      }
    }
  };



  handleKeyDown = (ref: any) => {
    return (e: SyntheticInputEvent<*>) => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
        ref.focus();
      }
    };
  };

  setFieldInvalid = (errorText: string, inputName?: string) => {
    const { invalidClassName, onError } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.add(invalidClassName);
    this.setState({ errorText });

    if (inputName) {
      const { onError } = this.props[`${inputName}InputProps`];
      onError && onError(errorText);
    }

    if (onError) {
      onError({ inputName, error: errorText });
    }
  };

  setFieldValid = () => {
    const { invalidClassName } = this.props;    
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.remove(invalidClassName);
    this.setState({ errorText: null });
  };

  render = () => {
    const { errorText } = this.state;
    const {
      cardExpiryInputProps,
      cardExpiryInputRenderer,
      containerClassName,
      containerStyle,
      dangerTextClassName,
      dangerTextStyle,
      fieldClassName,
      fieldStyle,
      inputComponent,
      invalidStyle,
      customTextLabels,
      classes,
      formControlProps,
      error,
      success,
      labelText,
      id,
      value,
      labelProps,
      inputProps
    } = this.props;

    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });

    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: !error,
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });

    return (
      <FormControl 
      // className={containerClassName} styled={containerStyle}
      {...formControlProps}
     // className='MuiFormControl-root undefined CustomInput-formControl-132 MuiFormControl-fullWidth'
      className={formControlProps.className }//+ " " + classes.formControl}
      >   
        <FieldWrapper
          id="field-wrapper"
          className={fieldClassName}
          styled={fieldStyle}
          invalidStyled={invalidStyle}
        >
         
         {labelText !== undefined ? (
        <InputLabel
          className= {!errorText && classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
            {labelText}
            </InputLabel>
          ) : null}
          <Input
              data-max="MM / YY 9"
              id= 'card-expiry'
              name= 'CardExpiry'
              value={value}
                ref= {cardExpiryField => {
                  this.cardExpiryField = cardExpiryField;
                }}
                autoComplete= 'off'
                classes={{
                  root: marginTop,
                  disabled: classes.disabled,
                  underline: underlineClasses
               }}
               // placeholder= {customTextLabels.expiryPlaceholder || 'MM/YY'}
                type= 'tel'
               // ...cardCVCInputProps
                onBlur= {this.handleCardExpiryBlur()}
                onChange= {this.handleCardExpiryChange()}
                onKeyPress= {this.handleCardExpiryKeyPress}
                fullWidth= {true}
                {...inputProps}
          >
          {cardExpiryInputRenderer({
              inputComponent,
              handleCardExpiryChange: onChange =>
                this.handleCardExpiryChange({ onChange }),
              handleCardExpiryBlur: onBlur =>
                this.handleCardExpiryBlur({ onBlur }),
           })}
          </Input>          
          
        </FieldWrapper>
        {errorText && (
          <DangerText className={dangerTextClassName} styled={dangerTextStyle}>
            {errorText}
          </DangerText>
        )}
      </FormControl>
    );
  };
}

export default  withStyles(customInputStyle)(ExpInput);
