import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
    FormControl,

} from "react-bootstrap";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from "react-select";
import Datetime from "react-datetime";
import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import Chip from '@material-ui/core/Chip';
import NetworkService from "services/NetworkService";
import ReactToPrint from 'react-to-print';

class Invoices extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                // Use the system font instead of the default Roboto font.
                //fontFamily: [
                // '-apple-system',
                // 'BlinkMacSystemFont',
                // '"Segoe UI"',
                //   'Roboto',
                //   '"Helvetica Neue"',
                //   'Arial',
                //   'sans-serif',
                //   '"Apple Color Emoji"',
                //   '"Segoe UI Emoji"',
                //   '"Segoe UI Symbol"',
                // ].join(','),
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            invoiceStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                { value: "all", label: "All" },
                { value: "due", label: "Due" },
                { value: "overdue", label: "Overdue" },
                { value: "paid", label: "Paid" },
            ],
            filter_invoice: { value: "all", label: "All" },
            //filter_invoice: "all",
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            showActionButtons: "none",
            showInvoiceSendButton: "none",
            showPrintButton: "none",
            showBatchEmail: "none",
            showSendInvoiceModal: false,
            errorSendInvoiceEmail: "",
            sendInvoiceClassName: "",
            customerEmail: "ahsan766@gmail.com",
            rawInvoiceData: [],
            selectedInvoiceIds: "",
            data: [
                // { invoicenumber: '654541', customername: 'Can dynamically add', invoicedate: '2020-01-01', duedate: '2020-12-05', balance: '$633.21', total: '$633.21', invoicestatus: 'Paid', emailstatus: 'not sent' },
            ],
            columns: [
                {
                    title: 'Invoice #',
                    field: 'invoicenumber',                   
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Invoice Date',
                    field: 'invoicedate',
                    type: 'date',
                    cellStyle: {
                        width: 'calc(12%)'
                    },
                },
                {
                    title: 'Due Date',
                    field: 'duedate',
                    type: 'date'
                },
                {
                    title: 'Balance',
                    field: 'balance'                    
                    //  lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                },
                {
                    title: 'Total',
                    field: 'total'                    
                    //  lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
                },
                {
                    title: 'Invoice Status',
                    field: 'invoicestatus',
                    cellStyle: {
                        width: 'calc(13%)'
                    },
                },
                {
                    title: 'Email Status',
                    field: 'emailstatus'
                },
                {
                    title: 'Action',
                    field: 'action'
                }
            ],
            options: {
                selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#eeeeee',//'#BABEC2', //'#eeeeee',
                    fontWeight: "bold",   
                    //color: 'white',
                    zIndex: 4,
                    //  color: '#FFF'
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"                   
                }),
            },
            createInvoiceToolTip: <Tooltip id="createInvoiceToolTip">Create Invoice</Tooltip>,
            printInvoiceToolTip: <Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>,
            batchEmailInvoiceToolTip: <Tooltip id="batchEmailInvoiceToolTip">Send Selected Invoices</Tooltip>,
            sendInvoiceToolTip: <Tooltip id="sendInvoiceToolTip">Email Invoice</Tooltip>,
            collectPaymnetToolTip: <Tooltip id="collectPaymnetToolTip">Collect Payment</Tooltip>,
            invoiceLinkToolTip: <Tooltip id="invoiceLinkToolTip">Invoice Link</Tooltip>,
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />

                        <div style={{ padding: '10px' }}>
                            {/* <span className="react-tagsinput-tag tag-fill tag-red">Hi</span> */}
                            <OverlayTrigger placement="top" overlay={this.state.createInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateInvoice(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>

                            {/* <OverlayTrigger placement="top" overlay={this.state.printInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePrintClick(e)} style={{ marginRight: '10px', display: this.state.showPrintButton }}> <i className="fa fa-print" ></i> </Button>
                            </OverlayTrigger> */}

                            <ReactToPrint
                                trigger={() =>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '10px', display: this.state.showPrintButton }}> <i className="fa fa-print" ></i> </Button>
                                    </OverlayTrigger>
                                }
                                // onBeforeGetContent={() => this.handlePrintClick() }
                                content={() => this.componentRef}
                            />
                            <div style={{ display: "none" }}> <ComponentToPrint ref={el => (this.componentRef = el)} dataFromParent={this.state.selectedInvoiceIds} /> </div>
                            <OverlayTrigger placement="top" overlay={this.state.batchEmailInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '10px', display: this.state.showBatchEmail }}> <i className="fa fa-send" ></i> </Button>
                            </OverlayTrigger>


                            <OverlayTrigger placement="top" overlay={this.state.sendInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleSendInvoice(e)} style={{ marginRight: '10px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-envelope" ></i> </Button>
                            </OverlayTrigger>


                            <OverlayTrigger placement="top" overlay={this.state.collectPaymnetToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePaymentInvoice(e)} style={{ marginRight: '10px', display: this.state.showActionButtons }}> <i className="fa fa-credit-card" ></i> </Button>
                            </OverlayTrigger>

                            {/* <OverlayTrigger placement="top" overlay={this.state.invoiceLinkToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '10px', display: this.state.showActionButtons }}> <i className="fa fa-link" ></i> </Button>
                            </OverlayTrigger> */}
                        </div>
                    </div>
                ),
                // Action: props => (
                //     <Button
                //         onClick={(event) => props.action.onClick(event, props.data)}>
                //         My Button
                //     </Button>
                // ),
            },
            actions: [
                {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => alert("You saved " + rowData.name)
                }
            ],
            singleEmailInvoiceData: {
                customerName: "",
                customerEmail: "",
                invoiceNumber: "",
                balance: "",
                index: ""
            },
            selectedInvoiceIndex: [],


            // actions:{[
            //     {
            //       icon: 'save',
            //       tooltip: 'Save User',
            //       onClick: (event, rowData) => {
            //         // Do save operation
            //       }
            //     }
            //   ]}

        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        }

        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        let invoiceStatusFilter = this.props.location.state ? this.props.location.state.invoice_status ? this.props.location.state.invoice_status : "all" : "all";
        this.state.filter_invoice = { value: invoiceStatusFilter, label: invoiceStatusFilter[0].toUpperCase() + invoiceStatusFilter.slice(1) }

        document.getElementById("loaderDiv").style.display = "none";

        this.SetFromToDates();
        this.filterInvoice();

        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }
    /** HANDLER */
    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        //console.log("from : " + this.state.queryFromDate + "  to : " + this.state.queryToDate);

        this.filterInvoice();
    }

    handleCreateInvoice(e) {
        this.props.history.push('/standalonecreate/invoice');
        e.preventDefault();
    }

    handlePaymentInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
        });
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        if(data_tokenize.PPType.toLowerCase() === "cardconnect"){
            this.props.history.push({
                pathname: '/invoice/ccpayment',
                state: { invoiceData: invoice }
            });
        }
        else if(data_tokenize.PPType.toLowerCase() === "nmi"){      
            this.props.history.push({
                pathname: '/invoice/nmipayment',
                state: { invoiceData: invoice }
            });
        }
    }

    handleSendInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const x = {
                customerName: invoice.CustomerRef.name,
                customerEmail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            this.setState({ singleEmailInvoiceData: x })
        })
        this.setState({ showSendInvoiceModal: true })

    }

    filterInvoice = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
            "status": this.state.filter_invoice.value
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this.getInvoiceDetailsRequest(query);
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleSendInvoiceEmail(e) {
        e.preventDefault();
        let invoice = [];
        this.state.selectedInvoiceIndex.map(index => {
            invoice.push(this.state.rawInvoiceData[index]);
        })
        const customer_email = this.state.singleEmailInvoiceData.customerEmail
        const payload = {
            "ToEmails": [customer_email],
            "Invoices": invoice
        }
        this.postEmailInvoiceRequest(payload);
    }

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            singleEmailInvoiceData: {
                ...prevState.singleEmailInvoiceData,
                customerEmail: value
            }
        }));
        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendInvoiceEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )

            });
            this.setState({
                sendInvoiceClassName: "error"
            });

        }
        else {
            this.setState({ errorSendInvoiceEmail: null });
            this.setState({ sendInvoiceClassName: "valid" });
        }
    }

    handleInvoiceDetailClick(invoice) {
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        this.props.history.push('/invoice/detail/' + invoice.Id);
    }

    handleSelectedRowData(data, length) {
        let newStates = [];
        let invoiceIds = "";
        data.map(d => {
            newStates.push(d.index);
            invoiceIds = invoiceIds + "," + d.id;
        });
        // this.setState({ selectedInvoiceIndex: newStates });
        this.state.selectedInvoiceIndex = newStates;
        this.state.selectedInvoiceIds = this.replaceAt(invoiceIds, 0, '');

        // if (length === 1){
        //     data.map(d =>{
        //         const x = {
        //             customerName:d.customername,
        //             customerEmail:d.customeremail,
        //             invoiceNumber:d.invoicenumber,
        //             balance:d.balance,
        //             index:d.index
        //         }
        //         this.setState({singleEmailInvoiceData:x})
        //     })

        // }else{

        // }
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    if (data.statuscode === 0) {
                        let processor = data.data.items;
                        this.setProcessorResult(processor);
                    }
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setProcessorResult = (processors) => {
         let tokenize_key = null;
          let processor = null;
          processors.map(data => {
            if(data.is_default == true)
            {
                processor = data;
            }
        });

        if (processor!= null){
            if (processor.method_type == "nmi"){
                tokenize_key = JSON.stringify({"PPType":"nmi", "PPToken":processor.TokenizationKey})
            }else if (processor.method_type == "cardconnect"){
                tokenize_key = JSON.stringify({"PPType":"cardconnect", "PPToken":processor.configuration.URL})
            }
        }
        localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
    }    

    /** API CALLING */

    // GET request for invoice details
    getInvoiceDetailsRequest(params = "") {
        this.setState({ ShowProgressBar: true });

        const url = BASE_URL + "api/v1/invoice/filter/?" + params;
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.onSetResult(data.data);
                this.setState({ ShowProgressBar: false });
            } else {
                this.setState({ ShowProgressBar: false });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });


    }

    onSetResult = (result) => {
        const newSeries = [];
        const button = <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
            <Button className="btn btn-primary btn-fill btn-round btn-icon"> <i className="fa fa-mail-forward" ></i> </Button>
        </OverlayTrigger>

        result.items.map((invoice, index) => {
            const data = {
                index: index,
                id: invoice.Id,
                invoicenumber: invoice.DocNumber,
                customername: invoice.CustomerRef.name,
                customeremail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoicedate: invoice.TxnDate,
                duedate: invoice.DueDate,
                balance: '$' + parseFloat(invoice.Balance).toFixed(2),
                total: '$' + parseFloat(invoice.TotalAmt).toFixed(2),
                invoicestatus: invoice.IsPaid ? "Paid" : "UnPaid",
                action: <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                    <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}> <i className="fa fa-mail-forward" ></i> </Button>
                </OverlayTrigger>,
                emailstatus: (invoice.SendGridEvent === "not sent") ? <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                    : (invoice.SendGridEvent === "read") ? <Chip style={{ backgroundColor: "#1A8D2B", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                        : (invoice.SendGridEvent === "delivered") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                            : (invoice.SendGridEvent === "email sent") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                                : (invoice.SendGridEvent === "click") ? <Chip style={{ backgroundColor: "#05C8FF", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                                    : (invoice.SendGridEvent === "processed") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                                        : (invoice.SendGridEvent === "open") ? <Chip style={{ backgroundColor: "#775DFF", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                                            : (invoice.SendGridEvent === "spamreport") ? <Chip style={{ backgroundColor: "#FF88FB", color: "white", height: 25 }} label={invoice.SendGridEvent}></Chip>
                                                : "",
            }
            newSeries.push(data);

        });
        this.setState({ rawInvoiceData: result.items });
        this.setState({ data: newSeries });
    }

    // POST request
    postEmailInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const url = BASE_URL + "api/v1/invoice/email/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        fetch(url, {
            method: "POST",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "ToEmails": data["ToEmails"],
                "Invoices": data["Invoices"]
            })
        }).then(response => {
            return response.json()
        }).then(data => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendInvoiceModal: false });
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            } else {
            }
        }).catch(error => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendInvoiceModal: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

    handlePrintClick(e) {
        e.preventDefault();
        let invoiceIds = "";
        this.state.selectedInvoiceIndex.map(index => {
            invoiceIds = invoiceIds + "," + (this.state.rawInvoiceData[index].Id);
        })
        invoiceIds = this.replaceAt(invoiceIds, 0, '');
        console.log(invoiceIds);
    }


    replaceAt(str, index, ch) {
        return str.replace(/./g, (c, i) => i === index ? ch : c)
    }

    render() {
        // const customLinkStyle = {
        //     color: "#0075c1",
        //     fontSize: "16px",
        //   } 
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>Invoice Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="invoiceStatus"
                                            value={this.state.filter_invoice}
                                            onChange={value => {
                                                this.setState({ filter_invoice: value });
                                            }
                                            }
                                            options={this.state.invoiceStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>

                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>

                                        }
                                        <MaterialTable
                                            //title="Styling with MuiThemeProvider Preview"
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            onSelectionChange={(rows) => {
                                                console.log('You selected ' + rows.length + ' rows');
                                                this.handleSelectedRowData(rows, rows.length);

                                                if (rows.length > 0) {
                                                    this.setState({ showPrintButton: "" });
                                                    if (rows.length === 1) {
                                                        let invoice = {};
                                                        this.state.selectedInvoiceIndex.map(index => {
                                                            invoice = this.state.rawInvoiceData[index];
                                                        });
                                                        if (invoice.IsPaid) {
                                                            this.setState({ showActionButtons: "none" });
                                                        }
                                                        else {
                                                            this.setState({ showActionButtons: "" });
                                                        }
                                                        this.setState({ showInvoiceSendButton: "" });
                                                        this.setState({ showBatchEmail: "none" });
                                                    }
                                                    else {
                                                        this.setState({ showInvoiceSendButton: "none" });
                                                        this.setState({ showActionButtons: "none" });
                                                        this.setState({ showBatchEmail: "none" });
                                                    }
                                                }
                                                else {
                                                    this.setState({ showPrintButton: "none" });
                                                    this.setState({ showActionButtons: "none" });
                                                    this.setState({ showInvoiceSendButton: "none" });
                                                    this.setState({ showBatchEmail: "none" });
                                                }
                                            }
                                            }
                                            components={this.state.components}
                                        // actions={this.state.actions}

                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showSendInvoiceModal}
                                        onHide={() => this.setState({ showSendInvoiceModal: false })}
                                    // style={{ marginTop: "200px" }}
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer Email :</h5>
                                                    </Col>
                                                    <Col md={8} >
                                                        <FormGroup >
                                                            <FormControl
                                                                value={this.state.singleEmailInvoiceData.customerEmail}
                                                                placeholder="Email"
                                                                type="email"
                                                                autoComplete="off"
                                                                className={this.state.sendInvoiceClassName}
                                                                onChange={event => this.validateEmail(event)}
                                                            />
                                                            {this.state.errorSendInvoiceEmail}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleSendInvoiceEmail(e)}>Send</Button>
                                        </Modal.Footer>
                                        {/* <Col md={4} >
                                                        <Button bsStyle={"primary"} fill onClick={event => { this.handleSendInvoiceEmail(event) }} >
                                                            Send
                                                        </Button>
                                                    </Col> */}

                                    </Modal>
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Invoices;

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHTMLDiv: true,
            InvoiceRawHtml: null,
            prevValue: "",
        }
    }

    getInvoicePreview(invoice_id) {
        const params = {
            "invoice_id": invoice_id
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }

    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    const content_type = data.data.content_type;
                    if (content_type === "application/html") {
                        this.setState({ showHTMLDiv: true })
                    } else {
                        this.setState({ showHTMLDiv: false });
                        this.renderPDFView(data.data.html || null, "pdf-canvas");
                    }
                    this.setState({ InvoiceRawHtml: data.data.html || null });
                } else {
                }
            }).catch(error => {
                this.setState({ InvoiceRawHtml: "<h1>Oops! Something went wrong, please try again.</h1>" });
            });
    }

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {

            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    rawMarkup(value) {
        if (this.state.prevValue != value && value != null) {
            this.state.prevValue = value;
            this.getInvoicePreview(value);
        }
        return { __html: this.state.InvoiceRawHtml };
    };


    render() {
        return (
            <div>
                <div style={{ display: "none" }} dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />

                {this.state.showHTMLDiv &&
                    <div dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />
                }
                {!this.state.showHTMLDiv &&
                    <div id="pdf-canvas" height="0"></div>
                }
            </div>
        );
    }
}






