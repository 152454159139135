/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { Component } from "react";
import Button from "components/CustomButton/CustomButton.jsx";

class Footer extends Component {
  render() {
    return (
      <footer
        className={
          "footer" +
          (this.props.transparent !== undefined ? " footer-transparent" : "") +
          (this.props.default !== undefined ? " footer-default" : "") +
          (this.props.black !== undefined ? " footer-black" : "")
        }
      >
        <div
          className={
            "container" + (this.props.fluid !== undefined ? "-fluid" : "")
          }
        >
          <nav className="pull-left"> 
            <div style={{marginTop:"15px"}}>
            <a href="https://www.youtube.com/channel/UCJNJy44IyQt5EESZ_7jb1cA" target="_blank">
              <Button round youtube>
                <i className="fa fa-youtube-play"> </i>
              </Button>
            </a>
            <a style={{marginLeft:"5px"}} href="https://www.facebook.com/instantinvoice" target="_blank">
              <Button round facebook>              
                <i className="fa fa-facebook-square"> </i>                
              </Button>
              </a>
            <a style={{marginLeft:"5px"}} href="https://www.linkedin.com/company/instant-invoice/" target="_blank">
              <Button round linkedin>
                <i className="fa fa-linkedin-square"> </i>
              </Button>
            </a>           
            </div> 
            {/* <ul>
              <li>
                <a href="#pablo">Home</a>
              </li>
              <li>
                <a href="#pablo">Company</a>
              </li>
              <li>
                <a href="#pablo">Portfolio</a>
              </li>
              <li>
                <a href="#pablo">Blog</a>
              </li>
            </ul> */}
          </nav>
          <p className="copyright pull-right">
            &copy; {1900 + new Date().getYear()}{" "} Powered by 
            <a href="https://www.instantinvoice.com" target="_blank"> Instant Invoice</a>
           
          </p>
        </div>
      </footer>
    );
  }
}
export default Footer;
