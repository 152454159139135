import React from 'react';
import ReactToPrint from 'react-to-print';
import Card from "components/Card/Card.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import paidimage from "assets/img/paidimage.png";
import EmailStatsPanel from "views/Components/EmailStatsPanel";

import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Alert,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";
//import ThreeHorseLoading from 'react-loadingg/lib/ThreeHorseLoading';



const styles = {
    watermark: {
        content: "",
        backgroundImage: `url(${paidimage})`,
        opacity: "0.2",
        top: "32%",
        left: "7%",
        bottom: "0",
        right: "0",
        position: "absolute"
    },
}    

// const LazyReactToPrint = lazy(() => import('react-to-print'));

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowProgressBar: false,
            errorCode: null,
            errorMessage: "",
            BillEmail: {
                Address: ""
            },
            Invoice: {
                CustomerRef: {
                    name: "",
                    value: ""
                },
                Balance: 0,
                DocNumber: "",
                Id: "",
                BillEmail: {
                    Address: ""
                },
                // billing:{
                //     address1: null,
                //     address2: null,
                //     city: null,
                //     company: null,
                //     country: null,
                //     email: "",
                //     fax: null,
                //     first_name: "",
                //     last_name: "",
                //     phone: null,
                //     postal: null,
                //     state: null,
                // },
                TxnDate: null
            },
            InvoiceRawHtml: null,
            docNumber: "",
            showSendInvoiceModal: false,
            showEmailStatsModal: false,
            showHTMLDiv: true
        }
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }

        var invoiceID = this.getInvoiceIDFromURL();
        var userID = this.getUserIDFromURL();
        if (invoiceID != null) {
            this.getInvoicePreview(invoiceID, userID);
        }

        document.getElementById("loaderDiv").style.display = "none";
    }

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            ...prevState,
            Invoice: {
                ...prevState.Invoice,
                BillEmail: {
                    ...prevState.Invoice.BillEmail,
                    Address: value
                }
            }
        }));

        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendInvoiceEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )

            });
            this.setState({
                sendInvoiceClassName: "error"
            });

        }
        else {
            this.setState({ errorSendInvoiceEmail: null });
            this.setState({ sendInvoiceClassName: "valid" });
        }
    }

    handleSendInvoiceEmail(e) {
        e.preventDefault();
        const payload = {
            "ToEmails": [this.state.Invoice.BillEmail.Address],
            "Invoices": [this.state.Invoice]
        }
        this._postEmailInvoiceRequest(payload);
    }

    getInvoiceIDFromURL() {
        const url = window.location.href;
        const path = url.split("/");
        const invoiceID = (path[path.length - 2] || "");
        return invoiceID;
    }

    getUserIDFromURL() {
        const url = window.location.href;
        const path = url.split("/");
        const userid = (path[path.length - 1] || "");
        return userid;
    }

    getInvoicePreview(invoice_id, userID) {
        this.setState({ ShowProgressBar: true });
        let params = {
            "invoice_id": invoice_id
        }
        if(userID != "null" && userID != null && userID != undefined && userID != "")
        {
            params = {
                "invoice_id": invoice_id,
                "user": userID
            }
        }        

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }

    setResult = (data) => {   
        
        this.setState({ InvoiceRawHtml: data.html || null });
        if (data.invoice.length > 0 && data.invoice[0].BillEmail == null) {
            data.invoice[0].BillEmail = this.state.BillEmail;
        }

        let invoice = {...this.state.Invoice};
        invoice.CustomerRef.name = data.invoice[0].billing.first_name + " " + data.invoice[0].billing.last_name;
        invoice.Balance = parseFloat(data.invoice[0].amount) - parseFloat(data.invoice[0].payments_applied);
        invoice.DocNumber = data.invoice[0].invoice_id;        
        var txnDate = new Date(data.invoice[0].created.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,'$4:$5:$6 $2/$3/$1'));
        
        invoice.TxnDate = this.formatDate(txnDate);
        invoice.Id = data.invoice[0].invoice_id;
        invoice.BillEmail.Address = data.invoice[0].billing.email;
        this.setState({ Invoice: invoice })
        
        const content_type = data.content_type
        if (content_type === "application/html") {
            this.setState({ showHTMLDiv: true })
        } else {
            this.setState({ showHTMLDiv: false })
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas-detail");
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas-detail2");
        }

    }

    /**
     * API Calling
     */
    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {       
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                 this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setResult(data.data);
                } else {
                }
            }).catch(error => {
                 this.setState({ ShowProgressBar: false });
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);   
        
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    /** POST request to Email invoice to customer */
    _postEmailInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id =  ApplicationIDEnum.NMIInvoicing;
        NetworkService.postEmailInvoiceRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showSendInvoiceModal: false });
                if (data != null) {
                     this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    rawMarkup(value) {
       // const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: this.state.InvoiceRawHtml };
    };

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {
            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    handleSendInvoice(e) {
        this.setState({ showSendInvoiceModal: true });
    }

    handlePaymentInvoice(e) {
        e.preventDefault();     
        this.props.history.push({
            pathname: '/nmi/nmipayment',
                state: { invoiceData: this.state.Invoice }
        });        
    }

    handleTemplateClick(e) {
    }

    handleProcessorSetupClick(e) {
    }

    handleGoBackClick(e) {
        this.props.history.push('/nmi/invoices');
        e.preventDefault();
    }

    handleEmailStatsClick(e) {
        if (this.state.showEmailStatsModal) {
            this.setState({ showEmailStatsModal: false });
            setTimeout(() => {
                this.setState({ showEmailStatsModal: true });
            }, 200);
        }
        else {
            this.setState({ showEmailStatsModal: true });
        }
        // e.preventDefault();
    }

    callbackFunction = (childData) => {
        this.setState({ showEmailStatsModal: childData })
    }


    handlerShowEmailStatsModal = (value) => {
        this.setState({ showEmailStatsModal: value });

    }

    render() {
        return (
            <div className="ii-main-content">
                <Card
                    // title={"Invoice # " + this.state.docNumber}
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            {this.state.ShowProgressBar &&
                                <div>
                                    <CustomProgressBar></CustomProgressBar>
                                    <br/>
                                </div>

                            }
                            <Row>
                                <Col md={12}>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                </Col>
                                <Col md={4} style={{ textAlign: "right" }}>
                                    {
                                        this.state.Invoice.Balance != 0 &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="sendInvoiceToolTip">Email Invoice</Tooltip>}>
                                            <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleSendInvoice(e)} style={{ marginRight: '15px' }}> <i className="fa fa-envelope" ></i> </Button>
                                        </OverlayTrigger>
                                    }                                   
                                    <ReactToPrint
                                        trigger={() =>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>}>
                                                <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '15px' }}> <i className="fa fa-print" ></i> </Button>
                                            </OverlayTrigger>
                                        }
                                        content={() => this.componentRef}
                                    />
                                    <div style={{ display: "none" }}> <ComponentToPrint ref={el => (this.componentRef = el)} dataFromParent={this.state.showHTMLDiv ? this.state.InvoiceRawHtml : null} /> </div>
                                  
                                    {
                                        this.state.Invoice.Balance != 0 &&
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="collectPaymnetToolTip">Collect Payment</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePaymentInvoice(e)} style={{ marginRight: '15px' }}> <i className="fa fa-credit-card" ></i> </Button>
                                    </OverlayTrigger>
                                    }
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="collectPaymnetToolTip">Email Stats</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleEmailStatsClick(e)} style={{ marginRight: '15px' }}> <i className="fa fa-pie-chart" ></i> </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="collectPaymnetToolTip">Invoices</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleGoBackClick(e)} style={{ marginRight: '15px' }}> <i className="fa fa-newspaper-o" ></i> </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {
                                        this.state.showHTMLDiv ? 
                                         <div>  
                                            <div dangerouslySetInnerHTML={this.rawMarkup(this.state.Invoice || null)} />
                                             {
                                                    (this.state.Invoice.Balance == 0 || this.state.Invoice.Balance == 0.00
                                                    || this.state.Invoice.Balance == "0" || this.state.Invoice.Balance == "0.00") &&
                                                    <div style={styles.watermark}>
                                                    </div>
                                                }
                                         </div>
                                         : <div id="pdf-canvas-detail" height="0"> </div>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showSendInvoiceModal}
                                        onHide={() => this.setState({ showSendInvoiceModal: false })}
                                    // style={{ marginTop: "200px" }}
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.Invoice.CustomerRef.name || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.Invoice.DocNumber || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.Invoice.Balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer Email :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.Invoice.BillEmail.Address}</h5>
                                                        {/* <FormGroup >
                                                            <FormControl
                                                                value={this.state.Invoice.BillEmail.Address || null}
                                                                placeholder="Email"
                                                                type="email"
                                                                autoComplete="off"
                                                                className={this.state.sendInvoiceClassName}
                                                                onChange={event => this.validateEmail(event)}
                                                            />
                                                            {this.state.errorSendInvoiceEmail}
                                                        </FormGroup> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleSendInvoiceEmail(e)}>Send</Button>
                                        </Modal.Footer>                                        

                                    </Modal>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {this.state.showEmailStatsModal &&
                                        <EmailStatsPanel showEmailStatsModal={this.handleEmailStatsClick} parentCallback={this.callbackFunction} Invoice={this.state.Invoice} AppId={ApplicationIDEnum.NMIInvoicing}/>
                                    }
                                </Col>
                            </Row>

                        </div>
                    }
                />
            </div>
        )
    }
}

export default Detail;

class ComponentToPrint extends React.Component {
    rawMarkup(value) {
        //const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: value };
    };

    render() {
        return (            
            <div>
            { this.props.dataFromParent != null &&
                <div dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />
            }
            { this.props.dataFromParent == null &&                
                 <div id="pdf-canvas-detail2" height="0"></div> 
            }            
            </div>
        );
    }
}       