/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { BASE_URL, StorageEnum } from "utils/constant.js";

import {
  Nav,
  NavDropdown,
  MenuItem
} from "react-bootstrap";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
    }
  }

  componentDidMount() {

  }
  handleUserLogout(e) {   
    localStorage.clear();
    window.location.href = BASE_URL + "#/account/login";
    e.preventDefault();
  };

  render() {
    return (
      <div >
        {!(window.location.href.indexOf("adminlogin") > -1) &&
          <div >
            <Nav pullRight  >
              <NavDropdown
                eventKey={4}
                title={
                  <div>
                    <i className="fa fa-list" />                    
                    <p className="hidden-md hidden-lg">
                      More
                  <b className="caret" />
                    </p>
                  </div>
                }
                noCaret
                id="basic-nav-dropdown-3"
                bsClass="dropdown-with-icons dropdown"
              >
               
                <MenuItem eventKey={4.5} onClick={e => this.handleUserLogout(e)}>
                  <i className="fa fa-user-times" /> Log out             
                </MenuItem>
              </NavDropdown>

            </Nav>
            <div className="customUserLabel"> 
              <div className="fa fa-user"></div> <span><b>
                    {localStorage.getItem("FULLNAME") || ""}
                  </b>
                  </span>
            </div>
          </div>
        }
      </div>
    );
  }
}
export default HeaderLinks;
