import React from 'react';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import cardSwipe from "assets/img/icons/cardswipe.gif";
import {
    Row,
    Col,
    Modal,

} from "react-bootstrap";

class Bolt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBoltPaymentModal: false,
            ShowPopupProgressBar: false,
            Amount: 0.00,
        }

    }

    componentDidMount() {
        this.handlerBolt();
    }

    handlerBolt = () => {
        if (this.props != null || this.props != undefined) {
            this.state.showBoltPaymentModal = this.props.showBoltPaymentModal
            this.state.Amount = this.props.Amount
        }
    }

    handlerCloseModal = () => {
        this.props.parentCallback(false);
        this.setState({ showBoltPaymentModal: false });
    }

    
    render() {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <Modal
                            show={this.state.showBoltPaymentModal}
                            onHide={() => this.setState({ showBoltPaymentModal: false })}
                            backdrop="static"
                        >
                            {this.state.ShowPopupProgressBar &&
                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                            {/* <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                            </Modal.Header> */}
                            <Modal.Body>
                                <div>
                                    <Row>
                                        <Col xs={12} sm={12} md={2}>
                                            <h4><strong> Charging Amount: </strong></h4>
                                        </Col>
                                        <Col style={{ textAlign: "right" }} xs={12} sm={12} md={3}>
                                            <h4><strong>${parseFloat(this.state.Amount).toFixed(2)}</strong></h4>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="modal-body text-center" >
                                    <img src={cardSwipe} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.handlerCloseModal()}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Bolt   