import React from 'react';
import ReactToPrint from 'react-to-print';
import Card from "components/Card/Card.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import SessionTimeout from 'components/SessionTimeout';
import paidimage from "assets/img/paidimage.png";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
} from "react-bootstrap";
//import ThreeHorseLoading from 'react-loadingg/lib/ThreeHorseLoading';
import EmailStatsPanel from 'views/Components/EmailStatsPanel';
import { createMuiTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const styles = {
    watermark: {
        content: "",
        backgroundImage: `url(${paidimage})`,
        opacity: "0.2",
        top: "32%",
        left: "7%",
        bottom: "0",
        right: "0",
        position: "absolute"
    },
}

// const LazyReactToPrint = lazy(() => import('react-to-print'));

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 18
            },
        });
        this.state = {
            ShowProgressBar: false,
            errorCode: null,
            errorMessage: "",
            BillEmail: {
                Address: ""
            },
            Invoice: {
                CustomerRef: {
                    name: "",
                    value: ""
                },
                Balance: 0,
                DocNumber: "",
                Id: "",
                BillEmail: {
                    Address: ""
                }
            },
            InvoiceRawHtml: null,
            docNumber: "",
            showSendInvoiceModal: false,
            showAttachmentModal: false,
            showTextSMSInvoiceModal: false,
            showEmailStatsModal: false,
            showActionButtons: "none",
            showInvoiceSendButton: "none",
            defaultPhoneNumberCheckBox: true,
            isInvoiceLinkCreated: false,
            invoicePaymentLink: "",
            popupErrorMessage: "",
            ShowPopupProgressBar: false,
            customerPhoneNumber: "",
            showHTMLDiv: true,
            isAuthenticated: true,
            queryFromDate: "",
            queryToDate: "",
            invoices: [],
            rawInvoiceData:[],
            statusFilter: {},
            attachmentColumns: [  
                {
                    title: 'File Name',
                    field: 'fileName',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(80%)'
                    },
                    cellStyle: {
                        width: 'calc(80%)'
                    },
                },
                {
                   // title: 'Action',
                    field: 'download',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(20%)'
                    },
                    cellStyle: {
                        width: 'calc(20%)'
                    },
                }
            ],
            attachmentOptions: {
                paging: false,
                toolbar: false,
                showTextRowsSelected: false,
               
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,                
            },
            attachmentData:[]
        }
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }

    componentDidMount() {        
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }

        var invoiceID = this.getInvoiceIDFromURL();
        if (invoiceID != null) {
            this.getInvoicePreview(invoiceID);
        }

        let Permissions = JSON.parse(localStorage.getItem(StorageEnum.PERMISSIONS));
        if (Permissions != null && Permissions != undefined && Permissions["invoices"] != undefined && Permissions["invoices"][1]["sendemail"] == false) {
            this.setState({ showInvoiceSendButton: "none" });
        }
        else {
            this.setState({ showInvoiceSendButton: "" });
        }
        if (Permissions != null && Permissions != undefined && Permissions["invoices"] != undefined && Permissions["invoices"][2]["sendsms"] == false) {
            this.setState({ showTextSMSInvoiceButton: "none" });
        }
        else {
            this.setState({ showTextSMSInvoiceButton: "" });
        }
        if (Permissions != null && Permissions != undefined && Permissions["invoices"] != undefined && Permissions["invoices"][3]["collectpayment"] == false) {
            this.setState({ showActionButtons: "none" });
        }
        else {
            this.setState({ showActionButtons: "" });
        }
        if( this.props.location.state)
        {
            this.state.queryFromDate =this.props.location.state.queryFromDate;
            this.state.queryToDate =this.props.location.state.queryToDate;
            this.state.invoices = this.props.location.state.invoices;
            this.state.rawInvoiceData = this.props.location.state.rawInvoiceData;
            this.state.statusFilter = this.props.location.state.statusFilter;
        }

        document.getElementById("loaderDiv").style.display = "none";
    }

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            ...prevState,
            Invoice: {
                ...prevState.Invoice,
                BillEmail: {
                    ...prevState.Invoice.BillEmail,
                    Address: value
                }
            }
        }));

        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendInvoiceEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )

            });
            this.setState({
                sendInvoiceClassName: "error"
            });

        }
        else {
            this.setState({ errorSendInvoiceEmail: null });
            this.setState({ sendInvoiceClassName: "valid" });
        }
    }

    handleSendInvoiceEmail(e) {
        e.preventDefault();
        const payload = {
            "ToEmails": [this.state.Invoice.BillEmail.Address],
            "Invoices": [this.state.Invoice]
        }
        this._postEmailInvoiceRequest(payload);
    }

    getInvoiceIDFromURL() {
        const url = window.location.href;
        const path = url.split("/");
        const invoiceID = (path[path.length - 1] || "");
        return invoiceID;
    }

    getInvoicePreview(invoice_id) {
        this.setState({ ShowProgressBar: true });
        const params = {
            "invoice_id": invoice_id
        }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }

    getInvoiceAttachment(invoice_id) {       
        const params = {
            "invoice_id": invoice_id
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoiceAttachmentRequest(query);
    }

    _getInvoiceAttachmentRequest = (params = "") => {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 
        NetworkService.getInvoiceAttachmentRequest(app_id, params)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null && data.data != null && data.data.items.length > 0) {  
                    const newSeries = [];
                    data.data.items.map((item, index) => {
                        const data = {
                            index: index,
                            fileName: item.filename.split('/')[item.filename.split('/').length-1],
                            download: <a  href={item.uri} target='_blank' download={item.filename.split('/')[item.filename.split('/').length-1]}>
                                        Download </a>                           
                        }
                        newSeries.push(data);            
                    });
                    this.setState({ attachmentData: newSeries });
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);

    }

    setResult = (data) => {
        this.setState({ InvoiceRawHtml: data.data.html || null });
        if (data.data.invoice.length > 0 && data.data.invoice[0].BillEmail == null) {
            data.data.invoice[0].BillEmail = this.state.BillEmail;
        }

        data.data.invoice.map(data => {
            this.setState({ Invoice: data })
        });

        const content_type = data.data.content_type
        if (content_type === "application/html") {
            this.setState({ showHTMLDiv: true })
        } else {
            this.setState({ showHTMLDiv: false })
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas-detail");
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas-detail2");
        }

    }

    /**
     * API Calling
     */
    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setResult(data);
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);

    }

    /** POST request to Email invoice to customer */
    _postEmailInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postEmailInvoiceRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showSendInvoiceModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    rawMarkup(value) {
        // const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: this.state.InvoiceRawHtml };
    };

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {
            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    handleSendInvoice(e) {
        this.setState({ showSendInvoiceModal: true });
    }

    handleAttachmentClick(e) {        
        this.setState({attachmentData:[]});
        this.setState({ showAttachmentModal: true });
        this.getInvoiceAttachment(this.state.Invoice.Id);
    }

    handleTextSMSInvoice(e) {
        this.setState({ showTextSMSInvoiceModal: true });
    }

    handleTextSMSKeyPressInput(e) {
        var charCode = (e.which) ? e.which : e.keyCode;
        if (charCode === 13 && this.state.phoneNumberState === "valid") {
            this.handleTextSMSInvoiceRequest(e);
        }
    };

    handleCreatePaymentLinkPopup(e){
        e.preventDefault();
        this.setState({ isInvoiceLinkCreated: false });
        this.setState({ popupErrorMessage: "" });
        this.setState({ invoicePaymentLink: "" });  
        
        this.setState({ showCreatePaymentLinkInvoiceModal: true });
    }

    handleCreatePaymentLinkInvoice(e){
        let invoice = this.state.Invoice; 
        const payload = {               
                "customer_id": invoice.CustomerRef.value,
                "customer_name": invoice.CustomerRef.name || null,
                "invoice_id": invoice.Id,                
                "invoice_number": invoice.DocNumber || null,
            }
            this._postCreatePaymentLinkInvoiceRequest(payload);
    }

    _postCreatePaymentLinkInvoiceRequest(data){
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ isInvoiceLinkCreated: false });
        this.setState({ popupErrorMessage: "" });
        this.setState({ invoicePaymentLink: "" });  

        const app_id = ApplicationIDEnum.QuickBooksOnline;

        NetworkService.postInvoiceLinkRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });                

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.statuscode == 0){
                        this.setState({ popupErrorMessage: data.statusmessage });    
                        this.setState({ invoicePaymentLink: data.data.url });  
                        this.setState({ isInvoiceLinkCreated: true });
                    }
                    else{
                        this.setState({ popupErrorMessage: data.statusmessage });
                    }
                }
                else {
                    this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
                }                
                setTimeout(() => {
                    this.setState({ popupErrorMessage: "" })
                }, 6000);
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ popupErrorMessage: "" })
                }, 5000);
            });
    }

    copyLinkToClipboard (e) {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        var copyText = document.getElementById("txtInvoicePaymentLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);

        el.value = copyText.value;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        this.setState({ errorCode: 0 });
        this.setState({ popupErrorMessage: "Invoice payment link copied successfully." });
        setTimeout(() => {
            this.setState({ popupErrorMessage: "" })
        }, 6000);
    }

    onPhoneNumberBlur() {
        if (this.state.phoneNumberState === "" || this.state.phoneNumberState === "error") {
            if (this.state.customerPhoneNumber == "") {
                this.setState({
                    phoneNumberErrorMessage: (
                        <small className="text-danger">
                            Customer Phone Number is required.
                        </small>
                    )
                });
            } else {
                this.setState({
                    phoneNumberErrorMessage: (
                        <small className="text-danger">
                            Invalid Phone Number, must be greater than or equal to 7 digits.
                        </small>
                    )
                });
            }

        }
        else {
            this.setState({ phoneNumberState: "valid" });
            this.setState({ phoneNumberErrorMessage: null });
        }
    }

    handlePhoneNumberCheckBox(e) {
        var cbValue = e.target.checked;
        this.setState({ defaultPhoneNumberCheckBox: cbValue, customerPhoneNumber: "" });

        if (cbValue) {
            this.setState({ phoneNumberState: "valid" });
        }
        else {
            this.setState({ phoneNumberState: "" });
        }
        this.setState({ phoneNumberErrorMessage: "" });
    }

    handleTextSMSInvoiceRequest(e) {
        if (this.state.defaultPhoneNumberCheckBox ||
            (this.state.customerPhoneNumber && this.state.phoneNumberState === "valid")) {
            e.preventDefault();
            let invoice = this.state.Invoice
            let customer_phone = null;
            if (!this.state.defaultPhoneNumberCheckBox) {
                customer_phone = "+1" + this.state.customerPhoneNumber;
            }
            const payload = {
                "To": customer_phone,
                "customer_id": invoice.CustomerRef.value,
                "customer_name": invoice.CustomerRef.name || null,
                "invoice_id": invoice.Id,
                "due_date": invoice.DueDate,
                "doc_number": invoice.DocNumber || null,
                "amount": invoice.Balance,
                "message": null,
                "phone_number": customer_phone
            }
            this._postTextSMSInvoiceRequest(payload);
        }
        else if (this.state.phoneNumberState === "") {
            this.setState({
                phoneNumberErrorMessage: (
                    <small className="text-danger">
                        Customer Phone Number is required.
                    </small>
                )
            });
        }
        else {
            this.setState({
                phoneNumberErrorMessage: (
                    <small className="text-danger">
                        Invalid Phone Number, must be greater than or equal to 7 digits.
                    </small>
                )
            });
        }
    }

    _postTextSMSInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.QuickBooksOnline;

        NetworkService.postInvoiceTextSMS(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showTextSMSInvoiceModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    handlePaymentInvoice(e) {
        e.preventDefault();

        const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));

        if (data_tokenize.PPType.toLowerCase() === "cardconnect") {
            this.props.history.push({
                pathname: '/invoice/ccpayment',
                state: { invoiceData: this.state.Invoice }
            });
        }
        else if (data_tokenize.PPType.toLowerCase() === "nmi") {
            this.props.history.push({
                pathname: '/invoice/nmipayment',
                state: { invoiceData: this.state.Invoice }
            });
        }
    }

    handleTemplateClick(e) {

    }

    handleProcessorSetupClick(e) {

    }

    handleGoBackClick(e) {
        //this.props.history.push('/invoice/invoices');
        this.props.history.push({
                pathname: '/invoice/invoices',
                state: { queryFromDate: this.state.queryFromDate, queryToDate: this.state.queryToDate, invoices: this.state.invoices, rawInvoiceData: this.state.rawInvoiceData, invoice_status: this.state.statusFilter}
            });      

        e.preventDefault();
    }

    handleEmailStatsClick(e) {
        if (this.state.showEmailStatsModal) {
            this.setState({ showEmailStatsModal: false });
            setTimeout(() => {
                this.setState({ showEmailStatsModal: true });
            }, 200);
        }
        else {
            this.setState({ showEmailStatsModal: true });
        }
        // e.preventDefault();
    }

    callbackFunction = (childData) => {
        this.setState({ showEmailStatsModal: childData })
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState({ customerPhoneNumber: value });
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    // title={"Invoice # " + this.state.docNumber}
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            {this.state.ShowProgressBar &&
                                <div>
                                    <CustomProgressBar></CustomProgressBar>
                                    <br />
                                </div>

                            }
                            <Row>
                                <Col md={12}>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    
                                </Col>
                                <Col md={6} style={{ textAlign: "right" }}>
                                    {
                                        this.state.Invoice.Balance != 0 && 
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="sendInvoiceToolTip">Email Invoice</Tooltip>}>
                                            <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleSendInvoice(e)} style={{ marginRight: '15px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-envelope" ></i> </Button>
                                        </OverlayTrigger>
                                    }
                                    {
                                        this.state.Invoice.Balance != 0  &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="sendInvoiceToolTip">Text/SMS Invoice</Tooltip>}>
                                            <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleTextSMSInvoice(e)} style={{ marginRight: '10px', display: this.state.showTextSMSInvoiceButton }}> <i className="fa fa-comments" ></i> </Button>
                                        </OverlayTrigger>
                                    }
                                    {
                                        this.state.Invoice.Balance != 0  &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="sendInvoiceToolTip">Create Payment Link</Tooltip>}>
                                            <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreatePaymentLinkPopup(e)} style={{ marginRight: '10px', display: this.state.showCreatePaymentLinkButton }}> <i className="fa fa-link" ></i> </Button>
                                        </OverlayTrigger>
                                    }
                                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id="invoiceTemplateToolTip">Invoice Template</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleTemplateClick(e)} style={{ marginRight: '15px' }}> <i className="fa fa-file-text" ></i> </Button>
                                    </OverlayTrigger> */}
                                    <ReactToPrint
                                        trigger={() =>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>}>
                                                <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '15px' }}> <i className="fa fa-print" ></i> </Button>
                                            </OverlayTrigger>
                                        }
                                        content={() => this.componentRef}
                                    />
                                    <div style={{ display: "none" }}> <ComponentToPrint ref={el => (this.componentRef = el)} dataFromParent={this.state.showHTMLDiv ? this.state.InvoiceRawHtml : null} /> </div>

                                    {
                                        this.state.Invoice.Balance != 0  && 
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="collectPaymnetToolTip">Collect Payment</Tooltip>}>
                                            <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePaymentInvoice(e)} style={{ marginRight: '15px', display: this.state.showActionButtons }}> <i className="fa fa-credit-card" ></i> </Button>
                                        </OverlayTrigger>
                                    }                                   
                                    
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="viewAttachmentToolTip">View Attachments</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleAttachmentClick(e)} style={{ marginRight: '15px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-paperclip" style={{fontSize:"18px"}}></i> </Button>
                                    </OverlayTrigger>                                    
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="viewEmailStatsToolTip">View Email Stats</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon"  onClick={e => this.handleEmailStatsClick(e)} style={{ marginRight: '15px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-pie-chart" ></i></Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="GoBacktoInvoicesStateToolTip">Go Back to Invoices State</Tooltip>}>
                                        <Button  bsStyle="primary" fill onClick={e => this.handleGoBackClick(e)} style={{ marginRight: '15px' }}> <i className="fa fa-arrow-left" > </i> Back </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {
                                        this.state.showHTMLDiv ?
                                            <div>
                                                <div dangerouslySetInnerHTML={this.rawMarkup(this.state.Invoice || null)} />
                                                {
                                                    (this.state.Invoice.Balance == 0 || this.state.Invoice.Balance == 0.00
                                                        || this.state.Invoice.Balance == "0" || this.state.Invoice.Balance == "0.00") &&
                                                    <div style={styles.watermark}>
                                                    </div>
                                                }
                                            </div>
                                            : <div id="pdf-canvas-detail" height="0"> </div>
                                    }
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showAttachmentModal}
                                        onHide={() => this.setState({  showAttachmentModal: false })}                                    
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Invoice Attachments</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <MuiThemeProvider theme={this.theme}>                                                   
                                                    <MaterialTable
                                                        columns={this.state.attachmentColumns}
                                                        data={this.state.attachmentData}   
                                                        options={this.state.attachmentOptions}                                             
                                                    />
                                                </MuiThemeProvider>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showAttachmentModal: false })}>Close</Button>                                            
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showSendInvoiceModal}
                                        onHide={() => this.setState({ showSendInvoiceModal: false })}
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} xs={5} sm={5} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} xs={7} sm={7} className="text-left">
                                                        <h5>{this.state.Invoice.CustomerRef.name || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} xs={5} sm={5} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} xs={7} sm={7} className="text-left">
                                                        <h5>{this.state.Invoice.DocNumber || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} xs={5} sm={5} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} xs={7} sm={7} className="text-left">
                                                        <h5>${parseFloat(this.state.Invoice.Balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} xs={5} sm={5} className="text-left">
                                                        <h5>Customer Email :</h5>
                                                    </Col>
                                                    <Col md={8} xs={7} sm={7}>
                                                        <FormGroup >
                                                            <FormControl
                                                                value={this.state.Invoice.BillEmail.Address || null}
                                                                placeholder="Email"
                                                                type="email"
                                                                autoComplete="off"
                                                                className={this.state.sendInvoiceClassName}
                                                                onChange={event => this.validateEmail(event)}
                                                            />
                                                            {this.state.errorSendInvoiceEmail}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleSendInvoiceEmail(e)}>Send</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showTextSMSInvoiceModal}
                                        onHide={() => this.setState({ showTextSMSInvoiceModal: false })}
                                        backdrop="static"
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Text/SMS Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <h5>Customer Name:</h5>
                                                    </Col>
                                                    <Col md={7} className="text-left">
                                                        <h5>{this.state.Invoice.CustomerRef.name || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={5} className="text-left">
                                                        <h5>Invoice Number:</h5>
                                                    </Col>
                                                    <Col md={6} className="text-left">
                                                        <h5>{this.state.Invoice.DocNumber || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <h5>Amount to collect:</h5>
                                                    </Col>
                                                    <Col md={7} className="text-left">
                                                        <h5>${parseFloat(this.state.Invoice.Balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <h5>Use Default Number:</h5>
                                                    </Col>
                                                    <Col md={7} className="text-left">
                                                        <CustomCheckbox
                                                            name="defaultPhoneNumberCheckBox"
                                                            checked={this.state.defaultPhoneNumberCheckBox}
                                                            // labelText={"Create Profile"}
                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                            formControlProps={{
                                                                onChange: (e) => { this.handlePhoneNumberCheckBox(e) }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                {!this.state.defaultPhoneNumberCheckBox &&
                                                    <Row>
                                                        <Col md={5} className="text-left">
                                                            <h5>Phone Number:</h5>
                                                        </Col>
                                                        <Col md={7} >
                                                            <InputGroup>
                                                                <InputGroup.Addon style={{ border: "1px solid #ccc", backgroundColor: "#f5f5f5" }}><span>{" +1 "}</span></InputGroup.Addon>
                                                                <FormControl
                                                                    value={this.state.customerPhoneNumber}
                                                                    type="number"
                                                                    placeholder="Customer Phone Number"
                                                                    className={this.state.phoneNumberState}
                                                                    name="phoneNumber"
                                                                    onChange={event =>
                                                                        this.change(event, "phoneNumber", "length", 7)
                                                                    }
                                                                    onKeyPress={event => this.handleTextSMSKeyPressInput(event)}
                                                                    onBlur={e => this.onPhoneNumberBlur()}
                                                                />
                                                            </InputGroup>
                                                            {this.state.phoneNumberErrorMessage}
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showTextSMSInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleTextSMSInvoiceRequest(e)}>Send SMS</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            {/* Create Payment Link Modal */}
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showCreatePaymentLinkInvoiceModal}
                                        onHide={() => this.setState({ showCreatePaymentLinkInvoiceModal: false })}
                                        backdrop="static"
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Create Payment Link</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {this.state.popupErrorMessage &&
                                                <div>
                                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                        <span>
                                                            {this.state.popupErrorMessage}
                                                        </span>
                                                    </Alert>
                                                </div>
                                            }
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.Invoice.CustomerRef.name}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.Invoice.DocNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.Invoice.Balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                {this.state.isInvoiceLinkCreated && !this.state.ShowPopupProgressBar &&
                                                    <Row>
                                                        <Col md={9} className="text-left">
                                                            <FormControl
                                                                    value={this.state.invoicePaymentLink}
                                                                    type="text"
                                                                    id="txtInvoicePaymentLink"
                                                                    readOnly={true}
                                                                    style={{ height: "30px", marginTop: "10px" }}                                                                
                                                                />
                                                        </Col>
                                                        <Col md={3} className="text-left">
                                                            <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={e => this.copyLinkToClipboard(e)}>
                                                                <i className="fa fa-copy"></i> Copy Link
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showCreatePaymentLinkInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleCreatePaymentLinkInvoice(e)}>Create Link</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12} sm={12}>
                                    {this.state.showEmailStatsModal &&
                                        <EmailStatsPanel showEmailStatsModal={this.handleEmailStatsClick} parentCallback={this.callbackFunction} Invoice={this.state.Invoice} AppId={ApplicationIDEnum.QuickBooksOnline}/>
                                    }
                                </Col>
                            </Row>

                        </div>
                    }
                />
            </div>
        )
    }
}

export default Detail;

class ComponentToPrint extends React.Component {
    rawMarkup(value) {
        //const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: value };
    };

    render() {
        return (
            <div>
                { this.props.dataFromParent != null &&
                    <div dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />
                }
                { this.props.dataFromParent == null &&
                    <div id="pdf-canvas-detail2" height="0"></div>
                }
            </div>
        );
    }
}