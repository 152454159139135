/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col, Media, FormControl, ControlLabel, FormGroup, Alert } from "react-bootstrap";
// react component that creates a dropdown menu for selection
import Select from "react-select";
import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL } from "utils/constant.js";
import { Redirect } from "react-router-dom";
import NetworkService from "services/NetworkService";
import Common from "utils/Common.js";

const styles = {
  amountLabel: {
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginTop: "20px",
    minHeight: "auto",
    marginBottom: "0px",
  },
}

class ResellerRegistration extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      ShowProgressBar: false,
      isFormValid: false,
      errorMessage: "",
      errorCode: 1,
      firstnameState: "",
      lastnameState: "",
      companynameState: "",
      addressState: "",
      reselleraffiliationState: "",
      emailState: "",
      phonenumberState: "",
     // passwordState: "",
      paymentmethodState: "",
      firstNameErrorMessage: "",
      companyNameErrorMessage: "",
      addressErrorMessage: "",
      reselleraffiliationErrorMessage: "",
      lastNameErrorMessage: "",            
      emailErrorMessage: "",
      phonenumberErrorMessage: "",
      //passwordErrorMessage: "",
      errorRegistrationMessage: "",  
      paymentmethodErrorMessage: "",
     // confirmpasswordState: "",      
     // confirmpasswordErrorMessage: "",
      user: {
        firstname: "",
        lastname: "",
        companyname: "",
        address: "",
        reselleraffiliation: "",
        email: "",
        phonenumber: "",
        paymentMethodType: "",
        password: "",
        status: "active"       
      }
    };
  }

  componentDidMount() {
    document.getElementById("loaderDiv").style.display = "none";
    //this.textInput.current.focus(); // one important change here is that we need to access the element via current.
  }

  postSignup(state) {
    if (this.isValidated()) {
      let formData = new FormData();
      formData.append("first_name", state.user.firstname);
      formData.append("last_name", state.user.lastname);
      formData.append("company_name", state.user.companyname);  
      formData.append("address", state.user.address);  
      formData.append("reseller_affiliation", state.user.reselleraffiliation);            
      formData.append("email", state.user.email);
      formData.append("phone_number", state.user.phonenumber);
      formData.append("password", state.user.phonenumber.slice(-4));
      formData.append("processor_type", state.user.paymentMethodType.value);
      formData.append("status", true);  

      this.setState({ ShowProgressBar: true });
      this.setState({ errorRegistrationMessage: "" });     
      this.postSelfResellerSignupRequest(formData);
    }
    else {
      console.log('not valid');
    }
  }

  
  
    loginLinkClick(e) {
        return (<Redirect to={BASE_URL} />);
    }

    
    //Post email reminder    
    postSelfResellerSignupRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorRegistrationMessage: "" });

        NetworkService.postResellerBoardingRequest(data)
        .then(data => {
            this.setState({ ShowProgressBar: false });

            if (data != null && data.statuscode == 0) {
                global.successfullRegistration = true;
                //global.successfullRegistration = true;

                this.props.history.push('/auth/login-page');
            } else {
                console.log(data.statusmessage);
                global.successfullRegistration = false;
                //global.successfullRegistration = false;
                this.setState({ errorRegistrationMessage: data.statusmessage });
            }
        }).catch(error => {
            global.successfullRegistration = false;
            this.setState({ errorRegistrationMessage: "Oops! Something went wrong, please try again." });
            this.setState({ ShowProgressBar: false });
            setTimeout(() => {
            this.setState({ errorRegistrationMessage: "" })
            }, 5000);
        });
    }
    
    isValidated() {
        this.setState({ isFormValid: true });
        if (this.state.firstnameState === "" || this.state.firstnameState === "error") {
            this.setState({
                firstnameErrorMessage: (
                <small className="text-danger">
                    First Name is Required.
                </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ firstnameErrorMessage: null });      
        }
        if (this.state.lastnameState === "" || this.state.lastnameState === "error") {
            this.setState({
                lastnameErrorMessage: (
                <small className="text-danger">
                    Last Name is Required.
                </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ lastnameErrorMessage: null });
        }

        if (this.state.emailState === "" || this.state.emailState === "error") {
            this.setState({
                emailErrorMessage: (
                <small className="text-danger">
                    Email is Required.
                </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ emailErrorMessage: null });
        }

        if (this.state.addressState === "" || this.state.addressState === "error") {
            this.setState({
                addressErrorMessage: (
                <small className="text-danger">
                    Address is Required.
                </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ addressErrorMessage: null });
        }

        if (this.state.companynameState === "" || this.state.companynameState === "error") {
            this.setState({
                companynameErrorMessage: (
                <small className="text-danger">
                    Company is Required.
                </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ companynameErrorMessage: null });
        }

        if (this.state.phonenumberState === "" || this.state.phonenumberState === "error") {
        this.setState({
            phonenumberErrorMessage: (
            <small className="text-danger">
                Phone Number is Required.
            </small>
            )
        });
        this.setState({ isFormValid: false });
        }
        else {
        this.setState({ phonenumberErrorMessage: null });
        }

        // var passwordRE = new RegExp(Common.getPasswordRegex());
        // if (passwordRE.test(this.state.user.password) === false) {
        //         this.setState({passwordErrorMessage: Common.getPasswordErrorMessage(), isFormValid: false});
        // } 
        // else {
        //     this.setState({ passwordErrorMessage: null});
        // }

        // if (passwordRE.test(this.state.user.confirmpassword) === false) {
        //         this.setState({confirmpasswordErrorMessage: Common.getPasswordErrorMessage(), isFormValid: false});
        // } 
        // else {
        //     this.setState({ confirmpasswordErrorMessage: null});
        // }

        // if (this.state.user.password != "" && this.state.user.confirmpassword != "" && this.state.user.password != this.state.user.confirmpassword) {
        // this.setState({
        //     confirmpasswordErrorMessage: (
        //     <small className="text-danger">
        //         Password and Confirm Password do not match.
        //     </small>
        //     )
        // });
        // this.setState({ isFormValid: false });
        // }      

        if (this.state.reselleraffiliationState === "" || this.state.reselleraffiliationState === "error") {
        this.setState({
            reselleraffiliationErrorMessage: (
            <small className="text-danger">
                Reseller Affiliation is Required.
            </small>
            )
        });
        this.setState({ isFormValid: false });
        }
        else {
        this.setState({ reselleraffiliationErrorMessage: null });
        }


        if (this.state.paymentmethodState === "" || this.state.paymentmethodState === "error") {
            this.setState({
                paymentmethodErrorMessage: (
                    <small className="text-danger">
                        Payment Method is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ paymentmethodErrorMessage: null });
        }

        return this.state.isFormValid;
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });                   
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                [stateName]: value
            }
        }));

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

  ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                emailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });
            this.setState({
                emailState: "error"
            });
        }
        else {
            this.setState({ emailState: "valid" });
            this.setState({ emailErrorMessage: null });
        }
    }

    handleSetPaymentMethod(event) {
        const { label, value } = event;
        this.setState({ paymentMethodType: value });
    }

  render() {
    const { classes } = this.props;
    const headerTextClass = {
      color: "#ffffff",
      textAlign: "center",
      padding: "10px 0 30px"
    }
    const marginBottomStyle = {
      marginBottom: "10px"
    };
    const customButtonStyle = {
      minWidth: "385px",
      marginBottom: "20px"
    };
    const customLinkStyle = {
      color: "#0075c1",
      fontSize: "16px",
    };
    const customSpanStyle = {
      color: "#000000",
      fontSize: "16px",
    };
    const customTextColor = {
      color: "#0075c1",
    };
    return (
      <Grid>
        <Row>
          <Col md={12} >
            <div style={headerTextClass}>
              <h2 style={customTextColor}>The Best Solution for QuickBooks Payments!</h2>
              <h4 style={customTextColor}>Register for free and experience the best QuickBooks Payments and QuickBooks Invoicing</h4>
              <hr />
              {this.state.ShowProgressBar &&
                <CustomProgressBar></CustomProgressBar>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={10} mdOffset={1} style={{ marginTop: "-30px" }}>
            {this.state.errorRegistrationMessage != "" &&

              <Alert bsStyle="danger">
                <span>
                  {this.state.errorRegistrationMessage}
                </span>
              </Alert>
            }

          </Col>
        </Row>
        <Row>
          <Col md={5} >
            <Media style={customTextColor}>
              <Media.Left>
                <div className="icon" style={{ fontSize: 30, marginRight: 30 }}>
                  <i className="fa fa-gears" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading >Real-Time Integration</Media.Heading>
                100% fully integrated real-time platform for your
                Online, Enterprise, and POS version of QuickBooks.
              </Media.Body>
            </Media>
            <Media style={customTextColor}>
              <Media.Left>
                <div className="icon" style={{ fontSize: 30, marginRight: 30 }}>
                  <i className="fa fa-check-circle-o" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>100% Satisfaction Guaranteed</Media.Heading>
                Instant Invoice is the ultimate fully integrated solution for
                QuickBooks Payments and QuickBooks Invoicing with the benefit
                of QuickBooks Next Day Funding.
              </Media.Body>
            </Media>
            <Media style={customTextColor}>
              <Media.Left>
                <div className="icon" style={{ fontSize: 30, marginRight: 30 }}>
                  <i className="fa fa-headphones" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Professional Customer Service</Media.Heading>
                Our goal is to make sure we support you every step of the way.
                If there is an issue, our customer service staff are ready to help
                 you over email or a phone call. We value your business!
              </Media.Body>
            </Media>
          </Col>
          <Col md={1}>
          </Col>
          <Col md={6} style={{ marginTop: (this.state.errorRegistrationMessage != "" || this.state.ShowProgressBar) ? "" : "-30px" }}>
            <Card
              content={
                <div>
                  <br />
                  <Row>
                    <Col md={6}>
                      <FormGroup style={marginBottomStyle}>
                        <ControlLabel>
                          First Name: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="First Name"
                            name="firstname"
                            className={this.state.firstNameState}
                            value={this.state.user.firstname}
                            onChange={event => {
                                this.change(event, "firstname", "length", 2)
                            }
                            }
                        />
                        {this.state.firstnameErrorMessage}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup style={marginBottomStyle}>
                        <ControlLabel>
                          Last Name: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Last Name"
                            name="lastname"
                            className={this.state.lastNameState}
                            value={this.state.user.lastname}
                            onChange={event => {
                                this.change(event, "lastname", "length", 2)
                            }
                            }
                        />
                        {this.state.lastnameErrorMessage}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup style={marginBottomStyle}>
                        <ControlLabel>
                          Email address: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            className={this.state.emailState}
                            value={this.state.user.email}
                            onChange={event =>
                                this.ValidateEmailAddress(event)
                            }
                        />
                        {this.state.emailErrorMessage}

                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup style={marginBottomStyle}>
                        <ControlLabel>
                          Company: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Company Name"
                            name="companyname"
                            className={this.state.lastNameState}
                            value={this.state.user.companyname}
                            onChange={event => {
                                this.change(event, "companyname", "length", 2)
                            }
                            }
                        />
                        {this.state.companynameErrorMessage}

                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                          <ControlLabel>
                          Address: <span className="star">*</span>
                        </ControlLabel>
                          <FormControl
                              type="text"
                              placeholder="Address"
                              name="address"
                              className={this.state.addressState}
                              value={this.state.user.address}
                              onChange={event => {
                                  this.change(event, "address", "length", 2)
                              }
                              }
                          />
                          {this.state.addressErrorMessage}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup style={marginBottomStyle}>
                        <ControlLabel>
                          Phone Number: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                            type="number"
                            placeholder="Phone Number"
                            className={this.state.user.phonenumberState}
                            name="phonenumber"
                            value={this.state.user.phonenumber}
                            onChange={event =>
                                //this.setState({ user: { phonenumber: event.target.value } })
                                this.change(event, "phonenumber", "length", 7)
                            }                                                               
                            type="number"
                        />
                        {this.state.phonenumberErrorMessage}

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col md={6}>
                        <FormGroup>
                            <ControlLabel>
                              Reseller/ISO Affiliation: <span className="star">*</span>
                            </ControlLabel>                            
                            <FormControl
                                type="text"
                                placeholder="Reseller/ISO Affiliation"
                                name="reselleraffiliation"
                                className={this.state.reselleraffiliationState}
                                value={this.state.user.reselleraffiliation}
                                onChange={event => {
                                    this.change(event, "reselleraffiliation", "length", 2)
                                }
                                }
                            />
                            {this.state.reselleraffiliationErrorMessage}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>
                              Processor Affiliation: <span className="star">*</span>
                            </ControlLabel>
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="paymentmethod"
                                value={this.state.user.paymentMethodType}
                                onChange={event => {
                                    this.handleSetPaymentMethod(event);
                                    this.setState({
                                        paymentmethodErrorMessage: null, paymentmethodState: "valid"
                                    });
                                    this.setState(prevState => ({
                                        user: {
                                            ...prevState.user,
                                            paymentMethodType: event
                                        }
                                    }));
                                }
                                }
                                options={[
                                    {
                                        value: "",
                                        label: "Select Processor Affiliation",
                                        isDisabled: true
                                    },
                                    { value: "NMI", label: "NMI" },
                                    { value: "CardConnect", label: "Card Connect" },
                                    // { value: "AuthorizeNet", label: "Authorize Net" }

                                ]}
                                placeholder="Payment Method"
                            />
                            {this.state.paymentmethodErrorMessage}

                        </FormGroup>
                    </Col>
                    
                  </Row>                  
                  {/* <Row>
                    <Col md={6}>
                      <FormGroup style={marginBottomStyle}>
                        <ControlLabel>
                          Password: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          type="password"
                          placeholder="Password"
                          autoComplete="off"
                          name="password"
                          className={this.state.passwordState}
                          value={this.state.user.password}
                          onChange={event =>
                            this.change(event, "password", "length", 6)
                          }
                        />
                        {this.state.passwordErrorMessage}

                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <ControlLabel>
                          Confirm Password: <span className="star">*</span>
                        </ControlLabel>
                        <FormControl
                          type="password"
                          autoComplete="off"
                          placeholder="Password Confirmation"
                          name="confirmpassword"
                          className={this.state.confirmpasswordState}
                          value={this.state.user.confirmpassword}
                          onChange={event =>
                            this.change(event, "confirmpassword", "length", 6)
                          }
                        />
                        {this.state.confirmpasswordErrorMessage}
                      </FormGroup>
                    </Col>
                  </Row> */}

                </div>

              }
              ftTextCenter
              legend={
                <Row>
                  <Col md={12}>
                    <Button bsStyle="primary" style={customButtonStyle} fill onClick={e => this.postSignup(this.state)} >
                      Create Account
                  </Button>
                    <br />
                    <span style={customSpanStyle}>Already member?   </span>
                    <a href="#" id="Login" style={customLinkStyle} value="Login" onClick={e => this.loginLinkClick(e)} >
                      Click here to Login
                    </a>
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
        <br />
      </Grid>
    );
  }
}

export default ResellerRegistration;
