import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    Modal,
    FormControl,
    InputGroup

} from "react-bootstrap";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from "react-select";
import Card from "components/Card/Card.jsx";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Chip from '@material-ui/core/Chip';
import NetworkService from "services/NetworkService";
import {Units} from "views/NMI/unit_of_measure.js"
import { event } from "react-ga";
// import ReactToPrint from 'react-to-print';

class Products extends Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            currency: {},
            currencies: [

                {
                    Value: "USD",
                    Name: "USD"
                }
            ],
            units: Units,
            data: [
                // { invoicenumber: '654541', customername: 'Can dynamically add', invoicedate: '2020-01-01', duedate: '2020-12-05', balance: '$633.21', total: '$633.21', invoicestatus: 'Paid', emailstatus: 'not sent' },
            ],
            columns: [
                {
                    title: 'SKU',
                    field: 'sku',
                    headerStyle: {
                        textAlign: "left"
                    },
                    cellStyle: {
                        textAlign: "left"
                    }
                },
                {
                    title: 'Description',
                    field: 'description',
                    headerStyle: {
                         width: 'calc(30%)',
                        textAlign: "left"
                    },
                    cellStyle: {
                        width: 'calc(30%)',
                        textAlign: "left"
                    }
                },
                {
                    title: 'Commodity Code',
                    field: 'commcode',
                    headerStyle: {                     
                       textAlign: "left"
                   },
                    cellStyle: {
                        
                        textAlign: "left"
                    },
                },
                {
                    title: 'Unit',
                    field: 'unit',
                    headerStyle: {                    
                        textAlign: "left"
                    },
                    cellStyle: {
                        width: 'calc(12%)',
                        textAlign: "left"
                    },
                },
                {
                    title: 'Unit Price',
                    field: 'cost',
                    cellStyle: {                      
                        textAlign: "center"
                    },
                },
                {
                    title: 'Tax Type',
                    field: 'taxType',
                    cellStyle: {                      
                        textAlign: "center"
                    },
                },
                {
                    title: 'Tax Value',
                    field: 'taxValue',
                    cellStyle: {                      
                        textAlign: "center"
                    },
                },
                {
                    title: 'Tax',
                    field: 'tax',
                    cellStyle: {                      
                        textAlign: "center"
                    },
                },
                // {
                //     title: 'Discount',
                //     field: 'discount',
                //     cellStyle: {                      
                //         textAlign: "center"
                //     },
                // },
                {
                    title: 'Total',
                    field: 'total',
                    cellStyle: {                      
                        textAlign: "center"
                    }
                },
            ],
            options: {
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                //  selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"
                }),
            },
            createProductToolTip: <Tooltip id="createProductToolTip">Create New Product</Tooltip>,
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={this.state.createProductToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateProduct(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
            },
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="deleteProductToolTip">Edit Product</Tooltip>}>
                        <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => {this.handleUpdateProduct(rowData, event)}
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="deleteProductToolTip">Delete Product</Tooltip>}>
                        <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => {this.handleDeleteProductPopup(rowData, event)}
                }
            ],
            productModelTitle: "Create Product/Item",
            productInfo: {
                product_id: null,
                product_sku: "",
                product_description: "",
                product_cost: "",
                product_currency: "",
                product_tax_type: "percentage",
                product_tax_value: "0.00",
                product_tax_amount: "0.00",
                product_discount_amount: "0.00",
                product_commodity_code: "",
                product_unit: ""
            },
            productInfoSKUState: "",
            productInfoSKUErrorMessage: "",
            productInfoDescriptionState: "",
            productInfoDescriptionErrorMessage: "",
            taxAmountErrorMessage:"",
            costErrorMessage:"",
            showDeleteProductPopupModal: false,
            deletedProduct:{},
            isNewItemFormValid: false,
            IsPercentage: true,
            IsFixed: false,
            tax: "0.00",
        }
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        document.getElementById("loaderDiv").style.display = "none";
        this.setCurrenciesResult(this.state.currencies);
        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
        this._getItemRequest();
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    setCurrenciesResult(currencies) {
        const newState = [];

        newState.push({ value: "", label: "Select Currency", isDisabled: true });
        currencies.map(data => {
            const x = { value: data["Value"], label: data["Name"] }
            newState.push(x);
        });
        newState.map(curr => {
            if (curr.label == 'USD') {
                this.state.currency = curr;
            }
        });
        this.setState({ currencies: newState });
    }

    handleCreateProduct(e) {
        this.state.productModelTitle = "Create Product/Item";
        this.state.productInfo.product_id= null;
        this.state.productInfo.product_sku = "";
        this.state.productInfo.product_commodity_code = "";
        this.state.productInfo.product_cost = "";
        this.state.productInfo.product_tax_amount = "0.00";
        this.state.productInfo.product_discount_amount = "0.00";
        this.state.productInfo.product_description = "";
        this.state.productInfo.product_currency = {value: "USD", label: "USD" }
        this.state.productInfo.product_unit = { value: "NAR", label: "Number of articles" }
        this.state.productInfoSKUState = "";
        this.state.productInfoDescriptionState = "";
        this.state.productInfoDescriptionErrorMessage = "";
        this.state.costErrorMessage = "";
        this.state.tax = "0.00";
        this.state.isNewItemFormValid = false;
        this.setState({ showNewItemPopupModal: true });
        e.preventDefault();
    }

    handleUpdateProduct(item, e) {
        this.state.productModelTitle = "Edit Product/Item";
        this.state.productInfo.product_id = item.id;
        this.state.productInfo.product_sku = item.sku;
        this.state.productInfo.product_description = item.description;
        this.state.productInfo.product_cost = item.cost;        
        this.state.productInfo.product_currency = {value: "USD", label: "USD" }
        this.state.productInfo.product_tax_amount = item.tax;
        this.state.productInfo.product_discount_amount = "0.00";////item.discount;
        this.state.productInfo.product_commodity_code = item.commcode;
        this.state.productInfo.taxType = item.taxType;
        this.state.productInfo.taxValue = item.taxValue;
        this.setState({IsPercentage: item.taxType == "Percentage" ? true : false});
        this.setState({IsFixed: item.taxType == "Fixed Amount" ? true : false});
        this.setState({tax: item.taxValue});
        if(item.unit == null || item.unit == ""){
            this.state.productInfo.product_unit = { value: "NAR", label: "Number of articles" }
        }
        else{
            this.state.productInfo.product_unit = this.state.units.filter((val) => val.value === item.unit);
        } 
        this.state.productInfoSKUState = "valid";
        this.state.productInfoDescriptionState = "valid";
        this.state.productInfoDescriptionErrorMessage = "";
        this.state.productInfoSKUErrorMessage = "";
        this.state.costErrorMessage = "";
        this.state.taxAmountErrorMessage = "";
        this.state.isNewItemFormValid = true;

        this.setState({ showNewItemPopupModal: true });
        e.preventDefault();
    }

    handleDeleteProductPopup(item, e) {
       console.log(item);
       this.setState({deletedProduct : item});
       this.setState({ showDeleteProductPopupModal: true });
    }

    handleDeleteProductSubmit(item){
        const payload = {product_id: item.id};
        this._postDeleteItemRequest(payload);
    }

    validateNewItem() {
        if (this.state.productInfoSKUErrorMessage === "") {
            if (this.state.productInfoSKUState === "" || this.state.productInfoSKUState === "error") {
                this.setState({
                    productInfoSKUErrorMessage: (
                        <small className="text-danger">
                            SKU is Required.
                        </small>
                    )
                });
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState({
                    productInfoSKUState: "valid",
                    productInfoSKUErrorMessage: ""
                });
            }
        }

        if (this.state.productInfoDescriptionErrorMessage === "") {
            if (this.state.productInfoDescriptionState === "" || this.state.productInfoDescriptionState === "error") {
                this.setState({
                    productInfoDescriptionErrorMessage: (
                        <small className="text-danger">
                            Description is Required.
                        </small>
                    )
                });
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState({
                    productInfoDescriptionState: "valid",
                    productInfoDescriptionErrorMessage: ""
                });
            }
        }
        if (this.state.taxAmountErrorMessage === "" && this.state.costErrorMessage === "" && this.state.productInfoSKUState === "valid" && this.state.productInfoDescriptionState == "valid" && parseFloat(this.state.productInfo.product_cost).toFixed(2)  > 0) {
            this.setState({ isNewItemFormValid: true });
        }
        else {
            this.setState({ isNewItemFormValid: false });
        }
    }

    createItemSubmit = (item) => {   
        
            const payload = {
                "product_id": item.product_id,
                "product_sku": item.product_sku,
                "product_cost": item.product_cost,
                "product_tax_type": item.product_tax_type,
                "product_tax_value": this.state.tax,
                "product_tax_amount": item.product_tax_amount,
                "product_description": item.product_description,
                "product_currency": item.product_currency.value,
                "product_discount_amount": item.product_discount_amount,
                "product_commodity_code": item.product_commodity_code,
                "product_unit_of_measure": item.product_unit.value
            }

            console.log(payload);
            if(item.product_id == null){
                this._postCreateItemRequest(payload);        

            }else{
                this._postUpdateNMIItemRequest(payload);        
            }
    }

    handleInvoiceDetailClick(invoice) {
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        this.props.history.push('/invoice/detail/' + invoice.Id);
    }

    /** API CALLING */
    _postCreateItemRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; 
        NetworkService.postCreateItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        this._getItemRequest();
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    onSetResult = (result) => {
        const newSeries = [];
        result.items.map((invoice, index) => {
            const data = {
                index: index,
                id: invoice.Id,
                invoicenumber: invoice.DocNumber,
                balance: '$' + parseFloat(invoice.Balance).toFixed(2),
                total: '$' + parseFloat(invoice.TotalAmt).toFixed(2),
                invoicestatus: invoice.IsPaid ? <Chip style={{ backgroundColor: "#00e396", color: "white", height: 25 }} label="Paid"></Chip> : parseFloat(invoice.Balance).toFixed(2) < parseFloat(invoice.TotalAmt).toFixed(2) ? <Chip style={{ backgroundColor: "#feb019", color: "white", height: 25 }} label="Partially Paid"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Unpaid"></Chip>,
                action: <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                    <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}> <i className="fa fa-clipboard" ></i> </Button>
                </OverlayTrigger>
            }
            newSeries.push(data);
        });
        this.setState({ data: newSeries });
    }

    _postUpdateNMIItemRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postUpdateNMIItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        this._getItemRequest();
                    }

                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postDeleteItemRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postDeleteItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showDeleteProductPopupModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });                    
                    if (data.statuscode == 0) {
                        this.setState({ errorMessage: "Product deleted successfully." });
                        this._getItemRequest();
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _getItemRequest() {
        this.setState({ ShowProgressBar: true });        
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getItemListingRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                this.setState({ showNewItemPopupModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });                    
                    if (data.statuscode == 0 && data.data != null) {
                        this.setItemsResult(data.data.items);
                    }
                    else{
                        this.setState({ errorMessage: data.statusmessage });
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    setItemsResult(items, isFromCache = false) {
        if (!isFromCache) {
            localStorage.setItem("items", null);
            localStorage.setItem("items", JSON.stringify(items));
        }
        const newState = [];
        items.map((data, index) => {
            const product = {
                index: index,
                id: data['id'],
                sku: data['sku'],
                cost: parseFloat(data['unit_cost']).toFixed(2),
                taxType: data['product_tax_type'] == null ? "Percentage" : data['product_tax_type'] == "percentage" ? "Percentage": "Fixed Amount",
                taxValue: parseFloat(data['product_tax_value']).toFixed(2), 
                tax: parseFloat(data['tax_amount']).toFixed(2),
              //  discount: parseFloat(data['discount_amount']).toFixed(2),
                commcode: data['commodity_code'],
                currency: data['currency_code'],
                description: data['description'],
                unit: data['measure_of_unit'],
                total: parseFloat(data['unit_cost'] + data['tax_amount']).toFixed(2) // - data['discount_amount']).toFixed(2)
            }
            newState.push(product);
        });
        this.setState({ data: newState });
    }

    handleTaxAmountRadio = event => {
        const target = event.target;
        if (target.value === "percentage") {   
            this.setState({IsPercentage: true, IsFixed: false });        
            // this.state.IsPercentage = true;
            // this.state.IsFixed = false;                 
        }
        else{
            this.setState({IsPercentage: false, IsFixed: true });  
            // this.state.IsPercentage = false;
            // this.state.IsFixed = true;
        }             
        this.setState({tax: ""});
        this.setState(prevState => ({                    
            ...prevState,
            productInfo: {
                ...prevState.productInfo,
                product_tax_amount: 0.00,
                product_tax_type: target.value
            }
        }));    
    };

    handleConvFeeAmountChange = e => {
        const { name, value } = e.target;  
        this.handleTaxChange(value);      
    }

    handleTaxChange(value){
        if (this.state.IsPercentage && (parseFloat(value) > parseFloat(99) || parseFloat(value) < parseFloat(0.00))) {              
            this.setState({
                isNewItemFormValid: false,
                taxAmountErrorMessage: 
                    <small className="text-danger">
                        Invalid Tax
                    </small>
            });
            return false;
        }
        else if(this.state.IsPercentage){                                                        
            var totalTaxAmount = parseFloat((value /100) * this.state.productInfo.product_cost).toFixed(2);
            this.setState(prevState => ({                    
                ...prevState,
                productInfo: {
                    ...prevState.productInfo,
                    product_tax_amount: totalTaxAmount,
                }
            }));
        }
        else if (this.state.IsFixed){
            this.setState(prevState => ({                    
                ...prevState,
                productInfo: {
                    ...prevState.productInfo,
                    product_tax_amount: parseFloat(value).toFixed(2),
                }
            }));
        }
    }

    render() {
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>
                                        }
                                        <MaterialTable
                                            //title="Styling with MuiThemeProvider Preview"
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            onSelectionChange={(rows) => {
                                            }
                                            }
                                            components={this.state.components}
                                            actions={this.state.actions}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>

                        </div>
                    }
                />
                <Modal
                    show={this.state.showNewItemPopupModal}
                    onHide={() => this.setState({ showNewItemPopupModal: false })}
                    bsSize="large"  
                    backdrop="static"
                    >
                    {this.state.ShowPopupProgressBar &&
                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                            <CustomProgressBar></CustomProgressBar>
                        </div>
                    }
                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                        <Modal.Title style={{ color: "white" }}>{this.state.productModelTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            SKU: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_sku}
                                            type="text"
                                            placeholder="SKU"
                                            name="product_sku"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_sku: value
                                                    }
                                                }));

                                                if (this.verifyLength(value, 2)) {
                                                    this.setState({
                                                        productInfoSKUState: "valid",
                                                        productInfoSKUErrorMessage: ""
                                                    });
                                                    this.state.productInfoSKUState = "valid"
                                                    this.validateNewItem();
                                                }
                                                else {
                                                    this.setState({
                                                        productInfoSKUErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid SKU.
                                                            </small>
                                                        )
                                                    });
                                                    this.setState({ isNewItemFormValid: false });
                                                }
                                            }
                                            }
                                            onBlur={e => this.validateNewItem()}
                                        />
                                        {this.state.productInfoSKUErrorMessage}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Commodity Code:
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_commodity_code}
                                            type="text"
                                            placeholder="Commodity Code"
                                            name="product_commodity_code"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_commodity_code: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_commodity_code: value
                                                    }
                                                }));

                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Unit:
                                        </div>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="product_unit"
                                            value={this.state.productInfo.product_unit}
                                            onChange={value => {
                                                this.setState(prevState => ({
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_unit: value
                                                    }
                                                }));
                                             }
                                            }
                                            options={this.state.units}
                                            placeholder="Select Unit"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Product Price/Rate: <span className="star">*</span>
                                        </div> 
                                        <FormControl
                                            value={this.state.productInfo.product_cost}
                                            type="number"
                                            placeholder="Price/Rate"
                                            name="product_cost"
                                            style={{ textAlign: "center" }}
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState({costErrorMessage: ""})
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_cost: value
                                                    }
                                                }));
                                                this.state.productInfo.product_cost = parseFloat(value).toFixed(2);
                                                this.handleTaxChange(this.state.tax); 
                                                this.validateNewItem(); 
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_cost: parseFloat(value).toFixed(2)
                                                    }
                                                }));
                                                if(!value || value <= 0)
                                                {
                                                    this.setState({
                                                        costErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid product cost.
                                                            </small>
                                                        )
                                                    });
                                                }
                                                else{
                                                    this.setState({costErrorMessage: ""});
                                                }
                                                this.state.productInfo.product_cost = parseFloat(value).toFixed(2);
                                                this.handleTaxChange(this.state.tax); 
                                                this.validateNewItem(); 
                                            }}
                                        />
                                        {this.state.costErrorMessage}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                <FormGroup>                                   
                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Tax Type:
                                    </div>
                                    <Row>
                                        <Col md={6}>
                                            <Radio
                                                number="percentage"
                                                option="percentage"
                                                name="radio"
                                                onChange={this.handleTaxAmountRadio}
                                                checked={this.state.IsPercentage}
                                                className="PrivateSwitchBase-input-538"
                                                label="Percentage"
                                             />
                                        </Col>
                                        <Col md={6}>
                                            <Radio
                                                number="fixedamount"
                                                option="fixedamount"
                                                name="radio"
                                                onChange={this.handleTaxAmountRadio}
                                                checked={this.state.IsFixed}
                                                label="Fixed"
                                            />
                                        </Col>
                                    </Row>      
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Tax:
                                        </div>
                                        <InputGroup>
                                            {this.state.IsFixed && 
                                                <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                            }    
                                            <FormControl
                                                value={this.state.tax}
                                                type="number"
                                                name="tax"
                                                style={{ textAlign: "right" }}
                                                onFocus={event => {
                                                    var value = event.target.value;
                                                    if(value == "0.00")
                                                    {
                                                        this.setState({tax: ""});
                                                    }
                                                }}
                                                onChange={event => {
                                                    var value = event.target.value;
                                                    this.setState({tax: value});
                                                    this.handleTaxChange(value);                                            
                                                    this.state.taxAmountErrorMessage = "";      
                                                    this.validateNewItem();
                                                }}
                                                onBlur={event => {
                                                    var value = event.target.value;
                                                    if(!value)
                                                    {
                                                        value = "0.00";                                                        
                                                    }
                                                    this.setState({tax: parseFloat(value).toFixed(2)});                                                    
                                                    this.handleTaxChange(value);
                                                    this.state.taxAmountErrorMessage = "";      
                                                    this.validateNewItem();
                                                }
                                                }                                                                            
                                            />
                                            {this.state.IsPercentage &&
                                                <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-percent" /></InputGroup.Addon>
                                            }
                                        </InputGroup>
                                    </FormGroup>

                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Total Tax Amount:
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_tax_amount}
                                            type="number"
                                            placeholder="0.00"
                                            name="product_tax_amount"
                                            min="0.00"
                                            disabled="disabled"
                                            style={{ textAlign: "center" }}
                                            //onKeyPress= {this.handleRateKeyPress}
                                            step="0.01"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_tax_amount: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_tax_amount: parseFloat(value).toFixed(2)
                                                    }
                                                }));
                                                
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                {/* <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Discount:
                                                    </div>
                                        <FormControl
                                            value={this.state.productInfo.product_discount_amount}
                                            type="number"
                                            placeholder="Discount"
                                            name="product_discount_amount"
                                            min="0.00"
                                            style={{ textAlign: "center" }}
                                            //onKeyPress= {this.handleRateKeyPress}
                                            step="0.01"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_discount_amount: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_discount_amount: parseFloat(value).toFixed(2)
                                                    }
                                                }));

                                            }}
                                        />
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col md={8}>                                                                   
                                </Col>
                                <Col style={{ textAlign: "left", marginTop: "-15px" }} md={4}>                                                                    
                                        {this.state.taxAmountErrorMessage}                                                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Description: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_description}
                                            type="text"
                                            placeholder="Product Description"
                                            name="product_description"
                                            onChange={event => {
                                                var value = event.target.value;

                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_description: value
                                                    }
                                                }));

                                                if (this.verifyLength(value, 1)) {
                                                    this.setState({
                                                        productInfoDescriptionState: "valid",
                                                        productInfoDescriptionErrorMessage: ""
                                                    });
                                                    this.state.productInfoDescriptionState = "valid"
                                                    this.validateNewItem();
                                                }
                                                else {
                                                    this.setState({
                                                        productInfoDescriptionErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid Description.
                                                            </small>
                                                        )
                                                    });
                                                    this.setState({ isNewItemFormValid: false });
                                                }
                                            }
                                            }
                                            onBlur={e => this.validateNewItem()}

                                        />
                                        {this.state.productInfoDescriptionErrorMessage}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Currency: <span className="star">*</span>
                                        </div>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="product_currency"
                                            value={this.state.productInfo.product_currency}
                                            onChange={value => {
                                                this.setState(prevState => ({
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_currency: value
                                                    }
                                                }));
                                             }
                                            }
                                            options={this.state.currencies}
                                            placeholder="Select Currency"
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewItemPopupModal: false })}>Close</Button>
                        <Button bsStyle="primary" fill wd onClick={e => this.createItemSubmit(this.state.productInfo)} disabled={!this.state.isNewItemFormValid}>Save</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showDeleteProductPopupModal}
                    onHide={() => this.setState({ showDeleteProductPopupModal: false })}
                    >
                    {this.state.ShowPopupProgressBar &&
                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                            <CustomProgressBar></CustomProgressBar>
                        </div>
                    }

                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                        <Modal.Title style={{ color: "white" }}>Delete Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body text-center">
                            <Row>
                                <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>SKU : </h5>
                                </Col> <Col xs={9} sm={12} md={9} className="text-left">
                                    <h5>{this.state.deletedProduct.sku}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>Description :</h5>
                                </Col> <Col xs={9} sm={12} md={9} className="text-left">
                                    <h5>{this.state.deletedProduct.description}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={43} sm={12} md={3} className="text-left">
                                    <h5>Cost :</h5>
                                </Col> <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>${this.state.deletedProduct.cost}</h5>
                                </Col>
                                <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>Tax :</h5>
                                </Col> <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>${this.state.deletedProduct.tax}</h5>
                                </Col>
                            </Row>                            
                            <Row>
                                <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>Discount :</h5>
                                </Col> <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>$0.00</h5> 
                                    {/* {this.state.deletedProduct.discount}</h5> */}
                                </Col>
                                <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>Total :</h5>
                                </Col> <Col xs={3} sm={12} md={3} className="text-left">
                                    <h5>${this.state.deletedProduct.total}</h5>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showDeleteProductPopupModal: false })}>Close</Button>
                        <Button bsStyle="primary" fill wd onClick={e => this.handleDeleteProductSubmit(this.state.deletedProduct)}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Products;








