import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    InputGroup,
    Modal,
    Alert,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import Select from "react-select";
import SelectCreatable from "react-select/creatable";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js"

class CreateInvoice extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            ShowProgressBar: false,
            errorMessage: "",
            errorCode: 0,
            term: {},
            currency: {},
            terms: [
                {
                    Active: true,
                    DueDays: 0,
                    Value: 'upon_receipt',
                    Id: "1",
                    Name: "Due on receipt"
                },
                {
                    Active: true,
                    DueDays: 10,
                    Value: 10,
                    Id: "2",
                    Name: "Net 10"
                },
                {
                    Active: true,
                    DueDays: 15,
                    Value: 15,
                    Id: "3",
                    Name: "Net 15"
                },
                {
                    Active: true,
                    DueDays: 30,
                    Value: 30,
                    Id: "4",
                    Name: "Net 30"
                },
                {
                    Active: true,
                    DueDays: 45,
                    Value: 45,
                    Id: "5",
                    Name: "Net 45"
                },
                {
                    Active: true,
                    DueDays: 60,
                    Value: 60,
                    Id: "6",
                    Name: "Net 60"
                }
            ],
            currencies: [
                {
                    Value: "USD",
                    Name: "USD"
                }
            ],
            measure_of_unit: [
                {
                    Value: "NOA",
                    Name: "NOA"
                }
            ],
            products: [],
            Invoice: {
                payment_terms: 'upon_receipt',
                amount: "0.00",
                email: "",
                tax: "0.00",
                first_name: "",
                last_name: "",
                orderid: "",
                ponumber: "",
                customer_id: "",
                company: "",
                order_description: "",
                address1: "",
                country: "",
                state: "",
                city: "",
                zip: "",
                phone: "",
                website: "",
                shipping_address1: "",
                shipping_country: "",
                shipping_state: "",
                shipping_city: "",
                shipping_zip: "",
                merchant_defined_field_1:"",
                merchant_defined_field_2:"",
                merchant_defined_field_3:"",
                merchant_defined_field_4:"",
                Products: [
                    {
                        item_product_code_: "",
                        item_description_: "",
                        item_unit_cost_: 0.00,
                        item_quantity_: 0,
                        item_tax_amount_: 0.00,
                        item_total_amount_: 0.00

                    }
                ],

            },
            rows: [
                {
                    product: "",
                    description: "",
                    quantity: 1,
                    rate: "0.00",
                    tax: "0.00",
                    amount: "0.00"
                }
            ],
            subtotal: 0.00,
            isShippingAddressSameAsBillingAddress: true,
            ShowAddressFields: false,
            customerFirstNameState: "",
            customerFirstNameErrorMessage: "",
            customerLastNameState: "",
            merchantDefinedField1State: "",
            merchantDefinedField2State: "",
            merchantDefinedField3State: "",
            merchantDefinedField4State: "",
            customerLastNameErrorMessage: "",
            merchantDefinedField1ErrorMessage: "",
            merchantDefinedField2ErrorMessage: "",
            merchantDefinedField3ErrorMessage: "",
            merchantDefinedField4ErrorMessage: "",
            customerEmailErrorMessage: "",
            customerEmailState: "",
            amountState: "",
            amountErrorMessage: "",
            productInfo: {
                product_sku: "",
                product_description: "",
                product_cost: "0.00",
                product_currency: "USD",
                product_tax_amount: "0.00",
                product_discount_amount: "0.00",
                product_commodity_code: "",
                product_discount_amount: "0.00"
            },
            customerData:{},
            productInfoSKUState: "",
            productInfoSKUErrorMessage: "",
            productInfoDescriptionState: "",
            productInfoDescriptionErrorMessage: "",
            isNewItemFormValid: false,
            isCustomerIDAvailable: false,
            isValid: false,
            merchant_defined_field_1:"",
                merchant_defined_field_2:"",
                merchant_defined_field_3:"",
                merchant_defined_field_4:"",
            customfield_list: [],

        }
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this._getCustomFieldsRequest();
        this.state.customerData = this.props.location.state ? this.props.location.state.customerData : null;//JSON.parse(localStorage.getItem("invoicecustomer"));
        if (this.state.customerData !== null) { 
            console.log(this.state.customerData);
            var custData = this.state.customerData;

            if(custData.customer_vault_id != "" && custData.customer_vault_id != null && custData.customer_vault_id != undefined){
                this.setState({customerLastNameState: "valid", customerFirstNameState: "valid", customerEmailState: "valid", })
            }
            this.setState(prevState => ({
                Invoice:
                {
                    ...prevState.Invoice, 
                    first_name: custData.firstname,
                    last_name: custData.lastname,
                    customer_id: custData.customer_vault_id,
                    email: custData.email,
                }
            }));

            this.setState({isCustomerIDAvailable: true});
        }   

        this.setTermsResult(this.state.terms);
        this.setCurrenciesResult(this.state.currencies);
        this._getItemListingRequest();
        this._getCustomerRequest();

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 3000);

    }

    _getCustomFieldsRequest(){
        this.setState({ ShowProgressBar: true });        
        const app_id = ApplicationIDEnum.NMIInvoicing; 
        NetworkService.getCustomFieldListingRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                this.setState({ showNewCustomFieldPopupModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });                    
                    if (data.statuscode == 0 && data.data != null) {
                        this.setCustomFieldsResult(data.data.items);
                    }
                    else if(data.statuscode != 0){
                        this.setState({ errorMessage: data.statusmessage });
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    setCustomFieldsResult(customfields) {        
        const newState = [];
        customfields.map((data, index) => {
            const custField = {
                index: index,
                customfieldid: data.id,
                customfieldvariablename: data.variable_name,
                customfieldname: data.customfield_name,
                customfieldtype: data.customfield_type,
                required: data.is_required 
            }
            newState.push(custField);
        });
        this.state.customfield_list = newState;
        this.setState({ customfield_list: newState });

        newState.map((custField, index) => (
            custField.customfieldvariablename == "merchant_defined_field_1" && !custField.required ? 
            this.setState({merchantDefinedField1State:"valid"}) : 
            custField.customfieldvariablename == "merchant_defined_field_2" && !custField.required ? 
            this.setState({merchantDefinedField2State:"valid"}) :
            custField.customfieldvariablename == "merchant_defined_field_3"  && !custField.required ? 
            this.setState({merchantDefinedField3State:"valid"}) : 
            custField.customfieldvariablename == "merchant_defined_field_4"  && !custField.required ? 
            this.setState({merchantDefinedField4State:"valid"}) : ""
        ));
        
    }

    CreateCustomFieldUI(){       

        if(this.state.customfield_list.length > 0)
        {
            return (
            <Row>
                {
                    this.state.customfield_list.map((custField, index) => (
                        <Col md={3}>
                            <FormGroup>
                                {custField.customfieldtype == "Text" && 
                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                    {custField.customfieldname}: {custField.required == true && <span className="star">*</span>}
                                </div>
                                }
                                {custField.customfieldtype == "Text" && 
                                <FormControl //custField.customfieldvariablename
                                    value={custField.customfieldvariablename == "merchant_defined_field_1" ? 
                                            this.state.Invoice.merchant_defined_field_1 : custField.customfieldvariablename == "merchant_defined_field_2" ? 
                                            this.state.Invoice.merchant_defined_field_2 : custField.customfieldvariablename == "merchant_defined_field_3" ?
                                            this.state.Invoice.merchant_defined_field_3 : this.state.Invoice.merchant_defined_field_4} 
                                    type="text"
                                    disabled={this.state.ShowProgressBar}
                                    placeholder={custField.customfieldname}
                                    name={custField.customfieldvariablename}
                                    onChange={event => {
                                        var value = event.target.value;
                                        if(event.target.name == "merchant_defined_field_1")
                                        {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                Invoice: {
                                                    ...prevState.Invoice,
                                                    merchant_defined_field_1 : value
                                                }
                                            }));
                                            if(value == "" && custField.required){
                                            this.setState({
                                                    merchantDefinedField1ErrorMessage: (
                                                        <small className="text-danger">
                                                            {custField.customfieldname} is required.
                                                        </small>
                                                    ),
                                                    merchantDefinedField1State: "",
                                                    isValid: false
                                                });
                                            }
                                            else if (value.length > 0 || custField.required == false) {                                                            
                                                this.setState({
                                                    merchantDefinedField1State: "valid",
                                                    merchantDefinedField1ErrorMessage: ""
                                                });
                                            }
                                        }
                                        else if(event.target.name == "merchant_defined_field_2")
                                        {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                Invoice: {
                                                    ...prevState.Invoice,
                                                    merchant_defined_field_2 : value
                                                }
                                            }));
                                            if(value == "" && custField.required){
                                                this.setState({
                                                        merchantDefinedField2ErrorMessage: (
                                                            <small className="text-danger">
                                                                {custField.customfieldname} is required.
                                                            </small>
                                                        ),
                                                        merchantDefinedField2State: "",
                                                        isValid: false
                                                    });
                                                }
                                                else if (value.length > 0 || custField.required == false) {                                                            
                                                    this.setState({
                                                        merchantDefinedField2State: "valid",
                                                        merchantDefinedField2ErrorMessage: ""
                                                    });
                                                }
                                       }
                                       else if(event.target.name == "merchant_defined_field_3")
                                       {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                Invoice: {
                                                    ...prevState.Invoice,
                                                    merchant_defined_field_3 : value
                                                }
                                            }));
                                            if(value == "" && custField.required){
                                            this.setState({
                                                    merchantDefinedField3ErrorMessage: (
                                                        <small className="text-danger">
                                                            {custField.customfieldname} is required.
                                                        </small>
                                                    ),
                                                    merchantDefinedField3State: "",
                                                    isValid: false
                                                });
                                            }
                                            else if (value.length > 0 || custField.required == false) {                                                            
                                                this.setState({
                                                    merchantDefinedField3State: "valid",
                                                    merchantDefinedField3ErrorMessage: ""
                                                });
                                            }
                                       }
                                       else if(event.target.name == "merchant_defined_field_4")
                                       {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                Invoice: {
                                                    ...prevState.Invoice,
                                                    merchant_defined_field_4 : value
                                                }
                                            }));
                                            if(value == "" && custField.required){
                                            this.setState({
                                                    merchantDefinedField4ErrorMessage: (
                                                        <small className="text-danger">
                                                            {custField.customfieldname} is required.
                                                        </small>
                                                    ),
                                                    merchantDefinedField4State: "",
                                                    isValid: false
                                                });
                                            }
                                            else if (value.length > 0 || custField.required == false) {                                                            
                                                this.setState({
                                                    merchantDefinedField4State: "valid",
                                                    merchantDefinedField4ErrorMessage: ""
                                                });
                                            }
                                       }
                                    }
                                    }
                                    onBlur={event => {
                                        // var value = event.target.value;
                                        // if (value.length > 0 && this.verifyLength(value, 2)) {
                                        //     this.setState({
                                        //         merchantDefinedField1State: "valid",
                                        //         merchantDefinedField1ErrorMessage: ""
                                        //     });
                                        // }
                                        // else if (value.length > 0) {
                                        //     this.setState({
                                        //         merchantDefinedField1ErrorMessage: (
                                        //             <small className="text-danger">
                                        //                 Invalid Pateint First Name.
                                        //             </small>
                                        //         ),
                                        //         merchantDefinedField1State: "error",
                                        //             isValid: false
                                        //     });
                                        // }
                                         this.Validate(event, custField.customfieldvariablename, custField.required, custField.customfieldname);
                                     }
                                    }
                                />
                                }                                
                                {custField.customfieldtype == "Checkbox" && 
                                    <div style={{ marginTop: "25px" }}>
                                   <CustomCheckbox
                                        name={custField.customfieldvariablename}
                                        checked={custField.customfieldvariablename == "merchant_defined_field_1" ? 
                                            this.state.Invoice.merchant_defined_field_1 : custField.customfieldvariablename == "merchant_defined_field_2" ? 
                                            this.state.Invoice.merchant_defined_field_2 : custField.customfieldvariablename == "merchant_defined_field_3" ?
                                            this.state.Invoice.merchant_defined_field_3 : this.state.Invoice.merchant_defined_field_4}
                                        labelText={custField.customfieldname}
                                        labelProps={{ style: { fontSize: "16px", marginTop: "6px" } }}
                                        formControlProps={{
                                            onChange: (event) => {
                                                var value = event.target.checked;
                                                if(event.target.name == "merchant_defined_field_1")
                                                {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    Invoice: {
                                                        ...prevState.Invoice,
                                                        merchant_defined_field_1 : value
                                                    }
                                                }));
                                                if (value || custField.required == false) {                                                            
                                                    this.setState({
                                                        merchantDefinedField1State: "valid",
                                                        merchantDefinedField1ErrorMessage: ""
                                                    });
                                                }

                                                }
                                                else if(event.target.name == "merchant_defined_field_2")
                                                {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        Invoice: {
                                                            ...prevState.Invoice,
                                                            merchant_defined_field_2 : value
                                                        }
                                                    }));
                                                    if (value || custField.required == false) {                                                            
                                                        this.setState({
                                                            merchantDefinedField2State: "valid",
                                                            merchantDefinedField2ErrorMessage: ""
                                                        });
                                                    }
                                                }
                                                else if(event.target.name == "merchant_defined_field_3")
                                                {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        Invoice: {
                                                            ...prevState.Invoice,
                                                            merchant_defined_field_3 : value
                                                        }
                                                    }));
                                                    if (value || custField.required == false) {                                                            
                                                        this.setState({
                                                            merchantDefinedField3State: "valid",
                                                            merchantDefinedField3ErrorMessage: ""
                                                        });
                                                    }
                                                }
                                                else if(event.target.name == "merchant_defined_field_4")
                                                {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        Invoice: {
                                                            ...prevState.Invoice,
                                                            merchant_defined_field_4 : value
                                                        }
                                                    }));
                                                    if (value || custField.required == false) {                                                            
                                                        this.setState({
                                                            merchantDefinedField4State: "valid",
                                                            merchantDefinedField4ErrorMessage: ""
                                                        });
                                                    }
                                                }
                                                }
                                            
                                        }}
                                    /> 
                                    </div>
                                }
                                {custField.customfieldvariablename == "merchant_defined_field_1" ? 
                                            this.state.merchantDefinedField1ErrorMessage : custField.customfieldvariablename == "merchant_defined_field_2" ? 
                                            this.state.merchantDefinedField2ErrorMessage : custField.customfieldvariablename == "merchant_defined_field_3" ?
                                            this.state.merchantDefinedField3ErrorMessage : this.state.merchantDefinedField4ErrorMessage}
                               
                            </FormGroup>
                    </Col>
                    ))
                    }
                  
                
            </Row>
            );
        }
        
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handleRowDescriptionChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx].description = value;

        const lineItems = [...this.state.Invoice.Products];
        lineItems[idx].item_description_ = value;

        this.setState({ lineItems });
        this.setState({ rows });
    }



    handleRowValueChange = idx => e => {
        let { name, value } = e.target;
        const rows = [...this.state.rows];

        rows[idx][name] = value;
        if (rows[idx].rate != undefined && rows[idx].quantity != undefined) {
           // rows[idx].tax = ().toFixed(2);
            rows[idx].amount = ((parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)) + (parseFloat(rows[idx].tax) *  parseInt(rows[idx].quantity))).toFixed(2);
        }
        else {
            rows[idx].amount = rows[idx].tax;
        }

        this.state.subtotal = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.Invoice.amount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.Invoice.amount = parseFloat((parseFloat(this.state.Invoice.amount) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.Invoice.tax = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.tax)) {
                this.state.Invoice.tax = parseFloat((parseFloat(this.state.Invoice.tax) + (parseFloat(element.tax)*  parseInt(element.quantity))).toFixed(2));
            }
        });

        this.state.Invoice.amount = parseFloat(this.state.Invoice.amount).toFixed(2);


        const lineItems = [...this.state.Invoice.Products];
        if (rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            lineItems[idx].item_total_amount_ = parseFloat(rows[idx].amount);
            lineItems[idx].item_quantity_ = parseInt(rows[idx].quantity);
            lineItems[idx].item_tax_amount_ = parseFloat(parseFloat(rows[idx].tax).toFixed(2));
            lineItems[idx].item_unit_cost_ = parseFloat(parseFloat(rows[idx].rate).toFixed(2));
        }

        this.setState({ lineItems });

        this.setState({ rows });

    };

    handleRemoveSpecificRow = idx => () => {
        if (this.state.rows.length > 1) {
            const rows = [...this.state.rows];
            rows.splice(idx, 1);
            const lineItems = [...this.state.Invoice.Products];
            lineItems.splice(idx, 1);
            this.setState(prevState => ({
                ...prevState,
                Invoice: {
                    ...prevState.Invoice,
                    Products: lineItems
                }
            }));

            this.state.subtotal = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)) {
                    this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                }
            });

            this.state.total = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)) {
                    this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
                }
            });

            this.state.Invoice.TotalAmt = this.state.total = parseFloat((parseFloat(this.state.total)).toFixed(2));

            this.setState({ lineItems });
            this.setState({ rows });
        }
    };

    setCurrenciesResult(currencies) {
        const newState = [];

        newState.push({ value: "", label: "Select Currency", isDisabled: true });
        currencies.map(data => {
            const x = { value: data["Value"], label: data["Name"] }
            newState.push(x);
        });
        newState.map(curr => {
            if (curr.label == 'USD') {
                this.state.currency = curr;
            }
        });
        this.setState({ currencies: newState });

    }

    setTermsResult(terms) {
        const newState = [];

        newState.push({ value: "", label: "Select Term", isDisabled: true });
        terms.map(data => {
            const x = { value: data["Value"], label: data["Name"], id: data["Id"] }
            newState.push(x);
        });
        newState.map(newTerm => {
            if (newTerm.label == 'Due on receipt') {
                this.state.term = newTerm;
            }
        });
        if (this.state.term == "" && newState.length >= 1) {
            this.setState({ term: newState[1] });
        }
        this.setState({ terms: newState });
    }

    handleOpenNewItemPopup(e, name) {
        if (name != "") {
            this.setState({
                productInfoSKUState: "valid",
                productInfoSKUErrorMessage: ""
            });
        }
        this.state.productInfo.product_sku = name;
        this.state.productInfo.product_cost = "0.00";
        this.state.productInfo.product_tax_amount = "0.00";
        this.state.productInfo.product_description = "";
        this.state.productInfoSKUState = "";
        this.state.productInfoDescriptionState = "";
        this.state.productInfoDescriptionErrorMessage = "";
        this.state.isNewItemFormValid = false;

        this.setState({ showNewItemPopupModal: true });
    }

    createInvoiceSubmit = (invoice) => {
        if (this.state.isShippingAddressSameAsBillingAddress) {
            invoice.shipping_address1 = invoice.address1;
            invoice.shipping_country = invoice.country;
            invoice.shipping_state = invoice.state;
            invoice.shipping_city = invoice.city;
            invoice.shipping_zip = invoice.zip;
        }
        let formData = new FormData();
        formData.append("data", JSON.stringify(invoice));
        this._postCreateInvoiceRequest(formData);
    }

    handleCancelCreateInvoice(e) {
        this.props.history.push('/nmi/invoices');
        e.preventDefault();
    }

    createItemSubmit = (item) => {
        const payload = {
            "product_sku": item.product_sku,
            "product_cost": item.product_cost,
            "product_tax_amount": item.product_tax_amount,
            "product_description": item.product_description,
            "product_currency": "USD",
            "product_discount_amount": item.product_discount_amount,
            "product_commodity_code": item.product_commodity_code,
            "product_unit_of_measure": "NAR"
        }

        console.log(payload);
        this._postCreateItemRequest(payload);
    }


    handleRowProductNameChange(idx, value) {
        const rows = [...this.state.rows];
        rows[idx].product = value;
        rows[idx].rate = value.rate != undefined ? parseFloat(value.rate).toFixed(2) : 0.00;
        rows[idx].quantity = value.quantity;
        rows[idx].tax = parseFloat(value.tax).toFixed(2);
        if (rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            rows[idx].amount = ((parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)) + parseFloat(rows[idx].tax)).toFixed(2);
        }
        else {
            rows[idx].amount = rows[idx].tax;
        }
        rows[idx].description = value.description;

        const lineItems = [...this.state.Invoice.Products];

        lineItems[idx].item_product_code_ = value.label;
        lineItems[idx].item_description_ = value.description;

        if (rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            lineItems[idx].item_total_amount_ = parseFloat(parseFloat(rows[idx].amount).toFixed(2));
            lineItems[idx].item_quantity_ = parseInt(rows[idx].quantity);
            lineItems[idx].item_tax_amount_ = parseFloat(parseFloat(rows[idx].tax).toFixed(2));
            lineItems[idx].item_unit_cost_ = parseFloat(parseFloat(rows[idx].rate).toFixed(2));
        }

        this.state.subtotal = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.Invoice.tax = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.tax)) {
                this.state.Invoice.tax = parseFloat((parseFloat(this.state.Invoice.tax) + parseFloat(element.tax)).toFixed(2));
            }
        });

        this.state.Invoice.amount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.Invoice.amount = (parseFloat(this.state.Invoice.amount) + parseFloat(element.amount)).toFixed(2);
            }
        });

        //this.state.Invoice.TotalAmt =  this.state.total = parseFloat(this.state.total).toFixed(2);

        this.setState({ lineItems });
        this.setState({ rows });
        this.state.amountState = "valid";
        this.setState({ amountErrorMessage: "" });
        if (this.state.amountState == "valid" && this.state.customerEmailState == "valid" && this.state.customerFirstNameState == "valid" && this.state.customerLastNameState == "valid"
        && this.IsCustomFieldsValid()
            ) {
            this.setState({ isValid: true });
        }
        else {
            this.setState({ isValid: false });
        }
    }

    IsCustomFieldsValid()
    {
        var isValid = true;
        if(this.state.customfield_list.length == 0)
        {
            return isValid;
        }
        else if(this.state.customfield_list.length == 1)
        {
            if(this.state.merchantDefinedField1State === "valid")
                isValid = true;
        }
        else if(this.state.customfield_list.length === 2)
        {
            if(this.state.merchantDefinedField1State === "valid" && this.state.merchantDefinedField2State === "valid")
                isValid = true;
        }
        else if(this.state.customfield_list.length === 3)
        {
            if(this.state.merchantDefinedField1State === "valid" && this.state.merchantDefinedField2State == "valid" && this.state.merchantDefinedField3State === "valid")
                isValid = true;
        }
        return isValid;
    }
    ValidateEmailAddress(event, name) {
        var value = event.target.value;
        this.setState(prevState => ({
            ...prevState,
            Invoice: {
                ...prevState.Invoice,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            if (value == "") {
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Email is required.
                        </small>
                    )
                });
            }
            else {
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Invalid email address.
                        </small>
                    )
                });
            }
            this.setState({
                customerEmailState: "error"
            });
            // this.setState({ isNewCustomerFormValid: false });                                               
        }
        else {
            this.state.customerEmailState = "valid";
            this.state.customerEmailErrorMessage = "";
        }
        if (this.state.amountState == "valid" && this.state.customerEmailState == "valid" && this.state.customerFirstNameState == "valid" && this.state.customerLastNameState == "valid"
            && ((this.state.customfield_list.length > 0 && this.state.merchantDefinedField1State == "valid" ) || this.state.customfield_list.length == 0)
            //&& this.state.merchantDefinedField2State == "valid" && this.state.merchantDefinedField3State == "valid"
            ) {
            this.setState({ isValid: true });
        }
        else {
            this.setState({ isValid: false });
        }
    }

    Validate(event, name, isRequired = false, fieldName = "") {
        if (name == "amount") {
            var value = event.target.value;
            if (value != "" && parseFloat(this.state.subtotal) > parseFloat(value)) {
                this.setState({
                    amountErrorMessage: (
                        <small className="text-danger">
                            Total amount ({parseFloat(this.state.Invoice.amount).toFixed(2)}) may not be less than total Product Items amount ({this.state.subtotal}).
                        </small>
                    )
                });
                this.state.amountState = "error";
            }
            else if (value == "" || value == undefined || value == null || parseFloat(value).toFixed(2) == "0.00") {
                this.setState({
                    amountErrorMessage: (
                        <small className="text-danger">
                            Total amount is required and must be greater than 0.00
                        </small>
                    )
                });
                this.state.amountState = "error";
            }
            else {
                this.setState({ amountErrorMessage: "" });
                this.state.amountState = "valid";
            }
        }
        else if (name == "customerEmail") {
            if (this.state.customerEmailState == "") {
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Email is required.
                        </small>
                    )
                });
            }
            else if (this.state.customerEmailState == "valid") {
                this.setState({
                    customerEmailErrorMessage: ""
                });
            }
        }
        else if(name == "first_name"){
            if (this.state.customerFirstNameState == "") {
            this.setState({
                customerFirstNameErrorMessage: (
                    <small className="text-danger">
                        First Name is required.
                    </small>
                ),
                customerFirstNameState: ""
            });
            }
        }        
        else if(name == "last_name"){
            if (this.state.customerLastNameState == "") {
            this.setState({
                customerLastNameErrorMessage: (
                    <small className="text-danger">
                        Last Name is required.
                    </small>
                ),
                customerLastNameState: ""
            });
            }
        }
        else if(name == "merchant_defined_field_1"){
            if(isRequired){
                if (this.state.merchantDefinedField1State == "") {
                this.setState({
                    merchantDefinedField1ErrorMessage: (
                        <small className="text-danger">
                            {fieldName} is required.
                        </small>
                    ),
                    merchantDefinedField1State: ""
                });
                }
            }
            else{
                this.setState({merchantDefinedField1State: "valid"});
            }
        }
        else if(name == "merchant_defined_field_2"){
            if(isRequired){
                if (this.state.merchantDefinedField2State == "") {
                this.setState({
                    merchantDefinedField2ErrorMessage: (
                        <small className="text-danger">
                            {fieldName} is required.
                        </small>
                    ),
                    merchantDefinedField2State: ""
                });
                }
            }
            else{
                this.setState({merchantDefinedField2State: "valid"});
            }
        }
        else if(name == "merchant_defined_field_3"){
            if(isRequired){
                if (this.state.merchantDefinedField3State == "") {
                this.setState({
                    merchantDefinedField3ErrorMessage: (
                        <small className="text-danger">
                            {fieldName} is required.
                        </small>
                    ),
                    merchantDefinedField3State: ""
                });
                }
            }
            else{
                this.setState({merchantDefinedField3State: "valid"});
            }
        }    
        else if(name == "merchant_defined_field_4"){
            if(isRequired){
                if (this.state.merchantDefinedField4State == "") {
                this.setState({
                    merchantDefinedField4ErrorMessage: (
                        <small className="text-danger">
                            {fieldName} is required.
                        </small>
                    ),
                    merchantDefinedField4State: ""
                });
                }
            }
            else{
                this.setState({merchantDefinedField4State: "valid"});
            }
        }    
        if (this.state.amountState == "valid" && this.state.customerEmailState == "valid" && this.state.customerFirstNameState == "valid" && this.state.customerLastNameState == "valid"
        && this.IsCustomFieldsValid()
            ) {
            this.setState({ isValid: true });
        }
        else {
            this.setState({ isValid: false });
        }
    }

    validateNewItem() {
        if (this.state.productInfoSKUErrorMessage === "") {
            if (this.state.productInfoSKUState === "" || this.state.productInfoSKUState === "error") {
                this.setState({
                    productInfoSKUErrorMessage: (
                        <small className="text-danger">
                            SKU is Required.
                        </small>
                    )
                });
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState({
                    productInfoSKUState: "valid",
                    productInfoSKUErrorMessage: ""
                });
            }
        }

        if (this.state.productInfoDescriptionErrorMessage === "") {
            if (this.state.productInfoDescriptionState === "" || this.state.productInfoDescriptionState === "error") {
                this.setState({
                    productInfoDescriptionErrorMessage: (
                        <small className="text-danger">
                            Description is Required.
                        </small>
                    )
                });
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState({
                    productInfoDescriptionState: "valid",
                    productInfoDescriptionErrorMessage: ""
                });
            }
        }

        if (this.state.productInfoSKUState === "valid" && this.state.productInfoDescriptionState == "valid") {
            this.setState({ isNewItemFormValid: true });
        }
        else {
            this.setState({ isNewItemFormValid: false });
        }
    }

    handleAddRow = () => {
        const rowItem = {
            productCode: "",
            product: "",
            description: "",
            quantity: "",
            rate: "",
            tax: "",
            amount: ""
        };

        this.setState({
            rows: [...this.state.rows, rowItem]
        });

        const lineItem = {
            item_product_code_: "",
            item_description_: "",
            item_unit_cost_: 0,
            item_quantity_: 0,
            item_tax_amount_: 0,
            item_total_amount_: 0
        }

        this.setState(prevState => ({
            ...prevState,
            Invoice: {
                ...prevState.Invoice,
                Products: [...this.state.Invoice.Products, lineItem]
            }
        }));

    };

    setCustomerResult(customers, isFromCache = false) {
        if (!isFromCache) {
            localStorage.setItem(StorageEnum.QBO_CUSTOMERS, null);
            localStorage.setItem(StorageEnum.QBO_CUSTOMERS, JSON.stringify(customers));
        }

        const newState = [{
            value: "AddNew",
            label: <Button simple icon onClick={e => this.handleOpenNewCustomerPopup(e, "")}>
                <div className="spinner-grow text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Customer</i>
            </Button>,

            isDisabled: true
        }]
        if (customers != null && JSON.stringify(customers) !== JSON.stringify({})) {
            customers.map(data => {
                try {
                    const x = {
                        value: data["Id"],
                        label: data["DisplayName"],
                        PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"] != null ? data["PrimaryEmailAddr"]["Address"] : "" }
                    }
                    newState.push(x);
                }
                catch (why) {
                    console.log(why)
                }

            });
        }
        this.setState({ customers: newState });
    }

    /** API CALLING */

    _postCreateItemRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            // this.setNewItemResult(data.data.items);
                        }
                    }

                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postCreateInvoiceRequest(payload) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //set appid here...
        NetworkService.postCreateInvoiceRequest(app_id, payload)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        this.props.history.push({
                            pathname: '/nmi/invoices',
                            state: { errorMessage: data.statusmessage, errorCode: 0 }
                        });
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _getCustomerRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getCustomerRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    // this.setState({ errorMessage: data.statusmessage });

                    if (data.data != null) {
                        this.setCustomerResult(data.data.items, false);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getItemListingRequest(){
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getItemListingRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    // this.setState({ errorMessage: data.statusmessage });

                    if (data.data != null) {
                        this.setItemsResult(data.data.items, false);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setItemsResult(items, isFromCache = false) {       
        if(!isFromCache)
        {
            localStorage.setItem("items", null);
            localStorage.setItem("items", JSON.stringify(items)); 
        }
        const newState = [
        //     {
        //     value: "AddNew",
        //     label: <Button simple icon onClick={e => this.handleOpenNewItemPopup(e, "")}>
        //         <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Product/Service</i>
        //     </Button>,

        //     isDisabled: true
        // }
        ]

        items.map(data => {
            const product = {
                value: data['id'],
                label: data['sku'],
                quantity: 1,
                rate: data['unit_cost'],
                tax: data['tax_amount'],
               // discount: data['discount_amount'],
                commodity_code: data['commodity_code'],
                currency: data['currency_code'],
                description: data['description'],
                measure_of_unit: data['measure_of_unit']
            }
            newState.push(product);
        });

        this.setState({ products: newState });
    }

    /** Render Function */
    render() {
        const totalLabelStyle = {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "35px",
            marginLeft: "30px"
        };
        const deleteButtonToolTip = <Tooltip id="refundToolTip">Delete</Tooltip>;
        return (
            <div className="ii-main-content">
                {this.state.ShowProgressBar &&
                    <div style={{ marginBottom: "5px" }}>
                        <CustomProgressBar></CustomProgressBar>
                    </div>
                }
                <Card
                    //  ctFullWidth
                    textCenter
                    //title="New Invoice"
                    content={
                        <div>
                            {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <legend></legend>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    First Name: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.first_name}
                                                    type="text"
                                                    placeholder="First Name"
                                                    name="first_name"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                first_name: value
                                                            }
                                                        }));
                                                        if(value == ""){
                                                            this.setState({
                                                                    customerFirstNameErrorMessage: (
                                                                        <small className="text-danger">
                                                                            First Name is required.
                                                                        </small>
                                                                    ),
                                                                    customerFirstNameState: "",
                                                                    isValid: false 
                                                             });
                                                        }
                                                        else if (value.length > 0) {                                                            
                                                            this.setState({
                                                                customerFirstNameState: "valid",
                                                                customerFirstNameErrorMessage: ""
                                                            });
                                                        }
                                                    }
                                                    }
                                                    onBlur={event => {
                                                        var value = event.target.value;
                                                        if (value.length > 0 && this.verifyLength(value, 2)) {
                                                            this.setState({
                                                                customerFirstNameState: "valid",
                                                                customerFirstNameErrorMessage: ""
                                                            });
                                                        }
                                                        else if (value.length > 0) {
                                                            this.setState({
                                                                customerFirstNameErrorMessage: (
                                                                    <small className="text-danger">
                                                                        Invalid First Name.
                                                                    </small>
                                                                ),
                                                                isValid: false
                                                            });
                                                        }
                                                         this.Validate(event, "first_name");
                                                    }
                                                    }
                                                />
                                                {this.state.customerFirstNameErrorMessage}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Last Name: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.last_name}
                                                    type="text"
                                                    placeholder="Last Name"
                                                    name="last_name"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                last_name: value
                                                            }
                                                        }));
                                                        if(value == ""){
                                                            this.setState({
                                                                    customerLastNameErrorMessage: (
                                                                        <small className="text-danger">
                                                                            Last Name is required.
                                                                        </small>
                                                                    ),
                                                                    customerLastNameState: "",
                                                                    isValid: false
                                                             });
                                                        }
                                                        else if (value.length > 0) {                                                            
                                                            this.setState({
                                                                customerLastNameState: "valid",
                                                                customerLastNameErrorMessage: ""
                                                            });
                                                        }
                                                    }
                                                    }
                                                    onBlur={event => {
                                                        var value = event.target.value;
                                                        if (value.length > 0 && this.verifyLength(value, 2)) {
                                                            this.setState({
                                                                customerLastNameState: "valid",
                                                                customerLastNameErrorMessage: ""
                                                            });
                                                        }
                                                        else if (value.length > 0) {
                                                            this.setState({
                                                                customerLastNameErrorMessage: (
                                                                    <small className="text-danger">
                                                                        Invalid Last Name.
                                                                    </small>
                                                                ),
                                                                customerLastNameState: "error",
                                                                 isValid: false
                                                            });
                                                        }
                                                        this.Validate(event, "last_name");
                                                    }
                                                    }
                                                />
                                                {this.state.customerLastNameErrorMessage}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Customer Email: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.email}
                                                    placeholder="Customer Email"
                                                    disabled={this.state.ShowProgressBar}
                                                    name="customerEmail"
                                                    type="email"
                                                    className={this.state.customerEmailState}
                                                    onChange={event =>
                                                        this.ValidateEmailAddress(event, "customerEmail")
                                                    }
                                                    onBlur={event => this.Validate(event, "customerEmail")}
                                                />
                                                {this.state.customerEmailErrorMessage}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    PO Number:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.ponumber}
                                                    type="text"
                                                    disabled={this.state.ShowProgressBar}
                                                    placeholder="PO Number"
                                                    name="ponumber"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                ponumber: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>                                            
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Phone:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.phone}
                                                    type="text"
                                                    placeholder="Phone"
                                                    name="phone"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                phone: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Website Address:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.website}
                                                    type="text"
                                                    placeholder="Website"
                                                    name="website"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                website: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Company:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.company}
                                                    type="text"
                                                    disabled={this.state.ShowProgressBar}
                                                    placeholder="Company"
                                                    name="company"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                company: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Terms:
                                                </div>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="terms"
                                                    isDisabled={this.state.ShowProgressBar}
                                                    value={this.state.term}
                                                    onChange={value => {
                                                        this.setState({ term: value });
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                payment_terms: value.value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                    options={this.state.terms}
                                                    placeholder="Select Term"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Customer ID:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.customer_id}
                                                    type="text"
                                                    disabled={this.state.ShowProgressBar || this.state.isCustomerIDAvailable}
                                                    placeholder="Customer ID"
                                                    name="customer_id"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                customer_id: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Order ID:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.orderid}
                                                    type="text"
                                                    disabled={this.state.ShowProgressBar}
                                                    placeholder="Order ID"
                                                    name="orderid"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                orderid: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={8}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Order Description:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.order_description}
                                                    type="text"
                                                    disabled={this.state.ShowProgressBar}
                                                    placeholder="Order Description"
                                                    name="order_description"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                order_description: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <legend> </legend>
                            {this.CreateCustomFieldUI()}
                            {/* <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                           Patient First Name: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.Invoice.merchant_defined_field_1}
                                            type="text"
                                            disabled={this.state.ShowProgressBar}
                                            placeholder="Patient First Name"
                                            name="merchant_defined_field_1"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    Invoice: {
                                                        ...prevState.Invoice,
                                                        merchant_defined_field_1: value
                                                    }
                                                }));
                                                if(value == ""){
                                                    this.setState({
                                                            merchantDefinedField1ErrorMessage: (
                                                                <small className="text-danger">
                                                                    Patient First Name is required.
                                                                </small>
                                                            ),
                                                            merchantDefinedField1State: "",
                                                            isValid: false
                                                        });
                                                }
                                                else if (value.length > 0) {                                                            
                                                    this.setState({
                                                        merchantDefinedField1State: "valid",
                                                        merchantDefinedField1ErrorMessage: ""
                                                    });
                                                }
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                if (value.length > 0 && this.verifyLength(value, 2)) {
                                                    this.setState({
                                                        merchantDefinedField1State: "valid",
                                                        merchantDefinedField1ErrorMessage: ""
                                                    });
                                                }
                                                else if (value.length > 0) {
                                                    this.setState({
                                                        merchantDefinedField1ErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid Pateint First Name.
                                                            </small>
                                                        ),
                                                        merchantDefinedField1State: "error",
                                                            isValid: false
                                                    });
                                                }
                                                this.Validate(event, "merchant_defined_field_1");
                                            }
                                            }
                                        />
                                        {this.state.merchantDefinedField1ErrorMessage}
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                           Patient Last Name: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.Invoice.merchant_defined_field_2}
                                            type="text"
                                            disabled={this.state.ShowProgressBar}
                                            placeholder="Patient First Name"
                                            name="merchant_defined_field_2"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    Invoice: {
                                                        ...prevState.Invoice,
                                                        merchant_defined_field_2: value
                                                    }
                                                }));
                                                if(value == ""){
                                                    this.setState({
                                                            merchantDefinedField2ErrorMessage: (
                                                                <small className="text-danger">
                                                                    Patient Last Name is required.
                                                                </small>
                                                            ),
                                                            merchantDefinedField2State: "",
                                                            isValid: false
                                                        });
                                                }
                                                else if (value.length > 0) {                                                            
                                                    this.setState({
                                                        merchantDefinedField2State: "valid",
                                                        merchantDefinedField2ErrorMessage: ""
                                                    });
                                                }
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                if (value.length > 0 && this.verifyLength(value, 2)) {
                                                    this.setState({
                                                        merchantDefinedField2State: "valid",
                                                        merchantDefinedField2ErrorMessage: ""
                                                    });
                                                }
                                                else if (value.length > 0) {
                                                    this.setState({
                                                        merchantDefinedField2ErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid Pateint Last Name.
                                                            </small>
                                                        ),
                                                        merchantDefinedField2State: "error",
                                                            isValid: false
                                                    });
                                                }
                                                this.Validate(event, "merchant_defined_field_2");
                                            }
                                            }
                                        />
                                        {this.state.merchantDefinedField2ErrorMessage}
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                           Invoice Number : <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.Invoice.merchant_defined_field_3}
                                            type="text"
                                            disabled={this.state.ShowProgressBar}
                                            placeholder=" Invoice Number"
                                            name="merchant_defined_field_3"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    Invoice: {
                                                        ...prevState.Invoice,
                                                        merchant_defined_field_3: value
                                                    }
                                                }));
                                                if(value == ""){
                                                    this.setState({
                                                            merchantDefinedField3ErrorMessage: (
                                                                <small className="text-danger">
                                                                    Invoice Number is required.
                                                                </small>
                                                            ),
                                                            merchantDefinedField3State: "",
                                                            isValid: false
                                                        });
                                                }
                                                else if (value.length > 0) {                                                            
                                                    this.setState({
                                                        merchantDefinedField3State: "valid",
                                                        merchantDefinedField3ErrorMessage: ""
                                                    });
                                                }
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                if (value.length > 0 && this.verifyLength(value, 2)) {
                                                    this.setState({
                                                        merchantDefinedField3State: "valid",
                                                        merchantDefinedField3ErrorMessage: ""
                                                    });
                                                }
                                                else if (value.length > 0) {
                                                    this.setState({
                                                        merchantDefinedField3ErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid Invoice Number.
                                                            </small>
                                                        ),
                                                        merchantDefinedField3State: "error",
                                                            isValid: false
                                                    });
                                                }
                                                this.Validate(event, "merchant_defined_field_3");
                                            }
                                            }
                                        />
                                        {this.state.merchantDefinedField3ErrorMessage}
                                    </FormGroup>
                                </Col>
                            </Row> */}
                            <legend> </legend>
                            <Row>
                                <Col md={12} style={{ marginTop: "-20px"}}>
                                    <CustomCheckbox
                                        name="CheckBox"
                                        checked={this.state.ShowAddressFields}
                                        labelText="Show Address Information"
                                        labelProps={{ style: { fontSize: "16px",  marginTop: "8px" } }}
                                        formControlProps={{
                                            onChange: (e) => {
                                                var value = e.target.checked;
                                                this.setState({
                                                    ShowAddressFields: value
                                                });
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            {this.state.ShowAddressFields && 
                                <div>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Billing Address:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.address1}
                                                    type="text"
                                                    placeholder="Billing Address"
                                                    name="address1"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                address1: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Billing Country:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.country}
                                                    type="text"
                                                    placeholder="Billing Country"
                                                    name="country"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                country: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Row>
                                                    <Col md={4}>
                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                            Shippng Address:
                                                        </div>
                                                    </Col>
                                                    <Col md={8} style={{ marginTop: "-38px", marginLeft: "-150px" }}>
                                                        <CustomCheckbox
                                                            name="CheckBox"
                                                            checked={this.state.isShippingAddressSameAsBillingAddress}
                                                            labelText="Same as billing address"
                                                            labelProps={{ style: { fontSize: "16px", marginTop: "12px" } }}
                                                            formControlProps={{
                                                                onChange: (e) => {
                                                                    var value = e.target.checked;
                                                                    this.setState({
                                                                        isShippingAddressSameAsBillingAddress: value
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <FormControl
                                                    value={this.state.Invoice.shipping_address1}
                                                    type="text"
                                                    placeholder="Shipping Address"
                                                    name="shipping_address1"
                                                    disabled={this.state.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                shipping_address1: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Shipping Country:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.shipping_country}
                                                    type="text"
                                                    placeholder="Shipping Country"
                                                    name="shipping_country"
                                                    disabled={this.state.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                shipping_country: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Billing State:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.state}
                                                    type="text"
                                                    placeholder="Billing State"
                                                    name="state"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                state: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Billing City:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.city}
                                                    type="text"
                                                    placeholder="Billing City"
                                                    name="city"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                city: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Billing Zip Code:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.zip}
                                                    type="text"
                                                    placeholder="Billing Zip Code"
                                                    name="zip"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                zip: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Shipping State:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.shipping_state}
                                                    type="text"
                                                    placeholder="Shipping State"
                                                    name="shipping_state"
                                                    disabled={this.state.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                shipping_state: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Shipping City:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.shipping_city}
                                                    type="text"
                                                    placeholder="Shipping City"
                                                    name="shipping_city"
                                                    disabled={this.state.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                shipping_city: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Shipping Zip Code:
                                                </div>
                                                <FormControl
                                                    value={this.state.Invoice.shipping_zip}
                                                    type="text"
                                                    placeholder="Shipping Zip Code"
                                                    name="shipping_zip"
                                                    disabled={this.state.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                shipping_zip: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>  
                            }                  
                            <legend> </legend>
                            <Row>
                                <Col md={12}>
                                    <br />
                                    <table className="table table-bordered table-hover" id="tab_logic">
                                        <thead>
                                            <tr style={{ backgroundColor: "#eeeeee" }}>
                                                <th className="text-center" style={{ width: 35, color: "#727272", fontWeight: "bold" }}> # </th>
                                                <th className="text-center" style={{ width: 300, color: "#727272", fontWeight: "bold" }}> PRODUCT/SERVICE </th>
                                                <th className="text-center" style={{ color: "#727272", fontWeight: "bold" }}> DESCRIPTION </th>
                                                <th className="text-center" style={{ width: 90, color: "#727272", fontWeight: "bold" }}>QTY</th>
                                                <th className="text-center" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>RATE</th>
                                                <th className="text-center" style={{ width: 95, color: "#727272", fontWeight: "bold" }}>TAX</th>
                                                <th className="text-center" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>AMOUNT</th>
                                                <th style={{ width: 30 }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.rows.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td className="text-right">{idx + 1}</td>
                                                    <td>
                                                        <SelectCreatable
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="product"
                                                            isDisabled={this.state.ShowProgressBar}
                                                            value={this.state.rows[idx].product}
                                                            formatCreateLabel={(opt) => {
                                                                return <Button simple icon style={{ minWidth: "100%", textAlign: "left" }} onClick={e => this.handleOpenNewItemPopup(e, opt)}>
                                                                    <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add {opt}</i> </Button>
                                                            }
                                                            }
                                                            onChange={value => {
                                                                if (this.state.products.indexOf(value) !== -1) {
                                                                    this.handleRowProductNameChange(idx, value);
                                                                }
                                                            }
                                                            }
                                                            options={this.state.products}
                                                            placeholder="Select Product"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].description}
                                                            type="text"
                                                            disabled={this.state.ShowProgressBar}
                                                            placeholder="Description"
                                                            name="description"
                                                            onChange={this.handleRowDescriptionChange(idx)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].quantity}
                                                            type="number"
                                                            disabled={this.state.ShowProgressBar}
                                                            placeholder="Qty"
                                                            name="quantity"
                                                            style={{ textAlign: "center" }}
                                                            min="1"
                                                            step="1"
                                                            onChange={this.handleRowValueChange(idx)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{ padding: "6px 0px", border: "1px solid #ccc" }}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                            <FormControl
                                                                value={this.state.rows[idx].rate}
                                                                type="number"
                                                                placeholder="Rate"
                                                                name="rate"
                                                                disabled={this.state.ShowProgressBar}
                                                                min="0.00"
                                                                style={{ textAlign: "center", padding: "8px 6px" }}
                                                                step="0.01"
                                                                onChange={this.handleRowValueChange(idx)}
                                                                onBlur={event => {
                                                                    const rows = [...this.state.rows];
                                                                    rows[idx].rate = parseFloat(this.state.rows[idx].rate).toFixed(2);
                                                                    this.setState({ rows });
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].tax}
                                                            type="number"
                                                            disabled={this.state.ShowProgressBar}
                                                            placeholder="Tax"
                                                            name="tax"
                                                            style={{ textAlign: "center" }}
                                                            min="0.00"
                                                            step="0.01"
                                                            onChange={this.handleRowValueChange(idx)}
                                                            onBlur={event => {
                                                                const rows = [...this.state.rows];
                                                                rows[idx].tax = parseFloat(this.state.rows[idx].tax).toFixed(2);
                                                                this.setState({ rows });
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{ padding: "6px 0px", border: "1px solid #ccc", backgroundColor: "#f5f5f5" }}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                            <FormControl
                                                                value={this.state.rows[idx].amount}
                                                                type="number"
                                                                placeholder="0.00"
                                                                name="amount"
                                                                min="0.00"
                                                                style={{ textAlign: "right", padding: "8px 6px" }}
                                                                readOnly={true}
                                                            />
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top" overlay={deleteButtonToolTip}>
                                                            <Button round twitter onClick={this.handleRemoveSpecificRow(idx)}>
                                                                <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Row>
                                        <Col md={7}>
                                            <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={this.handleAddRow}>
                                                <i className="fa fa-plus"></i> Add Line
                                            </Button>
                                        </Col>
                                        <Col md={3} style={{ textAlign: "right" }}>
                                            <p style={totalLabelStyle}>Subtotal</p>
                                        </Col>
                                        <Col md={2}>
                                            <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.subtotal}`).toFixed(2)).toFixed(2)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={7}>
                                        </Col>
                                        <Col md={3} style={{ textAlign: "right" }}>
                                            <p style={totalLabelStyle}>Total Tax</p>
                                        </Col>
                                        <Col md={2}>
                                            <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.Invoice.tax}`).toFixed(2)).toFixed(2)}</p>
                                        </Col>
                                    </Row>
                                    <div style={{ textAlign: "right" }}>
                                        <Row>
                                            <Col md={10}>
                                                <p style={totalLabelStyle}>Total Amount <span className="star">*</span></p>
                                            </Col>
                                            <Col md={2} style={{ textAlign: "center" }}>
                                                <InputGroup >
                                                    <InputGroup.Addon style={{ padding: "6px 0px", border: "1px solid #ccc" }}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                    <FormControl
                                                        value={this.state.Invoice.amount}
                                                        type="number"
                                                        placeholder="Amount"
                                                        name="amount"
                                                        disabled={this.state.ShowProgressBar}
                                                        min="0.00"
                                                        style={{ textAlign: "center", padding: "8px 6px", width: "95px", fontWeight: "bold", fontSize: "16px" }}
                                                        step="0.01"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Invoice: {
                                                                    ...prevState.Invoice,
                                                                    amount: value
                                                                }
                                                            }));
                                                            this.Validate(event, "amount");
                                                        }}
                                                        onBlur={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Invoice: {
                                                                    ...prevState.Invoice,
                                                                    amount: parseFloat(value).toFixed(2)
                                                                }
                                                            }));
                                                            this.Validate(event, "amount");
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                            </Col>
                                            <Col md={6}>
                                                <div style={{ marginRight: "60px" }}> {this.state.amountErrorMessage} </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{ textAlign: "right", marginBottom: "20px" }}>
                                        <br />
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelCreateInvoice(e)}>Cancel</Button>
                                        <Button bsStyle="primary" fill wd onClick={e => this.createInvoiceSubmit(this.state.Invoice)} disabled={!this.state.isValid || this.state.ShowProgressBar} >Create And Send</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                />
                <Modal
                    show={this.state.showNewItemPopupModal}
                    onHide={() => this.setState({ showNewItemPopupModal: false })}
                    bsSize="lg"
                //dialogClassName="modal-dialog-custom"    
                >
                    {this.state.ShowPopupProgressBar &&
                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                            <CustomProgressBar></CustomProgressBar>
                        </div>
                    }
                    <Modal.Header closeButton>
                        <Modal.Title>Product/Service information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            SKU: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_sku}
                                            type="text"
                                            placeholder="SKU"
                                            name="product_sku"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_sku: value
                                                    }
                                                }));

                                                if (this.verifyLength(value, 2)) {
                                                    this.setState({
                                                        productInfoSKUState: "valid",
                                                        productInfoSKUErrorMessage: ""
                                                    });
                                                    this.state.productInfoSKUState = "valid"
                                                    this.validateNewItem();
                                                }
                                                else {
                                                    this.setState({
                                                        productInfoSKUErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid SKU.
                                                            </small>
                                                        )
                                                    });
                                                    this.setState({ isNewItemFormValid: false });
                                                }
                                            }
                                            }
                                            onBlur={e => this.validateNewItem()}
                                        />
                                        {this.state.productInfoSKUErrorMessage}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Commodity Code:
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_commodity_code}
                                            type="text"
                                            placeholder="Commodity Code"
                                            name="product_commodity_code"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_commodity_code: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_commodity_code: value
                                                    }
                                                }));

                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Unit: 
                                        </div>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="product_currency"
                                            value={this.state.currency}
                                            options={this.state.measure_of_unit}
                                            placeholder="Select Unit"
                                        />
                                    </FormGroup>
                                </Col>                      
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Price/Rate: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_cost}
                                            type="number"
                                            placeholder="Price/Rate"
                                            name="product_cost"
                                            min="0.00"
                                            style={{ textAlign: "center" }}
                                            step="0.01"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_cost: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_cost: parseFloat(value).toFixed(2)
                                                    }
                                                }));

                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Tax:
                                                    </div>
                                        <FormControl
                                            value={this.state.productInfo.product_tax_amount}
                                            type="number"
                                            placeholder="Tax"
                                            name="product_tax_amount"
                                            min="0.00"
                                            style={{ textAlign: "center" }}
                                            //onKeyPress= {this.handleRateKeyPress}
                                            step="0.01"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_tax_amount: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_tax_amount: parseFloat(value).toFixed(2)
                                                    }
                                                }));

                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                {/* <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Discount:
                                                    </div>
                                        <FormControl
                                            value={this.state.productInfo.product_discount_amount}
                                            type="number"
                                            placeholder="Discount"
                                            name="product_discount_amount"
                                            min="0.00"
                                            style={{ textAlign: "center" }}
                                            step="0.01"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_discount_amount: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_discount_amount: parseFloat(value).toFixed(2)
                                                    }
                                                }));

                                            }}
                                        />
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Description: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.productInfo.product_description}
                                            type="text"
                                            placeholder="Product Description"
                                            name="product_description"
                                            onChange={event => {
                                                var value = event.target.value;

                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productInfo: {
                                                        ...prevState.productInfo,
                                                        product_description: value
                                                    }
                                                }));

                                                if (this.verifyLength(value, 1)) {
                                                    this.setState({
                                                        productInfoDescriptionState: "valid",
                                                        productInfoDescriptionErrorMessage: ""
                                                    });
                                                    this.state.productInfoDescriptionState = "valid"
                                                    this.validateNewItem();
                                                }
                                                else {
                                                    this.setState({
                                                        productInfoDescriptionErrorMessage: (
                                                            <small className="text-danger">
                                                                Invalid Description.
                                                            </small>
                                                        )
                                                    });
                                                    this.setState({ isNewItemFormValid: false });
                                                }
                                            }
                                            }
                                            onBlur={e => this.validateNewItem()}

                                        />
                                        {this.state.productInfoDescriptionErrorMessage}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Currency: <span className="star">*</span>
                                        </div>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="product_currency"
                                            value={this.state.currency}
                                            // onChange={value => {
                                            //     this.setState(prevState => ({
                                            //         productInfo: {
                                            //             ...prevState.productInfo,
                                            //             product_currency: value
                                            //         }
                                            //     }));
                                            //  }
                                            // }
                                            options={this.state.currencies}
                                            placeholder="Select Currency"
                                        />
                                    </FormGroup>
                                </Col>
                            
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewItemPopupModal: false })}>Close</Button>
                        <Button bsStyle="primary" fill wd onClick={e => this.createItemSubmit(this.state.productInfo)} disabled={!this.state.isNewItemFormValid}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default CreateInvoice;