import React from 'react'
import { BASE_URL, StorageEnum } from "utils/constant.js";
import C2QB_green_btn_lg_default from "assets/img/buttons/C2QB_green_btn_lg_default.png";
import C2QB_green_btn_lg_hover from "assets/img/buttons/C2QB_green_btn_lg_hover.png";


class ConnectToQuickBooksOnlineButton extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        display: false
      };
    }
   
    componentDidMount() {
      this.init();

      document.getElementById("connectToIntuit").addEventListener("click", this.sendModalState);
    }
   
    async init() {
      let script1 = document.createElement('script') 
      script1.src = "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js"
      script1.type = "text/javascript"
      script1.onload = () => {
        let script2 = document.createElement('script') 
        script2.src = "https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere-1.3.3.js"
        script2.type = "text/javascript"
        script2.onload = () => {
          let script3 = document.createElement('script');
          const access_token = localStorage.getItem(StorageEnum.UUID);
          const url = BASE_URL + "api/v1/qbo/connect?code="+access_token;
          
          script3.innerHTML = `
            intuit.ipp.anywhere.setup({
              grantUrl: '${url}',
              datasources: {
                  quickbooks : true,
                  payments : true
              },
                paymentOptions:{
                  intuitReferred : true
              }
            });
          `
          script3.type = "text/javascript"
   
          document.getElementsByTagName("head")[0].appendChild(script3)
        }
        document.getElementsByTagName("head")[0].appendChild(script2)
      }
      document.getElementsByTagName("head")[0].appendChild(script1)

      // let img = document.createElement('img')
      // img.setAttribute("id", "img")
      // img.height = "40px"
      // img.src = `'${C2QB_green_btn_lg_default}'`
      // img.onmouseover = `this.src='${C2QB_green_btn_lg_hover}'`
      // img.onmouseout = `this.src='${C2QB_green_btn_lg_default}'`
      // img.onclick = "intuit.ipp.anywhere.controller.onConnectToIntuitClicked();"

      // document.getElementsByTagName("body")[0].appendChild(img)
    }
    sendModalState = (e) => {
      if (e.target.id === "connectToIntuit") {
        this.props.hideQBOModal(false);
      }
    }
   
    render() {
      // "intuit.ipp.anywhere.controller.onConnectToIntuitClicked();"
      let html = `<img src="${C2QB_green_btn_lg_default}"
      id = "connectToIntuit" 
      height="40"
      onmouseover="this.src='${C2QB_green_btn_lg_hover}'"
      onmouseout="this.src='${C2QB_green_btn_lg_default}'"
      onclick="intuit.ipp.anywhere.controller.onConnectToIntuitClicked();"
       />`
      return (
        // <div dangerouslySetInnerHTML={{__html: "<ipp:connectToIntuit></ipp:connectToIntuit>"}}></div>
        <div dangerouslySetInnerHTML={{__html: html}}></div>
        // <div id="img"></div>
      );
    }
  }

export default ConnectToQuickBooksOnlineButton;