import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
    FormControl,
    Alert
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import NetworkService from "services/NetworkService";
import instantinvoicelogo from "assets/img/instantinvoice/instantinvoicelogo.jfif";

const Styles = {
    h2Class: {
        fontWeight: "300",
        lineHeight: "1",
        color: "#777"
    },
    labelParagraph: {
        fontWeight: "600",
        lineHeight: "1",
        color: "#777"
    }
}    

class Download extends Component {
    constructor(props) {
        super(props);       
        this.state = {    
            ShowProgressBar: false,
            User: {
                ActivationKey: null,
            }        
        }
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
         document.getElementById("download_div2").style.display = "none";
        document.getElementById("download_div").style.display = "none";
    }

    downloadButtonClicked = (event) => {
        //start download here...
        document.getElementById("download_div2").style.display = "block";
        document.getElementById("download_div").style.display = "block";

        this.getFinancialInstaller();
        event.preventDefault();
    }
    
    /** API CALLING */

    // GET request to download QBFinancial installer

    getFinancialInstaller() {        
        const url = BASE_URL + "app/home/installer/qbfinancial/release/instantinvoice.exe";
        const auth_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id =  ApplicationIDEnum.QuickBooksPOS;

        let a = document.createElement('a');
        a.href = url;
        a.download = 'instantinvoice.exe';
        a.click();
    };

    // GET request for product activation key
    getActivationKey = (e) => {
        if (this.state.User.ActivationKey != null) {
            e.preventDefault();
        }
        else {
            this.setState({ShowProgressBar: true});
            const url = BASE_URL + "api/v1/installer/key/discover/";
            const auth_token = "Token " + localStorage.getItem(StorageEnum.UUID);            
            const app_id = ApplicationIDEnum.QuickBooksPOS;

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": auth_token,
                    "APPID": app_id
                }
            }).then(response => {
                return response.json();
            }).then(data => {
                if (data != null) {
                    if (data.statuscode == 0) {
                        this.state.User.ActivationKey = data.data.activatedcode;
                        this.setState(prevState => ({
                            User:
                                {
                                    ...prevState.User, ActivationKey: data.data.activatedcode
                                }
                        }), () => { console.log(data.data.activatedcode); });
                         this.setState({ShowProgressBar: false});
                    } else {
                        this.setState({ShowProgressBar: false});
                        // Show toast with error message
                    }
                }
            }).catch(error => {
                 this.setState({ShowProgressBar: false});
                console.log(error);
            });
        }

        e.preventDefault();
    }
       
    render() {        
        return (
            <div className="ii-main-content">
                <Card
                    ctTextCenter
                    content={
                        <div>
                            {this.state.ShowProgressBar &&
                                <div style={{ marginBottom: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                             <img src={instantinvoicelogo} width="350px" height="110px" alt="Instant Invoice" />
                              <br />
                               <br />
                            <h3 style={Styles.h2Class}>Start using <b>Instant Invoice</b> with QuickBooks POS for accepting payments and save time</h3>
                            <p>Click on download button to download Instant Invoice application. </p>
                            <br />
                            <br />
                             <Button bsStyle="primary" fill onClick={this.downloadButtonClicked}>
                                  <i className="fa fa-windows"></i> Download
                            </Button>
                            
                            {/* <p>For windows</p> */}
                            <br />
                            <div id="download_div2" style={Styles.labelParagraph}>
                                <h4>Your download will start shortly. If your download does not begin, <a href="#" style={{color:"#0075c1"}} onClick={this.downloadButtonClicked}> click here to retry</a></h4>
                            </div>
                            <br />
                            <div id="download_div">
                                <Row>
                                    <Col md={2}>
                                    </Col>
                                    <Col md={10} style={{textAlign:"left"}}>
                                    <p  style={Styles.labelParagraph}> 1. Please wait for download to finish </p>
                                                <p  style={Styles.labelParagraph}> 2. Run InstantInvoice Setup to perform installation</p>
                                                <p  style={Styles.labelParagraph}> 3. Open Quickbooks Financials and start using Instant Invoice for payments.</p>
                                                <p style={Styles.labelParagraph}>4. Get Instant Invoice Installer <a href="#" onClick={e => this.getActivationKey(e)}  style={{color:"#0075c1",fontWeight: "600", lineHeight: "1"}}>
                                                    Activation Key
                                                </a>
                                                </p>                                           
                                                <div>
                                                    <p  style={{color:"#0075c1",fontWeight: "600", lineHeight: "1", marginLeft: "20px"}}><u>{this.state.User.ActivationKey}</u></p>
                                                </div>
                                    </Col>                                
                                </Row>
                            </div>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Download;