import React from 'react';
import ReactToPrint from 'react-to-print';
import Card from "components/Card/Card.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import NMIToInstantInvoice from "assets/img/instantinvoice/NMIToInstantInvoice.png";
import nmi_logo from "assets/img/buttons/nmi_logo.png";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Alert,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";  

class AccountingPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            errorCode: null,
            errorMessage: "",  
            is_nmiinvoicing_connected: false,
        }
    }

    componentWillMount() {
        if(localStorage.getItem("is_nmiinvoicing_connected") == "false"){
            this._isASConnectedRequest();             
        }        
    } 

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.GetAccountingPackage();
    }

    _isASConnectedRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        NetworkService.isASConnectedRequest()
        .then(data => {
            this.setState({ ShowProgressBar: false });

            if (data != null) {
                if (data.statuscode == 0) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null) {
                    //data.data.items.type = "standalone";
                        this.setNMIConnectionResult(data.data.items);
                    }
                }
            } 
            else {
                this.setState({ errorCode: 1 });
                this.setState({ errorMessage: data.statusmessage });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    setNMIConnectionResult(data) {
        if (data.type == "nmiinvoicing") {
            localStorage.setItem("is_nmiinvoicing_connected", "true");
        } 
        else {        
            this.setState({ showNMIInvoicingModal: true });
        }
    }

    GetAccountingPackage() {
        this.showModelAndEPRStatusRequest();
    }

    showModelAndEPRStatusRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.erpStatusRequest();
    }

    /** API's calling */
    erpStatusRequest() {
        const url = BASE_URL + "api/v1/erp/status";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access_token
        }
        }).then(response => {
        return response.json()
        }).then(data => {
            this.setState({ errorCode: data.statuscode });
            this.setState({ ShowProgressBar: false });

            if (data != null && data.statuscode == 0) {
                data.data.forEach((element) => {
                switch (element.type) {
                    case "nmiinvoicing": {
                        this.setState({ is_nmiinvoicing_connected: element.is_connected });
                    break;
                    }
                    default: {
                        console.log("Service not available");
                    }
                }
                });
            }
            else{
                console.log("Service not available");
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    handlerShowNMIInvoiceModel() {
        if (this.state.is_nmiinvoicing_connected) {
            this.disconnectERPRequest();
        } else {
            this.setState({ showNMIInvoicingModal: true, is_nmiinvoicing_connected: false });
        }
     }

    disconnectERPRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/erp/disconnect";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access_token
        }
        }).then(response => {
        return response.json()
        }).then(data => {
            this.setState({ errorCode: data.statuscode });
            this.setState({ ShowProgressBar: false });
            

            if (data != null && data.statuscode == 0) {
                localStorage.setItem("is_nmiinvoicing_connected", false);
                localStorage.setItem(StorageEnum.QBO_CUSTOMERS, null);
                localStorage.setItem("items", null);
                localStorage.setItem("terms", null);
                localStorage.setItem("preferences", null);
                localStorage.setItem("preferences", null);
                localStorage.setItem("taxcodes", null);
                localStorage.setItem("taxrates", null);
                this.setState({ errorMessage: "NMI account disconnected successfully." });
                localStorage.setItem("is_nmiinvoicing_recently_connected", "false");
                this.setState({ is_nmiinvoicing_connected: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            }
            else{
                this.setState({ errorMessage: data.statusmessage });
                console.log("Service not available");
            }
        }).catch(error => {
        this.setState({ ShowProgressBar: false });
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    onCloseModalDialog(){
        this.setState({ showNMIInvoicingModal: false });
        this.setState({ errorCode: 1 });
        this.setState({ errorMessage: "Instant Invoice did not connect to NMI, invoicing feature will not work." });
        console.log("Closing model dialog without connecting to NMI Online.");        
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);        
    }

    _postNMIInvoicingConnectRequest(){
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        NetworkService.postNMIInvoiceConnectRequest()
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNMIInvoicingModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        this.setState({is_nmiinvoicing_connected: true});
                    }
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }


    render() {
        return (
            <div className="ii-main-content">
                <Card
                    //title={"Accounting Software"}
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }                             
                            <Row>
                                <Col md={6} sm={6}>                                
                                    {this.state.ShowProgressBar &&
                                        <div>
                                            <CustomProgressBar></CustomProgressBar>
                                            <br/>
                                        </div>
                                    } 
                                    <Card
                                        content={
                                            <div>
                                                <legend>Accounting Software</legend>
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <img src={nmi_logo} height="60px" />                                                       
                                                    </Col>
                                                    <Col md={3}>
                                                    </Col>
                                                    <Col md={4} className="text-left">                                                        
                                                        <Button bsStyle={this.state.is_nmiinvoicing_connected ? "danger" : "primary"} style={{ marginLeft: "20px", marginTop: "12px" }} fill onClick={() => this.handlerShowNMIInvoiceModel()} >
                                                            {this.state.is_nmiinvoicing_connected ? "Disconnect" : "Connect"}
                                                        </Button>
                                                    </Col>

                                                </Row>                                                
                                            </div>
                                        }
                                    />  
                                </Col>
                            </Row>                      

                        </div>
                    }
                />
                <div>
                    <Modal
                        show={this.state.showNMIInvoicingModal}
                        onHide={() => this.setState({ showNMIInvoicingModal: false })}
                        style={{ marginTop: "200px" }}
                    >
                         {this.state.ShowPopupProgressBar &&
                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                <CustomProgressBar></CustomProgressBar>
                            </div>
                        }
                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                            <Modal.Title style={{ color: "white" }}>Connect to NMI</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-body">
                                <Row>
                                <Col md={6} mdOffset={3}>
                                    <img src={NMIToInstantInvoice} alt="Standalone Invoicing" />
                                </Col>
                                </Row>
                                <Row>
                                <Col md={12} className="text-center">
                                    <p style={{ color: "#7B8084" }}>
                                    <br />
                                    Connect your existing NMI Account with Instant Invoice <br />
                                    Don't already have a NMI account? <a href="https://www.nmi.com/logins" target="_blank" style={{ color: "rgba(0, 117, 193, 0.8)", textDecoration: "underline" }}>Click here</a> to get started.
                                </p>
                                </Col>
                                </Row>
                                <Row>
                                <Col md={12} className="text-center">
                                    <br />                                    
                                    <Button bsStyle= "primary" style={{ marginLeft: "20px", marginTop: "12px" }} fill onClick={() => this._postNMIInvoicingConnectRequest()} >
                                        Connect to NMI 
                                    </Button>
                                </Col>
                                </Row>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default AccountingPackage;
      