import React from 'react';
import {
    Row,
    Col,
    Alert,
    Modal,
} from "react-bootstrap";
import NetworkService from "services/NetworkService";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { ProgressBar } from 'react-bootstrap';
import Card from 'components/Card/Card.jsx';
import { createMuiTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js";


class BatchEmailModule extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 18
            },
        });
        this.state = {
            showBatchEmailModal: false,
            ShowPopupProgressBar: false,
            errorMessage: "",
            successMessage: "",
            isDisableEmailButton: false,
            isSendingEmail: false,
            data: [],
            invoices: [],
            app_id: ApplicationIDEnum.QuickBooksOnline,
            columns: [                
                {
                    title: 'Invoice #',
                    field: 'invoicenumber',
                    headerStyle: {
                        textAlign: "left",
                       // width: "calc(10%)",
                    },
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(20%)'
                    },
                    cellStyle: {
                        width: 'calc(20%)'
                    },
                },
                {
                    title: 'Customer Email',
                    field: 'customeremail',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(20%)'
                    },
                    cellStyle: {
                        width: 'calc(20%)'
                    },
                },
                {
                    title: 'Invoice Date',
                    field: 'invoicedate',
                    type: 'date',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "center",
                    },
                },
                {
                    title: 'Due Date',
                    field: 'duedate',
                    type: 'date',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "center",
                    },
                },
                {
                    title: 'Amount',
                    field: 'balance',
                    cellStyle: {
                        textAlign: "center",
                    },
                }
            ],
            options: {
                paging: false,
                toolbar: false,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,                
            },
        }
    }

    componentDidMount() {
        this.handlerEmailStats();
    }

    componentWillUnmount() {
        this.setState({ showBatchEmailModal: false });
        this.props.parentCallback(false);
    }

    handlerEmailStats = () => {
        if (this.props != null || this.props != undefined) {
            this.state.showBatchEmailModal = this.props.showBatchEmailModal
            this.state.invoices = this.props.Invoices
            this.state.app_id = this.props.AppId
            if(this.state.invoices.length === 0){
                this.setState({ isDisableEmailButton: true });
            }
            const newSeries = [];
            this.props.Invoices.map((invoice, index) => {
            console.log(invoice);
            const data1 = {
                invoicenumber: invoice.DocNumber,                   
                customername: invoice.CustomerRef.name,
                customeremail: invoice.BillEmail.Address,
                invoicedate: invoice.TxnDate,
                duedate: invoice.DueDate,
                balance: '$' + parseFloat(invoice.Balance).toFixed(2)
            }
            newSeries.push(data1);
        });
        this.setState({ data: newSeries });
        }
    }

    handlerCloseModal = () => {
        this.setState({ showBatchEmailModal: false });    
        this.props.parentCallback(false);           
    }

    sendBatchEmailSubmit() {  
        const invoices = this.state.invoices;
        var self = this;
        const url = BASE_URL + "api/v1/invoice/email/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = this.state.app_id; //ApplicationIDEnum.QuickBooksOnline;   
        let i = invoices.length;   
          
        invoices.forEach(function (invoice) {           
            self.setState({ ShowPopupProgressBar: true });
            self.setState({ isDisableEmailButton: true, isSendingEmail: true });
            const payload = {
                "ToEmails": [invoice.BillEmail.Address], 
                "Invoices": [invoice]
            }
            //self.postEmailInvoiceRequest(payload)            
            fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": access_token,
                    "Appid": app_id,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "ToEmails": payload["ToEmails"],
                    "Invoices": payload["Invoices"]
                })
            }).then(response => {
                                
            }).then(data => {
                i = --i;
                self.setState({successMessage: self.state.successMessage + invoice.DocNumber + ","});
                if(i == 0){
                    self.setState({ ShowPopupProgressBar: false }); 
                    self.setState({successMessage: self.state.successMessage.replace(/,\s*$/, "")}); 
                    self.setState({ isDisableEmailButton: false, isSendingEmail: false });
                }
            }).catch(error => {
                i = --i;
                self.setState({errorMessage: self.state.errorMessage + invoice.DocNumber + ","});
                if(i == 0){
                    self.setState({ ShowPopupProgressBar: false });  
                    self.setState({errorMessage: self.state.errorMessage.replace(/,\s*$/, "")});
                     self.setState({ isDisableEmailButton: false, isSendingEmail: false });
                }
            });
        });      
               
    }

   
    render() {
        const customLegenSize = {
            fontSize: "14px",
            fontWeight: "300px",
        };
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <Modal
                            show={this.state.showBatchEmailModal}
                            onHide={() => this.setState({ showBatchEmailModal: false })}
                            bsSize="large" 
                            backdrop="static"     
                            //dialogClassName="modal-dialog-email-statistics-custom"
                            >
                            {this.state.ShowPopupProgressBar && 
                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                <Modal.Title style={{ color: "white" }}>Batch Email</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modal-body text-left" >     
                                    <div>
                                        {this.state.successMessage &&
                                            <Alert bsStyle="success" style={{marginTop:"-15px"}}>
                                                <span>
                                                Invoices email successfully : {this.state.successMessage} 
                                                </span>
                                            </Alert>
                                        }
                                        {this.state.errorMessage &&
                                            <Alert bsStyle="danger"  style={{marginTop:"-15px"}}>
                                                <span>                                            
                                                    Error in sending email invoices : {this.state.errorMessage} 
                                                </span>
                                            </Alert>
                                        }
                                    </div>
                                                                  
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br/>
                                            </div>
                                        }
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.data}   
                                            options={this.state.options}                                             
                                        />
                                    </MuiThemeProvider> 
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.handlerCloseModal()} disabled={this.state.isSendingEmail}>Close</Button>
                                <Button bsStyle="primary" fill wd onClick={e => this.sendBatchEmailSubmit()} disabled={this.state.isDisableEmailButton}>Send</Button>                                   
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default BatchEmailModule