import {StorageEnum } from "utils/constant.js"

class RequestService {

    // async function
    async getRequest(url, app_id, content_type="application/json") {
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        let data = await (await (fetch(url,{
            method:"GET",
            headers:{
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": content_type
            }
        }).then(res => {
                return res.json()
            }).catch(err => {
                console.log('Error: ', err)
            })
        ))
        return data
    }

    // POST async function
    async postRequest(url, app_id, body_intent, content_type="application/json") {
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        let data = await (await (fetch(url,{
            method:"POST",
            headers:{
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": content_type
            },
            body:JSON.stringify(body_intent)
        }).then(res => {
                return res.json()
            }).catch(err => {
                console.log('Error: ', err)
            })
        ))
        return data
    }

    // MULTIPART FORM-DATA async function
    async multipartRequest(url, app_id, body_intent, content_type=null, encoded_type=null) {
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        let data = await (await (fetch(url,{
            method:"POST",
            headers:{
                "Authorization": access_token,
                "Appid": app_id,
            },
            body:body_intent
        }).then(res => {
                return res.json()
            }).catch(err => {
                console.log('Error: ', err)
            })
        ))
        return data
    }
}

export default new RequestService()