/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";

import {
  Nav,
  NavDropdown,
  MenuItem,  
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
    }
  }

  componentDidMount() {

  }
  handleUserLogout(e) {
    //Need to add here client id..
    const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
    if(data_tokenize != null)
    {
      let tokenizationKey = data_tokenize.PPToken;
      if(document.getElementById(tokenizationKey))
      {
          document.getElementById(tokenizationKey).remove();
      }
    } 
    localStorage.clear();     
      
    window.location.href = BASE_URL + "#/account/login";
    e.preventDefault();
  };

  handleCustomerLogin(e){
    const url = window.location.href;
    const path = url.split("=");
    const token = (path[1] || "");
    window.location.href = BASE_URL + "#/customer/login?id="+token;
    e.preventDefault();
  }

  // handleHomeClick(e) {
  //   window.location.href = BASE_URL + "#/account/home";
  //   e.preventDefault();
  // }


  render() {
    return (
      <div >        
        {!(window.location.href.indexOf("login") > -1) && !(window.location.href.indexOf("registration") > -1) &&          
          !(window.location.href.indexOf("resetpassword") > -1) && 
          <div >
            {!(window.location.href.indexOf("customer/payment") > -1)  && !(window.location.href.indexOf("customer/ccpayment") > -1)  &&
              <div>
                <Nav pullRight  >
                  <NavDropdown
                    eventKey={4}
                    title={
                      <div>
                        <i className="fa fa-list" />                    
                        <p className="hidden-md hidden-lg">
                          More
                          <b className="caret" />
                        </p>
                      </div>
                    }
                    noCaret
                    id="basic-nav-dropdown-3"
                    bsClass="dropdown-with-icons dropdown"
                  >                
                    <MenuItem eventKey={4.5} onClick={e => this.handleUserLogout(e)}>                 
                      <i className="fa fa-user-times" /> Log out             
                    </MenuItem>
                  </NavDropdown>
                </Nav>
                <div className="customUserLabel"> 
                  <div className="fa fa-user"></div> 
                  <span>
                    <b>{localStorage.getItem("FULLNAME") || ""}</b>
                  </span>
                </div>
              </div>
            }
            {((window.location.href.indexOf("customer/payment") > -1)  || (window.location.href.indexOf("customer/ccpayment") > -1))  &&
              <div className="customUserLabel">    
                <OverlayTrigger placement="left" overlay={<Tooltip id="customerLoginToolTip">To review all invoices and history</Tooltip>}>
                  <Button bsStyle="primary" fill style={{ marginTop: -5 }} wd onClick={e => this.handleCustomerLogin(e)}>Login</Button>                         
                </OverlayTrigger>                               
              </div>
            }            
            
          </div>
        }
      </div>
    );
  }
}
export default HeaderLinks;
