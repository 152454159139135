import React, { Component } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Panel,
    Tab,
    Nav,
    NavItem,
    Alert, 
    InputGroup,
    OverlayTrigger,
    Tooltip,

} from "react-bootstrap";
import Datetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
//import ChartistGraph from "react-chartist";
import Chart from "react-apexcharts";
import Card from "components/Card/Card.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import {
    dataPie
} from "variables/Variables.jsx";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: true,
            invoice_stats: {
                paid: 0,
                due: 0,
                overdue: 0
            },
            donutInvoiceOptions: {
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    colors: ['#00e396', '#feb019', '#ff4560']
                },
                tooltip: {
                    enabled: false
                },
                labels: ["Paid", "Due", "Overdue"],
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    hover: {
                        filter: {
                            type: 'lighten',
                            value: 0.15,
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '22px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: '#0066a8',
                                    offsetY: -5
                                },
                                value: {
                                    fontSize: '18px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    offsetY: 5,
                                    color: '#0066a8'
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: false,
                },
                chart: {
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            // console.log(config.w.config.labels[config.dataPointIndex])
                            this.donutSliceLegendClick(config.w.config.labels[config.dataPointIndex]);
                        }
                    },
                }
            },
            donutInvoiceData: [0, 0, 0],
            //donutEmailOptions
            donutEmailOptions: {
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false
                },
                fill: {
                    colors: ["#008ffb", '#00e396', '#feb019', "#775dd0"]
                },
                labels: ["Requested", "Processed", "Delivered", "Read"],
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    hover: {
                        filter: {
                            type: 'lighten',
                            value: 0.15,
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '20px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: '#0066a8',
                                    offsetY: -5
                                },
                                value: {
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    offsetY: 5,
                                    color: '#0066a8'
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: false,
                }
            },
            donutEmailData: [0, 0, 0, 0],
            //donutEmailOptions
            payment_stats: {
                amount: 0
            },
            donutPaymentTypeOptions: {
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false
                },
                // fill: {
                //     colors: ["#008ffb", '#00e396', '#feb019', "#775dd0"]
                // },
                labels: ["Credit Card", "eCheck(ACH)"],
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    hover: {
                        filter: {
                            type: 'lighten',
                            value: 0.15,
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '20px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: '#0066a8',
                                    offsetY: -5
                                },
                                value: {
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    offsetY: 5,
                                    color: '#0066a8'
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: false,
                }
            },
            donutPaymentTypeData: [0, 0],
            // 
            splinePaymentOptions: {
                chart: {
                    height: 350,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#008ffb", '#fb404b'],
                fill: {
                    type: 'gradient'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'date',
                    categories: [
                        "2020-04-01", "2020-04-02", "2020-04-03", "2020-04-04", "2020-04-05", "2020-04-06", "2020-04-07", "2020-04-08", "2020-04-09", "2020-04-10",
                        "2020-04-11", "2020-04-12", "2020-04-13", "2020-04-14", "2020-04-15", "2020-04-16", "2020-04-17", "2020-04-18", "2020-04-19", "2020-04-20",
                        "2020-04-21", "2020-04-22", "2020-04-23", "2020-04-24", "2020-04-25", "2020-04-26", "2020-04-27", "2020-04-28", "2020-04-29", "2020-04-30"
                    ]
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy'
                    },
                },
                legend: {
                    show: false,
                },
            },
            splinePaymentData: [{
                name: 'Approved',
                data: [0]
            },
            // {
            //     name: 'eCheck (ACH)',
            //     data: [11, 32, 45, 32, 34, 52, 41, 65, 27, 75, 11, 32, 45, 32, 34, 52, 41, 65, 27, 75, 11, 32, 45, 32, 34, 52, 41, 65, 27, 75]
            // },
            {
                name: 'Declined',
                data: [0]
            }],
        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    /** HANDLER */
    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.getDashboardStats();
    }

    handleInvoiceWidgetClick(invoiceStatus, invoiceCount) {
        console.log("invoice status is =  " + invoiceStatus + "  count = " + invoiceCount);
        this.props.history.push({
            pathname: '/invoice/invoices',
            state: { invoice_status: invoiceStatus.toLowerCase() }
        });
    }

    handlePaymentWidgetClick(paymentStatus) {
        console.log("payment status is =  " + paymentStatus);
    }

    setFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        
        document.getElementById("loaderDiv").style.display = "none";
        this.setFromToDates();
        this.getDashboardStats();
    }

    donutSliceLegendClick(invoiceValue) {
        console.log("You click : " + invoiceValue)
        this.props.history.push({
            pathname: '/invoice/invoices',
            state: { invoice_status: invoiceValue.toLowerCase() }
        })
    }

    getDashboardStats = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate
        }
        try {
            for (var d = this.state.from_date; d <= this.state.to_date; d.setDate(d.getDate() + 1)) {
                this.state.splinePaymentOptions.xaxis.categories.push(this.formatDate(d));
            }
            console.log(this.state.splinePaymentOptions.xaxis.categories);
        } catch (error) {
            console.log(error)
        }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this.getDashboardRequest(query);
        this._getEmailStatsRequest(query);
        this._getPaymentStatsRequest(query);
    };

    /** Api's Implementation */
    // GET request for dashboard stats
    getDashboardRequest(params = "") {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const url = BASE_URL + "api/v1/invoice/dashboard/?" + params;
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token,
                "Appid": app_id
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            this.setState({ ShowProgressBar: false });

            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                this.onSetResult(data.data);
            } else {

            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    _getEmailStatsRequest(params = "") {
        // this.setState({ ShowProgressBar: true });
        // this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getEmailStatsRequest(app_id, params)
            .then(data => {
                // this.setState({ ShowProgressBar: false });

                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                        this.onSetEmailStatsResult(data.data);
                    }

                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                // this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getPaymentStatsRequest(params = "") {       
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getPaymentStatsRequest(app_id, params)
            .then(data => {              
                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                        this.onSetPaymentStatsResult(data.data);
                    }

                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    onSetResult = (result) => {
        let invoice_stats = result.invoice_stats;
        this.setState({ invoice_stats: invoice_stats });
        this.setState({ donutInvoiceData: [invoice_stats.paid || 0, invoice_stats.due || 0, invoice_stats.overdue || 0] });
    };

    onSetEmailStatsResult = (result) => {
        let email_stats = result.email_stats;
        this.setState({ donutEmailData: [email_stats.emailsent || 0, email_stats.processed || 0, email_stats.delivered || 0, email_stats.open || 0] })
    }

    onSetPaymentStatsResult = (result) => {   
        let payment_stats = result.payment_stats;
        this.setState({ donutPaymentTypeData: [payment_stats.credit_card || 0, payment_stats.ach || 0] })
        this.setState({ payment_stats: { amount: payment_stats.amount != null ? parseFloat(payment_stats.amount).toFixed(2) : 0.00} })

    }



    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        const customLegenSize = {
            fontSize: "16px",
            fontWeight: "300px",
        };
        
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} sm={6}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="viewPaidInvoiceToolTip">Click to view Paid Invoices</Tooltip>}>
                                        <span onClick={this.handleInvoiceWidgetClick.bind(this, "paid", this.state.invoice_stats == undefined ? 0 : this.state.invoice_stats.paid)}>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-newspaper-o text-success" style={{ fontSize: "60px", verticalAlign: "bottom" }} />}
                                                statsText="Paid Invoices"
                                                statsValue={this.state.invoice_stats == undefined ? 0 : this.state.invoice_stats.paid}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="viewDueInvoiceToolTip">Click to view Due Invoices</Tooltip>}>
                                        <span onClick={this.handleInvoiceWidgetClick.bind(this, "due", this.state.invoice_stats == undefined ? 0 : this.state.invoice_stats.due)}>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-newspaper-o text-warning" style={{ fontSize: "60px", verticalAlign: "bottom" }} />}
                                                statsText="Due Invoices"
                                                statsValue={this.state.invoice_stats == undefined ? 0 : this.state.invoice_stats.due}                                           
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">Click to view Overdue Invoices</Tooltip>}>
                                        <span onClick={this.handleInvoiceWidgetClick.bind(this, "overdue", this.state.invoice_stats == undefined ? 0 : this.state.invoice_stats.overdue)}>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-newspaper-o text-danger" style={{ fontSize: "60px", verticalAlign: "bottom" }} />}
                                                statsText="Overdue Invoices"
                                                statsValue={this.state.invoice_stats == undefined ? 0 : this.state.invoice_stats.overdue}                                            
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <span onClick={this.handlePaymentWidgetClick.bind(this, "Payments")}>
                                        <StatsCard
                                            bigIcon={<i className="fa fa-money text-info" style={{ fontSize: "60px", verticalAlign: "bottom" }} />}
                                            statsText="Payments ($)"
                                            statsValue= {this.state.payment_stats == undefined ? 0 : this.state.payment_stats.amount}                                      
                                        />
                                    </span>
                                </Col>
                            </Row>
                            <br />
                            {this.state.ShowProgressBar ?
                                <div>
                                    <CustomProgressBar></CustomProgressBar>
                                    <br />
                                </div>
                                : ""
                            }
                            <Row>
                                <Col md={4}>
                                    <Card
                                        title="Invoices"
                                        ctTextCenter
                                        content={
                                            <Chart
                                                options={this.state.donutInvoiceOptions}
                                                series={this.state.donutInvoiceData}
                                                type="donut"
                                            />
                                        }
                                        legend={
                                            <div className="text-center">
                                                <span style={customLegenSize} onClick={e => this.donutSliceLegendClick("Paid")}> <i className="fa fa-circle text-success" /> Paid </span>
                                                <span style={customLegenSize} onClick={e => this.donutSliceLegendClick("Due")}><i className="fa fa-circle text-warning" /> Due </span>
                                                <span style={customLegenSize} onClick={e => this.donutSliceLegendClick("Overdue")}> <i className="fa fa-circle text-danger" /> Overdue   </span>
                                            </div>
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <Card
                                        title="Email Statistics"
                                        ctTextCenter
                                        content={
                                            <Chart
                                                options={this.state.donutEmailOptions}
                                                series={this.state.donutEmailData}
                                                type="donut"
                                            />
                                        }
                                        legend={
                                            <div className="text-center">
                                                <span style={customLegenSize}> <i className="fa fa-circle" style={{ color: "#008ffb" }} /> Requested </span>
                                                <span style={customLegenSize}><i className="fa fa-circle text-success" /> Processed </span>
                                                <span style={customLegenSize}><i className="fa fa-circle text-warning" /> Delivered </span>
                                                <span style={customLegenSize}><i className="fa fa-circle" style={{ color: "#775dd0" }} /> Read </span>
                                            </div>
                                        }
                                    />
                                </Col>
                                <Col md={4}>
                                    <Card
                                        title="Payment Types"
                                        ctTextCenter
                                        content={
                                            <Chart
                                                options={this.state.donutPaymentTypeOptions}
                                                series={this.state.donutPaymentTypeData}
                                                type="donut"
                                            />
                                        }
                                        legend={
                                            <div className="text-center">
                                                <span style={customLegenSize}> <i className="fa fa-circle" style={{ color: "#008ffb" }} /> Credit Card </span>
                                                <span style={customLegenSize}><i className="fa fa-circle text-success" /> eCheck(ACH) </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                            <br />                            
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Dashboard;