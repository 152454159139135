/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "components/Footer/Footer.jsx";
import AccountNavbar from "components/Navbars/AccountNavbar.jsx";
import routes from "AccountRoutes.js";
import bgImage from "assets/img/instantinvoice/bg1.png";

class Create extends Component {
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
 
 
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/create") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    
    return (
      <div>         
          <div
            className={"full-page-white"}
            data-color="lightWhite" 
            data-image={bgImage}
          >          
            <div className="content">
            <AccountNavbar /> 
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
            <Footer  fluid  />
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
              />
            </div>
      </div>
    );
  }
}

export default Create;
