/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react component that creates a form divided into multiple steps
import StepZilla from "react-stepzilla";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";



class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setForm = {
      formUrl: '',
      tokenId: '',
      redirectUrl: '',
      htmlText: null,

      step1Completed: false,
      step2Completed: false,
      step3Completed: false
    };
    this.setChargedAmount = props.amountToCharge;
    this.isActionTypeCC = props.isActionTypeCC;
  }

  getForm() { return this.setForm; }


  updateForm(update) {
    this.setForm = {
      ...this.setForm,
      ...update
    }
  }

  onTrigger = (event, step) => {
    this.props.wizardCallback(event, step);
    // event.preventDefault();
  }

  getChargedAmount() { return this.setChargedAmount; }

  updateActionTypeCC(update){
    this.isActionTypeCC=update;
  }

  getActionTypeCC() {return this.isActionTypeCC;}

  componentWillReceiveProps(props) {
    if (props) {
      this.updateForm(props.setForm ? props.setForm : props);
      this.updateActionTypeCC(props.isActionTypeCC ? props.isActionTypeCC : false);
    }
  }

  render() {
    const steps = [
      { name: "Personal", component: <Step1 getForm={() => (this.getForm())} updateForm={(u) => { this.updateForm(u) }} onTrigger={(e, s) => { this.onTrigger(e, s) }} amountToCharge={this.getChargedAmount()} /> },
      { name: "Card/ACH", component: <Step2 getForm={() => (this.getForm())} updateForm={(u) => { this.updateForm(u) }} onTrigger={(e, s) => { this.onTrigger(e, s) }} getActionTypeCC={this.getActionTypeCC()}/> },
      { name: "Verification", component: <Step3 getForm={() => (this.getForm())} updateForm={(u) => { this.updateForm(u) }} onTrigger={(e, s) => { this.onTrigger(e, s) }} /> }
    ];


    return (
      <div>
        <Grid fluid>
          <Row>
            <Col >
              <Card
                wizard
                id="wizardCard"
                textCenter
                title="3D-Secure Payer Auth"
                category=""
                content={
                  <StepZilla
                    steps={steps}
                    stepsNavigation={false}
                    nextButtonCls="btn btn-prev btn-info btn-fill pull-right btn-wd"
                    backButtonCls="btn btn-next btn-default btn-fill pull-left btn-wd"
                    prevBtnOnLastStep={false}
                    showNavigation={false}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Wizard;
