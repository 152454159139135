import React, { Component } from "react";
import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Alert

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import MaterialTable, { MTableToolbar } from 'material-table';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js"
import NetworkService from "services/NetworkService";

class Resellers extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            ShowProgressBar: false,
            errorMessage: "",
            errorCode: 1,
            data: [],
            columns: [
                {
                    title: 'Company',
                    field: 'companyname',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Reseller Name',
                    field: 'resellername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Email',
                    field: 'email',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(20%)'
                    },
                    cellStyle: {
                        width: 'calc(20%)'
                    },
                },
                {
                    title: 'Phone No.',
                    field: 'phonenumber',
                    headerStyle: {
                        width: 'calc(20%)',
                        textAlign: "center"
                    },
                    cellStyle: {
                        textAlign: "center",  
                         width: 'calc(20%)'                      
                    },
                },
                {
                    title: 'Affiliation',
                    field: 'reseller_affiliation',
                    headerStyle: {
                        textAlign: "left"
                    },
                    cellStyle: {
                        textAlign: "left"                        
                    },
                },
                {
                    title: 'Processor Type',
                    field: 'processor_type',
                    headerStyle: {
                        textAlign: "left"
                    },
                    cellStyle: {
                        textAlign: "left"                        
                    },
                },
                {
                    title: 'Status',
                    field: 'status',
                    headerStyle: {
                        textAlign: "center"
                    },
                    cellStyle: {
                        textAlign: "center"                        
                    },
                },

            ],
            options: {
                //selection: true,
                pageSize: 25,
                pageSizeOptions: [10, 25, 50, 100, 200, 500],
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            subUsersOptions: {
                //selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                paging: false,
                toolbar: false,
                sorting: false,
                draggable: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },           
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />

                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="resellerBoardingToolTip">Add New Reseller</Tooltip>}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateReseller(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>

                        </div>
                    </div>
                ),
            },
            actions: [                                
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editResellerToolTip">Edit</Tooltip>}>
                        <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleEditReseller(event, rowData)
                },    
                   
            ],
        }
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');            
        }
        else if(localStorage.getItem("login_user_type") != "Admin")
        {
            this.props.history.goBack();//go(-1);
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        this._resellerListingRequest();
    }

    handleCreateReseller(e) {
        this.props.history.push('/admin/createreseller');
        e.preventDefault();
    }

    handleEditReseller(e, reseller) {
        this.props.history.push({
            pathname: '/admin/editreseller',
            state: { resellerData: reseller.id }
        });
        sessionStorage.setItem("_ResellerID", reseller.id)
        e.preventDefault();
    }

    
    handleDefaultPassword = (e, reseller) => {        
        e.preventDefault();
        const params = {"email":reseller.email}
        this._defaultPasswordEmailRequest(params);
    }

    
    handleDeleteReseller(e, reseller) {
        console.log(reseller);
        //need to delete reseller here..
        e.preventDefault();
    }

    setResellerResult(data) {
        const newSeries = [];
        const resellerArr = [];        
        data.map((value, index) => {                    
            resellerArr.push({
                id: value.id,
                companyname: value.company_name,
                resellername: value.first_name + " " + value.last_name,
                email: value.email,
                phonenumber: value.phone_number,
                reseller_affiliation: value.reseller_affiliation,
                processor_type: value.processor_type,
                status: value.status ? "Active" : "InActive",
            });
        });
        
        this.setState({ data: resellerArr });
    }

    /** API CALLING */
    _resellerListingRequest() {        
        this.setState({ ShowProgressBar: true });
        NetworkService.getResellerListingRequest()
            .then(data => {
                if (data != null) {
                    if (data.statuscode == 0) {
                         this.setState({ ShowProgressBar: false });
                        if (data.data != null) {
                            this.setResellerResult(data.data.items)
                        }
                    }
                } else {
                     this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);    
    }

    _defaultPasswordEmailRequest(body){
        this.setState({ ShowProgressBar: true });
        NetworkService.postDefaultPasswordEmailRequest(body)
            .then(data => {
                if (data != null) {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                } else {
                     this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);    
    }

    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                             {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>                                    
                                </div>
                            }
                            {this.state.ShowProgressBar &&
                                <div style={{ marginBottom: "2px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                             <br />
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            actions={this.state.actions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            components={this.state.components}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Resellers;