import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,

} from "react-bootstrap";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from "react-select";
import Datetime from "react-datetime";
import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import RecurringModal from "views/Components/CreateRecurringModal.jsx";
import Chip from '@material-ui/core/Chip';
import NetworkService from "services/NetworkService";

class Invoices extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            Invoice: {},
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            invoiceStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                { value: "all", label: "All" },
                { value: "due", label: "Due" },
                { value: "overdue", label: "Overdue" },
                { value: "paid", label: "Paid" },
            ],
            filter_invoice: { value: "all", label: "All" },
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: false,
            showActionButtons: "none",
            showPrintButton: "none",
            customerEmail: "ahsan766@gmail.com",
            rawInvoiceData: [],
            selectedInvoiceIds: "",
            data: [
                // { invoicenumber: '654541', customername: 'Can dynamically add', invoicedate: '2020-01-01', duedate: '2020-12-05', balance: '$633.21', total: '$633.21', invoicestatus: 'Paid' },
            ],
            columns: [
                {
                    title: 'Invoice #',
                    field: 'invoicenumber', 
                    headerStyle: {                        
                        textAlign: "left",
                        width: "calc(10%)",                        
                    },
                    cellStyle: {
                        width: "calc(10%)",
                    }                     
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(30%)'
                    },
                    cellStyle: {
                        width: 'calc(30%)'
                    },
                },
                {
                    title: 'Invoice Date',
                    field: 'invoicedate',
                    type: 'date',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "center",
                    },
                },
                {
                    title: 'Due Date',
                    field: 'duedate',
                    type: 'date',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "center",
                    },
                },
                {
                    title: 'Balance',
                    field: 'balance',
                    cellStyle: {
                        textAlign: "center",
                    },                    
                },
                {
                    title: 'Total',                    
                    field: 'total',
                    cellStyle: {
                        textAlign: "center",
                    },                      
                },
                {
                    title: 'Invoice Status',
                    field: 'invoicestatus',
                    sortable: false,
                    cellStyle: {
                        textAlign: "center",
                    },
                }
            ],
            options: {
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"                   
                }),
                selectionProps: rowData => ({                   
                    color: 'primary',
                }),
            },
            printInvoiceToolTip: <Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>,
            collectPaymnetToolTip: <Tooltip id="collectPaymnetToolTip">Pay Invoice</Tooltip>,
            createRecurringToolTip : <Tooltip id="createRecurring">Create Recurring</Tooltip>,
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>  
                            <div style={{ display: "none" }}> <ComponentToPrint ref={el => (this.componentRef = el)} dataFromParent={this.state.selectedInvoiceIds} /> </div>                           
                            <OverlayTrigger placement="top" overlay={this.state.collectPaymnetToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePaymentInvoice(e)} style={{ marginRight: '10px', display: this.state.showActionButtons }}> <i className="fa fa-credit-card" ></i> </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={this.state.createRecurringToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateRecurringClick(e)} style={{ marginRight: '10px', display: this.state.showActionButtons }}> <i className="fa fa-refresh"></i> </Button> 
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
                
            },
            singleEmailInvoiceData: {
                customerName: "",
                customerEmail: "",
                invoiceNumber: "",
                balance: "",
                index: ""
            },
            selectedInvoiceIndex: [],
            isCreateRecurringEnabled: false,
            ProfileParams:{},
            RecurringParams:{},
            CustomerProfileInfo:{},
            IsCreditCard: true,
            showCreateRecurringModal: false,

        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem("login_user_type") != "Customer") {
            this.props.history.push('/account/login');
        }        
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        }

        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        let invoiceStatusFilter = this.props.location.state ? this.props.location.state.invoice_status ? this.props.location.state.invoice_status : "all" : "all";
        this.state.filter_invoice = { value: invoiceStatusFilter, label: invoiceStatusFilter[0].toUpperCase() + invoiceStatusFilter.slice(1) }
        document.getElementById("loaderDiv").style.display = "none";
        this.SetFromToDates();
        this.filterInvoice();
        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }
    /** HANDLER */
    fromDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.filterInvoice();
    }

    handlePaymentInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
        });
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        if(data_tokenize.PPType.toLowerCase() === "cardconnect"){
            this.props.history.push({
                pathname: '/customerportal/customerpayment',
                state: { invoiceData: invoice }
            });
        }
        else if(data_tokenize.PPType.toLowerCase() === "nmi"){      
            this.props.history.push({
                pathname: '/customerportal/nmipayment',
                state: { invoiceData: invoice }
            });
        }
    }

   
    filterInvoice = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
            "status": this.state.filter_invoice.value,
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this.getInvoiceDetailsRequest(query);
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleInvoiceDetailClick(invoice) {
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        this.props.history.push('/customerportal/detail/' + invoice.Id);
    }

    handleSelectedRowData(data, length) {
        let newStates = [];
        let invoiceIds = "";
        data.map(d => {
            newStates.push(d.index);
            invoiceIds = invoiceIds + "," + d.id;
        });
        this.state.selectedInvoiceIndex = newStates;
        this.state.selectedInvoiceIds = this.replaceAt(invoiceIds, 0, '');
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });
        const app_id = localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    if (data.statuscode === 0) {
                        let processor = data.data.items;
                        this.setProcessorResult(processor);
                    }
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setProcessorResult = (processors) => {
         let tokenize_key = null;
          let processor = null;
          processors.map(data => {
            if(data.is_default == true)
            {
                processor = data;
            }
        });

        if (processor!= null){
            if (processor.method_type == "nmi"){
                tokenize_key = JSON.stringify({"PPType":"nmi", "PPToken":processor.TokenizationKey})
            }else if (processor.method_type == "cardconnect"){
                tokenize_key = JSON.stringify({"PPType":"cardconnect", "PPToken":processor.configuration.URL})
            }
        }
        localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
    }    

    /** API CALLING */

    // GET request for invoice details
    getInvoiceDetailsRequest(params = "") {
        this.setState({ ShowProgressBar: true });

        const url = BASE_URL + "api/v1/invoice/filter/?" + params;
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 6;//localStorage.getItem(StorageEnum.APPID);

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Content-Type": "application/json",
                "appid": app_id
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.onSetResult(data.data);
                this.setState({ ShowProgressBar: false });
            } else {
                this.setState({ ShowProgressBar: false });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

    onSetResult = (result) => {
        const newSeries = [];        
        result.items.map((invoice, index) => {
            const data = {
                index: index,
                id: invoice.Id,
                invoicenumber: 
                <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                        <a className="invoicelink"  onClick={this.handleInvoiceDetailClick.bind(this, invoice)}>                    
                            {invoice.DocNumber}
                        </a>
                    </OverlayTrigger>,
                customername: invoice.CustomerRef.name,
                customeremail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoicedate: invoice.TxnDate,
                duedate: invoice.DueDate,
                balance: '$' + parseFloat(invoice.Balance).toFixed(2),
                total: '$' + parseFloat(invoice.TotalAmt).toFixed(2),
                invoicestatus: invoice.IsPaid ? <Chip style={{ backgroundColor: "#25D366", color: "white", height: 25 }} label="Paid"></Chip> : parseFloat(invoice.Balance).toFixed(2) < parseFloat(invoice.TotalAmt).toFixed(2) ? <Chip style={{ backgroundColor: "#feb019", color: "white", height: 25 }} label="Partially Paid"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Unpaid"></Chip>,               
            }
            newSeries.push(data);

        });
        this.setState({ rawInvoiceData: result.items });
        this.setState({ data: newSeries });
    }


    handlePrintClick(e) {
        e.preventDefault();
        let invoiceIds = "";
        this.state.selectedInvoiceIndex.map(index => {
            invoiceIds = invoiceIds + "," + (this.state.rawInvoiceData[index].Id);
        })
        invoiceIds = this.replaceAt(invoiceIds, 0, '');       
    }

    replaceAt(str, index, ch) {
        return str.replace(/./g, (c, i) => i === index ? ch : c)
    }

    handleCreateRecurringClick(e) {   
        this.setState({ showCreateRecurringModal: true });       
    }

    createRecurringCallbackFunction = (childData , recurringParams, profileParams, customerProfileInfo, isCreditCard) => {
        if(this.state.showCreateRecurringModal){
            this.setState({ProfileParams: profileParams});
            this.setState({RecurringParams: recurringParams});
            this.setState({IsCreditCard: isCreditCard});       
            this.setState({CustomerProfileInfo: customerProfileInfo});
            const payload = {    
                "ProfileParams": profileParams,
                "RecurringParams": recurringParams,
                "InvoiceParams": this.state.Invoice                
            }
            this._postCreateRecurringRequest(payload);
        }
        this.setState({ showCreateRecurringModal: childData });
    }

    _postCreateRecurringRequest(payload) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postCreateRecurringRequest(app_id, payload)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        this.props.history.push({
                            pathname: '/invoice/invoices',
                            state: { errorMessage: data.statusmessage, errorCode: 0 }
                        });
                    }                    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }


    render() {       
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>Invoice Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="invoiceStatus"
                                            value={this.state.filter_invoice}
                                            onChange={value => {
                                                this.setState({ filter_invoice: value });
                                            }
                                            }
                                            options={this.state.invoiceStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>

                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>

                                        }
                                        <MaterialTable
                                            //title="Styling with MuiThemeProvider Preview"
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            onSelectionChange={(rows) => {
                                                this.setState({ showPrintButton: "none" });
                                                this.setState({ showActionButtons: "none" });
                                                this.handleSelectedRowData(rows, rows.length);
                                                if (rows.length > 0) {
                                                    this.setState({ showPrintButton: "" });
                                                    if (rows.length === 1) {
                                                        this.state.selectedInvoiceIndex.map(index => {
                                                            this.state.Invoice = this.state.rawInvoiceData[index];
                                                        });
                                                        if (this.state.Invoice.IsPaid) {
                                                            this.setState({ showActionButtons: "none" });
                                                        }
                                                        else {
                                                            this.setState({ showActionButtons: "" });
                                                        }                                                        
                                                    }
                                                    else {
                                                        this.setState({ showActionButtons: "none" });
                                                    }
                                                }
                                                else {
                                                    this.setState({ showPrintButton: "none" });
                                                    this.setState({ showActionButtons: "none" });
                                                }
                                            }
                                            }
                                            components={this.state.components}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                            {this.state.showCreateRecurringModal &&
                                <RecurringModal showCreateRecurringModal={this.handleCreateRecurringClick} parentCallback={this.createRecurringCallbackFunction} CustomerRef={this.state.Invoice.CustomerRef} ProfileParams={this.state.ProfileParams} RecurringParams={this.state.RecurringParams} CustomerProfileInfo={this.state.CustomerProfileInfo} IsCreditCardProfile={this.state.IsCreditCard} />
                            } 
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Invoices;

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHTMLDiv: true,
            InvoiceRawHtml: null,
            prevValue: "",
        }
    }

    getInvoicePreview(invoice_id) {
        const params = {
            "invoice_id": invoice_id
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }

    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {
        const app_id = localStorage.getItem(StorageEnum.APPID);
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    const content_type = data.data.content_type;
                    if (content_type === "application/html") {
                        this.setState({ showHTMLDiv: true })
                    } else {
                        this.setState({ showHTMLDiv: false });
                        this.renderPDFView(data.data.html || null, "pdf-canvas");
                    }
                    this.setState({ InvoiceRawHtml: data.data.html || null });
                } else {
                }
            }).catch(error => {
                this.setState({ InvoiceRawHtml: "<h1>Oops! Something went wrong, please try again.</h1>" });
            });
    }

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {

            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    rawMarkup(value) {
        if (this.state.prevValue != value && value != null) {
            this.state.prevValue = value;
            this.getInvoicePreview(value);
        }
        return { __html: this.state.InvoiceRawHtml };
    };


    render() {
        return (
            <div>
                <div style={{ display: "none" }} dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />

                {this.state.showHTMLDiv &&
                    <div dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />
                }
                {!this.state.showHTMLDiv &&
                    <div id="pdf-canvas" height="0"></div>
                }
            </div>
        );
    }
}






