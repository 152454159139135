import React from 'react';
import { lighten, makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont,
  roseColor
} from "assets/jss/material-dashboard-react.jsx";

const ColorCircularProgress = withStyles({
  root: {
    color: '#00695c',
  },
})(CircularProgress);

const ColorLinearProgress = withStyles({
   
  colorPrimary: {
    backgroundColor: primaryColor[4],//'#b2dfdb', #0075c1
  },
  barColorPrimary: {
    backgroundColor: "#0075c1"//primaryColor[0], //'#00695c',
  },
})(LinearProgress);

// const BorderLinearProgress = withStyles({
//   root: {
//     height: 10,
//     backgroundColor: lighten('#ff6c5c', 0.5),
//   },
//   bar: {
//     borderRadius: 20,
//     backgroundColor: '#ff6c5c',
//   },
// })(LinearProgress);

// Inspired by the Facebook spinners.
// const useStylesFacebook = makeStyles({
//   root: {
//     position: 'relative',
//   },
//   top: {
//     color: '#eef3fd',
//   },
//   bottom: {
//     color: '#6798e5',
//     animationDuration: '550ms',
//     position: 'absolute',
//     left: 0,
//   },
// });

// function FacebookProgress(props: CircularProgressProps) {
//   const classes = useStylesFacebook();

//   return (
//     <div className={classes.root}>
//       <CircularProgress
//         variant="determinate"
//         value={100}
//         className={classes.top}
//         size={24}
//         thickness={4}
//         {...props}
//       />
//       <CircularProgress
//         variant="indeterminate"
//         disableShrink
//         className={classes.bottom}
//         size={24}
//         thickness={4}
//         {...props}
//       />
//     </div>
//   );
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

export default function CustomizedProgressBars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <ColorCircularProgress size={30} thickness={5}  /> */}
      <ColorLinearProgress />
      {/* <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={50}
      /> */}
      {/* <FacebookProgress /> */}
    </div>
  );
}