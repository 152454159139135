import React, { Component } from "react";
import {
    Row,
    Col,
    InputGroup,
    FormGroup,
    ControlLabel,
    FormControl,
    Tab,
    Nav,
    Alert,
    Modal,
    NavItem,
    Tooltip,
    OverlayTrigger

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { TrixEditor } from "react-trix";
import Button from "components/CustomButton/CustomButton.jsx";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import Chip from '@material-ui/core/Chip';
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js"
import TagsInput from 'react-tagsinput';
import NetworkService from "services/NetworkService";
import Common from "utils/Common.js";
import ISC250Terminal from "views/Modals/ISC250.jsx"
import MaterialTable, { MTableToolbar } from 'material-table';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';


class Settings extends Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            isFormValid: true,
            ShowProgressBar: false,
            errorMessage: "",
            popupErrorMessage: "",
            errorCode: "0",
            senderNameErrorMessage: "",
            senderNameState: "",
            senderEmailErrorMessage: "",
            senderEmailState: "",
            subjectErrorMessage: "",
            subjectState: "",
            bodyErrorMessage: "",
            bodyState: "",
            Email: {
                senderName: "",
                senderEmail: "",
                bccEmails: [],
                bccEmail: "",
                ccEmails: [],
                subject: "",
                body: "",
                templates: [
                    {
                        value: "",
                        label: "Select Template",
                        isDisabled: true
                    },
                    { value: "Template1", label: "Template 1" },
                    { value: "Template2", label: "Template 2" }
                ],
                selectedTemplate: "",
                isDefaultTemplateChecked: false
            },
            invoiceTemplate: {
                template_id: 1,
                template_color: "blue",
            },
            Profile: {
                Company: {
                    Name: "",
                    Phone: "",
                    Address: "",
                    Country: "",
                    City: "",
                    State: "",
                    PostalCode: "",
                    WebSite: "",
                    Fax: "",
                },
                User: {
                    Email: "",
                    FirstName: "",
                    LastName: "",
                }
            },
            FirstNameState: "",
            LastNameState: "",
            EmailState: "",
            websiteState: "",
            companynameState: "",
            PhoneNumberState: "",
            FirstNameErrorMessage: "",
            LastNameErrorMessage: "",
            EmailErrorMessage: "",
            PhoneNumberErrorMessage: "",
            currentPassword: "",
            currentPasswordState: "",
            currentPasswordErrorMessage: "",
            newPassword: "",
            newPasswordState: "",
            newPasswordErrorMessage: "",
            confirmNewPassword: "",
            confirmNewPasswordState: "",
            confirmNewPasswordErrorMessage: "",
            isPasswordFormValid: false,
            addNewTemplateToolTip: <Tooltip id="addNewTemplateToolTip">Add new template</Tooltip>,
            terminalOptions: {
                IngenicoISC250: false
            },
            processorType: "",
            terminal_list: [],
            showRegistrationPopupModal: false,
            showUnregisterationPopupModal: false,
            registrationModel: {},
            deviceSN: "",
            deviceSNErrorMessage: "",
            deviceSNState: "",
            registrationCodeIsValid: false,
            columns: [
                {
                    title: "Device Name",
                    field: "deviceName",
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: "Device Code",
                    field: "hsn",
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: "Registration Status",
                    field: "registrationStatus",
                    headerStyle: {
                        textAlign: "left",
                    },
                }
            ],
            options: {
                search: false,
                //toolbar: false,
                paging: false,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            components: {
                Toolbar: props => (
                    <div>
                        {/* <MTableToolbar {...props} /> */}
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="createSubuserToolTip">Add New Terminal</Tooltip>}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={this.handlerIngenicoISC250.bind(true)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>

                        </div>
                    </div>
                ),
            },
            actions: [
                {                
                        
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="unregisterTerminalToolTip">Renewal/Unregister Terminal</Tooltip>}>                     
                        <i className="fa fa-pencil-square-o" style={{ color: "#0075c1" }}></i>       
                    </OverlayTrigger>,
                     onClick: (event, rowData) => rowData.registrationStatus === "registered" ? this.handleUnregisterTerminalPopup(rowData) : this.handleRegisterTerminalPopup(rowData)
                },
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="unregisterTerminalToolTip">Delete Terminal</Tooltip>}>
                //         <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => this.handlerDeleteTerminal(rowData)
                // }
            ],
            showConvFeePopupModal: false,
            showConvFeeDeletePopupModal: false,
            ConvFee: "",//{title: "Conv. Fee", type: "Percent", amount: "3.00"},
            ConvFeeModel: {
                Title: "",
                Description: "",
                Amount: "",
                IsPercentage: true,
                IsFixed: false,
                convFeeTitleErrorMessage: "",
                convFeeTitleState: "",
                convFeeAmountErrorMessage: "",
                convFeeAmountState: false
            }
        };

        this.handleBccEmailsChange = this.handleBccEmailsChange.bind(this);
        this.handleCCEmailsChange = this.handleCCEmailsChange.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        if (localStorage.getItem(StorageEnum.PPBUNDLE) != null && localStorage.getItem(StorageEnum.PPBUNDLE) != "null") {
            const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
            this.setState({ processorType: data_tokenize.PPType.toLowerCase() })
        }
        this.getEmailSettingRequest();
        this.getProfileRequest();
        this.GetConvFee();
    }

    //#region Profile
    // GET request to fetch merchant profile
    getProfileRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/merchant/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token
            },
        }).then(response => {
            return response.json()
        }).then(data => {
            if (data != null) {

                this.setState({ ShowProgressBar: false });
                if (data.statuscode == 0) {

                    const companyData = data.data.company;
                    const userData = data.data.user;

                    const company = { ...this.state.Profile.Company };
                    company.Name = companyData.name;
                    company.Phone = companyData.phone_number;
                    company.Address = companyData.address;
                    company.Country = companyData.country;
                    company.City = companyData.city;
                    company.State = companyData.state;
                    company.PostalCode = companyData.postal_code;
                    company.Fax = companyData.fax;
                    company.WebSite = companyData.website;

                    const user = { ...this.state.Profile.User }
                    user.Email = userData.email;
                    user.FirstName = userData.first_name;
                    user.LastName = userData.last_name;

                    this.setState({ Profile: { Company: company, User: user } });
                }
                else {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                }
            }
        }).catch(error => {
            // this.successAlert.bind(this);
            console.log(error);
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.QuickBooksDesktop;
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
            this.setState({ ConvFee: convfee[0] });   
    }

    handlerProfileSave = (profileInfo) => {
        this.postProfileRequest(profileInfo);
    }

    // POST request for profile update
    postProfileRequest = (profileInfo) => {
        if (this.isProfileValidated()) {
            this.setState({ ShowProgressBar: true });
            this.setState({ errorMessage: "" });
            const auth_token = localStorage.getItem(StorageEnum.UUID);
            const url = BASE_URL + "api/v1/merchant/";

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + auth_token
                },
                body: JSON.stringify({
                    "name": profileInfo.Company.Name,
                    "phone": profileInfo.Company.Phone,
                    "fax": profileInfo.Company.Fax,
                    "website": profileInfo.Company.WebSite,
                    "address": profileInfo.Company.Address,
                    "city": profileInfo.Company.City,
                    "state": profileInfo.Company.State,
                    "country": profileInfo.Company.Country,
                    "postalcode": profileInfo.Company.PostalCode,
                    "first_name": profileInfo.User.FirstName,
                    "last_name": profileInfo.User.LastName,
                })
            }).then(response => {
                return response.json()
            }).then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        console.log(data.data);
                        this.setState({ errorMessage: "Profile saved successfully." });
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                console.log(error)
            });
        }

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    profileValueChange(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }

    ValidateProfileEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                EmailErrorMessage: (
                    <small className="text-danger">
                        Invalid Email Address.
                    </small>
                )
            });
            this.setState({
                EmailState: "error"
            });
        }
        else {
            this.setState({ EmailState: "valid" });
            this.setState({ EmailErrorMessage: null });
        }

    }

    AddressChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Address: val
                }
            }
        }))
    }

    selectCountry(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Country: val
                }
            }
        }))
    }

    selectRegion(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    State: val
                }
            }
        }))
    }

    CityChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    City: val
                }
            }
        }))
    }

    PostalCodeChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    PostalCode: val
                }
            }
        }))
    }

    isProfileValidated() {
        let isValid = true;
        if (this.state.Profile.User.FirstName === "") {
            this.setState({
                FirstNameErrorMessage: (
                    <small className="text-danger">
                        First Name is Required.
                    </small>
                )
            });
            this.setState({ FirstNameState: "error" });
            isValid = false;
        }
        else if (this.state.FirstNameState === "error") {
            this.setState({
                FirstNameErrorMessage: (
                    <small className="text-danger">
                        Invalid First Name.
                    </small>
                )
            });
            isValid = false;
        }
        else {
            this.setState({ FirstNameErrorMessage: null });
        }

        if (this.state.Profile.User.LastName === "") {
            this.setState({
                LastNameErrorMessage: (
                    <small className="text-danger">
                        Last Name is Required.
                    </small>
                )
            });
            this.setState({ LastNameState: "error" });
            isValid = false;
        }
        else if (this.state.LastNameState === "error") {
            this.setState({
                LastNameErrorMessage: (
                    <small className="text-danger">
                        Invalid Last Name.
                    </small>
                )
            });
            isValid = false;
        }
        else {
            this.setState({ LastNameErrorMessage: null });
        }

        if (this.state.Profile.Company.Phone === "") {
            this.setState({
                PhoneNumberErrorMessage: (
                    <small className="text-danger">
                        Phone Number is Required.
                    </small>
                )
            });
            this.setState({ PhoneNumberState: "error" });
            isValid = false;
        }
        else if (this.state.PhoneNumberState == "error") {
            this.setState({
                PhoneNumberErrorMessage: (
                    <small className="text-danger">
                        Invalid Phone Number.
                    </small>
                )
            });
            this.setState({ PhoneNumberState: "error" });
            isValid = false;
        }
        else {
            this.setState({ PhoneNumberErrorMessage: null });
        }

        return isValid;
    }
    //#endregion

    //#region Reset Password
    saveChangePassword() {
        if (this.ValidatePasswords()) {
            const password_info = {
                current_password: this.state.currentPassword,
                new_password: this.state.newPassword,
                confirm_password: this.state.confirmNewPassword
            };

            this.setState({ ShowProgressBar: true });
            NetworkService.postChangePasswordRequest(password_info)
                .then(data => {
                    if (data != null) {
                        this.setState({ ShowProgressBar: false });
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                        if (data.statuscode === 0) {
                        }
                        else {
                        }
                    }
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                })
                .catch(error => {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                })
        }
    }

    ValidatePasswords() {
        if (this.state.currentPasswordState === "" || this.state.currentPasswordState === "error") {
            this.setState({
                currentPasswordErrorMessage: (
                    <small className="text-danger">
                        Current password is required.
                    </small>
                )
            });
            this.setState({ isPasswordFormValid: false });
        }
        else {
            this.setState({ currentPasswordErrorMessage: null });
            this.setState({ isPasswordFormValid: true });
        }
        return Common.ValidatePasswords(this);
    }
    //#endregion

    handleBodyChange(html, text) {
        // html is the new html content
        // text is the new text content

        //  if(text != null && text != undefined){
        //     html = html + " " + text;
        //     var div = document.createElement("div");
        //     html = html.replace("<div>","").replace("</div>","")
        //     html = "<div>"+ html + "[sdfsdf]" + "</div>";
        // }

        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                body: html
            }
        }));

        this.setState({ bodyState: html != "" ? "valid" : "" });
        this.setState({ bodyErrorMessage: html != "" ? null : <small className="text-danger"> Body is Required. </small> });

    }

    handleEditorReady(editor, bodyHTML) {
        // this is a reference back to the editor if you want to
        // do editing programatically
        var div = document.createElement("div");
        div.innerHTML = bodyHTML;
        var text = div.textContent || div.innerText || "";

        bodyHTML = bodyHTML.replace(/<style([\s\S]*?)<\/style>/gi, '');
        bodyHTML = bodyHTML.replace(/<script([\s\S]*?)<\/script>/gi, '');
        bodyHTML = bodyHTML.replace(/<\/div>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<\/li>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<li>/ig, '  *  ');
        bodyHTML = bodyHTML.replace(/<\/ul>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<\/p>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<br\s*[\/]?>/gi, "\n");
        bodyHTML = bodyHTML.replace(/<[^>]+>/ig, '');
        editor.insertString(bodyHTML);
    }

    handleUnregisterTerminalPopup(row) {
        this.setState({ popupErrorMessage: "" });
        this.setState({registrationModel: row});
        this.setState({ showUnregisterationPopupModal: true });        
    }

    handleRegisterTerminalPopup(row) {
        this.setState({ popupErrorMessage: "" });
        this.setState({registrationModel: row});
        this.setState({ showRegistrationPopupModal: true });
    }

    handlerDeleteTerminal(row) {
        let paylaod = { "device_id": row.poiDeviceId };
        this._postNMITerminalDelete(paylaod);
    }

    ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                senderEmail: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });
            this.setState({
                senderEmailState: "error"
            });
        }
        else {
            this.setState({ senderEmailState: "valid" });
            this.setState({ senderEmailErrorMessage: null });
        }

    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                [stateName]: value
            }
        }));
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handlerUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case "DeviceSN":
                this.state.deviceSN = value;
                this.onDeviceSNBlur();
                break;           
            default:
                break;
        }
    }

    onDeviceSNBlur() {
        if (this.state.deviceSN == "") {
            this.setState({
                deviceSNErrorMessage: (
                    <small className="text-danger">
                        Device Registration Code is required.
                    </small>
                )
            });
             this.state.deviceSNState = "error";
        } else if (this.state.deviceSN.length <= 4) {
            this.setState({
                deviceSNErrorMessage: (
                    <small className="text-danger">
                        Invalid Registration Code.
                    </small>
                )
            });
            this.state.deviceSNState = "error";
        } else {
            this.state.deviceSNState = "valid";
            this.setState({ deviceSNErrorMessage: null });
        }

        if(this.state.deviceSNState === "valid"){
            this.setState({registrationCodeIsValid: true})
        }else{
            this.setState({registrationCodeIsValid: false})
        }
    }

    handlerDeviceRegistrationSubmit() { 
        if(this.state.registrationCodeIsValid){
            let payload = {
                device_name: this.state.registrationModel.deviceName,
                device_code: this.state.deviceSN
            }
            this._postNMITerminalRegister(payload);
        }
        else{
            this.onDeviceSNBlur();
        }
    }

    _postNMITerminalRegister(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ popupErrorMessage: "" });
        const app_id = ApplicationIDEnum.QuickBooksDesktop;
        NetworkService.postNMITerminalRegister(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({showRegistrationPopupModal: false});
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: "Device registered successfully." });
                        this.getEmailSettingRequest();
                    } else {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ popupErrorMessage: data.statusmessage });
                    }                    
                }
                else {
                    this.setState({ errorCode: 1 });
                    this.setState({ errorMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    isValidated() {
        if (this.state.senderNameState === "" || this.state.senderNameState === "error") {
            this.setState({
                senderNameErrorMessage: (
                    <small className="text-danger">
                        Sender Name is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderNameErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.senderEmailState === "" || this.state.senderEmailState === "error") {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Email is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderEmailErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.subjectState === "" || this.state.subjectState === "error") {
            this.setState({
                subjectErrorMessage: (
                    <small className="text-danger">
                        Subject is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ subjectErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.bodyState === "" || this.state.bodyState === "error") {
            this.setState({
                bodyErrorMessage: (
                    <small className="text-danger">
                        Body is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ bodyErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        return this.state.isFormValid;

    }

    handleDefaultTemplateCheck = event => {
        const target = event.target;
        // this.setState({
        //     isDefaultTemplateChecked: target.checked
        // });
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                isDefaultTemplateChecked: target.checked
            }
        }));
    };

    handlerEmailSettingsSave = (emailSettings) => {
        if (this.isValidated()) {
            const invoiceContact = {
                sender_name: emailSettings.senderName,
                sender_email: emailSettings.senderEmail,
                bcc_emails: emailSettings.bccEmails,
                cc_emails: emailSettings.ccEmails,
                subject: emailSettings.subject,
                body: emailSettings.body,
                templates: emailSettings.templates
            }
            const setting = { "InvoiceContacts": invoiceContact }

            this.postEmailSettingsRequest(setting);
        }
        else {
            console.log("form not valid");
        }
    }

    handleBccEmailsChange(tags) {
        const bcc = { ...this.state.Email };
        bcc.bccEmails = tags || []
        this.setState({ Email: bcc })
    }

    handleCCEmailsChange(tags) {
        const cc = { ...this.state.Email };
        cc.ccEmails = tags || []
        this.setState({ Email: cc })
    }


    handlerIngenicoISC250 = (value) => {
        this.setState(prevState => ({
            terminalOptions: {
                ...prevState.terminalOptions,
                ISC250Terminal: value
            }
        }));
    }

    ISC250CallbackFunction = (childData) => {
        this.setState(prevState => ({
            terminalOptions: {
                ...prevState.terminalOptions,
                ISC250Terminal: childData
            }
        }));
    }

    onSetEmailResult = (result) => {
        let invoiceContacts = result.InvoiceContacts;
        let templateID = result.TemplateID;
        let templateColor = result.TemplateColor;
        let depositToAccount = result.DepositAccountID;

        let email_setting = { ...this.state.Email }
        email_setting.senderName = invoiceContacts != null ? invoiceContacts.sender_name : "";
        if (invoiceContacts != null && invoiceContacts.sender_name != "") {
            this.setState({ senderNameState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.senderEmail = invoiceContacts != null ? invoiceContacts.sender_email : "";
        if (invoiceContacts != null && invoiceContacts.sender_email != "") {
            this.setState({ senderEmailState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.subject = invoiceContacts != null ? invoiceContacts.subject : "";
        if (invoiceContacts != null && invoiceContacts.subject != "") {
            this.setState({ subjectState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.body = invoiceContacts != null ? invoiceContacts.body : "";
        if (invoiceContacts != null && invoiceContacts.body != "") {
            this.setState({ bodyState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.bccEmails = invoiceContacts != null ? invoiceContacts.bcc_emails : [];
        email_setting.ccEmails = invoiceContacts != null ? invoiceContacts.cc_emails : [];

        let payment_processor = result.PaymentProcessor;
        if (payment_processor) {
            let terminals = [];
            payment_processor.map((el, i) => {
                if (el.configuration != null && el.is_default == true) {
                    el.configuration.Terminals.map((data, j) => {
                        terminals.push({ "deviceName": data.deviceName, "hsn": data.hsn, "poiDeviceId": data.poiDeviceId, 
                       "registrationStatus": data.registrationStatus == undefined || data.registrationStatus == "" || data.registrationStatus == null ?
                        "unregistered" :  data.registrationStatus
                         });
                    });
                }
            });
            this.setState({ terminal_list: terminals });
        }


        const invoiceTemplate = { ...this.state.invoiceTemplate }
        invoiceTemplate.templateID = templateID
        invoiceTemplate.templateColor = templateColor
        this.setState({ Email: email_setting });
        this.setState({ invoiceTemplate: invoiceTemplate });
    }

    /** API Calling */

    getEmailSettingRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const url = BASE_URL + "api/v1/settings/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.QuickBooksDesktop;

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.onSetEmailResult(data.data);
                this.setState({ ShowProgressBar: false });
                if (data.statuscode == 0) {

                }
                else {
                    this.setState({ errorMessage: data.statusmessage });
                }

            } else {

            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    // POST request for invoice email setting
    postEmailSettingsRequest = (settings) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/settings/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.QuickBooksDesktop;
        fetch(url, {
            method: "POST",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "Setting": settings
            })
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: data.statuscode });
            } else {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: "1" });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorCode: "1" });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _postNMITerminalDelete(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ popupErrorMessage: "" });

        const app_id = ApplicationIDEnum.QuickBooksDesktop;
        NetworkService.postNMITerminalUnRegister(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ popupErrorMessage: "Device unregistered successfully." });
                        
                        this.getEmailSettingRequest();
                        this.setState({showUnregisterationPopupModal: false});
                    } else {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ popupErrorMessage: data.statusmessage });
                    }
                }
                else {
                    this.setState({ errorCode: 1 });
                    this.setState({ popupErrorMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    handlerConvFeeCreatePopup() {
        this.state.ConvFeeModel.Title = "";
        this.state.ConvFeeModel.Description = "";
        this.state.ConvFeeModel.Amount = "";
        this.setState({ showConvFeePopupModal: true });
    }

    handlerConvFeeDeletePopup() {
        this.setState({ showConvFeeDeletePopupModal: true });
    }

    handleConvFeeRadio = event => {
        const target = event.target;
        if (target.value === "percentage") {
            this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                        IsPercentage: true,
                        IsFixed: false,
                    }
                }));
        }
        else{
            this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                        IsPercentage: false,
                        IsFixed: true
                    }
                }));
        }
                
        this.setState({ StatusMessage: "" });              
    };

    handleConvFeeAmountChange = e => {
        const { name, value } = e.target;
        if(value == "")
        {
            this.setState(prevState => ({
                ...prevState,
                ConvFeeModel: {
                    ...prevState.ConvFeeModel,
                    convFeeAmountState: "invalid",
                    convFeeAmountErrorMessage: (
                        <small className="text-danger">
                            Amount is required.
                        </small>
                    )
                }
            })); 
            return false;
        }
        else if (parseFloat(value) > parseFloat(99.00) || parseFloat(value) < parseFloat(0.01)) {
          
            this.setState(prevState => ({
                ...prevState,
                ConvFeeModel: {
                    ...prevState.ConvFeeModel,
                    convFeeAmountState: "invalid",
                    convFeeAmountErrorMessage: (
                        <small className="text-danger">
                            Value must be greater than 0.01 and less than 99.99
                        </small>
                    )
                }
            }));          
    
            return false;
        }

        this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                         Amount: value,
                        convFeeAmountState: "valid",
                        convFeeAmountErrorMessage: ""
                    }
                    }));
    };

    handleConvFeeCreate(convFee) {
        if(convFee.convFeeAmountState == "valid" &&  convFee.convFeeTitleState == "valid")
        {
            const data = {
                "name": convFee.Title,
                "description": convFee.Description,
                "value": convFee.Amount,
                "is_percentage": convFee.IsPercentage,
                "is_active": true
            }
            console.log(data);
            this._postConvFeeRequest(data);
        }
        else{
            if(convFee.convFeeAmountState != "valid") 
            {
                    this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                        convFeeAmountState: false,
                        convFeeAmountErrorMessage: (
                            <small className="text-danger">
                                Invalid amount value.
                            </small>
                        )
                    }
                }));  
             }
             if(convFee.convFeeTitleState != "valid") 
            {
                this.setState(prevState => ({
                ...prevState,
                ConvFeeModel: {
                    ...prevState.ConvFeeModel,
                    convFeeTitleState: false,
                    convFeeTitleErrorMessage: (
                        <small className="text-danger">
                            Invalid title field.
                        </small>
                    )
                }
                })); 
            } 
        }
    }

    handleDeleteConvFeeSubmit(convFee){
         const data = {
            "id": convFee.id
        }
        console.log(data);
        this._postDeleteConvFeeRequest(data);
    }

     _postConvFeeRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.QuickBooksDesktop;
        NetworkService.postConvFeeCreateRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showConvFeePopupModal: false });
                if (data != null) {
                    if (data.statuscode == 0) {                       
                        this.GetConvFee();                       
                    }
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showConvFeePopupModal: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    _postDeleteConvFeeRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.QuickBooksDesktop;
        NetworkService.postConvFeeDeleteRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showConvFeeDeletePopupModal: false });
                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ConvFee: ""})
                        if (data.data != null) {
                           // this.filterTransactions();
                        }
                    }
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showConvFeePopupModal: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    render() {
        const customChipStyle = {
            fontSize: "1.5rem", marginRight: "5px", marginTop: "10px"
        }
        const EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i

        return (
            <div className="ii-main-content">
                <Card
                    ctFullWidth
                    content={
                        <Tab.Container id="tabs-with-dropdown" defaultActiveKey="email">
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav bsStyle="tabs">
                                        <NavItem eventKey="email">
                                            <i className="fa fa-envelope-o" /> Email
                                        </NavItem>
                                        <NavItem eventKey="profliekey">
                                            <i className="fa fa-user" /> Profile
                                        </NavItem>                                        
                                        <NavItem eventKey="changepassword">
                                            <i className="fa fa-lock" /> Change Password
                                        </NavItem>
                                        <NavItem eventKey="convfee">
                                            <i className="fa fa-dollar" /> Non-Cash Adjustment
                                        </NavItem>
                                        {/* <NavItem eventKey="template">
                                            <i className="fa fa-file-text-o" /> Template
                                        </NavItem> */}
                                        {this.state.processorType === "nmi" &&
                                            <NavItem eventKey="terminal">
                                                <i className="fa fa-usb" /> Hardware/Terminals
                                            </NavItem>
                                        }
                                    </Nav>
                                </Col>
                                <Col sm={10}>
                                    <Tab.Content animation>
                                        <Tab.Pane eventKey="email">
                                            <Card
                                                content={
                                                    <div>
                                                        {this.state.ShowProgressBar &&
                                                            <div>
                                                                <CustomProgressBar></CustomProgressBar>
                                                                <br />
                                                            </div>
                                                        }
                                                        {this.state.errorMessage != "" &&
                                                            <div>
                                                                <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                                    <span>
                                                                        {this.state.errorMessage}
                                                                    </span>
                                                                </Alert>
                                                            </div>
                                                        }
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Sender Name: <span className="star">*</span>
                                                                    </div>
                                                                    <FormControl
                                                                        value={this.state.Email.senderName}
                                                                        type="text"
                                                                        placeholder="Sender Name"
                                                                        name="senderName"
                                                                        className={this.state.senderNameState}
                                                                        onChange={event =>
                                                                            this.change(event, "senderName", "length", 2)
                                                                        }
                                                                        onBlur={e => this.isValidated()}
                                                                    />
                                                                    {this.state.senderNameErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Sender Email: <span className="star">*</span>
                                                                    </div>
                                                                    <FormControl
                                                                        readOnly
                                                                        value={this.state.Email.senderEmail}
                                                                        placeholder="Sender Email"
                                                                        name="senderEmail"
                                                                        type="email"
                                                                        className={this.state.senderEmailState}
                                                                        onChange={event =>
                                                                            this.ValidateEmailAddress(event)
                                                                        }
                                                                        onBlur={e => this.isValidated()}
                                                                    />
                                                                    {this.state.senderEmailErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>

                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        BCC Email:
                                                                    </div>
                                                                    <TagsInput
                                                                        maxTags={3}
                                                                        value={this.state.Email.bccEmails}
                                                                        onChange={this.handleBccEmailsChange}
                                                                        // inputValue={this.state.Email.bccEmail}
                                                                        // onChangeInput={this.handleBccEmailsChangeInput}
                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                        onlyUnique
                                                                        addOnPaste
                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                        pasteSplit={data => {
                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                        }}
                                                                        tagProps={{ className: 'react-tagsinput-tag tag-fill tag-blue' }}
                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                    />
                                                                    {/* <FormControl
                                                                        value={this.state.Email.bccEmails}
                                                                        placeholder="Add upto 3 BCC Emails seperated by ;"
                                                                        name="bccEmails"
                                                                        type="email"
                                                                        onChange={event => {
                                                                            var value = event.target.value;
                                                                            this.setState(prevState => ({
                                                                                Email: {
                                                                                    ...prevState.Email,
                                                                                    bccEmails: value
                                                                                }
                                                                            }));
                                                                        }}
                                                                    /> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        CC Email:
                                                                    </div>
                                                                    <TagsInput
                                                                        maxTags={3}
                                                                        value={this.state.Email.ccEmails}
                                                                        onChange={this.handleCCEmailsChange}
                                                                        // inputValue={this.state.Email.bccEmail}
                                                                        // onChangeInput={this.handleBccEmailsChangeInput}
                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                        onlyUnique
                                                                        addOnPaste
                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                        pasteSplit={data => {
                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                        }}
                                                                        tagProps={{
                                                                            className: 'react-tagsinput-tag tag-fill tag-blue',
                                                                            classNameRemove: 'react-tagsinput-remove'
                                                                        }}
                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                    />
                                                                    {/* <FormControl
                                                                        value={this.state.Email.ccEmails}
                                                                        placeholder="Add upto 3 CC Emails seperated by ;"
                                                                        name="ccEmails"
                                                                        type="email"
                                                                        onChange={event => {
                                                                            var value = event.target.value;
                                                                            this.setState(prevState => ({
                                                                                Email: {
                                                                                    ...prevState.Email,
                                                                                    ccEmails: value
                                                                                }
                                                                            }));
                                                                        }}
                                                                    /> */}
                                                                    {/* {this.state.emailErrorLogin} */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Subject: <span className="star">*</span>
                                                                    </div>
                                                                    <FormControl
                                                                        value={this.state.Email.subject}
                                                                        type="text"
                                                                        placeholder="Subject"
                                                                        name="subject"
                                                                        className={this.state.subjectState}
                                                                        onChange={event =>
                                                                            this.change(event, "subject", "length", 2)
                                                                        }
                                                                        onBlur={e => this.isValidated()}
                                                                    // onChange={event => {
                                                                    //     var value = event.target.value;
                                                                    //     this.setState(prevState => ({
                                                                    //         Email: {
                                                                    //             ...prevState.Email,
                                                                    //             subject: value
                                                                    //         }
                                                                    //     }));
                                                                    // }}
                                                                    />
                                                                    {this.state.subjectErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Body:  <span className="star">*</span>
                                                                    </div>
                                                                    <TrixEditor
                                                                        className="custom-css-class"
                                                                        autoFocus={true}
                                                                        placeholder="Email's Body"
                                                                        value={this.state.Email.body}
                                                                        onChange={e => this.handleBodyChange(e)}
                                                                        onEditorReady={e => {
                                                                            setTimeout(() => {
                                                                                this.handleEditorReady(e, this.state.Email.body);
                                                                            }, 1000);
                                                                        }
                                                                        }
                                                                    />
                                                                    {this.state.bodyErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Supported Tags:
                                                                    </div>
                                                                    <Chip style={customChipStyle} label="[customer_name]" onClick={e => this.handleBodyChange(this.state.Email.body, "[customer_name]")} />
                                                                    <Chip style={customChipStyle} label="[invoice_number]" />
                                                                    <Chip style={customChipStyle} label="[amount]" />
                                                                    <Chip style={customChipStyle} label="[full_name]" />
                                                                    <Chip style={customChipStyle} label="[company_name]" />
                                                                    <Chip style={customChipStyle} label="[company_phone]" />
                                                                    <Chip style={customChipStyle} label="[company_weburl]" />
                                                                    <Chip style={customChipStyle} label="[date]" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Template:
                                                                    </div>
                                                                    <Select
                                                                        className="react-select primary"
                                                                        classNamePrefix="react-select"
                                                                        name="templates"
                                                                        value={this.state.Email.selectedTemplate}
                                                                        onChange={value =>
                                                                            this.setState(prevState => ({
                                                                                Email: {
                                                                                    ...prevState.Email,
                                                                                    selectedTemplate: value
                                                                                }
                                                                            }))
                                                                        }
                                                                        options={this.state.Email.templates}
                                                                        placeholder="Select Template"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={1}>
                                                                <FormGroup>
                                                                    <OverlayTrigger placement="top" overlay={this.state.addNewTemplateToolTip}>
                                                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '10px', marginTop: '25px' }}>
                                                                            <i className="fa fa-plus" ></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                <FormGroup style={{ marginTop: '33px' }}>
                                                                    <Checkbox
                                                                        number="DefaultTemplatCheckBox"
                                                                        label="Set template as default"
                                                                        onChange={this.handleDefaultTemplateCheck}
                                                                        checked={this.state.Email.isDefaultTemplateChecked}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> */}

                                                        <div style={{ textAlign: "left" }}>
                                                            <br />
                                                            <Button bsStyle="primary" fill wd onClick={e => this.handlerEmailSettingsSave(this.state.Email)}>Save Changes</Button>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="profliekey">
                                            <Row>
                                                <Col md={12}>
                                                    {this.state.errorMessage != "" &&
                                                        <Row>
                                                            <Col md={12}>
                                                                <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                    <span>
                                                                        {this.state.errorMessage}
                                                                    </span>
                                                                </Alert>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {this.state.ShowProgressBar &&
                                                        <Row >
                                                            <Col md={12}>
                                                                <CustomProgressBar></CustomProgressBar>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <br />
                                                    <Card
                                                        content={
                                                            <div>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                        First Name: <span className="star">*</span>
                                                                                    </div>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="First Name"
                                                                                        name="FirstName"
                                                                                        className={this.state.FirstNameState}
                                                                                        value={this.state.Profile.User.FirstName}
                                                                                        onChange={event => {
                                                                                            this.state.Profile.User.FirstName = event.target.value;
                                                                                            this.profileValueChange(event, "FirstName", "length", 2)
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {this.state.FirstNameErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Last Name: <span className="star">*</span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="Last Name"
                                                                                        autoComplete="off"
                                                                                        name="LastName"
                                                                                        className={this.state.LastNameState}
                                                                                        value={this.state.Profile.User.LastName}
                                                                                        onChange={event => {
                                                                                            this.state.Profile.User.LastName = event.target.value;
                                                                                            this.profileValueChange(event, "LastName", "length", 2)
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {this.state.LastNameErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Company: <span className="star"></span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        disabled={false}
                                                                                        placeholder="Company"
                                                                                        name="companyname"
                                                                                        className={this.state.companynameState}
                                                                                        value={this.state.Profile.Company.Name}
                                                                                        onChange={event => {
                                                                                            const val = event.target.value;
                                                                                            this.setState(prevState => ({
                                                                                                ...prevState,
                                                                                                Profile: {
                                                                                                    ...prevState.Profile,
                                                                                                    Company: {
                                                                                                        ...prevState.Profile.Company,
                                                                                                        Name: val
                                                                                                    }
                                                                                                }
                                                                                            }))
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {this.state.companynameErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Email adress: <span className="star">*</span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="email"
                                                                                        disabled={true}
                                                                                        placeholder="Enter Email"
                                                                                        name="Email"
                                                                                        className={this.state.EmailState}
                                                                                        value={this.state.Profile.User.Email}
                                                                                    // onChange={event => {
                                                                                    //     this.ValidateProfileEmailAddress(event);
                                                                                    //     this.state.Profile.User.Email = event.target.value;
                                                                                    //     this.profileValueChange(event, "Email", "email", 2)
                                                                                    // }
                                                                                    //}
                                                                                    />
                                                                                    {this.state.EmailErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Phone Number: <span className="star"></span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="number"
                                                                                        placeholder="Phone Number"
                                                                                        className={this.state.PhoneNumberState}
                                                                                        name="Phone"
                                                                                        value={this.state.Profile.Company.Phone}
                                                                                        onChange={event => {
                                                                                            this.state.Profile.Company.Phone = event.target.value;
                                                                                            this.profileValueChange(event, "PhoneNumber", "length", 7);
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {this.state.PhoneNumberErrorMessage}

                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Website:
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="Website"
                                                                                        name="Website"
                                                                                        value={this.state.Profile.Company.WebSite}
                                                                                        onChange={event => {
                                                                                            const val = event.target.value;
                                                                                            this.setState(prevState => ({
                                                                                                ...prevState,
                                                                                                Profile: {
                                                                                                    ...prevState.Profile,
                                                                                                    Company: {
                                                                                                        ...prevState.Profile.Company,
                                                                                                        WebSite: val
                                                                                                    }
                                                                                                }
                                                                                            }))
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Address:
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="Address"
                                                                                        name="Address"
                                                                                        value={this.state.Profile.Company.Address}
                                                                                        onChange={event => this.AddressChange(event.target.value)}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Country:
                                                                                    </ControlLabel>
                                                                                    <CountryDropdown
                                                                                        value={this.state.Profile.Company.Country}
                                                                                        id="country"
                                                                                        onChange={(val) => this.selectCountry(val)}
                                                                                        classes="form-control"
                                                                                        valueType="short"
                                                                                        whitelist={['GB', 'US', 'CA']}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        State/Region:
                                                                                    </ControlLabel>
                                                                                    <RegionDropdown
                                                                                        country={this.state.Profile.Company.Country}
                                                                                        value={this.state.Profile.Company.State}
                                                                                        defaultOptionLabel="Select State"
                                                                                        countryValueType="short"
                                                                                        id="region"
                                                                                        //labelType="short"
                                                                                        onChange={(val) => this.selectRegion(val)}
                                                                                        classes="form-control"
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        City:
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="City"
                                                                                        name="City"
                                                                                        value={this.state.Profile.Company.City}
                                                                                        onChange={event => this.CityChange(event.target.value)}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Postal Code: <span className="star"></span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="number"
                                                                                        placeholder="Postal Code"
                                                                                        name="postalcode"
                                                                                        value={this.state.Profile.Company.PostalCode}
                                                                                        onChange={event => this.PostalCodeChange(event.target.value)}
                                                                                        type="number"
                                                                                    />

                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <div style={{ textAlign: "left" }}>
                                                                            <br />
                                                                            <Button bsStyle="primary" fill wd onClick={e => this.handlerProfileSave(this.state.Profile)}>Save Changes</Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="changepassword">
                                            <Card
                                                plain
                                                content={
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            {this.state.errorMessage != "" &&
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                            <span>
                                                                                {this.state.errorMessage}
                                                                            </span>
                                                                        </Alert>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {this.state.ShowProgressBar &&
                                                                <Row >
                                                                    <Col md={6}>
                                                                        <CustomProgressBar></CustomProgressBar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <br />
                                                            <Row>
                                                                <Col md={6} >
                                                                    <Card
                                                                        pink
                                                                        content={
                                                                            <div >
                                                                                <form>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>Current Password
                                                                                            <span className="star"> *</span>
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            value={this.state.currentPassword}
                                                                                            placeholder="Current Password"
                                                                                            autoComplete="off"
                                                                                            name="currentPassword"
                                                                                            type="password"
                                                                                            className={this.state.currentPasswordState}
                                                                                            onChange={event => {
                                                                                                this.profileValueChange(event, "currentPassword", "length", 4)
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.currentPasswordErrorMessage}
                                                                                    </FormGroup>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>New Password  <span className="star">*</span></ControlLabel>
                                                                                        <FormControl
                                                                                            value={this.state.newPassword}
                                                                                            placeholder="New Password"
                                                                                            autoComplete="off"
                                                                                            name="newPassword"
                                                                                            type="password"
                                                                                            className={this.state.newPasswordState}
                                                                                            onChange={event => {
                                                                                                this.profileValueChange(event, "newPassword", "length", 4)
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.newPasswordErrorMessage}
                                                                                    </FormGroup>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>Confirm Password  <span className="star">*</span></ControlLabel>
                                                                                        <FormControl
                                                                                            value={this.state.confirmNewPassword}
                                                                                            placeholder="Confirm Password"
                                                                                            autoComplete="off"
                                                                                            name="confirmNewPassword"
                                                                                            type="password"
                                                                                            className={this.state.confirmNewPasswordState}
                                                                                            onChange={event => {
                                                                                                this.profileValueChange(event, "confirmNewPassword", "length", 4);
                                                                                            }
                                                                                            }
                                                                                            onBlur={e => this.ValidatePasswords()}
                                                                                        />
                                                                                        {this.state.confirmNewPasswordErrorMessage}
                                                                                    </FormGroup>

                                                                                    <br />
                                                                                    <div style={{ textAlign: "left" }}>
                                                                                        <Button bsStyle="primary" disabled={!this.state.isPasswordFormValid} fill wd onClick={e => this.saveChangePassword()}>Save Changes</Button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                }
                                            />
                                        </Tab.Pane>
                                        {/* Non-Cash Adjustment Tab */}
                                        <Tab.Pane eventKey="convfee">
                                                {this.state.ShowProgressBar &&
                                                    <div>
                                                        <CustomProgressBar></CustomProgressBar>
                                                        <br />
                                                    </div>
                                                }
                                                {this.state.errorMessage != "" &&
                                                    <div>
                                                        <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                            <span>
                                                                {this.state.errorMessage}
                                                            </span>
                                                        </Alert>
                                                    </div>
                                                }                                                
                                                <Card
                                                    title="Non-Cash Adjustment"
                                                    content={
                                                        <div>
                                                            <div style={{ textAlign: "left" }}>
                                                                <br />                                                                
                                                                {this.state.ConvFee == "" && 
                                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerConvFeeCreatePopup()}>Create New</Button>
                                                                }
                                                                {this.state.ConvFee != "" &&
                                                                    <div style={{width: "700px"}}>
                                                                        <Card
                                                                            content={
                                                                                <div>
                                                                                    <br/>
                                                                                    <div style={{ color: "white", backgroundColor: "#0075c1" }}>
                                                                                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                                            <Col xs={6} sm={6} md={6} style={{ marginTop: 5 }}>
                                                                                                <h5>Title/Name</h5>
                                                                                            </Col>
                                                                                            <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                                <h5> Amount  </h5>
                                                                                            </Col>
                                                                                            <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                                <h5>Type </h5>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                                        <Col xs={6} sm={6} md={6} style={{ marginTop: 5 }}>
                                                                                            <h5>{this.state.ConvFee.name}</h5>
                                                                                        </Col>
                                                                                        <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                            <h5>{parseFloat(this.state.ConvFee.value).toFixed(2)}</h5>
                                                                                        </Col>
                                                                                        <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                            <h5>{this.state.ConvFee.is_percentage == true ? "Percent" : "Fixed Amount"} </h5>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <br/>
                                                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerConvFeeDeletePopup()}>Delete </Button>
                                                                                    
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                                <Modal show={this.state.showConvFeePopupModal} onHide={() => this.setState({ showConvFeePopupModal: false })}>
                                                    {this.state.ShowPopupProgressBar &&
                                                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                            <CustomProgressBar></CustomProgressBar>
                                                        </div>
                                                    }

                                                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                        <Modal.Title style={{ color: "white" }}>Create Non-Cash Adjustment</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="modal-body text-center">
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Title : </h5>
                                                                </Col> 
                                                                <Col xs={12} sm={12} md={6} className="text-left">
                                                                    <FormGroup>                                                                       
                                                                        <FormControl
                                                                            value={this.state.ConvFeeModel.Title}
                                                                            type="text"
                                                                            placeholder="Title"
                                                                            name="convfeetitle"
                                                                            onChange={event => {
                                                                                var value = event.target.value;
                                                                                this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        Title: value
                                                                                    }
                                                                                }));
                                                                                if(this.verifyLength(value, 2))
                                                                                {
                                                                                    this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        convFeeTitleState: "valid",
                                                                                        convFeeTitleErrorMessage: ""
                                                                                    }
                                                                                    }));
                                                                                }
                                                                                else{
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        ConvFeeModel: {
                                                                                            ...prevState.ConvFeeModel,
                                                                                            convFeeTitleState: "invalid",
                                                                                            convFeeTitleErrorMessage: (
                                                                                                <small className="text-danger">
                                                                                                    Title must be at least 2 characters.
                                                                                                </small>
                                                                                            )
                                                                                        }
                                                                                    }));
                                                                                }                                                    
                                                                            }
                                                                            }
                                                                            onBlur={event => {
                                                                                var value = event.target.value;
                                                                                if(this.verifyLength(value, 2))
                                                                                {
                                                                                    this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        convFeeTitleState: "valid",
                                                                                        convFeeTitleErrorMessage: ""
                                                                                    }
                                                                                }));
                                                                                }
                                                                                else{
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        ConvFeeModel: {
                                                                                            ...prevState.ConvFeeModel,
                                                                                            convFeeTitleState: "invalid",
                                                                                            convFeeTitleErrorMessage: (
                                                                                                <small className="text-danger">
                                                                                                    Title must be at least 2 characters.
                                                                                                </small>
                                                                                            )
                                                                                        }
                                                                                    }));
                                                                                }  
                                                                            }}
                                                                        />
                                                                        {this.state.ConvFeeModel.convFeeTitleErrorMessage}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Description : </h5>
                                                                </Col> 
                                                                <Col xs={12} sm={12} md={6} className="text-left">
                                                                    <FormGroup>                                                                       
                                                                        <FormControl
                                                                            value={this.state.ConvFeeModel.Description}
                                                                            type="text"
                                                                            placeholder="Description"
                                                                            name="convfeedescription"
                                                                            onChange={event => {
                                                                                var value = event.target.value;
                                                                                this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        Description: value
                                                                                    }
                                                                                }));                                                   
                                                                            }
                                                                            }
                                                                            
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row> 
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Type : </h5>
                                                                </Col>                                                                                                      
                                                                <Col md={3} style={{marginTop: "-5px", marginLeft: "-25px"}}>
                                                                    <Radio
                                                                        number="percentage"
                                                                        option="percentage"
                                                                        name="radio"
                                                                        onChange={this.handleConvFeeRadio}
                                                                        checked={this.state.ConvFeeModel.IsPercentage}
                                                                        className="PrivateSwitchBase-input-538"
                                                                        label="Percent"
                                                                    />
                                                                </Col>
                                                                <Col md={4} style={{marginTop: "-5px"}}>
                                                                    <Radio
                                                                        number="fixedamount"
                                                                        option="fixedamount"
                                                                        name="radio"
                                                                        onChange={this.handleConvFeeRadio}
                                                                        checked={this.state.ConvFeeModel.IsFixed}
                                                                        label="Fixed Amount"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Amount/Rate :</h5>
                                                                </Col>
                                                                <Col style={{ textAlign: "right" }} xs={12} sm={12} md={3}>
                                                                    <InputGroup>
                                                                        {this.state.ConvFeeModel.IsFixed && 
                                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                                        }    
                                                                        <FormControl
                                                                            value={parseFloat(this.state.ConvFeeModel.Amount).toFixed(2)}
                                                                            type="number"
                                                                            name="amount"
                                                                            min="0.01"
                                                                            style={{ textAlign: "right" }}
                                                                            step="0.01"
                                                                            onChange={this.handleConvFeeAmountChange}
                                                                            onBlur={this.handleConvFeeAmountChange}                                                                            
                                                                        />
                                                                        {this.state.ConvFeeModel.IsPercentage &&
                                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-percent" /></InputGroup.Addon>
                                                                        }
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4}>                                                                   
                                                                </Col>
                                                                <Col style={{ textAlign: "left", marginTop: "-5px" }} xs={12} sm={12} md={8}>                                                                    
                                                                        {this.state.ConvFeeModel.convFeeAmountErrorMessage}                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showConvFeePopupModal: false })}>Close</Button>
                                                        <Button bsStyle="primary" fill wd onClick={e => this.handleConvFeeCreate(this.state.ConvFeeModel)}>Submit</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                <Modal show={this.state.showConvFeeDeletePopupModal} onHide={() => this.setState({ showConvFeeDeletePopupModal: false })}>
                                                    {this.state.ShowPopupProgressBar &&
                                                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                            <CustomProgressBar></CustomProgressBar>
                                                        </div>
                                                    }
                                                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                        <Modal.Title style={{ color: "white" }}>Delete Non-Cash Adjustment</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="modal-body text-center">
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Title : </h5>
                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                    <h5>{this.state.ConvFee.name}</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Amount :</h5>
                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                    <h5>{parseFloat(this.state.ConvFee.value).toFixed(2)}</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Type :</h5>
                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                     <h5>{this.state.ConvFee.is_percentage == true ? "Percent" : "Fixed Amount"} </h5>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showConvFeeDeletePopupModal: false })}>Close</Button>
                                                        <Button bsStyle="primary" fill wd onClick={e => this.handleDeleteConvFeeSubmit(this.state.ConvFee)}>Submit</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </Tab.Pane>
                                        <Tab.Pane eventKey="template">
                                            <Card
                                                content={
                                                    <div>
                                                    </div>
                                                }
                                            />
                                        </Tab.Pane>
                                        {/* Hardware/Terminal Setting Tab */}
                                        <Tab.Pane eventKey="terminal">
                                            <Card
                                                content={
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            {this.state.errorMessage != "" &&
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                            <span>
                                                                                {this.state.errorMessage}
                                                                            </span>
                                                                        </Alert>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {this.state.ShowProgressBar &&
                                                                <Row >
                                                                    <Col md={12}>
                                                                        <CustomProgressBar></CustomProgressBar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <br />

                                                            <Row>
                                                                <Col md={12}>
                                                                    <MuiThemeProvider theme={this.theme}>
                                                                        <MaterialTable
                                                                            columns={this.state.columns}
                                                                            options={this.state.options}
                                                                            actions={this.state.actions}
                                                                            data={this.state.terminal_list}
                                                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                                                            components={this.state.components}
                                                                        />
                                                                    </MuiThemeProvider>
                                                                </Col>
                                                                {this.state.terminalOptions.ISC250Terminal && <ISC250Terminal isModalDialogShow={true} terminals={this.state.terminal_list} parentCallback={this.ISC250CallbackFunction}></ISC250Terminal>}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                }
                                            />
                                        </Tab.Pane>
                                        <Modal
                                            show={this.state.showRegistrationPopupModal}
                                            onHide={() => this.setState({ showRegistrationPopupModal: false })}
                                        >
                                            {this.state.ShowPopupProgressBar &&
                                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                    <CustomProgressBar></CustomProgressBar>
                                                </div>
                                            }

                                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                <Modal.Title style={{ color: "white" }}>Terminal Registration</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    {this.state.popupErrorMessage &&
                                                        <div>
                                                            <Alert style={{marginTop:"-15px"}} bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                                <span>
                                                                    {this.state.popupErrorMessage}
                                                                </span>
                                                            </Alert>
                                                        </div>
                                                    }    
                                                    <Row>
                                                        <Col xs={12} sm={12} md={4} className="text-left">
                                                            <h5>Device Name : </h5>
                                                        </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                            <h5>{this.state.registrationModel.deviceName}</h5>
                                                        </Col>
                                                    </Row>                                                    
                                                    <Row>
                                                        <Col xs={12} sm={12} md={4} className="text-left">
                                                            <h5>Device Code :</h5>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={8} className="text-left">
                                                            <h5>{this.state.registrationModel.hsn}</h5>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={4} className="text-left">
                                                            <h5>Registration Code :</h5>
                                                        </Col> 
                                                        <Col xs={12} sm={12} md={8} className="text-left">
                                                            <FormGroup>                                                           
                                                                <FormControl
                                                                    id="devicesn"
                                                                    type="text"
                                                                    placeholder="Registration Code"
                                                                    name="DeviceSN"
                                                                    className={this.state.deviceSNState}
                                                                    value={this.state.deviceSN}
                                                                    onChange={event => { this.handlerUserInput(event) }}
                                                                    onBlur={e => this.onDeviceSNBlur()}
                                                                />
                                                                {this.state.deviceSNErrorMessage}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showRegistrationPopupModal: false })}>Close</Button>
                                                <Button bsStyle="primary" fill wd onClick={e => this.handlerDeviceRegistrationSubmit()}>Register</Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal
                                            show={this.state.showUnregisterationPopupModal}
                                            onHide={() => this.setState({ showUnregisterationPopupModal: false })}
                                        >
                                            {this.state.ShowPopupProgressBar &&
                                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                    <CustomProgressBar></CustomProgressBar>
                                                </div>
                                            }

                                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                <Modal.Title style={{ color: "white" }}>Terminal Registration</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    {this.state.popupErrorMessage &&
                                                        <div>
                                                            <Alert style={{marginTop:"-15px"}} bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                                <span>
                                                                    {this.state.popupErrorMessage}
                                                                </span>
                                                            </Alert>
                                                        </div>
                                                    }    
                                                    <Row>
                                                        <Col xs={12} sm={12} md={4} className="text-left">
                                                            <h5>Device Name : </h5>
                                                        </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                            <h5>{this.state.registrationModel.deviceName}</h5>
                                                        </Col>
                                                    </Row>                                                    
                                                    <Row>
                                                        <Col xs={12} sm={12} md={4} className="text-left">
                                                            <h5>Device Code :</h5>
                                                        </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                            <h5>{this.state.registrationModel.hsn}</h5>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showUnregisterationPopupModal: false })}>Close</Button>
                                                <Button bsStyle="primary" fill wd onClick={e => this.handlerDeleteTerminal(this.state.registrationModel)}>Unregister</Button>
                                            </Modal.Footer>
                                        </Modal>
                                        
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    }
                />
            </div>
        );
    }
}


export default Settings;