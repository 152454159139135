import React from 'react';
import ReactToPrint from 'react-to-print';
import Card from "components/Card/Card.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import QuickBooksToInstantInvoiceImage from "assets/img/instantinvoice/instantinvoicelogo.png";//QuickBooksToInstantInvoice.png";
import QuickBooksToInstantInvoiceReady from "assets/img/instantinvoice/Ready.png";
import ConnectToQuickBooksOnlineButton from "views/Components/Quickbooks.jsx";
//import qbo_logo from "assets/img/buttons/qbo_logo.png";
import qbo_logo from "assets/img/buttons/qb-logo-horizontal-preferred.svg";
import SessionTimeout from 'components/SessionTimeout';
import Common from "utils/Common.js";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Alert,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";  

class AccountingPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowProgressBar: false,
            errorCode: null,
            errorMessage: "",  
            is_qbo_connected: false, 
            proceesorSettingError: "",
            isAuthenticated: true,
        }
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }

    componentWillMount() {
        if(localStorage.getItem("is_qbo_connected") == "false"){
            this._isASConnectedRequest();             
        }        
    } 

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.GetAccountingPackage();
        var data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        if (data_tokenize == null || data_tokenize == undefined) {
            this.setState({proceesorSettingError: Common.getProcessorSettingsErrorMessage()})   
        }
    }

    _isASConnectedRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        NetworkService.isASConnectedRequest()
        .then(data => {
            this.setState({ ShowProgressBar: false });

            if (data != null) {
                if (data.statuscode == 0) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null) {
                    //data.data.items.type = "standalone";
                        this.setQBConnectionResult(data.data.items);
                    }
                }
            } 
            else {
                this.setState({ errorCode: 1 });
                this.setState({ errorMessage: data.statusmessage });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    setQBConnectionResult(data) {
        if (data.type == "quickbooks") {
            localStorage.setItem("is_qbo_connected", "true");
        } 
        else {        
            this.setState({ showQuickBooksModal: true });
        }
    }

    GetAccountingPackage() {
        this.showModelAndEPRStatusRequest();
    }

    showModelAndEPRStatusRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.erpStatusRequest();
    }

    /** API's calling */
    erpStatusRequest() {
        const url = BASE_URL + "api/v1/erp/status";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access_token
        }
        }).then(response => {
        return response.json()
        }).then(data => {
            this.setState({ errorCode: data.statuscode });
            this.setState({ ShowProgressBar: false });

            if (data != null && data.statuscode == 0) {
                data.data.forEach((element) => {
                switch (element.type) {
                    case "quickbooks": {
                        this.setState({ is_qbo_connected: element.is_connected });
                    break;
                    }
                    default: {
                        console.log("Service not available");
                    }
                }
                });
            }
            else{
                console.log("Service not available");
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    handlerShowQBOModal() {
        if (this.state.is_qbo_connected) {
            this.disconnectERPRequest();
        } else {
            this.setState({ showQuickBooksModal: true, is_qbo_connected: false });
        }
     }

    disconnectERPRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/erp/disconnect";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": access_token
        }
        }).then(response => {
        return response.json()
        }).then(data => {
            this.setState({ errorCode: data.statuscode });
            this.setState({ ShowProgressBar: false });
            

            if (data != null && data.statuscode == 0) {
                localStorage.setItem("is_qbo_connected", false);
                localStorage.setItem(StorageEnum.QBO_CUSTOMERS, null);
                localStorage.setItem("items", null);
                localStorage.setItem("terms", null);
                localStorage.setItem("preferences", null);
                localStorage.setItem("preferences", null);
                localStorage.setItem("taxcodes", null);
                localStorage.setItem("taxrates", null);
                this.setState({ errorMessage: "QuickBooks Online disconnected successfully." });
                localStorage.setItem("is_qbo_recently_connected", "false");
                this.setState({ is_qbo_connected: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            }
            else{
                this.setState({ errorMessage: data.statusmessage });
                console.log("Service not available");
            }
        }).catch(error => {
        this.setState({ ShowProgressBar: false });
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    callbackQBOButton = () => {
        this.setState({ showQuickBooksModal: false });
        localStorage.setItem("is_qbo_recently_connected", "true");
    }
    onCloseModalDialog(){
        this.setState({ showQuickBooksModal: false });
        this.setState({ errorCode: 1 });
        this.setState({ errorMessage: "Instant Invoice did not connect to QuickBooks Online, invoicing feature will not work." });
        console.log("Closing model dialog without connecting to QB Online.");
        
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
        
    }


    render() {
        return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    //title={"Accounting Software"}
                    content={
                        <div>
                            {this.state.proceesorSettingError &&
                                <div>
                                    <Alert bsStyle="info">
                                        <span>
                                            {this.state.proceesorSettingError}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }                             
                            <Row>
                                <Col md={6} sm={6}>                                
                                    {this.state.ShowProgressBar &&
                                        <div>
                                            <CustomProgressBar></CustomProgressBar>
                                            <br/>
                                        </div>
                                    } 
                                    <Card
                                        content={
                                            <div>
                                                <legend>Accounting Software</legend>
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <img src={qbo_logo}  width="250px" height="80px"/>
                                                        {/* <span >QuickBooks Online</span> */}
                                                    </Col>
                                                    <Col md={3}>
                                                    </Col>
                                                    <Col md={4} className="text-left">
                                                        <Button bsStyle={this.state.is_qbo_connected ? "danger" : "primary"} style={{ marginLeft: "20px", marginTop: "20px" }} fill onClick={() => this.handlerShowQBOModal()} >
                                                        {this.state.is_qbo_connected ? "Disconnect" : "Connect"}
                                                        </Button>
                                                
                                                    </Col>

                                                </Row>                                                
                                            </div>
                                        }
                                    />  
                                </Col>
                            </Row>                      

                        </div>
                    }
                />
                <div>
                    <Modal
                        show={this.state.showQuickBooksModal}
                        onHide={() => this.onCloseModalDialog()}
                        style={{ marginTop: "200px" }}
                        >
                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                            <Modal.Title style={{ color: "white" }}>Connect to QuickBooks Online</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-body">
                                <Row>
                                    <Col md={5}>
                                        <img src={qbo_logo}  width="205px" height="80px"/>
                                    </Col>
                                    <Col md={2}>
                                        <img src={QuickBooksToInstantInvoiceReady}  style={{width:"100px", height:"56px", marginTop: "20px",  marginLeft: "-20px"}} /> 
                                    </Col>
                                    <Col md={5}>
                                        <img src={QuickBooksToInstantInvoiceImage} alt="Instant Invoice" width="220px" height="80px"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <p style={{ color: "#7B8084" }}>
                                            <br />
                                            Connect to your existing QuickBooks Online account <br />
                                            Don't already have a QuickBooks Online account? <a href="https://quickbooks.intuit.com/sign-in-offer/" target="_blank" style={{ color: "rgba(0, 117, 193, 0.8)", textDecoration: "underline" }}>Click here</a> to get started.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="text-center">
                                        <br />
                                        <ConnectToQuickBooksOnlineButton hideQBOModal={this.callbackQBOButton}></ConnectToQuickBooksOnlineButton>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Body>                
                    </Modal>
                </div>
            </div>
        )
    }
}

export default AccountingPackage;
      