/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react component used to create charts
import Button from "components/CustomButton/CustomButton.jsx";
import { BASE_URL } from "utils/constant.js";
import { Col, Row } from "react-bootstrap";


class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token_id: props.getForm().tokenId,
      remainingBalance: props.getForm().remainingBalance,
      htmlText: props.getForm().htmlText

    };
  }

  componentDidMount() {
      window.addEventListener('load', (event) => {
          console.log('The page has fully loaded');
      });
      window.addEventListener('message', (event) => {
          console.log('The message has fully loaded');
          console.log(event.origin);
          console.log(event.data);
      });
  }

  componentWillReceiveProps(props) {
    if (props) {
    }
  }

  isValidated() {
    return false;
  }

  handleOnSubmit= (event) => {
    this.props.jumpToStep(0);
    event.preventDefault();    
  };

  handleGoBack= (e) => {
    window.location.href = BASE_URL + "#/invoice/invoices";
    e.preventDefault();
  };

  renderCustomerCareForm() {
    return (<div>
        <Row>
            <Col>
            <iframe
                title='CardPointeTokenizer'
                id='tokenframe'
                name='tokenframe'
                src={"data:text/html," + encodeURIComponent(this.state.htmlText)}
                scrolling='no'
                width='100%'
                height='150px'
                crossorigin="anonymous"
            />
            </Col>
        </Row>
    </div>
    );

}

  render() {
    return (
      <div className="wizard-step">
        {this.state.htmlText != null ? this.renderCustomerCareForm() : this.state.htmlText}
        <h2 className="text-center">
          <small style={{color:"green"}}>
            {this.state.token_id}
          </small>  
        </h2>
        <br />
        {/* { this.state.remainingBalance > 0  &&
          <div style={{ marginTop:"20px"}}>
            <Button
              bsStyle="info"
              fill
              wd
              onClick={this.handleOnSubmit.bind(this)}
              pullRight
            >
              Pay more
            </Button>
          </div>
        } */}
        {/* { parseFloat(this.state.remainingBalance).toFixed(2) == "0.00"  &&
          <div style={{ marginTop:"20px"}}>
            <Button
              bsStyle="info"
              fill
              wd
              onClick={this.handleGoBack.bind(this)}
              pullRight
            >
              Go Back
            </Button>
          </div>
        } */}
        
      </div>
    );
  }
}

export default Step3;
