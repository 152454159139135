/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Modal, Grid, Col, Row } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import QuickBooksToInstantInvoiceImage from "assets/img/instantinvoice/QuickBooksToInstantInvoice.png";
import NMIToInstantInvoice from "assets/img/instantinvoice/NMIToInstantInvoice.png";
import ConnectToQuickBooksOnlineButton from "views/Components/Quickbooks.jsx";
import { BASE_URL, StorageEnum, SoftwareTypeEnum } from "utils/constant.js";
import qbo_logo from "assets/img/buttons/qbo_logo.png";
import nmi_logo from "assets/img/buttons/nmi_logo.png";
import NetworkService from "services/NetworkService";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_qbo_connected: false,
      is_standalone_connected: false,
      is_freshbooks_connected: false,
      is_xero_connected: false,
      is_nmiinvoicing_connected: false,
      isQBDataCached: false,
    };

    this.handleAccountingPackageSubmit = this.handleAccountingPackageSubmit.bind(this);
  }

  componentWillMount() {
    if (localStorage.getItem("is_qbo_connected") == "true" && (localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null") && localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.QuickBooksOnline) {
      this.setCache();
    }
  }

  setCache() {
    if (localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null") {
      this._getCustomerRequest();
    }
    else {
      this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)));
    }
    if (localStorage.getItem("items") == null || localStorage.getItem("items") == "null") {
      this._getItemListingRequest();
    }
    else {
      this.setItemsResult(JSON.parse(localStorage.getItem("items")));
    }
    if (localStorage.getItem("terms") == null || localStorage.getItem("terms") == "null") {
      this._getTermsRequest();
    }
    else {
      this.setTermsResult(JSON.parse(localStorage.getItem("terms")));
    }
    if (localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null") {
      this._getPreferencesRequest();
    }
    else {
      this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")));
    }
  }

  componentDidMount() {
   // this.props.history.push('/account/login');
    if (localStorage.getItem(StorageEnum.UUID) == null) {
      this.props.history.push('/account/login');
    }
    document.getElementById("loaderDiv").style.display = "none";
  }

  _getCustomerRequest() {
    const app_id = 2
    NetworkService.getCustomerRequest(app_id)
      .then(data => {
        if (data != null) {
          if (data.data != null) {
            this.setCustomerResult(data.data.items);
          }
        }
      }).catch(error => {
        console.log("Error loading customers: " + error);
      });
  }

  setCustomerResult(customers) {
    localStorage.setItem(StorageEnum.QBO_CUSTOMERS, JSON.stringify(customers));
  }

  _getItemListingRequest() {
    const app_id = 2
    NetworkService.getItemListingRequest(app_id)
      .then(data => {
        if (data != null) {
          if (data.data != null)
            this.setItemsResult(data.data.items);
        }
      }).catch(error => {
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
      });
  }

  setItemsResult(items) {
    localStorage.setItem("items", JSON.stringify(items));
  }

  _getTermsRequest() {
    const app_id = 2
    NetworkService.getTermsRequest(app_id)
      .then(data => {
        if (data != null) {
          if (data.data != null)
            this.setTermsResult(data.data.items);
        } else {
        }
      }).catch(error => {
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
      });
  }

  setTermsResult(terms) {
    localStorage.setItem("terms", JSON.stringify(terms));
  }

  _getPreferencesRequest() {
    const app_id = 2
    NetworkService.getPreferencesRequest(app_id)
      .then(data => {
        if (data != null) {
          if (data.data.Preferences != null) {
            this.setPreferencesResult(data.data.Preferences)
          }
        }
      }).catch(error => {
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
      });
  }

  setPreferencesResult(preferences) {
    localStorage.setItem("preferences", JSON.stringify(preferences));
  }
  // Button Handlers
  handleQBDesktopSubmit(e) {
    // this.setState({ ShowProgressBar: true });
    this.props.history.push('/qbfinancial/dashboard');
  }

  handleQBPOSSubmit(e) {
    this.props.history.push('/qbpos/dashboard');
  }

  handleInvoiceSubmit(e) {
    // this.setState({ ShowProgressBar: true });
    if (localStorage.getItem("login_user_type") == "Staff" && localStorage.getItem("is_qbo_connected") == "false") {
      this.setState({ showAlertModal: true });
    }
    else if (localStorage.getItem("is_qbo_connected") == "false") {
      this._isASConnectedRequest();
    }
    else {
      this.props.history.push('/invoice/dashboard');
    }
    // this.props.history.push('/invoice/dashboard');
  }

  handleNMIInvoiceSubmit(e) {
    this.props.history.push('/nmi/invoices');
  }

  handleStandaloneSubmit(e) {
    this._isASConnectedRequest();
  }

  handleSettingsSubmit(e) {
    // this.setState({ ShowProgressBar: true });
    this.props.history.push('/account/settings');
  }

  handleAccountingPackageSubmit(e) {
    this.showModelAndEPRStatusRequest();
  }

  handlerShowQBOModal() {

    if (this.state.is_qbo_connected) {
      this.disconnectERPRequest();
    } else {
      this.setState({ showERPModal: false, showQuickBooksModal: true, is_qbo_connected: false });
    }

  }

  handlerShowStandAloneModel() {
    if (this.state.is_standalone_connected) {
      this.disconnectERPRequest();
    } else {
      this.setState({ showERPModal: false, showStandAloneModel: true, is_standalone_connected: false })
    }
  }

  handlerShowNMIInvoiceModel() {
    if (this.state.is_nmiinvoicing_connected) {
      this.disconnectERPRequest();
    } else {
      this.setState({ showERPModal: false, showNMIInvoicingModal: true, is_nmiinvoicing_connected: false })
    }
  }

  callbackQBOButton = () => {
    this.setState({ showQuickBooksModal: false })
  }

  callbackNMIInvoicingButton = () => {
    this.setState({ showNMIInvoicingModel: false })
  }


  setResult(data) {
    if (data.type == "quickbooks" || data.type == "freshbooks" || data.type == "xero") {
      this.props.history.push('/invoice/dashboard');
    } else if (data.type == "standalone") {
      this.props.history.push('/standalone/dashboard');
    } else {
      this.setState({ showERPModal: true });
    }
  }

  // API CALLING

  // GET request to check user erp status
  showModelAndEPRStatusRequest() {
    this.setState({ showERPModal: true });
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });
    this.erpStatusRequest();
  }

  /** API's calling */
  erpStatusRequest() {
    const url = BASE_URL + "api/v1/erp/status";
    const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": access_token
      }
    }).then(response => {
      return response.json()
    }).then(data => {
      this.setState({ errorCode: data.statuscode });
      this.setState({ ShowProgressBar: false });
      this.setState({ errorMessage: data.statusmessage });

      if (data != null && data.statuscode == 0) {
        data.data.forEach((element) => {
          switch (element.type) {
            case "quickbooks": {
              this.setState({ is_qbo_connected: element.is_connected });
              break;
            }
            case "freshbooks": {
              this.setState({ is_freshbooks_connected: element.is_connected });
              break;
            }
            case "xero": {
              this.setState({ is_xero_connected: element.is_connected });
              break;
            }
            case "standalone": {
              this.setState({ is_standalone_connected: element.is_connected });
              break;
            }
            case "nmiinvoicing": {
              this.setState({ is_nmiinvoicing_connected: element.is_connected });
              break;
            }
            default: {
              console.log("Service not available");
            }
          }
          // if (element.type === "quickbooks") {
          //   this.setState({ is_qbo_connected: element.is_connected });            
          // } else if (element.type === "freshbooks") {
          //   this.setState({ is_freshbooks_connected: element.is_connected });
          // } else if (element.type === "xero") {
          //   this.setState({ is_xero_connected: element.is_connected });
          // } else if (element.type === "standalone") {
          //   this.setState({ is_standalone_connected : element.is_connected });
          // }
        });

      }
      else
        console.log("Service not available");
    }).catch(error => {
      this.setState({ ShowProgressBar: false });
      this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
    });
  }

  // GET request to disconnect accouting package
  disconnectERPRequest() {
    this.setState({ showERPModal: false });
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });
    const url = BASE_URL + "api/v1/erp/disconnect";
    const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": access_token
      }
    }).then(response => {
      return response.json()
    }).then(data => {
      this.setState({ errorCode: data.statuscode });
      this.setState({ ShowProgressBar: false });
      this.setState({ errorMessage: data.statusmessage });

      if (data != null && data.statuscode == 0) {
        localStorage.setItem("is_qbo_connected", false);
        localStorage.setItem(StorageEnum.QBO_CUSTOMERS, null);
        localStorage.setItem("items", null);
        localStorage.setItem("terms", null);
        localStorage.setItem("preferences", null);
        localStorage.setItem("preferences", null);
        localStorage.setItem("taxcodes", null);
        localStorage.setItem("taxrates", null);
      }
      else
        console.log("Service not available");
    }).catch(error => {
      this.setState({ ShowProgressBar: false });
      this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
    });
  }

  _isASConnectedRequest() {
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });

    NetworkService.isASConnectedRequest()
      .then(data => {
        this.setState({ ShowProgressBar: false });

        if (data != null) {
          if (data.statuscode == 0) {
            this.setState({ errorCode: data.statuscode });
            this.setState({ errorMessage: data.statusmessage });
            if (data.data != null) {
              //data.data.items.type = "standalone";
              this.setResult(data.data.items);
            }
          }

        } else {
          this.setState({ StatusCode: 1 });
          this.setState({ StatusMessage: data.statusmessage });
        }
      }).catch(error => {
        // this.setState({ ShowProgressBar: false });
        this.setState({ ShowProgressBar: false });
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
      });
  }

  _postNMIInvoicingConnectRequest(){
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });

    NetworkService.postNMIInvoiceConnectRequest()
        .then(data => {
            this.setState({ ShowProgressBar: false });
            this.setState({ showNMIInvoicingModal: false });

            if (data != null) {
              this.setState({ errorCode: data.statuscode });
              this.setState({ errorMessage: data.statusmessage });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
  }

  // View render
  render() {
    const customButtonStyle = {
      minWidth: "120px",
      minHeight: "120px",
      borderRadius: "30px"
    };
    const customIconStyle = {
      fontSize: "70px",
      marginRight: "60px"
    };
    const textCenter = {
      textAlign: "center"
    };
    const customTextStyle = {
      color: "#0075c1",
      fontSize: "17px"
    };
    const customParagraphStyle = {
      color: "#0075c1",
      fontSize: "12px"
    }

    return (
      <div className="main-content">
        <br />
        {this.state.ShowProgressBar ?
          <div>
            <CustomProgressBar></CustomProgressBar>
            <br />
          </div>
          : ""
        }
        <Grid>
          <br />
          <br />
          <Row>
            {/* mdOffset={1} */}
            <Col mdOffset={localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.QuickBooksDesktop ||
              localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.QuickBooksPOS ||
              localStorage.getItem("login_user_type") == "Staff" ? 4 : 3}
              lg={2} md={2} sm={6} style={textCenter}>
              <Button bsStyle="primary" fill style={customButtonStyle} onClick={e => this.handleSettingsSubmit(e)}>
                <i className="fa fa-gears" style={{ fontSize: "70px", marginRight: "55px" }} />
              </Button>
              <br />
              <span style={customTextStyle}>Settings</span>
            </Col>
            {localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.NMIInvoicing &&
            <Col lg={2} md={2} sm={6} style={textCenter}>
              <Button bsStyle="primary" fill style={customButtonStyle} onClick={e => this.handleNMIInvoiceSubmit(e)}>
                <i className="fa fa-newspaper-o" style={{ fontSize: "70px", marginRight: "60px" }} />
              </Button>
              <br />
              <span style={customTextStyle}>NMI Invoices</span>
            </Col>
            }
            {localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.QuickBooksOnline &&
              <Col lg={2} md={2} sm={6} style={textCenter}>
                <Button bsStyle="primary" fill style={customButtonStyle} onClick={e => this.handleInvoiceSubmit(e)}>
                  <i className="fa fa-newspaper-o" style={{ fontSize: "70px", marginRight: "60px" }} />
                </Button>
                <br />
                <span style={customTextStyle}>Invoices</span>
              </Col>
            }
            {/* <Col lg={2} md={2} sm={6} style={textCenter}>
              <Button bsStyle="primary" fill style={customButtonStyle} onClick={e => this.handleStandaloneSubmit(e)}>
                <i className="fa fa-male" style={{ fontSize: "70px", marginRight: "20px" }} />
              </Button>
              <br />
              <span style={customTextStyle}>Standalone</span>
            </Col> */}
            {localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.QuickBooksDesktop &&
              <Col lg={2} md={2} sm={6} style={textCenter}>
                <Button bsStyle="primary" fill style={customButtonStyle} onClick={e => this.handleQBDesktopSubmit(e)}>
                  <i className="fa fa-desktop" style={{ fontSize: "70px", marginRight: "55px" }} />
                </Button>
                <br />
                <span style={customTextStyle}>QB Financial</span>
              </Col>
            }
            {/* <Col lg={2} md={2} sm={6} style={textCenter}>
              <Button bsStyle="primary" fill style={customButtonStyle} onClick={e => this.handleQBPOSSubmit(e)}>
                <i className="fa fa-fax" style={{ fontSize: "70px", marginRight: "55px" }} />               
              </Button>
              <br />
              <span style={customTextStyle}>QB POS</span>
            </Col> */}
            {(localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.QuickBooksOnline ||
              localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.NMIInvoicing || 
              localStorage.getItem(StorageEnum.SOFTWARE_TYPE) == SoftwareTypeEnum.InstantInvoiceStandalone) &&
              localStorage.getItem("login_user_type") == "Merchant" &&
              <Col lg={2} md={2} sm={6} style={textCenter}>
                <Button bsStyle="primary" fill style={customButtonStyle} onClick={e => this.handleAccountingPackageSubmit(e)}>
                  <i className="fa fa-cubes" style={{ fontSize: "70px", marginRight: "65px" }} />
                </Button>
                <br />
                <span style={customTextStyle}>Accounting Packages</span>
              </Col>
            }
          </Row>
          <br />
          <Row>
            <Col md={12}>
              {/**   
              {/** QBO Modal */}
              <Modal
                show={this.state.showQuickBooksModal}
                onHide={() => this.setState({ showQuickBooksModal: false })}
                style={{ marginTop: "200px" }}
              >
                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                  <Modal.Title style={{ color: "white" }}>Connect to QuickBooks Online</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div class="modal-body">
                    <Row>
                      <Col md={6} mdOffset={3}>
                        <img src={QuickBooksToInstantInvoiceImage} alt="Instant Invoice" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <p style={{ color: "#7B8084" }}>
                          <br />
                          Connect to your existing Quickbooks Online account <br />
                          Don't already have a Quickbooks Online account? <a href="https://quickbooks.intuit.com/sign-in-offer/" target="_blank" style={{ color: "rgba(0, 117, 193, 0.8)", textDecoration: "underline" }}>Click here</a> to get started.
                      </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <br />
                        <ConnectToQuickBooksOnlineButton hideQBOModal={this.callbackQBOButton}></ConnectToQuickBooksOnlineButton>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col ms={12}>
                        <p class="modal-body" style={customParagraphStyle}>© 2016 Intuit Inc. All rights reserved. Intuit and QuickBooks are registered trademarks of Intuit Inc. Terms and conditions, features, support, pricing, and service options subject to change without notice.</p>
                      </Col>
                    </Row> */}
                  </div>
                </Modal.Body>
                {/* <Modal.Footer>
                  <Button
                    simple
                    style={{backgroundColor:"lightgray",  marginRight:"36px"}}
                    onClick={() => this.setState({ showQuickBooksModal: false })}                  >
                    Close
                  </Button>
                </Modal.Footer> */}
              </Modal>

              {/** Standalone Invoicing Modal */}
              <Modal
                show={this.state.showNMIInvoicingModal}
                onHide={() => this.setState({ showNMIInvoicingModal: false })}
                style={{ marginTop: "200px" }}
              >
                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                  <Modal.Title style={{ color: "white" }}></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="modal-body">
                    <Row>
                      <Col md={6} mdOffset={3}>
                        <img src={NMIToInstantInvoice} alt="Standalone Invoicing" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <p style={{ color: "#7B8084" }}>
                          <br />
                          Connect to your existing NMI Invoicing service <br />
                          Don't already have a NMI account? <a href="https://quickbooks.intuit.com/sign-in-offer/" target="_blank" style={{ color: "rgba(0, 117, 193, 0.8)", textDecoration: "underline" }}>Click here</a> to get started.
                      </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <br />
                        {/* <ConnectToQuickBooksOnlineButton hideQBOModal={this.callbackNMIInvoicingButton}></ConnectToQuickBooksOnlineButton> */}
                        <Button bsStyle= "success" style={{ marginLeft: "20px", marginTop: "12px" }} fill onClick={() => this._postNMIInvoicingConnectRequest()} >
                          Connect to Standalone Invoicing
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
              </Modal>

              {/** ERP Modal */}
              <Modal
                show={this.state.showERPModal}
                onHide={() => this.setState({ showERPModal: false })}
                style={{ marginTop: "200px" }}
              >
                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                  <Modal.Title style={{ color: "white" }}>Accounting Softwares</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="modal-body text-center" >
                    <Row>
                      <Col md={5} className="text-left">
                        <img src={qbo_logo} width="60px" height="60px" />
                        <span >QuickBooks Online</span>
                      </Col>
                      <Col md={3}>
                      </Col>
                      <Col md={4} className="text-left">
                        <Button bsStyle={this.state.is_qbo_connected ? "danger" : "primary"} style={{ marginLeft: "20px", marginTop: "12px" }} fill onClick={() => this.handlerShowQBOModal()} >
                          {this.state.is_qbo_connected ? "Disconnect" : "Connect"}
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={5} className="text-left">
                        <img src={nmi_logo} width="60px" height="60px" />
                        <span >Standalone Invoicing</span>
                      </Col>
                      <Col md={3}>
                      </Col>
                      <Col md={4} className="text-left">
                        <Button bsStyle={this.state.is_nmiinvoicing_connected ? "danger" : "primary"} style={{ marginLeft: "20px", marginTop: "12px" }} fill onClick={() => this.handlerShowNMIInvoiceModel()} >
                          {this.state.is_nmiinvoicing_connected ? "Disconnect" : "Connect"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
              </Modal>

              {/** Alert Modal */}
              <Modal
                show={this.state.showAlertModal}
                onHide={() => this.setState({ showAlertModal: false })}
                style={{ marginTop: "200px" }}
              >
                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                  <Modal.Title style={{ color: "white" }}>QuickBooks Online Accont Not Connected</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div class="modal-body">
                    <Row>
                      <Col md={13} className="text-center">
                        <p style={{ color: "#7B8084" }}>
                          Your Quickbooks Online account is not connected with Instant Invoice.<br />
                          Please contact Instant Invoice account holder or Instant Invoice Support.<br />
                          Don't already have a Quickbooks Online account? <a href="https://quickbooks.intuit.com/sign-in-offer/" target="_blank" style={{ color: "rgba(0, 117, 193, 0.8)", textDecoration: "underline" }}>Click here</a> to get started.
                      </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <br />
                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.setState({ showAlertModal: false })}>Close</Button>
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
              </Modal>

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Home;
