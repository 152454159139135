// @flow

import React, { Component } from 'react';
import payment from 'payment';
import creditCardType from 'credit-card-type';
import styled from 'styled-components';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import Input from "@material-ui/core/Input";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import {
  formatCardNumber,
  hasCardNumberReachedMaxLength,
  isHighlighted
} from 'utils/formatter';
import images from 'utils/images';

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};

  
`;

const CardImage = styled.img`
  height: 2.2em;
  margin-left: 1.5em;
  ${({ styled }) => ({ ...styled })};
`;

global.isCardNumberValid = false;

const DangerText = styled.p`
  font-size: 1.2rem;
  margin: 5px 0 0 0;
  color: #ff3860;
  ${({ styled }) => ({ ...styled })};
`;

const BACKSPACE_KEY_CODE = 8;
const CARD_TYPES = {
  mastercard: 'MASTERCARD',
  visa: 'VISA',
  amex: 'AMERICAN_EXPRESS'
};

type Props = {
  CARD_TYPES: Object,
  cardNumberInputRenderer: Function,
  onError?: Function,
  cardNumberInputProps: Object,
  cardImageClassName: string,
  cardImageStyle: Object,
  containerClassName: string,
  containerStyle: Object,
  dangerTextClassName: string,
  dangerTextStyle: Object,
  fieldClassName: string,
  fieldStyle: Object,
  images: Object,
  inputComponent: Function | Object | string,
  inputClassName: string,
  //invalidClassName: string,
  invalidStyle: Object,
  customTextLabels: Object,
  formControlProps:  PropTypes.object,
  classes: PropTypes.object.isRequired,
};
type State = {
  cardImage: string,
  cardNumberLength: number,
  cardNumber: ?string,
  errorText: ?string
};

const inputRenderer = ({ inputComponent, props }: Object) => {
  const Input = inputComponent || 'input';
  return <Input {...props} />;
};

class CreditCardInput extends Component<Props, State> {
  cardNumberField: any;

  static defaultProps = {
    cardNumberInputRenderer: inputRenderer,
    cardNumberInputProps: {},
    cardImageClassName: '',
    cardImageStyle: {},
    containerClassName: '',
    containerStyle: {},
    dangerTextClassName: '',
    dangerTextStyle: {},
    fieldClassName: '',
    fieldStyle: {},
    inputComponent: 'input',
    inputClassName: '',
    invalidStyle: {},
    customTextLabels: {},
    formControlProps:{},
    classes: {},
  };

  constructor(props: Props) {
    super(props);
    this.CARD_TYPES = Object.assign({}, CARD_TYPES, props.CARD_TYPES);
    this.images = Object.assign({}, images, props.images);
    this.state = {
      cardImage: this.images.placeholder,
      cardNumberLength: 0,
      cardNumber: null,
      errorText: null
    };
  }

  componentDidMount = () => {
    this.setState({ cardNumber: this.cardNumberField.value }, () => {
      const cardType = payment.fns.cardType(this.state.cardNumber);
      const images = this.images;
      this.setState({
        cardImage: images[cardType] || images.placeholder
      });
    });
  };

  isMonthDashKey = ({ key, target: { value } } = {}) => {
    return !value.match(/[/-]/) && /^[/-]$/.test(key);
  };

  checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  handleCardNumberBlur = (
    { onBlur }: { onBlur?: ?Function } = { onBlur: null }
  ) => (e: SyntheticInputEvent<*>) => {
    const { customTextLabels } = this.props;
    if (!payment.fns.validateCardNumber(e.target.value)) {
      this.setFieldInvalid(
        customTextLabels.invalidCardNumber || 'Card number is invalid',
        'cardNumber'
      );
      global.isCardNumberValid = false;
    }
    else
    {
      global.isCardNumberValid = true;
    }

    const { cardNumberInputProps } = this.props;
    cardNumberInputProps.onBlur && cardNumberInputProps.onBlur(e);
    onBlur && onBlur(e);
  };

  handleCardNumberChange = (
    { onChange }: { onChange?: ?Function } = { onChange: null }
  ) => (e: SyntheticInputEvent<*>) => {
    const {
      customTextLabels,
      cardNumberInputProps
    } = this.props;
    const images = this.images;
    const cardNumber = e.target.value;
    const cardNumberLength = cardNumber.split(' ').join('').length;
    const cardType = payment.fns.cardType(cardNumber);
    const cardTypeInfo =
      creditCardType.getTypeInfo(
        creditCardType.types[this.CARD_TYPES[cardType]]
      ) || {};
    const cardTypeLengths = cardTypeInfo.lengths || [16];

    this.cardNumberField.value = formatCardNumber(cardNumber);

    this.setState({
      cardImage: images[cardType] || images.placeholder,
      cardNumber
    });


    this.setFieldValid();
    if (cardTypeLengths) {
      const lastCardTypeLength = cardTypeLengths[cardTypeLengths.length - 1];
      for (let length of cardTypeLengths) {        
        if (
          length === cardNumberLength &&
          payment.fns.validateCardNumber(cardNumber)
        ) {
           global.isCardNumberValid = true;
          break;
        }
        if (cardNumberLength === lastCardTypeLength) {
          this.setFieldInvalid(
            customTextLabels.invalidCardNumber || 'Card number is invalid',
            'cardNumber'
          );
           global.isCardNumberValid = false;
        }
        else{
           global.isCardNumberValid = false; 
        }
      }
    }

    cardNumberInputProps.onChange && cardNumberInputProps.onChange(e);
    onChange && onChange(e);
  };

  handleCardNumberKeyPress = (e: any) => {
    const value = e.target.value;
    this.checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(' ').join('').length;
      if (hasCardNumberReachedMaxLength(value, valueLength)) {
        e.preventDefault();
      }
    }
  };

  handleKeyDown = (ref: any) => {
    return (e: SyntheticInputEvent<*>) => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
        ref.focus();
      }
    };
  };

  setFieldInvalid = (errorText: string, inputName?: string) => {
    const { invalidClassName, onError } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.add(invalidClassName);
    this.setState({ errorText });

    if (inputName) {
      const { onError } = this.props[`${inputName}InputProps`];
      onError && onError(errorText);
      global.isCardNumberValid = false;
    }

    if (onError) {
      onError({ inputName, error: errorText });
    }
  };

  setFieldValid = () => {
    const { invalidClassName } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.remove(invalidClassName);
    this.setState({ errorText: null });
  };

  render = () => {
    const { cardImage, errorText} = this.state;
    const {
      cardImageClassName,
      cardImageStyle,
      cardNumberInputProps,
      cardNumberInputRenderer,
      containerClassName,
      containerStyle,
      dangerTextClassName,
      dangerTextStyle,
      fieldClassName,
      fieldStyle,
      inputClassName,
      inputComponent,
      invalidStyle,
      customTextLabels,
      classes,
      formControlProps,
      error,
      success,
      labelText,
      id,
      value,
      labelProps,
      inputProps,
    } = this.props;
     
     const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });

    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: !error,
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });

    return (
      <FormControl 
      {...formControlProps}
      className={formControlProps.className + " MuiFormControl-fullWidth" }
      >       
        <FieldWrapper
          id="field-wrapper"
          className={fieldClassName}
          styled={fieldStyle}
          invalidStyled={invalidStyle}
        >
          
          {labelText !== undefined ? (
        <InputLabel
          className= {!errorText && classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
            {labelText}
            </InputLabel>
          ) : null}
          <Input
             data-max="9999 9999 9999 9999 9999"
             id='card-number'
             name='CardNumber'
             value={value}
             ref = {cardNumberField => {
                  this.cardNumberField = cardNumberField;
                }}
            maxLength= '19'
            autoComplete= 'cc-number'                
            classes={{
                  root: marginTop,
                  disabled: classes.disabled,
                  underline: underlineClasses
               }}
             type=  'tel'
              onBlur= {this.handleCardNumberBlur()}
              onChange={this.handleCardNumberChange()}
              onKeyPress= {this.handleCardNumberKeyPress}
              fullWidth= {true}
              {...inputProps}
           >         
          </Input>
             <CardImage
            className={cardImageClassName}
            styled={cardImageStyle}
            src={cardImage}
          />              
        
        </FieldWrapper>

        {errorText && (
          <DangerText className={dangerTextClassName} styled={dangerTextStyle}>
            {errorText}
          </DangerText>
        )}
      </FormControl>
    );
  };
}

export default withStyles(customInputStyle)(CreditCardInput);

