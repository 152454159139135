import React from "react";
import {
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import red from "@material-ui/core/colors/red";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  Row,
  Col,
  Modal,
} from "react-bootstrap"; 


const useStyles = makeStyles(() => ({
  countdown: {
    color: red[700]
  }
}));

const SessionTimeoutDialog = ({  open, countdown, onLogout,onContinue }) => {
  const classes = useStyles();

  return (
    <Modal
        show={open}    
        style={{ marginTop: "200px" }}
         dialogClassName="modal-dialog-timeout-custom"      
        >
        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
            <Modal.Title style={{ color: "white" }}>Session Timeout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="modal-body">                
                <Row>
                    <Col md={2}>
                    <i className="fa fa-exclamation-triangle fa-3x text-warning" /> 
                    </Col>
                    <Col md={10}>
                      <span style={{fontSize:"16px"}}>Your session is about to expire.</span>
                      <br/>
                      <span style={{fontSize:"16px"}}>You will be logged out in <span className={classes.countdown}>{countdown}</span> seconds.</span>
                      
                    </Col>
                </Row>
            </div>
        </Modal.Body>  
        <Modal.Footer>
            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={onLogout} >Log Out</Button>
            <Button bsStyle="primary" fill wd onClick={onContinue}> Continue Session </Button>  
        </Modal.Footer>              
    </Modal>
  );
}


export default SessionTimeoutDialog;