/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Transactions from "views/AdminPanel/Transactions.jsx";
import Merchants from "views/AdminPanel/Merchants.jsx";
import Resellers from "views/AdminPanel/Resellers.jsx";
import CreateMerchant from "views/AdminPanel/CreateMerchant.jsx";
import CreateReseller from "views/AdminPanel/CreateReseller.jsx";
import EditMerchant from "views/AdminPanel/EditMerchant.jsx";
import EditReseller from "views/AdminPanel/EditReseller.jsx";

var AdminRoutes = [
  {
    path: "/transactions",
    layout: "/admin",
    name: "Transactions",
    icon: "fa fa-money",
    component: Transactions
  },
  {
    path: "/resellers",
    layout: "/admin",
    name: "Resellers",
    icon: "fa fa-handshake-o",
    component: Resellers
  },  
  {
    path: "/createreseller",
    layout: "/admin",
    name: "Create Reseller",
    component: CreateReseller
  },
  {
    path: "/editreseller",
    layout: "/admin",
    name: "Edit Reseller",
    component: EditReseller
  },  
  {
    path: "/merchants",
    layout: "/admin",
    name: "Merchants",
    icon: "fa fa-users",
    component: Merchants
  },  
  {
    path: "/createmerchant",
    layout: "/admin",
    name: "Merchant Boarding",
    component: CreateMerchant
  },
  {
    path: "/editmerchant",
    layout: "/admin",
    name: "Edit Merchant",
    component: EditMerchant
  }
];

export default AdminRoutes;
