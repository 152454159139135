import React, { Component } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    OverlayTrigger,
    Tooltip,
    Modal,
    Alert,
    InputGroup

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from "react-select";
import Datetime from "react-datetime";
import SessionTimeout from 'components/SessionTimeout';
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { createMuiTheme, ThemeProvider as MuiThemeProvider  } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import NetworkService from "services/NetworkService"
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Common from "utils/Common.js";

class Recurring extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            isAuthenticated: true,
            errorCode: null,
            errorMessage: "",   
            ShowProgressBar: false,
            ShowPopupProgressBar: false,         
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            queryFromDate: "",
            queryToDate: "",
            filter_invoice: { value: "all", label: "All" },
            data: [
                // { customername: 'Cool Cars', amount: '$500.00', recurringtemplatename: 'Monthly Due Payment', recurringtype: 'Monthly', lastexecutiontime: '2021-12-20 00:00:00', status: 'Active', errorstatus: 'None' },
            ],
            columns: [  
                {
                  //  title: 'id',
                    field: 'id',
                    hidden: true
                },       
                {                    
                   // title: 'profile_id',
                    field: 'profile_id',
                    hidden: true
                },
                {                    
                  //  title: 'next_execute_at',
                    field: 'next_execute_at',
                    hidden: true
                },
                {                    
                  //  title: 'success_attempt',
                    field: 'success_attempt',
                    hidden: true
                },
                {                    
                  //  title: 'total_attempt',
                    field: 'total_attempt',
                    hidden: true
                },       
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Amount',
                    field: 'amount',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "right",
                    }
                },
                {
                    title: 'Template Name',
                    field: 'recurringtemplatename',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },  
                {
                    title: 'Charge the Customer',
                    field: 'chargethecustomer',
                    headerStyle: {
                        textAlign: "left"
                    },
                },                 
                {
                    title: 'Occurrence',
                    field: 'occurrence',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "right",
                    }
                },                             
                {
                    title: 'Last Execution Time',
                    field: 'lastexecutiontime',
                   // type: 'date',
                    headerStyle: {
                        textAlign: "center"
                    },
                    cellStyle: {
                        textAlign: "center",
                    }
                },                            
                
                {
                    title: 'Status',
                    field: 'status',
                    sortable: false,
                    cellStyle: {
                        textAlign: "center",
                    }
                    
                },               
                
                
                // {
                //     title: 'Error Status',
                //     field: 'errorstatus',
                //     sortable: false,
                //     cellStyle: {
                //         textAlign: "center",
                //     }
                // }
            ],
            options: {
                //selection: true,
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                rowStyle: rowData => ({
                     backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"
                }),
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,
                loadingType: "overlay",
                rowStyle: rowData => ({
                })
            },
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            {/* <span className="react-tagsinput-tag tag-fill tag-red">Hi</span> */}
                            <OverlayTrigger placement="top" overlay={<Tooltip id="ttCreateRecurring">Create Recurring</Tooltip>}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateRecurring(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
            },
            showDeactivateRecurringPopupModal: false,
            showDetailRecurringPopupModal: false,
            recurringModel: {
                customername: "",
                recurringtemplatename: "",
                amount: 0.00
            },
            deactivateToolTip: <Tooltip id="deactivateToolTip">Deactivate</Tooltip>,
            viewDetailToolTip: <Tooltip id="viewdetailToolTip">View Detail</Tooltip>,
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.deactivateToolTip}>
                        <i className="fa fa-power-off" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleDeactivateRecurringPopup(rowData)
                },
                {                    
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.viewDetailToolTip}>
                        <i className="fa fa-info" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleDetailRecurringPopup(rowData)
                }
            ]
        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";        
        this.SetFromToDates();
        this.handleSearchSubmit();
        this.setState({errorMessage : this.props.location.state ? this.props.location.state.errorMessage : ""});
        this.setState({errorCode : this.props.location.state ? this.props.location.state.errorCode : ""});       
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 7000);
       
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleDeactivateRecurringPopup(recurring) {
        this.state.recurringModel = recurring;
        this.setState({ showDeactivateRecurringPopupModal: true });
    }

    handleDetailRecurringPopup(recurring){
        this.state.recurringModel = recurring;
        this.setState({ showDetailRecurringPopupModal: true });
    }

    handleDeactivateRecurringSubmit(recurring) {
        console.log(recurring);
        const data = {
            "subscription_id": recurring.id
        }
        this._postDeactivateRecurringRequest(data);
    }

    _postDeactivateRecurringRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postDeactivateRecurringRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showDeactivateRecurringPopupModal: false });
                if (data != null) {
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.handleSearchSubmit();
                        }
                    }
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);

                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showDeactivateRecurringPopupModal: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.filterRecurring();
    }

    filterRecurring = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
            "status": this.state.filter_invoice.value
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this.getRecurringRequest(query);
    };

    // GET request for Recurring details
    getRecurringRequest(params = "") {
        this.setState({ ShowProgressBar: true });
        const url = BASE_URL + "api/v1/recurring/subscribe/plan/?" + params;
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.QuickBooksOnline;

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.onSetResult(data.data);
                this.setState({ ShowProgressBar: false });
            } else {
                this.setState({ ShowProgressBar: false });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

    onSetResult = (result) => {
        const newSeries = [];      

        result.items.map((rec, index) => {
            const data = {
                index: index,
                id: rec.id,
                customername: rec.customer_name,
                amount: '$' + parseFloat(rec.amount).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
                recurringtemplatename: rec.template_name,                
                chargethecustomer: rec.day_frequency ? "Every  " + rec.day_frequency + "  Days" : "On Day  " + rec.day_of_month + "  Of Every  " + rec.month_frequency + "  month(s)",
                occurrence: rec.occurrence,
                lastexecutiontime: rec.last_run_at, //Common.formatDate(rec.last_run_at), 
                status: rec.is_active ? "Active" : "Inactive",
                profile_id: rec.profile_id,
                next_execute_at: rec.next_execute_at,
                success_attempt: rec.success_attempt,
                total_attempt: rec.total_attempt,
            }
            newSeries.push(data);

        });
        this.setState({ rawInvoiceData: result.items });
        this.setState({ data: newSeries });
    }

    handleCreateRecurring(e) {
        this.props.history.push('/invoice/createrecurring');
        e.preventDefault();
    }

    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        const className1 = !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? "modal-dialog-email-statistics-custom" : "";
        return (
            <div className="main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime                                              
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>Invoice Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="invoiceStatus"
                                            value={this.state.filter_invoice}
                                            onChange={value => {
                                                this.setState({ filter_invoice: value });
                                            }
                                            }
                                            options={this.state.invoiceStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>
                                        }
                                        <MaterialTable                                            
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            actions={this.state.actions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            components={this.state.components}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showDeactivateRecurringPopupModal}
                                        onHide={() => this.setState({ showDeactivateRecurringPopupModal: false })}
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }

                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Deactivate Recurring</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center">
                                                <Row>
                                                    <Col xs={12} sm={12} md={4} className="text-left">
                                                        <h5>Customer : </h5>
                                                    </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                        <h5>{this.state.recurringModel.customername}</h5>
                                                    </Col>
                                                </Row>  
                                                <Row>
                                                    <Col xs={12} sm={12} md={4} className="text-left">
                                                        <h5>Template Name : </h5>
                                                    </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                        <h5>{this.state.recurringModel.recurringtemplatename}</h5>
                                                    </Col>
                                                </Row>                                               
                                                <Row>
                                                    <Col xs={12} sm={12} md={4} className="text-left">
                                                        <h5>Amount :</h5>
                                                    </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                        <h5>{this.state.recurringModel.amount}</h5>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showDeactivateRecurringPopupModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleDeactivateRecurringSubmit(this.state.recurringModel)}>Submit</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showDetailRecurringPopupModal}
                                        onHide={() => this.setState({ showDetailRecurringPopupModal: false })}
                                        dialogClassName= {className1}    
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }

                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Recurring Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center">
                                                <Row>
                                                    <Col md={3} className="text-left">
                                                        <h5>Customer Name : </h5>
                                                    </Col> 
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.customername}</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>Profile ID : </h5>
                                                    </Col> 
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.profile_id}</h5>
                                                    </Col>
                                                </Row>  
                                                <Row>
                                                    <Col md={3} className="text-left">
                                                        <h5>Template Name : </h5>
                                                    </Col> 
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.recurringtemplatename}</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>Amount :</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.amount}</h5>
                                                    </Col>
                                                    
                                                </Row>                                               
                                                <Row>
                                                    <Col md={3} className="text-left">
                                                        <h5>Occurrence :</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.occurrence == null ? "Until Cancel" : this.state.recurringModel.occurrence }</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>Charge the Customer : </h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.chargethecustomer}</h5> 
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3} className="text-left">
                                                        <h5>Last Run : </h5>
                                                    </Col> 
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.lastexecutiontime}</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>Next Run :</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.next_execute_at}</h5>
                                                    </Col>                                                    
                                                </Row>  
                                                <Row>
                                                    <Col md={3} className="text-left">
                                                        <h5>Total Attempts : </h5>
                                                    </Col> 
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.total_attempt}</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>Success Attempts :</h5>
                                                    </Col>
                                                    <Col md={3} className="text-left">
                                                        <h5>{this.state.recurringModel.success_attempt}</h5>
                                                    </Col>                                                    
                                                </Row>                    
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showDetailRecurringPopupModal: false })}>Close</Button>                                            
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Recurring;