import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    OverlayTrigger,
    Tooltip,
    InputGroup

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import MaterialTable, { MTableToolbar } from 'material-table';
import Datetime from "react-datetime";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
import { StorageEnum } from "utils/constant.js"
import NetworkService from "services/NetworkService";
import Chip from '@material-ui/core/Chip';
import Common from "utils/Common.js";

class Transactions extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: false,
            totalTrans:0,
            totalCCAmount:0,
            totalACHAmount:0,
            totalCoFAmount:0,
            transactionStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                { value: "all", label: "All" },
                { value: "approved", label: "Approved" },
                { value: "decline", label: "Declined" },
            ],
            filter_transaction: { value: "all", label: "All" },
            data: [],
            columns: [
                {
                    title: 'Merchant',
                    field: 'merchant',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Payment Type',
                    field: 'paymenttype'
                },
                {
                    title: 'Inv/SaleRec #',
                    field: 'paymentnumber',
                    cellStyle: {
                        textAlign: "center"
                    },
                },
                {
                    title: 'Ref.ID',
                    field: 'tansactionid',
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Date',
                    field: 'date'
                },
                {
                    title: 'Amount ($)',
                    field: 'amount',
                    cellStyle: {
                        textAlign: "center"
                    },
                },
                {
                    title: 'Trans.Type',
                    field: 'transactiontype'
                },
                {
                    title: 'Status',
                    field: 'transactionstatus'
                }                
            ],
            subTransactionColumns: [
                {
                    title: 'Trans.ID',
                    field: 'tansactionid',
                },
                {
                    title: 'Date',
                    field: 'date'
                },
                {
                    title: 'Amount ($)',
                    field: 'amount',
                },
                {
                    title: 'Trans.Type',
                    field: 'transactiontype'
                },
                {
                    title: 'Status',
                    field: 'transactionstatus'
                },
            ],
            options: {
                //selection: true,
                showTextRowsSelected: false,
                pageSize: 50,
                pageSizeOptions: [10, 50, 100, 200, 500],
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            subTransactionOptions: {
                //selection: true,
                showTextRowsSelected: false,
               headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                paging: false,
                toolbar: false,
                sorting: false,
                draggable: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            detailPanel: [
                rowData => ({
                    disabled: !rowData.expandable,
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionExpandToolTip">Expand</Tooltip>}><i className="fa fa-plus" style={{ color: "#0075c1", display: !rowData.expandable ? "none" : "block" }}></i></OverlayTrigger>, //<UnfoldMore className={!rowData.expandable && classes.displayNone} />,
                    openIcon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionCollapseToolTip">Collapse</Tooltip>}><i className="fa fa-minus" style={{ color: "#0075c1" }}></i></OverlayTrigger>,
                    render: () => {
                        return (
                            <Row>
                                <Col md={10} mdOffset={1}>
                                    <MuiThemeProvider theme={this.theme}>
                                        <MaterialTable
                                            columns={this.state.subTransactionColumns}
                                            data={rowData.subTrans}
                                            options={this.state.subTransactionOptions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        )
                    },
                })
            ],
            emailReceiptToolTip: <Tooltip id="emailReceiptToolTip">Email Receipt</Tooltip>,
            printReceiptToolTip: <Tooltip id="printReceiptToolTip">Print Receipt</Tooltip>,
            voidToolTip: <Tooltip id="voidToolTip">Void</Tooltip>,
            refundToolTip: <Tooltip id="refundToolTip">Refund</Tooltip>,
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.emailReceiptToolTip}>
                        <i className="fa fa-send" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => alert("You saved " + rowData.customername)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.printReceiptToolTip}>
                        <i className="fa fa-print" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => alert("You saved " + rowData.customername)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.voidToolTip}>
                        <i className="fa fa-ban" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => alert("You saved " + rowData.customername)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.refundToolTip}>
                        <i className="fa fa-mail-reply-all" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => alert("You saved " + rowData.customername)
                }
            ],
        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        else if(localStorage.getItem("login_user_type") != "Reseller")
        {
            this.props.history.goBack();//go(-1);
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.SetFromToDates();
        this.handleSearchSubmit();
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    
    /** HANDLER */
    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
       // console.log("from date: " + this.state.queryFromDate + "   to date : " + this.state.queryToDate);

        this.filterTransactions();
    }

    filterTransactions = () => {
         this.setState({ ShowProgressBar: true });
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
           // "status": this.state.filter_transaction.value
        }
        console.log(params);
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getResellerPaymentListRequest(query);
    };

    /** API CALLING */
    setPaymentListResult(result) {
        const newSeries = [];
        const salesData = [];
        const voidRefundData = [];
        let ach_amount = 0;
        let cc_amount = 0;
        let cof_amount = 0;

        result.map((data, index) => {
            if(data.parent_ref_id === null || data.parent_ref_id === "")
            {           
                salesData.push({
                    index: index,
                    expandable: false,
                    id: data.id,
                    tansactionid: data.ref_id,
                    customername: data.customer_name,
                    date: Common.formatDate(data.trans_date),
                    amount: parseFloat(data.trans_amount).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
                    remainingamount: "",
                    transactiontype: data.trans_type,
                    transactionstatus: data.trans_status=="approved"?<Chip style={{ backgroundColor: "#2196f3", color: "white", height: 25 }} label="Approved"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Declined"></Chip>,
                    paymenttype: data.payment_type,
                    paymentnumber: data.doc_number,
                    ref_id: data.ref_id,
                    customer_email: data.customer_email,
                    merchant: data.merchant_name,
                    subTrans: [],
                });
            }
            if (data.trans_type == "ach_sale" && data.trans_status == "approved"){
                ach_amount += parseFloat(data.trans_amount)
            }else if(data.trans_type == "sale" && data.trans_status == "approved"){
               cc_amount += parseFloat(data.trans_amount)
            }else if(data.trans_type == "profile_sale" && data.trans_status == "approved"){
                cof_amount += parseFloat(data.trans_amount)
            }

        });

        result.map((vdata, index2) => {
            if(vdata.parent_ref_id !== null && vdata.parent_ref_id !== "")
            {
                voidRefundData.push({
                    index: index2,
                    expandable: false,
                    id: vdata.id,
                    tansactionid: vdata.ref_id,
                    customername: vdata.customer_name,
                    date: Common.formatDate(vdata.trans_date),
                    amount: parseFloat(vdata.trans_amount).toFixed(2),
                    remainingamount: "",
                    transactiontype: vdata.trans_type,
                    transactionstatus: vdata.trans_status,
                    paymenttype: vdata.payment_type,
                    paymentnumber: vdata.doc_number,
                    ref_id: vdata.ref_id,
                    parent_ref_id: vdata.parent_ref_id,
                    customer_email: vdata.customer_email
                });
            }
        });

        salesData.forEach(function(sale) {
            voidRefundData.forEach(function(voidRefund){
                if(sale.ref_id === voidRefund.parent_ref_id)
                {
                    sale.expandable = true;
                    sale.subTrans.push(voidRefund);
                }
            });
            newSeries.push(sale);
        });

        this.setState({totalTrans: newSeries.length});
        this.setState({totalACHAmount: ach_amount});
        this.setState({totalCCAmount:cc_amount});
        this.setState({totalCoFAmount:cof_amount});

        this.setState({ data: newSeries });

    }

    /** API CALLING */
    _getResellerPaymentListRequest(params = "") {
        this.setState({ errorMessage: "" });

        NetworkService.getResellerPaymentListRequest(params)
            .then(data => {
                // this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    //    this.setState({ errorMessage: data.statusmessage });

                    if (data.data != null) {
                        this.setPaymentListResult(data.data.items)
                    }
                    this.setState({ ShowProgressBar: false });

                } else {
                    this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }


    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>Transaction Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="transactionStatus"
                                            value={this.state.filter_transaction}
                                            onChange={value => {
                                                this.setState({ filter_transaction: value });
                                            }
                                            }
                                            options={this.state.transactionStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} sm={6}>  
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="totalTransactionsToolTip">Total Transactions</Tooltip>}>                            
                                        <span>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-line-chart text-info" />}
                                                statsText="Total Transactions"
                                                statsValue= {this.state.totalTrans || "0"}
                                            />
                                        </span>    
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>  
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="creditCardAmountToolTip">Credit Card Amount</Tooltip>}>                            
                                        <span>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-credit-card text-success" />}
                                                statsText="Credit Card Amount"
                                                statsValue= {"$ " + parseFloat(this.state.totalCCAmount).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                                            />
                                        </span>    
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>  
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="eCheckACHAmountToolTip">eCheck(ACH) Amount</Tooltip>}>                            
                                        <span>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-list-alt text-warning" />}
                                                statsText="eCheck(ACH) Amount"
                                                statsValue= {"$ " + parseFloat(this.state.totalACHAmount).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}//{this.state.invoice_stats.paid}
                                            />
                                        </span>    
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>  
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="cardOnFileAmountToolTip">Card on File (Profile Sale) Amount</Tooltip>}>                            
                                        <span>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-file-text text-danger" />}
                                                statsText="Card on File Amount"
                                                statsValue= {"$ " + parseFloat(this.state.totalCoFAmount).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}//{this.state.invoice_stats.paid}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                            </Row> 
                            <br />   
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br/>
                                            </div>

                                        }
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                           // actions={this.state.actions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            detailPanel={this.state.detailPanel}

                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Transactions;