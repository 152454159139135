import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Tab,
    Nav,
    Alert,
    NavItem,
    Tooltip,

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { TrixEditor } from "react-trix";
import Button from "components/CustomButton/CustomButton.jsx";
import Chip from '@material-ui/core/Chip';
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js"
import TagsInput from 'react-tagsinput';
import NetworkService from "services/NetworkService";
import ISC250Terminal from "views/Modals/ISC250.jsx"


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormValid: true,
            ShowProgressBar: false,
            errorMessage: "",
            errorCode: "0",
            senderNameErrorMessage: "",
            senderNameState: "",
            senderEmailErrorMessage: "",
            senderEmailState: "",
            subjectErrorMessage: "",
            subjectState: "",
            bodyErrorMessage: "",
            bodyState: "",
            Email: {
                senderName: "",
                senderEmail: "",
                bccEmails: [],
                bccEmail: "",
                ccEmails: [],
                subject: "",
                body: "",
                templates: [
                    {
                        value: "",
                        label: "Select Template",
                        isDisabled: true
                    },
                    { value: "Template1", label: "Template 1" },
                    { value: "Template2", label: "Template 2" }
                ],
                selectedTemplate: "",
                isDefaultTemplateChecked: false
            },
            otherOptions: {
                isShowConfirmationBeforeSendingInvoiceChecked: false,
                isSendPaymentReceiptAfterTrasnsactionChecked: false,
                isEnableFeedbackOnCustomerPortalChecked: false,
                isEnableInvoiceDefaultTemplateChecked: false,
                isEmailReceiptDialog: false,
                selectedDepositToAccount: "",
                depositToAccounts: [
                    // {
                    //     value: "",
                    //     label: "Select Deposit To Account",
                    //     isDisabled: true
                    // },
                    // { value: "DepositToAccount1", label: "Deposit To Account 1" },
                    // { value: "DepositToAccount2", label: "Deposit To Account 2" },
                    // { value: "DepositToAccount3", label: "Deposit To Account 3" }
                ]

            },
            invoiceTemplate: {
                template_id: 1,
                template_color: "blue",
            },
            addNewTemplateToolTip: <Tooltip id="addNewTemplateToolTip">Add new template</Tooltip>,
            terminalOptions: {
                IngenicoISC250: false
            }
        };

        this.handleBccEmailsChange = this.handleBccEmailsChange.bind(this);
        this.handleCCEmailsChange = this.handleCCEmailsChange.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";

        this.getEmailSettingRequest();
    }


    handleBodyChange(html, text) {
        // html is the new html content
        // text is the new text content
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                body: html
            }
        }));

        this.setState({ bodyState: html != "" ? "valid" : "" });
        this.setState({ bodyErrorMessage: html != "" ? null : <small className="text-danger"> Body is Required. </small> });
    }

    handleEditorReady(editor, bodyHTML) {
        // this is a reference back to the editor if you want to
        // do editing programatically
        var div = document.createElement("div");
        div.innerHTML = bodyHTML;
        var text = div.textContent || div.innerText || "";
        editor.insertString(text);
    }

    ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                senderEmail: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });
            this.setState({
                senderEmailState: "error"
            });
        }
        else {
            this.setState({ senderEmailState: "valid" });
            this.setState({ senderEmailErrorMessage: null });
        }

    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                [stateName]: value
            }
        }));

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    isValidated() {

        if (this.state.senderNameState === "" || this.state.senderNameState === "error") {
            this.setState({
                senderNameErrorMessage: (
                    <small className="text-danger">
                        Sender Name is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderNameErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.senderEmailState === "" || this.state.senderEmailState === "error") {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Email is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderEmailErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.subjectState === "" || this.state.subjectState === "error") {
            this.setState({
                subjectErrorMessage: (
                    <small className="text-danger">
                        Subject is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ subjectErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.bodyState === "" || this.state.bodyState === "error") {
            this.setState({
                bodyErrorMessage: (
                    <small className="text-danger">
                        Body is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ bodyErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        return this.state.isFormValid;

    }

    handleDefaultTemplateCheck = event => {
        const target = event.target;
        // this.setState({
        //     isDefaultTemplateChecked: target.checked
        // });
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                isDefaultTemplateChecked: target.checked
            }
        }));
    };

    handleShowConfirmationBeforeSendingInvoiceCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isShowConfirmationBeforeSendingInvoiceChecked: target.checked
            }
        }));
    };

    handleSendPaymentReceiptAfterTrasnsactionCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isSendPaymentReceiptAfterTrasnsactionChecked: target.checked
            }
        }));
    };

    handleEnableFeedbackOnCustomerPortalCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isEnableFeedbackOnCustomerPortalChecked: target.checked
            }
        }));
    };

    handleEnableInvoiceDefaultTemplateCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isEnableInvoiceDefaultTemplateChecked: target.checked
            }
        }));
    };

    handlerEmailSettingsSave = (emailSettings) => {
        if (this.isValidated()) {
            const invoiceContact = {
                sender_name: emailSettings.senderName,
                sender_email: emailSettings.senderEmail,
                bcc_emails: emailSettings.bccEmails,
                cc_emails: emailSettings.ccEmails,
                subject: emailSettings.subject,
                body: emailSettings.body,
                templates: emailSettings.templates
            }
            const setting = { "InvoiceContacts": invoiceContact }

            this.postEmailSettingsRequest(setting);
        }
        else {
            console.log("form not valid");
        }
    }

    handlerIngenicoISC250 = (value) => {
        this.setState(prevState => ({
            terminalOptions: {
                ...prevState.terminalOptions,
                ISC250Terminal: value
            }
        }));
    }


    handleBccEmailsChange(tags) {
        const bcc = { ...this.state.Email };
        bcc.bccEmails = tags || []
        this.setState({ Email: bcc })
    }

    handleCCEmailsChange(tags) {
        const cc = { ...this.state.Email };
        cc.ccEmails = tags || []
        this.setState({ Email: cc })
    }

    onSetEmailResult = (result) => {
        const invoiceContacts = result.InvoiceContacts
        const templateID = result.TemplateID
        const templateColor = result.TemplateColor
        const depositToAccount = result.DepositAccountID

        const email_setting = { ...this.state.Email }
        email_setting.senderName = invoiceContacts != null ? invoiceContacts.sender_name : "";
        if (invoiceContacts != null && invoiceContacts.sender_name != "") {
            this.setState({ senderNameState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.senderEmail = invoiceContacts != null ? invoiceContacts.sender_email : "";
        if (invoiceContacts != null && invoiceContacts.sender_email != "") {
            this.setState({ senderEmailState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.subject = invoiceContacts != null ? invoiceContacts.subject : "";
        if (invoiceContacts != null && invoiceContacts.subject != "") {
            this.setState({ subjectState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.body = invoiceContacts != null ? invoiceContacts.body : "";
        if (invoiceContacts != null && invoiceContacts.body != "") {
            this.setState({ bodyState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.bccEmails = invoiceContacts != null ? invoiceContacts.bcc_emails : [];
        email_setting.ccEmails = invoiceContacts != null ? invoiceContacts.cc_emails : [];

        const invoiceTemplate = { ...this.state.invoiceTemplate }
        invoiceTemplate.templateID = templateID
        invoiceTemplate.templateColor = templateColor
    }

    ISC250CallbackFunction = (childData) => {
        this.setState(prevState => ({
            terminalOptions: {
                ...prevState.terminalOptions,
                ISC250Terminal: childData
            }
        }));
    }


    /** API Calling */

    getEmailSettingRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const url = BASE_URL + "api/v1/settings/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.onSetEmailResult(data.data);
                this.setState({ ShowProgressBar: false });
                if (data.statuscode == 0) {

                }
                else {
                    this.setState({ errorMessage: data.statusmessage });
                }

            } else {

            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    // POST request for invoice email setting
    postEmailSettingsRequest = (settings) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/settings/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        fetch(url, {
            method: "POST",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "Setting": settings
            })
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: data.statuscode });
            } else {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: "1" });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorCode: "1" });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }


    render() {
        const customChipStyle = {
            fontSize: "1.5rem", marginRight: "5px", marginTop: "10px"
        }
        const EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i

        return (
            <div className="ii-main-content">
                <Card
                    ctFullWidth
                    content={
                        <Tab.Container id="tabs-with-dropdown" defaultActiveKey="email">
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav bsStyle="tabs">
                                        <NavItem eventKey="email">
                                            <i className="fa fa-envelope-o" /> Email
                                        </NavItem>
                                        <NavItem eventKey="other">
                                            <i className="fa fa-cogs" /> Other Settings
                                        </NavItem>
                                        <NavItem eventKey="customfields">
                                            <i className="fa fa-list-alt" /> Custom Fields
                                        </NavItem>
                                        {/* <NavItem eventKey="terminal">
                                            <i className="fa fa-usb" /> Hardware/Terminals
                                        </NavItem> */}
                                        {/* <NavItem eventKey="template">
                                            <i className="fa fa-file-text-o" /> Template
                                        </NavItem> */}
                                    </Nav>
                                </Col>
                                <Col sm={10}>
                                    <Tab.Content animation>
                                        {/* Email Tab */}
                                        <Tab.Pane eventKey="email">
                                            <Card
                                                content={
                                                    <div>
                                                        {this.state.ShowProgressBar &&
                                                            <div>
                                                                <CustomProgressBar></CustomProgressBar>
                                                                <br />
                                                            </div>
                                                        }
                                                        {this.state.errorMessage != "" &&
                                                            <div>
                                                                <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                                    <span>
                                                                        {this.state.errorMessage}
                                                                    </span>
                                                                </Alert>
                                                            </div>
                                                        }
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Sender Name: <span className="star">*</span>
                                                                    </div>
                                                                    <FormControl
                                                                        value={this.state.Email.senderName}
                                                                        type="text"
                                                                        placeholder="Sender Name"
                                                                        name="senderName"
                                                                        className={this.state.senderNameState}
                                                                        onChange={event =>
                                                                            this.change(event, "senderName", "length", 2)
                                                                        }
                                                                        onBlur={e => this.isValidated()}
                                                                    />
                                                                    {this.state.senderNameErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Sender Email: <span className="star">*</span>
                                                                    </div>
                                                                    <FormControl
                                                                        value={this.state.Email.senderEmail}
                                                                        placeholder="Sender Email"
                                                                        name="senderEmail"
                                                                        type="email"
                                                                        className={this.state.senderEmailState}
                                                                        onChange={event =>
                                                                            this.ValidateEmailAddress(event)
                                                                        }
                                                                        onBlur={e => this.isValidated()}
                                                                    />
                                                                    {this.state.senderEmailErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>

                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        BCC Email:
                                                                    </div>
                                                                    <TagsInput
                                                                        maxTags={3}
                                                                        value={this.state.Email.bccEmails}
                                                                        onChange={this.handleBccEmailsChange}
                                                                        // inputValue={this.state.Email.bccEmail}
                                                                        // onChangeInput={this.handleBccEmailsChangeInput}
                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                        onlyUnique
                                                                        addOnPaste
                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                        pasteSplit={data => {
                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                        }}
                                                                        tagProps={{ className: 'react-tagsinput-tag tag-fill tag-blue' }}
                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        CC Email:
                                                                    </div>
                                                                    <TagsInput
                                                                        maxTags={3}
                                                                        value={this.state.Email.ccEmails}
                                                                        onChange={this.handleCCEmailsChange}
                                                                        // inputValue={this.state.Email.bccEmail}
                                                                        // onChangeInput={this.handleBccEmailsChangeInput}
                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                        onlyUnique
                                                                        addOnPaste
                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                        pasteSplit={data => {
                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                        }}
                                                                        tagProps={{
                                                                            className: 'react-tagsinput-tag tag-fill tag-blue',
                                                                            classNameRemove: 'react-tagsinput-remove'
                                                                        }}
                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Subject: <span className="star">*</span>
                                                                    </div>
                                                                    <FormControl
                                                                        value={this.state.Email.subject}
                                                                        type="text"
                                                                        placeholder="Subject"
                                                                        name="subject"
                                                                        className={this.state.subjectState}
                                                                        onChange={event =>
                                                                            this.change(event, "subject", "length", 2)
                                                                        }
                                                                        onBlur={e => this.isValidated()}
                                                                    />
                                                                    {this.state.subjectErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Body:  <span className="star">*</span>
                                                                    </div>
                                                                    <TrixEditor
                                                                        className="custom-css-class"
                                                                        autoFocus={true}
                                                                        placeholder="Email's Body"
                                                                        value={this.state.Email.body}
                                                                        onChange={e => this.handleBodyChange(e)}
                                                                        onEditorReady={e => {
                                                                            setTimeout(() => {
                                                                                this.handleEditorReady(e, this.state.Email.body);
                                                                            }, 1000);
                                                                        }
                                                                        }
                                                                    />
                                                                    {this.state.bodyErrorMessage}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                        Supported Tags:
                                                                    </div>
                                                                    <Chip style={customChipStyle} label="[customer_name]" />
                                                                    <Chip style={customChipStyle} label="[invoice_number]" />
                                                                    <Chip style={customChipStyle} label="[amount]" />
                                                                    <Chip style={customChipStyle} label="[full_name]" />
                                                                    <Chip style={customChipStyle} label="[company_name]" />
                                                                    <Chip style={customChipStyle} label="[company_phone]" />
                                                                    <Chip style={customChipStyle} label="[company_weburl]" />
                                                                    <Chip style={customChipStyle} label="[date]" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ textAlign: "left" }}>
                                                            <br />
                                                            <Button bsStyle="primary" fill wd onClick={e => this.handlerEmailSettingsSave(this.state.Email)}>Save Changes</Button>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Tab.Pane>

                                        {/* Other Tab */}
                                        <Tab.Pane eventKey="other">
                                            {this.state.ShowProgressBar &&
                                                <div>
                                                    <CustomProgressBar></CustomProgressBar>
                                                    <br />
                                                </div>
                                            }
                                            {this.state.errorMessage != "" &&
                                                <div>
                                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                        <span>
                                                            {this.state.errorMessage}
                                                        </span>
                                                    </Alert>
                                                </div>
                                            }

                                            <Card
                                                title="Other Settings"
                                                content={
                                                    <div>

                                                        <div style={{ textAlign: "left" }}>
                                                            <br />
                                                            <Button bsStyle="primary" fill wd onClick={e => this.handlerAppOptionsSettingsSave(this.state.otherOptions)}>Save Changes</Button>
                                                        </div>
                                                    </div>
                                                }

                                            />
                                        </Tab.Pane>

                                        {/* Template Tab */}
                                        <Tab.Pane eventKey="template">
                                            <Card
                                                content={
                                                    <div>
                                                    </div>
                                                }

                                            />
                                        </Tab.Pane>

                                        {/* Hardware/Terminal Setting Tab */}
                                        <Tab.Pane eventKey="customfields">
                                            <Card
                                                content={
                                                    <div style={{ textAlign: "left" }}>
                                                        <br />
                                                        <Row>
                                                            <Col md={12}>
                                                                <Col md={4}>
                                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerIngenicoISC250(true)}>Ingenico iSC250</Button>
                                                                </Col>
                                                                <Col md={8}>
                                                                    {this.state.terminalOptions.ISC250Terminal && <ISC250Terminal isModalDialogShow={true} parentCallback={this.ISC250CallbackFunction}></ISC250Terminal>}

                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                        {/* <ISC250Terminal></ISC250Terminal> */}

                                                    </div>

                                                }

                                            />
                                        </Tab.Pane>
                                        
                                        {/* Hardware/Terminal Setting Tab */}
                                        <Tab.Pane eventKey="terminal">
                                            <Card
                                                content={
                                                    <div style={{ textAlign: "left" }}>
                                                        <br />
                                                        <Row>
                                                            <Col md={12}>
                                                                <Col md={4}>
                                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerIngenicoISC250(true)}>Ingenico iSC250</Button>
                                                                </Col>
                                                                <Col md={8}>
                                                                    {this.state.terminalOptions.ISC250Terminal && <ISC250Terminal isModalDialogShow={true} parentCallback={this.ISC250CallbackFunction}></ISC250Terminal>}

                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                        {/* <ISC250Terminal></ISC250Terminal> */}

                                                    </div>

                                                }

                                            />
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    }
                />
            </div>
        );
    }
}


export default Settings;