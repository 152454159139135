import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    InputGroup,
    Modal,
    Alert
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import Select from "react-select";
import Datetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import creditCardIcon from "assets/img/icons/creditcard.png";
import achIcon from "assets/img/icons/ach.png";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import NetworkService from "services/NetworkService";
import { StorageEnum } from "utils/constant.js";
// import { forwardRef } from "react";

const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
            color: "green",//successColor[2], 
            fontSize: "17px",
            fontWeight: "bold",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
            marginBottom: "0px",
            lineHeight: "1.8",
        },
        errorResponse: {
            color: "red",//dangerColor[0], 
            fontSize: "17px",
            fontWeight: "bold",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
            marginBottom: "0px",
            lineHeight: "1.8",
        },
}   

class CreateSalesReceipt extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            processorType: "nmi",
            IsTokenValid: false,
            showNewItemPopupModal: false,
            showNewCustomerPopupModal: false,
            showPaymentPopupModal: false,
            ShowPopupProgressBar: false,
            isFormValid: false,
            errorMessage: "",
            errorCode: 1,
            ShowProgressBar: false,
            customerEmailState: "",
            salesReceiptMessage: "",
            salesReceiptNumberState: "",
            salesReceiptNumberErrorMessage: "",
            customers: [],
            products: [],
            rows: [
                {
                    product: "",
                    description: "",
                    quantity: 1,
                    rate: 0.00,
                    amount: 0.00,
                    taxable: false,
                }
            ],
            taxRates: [
            ],    
            taxcodes:[                
            ],
            salesReceiptDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            customer: "",
            customerEmail: "",
            customerEmailErrorMessage: "",
            salesReceiptNumber: "",
            taxrate: "",
            total: 0.00,
            subtotal: 0.00,
            taxableSubtotal: 0.00,
            taxAmount: 0.00,
            Amount: 0.00,
            showTotalBalanceRow: false,
            customTxnNumbers: true,
            SalesReceipt: {
                TotalAmt: 0,
                TxnDate: "",
                DocNumber: "",
                Line: [
                    {
                        Amount: 0,
                        DetailType: "",
                        Description: "",
                    }
                ],
                BillEmail: {
                    Address: ""
                },
                BillAddr: {
                    Line1: "",
                    PostalCode: ""
                },
                CustomerMemo: {
                    value: ""
                },
                CustomerRef: {
                    value: "",
                    name: ""
                },
                TxnTaxDetail: {
                    TotalTax:0.00,
                    TxnTaxCodeRef: {
                        value:"",
                        name:""
                    },
                    TaxLine: [
                        {
                            DetailType: "TaxLineDetail", //TaxLineDetail
                            Amount: 0,
                            TaxLineDetail: {
                                TaxRateRef: {
                                   // value: "",
                                   // name: ""
                                   value: 0,
                                },
                                PercentBased: true,
                                TaxPercent: 0,
                                NetAmountTaxable: 0
                            }
                        }
                    ]
                }
            },
            itemInfo:{
                name: "",
                sku: "",
                price: 0.00,
                itemType:[        
                     { value: "", label: "Select Type", isDisabled: true },           
                     { value: "Service", label: "Service" },
                     { value: "NonInventory", label: "Non Inventory" },
                     { value: "Inventory", label: "Inventory" }
                ],
                selectedItemType: { value: "Service", label: "Service" },
                category:[
                    // {value: "", label: "Select Category", isDisabled: true },
                    // {value: "none", label: "None"},
                    // {value: "cat1", label: "category 1"},
                    // {value: "cat2", label: "category 2"}
                ],
                selectedCategory: {value: "none", label: "None"},
                salesInformation:"",
                expenseAccount:[
                    //  { value: "", label: "Select Expense Account", isDisabled: true },   
                    //  { value: "costofgoodsold", label: "Cost of Good Sold" },
                    //  { value: "shippingcostofgoodsold", label: "Shipping Cost of Good Sold" }
                ],
                selectedExpenseAccount: { value: "", label: "" },
                incomeAccount:[
                    // {value: "", label: "Select Income Account", isDisabled: true },
                    // {value: "billablesalesincome", label: "Billable Sales Income"},
                    // {value: "salesincome", label: "Sales Income"},
                    // {value: "salesofproductincome", label: "Sales of Product Income"},
                    // {value: "uncategorizedincome", label: "Uncategorized Income"},
                ],
                selectedIncomeAccount:  {value: "", label: ""},
                inventoryAssetAccount:[
                    // { value: "", label: "Select Inventory Asset Account", isDisabled: true },
                    // { value: "inventoryothercurrentasset", label: "Inventory Other Current Asset" },
                    // { value: "inventoryassetothercurrentasset", label: "Inventory Asset Other Current Asset" },
                    // { value: "uncategorizedassetothercurrentasset", label: "Uncategorized Asset Other Current Asset" },
                    // { value: "undepositedfundsothercurrentasset", label: "Undeposited Funds Other Current Asset" },
                ],
                selectedInventoryAssetAccount: {value: "", label: ""},
                quantityOnHand: 0,
                quantityOnHandDate: new Date(),
                quantityOnHandDateServer: "",
                nameState: "",
                nameErrorMessage: "",
                quantityErrorMessage: "",
                quantityState: "",
                isInvenotryItem: false,
            },
            IsCreditCard: true,
            IsACH: false,
            isSubmitting: false,
            paymentIcon: creditCardIcon,
            StatusCode: false,
            StatusMessage: "",
            paymentInfo: {
                CardHolderName: '',
                firstName: '',
                lastName: '',
                CardNumber: '',
                CardExpiry: '',
                GatewayExpiryDate: '',
                CardCVV: '',
                AccountNumber: '',
                AccountType: 'checking',
                RoutingNumber: '',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: false,
                CardHolderNameValid: true,
                firstNameValid: true,
                lastNameValid: true,
                AccountNameValid: true,
                AccountNumberValid: true,
                RoutingNumberValid: true,
                CardNumberValid: true,
                CardExpiryValid: true,
                CardCVVValid: true,
                AmountValid: true,
                CheckBoxChecked: false,
                FormErrors: { firstName: '', lastName: '', CardHolderName: '', AccountName: '' },                
            },
            customerInfo: {
                customerName: "",
                customerEmail: "",
                customerCompany: "",
                customerPhone: "",
                customerFax: "",
                customerWebsite: "",
                customerNote: "",
                isShippingAddressSameAsBillingAddress: true,
                billingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""
                },
                shippingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""    
                },
                newCustomerNameState: "",
                newCustomerNameErrorMessage: "",
                newCustomerEmailState: "",
                newCustomerEmailErrorMessage: ""
            },
            isNewCustomerFormValid: false,
            isNewItemFormValid: false,

            emvToken: '',
            expiryDate: '',
            hostName: '',
            hostPort: '',
            copilotURL: ''
        };

        this.salesReceiptDateHandler = this.salesReceiptDateHandler.bind(this);
    }

    handleOpenNewItemPopup(e) {
         this.state.itemInfo.name = "";
        this.state.itemInfo.sku = "";
        this.state.itemInfo.price = 0.00;                
        this.state.itemInfo.selectedItemType = this.state.itemInfo.itemType[0];               
        this.state.itemInfo.selectedCategory = this.state.itemInfo.category[0];
        this.state.itemInfo.salesInformation = "";                
        // this.state.itemInfo.selectedExpenseAccount = this.state.itemInfo.expenseAccount[0];               
        // this.state.itemInfo.selectedIncomeAccount =  this.state.itemInfo.incomeAccount[0];                
        // this.state.itemInfo.selectedInventoryAssetAccount = null;
        this.state.itemInfo.quantityOnHand = 0;
        this.state.itemInfo.quantityOnHandDate = new Date();
        this.state.itemInfo.quantityOnHandDateServer = "";
        this.state.itemInfo.nameState = "";
        this.state.itemInfo.nameErrorMessage = "";
        this.state.itemInfo.quantityErrorMessage = "";
        this.state.itemInfo.quantityState = "";
        this.state.itemInfo.isInvenotryItem = false;
        this.state.isNewItemFormValid = false;
        this.setState({showNewItemPopupModal: true});
        this._getAccountRequest();
    }

    handleOpenNewCustomerPopup(e){        
        this.setState({ShowPopupProgressBar:false});
        this.state.customerInfo.customerName =  "";
        this.state.customerInfo.customerEmail =  "";
        this.state.customerInfo.customerCompany =  "";
        this.state.customerInfo.customerPhone =  "";
        this.state.customerInfo.customerFax =  "";
        this.state.customerInfo.customerWebsite =  "";
        this.state.customerInfo.customerNote =  "";
        this.state.customerInfo.shippingAddress.address =  "";
        this.state.customerInfo.billingAddress.address = "";
        this.state.customerInfo.shippingAddress.country =  "";
        this.state.customerInfo.billingAddress.country =  "";
        this.state.customerInfo.shippingAddress.state =  "";
        this.state.customerInfo.billingAddress.state=  "";
        this.state.customerInfo.shippingAddress.city =  "";
        this.state.customerInfo.billingAddress.city =  "";
        this.state.customerInfo.shippingAddress.postalCode =  "";
        this.state.customerInfo.billingAddress.postalCode =  "";
        this.state.customerInfo.newCustomerNameState = "";
        this.state.customerInfo.newCustomerNameErrorMessage = "";
        this.state.customerInfo.newCustomerEmailState = "";
        this.state.customerInfo.newCustomerEmailErrorMessage = "";
        this.state.isNewCustomerFormValid = false;
        this.setState({showNewCustomerPopupModal: true});
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
       
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        }else{
            const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
            this.setState({processorType:data_tokenize.PPType.toLowerCase()});
            if(data_tokenize.PPType.toLowerCase() === "nmi"){     
                this.loadNMIScriptTag(data_tokenize.PPToken);
            }else if(data_tokenize.PPType.toLowerCase() === "cardconnect"){
                this.state.copilotURL = data_tokenize.PPToken;
            }
        }

        document.getElementById("loaderDiv").style.display = "none";
        this.SetSalesReceiptDates();
        this._getItemListingRequest();
       // this._getTermsRequest();
        this._getTaxCodeRequest();
        this._getPreferencesRequest();
        this._getClassesRequest();
        this._getCustomerRequest();
    }

    handleTokenEvent = (event) => {
        window.addEventListener(
            'message',
            (event) => {
                const token = JSON.parse(event.data)
                let mytoken = document.getElementById('mytoken')
                console.log(event.data);
                mytoken.value = token.message;

                if(token.errorCode == "0" && token.errorMessage == "")
                {
                    this.setState({
                        IsTokenValid: true
                    });
                    this.validateForm();
                }
                else
                {
                   this.setState({
                        IsTokenValid: false
                    }); 
                     this.validateForm();
                }
                const expField = token.expiry

                const tkn = mytoken.value
                const dte = expField

                this.setState({
                    emvToken: tkn,
                    expiryDate: dte
                })

                const emvData = {
                    token: tkn,
                    expiryDate: dte
                }

                // this.props.tokenProps.userEmvData(emvData)
                console.log(emvData);
                event.preventDefault()
            },
            false
        )
    }

    /** HANDLER */
    salesReceiptDateHandler = (date) => {
        this.setState({ salesReceiptDate: date.format("MM/DD/YYYY") });
        //this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        this.setState(prevState => ({
            SalesReceipt: {
                ...prevState.SalesReceipt,
                TxnDate: date.format("YYYY-MM-DD")
            }
        }));

    };

    SetSalesReceiptDates() {
        this.setState(prevState => ({
            SalesReceipt: {
                ...prevState.SalesReceipt,
                TxnDate: this.formatDate(this.state.salesReceiptDate)
            }
        }));

         this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                quantityOnHandDateServer: this.formatDate(this.state.itemInfo.quantityOnHandDate)
            }
        }));
    }

    quantityOnHandDateHandler = (date) => {
        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                quantityOnHandDate: date.format("MM/DD/YYYY")
            }
        }));
        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                quantityOnHandDateServer: date.format("YYYY-MM-DD")
            }
        }));
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState({ [stateName]: value });

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handTaxRateChange(taxRate){

        const rows = [...this.state.rows];
        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(taxRate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        });  

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

         this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        
        var taxRefCode = {
                        value : taxRate.value,
                        name : taxRate.name
                      } 

        const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
        txnTaxDetail.TxnTaxCodeRef = taxRefCode;
        txnTaxDetail.TotalTax = this.state.taxAmount;
        var detailType = txnTaxDetail.TaxLine[0].DetailType; 
        txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
        txnTaxDetail.TaxLine[0][detailType].TaxRateRef = taxRefCode;
        txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(taxRate.taxValue);
        txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

        this.setState({ txnTaxDetail });
        

        const lineItems = [...this.state.SalesReceipt.Line];
        lineItems.forEach(lineItem => {
             var detailType = lineItem.DetailType;
             if(lineItem.DetailType != ""){
                     taxRefCode = { value: "NON" }
                      lineItem[detailType].TaxCodeRef = taxRefCode;
            }
        });    

        lineItems.forEach(lineItem => {
             var detailType = lineItem.DetailType;
            rows.forEach(element => {
                if (lineItem.DetailType != "" && element.taxable && lineItem[detailType].ItemRef.value === element.product.value && parseFloat(element.amount) === parseFloat(lineItem.Amount)) {
                    taxRefCode = { value: "TAX" }                 
                   lineItem[detailType].TaxCodeRef = taxRefCode;
                   return false;
                }
             });           
        });  
       this.setState({ lineItems });
       this.setState({ rows });        
        
    }

    ValidateEmailAddress(event, name) {
        var value  = event.target.value;
        
        if(name === "newCustomerEmail"){
             this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmail:  value
                    }
                }));
        }
        else{
            this.setState({ customerEmail: value });
        }

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            if(name === "newCustomerEmail"){
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailErrorMessage: (
                            <small className="text-danger">
                                Invalid email address.
                            </small>
                        )
                    }
                }));
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState: "error"
                    }
                }));                
               
                this.setState({ isNewCustomerFormValid: false });                            
            }
            else{
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Invalid email address.
                        </small>
                    )
                });
                this.setState({
                    customerEmailState: "error"
                });
                this.setState({ isFormValid: false });
            }
        }
        else {
            if(name === "newCustomerEmail"){
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState:  "valid",
                        newCustomerEmailErrorMessage: ""
                    }
                }));
              if(this.state.customerInfo.newCustomerNameState === "valid"){
                    this.setState({ isNewCustomerFormValid: true });
                }   
            }
            else{
                this.setState({ customerEmailState: "valid" });
                this.setState({ customerEmailErrorMessage: "" });
                this.setState({ isFormValid: true });
            }
        }

    }

    validateNewCustomer(){
        if (this.state.customerInfo.newCustomerEmailErrorMessage === "") {
            if (this.state.customerInfo.newCustomerEmailState === "" || this.state.customerInfo.newCustomerEmailState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailErrorMessage: (
                            <small className="text-danger">
                                Email is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState:  "valid",
                        newCustomerEmailErrorMessage: ""
                    }
                }));                
            }
        }

        if (this.state.customerInfo.newCustomerNameErrorMessage === "") {
            if (this.state.customerInfo.newCustomerNameState === "" || this.state.customerInfo.newCustomerNameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerNameErrorMessage: (
                            <small className="text-danger">
                                Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerNameState:  "valid",
                        newCustomerNameErrorMessage: ""
                    }
                }));                
            }
        }       

        if(this.state.customerInfo.newCustomerEmailState === "valid" && this.state.customerInfo.newCustomerNameState === "valid"){
            this.setState({ isNewCustomerFormValid: true });
        }
        else{
            this.setState({ isNewCustomerFormValid: false });
        }
    }

    validateNewItem() {
        if (this.state.itemInfo.nameErrorMessage === "") {
            if (this.state.itemInfo.nameState === "" || this.state.itemInfo.nameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameErrorMessage: (
                            <small className="text-danger">
                                Item Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameState:  "valid",
                        nameErrorMessage: ""
                    }
                }));                
            }
        }

        if(this.state.itemInfo.selectedItemType.value === "Inventory"){
            if(this.state.itemInfo.quantityOnHand !== "")
            {
                if(parseInt(this.state.itemInfo.quantityOnHand) !== "NAN"){                    
                     this.state.itemInfo.quantityState = "valid";   
                     this.state.itemInfo.quantityErrorMessage = "";                    
                }
                else{
                     this.state.itemInfo.quantityState = "error";   
                     this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Invalid Quantity.</small>;                      
                }
            } 
            else{
                 this.state.itemInfo.quantityState = "error";   
                 this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Quantity is Required.</small>;                 
            }
        }
        else{
             this.state.itemInfo.quantityState = "valid";   
             this.state.itemInfo.quantityErrorMessage = "";
        }

        

        if(this.state.itemInfo.nameState === "valid" && this.state.itemInfo.quantityState === "valid"){
            this.setState({ isNewItemFormValid: true });
        }
        else{
            this.setState({ isNewItemFormValid: false });
        }

    }

    isValidated() {
        // if (this.state.senderNameState === "" || this.state.senderNameState === "error") {
        //     this.setState({
        //         senderNameErrorMessage: (
        //             <small className="text-danger">
        //                 Sender Name is Required.
        //             </small>
        //         )
        //     });
        //     this.setState({ isFormValid: false });
        // }
        // else {
        //     this.setState({ senderNameErrorMessage: null });
        //     this.setState({ isFormValid: true });
        // }

        if (this.state.customerEmailErrorMessage === "") {
            if (this.state.customerEmailState === "" || this.state.customerEmailState === "error") {
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Email is Required.
                        </small>
                    )
                });
                this.setState({ isFormValid: false });
            }
            else {
                this.setState({ customerEmailErrorMessage: null });
                this.setState({ isFormValid: true });
            }
        }

        return this.state.isFormValid;

    }

    handleRowProductNameChange = idx => value => {
        const rows = [...this.state.rows];
        rows[idx].product = value;
        rows[idx].rate = value.rate;
        rows[idx].quantity = value.quantity;
        rows[idx].taxable = value.taxable;
        if( rows[idx].rate != undefined && rows[idx].quantity != undefined){
            rows[idx].amount =  (parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)).toFixed(2);
        }
        else{
            rows[idx].amount = 0.00;
        }
        rows[idx].description = value.description;

        var detailType = value.DetailType;
       
        var taxRefCode = {};
        if(value.taxable){
            taxRefCode = { value : "TAX" }
        }
        else{
           taxRefCode = { value: "NON" }
        }
        const lineItems = [...this.state.SalesReceipt.Line];
        lineItems[idx].DetailType = detailType;
        lineItems[idx][detailType] = {
            ItemRef: { value: value.value, name: value.label },
            // Qty: "1",
            // UnitPrice: 100,
            TaxCodeRef: taxRefCode
        };

        if (lineItems[idx].DetailType != "" && rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            lineItems[idx].Amount = parseFloat(rows[idx].amount);
            lineItems[idx][detailType].Qty = parseInt(rows[idx].quantity);
            lineItems[idx][detailType].UnitPrice = parseFloat(parseFloat(rows[idx].rate).toFixed(2));
        }

        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                if(!isNaN(this.state.taxrate.taxValue)){
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                }    
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        }); 

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        if (this.state.total > 0) {
            this.setState({
                showTotalBalanceRow: true
            });
        }
        else {
            this.setState({
                showTotalBalanceRow: false
            });
        }

        if(this.state.taxRates.length > 1){
            const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;        
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
        }

        this.setState({ lineItems });
        this.setState({ rows });
    }

    handleRowDescriptionChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx].description = value;

        const lineItems = [...this.state.SalesReceipt.Line];
        lineItems[idx].Description = value;

        this.setState({ lineItems });
        this.setState({ rows });
    }

    handleRowValueChange = idx => e => {
        let { name, value } = e.target;        
        const rows = [...this.state.rows];
        if(name === "TaxCheckBox"){
            value = e.target.checked;
            rows[idx].taxable = value;
        }
        rows[idx][name] = value;
        if (rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            rows[idx].amount = (parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)).toFixed(2);
        }

        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                if(!isNaN(this.state.taxrate.taxValue)){
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                }
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        });            

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

         this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        if (this.state.total > 0) {
            this.setState({
                showTotalBalanceRow: true
            });
        }
        else {
            this.setState({
                showTotalBalanceRow: false
            });
        }
        var taxRefCode = {};
         if( rows[idx].taxable){
             taxRefCode = { value: "TAX" }
        }
        else{
           taxRefCode = { value: "NON" }
        }

        const lineItems = [...this.state.SalesReceipt.Line];
        if (lineItems[idx].DetailType != "" && rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            var detailType = lineItems[idx].DetailType;
            lineItems[idx].Amount = parseFloat(rows[idx].amount);
            lineItems[idx][detailType].Qty = parseInt(rows[idx].quantity);
            lineItems[idx][detailType].UnitPrice = parseFloat(parseFloat(rows[idx].rate).toFixed(2));

            lineItems[idx][detailType].TaxCodeRef = taxRefCode;
        }

        if(this.state.taxRates.length > 1){
            const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;        
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
        }

        this.setState({ lineItems });

        this.setState({ rows });

    };

    handleAddRow = () => {
        const rowItem = {
            product: "",
            description: "",
            quantity: "",
            rate: "",
            amount: "",
            taxable: false,
        };
        this.setState({
            rows: [...this.state.rows, rowItem]
        });

        const lineItem = {
            Amount: 0,
            DetailType: "",
            Description: "",
        }

        this.setState(prevState => ({
            ...prevState,
            SalesReceipt: {
                ...prevState.SalesReceipt,
                Line: [...this.state.SalesReceipt.Line, lineItem]
            }
        }));

    };

    handleRemoveRow = () => {
        if (this.state.rows.length > 1) {
            this.setState({
                rows: this.state.rows.slice(0, -1)
            });

            this.setState(prevState => ({
                ...prevState,
                SalesReceipt: {
                    ...prevState.SalesReceipt,
                    Line: this.state.SalesReceipt.Line.slice(0, -1)
                }
            }));
        }
    };

    handleRemoveSpecificRow = idx => () => {
       
        // if (this.state.rows.length > 1) {
        //     const rows = [...this.state.rows];
        //     rows.splice(idx, 1);
        //     const lineItems = [...this.state.SalesReceipt.Line];
        //     lineItems.splice(idx, 1);

        //     this.state.total = 0.00;
        //     rows.forEach(element => {
        //         if (!isNaN(element.amount)) {
        //             this.state.SalesReceipt.TotalAmt = this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
        //         }
        //     });
        //     if (this.state.total > 0) {
        //         this.setState({
        //             showTotalBalanceRow: true
        //         });
        //     }
        //     else {
        //         this.setState({
        //             showTotalBalanceRow: false
        //         });
        //     }

        //     this.setState(prevState => ({
        //         ...prevState,
        //         SalesReceipt: {
        //             ...prevState.SalesReceipt,
        //             Line: lineItems
        //         }
        //     }));

        //     this.setState({ rows });
        // }

        if (this.state.rows.length > 1) {
            const rows = [...this.state.rows];
            rows.splice(idx, 1);
            const lineItems = [...this.state.SalesReceipt.Line];
            lineItems.splice(idx, 1);
            this.setState(prevState => ({
                ...prevState,
                SalesReceipt: {
                    ...prevState.SalesReceipt,
                    Line: lineItems
                }
            }));
           
            this.state.taxableSubtotal = 0.00;
            this.state.subtotal = 0.00;
            this.state.taxAmount = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)  && element.taxable) {
                    this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                    this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                } 
                else if(!isNaN(element.amount)){
                    this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                }   
            });  

            this.state.total = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)) {
                    this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
                }
            });

            this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));
            
            var taxRefCode = {
                            value : this.state.taxrate.value,
                            name : this.state.taxrate.name
                        } 

            const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
            txnTaxDetail.TxnTaxCodeRef = taxRefCode;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
            txnTaxDetail.TaxLine[0][detailType].TaxRateRef = taxRefCode;
            txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(this.state.taxrate.taxValue);
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
            

            //const lineItems = [...this.state.Invoice.Line];
            lineItems.forEach(lineItem => {
                var detailType = lineItem.DetailType;
                if(lineItem.DetailType != ""){
                        taxRefCode = { value: "NON" }
                        lineItem[detailType].TaxCodeRef = taxRefCode;
                }
            });    

            lineItems.forEach(lineItem => {
                var detailType = lineItem.DetailType;
                rows.forEach(element => {
                    if (lineItem.DetailType != "" && element.taxable && lineItem[detailType].ItemRef.value === element.product.value && parseFloat(element.amount) === parseFloat(lineItem.Amount)) {
                        taxRefCode = { value: "TAX" }                 
                    lineItem[detailType].TaxCodeRef = taxRefCode;
                    return false;
                    }
                });           
            });  
        this.setState({ lineItems });
        this.setState({ rows });   
        }
    };

    createSalesReceiptSubmit = (salesReceipt) => {
        //Need to create this SalesReceipt api...  
        var custNameArr = this.state.SalesReceipt.CustomerRef.name.split(' ');
        this.state.paymentInfo.firstName = custNameArr[0];
        if(custNameArr.length > 1)
        {
           this.state.paymentInfo.lastName = custNameArr[custNameArr.length - 1];        
        }
        this.state.paymentInfo.CardHolderName = this.state.SalesReceipt.CustomerRef.name;   
        this.state.paymentInfo.Address = this.state.SalesReceipt.BillAddr.Line1;
        this.state.paymentInfo.PostalCode = this.state.SalesReceipt.BillAddr.PostalCode;    
        this.state.paymentInfo.CheckBoxChecked = false; 
        this.state.paymentInfo.FormValid = false;
        this.setState({ StatusCode: 0 });
        this.setState({ StatusMessage: "" });
        this.setState({ShowPopupProgressBar:false});
        this.setState({showPaymentPopupModal: true});
        if(this.state.processorType == "nmi")
        {
            this.configureCollectJS();
        }
        console.log(salesReceipt);
    }

    createCustomerSubmit = (customer) =>
    {
        if(customer.isShippingAddressSameAsBillingAddress)
        {
            customer.shippingAddress.address = customer.billingAddress.address;
            customer.shippingAddress.country = customer.billingAddress.country;
            customer.shippingAddress.state = customer.billingAddress.state;
            customer.shippingAddress.city = customer.billingAddress.city;
            customer.shippingAddress.postalCode = customer.billingAddress.postalCode;
        }

        const payload = {
            "FullyQualifiedName": null, 
            "PrimaryEmailAddr": {
              "Address": customer.customerEmail
            }, 
            "DisplayName": customer.customerName, 
            "Suffix": null, 
            "Title": null, 
            "MiddleName": null, 
            "Notes": customer.customerNote, 
            "FamilyName": null, 
            "PrimaryPhone": {
              "FreeFormNumber": customer.customerPhone
            }, 
            "CompanyName": customer.customerCompany, 
            "BillAddr": {
              "CountrySubDivisionCode": null, 
              "City": customer.billingAddress.city, 
              "PostalCode": customer.billingAddress.postalCode, 
              "Line1": customer.billingAddress.address, 
              "Country": customer.billingAddress.country
            },
            "ShipAddr": {
                "CountrySubDivisionCode": null, 
                "City": customer.billingAddress.city, 
                "PostalCode": customer.billingAddress.postalCode, 
                "Line1": customer.billingAddress.address, 
                "Country": customer.billingAddress.country
              }, 
            "GivenName": null,
            "WebAddr":{
                "URI":null
            }
          }

        this._postCreateCustomerRequest(payload);

        // //after creating customer uncomment below 
        // this.setState({ showNewCustomerPopupModal: false });
        // this.setState({ShowPopupProgressBar: false });
    }

    createItemSubmit = (item) =>
    {
        const payload = {
            "TrackQtyOnHand": this.state.itemInfo.isInvenotryItem?true:false, 
            "Name": item.name, 
            "QtyOnHand": item.price, 
            "IncomeAccountRef": item.selectedIncomeAccount, 
            "AssetAccountRef": item.selectedInventoryAssetAccount, 
            "InvStartDate": item.InvStartDate, 
            "Type": item.selectedItemType.value, 
            "ExpenseAccountRef": item.selectedExpenseAccount,
            "QtyOnHand": item.quantityOnHand,
            "InvStartDate": item.quantityOnHandDate,
            "Description": item.salesInformation
          }

        this._postCreateItemRequest(payload);
        
    }

    handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        //alert(charCode);
        //97-122, 32,65-90'
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };

    handleOnBlurInput(e) {
        console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        const name = e.target.name;
        const value = e.target.value;
        this.validateField(name, value)
        switch (name) {
            case "CardExpiry":
                if (this.state.Customer.CardExpiryValid === true) {
                    var dt_year = value.split("/")[1]; //"20"+
                    var dt_month = value.split("/")[0];
        
                    this.setState(prevState => ({
                        Customer:
                        {
                            ...prevState.Customer, GatewayExpiryDate: dt_year + "-" + dt_month
                        }
                    }), () => console.log(this.state.Customer.GatewayExpiryDate));
                }
                break;
            case "AccountNumber":
            case "RoutingNumber":
                if (this.state.paymentInfo.RoutingNumber && this.state.paymentInfo.AccountNumber) {
                    let account = `${this.state.paymentInfo.RoutingNumber}/${this.state.paymentInfo.AccountNumber}`
                    const payload = {
                        "account": account,
                        "source": "iToke",
                        "encryptionhandler": null,
                        "unique": false,
                        "expiry": null,
                        "cvv": null
                    }
                    this._postCardSecureTokenize(payload).then(event => {
                        // const token = JSON.parse(event)
                        let mytoken = document.getElementById('mytoken')
                        mytoken.value = event.token;

                        if (event.errorcode == 0) {

                            let acct_number = document.getElementById('accountnumber')
                            acct_number.value = "XXXXX" + acct_number.value.slice(-4);

                            let rout_number = document.getElementById('routingnumber')
                            rout_number.value = "XXXXX" + rout_number.value.slice(-4);

                            this.setState(prevState => ({
                                paymentInfo:
                                {
                                    ...prevState.paymentInfo, AccountNumber: "XXXXX" + acct_number.value.slice(-4)
                                }
                            }))

                            this.setState(prevState => ({
                                paymentInfo:
                                {
                                    ...prevState.paymentInfo, RoutingNumber: "XXXXX" + rout_number.value.slice(-4)
                                }
                            }))
                        }
                        this.setState({
                            IsTokenValid: event.errorcode == 0 ? true : false
                        });
                        this.setState({
                            emvToken: event.token,
                            expiryDate: null
                        })
                        
                        this.validateForm();

                        event.preventDefault()
                    }).catch(error => {
                        console.log(error)
                    })
                }
                break;
            default:
                break;
        }
    };

    handleUserInput(e) {
        console.log('handleUserInput is calling..' + e.target.name);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'firstName':
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, firstName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case 'lastName':
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, lastName: value
                    }
                }), () => { this.validateField(name, value) })
                break;    
            case 'CardHolderName':
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, CardHolderName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardNumber":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, CardNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardExpiry":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, CardExpiry: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardCVV":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, CardCVV: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, Address: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, Country: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, City: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, State: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, PostalCode: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                this.setState(prevState => ({
                    Amount:
                    {
                        ...prevState.Amount, Amount: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CheckBox":
                this.validateField(name, e.target.checked)
                break;
            case "AccountName":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, AccountName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, AccountNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, RoutingNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountType":
                this.setState(prevState => ({
                    paymentInfo:
                    {
                        ...prevState.paymentInfo, AccountType: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CheckBox":
                this.validateField(name, e.target.checked)
                break;    
            default:
                break;
        }

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.paymentInfo.FormErrors;
        let cardHolderNameValid = this.state.paymentInfo.CardHolderNameValid;
        let firstNameValid = this.state.paymentInfo.firstNameValid;
        let lastNameValid = this.state.paymentInfo.lastNameValid;
        let cardNumberValid = this.state.paymentInfo.CardNumberValid;
        let cardExpiryValid = this.state.paymentInfo.CardExpiryValid;
        let cardCVVValid = this.state.paymentInfo.CardCVVValid;
        let checkBoxChecked = this.state.paymentInfo.CheckBoxChecked;

        let accountNumberValid = this.state.paymentInfo.AccountNumberValid;
        let accountNameValid = this.state.paymentInfo.AccountNameValid;
        let routingNumberValid = this.state.paymentInfo.RoutingNumberValid;
        let amountValid = this.state.paymentInfo.AmountValid;
        switch (fieldName) {
            case 'firstName':
                firstNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.firstName = firstNameValid ? '' : ' is invalid';
                this.state.paymentInfo.firstNameValid = firstNameValid ? true : false;
                break;
            case 'lastName':
                lastNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.lastName = lastNameValid ? '' : ' is invalid';
                this.state.paymentInfo.lastNameValid = lastNameValid ? true : false;
                break;    
            case 'CardHolderName':
                cardHolderNameValid = true;//value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.CardHolderName = cardHolderNameValid ? '' : ' is invalid';
                this.state.paymentInfo.CardHolderNameValid = cardHolderNameValid ? true : false;
                break;
            case 'CardNumber':
              //  cardNumberValid = global.isCardNumberValid;
              //  this.state.Customer.CardNumberValid = cardNumberValid ? true : false;
                break;
            case 'CardExpiry':
               // cardExpiryValid = global.isCardExpiryValid;
              //  this.state.Customer.CardExpiryValid = cardExpiryValid ? true : false;
                break;
            case 'CardCVV':
             //   cardCVVValid = global.isCardCVVValid;
             //   this.state.Customer.CardCVVValid = cardCVVValid ? true : false;
                break;  
            case 'CheckBox':
                checkBoxChecked = value;
                this.state.paymentInfo.CheckBoxChecked = value;
                break;              
            case 'Amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.paymentInfo.AmountValid = amountValid ? true : false;
                this.state.Amount = value;
                break;
            case 'AccountName':
                accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.paymentInfo.AccountNameValid = accountNameValid ? true : false;
                break;
            case 'AccountNumber':
                accountNumberValid = value.match(/^\d{4,17}$/);
                this.state.paymentInfo.AccountNumberValid = accountNumberValid ? true : false;
                break;
            case 'RoutingNumber':
                routingNumberValid = value.match(/^\d{9}$/);
                this.state.paymentInfo.RoutingNumberValid = routingNumberValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            CardHolderNameValid: cardHolderNameValid,
            firstNameValid: firstNameValid,
            CheckBoxChecked: checkBoxChecked,
            lastNameValid: lastNameValid
        }, this.validateForm);

    }

    validateForm() {
        //console.log(this.state.Customer.CardNumberValid);
        if (this.state.IsCreditCard && this.state.processorType == "nmi") {
            this.setState(prevState => ({
                paymentInfo:
                {
                    ...prevState.paymentInfo, FormValid: this.state.IsCreditCard 
                        && this.state.paymentInfo.CardHolderNameValid  
                        && this.state.paymentInfo.firstNameValid && this.state.lastNameValid 
                        && this.state.paymentInfo.CheckBoxChecked
                }
            }), () => console.log())
        }
        else if (this.state.IsCreditCard && this.state.processorType == "cardconnect") {
            this.setState(prevState => ({
                paymentInfo:
                {
                    ...prevState.paymentInfo, FormValid: this.state.IsCreditCard && this.state.IsTokenValid
                        && this.state.paymentInfo.CardHolderNameValid  
                        && this.state.paymentInfo.CheckBoxChecked
                }
            }), () => console.log())
        }
        else if(this.state.IsACH && this.state.processorType == "cardconnect") {
            this.setState(prevState => ({
                paymentInfo:
                {
                    ...prevState.paymentInfo, FormValid: this.state.paymentInfo.AccountNameValid 
                        && this.state.IsTokenValid
                        && this.state.paymentInfo.CheckBoxChecked
                }
            }), () => console.log())
        }
        else if (this.state.IsACH) {
            this.setState(prevState => ({
                paymentInfo:
                {
                    ...prevState.paymentInfo, FormValid: this.state.paymentInfo.AccountNameValid 
                       && this.state.paymentInfo.firstNameValid && this.state.lastNameValid  
                        && this.state.paymentInfo.CheckBoxChecked
                }
            }), () => console.log())
        }
    }

    handleRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "creditcard") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsACH: false });
            this.setState({ paymentIcon: creditCardIcon });             
        }
        else {
            this.setState({ IsACH: true });
            this.setState({ IsCreditCard: false });
            this.setState({ paymentIcon: achIcon });

        }
        this.setState(prevState => ({
                    Customer: {
                        ...prevState.Customer,
                        FormValid: false
                    }
                }));
        this.state.paymentInfo.CheckBoxChecked = false;
        if(this.state.processorType == "nmi")
        {
            this.configureCollectJS();        

            document.getElementById("creditCardErrorMessage").style.display = "none";                            
            document.getElementById("expErrorMessage").style.display = "none";                            
            document.getElementById("cvvErrorMessage").style.display = "none";                            
            document.getElementById("accountNameErrorMessage").style.display = "none";                            
            document.getElementById("accountNumberErrorMessage").style.display = "none";                            
            document.getElementById("routingNumberErrorMessage").style.display = "none";
        }
                            
      //  this.state.Customer.FormValid = false;
    };

    configureCollectJS() {
        try{
            window.CollectJS.configure({           
                variant: 'inline',
                styleSniffer: true,
                callback: (token) => {
                    this.finishSubmit(token)
                },
                validationCallback: function (field, status, message) {
                    if (status) {
                        // console.log(document.getElementById(field).value);
                        var message = field + " is now OK 1 : " + message;
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "none";
                                document.getElementById("creditCardErrorMessage").innerHTML = "";
                            break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "none";
                                document.getElementById("expErrorMessage").innerHTML = "";
                            break; 
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "none";
                                document.getElementById("cvvErrorMessage").innerHTML = "";
                            break;    
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "none";
                                document.getElementById("accountNameErrorMessage").innerHTML = "";
                            break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "none";
                                document.getElementById("accountNumberErrorMessage").innerHTML = "";
                            break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "none";
                                document.getElementById("routingNumberErrorMessage").innerHTML = "";
                            break;                    
                        }    
                    } else {
                        var message = field + " is now Invalid : " + message + "  status=" + status;
                    //  document.getElementById(field).classList.add("invalidCss");
                    switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                                }
                                else
                                {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Invalid card number";
                                }
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                                }
                                else
                                {
                                    document.getElementById("expErrorMessage").innerHTML = "Invalid expiry date";
                                }                        
                                break;  
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                                }
                                else
                                {
                                    document.getElementById("cvvErrorMessage").innerHTML = "Invalid CVV";
                                }                        
                                break;  
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                                }
                                else
                                {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Invalid account name";
                                }    
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                                }
                                else
                                {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Invalid account number";
                                }    
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                                }
                                else
                                {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Invalid routing number";
                                }    
                                break;
                                break;                                                             
                    }
                    }
                    console.log(message);
                },
                // variant: 'inline',
                // googleFont: 'Abel',
                invalidCss: {
                    'border-bottom-color': 'red',
                    'border-width': '2px'
                },
                // validCss: {
                //   color: '#13AA73'
                // },
                customCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#AAAAAA',
                    'border-style': 'solid',
                    'border-width': '1px',
                    'font-family': 'sans-serif',
                    'font-size': '18px'
                },
                placeholderCss: {
                    "color": "#AAAAAA",
                    "font-weight": "bold",
                    "font-size": "16px"
                },
                // placeholderCss:{
                //     'font-family': 'sans-serif',
                //     'font-size':'18px',
                //     'ont-weight':'bold'
                // },
                focusCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#0075c1',
                    // 'border-style': 'solid',
                    'border-width': '2px'
                },            
                fields: {
                    ccnumber: {
                        // placeholder: 'Card Number',
                        // title: "Account Number",
                        selector: '#ccnumber',
                        //onChange:(e) => { console.log('a'); }
                    },
                    ccexp: {
                        // placeholder: 'MM/YYYY',
                        selector: '#ccexp'
                    },
                    cvv: {
                        // placeholder: 'CVV',
                        selector: '#cvv',
                        //  display: "show"
                    },
                    checkaccount: {
                        selector: "#accountnumber",
                        // title: "Account Number",
                        // placeholder: "Account Number"
                    },
                    checkaba: {
                        selector: "#routingnumber",
                        //title": "Routing Number",
                        //  placeholder: "Routing Number"
                    },
                    checkname: {
                        selector: "#accountname",
                        //title": "Name on Checking Account",
                        // placeholder: "Account Name"
                    }
                }
            });
        }catch (error) {
            this._getActiveProcessorRequest();
        }
    }

    loadNMIScriptTag(tokenizationKey){
        let script = document.createElement("script");
        script.src = "https://secure.networkmerchants.com/token/Collect.js"; 
        script.async = true;
        script.setAttribute(
            "data-tokenization-key", 
            tokenizationKey
        );
        script.setAttribute(
            "id",
            tokenizationKey
        );
        script.onload = () => {
            this.configureCollectJS();
        }
        document.head.appendChild(script);
        // setTimeout(() => {
        //     this.configureCollectJS();
        // }, 1000);  
    }

    handleCancelCreateSalesReceipt(e) {
        this.props.history.push('/standalone/dashboard');
        e.preventDefault();
    }

    handleSubmit = (event) => {
        this.setState({StatusMessage:""});  
        this.validateForm();     
        if(this.state.processorType == "nmi") 
        {
            var isCCFormValid = true;
            if(this.state.IsCreditCard){
                if(document.getElementById("creditCardErrorMessage").innerHTML !== "") {
                    document.getElementById("creditCardErrorMessage").style.display = "block";                            
                    isCCFormValid = false;
                }
                if(document.getElementById("expErrorMessage").innerHTML !== "") {
                    document.getElementById("expErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
            }
            else if(this.state.IsACH){
                if(document.getElementById("accountNameErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNameErrorMessage").style.display = "block";                            
                    isCCFormValid = false;
                }
                if(document.getElementById("accountNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNumberErrorMessage").style.display = "block";                            
                    isCCFormValid = false;
                }
                if(document.getElementById("routingNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("routingNumberErrorMessage").style.display = "block";                            
                    isCCFormValid = false;
                }              
            }
            if(!isCCFormValid || !this.state.paymentInfo.FormValid)
            {
                event.preventDefault();
                return false;
            }
            else {
                this.setState({ ShowPopupProgressBar: true });
                this.setState({ isSubmitting: true });

                event.preventDefault();
                window.CollectJS.startPaymentRequest();
            }
        }
        else if(this.state.processorType == "cardconnect") 
        {
            if (!this.state.paymentInfo.FormValid) {
                event.preventDefault();
                return false;
            }
            else {
                this.setState({ ShowPopupProgressBar: true });
                this.handleCharged(this.state);
            }
        }
    }

    finishSubmit(response) {        
        const { isSubmitting, ...formData } = { ...this.state };
        formData.token = response.token;
        console.log(formData);

        this.handleCharged(response);
        //post this form data to server api....
    }

    handleCharged = (value) => {
        const invoice_info = this.state.SalesReceipt;
        const payment_info = this.state.paymentInfo;
        const amount = parseFloat(this.state.total).toFixed(2);

        let cardInfo = null;
        let achInfo = null;
        if (this.state.IsCreditCard) {
            cardInfo = {         
                "FirstName": this.state.processorType == "nmi" ? payment_info.firstName : "",
                "LastName": this.state.processorType == "nmi" ? payment_info.lastName : "",
                "CardHolder": payment_info.CardHolderName,
                "CardNumber": this.state.processorType == "nmi" ? value.card.number : value.emvToken,
                "Cvv": null,
                "Month": null,
                "Year": null,
                "CardType": this.state.processorType == "nmi" ? value.card.type : null,
                "Token": this.state.processorType == "nmi" ? value.token : value.emvToken
            }
        } else if (this.state.IsACH) {
            achInfo = {
                "FirstName": this.state.processorType == "nmi" ? payment_info.firstName: "",
                "LastName": this.state.processorType == "nmi" ? payment_info.lastName: "",
                "AccountHolder": payment_info.AccountName,
                "AccountNumber": null,
                "RoutingNumber": null,
                "AccountType": "",//payment_info.AccountType,
                "CardType": "ECHK",
                "Token":  this.state.processorType == "nmi" ? value.token : value.emvToken
            }
        }

        const data = {
            "InvoiceInfo": {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "payment",
                "Invoice": invoice_info
            },
            "SalesReceiptInfo": {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "salesreceipt",
                "Invoice": invoice_info
            },
            "PaymentInfo": {
                "CardInfo": cardInfo,
                "AchInfo": achInfo,
                "ProfileInfo": {
                    "ProfileId": payment_info.ProfileId,
                    "CreateProfile": payment_info.IsCreateProfile
                },
                "BillingInfo": {
                    "Country": payment_info.Country,
                    "City": payment_info.City,
                    "State": payment_info.State,
                    "Street": payment_info.Address,
                    "PostalCode": payment_info.PostalCode
                },
                "TransactionInfo": {
                    "TransType": this.state.IsCreditCard?"sale":"ach_sale",
                    "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                    "Amount": amount,
                    "ActionType": "salesreceipt",
                    "CurrencyCode": "USD"
                }
            },
            "CustomerRef": {
                "Name": invoice_info ? invoice_info.CustomerRef.name : null,
                "Value": invoice_info ? invoice_info.CustomerRef.value : null,
                "Email": invoice_info ? invoice_info.BillEmail.Address : null
            }
        }

        console.log(data);

        this._postCollectPaymentRequest(data)
    }

        /**
     * Manipulate api's response
     */

    setCustomerResult(customers) {
        const newState = [{
            value: "AddNew",
            label: <Button simple icon onClick={e => this.handleOpenNewCustomerPopup(e)}>
                <div className="spinner-grow text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Customer</i>
            </Button>,

            isDisabled: true
        }]
        if(customers != null && JSON.stringify(customers) !== JSON.stringify({})){
            customers.map(data => {
                try {
                    const x = {
                        value: data["Id"],
                        label: data["DisplayName"],
                        PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"]["Address"] },
                        BillAddr: data["BillAddr"] != null ? {Line1: data["BillAddr"]["Line1"], PostalCode: data["BillAddr"]["PostalCode"]} : {Line1: "", PostalCode: ""}
                    }
                    newState.push(x);
                }
                catch (why) {
                    console.log(why)
                }

            });
        }
        this.setState({ customers: newState });
    }

    setNewCustomerResult(customer){
        customer.map(data => {
            try {
                const c = {
                    value: data["Id"],
                    label: data["DisplayName"],
                    PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"]["Address"] },
                    BillAddr: {Line1: data["BillAddr"]["Line1"], PostalCode: data["BillAddr"]["PostalCode"]}
                }
                this.setState(prevState => ({
                    customers: [
                        ...prevState.customers,
                        c,
                    ]
                }));
            }
            catch (why) {
                console.log(why)
            }
        });        
    }

    setTermsResult(terms) {
        const newState = [];

        terms.map(data => {
            const x = { value: data["Id"], label: data["Name"] }
            newState.push(x);
        });

        this.setState({ terms: newState });
    }

    setTaxCodesResult(taxcodes){
        const newState = [];
          taxcodes.map(data => {            
            newState.push(data);
        });  

        this.setState({ taxcodes: newState });
        this._getTaxRateRequest(); 
    }

    setTaxRatesResult(taxRates){
        const newState = [];
        console.log(this.state.taxcodes);
        newState.push({value: "", label: "Select Tax", name: "",  taxValue: "", isDisabled: true});

        this.state.taxcodes.map(tc =>{
             if(tc.Active && tc.SalesTaxRateList != null){
                let rv = 0
                tc.SalesTaxRateList.TaxRateDetail.map(strl =>{
                    var tr = taxRates.find( ({ Id }) => Id ===  strl.TaxRateRef.value );
                    if(tr != null){
                        rv += tr.RateValue
                    }
                })
                var id = tc.Id;
                var label = tc.Name + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +  (rv + "%") ;
                var taxValue =  rv;
                var name = tc.Name;
                const x = { value: id, label: label, name: name , taxValue: taxValue }; 
                newState.push(x);
            }
        })
        this.setState({ taxRates: newState }); 

        //parseFloat(parseFloat(`${taxCode.SalesTaxRateList.TaxRateDetail[0].TaxRateRef.value}`).toFixed(2)).toFixed(2)
        
        const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;

        if(this.state.taxRates.length > 1){
            this.setState({ taxrate: this.state.taxRates[1] });
            txnTaxDetail.TxnTaxCodeRef = {value: this.state.taxRates[1].value, name: this.state.taxRates[1].name};
        }

        txnTaxDetail.TotalTax = this.state.taxAmount;
        var detailType = txnTaxDetail.TaxLine[0].DetailType; 
        txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
        if(this.state.taxRates.length > 1){
            txnTaxDetail.TaxLine[0][detailType].TaxRateRef = {value: this.state.taxRates[1].value, name: this.state.taxRates[1].name};
            txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(this.state.taxRates[1].taxValue);
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;
        }

        this.setState({ txnTaxDetail });
    }

    setItemsResult(items) {
        const newState = [{
            value: "AddNew",
            label: <Button simple icon onClick={e => this.handleOpenNewItemPopup(e)}>
                <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Product/Service</i>
            </Button>,

            isDisabled: true
        }]

        items.map(data => {
            const product = {
                value: data["Id"],
                label: data["Name"],
                DetailType: "SalesItemLineDetail",
                taxable: data["Taxable"],
                quantity: 1,
                rate: data["UnitPrice"],
                description: data["Description"]
            }
            newState.push(product);
        });

        this.setState({ products: newState });
    }

    setNewItemResult(item){
        item.map(data => {
            const product = {
                value: data["Id"],
                label: data["Name"],
                DetailType: "SalesItemLineDetail",
                taxable: data["Taxable"],
                quantity: 1,
                rate: data["UnitPrice"],
                description: data["Description"]
            }
            this.setState(prevState => ({
                products: [
                    ...prevState.products,
                    product,
                ]
            }));
        });        
    }

    setPreferenceResult(preferences) {
        preferences.map(data => {
            this.setState({ customTxnNumbers: data["SalesFormsPrefs"]["CustomTxnNumbers"] })
            console.log(data["SalesFormsPrefs"]["CustomTxnNumbers"])
        });
    }

    setAccountResult(accounts){
        const newState = []
        accounts.map(data => {
            newState.push({value:data.Id, label:data.Name +"|"+ data.AccountType});
        });

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                incomeAccount: newState
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                expenseAccount: newState
            }
        }));
        
        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                inventoryAssetAccount: newState
            }
        }));  
    }

    setCategoryTypeItemResult(categories){
        categories.map(data => {
            this.setState(prevState => ({
                itemInfo: {
                    ...prevState.itemInfo,
                    selectedCategory: data
                }
            }));
            console.log(data);
        });
    }

    setProcessorResult = (processor) => {       
        if (processor.ProcessorType == "nmi"){
           processor.map(data =>{
                const configuration = data.configuration;
                if (configuration != null || configuration != "undefined"){
                    const tokenizationKey = configuration.TokenizationKey;
                    this.loadNMIScriptTag(tokenizationKey);
                    
                }else{
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Reseller" });
                }
            });
        }else if(processor.ProcessorType == "cardconnect"){
            processor.map(data => {
                const configuration = data.configuration;
                if (configuration != null || configuration != "undefined") {
                    this.state.copilotURL = configuration.URL;
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Customer Support Team..." });
                }
            });
        }        
    }

    /**
     * API Calling
     */   
    _getItemListingRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getItemListingRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setItemsResult(data.data.items);
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _postCreateItemRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        // this.props.history.push({
                        //     pathname: '/invoice/invoices',
                        //     state: { errorMessage: data.statusmessage, errorCode: 0 }
                        // });
                        if (data.data != null) {
                            // this.setCustomerResult(data.data.items);
                            this.setNewItemResult(data.data.items);
                         }
                    }
                    
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    _getTermsRequest() {
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTermsRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                //    this.setState({ errorMessage: data.statusmessage });

                    if (data.data != null)
                        this.setTermsResult(data.data.items);

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getTaxRateRequest() {
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTaxRateRequest(app_id)
            .then(data => {
               // this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.data != null){
                        this.setTaxRatesResult(data.data.items);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getTaxCodeRequest() {
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTaxCodeRequest(app_id)
            .then(data => {
              //  this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.data != null){
                        this.setTaxCodesResult(data.data.items);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getPreferencesRequest() {
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getPreferencesRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data.Preferences != null)
                        this.setPreferenceResult(data.data.Preferences)

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getClassesRequest() {
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getClassesRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getCustomerRequest() {
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getCustomerRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null) {
                        this.setCustomerResult(data.data.items);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _postCreateCustomerRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateCustomerRequest(app_id, body)
            .then(data => {

                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });
                this.setState({showNewCustomerPopupModal: false});

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        if (data.data != null) {
                            this.setNewCustomerResult(data.data.items);
                         }
                    }
                    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({showNewCustomerPopupModal: false});
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }
    
    _getAccountRequest(){
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getAccountRequest(app_id)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });
                   if(data.statuscode == 0){
                        const account = data.data.items;
                        this.setAccountResult(account);
                   }


                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getCategoryTypeItemRequest(){
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getCategoryTypeItemRequest(app_id)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });
                   const categories = data.items;
                   this.setCategoryTypeItemResult(categories);

                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _postCollectPaymentRequest = (data) => {
        //this.setState({ ShowPopupProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postCollectPaymentRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ isSubmitting: false });
                if (data != null) {
                    console.log(data.statuscode  +'  ' + data.statusmessage)
                    this.setState({ StatusCode: data.statuscode });
                    this.setState({ StatusMessage: data.statusmessage });
                    
                    if(data.statuscode === 0) {
                        if(this.state.processorType == "nmi")
                        {
                            this.configureCollectJS();
                            document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                            document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                            document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                            document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                            document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                            document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                        }
                        this.setState({isPaid : true});
                        if(parseFloat(this.state.remainingBalance).toFixed(2) == "0.00"){                         
                           
                        }
                        else {
                            this.setState({totalAmount: this.state.remainingBalance});
                            this.setState({amountToCharge: this.state.remainingBalance});
                            this.setState({remainingBalance: 0});
                        }
                        if(this.state.processorType == "cardconnect")
                        { 
                            if(this.state.IsCreditCard)
                            {
                                setTimeout(() => {
                                    var iframe = document.getElementById('tokenframe');
                                    iframe.src = iframe.src;
                                }, 1000);
                            }
                            else
                            {
                                // setTimeout(() => {
                                //     var iframe = document.getElementById('achtokenframe');
                                //     iframe.src = iframe.src;
                                // }, 1000);
                            }
                            this.setState({IsTokenValid: false});
                            this.setState({emvToken: "", expiryDate: ""});
                            this.validateForm();
                        }

                    }
                  //  if (data.data != null)
                   //     this.setItemsResult(data.data.items);
                } else {
                    this.setState({ StatusCode: -1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
               // this.setState({ ShowProgressBar: false });

                if (data != null) {
                    // this.setState({ StatusCode: data.statuscode });
                    // this.setState({ StatusMessage: data.statusmessage });
                    if(data.statuscode === 0) {
                        let processor = data.data.items;           
                        this.setProcessorResult(processor);
                    }

                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
              //  this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            }); 
    }

    async _postCardSecureTokenize(data) {
        let url = `${this.state.copilotURL}/cardsecure/api/v1/ccn/tokenize`

        let response = await (fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }).then(res => {
            return res.json()
        }).catch(err => {
            console.log('Error: ', err)
        })
        )
        return response
    }


    render() {
        const totalLabelStyle = {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "35px"
        };
        const deleteButtonToolTip = <Tooltip id="refundToolTip">Delete</Tooltip>;        

        // const url = 'https://fts.cardconnect.com:6443/itoke/ajax-tokenizer.html'
        const url = `${this.state.copilotURL}/itoke/ajax-tokenizer.html`
        var padding = this.state.IsCreditCard ? "6px 0 7px" : "0px 0 7px";
        const cssStyle = 'css=.success{color:green;border-color:green;}.error{color:red;border-color:red;}label{font-family:sans-serif;font-weight:bold;font-size:14px;margin-top:12px;margin-bottom:0px;line-height:1.42857;color:rgba(0, 0, 0, 0.54)}input{font:inherit;color: currentColor;font-family:sans-serif;height:1.1875em;font-size:18px;margin-top:-5px;color:currentColor;width:100%;border: 0px;border-bottom: 1px solid;border-bottom-color: rgba(227,227,227,1); outline: none;display:block;padding:'+ padding +';margin-bottom: -6px;background:none;box-sizing: content-box;}select{font:inherit;color: currentColor;outline: none;font-family:sans-serif;height:1.1875em;font-size:18px;margin-top:-5px;color:currentColor;border:0;border-bottom: 1px solid; border-bottom-color: rgba(227,227,227,1);padding: 6px 0 7px;background:none;box-sizing: content-box; width:45%;margin-bottom: 10px;}';
        
        const params = new URLSearchParams({
            maskfirsttwo: false,
            useexpiry: this.state.IsCreditCard ? true : false,
            usemonthnames: this.state.IsCreditCard ? true : false,
            usecvv: this.state.IsCreditCard ? true : false,
            cardnumbernumericonly: this.state.IsCreditCard ? true : false,
            orientation: 'default',
            invalidinputevent: true,
            enhancedresponse: true,
            formatinput: true,
            tokenizewheninactive: true,
            inactivityto: this.state.IsCreditCard ? 4000 : 5000,
            fullmobilekeyboard: true
        })

        const iFrameUrl = url + '?' + params + '&' + encodeURI(cssStyle);

        return (
            <div className="ii-main-content">
                {this.state.ShowProgressBar &&
                    <div style={{marginBottom:"5px"}}>
                        <CustomProgressBar></CustomProgressBar>
                    </div>
                }
                <Card
                    //  ctFullWidth
                    textCenter
                    title="New Sales Receipt"
                    content={                        
                        <div>
                            {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Customer: <span className="star">*</span>
                                        </div>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="customers"
                                            isDisabled={this.state.ShowProgressBar}
                                            value={this.state.customer}
                                            onChange={value => {
                                                this.setState({ customer: value });
                                                this.setState({ customerEmail: value.PrimaryEmailAddr.Address })
                                                if(value.PrimaryEmailAddr.Address != "" && value.PrimaryEmailAddr.Address != undefined)
                                                {
                                                    this.setState({customerEmailState: "valid"});
                                                }
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    SalesReceipt: {
                                                        ...prevState.SalesReceipt,
                                                        CustomerRef: {
                                                            ...prevState.SalesReceipt.CustomerRef,
                                                            value: value.value, name: value.label
                                                        },
                                                        BillEmail: {
                                                            ...prevState.SalesReceipt.BillEmail,
                                                            Address: value.PrimaryEmailAddr.Address
                                                        },
                                                        BillAddr: {
                                                            ...prevState.SalesReceipt.BillAddr,
                                                            Line1: value.BillAddr.Line1, PostalCode: value.BillAddr.PostalCode
                                                        }

                                                        // BillAddr: {Line1: data["BillAddr"]["Line1"], PostalCode: data["BillAddr"]["PostalCode"]}
                                                    }
                                                }));
                                             }                                             
                                            }
                                            onBlur={e => this.isValidated()}
                                            options={this.state.customers}
                                            placeholder="Select Customer"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Customer Email: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.customerEmail}
                                            placeholder="Customer Email"
                                            name="customerEmail"
                                            type="email"
                                            className={this.state.customerEmailState}
                                            disabled={this.state.ShowProgressBar}
                                            onChange={event =>
                                                this.ValidateEmailAddress(event, "customers")
                                            }
                                            onBlur={e => this.isValidated()}
                                        />
                                        {this.state.customerEmailErrorMessage}
                                    </FormGroup>
                                </Col>
                                 {this.state.customTxnNumbers &&
                                    <Col md={2}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Sales Receipt Number: <span className="star">*</span>
                                            </div>
                                            <FormControl
                                                value={this.state.salesReceiptNumber}
                                                type="text"
                                                placeholder="Sales Receipt Number"
                                                name="salesReceiptNumber"
                                                className={this.state.salesReceiptNumberState}
                                                disabled={this.state.ShowProgressBar}
                                                onChange={event => {
                                                    this.change(event, "salesReceiptNumber", "length", 2);
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        SalesReceipt: {
                                                            ...prevState.SalesReceipt,
                                                            DocNumber: value
                                                        }
                                                    }));
                                                }
                                                }
                                                onBlur={e => this.isValidated()}
                                            />
                                            {this.state.salesReceiptNumberErrorMessage}
                                        </FormGroup>
                                    </Col>
                                 }
                            </Row>
                            <Row>                           
                                <Col md={2}>
                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Sales Receipt Date:
                                        </div>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="salesReceiptDate"
                                                disabled={this.state.ShowProgressBar}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "Date" }}
                                                onChange={value => this.salesReceiptDateHandler}
                                                value={this.state.salesReceiptDate}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <br />
                                    <table
                                        className="table table-bordered table-hover"
                                        id="tab_logic"
                                    >
                                        <thead>
                                            <tr style={{ backgroundColor: "#eeeeee" }}>
                                                <th className="text-right" style={{ width: 35, color: "#727272", fontWeight: "bold" }}> # </th>
                                                <th className="text-left" style={{ width: 300, color: "#727272", fontWeight: "bold" }}> PRODUCT/SERVICE </th>
                                                <th className="text-left" style={{ color: "#727272", fontWeight: "bold" }}> DESCRIPTION </th>
                                                <th className="text-right" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>QTY</th>
                                                <th className="text-right" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>RATE</th>
                                                <th className="text-right" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>AMOUNT</th>
                                                <th className="text-center" style={{ width: 35, color: "#727272", fontWeight: "bold" }}>TAX</th>
                                                {/* <th className="text-center"> Mobile </th> */}
                                                <th style={{ width: 30 }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.rows.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td className="text-right">{idx + 1}</td>
                                                    <td>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="product"
                                                            value={this.state.rows[idx].product}
                                                            isDisabled={this.state.ShowProgressBar}
                                                            onChange={this.handleRowProductNameChange(idx)}
                                                            // onChange={value => {
                                                            //     const rows = [...this.state.rows];
                                                            //     rows[idx].product = value;
                                                            //     this.setState({ rows });
                                                            // }
                                                            // }
                                                            options={this.state.products}
                                                            placeholder="Select Product"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].description}
                                                            type="text"
                                                            placeholder="Description"
                                                            name="description"
                                                            disabled={this.state.ShowProgressBar}
                                                            onChange={this.handleRowDescriptionChange(idx)}
                                                        // onChange={event => {
                                                        //     const rows = this.state.rows;
                                                        //     rows[idx].description = event.target.value;
                                                        //     this.setState({ rows });
                                                        // }
                                                        //}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].quantity}
                                                            disabled={this.state.ShowProgressBar}
                                                            type="number"
                                                            placeholder="Quantity"
                                                            name="quantity"
                                                            style={{ textAlign: "center" }}
                                                            min="1"
                                                            step="1"
                                                            onChange={this.handleRowValueChange(idx)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                            <FormControl
                                                                value={parseFloat(this.state.rows[idx].rate).toFixed(2)}
                                                                disabled={this.state.ShowProgressBar}
                                                                type="number"
                                                                placeholder="Rate"
                                                                name="rate"
                                                                min="0.00"
                                                                style={{ textAlign: "center", padding: "8px 6px" }}
                                                                step="0.01"
                                                                onChange={this.handleRowValueChange(idx)}
                                                            />
                                                        </InputGroup>    
                                                    </td>
                                                    <td>
                                                        <InputGroup>  
                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc", backgroundColor:"#f5f5f5"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                            <FormControl
                                                                value={this.state.rows[idx].amount}
                                                                type="number"
                                                                placeholder="0.00"
                                                                name="amount"
                                                                min="0.00"
                                                                style={{ textAlign: "right", padding: "8px 6px" }}
                                                                readOnly={true}
                                                            />
                                                        </InputGroup>      
                                                    </td>
                                                    <td>
                                                        <CustomCheckbox
                                                                    name="TaxCheckBox"
                                                                    checked={this.state.rows[idx].taxable}
                                                                    isDisabled= {this.state.ShowProgressBar  || this.state.taxRates.length <= 1}  
                                                                    formControlProps={{
                                                                        onChange: this.handleRowValueChange(idx)
                                                                        
                                                                    }}
                                                                />
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top" overlay={deleteButtonToolTip}>
                                                            <Button round twitter onClick={this.handleRemoveSpecificRow(idx)}>
                                                                <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Row>
                                        <Col md={7}>
                                            <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={this.handleAddRow}>
                                                <i className="fa fa-plus"></i> Add Line
                                            </Button>
                                        </Col><div style={{ textAlign: "right" }}>
                                        <Col md={3}>
                                                    <p style={totalLabelStyle}>Subtotal</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.subtotal}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                                </div>
                                     </Row>
                                     <Row>
                                         <Col md={4}>
                                           <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Message on Sales Receipt:
                                        </div>
                                    <FormGroup controlId="formControlsTextarea">

                                        <FormControl
                                            rows="2"
                                            value={this.state.salesReceiptMessage}
                                            componentClass="textarea"
                                            disabled={this.state.ShowProgressBar}
                                            bsClass="form-control"
                                            placeholder="Message to display on Sales Receipt"
                                            maxLength="500"
                                            onChange={event => {
                                                const msg = event.target.value;
                                                this.setState({ salesReceiptMessage: msg });
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    SalesReceipt: {
                                                        ...prevState.SalesReceipt,
                                                        CustomerMemo: {
                                                            ...prevState.SalesReceipt.CustomerMemo,
                                                            value: msg
                                                        }
                                                    }
                                                }));
                                            }}
                                        />
                                    </FormGroup>
                                         </Col>
                                         <Col md={3}>
                                         </Col>
                                         {this.state.taxRates.length > 1 && 
                                         <div>
                                        <Col md={3}>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em", fontSize:"13px" , textAlign: "right", fontWeight:"bold" }}>
                                                Taxable Subtotal  <span style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.taxableSubtotal}`).toFixed(2)).toFixed(2)}</span>
                                                </div>
                                                <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Select tax rate:                                            
                                            </div>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="taxRates"
                                                isDisabled={this.state.ShowProgressBar}
                                                value={this.state.taxrate}
                                                onChange={value => {
                                                    this.setState({ taxrate: value });
                                                    this.handTaxRateChange(value);                                                    
                                                }
                                                }
                                                options={this.state.taxRates}
                                                placeholder="Select Tax Rate"
                                            />
                                        </FormGroup>
                                        </Col>
                                        
                                            <Col md={1}>
                                                <br/><br/>   <br/>                                                           
                                                  <div style={{ textAlign: "right" }}>                                   
                                                   <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.taxAmount}`).toFixed(2)).toFixed(2)}</p>
                                                  </div>
                                            </Col>
                                            </div>
                                         }
                                        <Col md={1}>
                                         </Col>
                                         
                                    </Row>   
                                    <div style={{ textAlign: "right" }}>
                                            <Row>
                                                <Col md={10}>
                                                    <p style={totalLabelStyle}>Total</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10}>
                                                    <p style={totalLabelStyle}>Balance Due</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{ textAlign: "right" }}>
                                        <br />
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelCreateSalesReceipt(e)}>Cancel</Button>
                                        <Button bsStyle="primary" fill wd disabled={!this.state.showTotalBalanceRow  || !this.state.isFormValid } onClick={e => this.createSalesReceiptSubmit(this.state.SalesReceipt)}>Create Sales Receipt</Button>
                                    </div>
                                </Col>
                            </Row>

                            
                            <Modal
                                show={this.state.showNewItemPopupModal}
                                onHide={() => this.setState({ showNewItemPopupModal: false })}
                                bsSize="large"       
                                //dialogClassName="modal-dialog-custom"    
                            >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }
                                <Modal.Header closeButton>
                                    <Modal.Title>Product/Service information</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Item Name: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                        value={this.state.itemInfo.name}
                                                        type="text"
                                                        placeholder="Item Name"
                                                        name="name"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    name: value
                                                                }
                                                            }));
                                                            if(this.verifyLength(value, 2))
                                                            {
                                                                this.setState(prevState => ({
                                                                ...prevState,
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    nameState: "valid",
                                                                    nameErrorMessage: ""
                                                                }
                                                                }));
                                                                this.state.itemInfo.nameState = "valid"
                                                                this.validateNewItem();
                                                            }
                                                            else{
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        nameErrorMessage: (
                                                                            <small className="text-danger">
                                                                                Invalid Item Name.
                                                                            </small>
                                                                        )
                                                                    }
                                                                }));
                                                                this.setState({ isNewItemFormValid: false });
                                                            }                                                    
                                                        }
                                                        }
                                                        onBlur={e => this.validateNewItem()}
                                                />
                                                {this.state.itemInfo.nameErrorMessage}
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    SKU: 
                                                </div>
                                                <FormControl
                                                            value={this.state.itemInfo.sku}
                                                            type="text"
                                                            placeholder="SKU"
                                                            name="sku"
                                                            onChange={event => {
                                                                var value = event.target.value;
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        sku : value
                                                                    }
                                                                }));                                                                                                                  
                                                            }
                                                            }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Price/Rate: 
                                                </div>
                                                <FormControl
                                                            value={parseFloat(this.state.itemInfo.price).toFixed(2) }
                                                            type="number"
                                                            placeholder="Price/Rate"
                                                            name="rate"
                                                            min="0.00"
                                                            style={{ textAlign: "center" }}
                                                            step="0.01"
                                                            onChange={event => {
                                                                var value = event.target.value;
                                                                            this.setState(prevState => ({
                                                                            itemInfo: {
                                                                                ...prevState.itemInfo,
                                                                                price: parseFloat(value).toFixed(2)                                                                            
                                                                        }}));                                                                                                                
                                                                }
                                                            }
                                                        /> 
                                                 </FormGroup>                                                      
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Item Type: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="itemtype"
                                                        value={this.state.itemInfo.selectedItemType}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedItemType: value
                                                                }
                                                            }));
                                                            if(value.value === "Inventory"){
                                                                this.state.itemInfo.isInvenotryItem = true;
                                                                this.state.itemInfo.selectedInventoryAssetAccount = this.state.itemInfo.inventoryAssetAccount[1];
                                                                this.state.itemInfo.quantityOnHand = 0;
                                                                this.state.itemInfo.quantityErrorMessage = "";
                                                            }
                                                            else{
                                                                this.state.itemInfo.isInvenotryItem = false;
                                                                this.state.itemInfo.quantityOnHand = null;
                                                                // this.state.itemInfo.selectedInventoryAssetAccount = null;
                                                                this.state.itemInfo.quantityErrorMessage = "";
                                                            }
                                                         }
                                                        }
                                                        options={this.state.itemInfo.itemType}
                                                        placeholder="Select Item Type"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Category:
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="category"
                                                        value={this.state.itemInfo.selectedCategory}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedCategory: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.category}
                                                        placeholder="Select Category"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.state.itemInfo.isInvenotryItem && 
                                        <Row>
                                            <Col md={3}>
                                              <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        QtyOnHand: <span className="star">*</span>
                                                    </div>
                                                        <FormControl
                                                            value={this.state.itemInfo.quantityOnHand}
                                                            type="number"
                                                            placeholder="Qty on Hand"
                                                            name="quantityOnHand"
                                                            style={{ textAlign: "center" }}
                                                            step="1"
                                                            onChange={event => {
                                                                        var value = event.target.value;
                                                                        this.state.itemInfo.quantityOnHand = value;                                                                
                                                                        this.validateNewItem();
                                                                    }                                                                                                                      
                                                                } 
                                                                onBlur={e => this.validateNewItem()}                                                          
                                                        />
                                                        {this.state.itemInfo.quantityErrorMessage} 
                                                 </FormGroup>        
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        InvStartDate: <span className="star">*</span>
                                                    </div>
                                                    <InputGroup>
                                                        <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                                        <Datetime
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            className="react-calendar"
                                                            inputProps={{ placeholder: "As of Date" }}
                                                            onChange={this.quantityOnHandDateHandler}
                                                            value={this.state.itemInfo.quantityOnHandDate}
                                                        // dateFormat={'YYYY-MM-DD'}
                                                        />
                                                        <InputGroup.Addon></InputGroup.Addon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Asset Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="inventoryAssetAccount"
                                                        value={this.state.itemInfo.selectedInventoryAssetAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedInventoryAssetAccount: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.inventoryAssetAccount}
                                                        placeholder="Select Inventory Asset Account"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        }
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Expense Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="expenseAccount"
                                                        value={this.state.itemInfo.selectedExpenseAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedExpenseAccount: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.expenseAccount}
                                                        placeholder="Select Expense Account"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Income Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="incomeAccount"
                                                        value={this.state.itemInfo.selectedIncomeAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedIncomeAccount: value
                                                                }
                                                            }));
                                                            console.log(this.state.itemInfo.selectedIncomeAccount);

                                                         }
                                                        }
                                                        options={this.state.itemInfo.incomeAccount}
                                                        placeholder="Select Income Account"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                          <Col md={12}>
                                             <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                             Sales Information:
                                            </div>
                                            <FormGroup controlId="formControlsTextarea">

                                                    <FormControl
                                                        rows="2"
                                                        value={this.state.itemInfo.salesInformation}
                                                        componentClass="textarea"
                                                        bsClass="form-control"
                                                        placeholder="Sales Information"
                                                        maxLength="200"
                                                        onChange={event => {
                                                            const msg = event.target.value;
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    salesInformation: msg
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>    
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>                                   
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewItemPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.createItemSubmit(this.state.itemInfo)} disabled={!this.state.isNewItemFormValid}>Save</Button>
                                </Modal.Footer>
                            </Modal>                         


                            <Modal
                                show={this.state.showNewCustomerPopupModal}
                                onHide={() => this.setState({ showNewCustomerPopupModal: false })}
                               // bsSize="large"       
                                dialogClassName="modal-dialog-custom"                   
                            >
                             {this.state.ShowPopupProgressBar &&
                                <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                 
                                <Modal.Header closeButton>
                                    <Modal.Title>Customer Information</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div>
                                    <Row>
                                        <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Customer Name: <span className="star">*</span>
                                            </div>
                                            <FormControl
                                                value={this.state.customerInfo.customerName}
                                                type="text"
                                                placeholder="Customer Name"
                                                name="customerName"
                                                onChange={event => {
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerName: value
                                                        }
                                                    }));
                                                    if(this.verifyLength(value, 2))
                                                    {
                                                        this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            newCustomerNameState: "valid",
                                                            newCustomerNameErrorMessage: ""
                                                        }
                                                    }));
                                                    }
                                                    else{
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                newCustomerNameErrorMessage: (
                                                                    <small className="text-danger">
                                                                        Invalid Customer Name.
                                                                    </small>
                                                                )
                                                            }
                                                        }));
                                                        this.setState({ isNewCustomerFormValid: false });
                                                    }                                                    
                                                }
                                                }
                                                onBlur={e => this.validateNewCustomer()}
                                            />
                                             {this.state.customerInfo.newCustomerNameErrorMessage}
                                        </FormGroup>
                                    </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Customer Email: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.customerEmail}
                                                    placeholder="Customer Email"
                                                    name="newCustomerEmail"
                                                    type="email"
                                                    className={this.state.customerInfo.newCustomerEmailState}
                                                    onChange={event =>
                                                        this.ValidateEmailAddress(event, "newCustomerEmail")
                                                    }
                                                   onBlur={e => this.validateNewCustomer()}
                                                />
                                                {this.state.customerInfo.newCustomerEmailErrorMessage}
                                            </FormGroup>
                                        </Col>                                    
                                        <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Company Name: 
                                            </div>
                                            <FormControl
                                                value={this.state.customerInfo.customerCompany}
                                                type="text"
                                                placeholder="Company Name"
                                                name="customerCompany"
                                              //  className={this.state.customerInfo.customerNameState}
                                                onChange={event => {
                                                    this.change(event, "customerCompany", "length", 3);
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerCompany: value
                                                        }
                                                    }));
                                                }
                                                }
                                               // onBlur={e => this.isValidated()}
                                            />
                                            {/* {this.state.invoiceNumberErrorMessage} */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Phone Number: 
                                            </div>
                                            <FormControl
                                            type="number"
                                            placeholder="Phone Number"
                                          //  className={this.state.user.phonenumberState}
                                            name="customerPhone"
                                            value={this.state.customerInfo.customerPhone}
                                            onChange={event => {
                                                //this.setState({ user: { phonenumber: event.target.value } })
                                                this.change(event, "customerPhone", "length", 7);
                                                var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerPhone: value
                                                        }
                                                    }));
                                            }
                                            }
                                          //  onBlur={e => this.isValidated()}
                                            />
                                            {/* {this.state.phonenumberErrorMessage} */}
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Fax: 
                                            </div>
                                            <FormControl
                                            type="number"
                                            placeholder="Fax Number"
                                          //  className={this.state.user.phonenumberState}
                                            name="customerFax"
                                            value={this.state.customerInfo.customerFax}
                                            onChange={event => {
                                                //this.setState({ user: { phonenumber: event.target.value } })
                                                this.change(event, "customerFax", "length", 7);
                                                var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerFax: value
                                                        }
                                                    }));
                                            }
                                            }
                                            />
                                           
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Website: 
                                            </div>
                                            <FormControl
                                                value={this.state.customerInfo.customerWebsite}
                                                type="text"
                                                placeholder="Website"
                                                name="customerWebsite"
                                              //  className={this.state.customerInfo.customerNameState}
                                                onChange={event => {
                                                    this.change(event, "customerWebsite", "length", 8);
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerWebsite: value
                                                        }
                                                    }));
                                                }
                                                }
                                               // onBlur={e => this.isValidated()}
                                            />
                                            {/* {this.state.invoiceNumberErrorMessage} */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Note:
                                            </div>
                                        <FormGroup controlId="formControlsTextarea">

                                            <FormControl
                                                rows="2"
                                                value={this.state.customerInfo.customerNote}
                                                componentClass="textarea"
                                                bsClass="form-control"
                                                placeholder="Note"
                                                maxLength="200"
                                                onChange={event => {
                                                    const note = event.target.value;
                                                     this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerNote: note
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <legend> </legend> 
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Billing Address: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.address}
                                                    type="text"
                                                    placeholder="Address"
                                                    name="billingAddress"
                                                //  className={this.state.customerInfo.customerNameState}
                                                    onChange={event => {
                                                    // this.change(event, "address", "length", 3);
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                address: value
                                                            }
                                                        }
                                                        
                                                    }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Row>
                                                    <Col md={4}>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Shippng Address: 
                                               
                                                </div>
                                                    </Col>
                                                    <Col md={8} style={{marginTop: "-32px", marginLeft:"-55px"}}>
                                                     <CustomCheckbox
                                                            name="CheckBox"
                                                            checked={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                            //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                            labelText="Same as billing address"
                                                            labelProps={{style: {fontSize: "16px", marginTop:"12px"}}} 
                                                           formControlProps={{
                                                            onChange:(e) => {
                                                              //  this.handleUserInput(e)
                                                              var value = e.target.checked;
                                                              this.setState(prevState => ({
                                                                    ...prevState,
                                                                    customerInfo: {
                                                                        ...prevState.customerInfo,
                                                                        isShippingAddressSameAsBillingAddress: value
                                                                    }
                                                                }));
                                                              }
                                                           }}  
                                                        />
                                                    </Col>
                                                </Row>
                                                
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.address}
                                                    type="text"
                                                    placeholder="Address"
                                                    name="shippingAddress"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                //  className={this.state.customerInfo.customerNameState}
                                                    onChange={event => {
                                                    // this.change(event, "address", "length", 3);
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                address: value
                                                            }
                                                        }                                                    
                                                    }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Country: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.country}
                                                    type="text"
                                                    placeholder="Billing Country"
                                                    name="billingCountry"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                country: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    State: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.state}
                                                    type="text"
                                                    placeholder="Billing State"
                                                    name="billingState"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                state: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }                                               
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Country: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.country}
                                                    type="text"
                                                    placeholder="Shipping Country"
                                                    name="shippingCountry"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                country: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }                                                
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    State: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.state}
                                                    type="text"
                                                    placeholder="Shipping State"
                                                    name="shippingState"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                state: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                     <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    City: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.city}
                                                    type="text"
                                                    placeholder="Billing City"
                                                    name="billingCity"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                city: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Postal Code: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.postalCode}
                                                    type="text"
                                                    placeholder="Billing Postal Code"
                                                    name="billingPostalCode"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                postalCode: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    City: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.city}
                                                    type="text"
                                                    placeholder="Shipping City"
                                                    name="shippingCity"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                city: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Postal Code:  
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.postalCode}
                                                    type="text"
                                                    placeholder="Shipping Postal Code"
                                                    name="shippingPostalCode"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                postalCode: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                </Modal.Body>
                                <Modal.Footer>
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewCustomerPopupModal: false })}>Close</Button>
                                        <Button bsStyle="primary" fill wd onClick={e => this.createCustomerSubmit(this.state.customerInfo)} disabled={!this.state.isNewCustomerFormValid}>Save</Button>                                   
                                    </Modal.Footer>                             
                             </Modal>
 

                            <Modal
                                show={this.state.showPaymentPopupModal}
                                onHide={() => this.setState({ showPaymentPopupModal: false })}
                               // bsSize="small"       
                                dialogClassName="modal-dialog-payment-custom"                   
                            >
                             {this.state.ShowPopupProgressBar &&
                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>

                            }
                                <Modal.Header closeButton>
                                    <Modal.Title>Total Amount: ${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}</Modal.Title>
                                </Modal.Header>
                                {this.state.processorType == "nmi" && 
                                 <form onSubmit={this.handleSubmit}>
                                    <Modal.Body>
                                        <div>
                                            <Row>
                                                <Col md={3}>
                                                    <img src={this.state.paymentIcon} width="70px" height="42px" alt="..." />
                                                </Col>                                            
                                                <Col md={4}>
                                                    <Radio
                                                        number="creditcard"
                                                        option="creditcard"
                                                        name="radio"
                                                        disabled={this.state.isPaid}
                                                        onChange={this.handleRadio}
                                                        checked={this.state.IsCreditCard}
                                                        className="PrivateSwitchBase-input-538"
                                                        label="Credit Card"
                                                    />
                                                </Col>
                                                <Col md={5}>
                                                    <Radio
                                                        number="ach"
                                                        option="ach"
                                                        name="radio"
                                                        disabled={this.state.isPaid}
                                                        onChange={this.handleRadio}
                                                        checked={this.state.IsACH}
                                                        label="eCheck (ACH)"
                                                    />
                                                </Col>
                                            </Row>
                                            <br />
                                        
                                                <Card
                                                content={
                                                    <div>                                                       
                                                        {this.state.StatusCode === 0 &&
                                                            <p style={styles.successResponse}>{this.state.StatusMessage}</p>
                                                        }
                                                        {this.state.StatusCode != 0 &&
                                                            <p style={styles.errorResponse}>{this.state.StatusMessage}</p>
                                                        }
                                                        <Row>
                                                            <Col xs={12} sm={12} md={6}>
                                                                <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        First Name
                                                                </InputLabel>
                                                                <CustomInput                                                                  
                                                                        isRequired={true}
                                                                        id="firstName"
                                                                        name="firstName"
                                                                        value={this.state.paymentInfo.firstName}
                                                                        showErrorMessage={!this.state.paymentInfo.firstNameValid}
                                                                        errorMessage="First name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid,
                                                                            //disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                            error: !this.state.paymentInfo.firstNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={6}>
                                                                <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Last Name
                                                                </InputLabel>
                                                                <CustomInput                                                                  
                                                                        isRequired={true}
                                                                        id="lastName"
                                                                        name="lastName"
                                                                        value={this.state.paymentInfo.lastName}
                                                                        showErrorMessage={!this.state.paymentInfo.lastNameValid}
                                                                        errorMessage="Last name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid,
                                                                            //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                            error: !this.state.paymentInfo.lastNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                            </Col>
                                                        </Row>
                                                        <div style={{ display: this.state.IsCreditCard ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Card Holder's Name
                                                                    </InputLabel>
                                                                    <CustomInput
                                                                        isRequired={true}
                                                                        id="CardHolderName"
                                                                        name="CardHolderName"
                                                                        value={this.state.paymentInfo.CardHolderName}
                                                                        showErrorMessage={!this.state.paymentInfo.CardHolderNameValid}
                                                                        errorMessage="Card holder name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid,                                                                            
                                                                            error: !this.state.paymentInfo.CardHolderNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            //onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                           
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} >
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Card Number
                                                                                </InputLabel>
                                                                    <div id="ccnumber"/>
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="creditCardErrorMessage" id="creditCardErrorMessage">Card number is required                                                                                                                                    
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        MM/YY
                                                                                </InputLabel>
                                                                    <div id="ccexp" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="expErrorMessage" id="expErrorMessage">Expiry date is required                                                                                                                                    
                                                                    </div>                                                                 
                                                                </Col>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel
                                                                        htmlFor="simple-select"
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        CVV
                                                                                </InputLabel>
                                                                    <div id="cvv" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="cvvErrorMessage" id="cvvErrorMessage">CVV date is required                                                                                                                                  
                                                                    </div> 
                                                                    
                                                                </Col>
                                                            </Row>
                                                            
                                                            
                                                          
                                                        </div>
                                                        <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Account Name
                                                                                </InputLabel>
                                                                    <div id="accountname" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="accountNameErrorMessage" id="accountNameErrorMessage">Account name is required                                                                                                                                  
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Account Number
                                                                                </InputLabel>
                                                                    <div id="accountnumber" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="accountNumberErrorMessage" id="accountNumberErrorMessage">Account number is required                                                                                                                                  
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Routing Number
                                                                                </InputLabel>
                                                                    <div id="routingnumber" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="routingNumberErrorMessage" id="routingNumberErrorMessage">Routing number is required                                                                                                                                  
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={8}>
                                                                <InputLabel
                                                                    style={styles.dropDownFontStyle}
                                                                >
                                                                    Address
                                                                </InputLabel>
                                                                <CustomInput
                                                                    //  labelText="Address"
                                                                    isRequired={true}
                                                                    id="address"
                                                                    name="Address"
                                                                    value={this.state.paymentInfo.Address}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        disabled: this.state.isPaid,
                                                                        onChange: (e) => { this.handleUserInput(e) }
                                                                    }}
                                                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                    labelProps={{ style: { fontWeight: "bold" } }}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={4}>
                                                                <InputLabel
                                                                    style={styles.dropDownFontStyle}
                                                                >
                                                                    Postal Code
                                                                </InputLabel>
                                                                <CustomInput
                                                                    id="postal-code"
                                                                    name="PostalCode"
                                                                    value={this.state.paymentInfo.PostalCode}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        disabled: this.state.isPaid,
                                                                        onChange: (e) => { this.handleUserInput(e) }
                                                                    }}
                                                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                    labelProps={{ style: { fontWeight: "bold" } }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <div style={{marginTop:"20px"}}>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <CustomCheckbox
                                                                    name="CheckBox"
                                                                    checked={this.state.paymentInfo.CheckBoxChecked}
                                                                    isDisabled= {this.state.isPaid}
                                                                    //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                                    labelText={"I agree to pay ($" + parseFloat(this.state.total).toFixed(2) + ") according to the card/eCheck issuer agreement."}
                                                                    labelProps={{ style: { fontSize: "15px" } }}
                                                                    formControlProps={{
                                                                        onChange: (e) => { this.handleUserInput(e) }
                                                                    }}
                                                                />

                                                            </Col>
                                                        </Row>
                                                        </div>
                                                    </div>
                                                }
                                                />
                                        
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Row>
                                        <Col xs={12} sm={12} md={6}>
                                        <Button bsStyle="primary" style={{ width: "100%" }} wd 
                                        onClick={() => {
                                                this.setState({ showPaymentPopupModal: false });
                                                if(this.state.isPaid)
                                                {
                                                    window.location.reload();
                                                }
                                            }
                                        }
                                        >Close</Button>
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                        <Button bsStyle="primary" style={{ width: "100%" }} fill type="submit" disabled={!this.state.paymentInfo.FormValid || this.state.isSubmitting || this.state.isPaid}>
                                                                    CHARGE ${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}
                                        </Button>                                  
                                        </Col>
                                        </Row>
                                    </Modal.Footer>   
                                 </form>                          
                                }
                                {this.state.processorType == "cardconnect" &&
                                    <div>
                                    <Modal.Body>
                                        <div>
                                            <Row>
                                                <Col md={3}>
                                                    <img src={this.state.paymentIcon} width="70px" height="42px" alt="..." />
                                                </Col>                                            
                                                <Col md={4}>
                                                    <Radio
                                                        number="creditcard"
                                                        option="creditcard"
                                                        name="radio"
                                                        disabled={this.state.isPaid}
                                                        onChange={this.handleRadio}
                                                        checked={this.state.IsCreditCard}
                                                        className="PrivateSwitchBase-input-538"
                                                        label="Credit Card"
                                                    />
                                                </Col>
                                                <Col md={5}>
                                                    <Radio
                                                        number="ach"
                                                        option="ach"
                                                        name="radio"
                                                        disabled={this.state.isPaid}
                                                        onChange={this.handleRadio}
                                                        checked={this.state.IsACH}
                                                        label="eCheck (ACH)"
                                                    />
                                                </Col>
                                            </Row>
                                            <br />
                                        
                                                <Card
                                                content={
                                                    <div>                                                       
                                                        {this.state.StatusCode === 0 &&
                                                            <p style={styles.successResponse}>{this.state.StatusMessage}</p>
                                                        }
                                                        {this.state.StatusCode != 0 &&
                                                            <p style={styles.errorResponse}>{this.state.StatusMessage}</p>
                                                        }                                                       
                                                        <div style={{ display: this.state.IsCreditCard ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Card Holder's Name
                                                                    </InputLabel>
                                                                    <CustomInput
                                                                        isRequired={true}
                                                                        id="CardHolderName"
                                                                        name="CardHolderName"
                                                                        value={this.state.paymentInfo.CardHolderName}
                                                                        showErrorMessage={!this.state.paymentInfo.CardHolderNameValid}
                                                                        errorMessage="Card holder name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid,                                                                            
                                                                            error: !this.state.paymentInfo.CardHolderNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            //onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                                </Col>
                                                               </Row>                                                           
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12}>
                                                                            <FormGroup
                                                                                controlId='tokenEvent'
                                                                                style={{marginLeft:"-6px", marginBottom:"0px"}}
                                                                            >
                                                                                <iframe
                                                                                    title='CardPointeTokenizer'
                                                                                    id='tokenframe'
                                                                                    name='tokenframe'
                                                                                    src={iFrameUrl}
                                                                                    frameBorder='0'
                                                                                    scrolling='no'
                                                                                    width='100%'
                                                                                    height='180'
                                                                                    onLoad={this.handleTokenEvent}
                                                                                >
                                                                                </iframe>
                                                                                <input type='hidden' name='token' id='mytoken' />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                              
                                                           
                                                        </div>
                                                        <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Account Name
                                                                    </InputLabel>                                  
                                                                    <CustomInput
                                                                        isRequired={true}
                                                                        id="AccountName"
                                                                        name="AccountName"
                                                                        value={this.state.paymentInfo.AccountName}
                                                                        showErrorMessage={!this.state.paymentInfo.AccountNameValid}
                                                                        errorMessage="Account name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid,                                                                            
                                                                            error: !this.state.paymentInfo.AccountNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                {/* <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}>
                                                                         Routing And Account No. <small>(Default Format: RoutingNo/AccountNo e.g 036001808/123456789)</small>
                                                                    </InputLabel>  
                                                                    <FormGroup
                                                                        controlId='tokenEvent'
                                                                        style={{ marginLeft: "-6px", marginBottom: "0px" }}
                                                                    >
                                                                        <iframe
                                                                            title='CardPointeTokenizer'
                                                                            id='achtokenframe'
                                                                            name='achtokenframe'
                                                                            src={iFrameUrl}
                                                                            frameBorder='0'
                                                                            scrolling='no'
                                                                            width='100%'
                                                                            height='33'
                                                                            onLoad={this.handleTokenEvent}
                                                                        >
                                                                        </iframe>
                                                                        <input type='hidden' name='token' id='mytoken' />
                                                                    </FormGroup>
                                                                </Col>
                                                             */}
                                                            <Col xs={12} sm={12} md={12}>
                                                            <InputLabel style={styles.dropDownFontStyle} >
                                                                Account Number
                                                            </InputLabel>
                                                            <CustomInput
                                                                //  labelText="Address"
                                                                isRequired={true}
                                                                id="accountnumber"
                                                                name="AccountNumber"
                                                                value={this.state.paymentInfo.AccountNumber}
                                                                type='number'
                                                                showErrorMessage={!this.state.paymentInfo.AccountNumberValid}
                                                                errorMessage="Account Number is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.isProfileSelected,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <InputLabel style={styles.dropDownFontStyle} >
                                                                Routing Number
                                                            </InputLabel>
                                                            <CustomInput
                                                                //  labelText="Address"
                                                                isRequired={true}
                                                                id="routingnumber"
                                                                name="RoutingNumber"
                                                                value={this.state.paymentInfo.RoutingNumber}
                                                                type="number"
                                                                showErrorMessage={!this.state.paymentInfo.RoutingNumberValid}
                                                                errorMessage="Routing Number is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.isProfileSelected,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                            </Row>
                                                            
                                                        </div>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={8}>
                                                                <InputLabel
                                                                    style={styles.dropDownFontStyle}
                                                                >
                                                                    Address
                                                                </InputLabel>
                                                                <CustomInput
                                                                    //  labelText="Address"
                                                                    isRequired={true}
                                                                    id="address"
                                                                    name="Address"
                                                                    value={this.state.paymentInfo.Address}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        disabled: this.state.isPaid,
                                                                        onChange: (e) => { this.handleUserInput(e) }
                                                                    }}
                                                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                    labelProps={{ style: { fontWeight: "bold" } }}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={4}>
                                                                <InputLabel
                                                                    style={styles.dropDownFontStyle}
                                                                >
                                                                    Postal Code
                                                                </InputLabel>
                                                                <CustomInput
                                                                    id="postal-code"
                                                                    name="PostalCode"
                                                                    value={this.state.paymentInfo.PostalCode}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        disabled: this.state.isPaid,
                                                                        onChange: (e) => { this.handleUserInput(e) }
                                                                    }}
                                                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                    labelProps={{ style: { fontWeight: "bold" } }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <div style={{marginTop:"20px"}}>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <CustomCheckbox
                                                                    name="CheckBox"
                                                                    checked={this.state.paymentInfo.CheckBoxChecked}
                                                                    isDisabled= {this.state.isPaid}
                                                                    //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                                    labelText={"I agree to pay ($" + parseFloat(this.state.total).toFixed(2) + ") according to the card/eCheck issuer agreement."}
                                                                    labelProps={{ style: { fontSize: "15px" } }}
                                                                    formControlProps={{
                                                                        onChange: (e) => { this.handleUserInput(e) }
                                                                    }}
                                                                />

                                                            </Col>
                                                        </Row>
                                                        </div>
                                                    </div>
                                                }
                                                />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Row>
                                        <Col xs={12} sm={12} md={6}>
                                        <Button bsStyle="primary" style={{ width: "100%" }} wd 
                                        onClick={() => {
                                            this.setState({ showPaymentPopupModal: false });
                                            if( this.state.isPaid){
                                                window.location.reload();
                                            }
                                            }
                                        }
                                        >Close</Button>
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                        <Button bsStyle="primary" style={{ width: "100%" }} fill onClick={e => this.handleSubmit(e)} type="submit" disabled={!this.state.paymentInfo.FormValid || this.state.isSubmitting || this.state.isPaid}>
                                                                    CHARGE ${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}
                                        </Button>                                  
                                        </Col>
                                        </Row>
                                    </Modal.Footer>
                                     </div>
                                }
                             </Modal>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default CreateSalesReceipt;