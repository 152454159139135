import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    InputGroup,
    Modal,
    Alert
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import Select from "react-select";
import SelectCreatable from "react-select/creatable";
import Datetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import creditCardIcon from "assets/img/icons/creditcard.png";
import NetworkService from "services/NetworkService";
import { StorageEnum } from "utils/constant.js";
import SessionTimeout from 'components/SessionTimeout';
import Common from "utils/Common.js";
import RecurringModal from "views/Components/CreateRecurringModal.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import InputLabel from "@material-ui/core/InputLabel";

const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        fontSize: "14px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
            color: "green",//successColor[2], 
            fontSize: "17px",
            fontWeight: "bold",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
            marginBottom: "0px",
            lineHeight: "1.8",
        },
        errorResponse: {
            color: "red",//dangerColor[0], 
            fontSize: "17px",
            fontWeight: "bold",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
            marginBottom: "0px",
            lineHeight: "1.8",
        },
}   

class CreateRecurring extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            processorType: "nmi",
            IsTokenValid: false,
            showNewItemPopupModal: false,
            showNewCustomerPopupModal: false,
            ShowPopupProgressBar: false,
            isFormValid: false,
            errorMessage: "",
            errorCode: 1,
            ShowProgressBar: false,
            customerEmailState: "",
            salesReceiptMessage: "",
            salesReceiptNumberState: "",
            salesReceiptNumberErrorMessage: "",
            customers: [],
            products: [],
            rows: [
                {
                    product: "",
                    description: "",
                    quantity: 1,
                    rate: parseFloat(0).toFixed(2),
                    amount: parseFloat(0).toFixed(2),
                    taxable: false,
                }
            ],
            taxRates: [
            ],    
            taxcodes:[                
            ],
            salesReceiptDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            customer: "",
            customerEmail: "",
            customerEmailErrorMessage: "",
            salesReceiptNumber: "",
            taxrate: "",
            total: 0.00,
            subtotal: 0.00,
            taxableSubtotal: 0.00,
            taxAmount: 0.00,
            Amount: 0.00,
            showTotalBalanceRow: false,
            customTxnNumbers: true,
            SalesReceipt: {
                TotalAmt: 0,
                TxnDate: "",
                DocNumber: "",
                Line: [
                    {
                        Amount: 0,
                        DetailType: "",
                        Description: "",
                    }
                ],
                BillEmail: {
                    Address: ""
                },
                BillAddr: {
                    Line1: "",
                    PostalCode: ""
                },
                CustomerMemo: {
                    value: ""
                },
                CustomerRef: {
                    value: "",
                    name: ""
                },
                TxnTaxDetail: {
                    TotalTax:0.00,
                    TxnTaxCodeRef: {
                        value:"",
                        name:""
                    },
                    TaxLine: [
                        {
                            DetailType: "TaxLineDetail", //TaxLineDetail
                            Amount: 0,
                            TaxLineDetail: {
                                TaxRateRef: {
                                   value: 0,
                                },
                                PercentBased: true,
                                TaxPercent: 0,
                                NetAmountTaxable: 0
                            }
                        }
                    ]
                }
            },
            itemInfo:{
                name: "",
                sku: "",
                price: 0.00,
                itemType:[        
                     { value: "", label: "Select Type", isDisabled: true },           
                     { value: "Service", label: "Service" },
                     { value: "NonInventory", label: "Non Inventory" },
                     { value: "Inventory", label: "Inventory" }
                ],
                selectedItemType: { value: "Service", label: "Service" },
                category:[],
                selectedCategory: {value: "", label: ""},
                salesInformation:"",
                expenseAccount:[],
                selectedExpenseAccount: { value: "", label: "" },
                incomeAccount:[],
                selectedIncomeAccount:  {value: "", label: ""},
                inventoryAssetAccount:[],
                selectedInventoryAssetAccount: {value: "", label: ""},
                quantityOnHand: 0,
                quantityOnHandDate: new Date(),
                quantityOnHandDateServer: "",
                nameState: "",
                nameErrorMessage: "",
                quantityErrorMessage: "",
                quantityState: "",
                isInvenotryItem: false,
                taxable: false
            },
            IsCreditCard: true,
            IsDebitCard: false,
            IsACH: false,
            IsCreditCardEnabled: true,
            IsACHEnabled: true,
            isSubmitting: false,
            paymentIcon: creditCardIcon,
            StatusCode: false,
            StatusMessage: "",
            paymentInfo: {
                CardHolderName: '',
                firstName: '',
                lastName: '',
                CardNumber: '',
                CardExpiry: '',
                GatewayExpiryDate: '',
                CardCVV: '',
                AccountNumber: '',
                AccountType: 'checking',
                RoutingNumber: '',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: true,
                CardHolderNameValid: true,
                firstNameValid: true,
                lastNameValid: true,
                AccountNameValid: true,
                AccountNumberValid: true,
                RoutingNumberValid: true,
                CardNumberValid: true,
                CardExpiryValid: true,
                CardCVVValid: true,
                AmountValid: true,
                FormErrors: { firstName: '', lastName: '', CardHolderName: '', AccountName: '' },                
            },
            customerInfo: {
                customerName: "",
                customerEmail: "",
                customerCompany: "",
                customerPhone: "",
                customerFax: "",
                customerWebsite: "",
                customerNote: "",
                isShippingAddressSameAsBillingAddress: true,
                billingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""
                },
                shippingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""    
                },
                newCustomerNameState: "",
                newCustomerNameErrorMessage: "",
                newCustomerEmailState: "",
                newCustomerEmailErrorMessage: ""
            },
            isNewCustomerFormValid: false,
            isNewItemFormValid: false,

            emvToken: '',
            expiryDate: '',
            hostName: '',
            hostPort: '',
            copilotURL: '',
            proceesorSettingError: "",
            isAuthenticated: true,
            ccProfiles: [],
            achProfiles: [],
            isProfileSelected: false,

            isCreateRecurringEnabled: false,
            ProfileParams:{},
            RecurringParams:{},
            CustomerProfileInfo:{},
            showCreateRecurringModal: false,
            alert: null,
            isAuthenticated: true,
            showRecurringButton: "",
            ConvFee: "",
            is_percentage:  false,
            ConvFeeAmount: 0.00,
        };

        this.salesReceiptDateHandler = this.salesReceiptDateHandler.bind(this);
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }
    
    handleOpenNewItemPopup(e, name) {
        if(name != "")
        {
            this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameState:  "valid",
                        nameErrorMessage: ""
                    }
                })); 
        }
        this.state.itemInfo.name = name;
        this.state.itemInfo.sku = "";
        this.state.itemInfo.price = 0.00;   
        this.state.itemInfo.selectedItemType = { value: "Service", label: "Service" };
        this.state.itemInfo.salesInformation = "";               
        this.state.itemInfo.quantityOnHand = 0;
        this.state.itemInfo.quantityOnHandDate = new Date();
        this.state.itemInfo.quantityOnHandDateServer = "";
        this.state.itemInfo.nameState = "";
        this.state.itemInfo.nameErrorMessage = "";
        this.state.itemInfo.quantityErrorMessage = "";
        this.state.itemInfo.quantityState = "";
        this.state.itemInfo.isInvenotryItem = false;
        this.state.itemInfo.taxable = false;
        this.state.isNewItemFormValid = false;
        this.setState({showNewItemPopupModal: true}); 
        this._getAccountRequest();
    }

    handleOpenNewCustomerPopup(e, customerName){   
        this.setState({ShowPopupProgressBar:false});
        this.state.customerInfo.customerName = customerName;
        this.state.customerInfo.customerEmail =  "";
        this.state.customerInfo.customerCompany =  "";
        this.state.customerInfo.customerPhone =  "";
        this.state.customerInfo.customerFax =  "";
        this.state.customerInfo.customerWebsite =  "";
        this.state.customerInfo.customerNote =  "";
        this.state.customerInfo.shippingAddress.address =  "";
        this.state.customerInfo.billingAddress.address = "";
        this.state.customerInfo.shippingAddress.country =  "";
        this.state.customerInfo.billingAddress.country =  "";
        this.state.customerInfo.shippingAddress.state =  "";
        this.state.customerInfo.billingAddress.state=  "";
        this.state.customerInfo.shippingAddress.city =  "";
        this.state.customerInfo.billingAddress.city =  "";
        this.state.customerInfo.shippingAddress.postalCode =  "";
        this.state.customerInfo.billingAddress.postalCode =  "";
        this.state.customerInfo.newCustomerNameState = "";
        this.state.customerInfo.newCustomerNameErrorMessage = "";
        this.state.customerInfo.newCustomerEmailState = "";
        this.state.customerInfo.newCustomerEmailErrorMessage = "";
        this.state.isNewCustomerFormValid = false;
        this.setState({showNewCustomerPopupModal: true});
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
           
        }else{
            const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
            this.setState({processorType:data_tokenize.PPType.toLowerCase()});
            
            let ach = (localStorage.getItem("is_ach_enabled") == "true")           
            let creditcard = (localStorage.getItem("is_creditcard_enabled") == "true")      
            this.setState({IsACH: ach, IsACHEnabled: ach })
            this.setState({IsCreditCard: creditcard, IsCreditCardEnabled: creditcard })
            if(ach && creditcard){
                this.setState({IsACH: false })
            }
        }

        document.getElementById("loaderDiv").style.display = "none";
        this.SetSalesReceiptDates();
        if(localStorage.getItem("taxcodes") == null || localStorage.getItem("taxcodes") == "null" || localStorage.getItem("taxcodes") == "[]")
        {
           this._getTaxCodeRequest(); 
        }
        else{
            this.GetSetTaxCodes();
        }
         //Not using so commented. when required uncomment it. 
        this.GetSetPreferences(); 
        this.GetSetItems(); 
        this.GetSetCustomers();
        this.GetConvFee();    
        var data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        if (data_tokenize == null || data_tokenize == undefined) {
            this.setState({proceesorSettingError: Common.getProcessorSettingsErrorMessage()})   
        }          
        
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
         const app_id = 2;
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } 
            }).catch(error => {  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
        {
            this.setState({ ConvFee: convfee[0] });          
        } 
    }

    /** HANDLER */
    salesReceiptDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ salesReceiptDate: date.format("MM/DD/YYYY") });            
            this.setState(prevState => ({
                SalesReceipt: {
                    ...prevState.SalesReceipt,
                    TxnDate: date.format("YYYY-MM-DD")
                }
            }));
        }
    };

    SetSalesReceiptDates() {
        this.setState(prevState => ({
            SalesReceipt: {
                ...prevState.SalesReceipt,
                TxnDate: this.formatDate(this.state.salesReceiptDate)
            }
        }));

         this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                quantityOnHandDateServer: this.formatDate(this.state.itemInfo.quantityOnHandDate)
            }
        }));
    }

    quantityOnHandDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState(prevState => ({
                itemInfo: {
                    ...prevState.itemInfo,
                    quantityOnHandDate: date.format("MM/DD/YYYY")
                }
            }));
            this.setState(prevState => ({
                itemInfo: {
                    ...prevState.itemInfo,
                    quantityOnHandDateServer: date.format("YYYY-MM-DD")
                }
            }));
        }
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    GetSetItems(){
        if(localStorage.getItem("is_qbo_recently_connected") == "true" && (localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]")){
            this._getItemListingRequest(); 
        }
        else if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
            setTimeout(() => {
                if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                    setTimeout(() => {
                       if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                            setTimeout(() => {
                                if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                                    setTimeout(() => {
                                       if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                                            setTimeout(() => {
                                                if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                                                    setTimeout(() => {
                                                        if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                                                            setTimeout(() => {
                                                                if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                                                                    setTimeout(() => {
                                                                        if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                                                                            setTimeout(() => {
                                                                               if(localStorage.getItem("items") == null || localStorage.getItem("items") == "null" || localStorage.getItem("items") == "[]"){
                                                                                    setTimeout(() => {
                                                                                        this._getItemListingRequest();     
                                                                                    }, 5000);
                                                                                }
                                                                                else{
                                                                                    this.setItemsResult(JSON.parse(localStorage.getItem("items")), true);  
                                                                                }
                                                                            }, 5000);
                                                                        }
                                                                        else{
                                                                            this.setItemsResult(JSON.parse(localStorage.getItem("items")), true);  
                                                                        }
                                                                    }, 5000);
                                                                }
                                                                else{
                                                                    this.setItemsResult(JSON.parse(localStorage.getItem("items")), true); 
                                                                }
                                                            }, 5000);
                                                        }
                                                        else{
                                                            this.setItemsResult(JSON.parse(localStorage.getItem("items")), true); 
                                                        }
                                                    }, 5000);
                                                }
                                                else{
                                                    this.setItemsResult(JSON.parse(localStorage.getItem("items")), true); 
                                                }
                                            }, 5000);
                                        }
                                        else {
                                            this.setItemsResult(JSON.parse(localStorage.getItem("items")), true); 
                                        }
                                    }, 5000);
                                }
                                else{
                                    this.setItemsResult(JSON.parse(localStorage.getItem("items")), true); 
                                }
                            }, 2000);
                        }
                        else {
                            this.setItemsResult(JSON.parse(localStorage.getItem("items")), true);     
                        }
                    }, 2000);
                }
                else{                    
                    this.setItemsResult(JSON.parse(localStorage.getItem("items")), true);    
                }
            }, 2000);
        }    
        else{
             this.setItemsResult(JSON.parse(localStorage.getItem("items")), true); 
        }
    }

    GetSetPreferences(){
        if(localStorage.getItem("is_qbo_recently_connected") == "true" && (localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "[]")){
            this._getPreferencesRequest();     
        }
        else if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
            setTimeout(() => {
                if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                    setTimeout(() => {
                       if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                            setTimeout(() => {
                                if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                                    setTimeout(() => {
                                       if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                                            setTimeout(() => {
                                                if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                                                    setTimeout(() => {
                                                        if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                                                            setTimeout(() => {
                                                                if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                                                                    setTimeout(() => {
                                                                        if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                                                                            setTimeout(() => {
                                                                               if(localStorage.getItem("preferences") == null || localStorage.getItem("preferences") == "null" || localStorage.getItem("preferences") == "[]"){
                                                                                    setTimeout(() => {
                                                                                        this._getPreferencesRequest();     
                                                                                    }, 5000);
                                                                                }
                                                                                else{
                                                                                    this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true);  
                                                                                }
                                                                            }, 5000);
                                                                        }
                                                                        else{
                                                                            this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true);  
                                                                        }
                                                                    }, 5000);
                                                                }
                                                                else{
                                                                    this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true); 
                                                                }
                                                            }, 5000);
                                                        }
                                                        else{
                                                            this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true); 
                                                        }
                                                    }, 5000);
                                                }
                                                else{
                                                    this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true); 
                                                }
                                            }, 5000);
                                        }
                                        else {
                                            this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true); 
                                        }
                                    }, 5000);
                                }
                                else{
                                    this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true); 
                                }
                            }, 2000);
                        }
                        else {
                            this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true);     
                        }
                    }, 2000);
                }
                else{                    
                    this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true);    
                }
            }, 2000);
        }    
        else{
            console.log(JSON.parse(localStorage.getItem("preferences")));
            console.log(localStorage.getItem("preferences"));
            this.setPreferencesResult(JSON.parse(localStorage.getItem("preferences")), true); 
        }
    }

    GetSetCustomers(){
        if(localStorage.getItem("is_qbo_recently_connected") == "true" && (localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]")){
            this._getCustomerRequest(); 
        }
        else if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
            setTimeout(() => {
                if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null  || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                    setTimeout(() => {
                       if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                            setTimeout(() => {
                                if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                                    setTimeout(() => {
                                       if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                                            setTimeout(() => {
                                                if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                                                    setTimeout(() => {
                                                        if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                                                            setTimeout(() => {
                                                                if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                                                                    setTimeout(() => {
                                                                        if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                                                                            setTimeout(() => {
                                                                               if(localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == null || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "null" || localStorage.getItem(StorageEnum.QBO_CUSTOMERS) == "[]"){
                                                                                    setTimeout(() => {
                                                                                        this._getCustomerRequest();     
                                                                                    }, 5000);
                                                                                }
                                                                                else{
                                                                                    this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                                                                                }
                                                                            }, 5000);
                                                                        }
                                                                        else{
                                                                            this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                                                                        }
                                                                    }, 5000);
                                                                }
                                                                else{
                                                                    this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                                                                }
                                                            }, 5000);
                                                        }
                                                        else{
                                                            this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                                                        }
                                                    }, 5000);
                                                }
                                                else{
                                                    this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                                                }
                                            }, 5000);
                                        }
                                        else {
                                            this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                                        }
                                    }, 5000);
                                }
                                else{
                                    this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                                }
                            }, 2000);
                        }
                        else {
                            this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                        }
                    }, 2000);
                }
                else{
                    this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);    
                }
            }, 2000);
        }
        else{
            this.setCustomerResult(JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS)), true);         
        }
    }

    GetSetTaxCodes() {
        this.state.taxcodes = JSON.parse(localStorage.getItem("taxcodes"));
        this.setTaxRatesResult(JSON.parse(localStorage.getItem("taxrates")), true)         
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState({ [stateName]: value });

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handTaxRateChange(taxRate){

        const rows = [...this.state.rows];
        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(taxRate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        });  

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));
        
        if(this.state.ConvFee != "" && this.state.IsCreditCard && this.state.ProfileParams.profile_id != undefined && this.state.ProfileParams.profile_id != null && this.state.RecurringParams.TemplateName != ""){
            let convFeeAmount = 0.00;
            if(this.state.ConvFee.is_percentage){
                convFeeAmount = (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) 
                this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) });
            }
            else{
                convFeeAmount =  parseFloat(this.state.ConvFee.value)
                this.setState({ ConvFeeAmount : parseFloat(this.state.ConvFee.value)});
            }                                   
            this.state.total = parseFloat(parseFloat(this.state.total) + convFeeAmount).toFixed(2);
        }

        var taxRefCode = {
                        value : taxRate.value,
                        name : taxRate.name
                      } 

        const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
        txnTaxDetail.TxnTaxCodeRef = taxRefCode;
        txnTaxDetail.TotalTax = this.state.taxAmount;
        var detailType = txnTaxDetail.TaxLine[0].DetailType; 
        txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
        txnTaxDetail.TaxLine[0][detailType].TaxRateRef = taxRefCode;
        txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(taxRate.taxValue);
        txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

        this.setState({ txnTaxDetail });
        

        const lineItems = [...this.state.SalesReceipt.Line];
        lineItems.forEach(lineItem => {
             var detailType = lineItem.DetailType;
             if(lineItem.DetailType != ""){
                     taxRefCode = { value: "NON" }
                      lineItem[detailType].TaxCodeRef = taxRefCode;
            }
        });    

        lineItems.forEach(lineItem => {
             var detailType = lineItem.DetailType;
            rows.forEach(element => {
                if (lineItem.DetailType != "" && element.taxable && lineItem[detailType].ItemRef.value === element.product.value && parseFloat(element.amount) === parseFloat(lineItem.Amount)) {
                    taxRefCode = { value: "TAX" }                 
                   lineItem[detailType].TaxCodeRef = taxRefCode;
                   return false;
                }
             });           
        });  
       this.setState({ lineItems });
       this.setState({ rows });        
        
    }

    ValidateEmailAddress(event, name) {
        var value = "";
        if(name === "customerEmail" || name === "newCustomerEmail")
        {
            value = event.target.value;
        }
        else if(name === "customers"){
            value = event.PrimaryEmailAddr.Address;
        }
        
        if(name === "newCustomerEmail"){
             this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmail:  value
                    }
                }));
        }
        else{
            this.setState({ customerEmail: value });
        }

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            if(name === "newCustomerEmail"){
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailErrorMessage: (
                            <small className="text-danger">
                                Invalid email address.
                            </small>
                        )
                    }
                }));
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState: "error"
                    }
                }));                
               
                this.setState({ isNewCustomerFormValid: false });                            
            }
            else{
                if(value == "") {
                    this.setState({ customerEmailState: "valid" });
                    this.setState({ customerEmailErrorMessage: "" });
                    this.setState({ isFormValid: true });
                }
                else{
                    this.setState({
                        customerEmailErrorMessage: (
                            <small className="text-danger">
                                Invalid email address.
                            </small>
                        )
                    });
                    this.setState({
                        customerEmailState: "error"
                    });
                    this.setState({ isFormValid: false });
                }
            }
        }
        else {
            if(name === "newCustomerEmail"){
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState:  "valid",
                        newCustomerEmailErrorMessage: ""
                    }
                }));
              if(this.state.customerInfo.newCustomerNameState === "valid"){
                    this.setState({ isNewCustomerFormValid: true });
                }   
            }
            else{
                this.setState({ customerEmailState: "valid" });
                this.setState({ customerEmailErrorMessage: "" });
                this.setState({ isFormValid: true });
            }
        }

    }

    validateNewCustomer(){
        if (this.state.customerInfo.newCustomerEmailErrorMessage === "") {
            if (this.state.customerInfo.newCustomerEmailState === "" || this.state.customerInfo.newCustomerEmailState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailErrorMessage: (
                            <small className="text-danger">
                                Email is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState:  "valid",
                        newCustomerEmailErrorMessage: ""
                    }
                }));                
            }
        }

        if (this.state.customerInfo.newCustomerNameErrorMessage === "") {
            if (this.state.customerInfo.newCustomerNameState === "" || this.state.customerInfo.newCustomerNameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerNameErrorMessage: (
                            <small className="text-danger">
                                Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerNameState:  "valid",
                        newCustomerNameErrorMessage: ""
                    }
                }));                
            }
        }       

        if(this.state.customerInfo.newCustomerEmailState === "valid" && this.state.customerInfo.newCustomerNameState === "valid"){
            this.setState({ isNewCustomerFormValid: true });
        }
        else{
            this.setState({ isNewCustomerFormValid: false });
        }
    }

    validateNewItem() {
        if (this.state.itemInfo.nameErrorMessage === "") {
            if (this.state.itemInfo.nameState === "" || this.state.itemInfo.nameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameErrorMessage: (
                            <small className="text-danger">
                                Item Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameState:  "valid",
                        nameErrorMessage: ""
                    }
                }));                
            }
        }

        if(this.state.itemInfo.selectedItemType.value === "Inventory"){
            if(this.state.itemInfo.quantityOnHand !== "")
            {
                if(parseInt(this.state.itemInfo.quantityOnHand) !== "NAN"){                    
                     this.state.itemInfo.quantityState = "valid";   
                     this.state.itemInfo.quantityErrorMessage = "";                    
                }
                else{
                     this.state.itemInfo.quantityState = "error";   
                     this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Invalid Quantity.</small>;                      
                }
            } 
            else{
                 this.state.itemInfo.quantityState = "error";   
                 this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Quantity is Required.</small>;                 
            }
        }
        else{
             this.state.itemInfo.quantityState = "valid";   
             this.state.itemInfo.quantityErrorMessage = "";
        }

        

        if(this.state.itemInfo.nameState === "valid" && this.state.itemInfo.quantityState === "valid"){
            this.setState({ isNewItemFormValid: true });
        }
        else{
            this.setState({ isNewItemFormValid: false });
        }

    }

    isValidated() {   
        if (this.state.customerEmailErrorMessage === "" || this.state.customerEmailErrorMessage === null) {
            if (this.state.customerEmailState === "error") {
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Invalid email address
                        </small>
                    )
                });
                this.setState({ isFormValid: false });
            }
            else {
                this.setState({ customerEmailErrorMessage: null });
                this.setState({ isFormValid: true });
            }
        }

        if(this.state.customTxnNumbers &&  this.state.salesReceiptNumber == "")
        {
            this.setState({ isFormValid: false });
        }

        return this.state.isFormValid;

    }

    handleRowProductNameChange(idx, value) {
        const rows = [...this.state.rows];
        rows[idx].product = value;
        rows[idx].rate = value.rate != undefined ? parseFloat(value.rate).toFixed(2) : 0.00;
        rows[idx].quantity = value.quantity;
        rows[idx].taxable = value.taxable;
        if( rows[idx].rate != undefined && rows[idx].quantity != undefined){
            rows[idx].amount =  (parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)).toFixed(2);
        }
        else{
            rows[idx].amount = 0.00;
        }
        rows[idx].description = value.description;

        var detailType = value.DetailType;
       
        var taxRefCode = {};
        if(value.taxable){
            taxRefCode = { value : "TAX" }
        }
        else{
           taxRefCode = { value: "NON" }
        }
        const lineItems = [...this.state.SalesReceipt.Line];
        lineItems[idx].DetailType = detailType;
        lineItems[idx][detailType] = {
            ItemRef: { value: value.value, name: value.label },
            // Qty: "1",
            // UnitPrice: 100,
            TaxCodeRef: taxRefCode
        };

        if (lineItems[idx].DetailType != "" && rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            lineItems[idx].Amount = parseFloat(rows[idx].amount);
            lineItems[idx][detailType].Qty = parseInt(rows[idx].quantity);
            lineItems[idx][detailType].UnitPrice = parseFloat(parseFloat(rows[idx].rate).toFixed(2));
        }

        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                if(!isNaN(this.state.taxrate.taxValue)){
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                }    
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        }); 

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        if(this.state.ConvFee != "" && this.state.IsCreditCard && this.state.ProfileParams.profile_id != undefined && this.state.ProfileParams.profile_id != null && this.state.RecurringParams.TemplateName != ""){
            let convFeeAmount = 0.00;
            if(this.state.ConvFee.is_percentage){
                convFeeAmount = (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) 
                this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) });
            }
            else{
                convFeeAmount =  parseFloat(this.state.ConvFee.value)
                this.setState({ ConvFeeAmount : parseFloat(this.state.ConvFee.value)});
            }                                   
            this.state.total = parseFloat(parseFloat(this.state.total) + convFeeAmount).toFixed(2);
        }
        
        if (this.state.total != 0) {
            this.setState({
                showTotalBalanceRow: true
            });
        }
        else {
            this.setState({
                showTotalBalanceRow: false
            });
        }

        if(this.state.taxRates.length > 1){
            const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;        
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
        }

        this.setState({ lineItems });
        this.setState({ rows });
    }

    handleRowDescriptionChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx].description = value;

        const lineItems = [...this.state.SalesReceipt.Line];
        lineItems[idx].Description = value;

        this.setState({ lineItems });
        this.setState({ rows });
    }

    checkIsNumeric = (e) => {      
        var charCode = (e.which) ? e.which : e.keyCode;          
        if(e.target.value.indexOf(".") !== -1)
        {
           if (charCode == 46 || e.target.value.length > 16) {
                e.preventDefault();
            }
        }
        else if (charCode != 46 && (!/^\d*$/.test(e.target.value) || e.target.value.length > 16)) {
            e.preventDefault();
        }
    };

    handleRateKeyPress = (e) => {
        var charCode = (e.which) ? e.which : e.keyCode;        
        this.checkIsNumeric(e);
    };

    handleRowValueChange = idx => e => {
        let { name, value } = e.target;        
        const rows = [...this.state.rows];
        if(name === "TaxCheckBox"){
            value = e.target.checked;
            rows[idx].taxable = value;
        }
        rows[idx][name] = value;
        if (rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            rows[idx].amount = (parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)).toFixed(2);
        }

        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                if(!isNaN(this.state.taxrate.taxValue)){
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                }
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        });            

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        if(this.state.ConvFee != "" && this.state.IsCreditCard && this.state.ProfileParams.profile_id != undefined && this.state.ProfileParams.profile_id != null && this.state.RecurringParams.TemplateName != ""){
            let convFeeAmount = 0.00;
            if(this.state.ConvFee.is_percentage){
                convFeeAmount = (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) 
                this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) });
            }
            else{
                convFeeAmount =  parseFloat(this.state.ConvFee.value)
                this.setState({ ConvFeeAmount : parseFloat(this.state.ConvFee.value)});
            }                                   
            this.state.total = parseFloat(parseFloat(this.state.total) + convFeeAmount).toFixed(2);
        }

        if (this.state.total > 0) {
            this.setState({
                showTotalBalanceRow: true
            });
        }
        else {
            this.setState({
                showTotalBalanceRow: false
            });
        }
        var taxRefCode = {};
         if( rows[idx].taxable){
             taxRefCode = { value: "TAX" }
        }
        else{
           taxRefCode = { value: "NON" }
        }

        const lineItems = [...this.state.SalesReceipt.Line];
        if (lineItems[idx].DetailType != "" && rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            var detailType = lineItems[idx].DetailType;
            lineItems[idx].Amount = parseFloat(rows[idx].amount);
            lineItems[idx][detailType].Qty = parseInt(rows[idx].quantity);
            lineItems[idx][detailType].UnitPrice = parseFloat(parseFloat(rows[idx].rate).toFixed(2));

            lineItems[idx][detailType].TaxCodeRef = taxRefCode;
        }

        if(this.state.taxRates.length > 1){
            const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;        
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
        }

        this.setState({ lineItems });

        this.setState({ rows });

    };

    handleAddRow = () => {
        const rowItem = {
            product: "",
            description: "",
            quantity: "",
            rate: "",
            amount: "",
            taxable: false,
        };
        this.setState({
            rows: [...this.state.rows, rowItem]
        });

        const lineItem = {
            Amount: 0,
            DetailType: "",
            Description: "",
        }

        this.setState(prevState => ({
            ...prevState,
            SalesReceipt: {
                ...prevState.SalesReceipt,
                Line: [...this.state.SalesReceipt.Line, lineItem]
            }
        }));

    };

    handleRemoveRow = () => {
        if (this.state.rows.length > 1) {
            this.setState({
                rows: this.state.rows.slice(0, -1)
            });

            this.setState(prevState => ({
                ...prevState,
                SalesReceipt: {
                    ...prevState.SalesReceipt,
                    Line: this.state.SalesReceipt.Line.slice(0, -1)
                }
            }));
        }
    };

    handleRemoveSpecificRow = idx => () => {
        if (this.state.rows.length > 1) {
            const rows = [...this.state.rows];
            rows.splice(idx, 1);
            const lineItems = [...this.state.SalesReceipt.Line];
            lineItems.splice(idx, 1);
            this.setState(prevState => ({
                ...prevState,
                SalesReceipt: {
                    ...prevState.SalesReceipt,
                    Line: lineItems
                }
            }));
           
            this.state.taxableSubtotal = 0.00;
            this.state.subtotal = 0.00;
            this.state.taxAmount = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)  && element.taxable) {
                    this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                    this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                } 
                else if(!isNaN(element.amount)){
                    this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                }   
            });  

            this.state.total = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)) {
                    this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
                }
            });

            this.state.SalesReceipt.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

            if(this.state.ConvFee != "" && this.state.IsCreditCard && this.state.ProfileParams.profile_id != undefined && this.state.ProfileParams.profile_id != null && this.state.RecurringParams.TemplateName != ""){
                let convFeeAmount = 0.00;
                if(this.state.ConvFee.is_percentage){
                    convFeeAmount = (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) 
                    this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) });
                }
                else{
                    convFeeAmount =  parseFloat(this.state.ConvFee.value)
                    this.setState({ ConvFeeAmount : parseFloat(this.state.ConvFee.value)});
                }                                   
                this.state.total = parseFloat(parseFloat(this.state.total) + convFeeAmount).toFixed(2);
            }
            
            var taxRefCode = {
                            value : this.state.taxrate.value,
                            name : this.state.taxrate.name
                        } 

            const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
            txnTaxDetail.TxnTaxCodeRef = taxRefCode;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
            txnTaxDetail.TaxLine[0][detailType].TaxRateRef = taxRefCode;
            txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(this.state.taxrate.taxValue);
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
            

            //const lineItems = [...this.state.Invoice.Line];
            lineItems.forEach(lineItem => {
                var detailType = lineItem.DetailType;
                if(lineItem.DetailType != ""){
                        taxRefCode = { value: "NON" }
                        lineItem[detailType].TaxCodeRef = taxRefCode;
                }
            });    

            lineItems.forEach(lineItem => {
                var detailType = lineItem.DetailType;
                rows.forEach(element => {
                    if (lineItem.DetailType != "" && element.taxable && lineItem[detailType].ItemRef.value === element.product.value && parseFloat(element.amount) === parseFloat(lineItem.Amount)) {
                        taxRefCode = { value: "TAX" }                 
                    lineItem[detailType].TaxCodeRef = taxRefCode;
                    return false;
                    }
                });           
            });  
        this.setState({ lineItems });
        this.setState({ rows });   
        }
    };

    handleCreateRecurringClick(e) {        
        global.SalesReceiptAmount = this.state.total = parseFloat(this.state.SalesReceipt.TotalAmt).toFixed(2);         
        this.setState({ showCreateRecurringModal: true });       
     }
 
    createRecurringCallbackFunction = (childData , recurringParams, profileParams, customerProfileInfo, isCreditCard, IsDebitCard, IsACH) => {
        if(this.state.showCreateRecurringModal){
            this.setState({ProfileParams: profileParams});
            this.setState({RecurringParams: recurringParams});
            this.setState({IsCreditCard: isCreditCard});       
            this.setState({IsDebitCard: IsDebitCard});  
            this.setState({IsACH: IsACH});  
            this.setState({CustomerProfileInfo: customerProfileInfo});
            let convFeeAmount = 0.00;
            if(this.state.ConvFee != "" && isCreditCard){                
                if(this.state.ConvFee.is_percentage){
                    convFeeAmount = (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) 
                    this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.total)/100) * parseFloat(this.state.ConvFee.value)) });
                }
                else{
                    convFeeAmount =  parseFloat(this.state.ConvFee.value)
                    this.setState({ ConvFeeAmount : parseFloat(this.state.ConvFee.value)});
                }                                   
               this.state.total = parseFloat(parseFloat(this.state.total) + convFeeAmount).toFixed(2);
               global.SalesReceiptAmount = parseFloat(parseFloat(global.SalesReceiptAmount) + convFeeAmount).toFixed(2);
               this.setState({ total: parseFloat(global.SalesReceiptAmount).toFixed(2) });
            }           

        }
        this.setState({ showCreateRecurringModal: childData }); 
     }

    createRecurringSubmit = (salesReceipt) => {        
        console.log(salesReceipt);
        const amount = parseFloat(this.state.total).toFixed(2);
        if(this.state.ProfileParams.profile_id != undefined && this.state.ProfileParams.profile_id != null && this.state.RecurringParams.TemplateName != ""){                                                           
            this.state.RecurringParams.CustomerName = salesReceipt.CustomerRef.name;
            this.state.RecurringParams.TotalAmount = parseFloat(parseFloat(salesReceipt.TotalAmt).toFixed(2)); 
            const payload = {    
                "ProfileParams": this.state.ProfileParams,
                "RecurringParams": this.state.RecurringParams,
                "SalesReceiptParams": salesReceipt,
                "TransactionParams": {
                    "TransType":  "profile_sale",
                    "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                    "Amount": amount,
                    "ActionType": "salesreceipt",
                    "CurrencyCode": "USD"
                }                
            }
            this._postCreateRecurringRequest(payload); 
        }
    }

    _postCreateRecurringRequest(payload) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postCreateRecurringPlanRequest(app_id, payload)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        this.props.history.push({
                            pathname: '/invoice/recurring',
                            state: { errorMessage: data.statusmessage, errorCode: 0 }
                        });
                    }                    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    getPaymentProfiles(customer_id) {
        const payload = {
            "customer_id": customer_id,
            "realm_id": null
        }

        this._getProfileByIdRequest(payload)
    }

    _getProfileByIdRequest = (data) => {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ StatusMessage: "" });
        const app_id = 2 
        NetworkService.postProfileByIdRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    this.setState({ StatusCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this.setProfileResult(data.data.items);
                    }
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    warningCustomerChangeAlert(value) {
        this.setState({
        alert: (
            <SweetAlert
            warning
            style={{ position:"static", display: "block" }}
            title="Delete Saved Recurring?"
            onConfirm={() => this.deleteRecurring(value)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            >
            By changing customer, save recurring will be deleted.
            </SweetAlert>
        )
        });
    }

    handleCustomerChange(value){       
        this.setState({ customer: value });
        this.setState({ customerEmail: value.PrimaryEmailAddr.Address })
        
        this.setState({customerEmailState: "valid"});
        this.setState({ customerEmailErrorMessage: null });
        
        this.setState(prevState => ({
            ...prevState,
            SalesReceipt: {
                ...prevState.SalesReceipt,
                CustomerRef: {
                    ...prevState.SalesReceipt.CustomerRef,
                    value: value.value, name: value.label
                },
                BillEmail: {
                    ...prevState.SalesReceipt.BillEmail,
                    Address: value.PrimaryEmailAddr.Address
                },
                BillAddr: {
                    ...prevState.SalesReceipt.BillAddr,
                    Line1: value.BillAddr.Line1, PostalCode: value.BillAddr.PostalCode
                }

            }
        }));
        this.setState({isCreateRecurringEnabled: true})
        this.ValidateEmailAddress(value, "customers");
    }

    deleteRecurring(value) {
        this.setState({
            alert: null
        });
        this.setState({ProfileParams:{} });
        this.setState({ RecurringParams:{} });
        this.handleCustomerChange(value);
        return true;
    }

    hideAlert() {
        console.log('Hiding alert...');
        this.setState({
            alert: null
        });
        return false;
    }

    setProfileResult = (result) => {
        let ccProfilesArray = [{
            value: "",
            label: "Select Proflie",
            isDisabled: true
        },
        {
            value: "none",
            label: "None"
        }]
        let achProfilesArray = [{
            value: "",
            label: "Select Proflie",
            isDisabled: true
        },
        {
            value: "none",
            label: "None"
        }]

        result.map(data => {
            if (data.card_type != "ECHK" && data.card_type != "ACH" && data.card_type != "ECHEK") {
                ccProfilesArray.push({
                    value: data.token,
                    label: data.last_digits,
                    cardholder_name: data.cardholder_name,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postal_code: data.postal,
                    card_type: data.card_type
                });
            }
            else {
                achProfilesArray.push({
                    value: data.token,
                    label: data.last_digits,
                    cardholder_name: data.cardholder_name,
                    first_name: null,
                    last_name: null,
                    address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postal_code: data.postal,
                    card_type: data.card_type
                });
            }
        });

        this.setState({ ccProfiles: ccProfilesArray });
        this.setState({ achProfiles: achProfilesArray })
    }

    handleProfileChanged(prof) {
        if (prof.value != "none") {
            this.setState(prevState => ({
                paymentInfo:
                {
                    ...prevState.paymentInfo, IsCreateProfile: false
                }
            }));

            this.setState({ isProfileSelected: true });
            this.setState(prevState => ({
                paymentInfo:
                {
                    ...prevState.paymentInfo,
                    ProfileId: prof.value,
                    CardHolderName: prof.cardholder_name,                    
                    Address: prof.address,
                    Country: prof.country,
                    State: prof.state,
                    City: prof.city,
                    PostalCode: prof.postal_code,
                }
            }));
        }
        else {
            this.setState({ isProfileSelected: false });
        }
    }

    createCustomerSubmit = (customer) =>
    {
        if(customer.isShippingAddressSameAsBillingAddress)
        {
            customer.shippingAddress.address = customer.billingAddress.address;
            customer.shippingAddress.country = customer.billingAddress.country;
            customer.shippingAddress.state = customer.billingAddress.state;
            customer.shippingAddress.city = customer.billingAddress.city;
            customer.shippingAddress.postalCode = customer.billingAddress.postalCode;
        }

        const payload = {
            "FullyQualifiedName": null, 
            "PrimaryEmailAddr": {
              "Address": customer.customerEmail
            }, 
            "DisplayName": customer.customerName, 
            "Suffix": null, 
            "Title": null, 
            "MiddleName": null, 
            "Notes": customer.customerNote, 
            "FamilyName": null, 
            "PrimaryPhone": {
              "FreeFormNumber": customer.customerPhone
            }, 
            "CompanyName": customer.customerCompany, 
            "BillAddr": {
              "CountrySubDivisionCode": null, 
              "City": customer.billingAddress.city, 
              "PostalCode": customer.billingAddress.postalCode, 
              "Line1": customer.billingAddress.address, 
              "Country": customer.billingAddress.country
            },
            "ShipAddr": {
                "CountrySubDivisionCode": null, 
                "City": customer.billingAddress.city, 
                "PostalCode": customer.billingAddress.postalCode, 
                "Line1": customer.billingAddress.address, 
                "Country": customer.billingAddress.country
              }, 
            "GivenName": null,
            "WebAddr":{
                "URI":null
            }
          }

        this._postCreateCustomerRequest(payload);

        // //after creating customer uncomment below 
        // this.setState({ showNewCustomerPopupModal: false });
        // this.setState({ShowPopupProgressBar: false });
    }

    createItemSubmit = (item) =>
    {
        const payload = {
            "TrackQtyOnHand": this.state.itemInfo.isInvenotryItem?true:false, 
            "Name": item.name, 
            "QtyOnHand": item.price, 
            "IncomeAccountRef": item.selectedIncomeAccount, 
            "AssetAccountRef": item.selectedInventoryAssetAccount, 
            "InvStartDate": item.InvStartDate, 
            "Type": item.selectedItemType.value, 
            "ExpenseAccountRef": item.selectedExpenseAccount,
            "QtyOnHand": item.quantityOnHand,
            "InvStartDate": item.quantityOnHandDate,
            "Description": item.salesInformation,
            "Taxable": item.taxable
          }

        this._postCreateItemRequest(payload);
        
    }

    handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        //alert(charCode);
        //97-122, 32,65-90'
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };


    
    handleCancelCreateRecurring(e) {
        this.props.history.push('/invoice/recurring');
        e.preventDefault();
    }
        
    setCustomerResult(customers, isFromCache = false) {
        if(!isFromCache)
        {
            localStorage.setItem(StorageEnum.QBO_CUSTOMERS, null);
            localStorage.setItem(StorageEnum.QBO_CUSTOMERS, JSON.stringify(customers)); 
        }
        const newState = [{
            value: "AddNew",
            label: <Button simple icon onClick={e => this.handleOpenNewCustomerPopup(e, "")}>
                <div className="spinner-grow text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Customer</i>
            </Button>,

            isDisabled: true
        }]
        customers.map(data => {
            try {
                const x = {
                    value: data["Id"],
                    label: data["DisplayName"],
                    PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"] != null ? data["PrimaryEmailAddr"]["Address"] : "" },
                    BillAddr: data["BillAddr"] != null ? {Line1: data["BillAddr"]["Line1"], PostalCode: data["BillAddr"]["PostalCode"]} : {Line1: "", PostalCode: ""}
                }
                newState.push(x);
            }
            catch (why) {
                console.log(why)
            }

        });
        this.setState({ customers: newState });
    }

    setNewCustomerResult(customer){
        let newCustomer = null;
        customer.map(data => {
            try {
                const c = {
                    value: data["Id"],
                    label: data["DisplayName"],
                    PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"]["Address"] },
                    BillAddr: {Line1: data["BillAddr"]["Line1"], PostalCode: data["BillAddr"]["PostalCode"]}
                }
                newCustomer = {
                    Id: data["Id"],
                    value: data["Id"],
                    label: data["DisplayName"],
                    DisplayName: data["DisplayName"],
                    PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"]["Address"] }
                }
                this.setState(prevState => ({
                    customers: [
                        ...prevState.customers,
                        c,
                    ]
                }));
            }
            catch (why) {
                console.log(why)
            }
        });   
        let customers = JSON.parse(localStorage.getItem(StorageEnum.QBO_CUSTOMERS));
        customers.push(newCustomer);
        localStorage.setItem(StorageEnum.QBO_CUSTOMERS, null);
        localStorage.setItem(StorageEnum.QBO_CUSTOMERS, JSON.stringify(customers));      
    }

    setTermsResult(terms) {
        const newState = [];

        terms.map(data => {
            const x = { value: data["Id"], label: data["Name"] }
            newState.push(x);
        });

        this.setState({ terms: newState });
    }

    setTaxCodesResult(taxcodes){
        const newState = [];
          taxcodes.map(data => {            
            newState.push(data);
        });  

        this.setState({ taxcodes: newState });
        localStorage.setItem("taxcodes", JSON.stringify(taxcodes));
        this._getTaxRateRequest(); 
    }

    setTaxRatesResult(taxRates, isFromCache = false) { 
        if(!isFromCache)
        {
            localStorage.setItem("taxrates", null);
            localStorage.setItem("taxrates", JSON.stringify(taxRates)); 
        }
        const newState = [];
        console.log(this.state.taxcodes);
        newState.push({value: "", label: "Select Tax", name: "",  taxValue: "", isDisabled: true});

        this.state.taxcodes.map(tc => {
            if(tc.Active && tc.SalesTaxRateList != null){
                let rv = 0
                var taxcodeName = tc.Name;
                var found = false;
                for(var i = 0; i < taxRates.length; i++) {
                    if (taxRates[i].Name.includes(taxcodeName)) {
                        found = true;
                        break;
                    }
                }                
                if(found) 
                {
                    tc.SalesTaxRateList.TaxRateDetail.map(strl =>{
                        var tr = taxRates.find( ({ Id }) => Id ===  strl.TaxRateRef.value );
                        if(tr != null){
                            rv += tr.RateValue
                        }
                    });
                    var id = tc.Id;
                    var label = tc.Name + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +  (rv + "%") ;
                    var taxValue =  rv;
                    var name = tc.Name;
                    const x = { value: id, label: label, name: name , taxValue: taxValue }; 
                    newState.push(x);
                }
            }
        });
        this.state.taxRates = newState; 
        const txnTaxDetail = this.state.SalesReceipt.TxnTaxDetail;
        if(this.state.taxRates.length > 1){
            this.setState({ taxrate: this.state.taxRates[1] });
            txnTaxDetail.TxnTaxCodeRef = {value: this.state.taxRates[1].value, name: this.state.taxRates[1].name};
        }
        txnTaxDetail.TotalTax = this.state.taxAmount;
        var detailType = txnTaxDetail.TaxLine[0].DetailType; 
        txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
        if(this.state.taxRates.length > 1){
            txnTaxDetail.TaxLine[0][detailType].TaxRateRef = {value: this.state.taxRates[1].value, name: this.state.taxRates[1].name};
            txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(this.state.taxRates[1].taxValue);
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;
        }

        this.setState({ txnTaxDetail });
    }

    setItemsResult(items, isFromCache = false) {       
        if(!isFromCache)
        {
            localStorage.setItem("items", null);
            localStorage.setItem("items", JSON.stringify(items)); 
        }
        const newState = [{
            value: "AddNew",
            label: <Button simple icon onClick={e => this.handleOpenNewItemPopup(e, "")}>
                <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Product/Service</i>
            </Button>,

            isDisabled: true
        }]

        items.map(data => {
            const product = {
                value: data["Id"],
                label: data["Name"],
                DetailType: "SalesItemLineDetail",
                taxable: data["Taxable"],
                quantity: 1,
                rate: data["UnitPrice"],
                description: data["Description"]
            }
            newState.push(product);
        });

        this.setState({ products: newState });
    }

    setNewItemResult(item){
        let newItem = null;
        item.map(data => {
            const product = {
                value: data["Id"],
                label: data["Name"],
                DetailType: "SalesItemLineDetail",
                taxable: data["Taxable"],
                quantity: 1,
                rate: data["UnitPrice"],
                description: data["Description"]
            }
            newItem = {
                value: data["Id"],
                label: data["Name"],
                Id: data["Id"],                
                Name: data["Name"],
                SalesItemLineDetail: "SalesItemLineDetail",
                Taxable: data["Taxable"],
                quantity: 1,
                UnitPrice: data["UnitPrice"],
                Description: data["Description"]
            }
            this.setState(prevState => ({
                products: [
                    ...prevState.products,
                    product,
                ]
            }));
        });       

        let items = JSON.parse(localStorage.getItem("items"));
        items.push(newItem);
        localStorage.setItem("items", null);
        localStorage.setItem("items", JSON.stringify(items));   
    }

    setPreferencesResult(preferences, isFromCache = false) {  
        if(preferences != null && JSON.stringify(preferences) !== JSON.stringify({}))
        {
            if(!isFromCache)
            {
                localStorage.setItem("preferences", null);
                localStorage.setItem("preferences", JSON.stringify(preferences)); 
                preferences.map(data => {   
                    this.setState({ customTxnNumbers: data["SalesFormsPrefs"]["CustomTxnNumbers"] })
                });
            }
            else{  
                preferences.map(data => {   
                    this.state.customTxnNumbers = data["SalesFormsPrefs"]["CustomTxnNumbers"];
                });
            }
        }
    }

    setAccountResult(accounts){
        const incomeAccounts = [];
        const expenseAccounts = [];
        const assetAccounts = [];        
        incomeAccounts.push({value: "", label: "Select Account", isDisabled: true});
        expenseAccounts.push({value: "", label: "Select Account", isDisabled: true});
        let selectedIncomeAccount1 = "";
        let selectedExpenseAccount1 = "";
        let selectedAssetAccount1 = "";
        this.setState({accounts: accounts});
        accounts.map(data => {
            if(data.Classification == "Revenue" || data.Classification == "Income" ||  data.AccountType == "Income"){           
                incomeAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                if(data.Name == "Services"){
                    selectedIncomeAccount1 = {value: data.Id, label: data.Name +"|"+ data.AccountType};
                }
            }
            else if((data.Classification == "Expense" || data.AccountType == "Expense") && 
                (data.AccountSubType == "SuppliesMaterialsCogs" || data.AccountSubType == "ShippingFreightDeliveryCos" || 
                data.AccountSubType == "OtherMiscellaneousServiceCost" || data.AccountSubType == "Utilities")){
                expenseAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                if(data.Name == "Cost of Goods Sold"){
                    selectedExpenseAccount1 =  {value: data.Id, label: data.Name +"|"+ data.AccountType};
                }
            }
            else if(data.AccountSubType == "Inventory"){
                assetAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                if(data.Name == "Inventory Asset"){
                    selectedAssetAccount1 =  {value: data.Id, label: data.Name +"|"+ data.AccountType};
                }
            }
                
        });        
        if(selectedIncomeAccount1 == ""){
            selectedIncomeAccount1 = incomeAccounts[1];
        }

        if(selectedExpenseAccount1 == ""){
            selectedExpenseAccount1 = expenseAccounts[1];
        }
        if(selectedAssetAccount1 == ""){
            selectedAssetAccount1 = assetAccounts[0];
        }
        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                incomeAccount: incomeAccounts,
                selectedIncomeAccount:  selectedIncomeAccount1
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                expenseAccount: expenseAccounts,
                selectedExpenseAccount: selectedExpenseAccount1
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                inventoryAssetAccount: assetAccounts,
                selectedInventoryAssetAccount: selectedAssetAccount1
            }
        }));   
    }

    /**
     * API Calling
     */   
    _getItemListingRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getItemListingRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setItemsResult(data.data.items, false);
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _postCreateItemRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        // this.props.history.push({
                        //     pathname: '/invoice/invoices',
                        //     state: { errorMessage: data.statusmessage, errorCode: 0 }
                        // });
                        if (data.data != null) {
                            this.setNewItemResult(data.data.items);
                         }
                    }
                    
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    _getTaxRateRequest() {
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTaxRateRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.data != null){
                        this.setTaxRatesResult(data.data.items, false);
                    }
                } else {
                }
            }).catch(error => {
                console.log(error);
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getTaxCodeRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTaxCodeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.data != null){
                        this.setTaxCodesResult(data.data.items);
                    }
                } 
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getPreferencesRequest() {
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getPreferencesRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data.Preferences != null)
                        this.setPreferencesResult(data.data.Preferences, false)

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    
    _getCustomerRequest() {
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getCustomerRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null) {
                        this.setCustomerResult(data.data.items, false);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _postCreateCustomerRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateCustomerRequest(app_id, body)
            .then(data => {

                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });
                this.setState({showNewCustomerPopupModal: false});

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        if (data.data != null) {
                            this.setNewCustomerResult(data.data.items);
                         }
                    }
                    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({showNewCustomerPopupModal: false});
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    _getAccountRequest(){
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getAccountRequest(app_id)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });
                   if(data.statuscode == 0){
                        const account = data.data.items;
                        this.setAccountResult(account);
                   }


                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    render() {
        const totalLabelStyle = {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "35px",
             marginRight: "40px"
        };
        const deleteButtonToolTip = <Tooltip id="refundToolTip">Delete</Tooltip>;               
        return (
            <div className="ii-main-content"> 
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                {this.state.alert} 
                <Card
                    textCenter
                    content={               
                        <div>  
                            {this.state.proceesorSettingError &&
                                <div>
                                    <Alert bsStyle= "info">
                                        <span>
                                            {this.state.proceesorSettingError}
                                        </span>
                                    </Alert>
                                </div>
                            }   
                            <div style={{textAlign:"center", color: "#0075c1", fontSize:"30px"}}>
                                New Recurring
                            </div>
                            <br/>
                            {this.state.ShowProgressBar &&
                                <div style={{marginBottom:"5px"}}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }                       
                            {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Customer: <span className="star">*</span>
                                        </div>
                                        <SelectCreatable
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="customers"
                                            isDisabled={this.state.ShowProgressBar}
                                            value={this.state.customer}
                                            formatCreateLabel = {(opt) =>
                                                             {
                                                                 return <Button simple icon style={{ minWidth: "100%", textAlign:"left" }} onClick={e => this.handleOpenNewCustomerPopup(e, opt)}> 
                                                                 <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add {opt}</i> </Button>
                                                             }
                                                        }
                                            onChange={value => {
                                                if(this.state.customers.indexOf(value) !== -1)
                                                    {
                                                        if(this.state.ProfileParams.profile_id != undefined && this.state.ProfileParams.profile_id != null && this.state.RecurringParams.TemplateName != ""){                                                           
                                                            this.warningCustomerChangeAlert(value); 
                                                         }
                                                         else{
                                                            this.handleCustomerChange(value);                                                            
                                                        }                                                           
                                                    }
                                             }                                             
                                            }
                                            onBlur={e => this.isValidated()}
                                            options={this.state.customers}
                                            placeholder="Select Customer"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Customer Email: 
                                        </div>
                                        <FormControl
                                            value={this.state.customerEmail}
                                            placeholder="Customer Email"
                                            name="customerEmail"
                                            type="email"
                                            className={this.state.customerEmailState}
                                            disabled={this.state.ShowProgressBar}
                                            onChange={event =>
                                                this.ValidateEmailAddress(event, "customerEmail")
                                            }
                                            onBlur={e => this.isValidated()}
                                        />
                                        {this.state.customerEmailErrorMessage}
                                    </FormGroup>
                                </Col>
                                 {this.state.customTxnNumbers &&
                                    <Col md={2}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Sales Receipt Number: <span className="star">*</span>
                                            </div>
                                            <FormControl
                                                value={this.state.salesReceiptNumber}
                                                type="text"
                                                placeholder="Sales Receipt Number"
                                                name="salesReceiptNumber"
                                                className={this.state.salesReceiptNumberState}
                                                disabled={this.state.ShowProgressBar}
                                                onChange={event => {
                                                    this.change(event, "salesReceiptNumber", "length", 2);
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        SalesReceipt: {
                                                            ...prevState.SalesReceipt,
                                                            DocNumber: value
                                                        }
                                                    }));
                                                }
                                                }
                                                onBlur={e => this.isValidated()}
                                            />
                                            {this.state.salesReceiptNumberErrorMessage}
                                        </FormGroup>
                                    </Col>
                                 }
                            </Row>  
                            <Row>
                                <Col md={3}>                     
                                    <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "35px" }} disabled={!this.state.isCreateRecurringEnabled} onClick={e => this.handleCreateRecurringClick(e)}>
                                        <i className="fa fa-refresh"></i> Create Recurring for this Sales Receipt
                                    </Button>                                                                            
                                </Col>                                                             
                            </Row>                          
                            <Row>
                                <Col md={12}>
                                    <br />
                                    <table
                                        className="table table-bordered table-hover"
                                        id="tab_logic"
                                    >
                                        <thead>
                                            <tr style={{ backgroundColor: "#eeeeee" }}>
                                                <th className="text-right" style={{ width: 35, color: "#727272", fontWeight: "bold" }}> # </th>
                                                <th className="text-left" style={{ width: 300, color: "#727272", fontWeight: "bold" }}> PRODUCT/SERVICE </th>
                                                <th className="text-left" style={{ color: "#727272", fontWeight: "bold" }}> DESCRIPTION </th>
                                                <th className="text-right" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>QTY</th>
                                                <th className="text-right" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>RATE</th>
                                                <th className="text-right" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>AMOUNT</th>
                                                <th className="text-center" style={{ width: 35, color: "#727272", fontWeight: "bold" }}>TAX</th>
                                                {/* <th className="text-center"> Mobile </th> */}
                                                <th style={{ width: 30 }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.rows.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td className="text-right">{idx + 1}</td>
                                                    <td>
                                                        <SelectCreatable
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="product"
                                                            value={this.state.rows[idx].product}
                                                            formatCreateLabel = {(opt) =>
                                                                {                                                                    
                                                                    return <Button simple icon style={{ minWidth: "100%", textAlign:"left" }} onClick={e => this.handleOpenNewItemPopup(e, opt)}> 
                                                                    <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add {opt}</i> </Button>
                                                                }
                                                            }
                                                            isDisabled={this.state.ShowProgressBar}
                                                            onChange={value => {
                                                                    if(this.state.products.indexOf(value) !== -1)
                                                                    {
                                                                        this.handleRowProductNameChange(idx, value);
                                                                    }
                                                                }
                                                            }
                                                            options={this.state.products}
                                                            placeholder="Select Product"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].description}
                                                            type="text"
                                                            placeholder="Description"
                                                            name="description"
                                                            disabled={this.state.ShowProgressBar}
                                                            onChange={this.handleRowDescriptionChange(idx)}                                                        
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].quantity}
                                                            disabled={this.state.ShowProgressBar}
                                                            type="number"
                                                            placeholder="Quantity"
                                                            name="quantity"
                                                            style={{ textAlign: "center" }}
                                                            min="1"
                                                            step="1"
                                                            onChange={this.handleRowValueChange(idx)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                            <FormControl
                                                                value={this.state.rows[idx].rate}
                                                                disabled={this.state.ShowProgressBar}
                                                                type="number"
                                                                placeholder="Rate"
                                                                name="rate"
                                                                min="0.00"
                                                                style={{ textAlign: "center", padding: "8px 6px" }}
                                                                step="0.01"
                                                                onChange={this.handleRowValueChange(idx)}
                                                                onKeyPress= {this.handleRateKeyPress}
                                                                onBlur={event => {
                                                                    const rows = [...this.state.rows];
                                                                    rows[idx].rate = parseFloat(this.state.rows[idx].rate).toFixed(2);
                                                                    this.setState({ rows });
                                                                }}
                                                            />
                                                        </InputGroup>    
                                                    </td>
                                                    <td>
                                                        <InputGroup>  
                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc", backgroundColor:"#f5f5f5"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                            <FormControl
                                                                value={this.state.rows[idx].amount}
                                                                type="number"
                                                                placeholder="0.00"
                                                                name="amount"
                                                                min="0.00"
                                                                style={{ textAlign: "right", padding: "8px 6px" }}
                                                                readOnly={true}
                                                            />
                                                        </InputGroup>      
                                                    </td>
                                                    <td>
                                                        <CustomCheckbox
                                                            name="TaxCheckBox"
                                                            checked={this.state.rows[idx].taxable}
                                                            isDisabled= {this.state.ShowProgressBar  || this.state.taxRates.length <= 1}  
                                                            formControlProps={{
                                                                onChange: this.handleRowValueChange(idx)
                                                                
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top" overlay={deleteButtonToolTip}>
                                                            <Button round twitter onClick={this.handleRemoveSpecificRow(idx)}>
                                                                <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Row>
                                        <Col md={7}>
                                            <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={this.handleAddRow}>
                                                <i className="fa fa-plus"></i> Add Line
                                            </Button>
                                        </Col><div style={{ textAlign: "right" }}>
                                        <Col md={3}>
                                                    <p style={totalLabelStyle}>Subtotal</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.subtotal}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                                </div>
                                     </Row>
                                     <Row>
                                         <Col md={5}>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                              Message on Recurring:
                                            </div>
                                            <FormGroup controlId="formControlsTextarea">
                                                <FormControl
                                                    rows="2"
                                                    value={this.state.salesReceiptMessage}
                                                    componentClass="textarea"
                                                    disabled={this.state.ShowProgressBar}
                                                    bsClass="form-control"
                                                    placeholder="Message to display on Sales Receipt"
                                                    maxLength="500"
                                                    onChange={event => {
                                                        const msg = event.target.value;
                                                        this.setState({ salesReceiptMessage: msg });
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            SalesReceipt: {
                                                                ...prevState.SalesReceipt,
                                                                CustomerMemo: {
                                                                    ...prevState.SalesReceipt.CustomerMemo,
                                                                    value: msg
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                            </FormGroup>
                                         </Col>
                                         <Col md={1}>
                                         </Col>
                                         {this.state.taxRates.length > 1 && 
                                         <div>
                                        <Col md={4}>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em", fontSize:"13px" , textAlign: "right", fontWeight:"bold" }}>
                                                Taxable Subtotal  <span style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.taxableSubtotal}`).toFixed(2)).toFixed(2)}</span>
                                                </div>
                                                <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Select tax rate:                                            
                                            </div>
                                            <div style={{width:"92%" }}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="taxRates"
                                                    isDisabled={this.state.ShowProgressBar}
                                                    value={this.state.taxrate}
                                                    onChange={value => {
                                                        this.setState({ taxrate: value });
                                                        this.handTaxRateChange(value);                                                    
                                                    }
                                                    }
                                                    options={this.state.taxRates}
                                                    placeholder="Select Tax Rate"
                                                />
                                            </div>
                                        </FormGroup>
                                        </Col>                                        
                                        <Col md={1}>
                                            <br/><br/>   <br/>                                                           
                                                <div style={{ textAlign: "right" }}>                                   
                                                <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.taxAmount}`).toFixed(2)).toFixed(2)}</p>
                                                </div>
                                        </Col>
                                            </div>
                                         }
                                        <Col md={1}>
                                        </Col>
                                         
                                    </Row>   
                                    <div style={{ textAlign: "right" }}>
                                            <Row>
                                                <Col md={9}>                                               
                                                    {this.state.IsCreditCard && this.state.ConvFee != "" &&                                         
                                                        <p style={totalLabelStyle}>
                                                           {this.state.ConvFee.name} &nbsp;&nbsp; {this.state.ConvFee.is_percentage == false && "$"}{parseFloat(this.state.ConvFee.value).toFixed(2)}{this.state.ConvFee.is_percentage == true && "%"}
                                                        </p>                                           
                                                    }                                 
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>Total</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10}>
                                                    <p style={totalLabelStyle}>Balance Due</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{ textAlign: "right", marginRight: "2em" }}>
                                        <br />
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelCreateRecurring(e)}>Cancel</Button>
                                        <Button bsStyle="primary" fill wd disabled={!(this.state.ProfileParams.profile_id != undefined && this.state.ProfileParams.profile_id != null && this.state.RecurringParams.TemplateName != "") || this.state.proceesorSettingError || !this.state.showTotalBalanceRow  || !this.state.isFormValid } onClick={e => this.createRecurringSubmit(this.state.SalesReceipt)}>Create Recurring</Button>
                                    </div>
                                </Col>
                            </Row>

                            
                            <Modal show={this.state.showNewItemPopupModal}
                                onHide={() => this.setState({ showNewItemPopupModal: false })}
                                bsSize="large" backdrop="static"           
                                >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }
                                <Modal.Header closeButton>
                                    <Modal.Title>Product/Service information</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Item Name: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                        value={this.state.itemInfo.name}
                                                        type="text"
                                                        placeholder="Item Name"
                                                        name="name"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    name: value
                                                                }
                                                            }));
                                                            if(this.verifyLength(value, 2))
                                                            {
                                                                this.setState(prevState => ({
                                                                ...prevState,
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    nameState: "valid",
                                                                    nameErrorMessage: ""
                                                                }
                                                                }));
                                                                this.state.itemInfo.nameState = "valid"
                                                                this.validateNewItem();
                                                            }
                                                            else{
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        nameErrorMessage: (
                                                                            <small className="text-danger">
                                                                                Invalid Item Name.
                                                                            </small>
                                                                        )
                                                                    }
                                                                }));
                                                                this.setState({ isNewItemFormValid: false });
                                                            }                                                    
                                                        }
                                                        }
                                                        onBlur={e => this.validateNewItem()}
                                                />
                                                {this.state.itemInfo.nameErrorMessage}
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    SKU: 
                                                </div>
                                                <FormControl
                                                            value={this.state.itemInfo.sku}
                                                            type="text"
                                                            placeholder="SKU"
                                                            name="sku"
                                                            onChange={event => {
                                                                var value = event.target.value;
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        sku : value
                                                                    }
                                                                }));                                                                                                                  
                                                            }
                                                            }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Price/Rate: 
                                                </div>
                                                <FormControl
                                                            value={this.state.itemInfo.price}
                                                            type="number"
                                                            placeholder="Price/Rate"
                                                            name="rate"
                                                            min="0.00"
                                                            style={{ textAlign: "center" }}
                                                            step="0.01"
                                                            onKeyPress= {this.handleRateKeyPress}
                                                            onChange={event => {
                                                                var value = event.target.value;
                                                                            this.setState(prevState => ({
                                                                            itemInfo: {
                                                                                ...prevState.itemInfo,
                                                                                price: value                                                                       
                                                                        }}));                                                                                                                
                                                                }
                                                            }
                                                            onBlur={event => {
                                                                var value = event.target.value;
                                                                this.setState(prevState => ({
                                                                itemInfo: { 
                                                                    ...prevState.itemInfo,
                                                                    price: parseFloat(value).toFixed(2)                                                                            
                                                                }}));                                                                                                                
                                                                
                                                            }}
                                                        /> 
                                                 </FormGroup>                                                      
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Item Type: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="itemtype"
                                                        value={this.state.itemInfo.selectedItemType}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedItemType: value
                                                                }
                                                            }));
                                                            let accounts = this.state.accounts; 
                                                            let incomeAccounts = [];
                                                            let expenseAccounts = [];
                                                            incomeAccounts.push({value: "", label: "Select Account", isDisabled: true});
                                                            expenseAccounts.push({value: "", label: "Select Account", isDisabled: true});
                                                            let selectedIncomeAccount = "";
                                                            let selectedExpenseAccount = "";
                                                            if(value.value === "Inventory"){                                  
                                                                accounts.map(data => {
                                                                    if((data.Classification == "Revenue" || data.Classification == "Income" ||  data.AccountType == "Income") && (data.AccountSubType == "SalesOfProductIncome"))
                                                                        incomeAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                                                                        if(data.Name == "Sales of Product Income"){
                                                                            selectedIncomeAccount = {value: "79", label: "Sales of Product Income|Income"};
                                                                        }

                                                                })                                                                
                                                                this.setState(prevState => ({
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        incomeAccount: incomeAccounts
                                                                    }
                                                                }));
                                                                accounts.map(data => {
                                                                   if(data.AccountSubType == "SuppliesMaterialsCogs"){
                                                                        expenseAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                                                                        if(data.Name == "Cost of Goods Sold"){
                                                                            selectedExpenseAccount =  {value: data.Id, label: data.Name +"|"+ data.AccountType};
                                                                        }
                                                                    }
                                                                });
                                                                this.setState(prevState => ({
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        expenseAccount: expenseAccounts
                                                                    }
                                                                }));


                                                                this.state.itemInfo.isInvenotryItem = true;
                                                                this.state.itemInfo.quantityOnHand = 0;
                                                                this.state.itemInfo.quantityErrorMessage = "";
                                                                if(selectedIncomeAccount == ""){
                                                                    selectedIncomeAccount = incomeAccounts[1];
                                                                }
                                                                if(selectedExpenseAccount == ""){
                                                                    selectedExpenseAccount = expenseAccounts[1];
                                                                }
                                                                this.state.itemInfo.selectedIncomeAccount =  selectedIncomeAccount;
                                                                
                                                            }
                                                            else{
                                                                accounts.map(data => {
                                                                    if(data.Classification == "Revenue" || data.Classification == "Income" ||  data.AccountType == "Income")
                                                                        incomeAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                                                                        if(data.Name == "Services"){
                                                                            selectedIncomeAccount = {value: data.Id, label: data.Name +"|"+ data.AccountType};
                                                                        }
                                                                })                                                              
                                                                this.setState(prevState => ({
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        incomeAccount: incomeAccounts
                                                                    }
                                                                }));
                                                                
                                                                
                                                                this.state.itemInfo.isInvenotryItem = false;
                                                                this.state.itemInfo.quantityOnHand = null;
                                                                this.state.itemInfo.quantityErrorMessage = "";
                                                                if(selectedIncomeAccount == "")
                                                                {
                                                                    selectedIncomeAccount = incomeAccounts[0];
                                                                }
                                                                this.state.itemInfo.selectedIncomeAccount=  selectedIncomeAccount;
                                                            }
                                                         }
                                                        }
                                                        options={this.state.itemInfo.itemType}
                                                        placeholder="Select Item Type"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Income Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="incomeAccount"
                                                        value={this.state.itemInfo.selectedIncomeAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedIncomeAccount: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.incomeAccount}
                                                        placeholder="Select Income Account"
                                                        onBlur={e => this.validateNewItem()}
                                                    />
                                                </FormGroup>
                                                {/* <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Category:
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="category"
                                                        value={this.state.itemInfo.selectedCategory}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedCategory: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.category}
                                                        placeholder="Select Category"
                                                    />
                                                </FormGroup> */}
                                            </Col>
                                        </Row>
                                        {this.state.itemInfo.isInvenotryItem && 
                                        <Row>
                                            <Col md={3}>
                                              <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        QtyOnHand: <span className="star">*</span>
                                                    </div>
                                                        <FormControl
                                                            value={this.state.itemInfo.quantityOnHand}
                                                            type="number"
                                                            placeholder="Qty on Hand"
                                                            name="quantityOnHand"
                                                            style={{ textAlign: "center" }}
                                                            step="1"
                                                            onChange={event => {
                                                                        var value = event.target.value;
                                                                        this.state.itemInfo.quantityOnHand = value;                                                                
                                                                        this.validateNewItem();
                                                                    }                                                                                                                      
                                                                } 
                                                                onBlur={e => this.validateNewItem()}                                                          
                                                        />
                                                        {this.state.itemInfo.quantityErrorMessage} 
                                                 </FormGroup>        
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Inv. Start Date: <span className="star">*</span>
                                                    </div>
                                                    <InputGroup>
                                                        <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                                        <Datetime
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            className="react-calendar"
                                                            inputProps={{ placeholder: "As of Date" }}
                                                            onChange={this.quantityOnHandDateHandler}
                                                            value={this.state.itemInfo.quantityOnHandDate}
                                                        // dateFormat={'YYYY-MM-DD'}
                                                        />
                                                        <InputGroup.Addon></InputGroup.Addon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Expense Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="expenseAccount"
                                                        value={this.state.itemInfo.selectedExpenseAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedExpenseAccount: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.expenseAccount}
                                                        placeholder="Select Expense Account"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        }
                                        <Row>
                                            <Col md={6}>
                                             <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                             Sales Information:
                                            </div>
                                            <FormGroup controlId="formControlsTextarea">

                                                    <FormControl
                                                        rows="2"
                                                        value={this.state.itemInfo.salesInformation}
                                                        componentClass="textarea"
                                                        bsClass="form-control"
                                                        placeholder="Sales Information"
                                                        maxLength="200"
                                                        onChange={event => {
                                                            const msg = event.target.value;
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    salesInformation: msg
                                                                }
                                                            }));
                                                        }}
                                                        onBlur={e => this.validateNewItem()}
                                                    />
                                                </FormGroup>
                                            </Col>   
                                            <Col md={6}>
                                                <br/>
                                                <CustomCheckbox
                                                    name="ItemTaxableCheckBox"
                                                    checked={this.state.itemInfo.taxable}
                                                    labelText="Taxable"
                                                    labelProps={{style: {fontSize: "16px", marginTop:"5px"}}} 
                                                    formControlProps={{
                                                    onChange:(e) => {                                                    
                                                        var value = e.target.checked;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            itemInfo: {
                                                                ...prevState.itemInfo,
                                                                taxable: value
                                                            }
                                                        }));
                                                    }
                                                }}  
                                                />
                                            </Col> 
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>                                   
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewItemPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.createItemSubmit(this.state.itemInfo)} disabled={!this.state.isNewItemFormValid}>Save</Button>
                                </Modal.Footer>
                            </Modal>  

                            <Modal show={this.state.showNewCustomerPopupModal} onHide={() => this.setState({ showNewCustomerPopupModal: false })} dialogClassName="modal-dialog-custom">
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }                 
                                <Modal.Header closeButton>
                                    <Modal.Title>Customer Information</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <Row>
                                            <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Customer Name: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.customerName}
                                                    type="text"
                                                    placeholder="Customer Name"
                                                    name="customerName"
                                                    onChange={event => {
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                customerName: value
                                                            }
                                                        }));
                                                        if(this.verifyLength(value, 2))
                                                        {
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                newCustomerNameState: "valid",
                                                                newCustomerNameErrorMessage: ""
                                                            }
                                                        }));
                                                        }
                                                        else{
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                customerInfo: {
                                                                    ...prevState.customerInfo,
                                                                    newCustomerNameErrorMessage: (
                                                                        <small className="text-danger">
                                                                            Invalid Customer Name.
                                                                        </small>
                                                                    )
                                                                }
                                                            }));
                                                            this.setState({ isNewCustomerFormValid: false });
                                                        }                                                    
                                                    }
                                                    }
                                                    onBlur={e => this.validateNewCustomer()}
                                                />
                                                {this.state.customerInfo.newCustomerNameErrorMessage}
                                            </FormGroup>
                                        </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Customer Email: <span className="star">*</span>
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.customerEmail}
                                                        placeholder="Customer Email"
                                                        name="newCustomerEmail"
                                                        type="email"
                                                        className={this.state.customerInfo.newCustomerEmailState}
                                                        onChange={event =>
                                                            this.ValidateEmailAddress(event, "newCustomerEmail")
                                                        }
                                                    onBlur={e => this.validateNewCustomer()}
                                                    />
                                                    {this.state.customerInfo.newCustomerEmailErrorMessage}
                                                </FormGroup>
                                            </Col>                                    
                                            <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Company Name: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.customerCompany}
                                                    type="text"
                                                    placeholder="Company Name"
                                                    name="customerCompany"
                                                //  className={this.state.customerInfo.customerNameState}
                                                    onChange={event => {
                                                        this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                customerCompany: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                                {/* {this.state.invoiceNumberErrorMessage} */}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Phone Number: 
                                                </div>
                                                <FormControl
                                                type="number"
                                                placeholder="Phone Number"
                                            //  className={this.state.user.phonenumberState}
                                                name="customerPhone"
                                                value={this.state.customerInfo.customerPhone}
                                                onChange={event => {
                                                    //this.setState({ user: { phonenumber: event.target.value } })
                                                    this.change(event, "customerPhone", "length", 7);
                                                    var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                customerPhone: value
                                                            }
                                                        }));
                                                }
                                                }
                                            //  onBlur={e => this.isValidated()}
                                                />
                                                {/* {this.state.phonenumberErrorMessage} */}
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Fax: 
                                                </div>
                                                <FormControl
                                                type="number"
                                                placeholder="Fax Number"
                                            //  className={this.state.user.phonenumberState}
                                                name="customerFax"
                                                value={this.state.customerInfo.customerFax}
                                                onChange={event => {
                                                    //this.setState({ user: { phonenumber: event.target.value } })
                                                    this.change(event, "customerFax", "length", 7);
                                                    var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                customerFax: value
                                                            }
                                                        }));
                                                }
                                                }
                                                />
                                            
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Website: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.customerWebsite}
                                                    type="text"
                                                    placeholder="Website"
                                                    name="customerWebsite"
                                                //  className={this.state.customerInfo.customerNameState}
                                                    onChange={event => {
                                                        this.change(event, "customerWebsite", "length", 8);
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                customerWebsite: value
                                                            }
                                                        }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                                {/* {this.state.invoiceNumberErrorMessage} */}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Note:
                                                </div>
                                            <FormGroup controlId="formControlsTextarea">

                                                <FormControl
                                                    rows="2"
                                                    value={this.state.customerInfo.customerNote}
                                                    componentClass="textarea"
                                                    bsClass="form-control"
                                                    placeholder="Note"
                                                    maxLength="200"
                                                    onChange={event => {
                                                        const note = event.target.value;
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                customerNote: note
                                                            }
                                                        }));
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                        <legend> </legend> 
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Billing Address: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.billingAddress.address}
                                                        type="text"
                                                        placeholder="Address"
                                                        name="billingAddress"
                                                    //  className={this.state.customerInfo.customerNameState}
                                                        onChange={event => {
                                                        // this.change(event, "address", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                billingAddress: {
                                                                    ...prevState.customerInfo.billingAddress,
                                                                    address: value
                                                                }
                                                            }
                                                            
                                                        }));
                                                        }
                                                        }
                                                    // onBlur={e => this.isValidated()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Row>
                                                        <Col md={4}>
                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Shippng Address: 
                                                
                                                    </div>
                                                        </Col>
                                                        <Col md={8} style={{marginTop: "-32px", marginLeft:"-55px"}}>
                                                        <CustomCheckbox
                                                                name="CheckBox"
                                                                checked={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                                labelText="Same as billing address"
                                                                labelProps={{style: {fontSize: "16px", marginTop:"12px"}}} 
                                                            formControlProps={{
                                                                onChange:(e) => {
                                                                var value = e.target.checked;
                                                                this.setState(prevState => ({
                                                                        ...prevState,
                                                                        customerInfo: {
                                                                            ...prevState.customerInfo,
                                                                            isShippingAddressSameAsBillingAddress: value
                                                                        }
                                                                    }));
                                                                }
                                                            }}  
                                                            />
                                                        </Col>
                                                    </Row>
                                                    
                                                    <FormControl
                                                        value={this.state.customerInfo.billingAddress.address}
                                                        type="text"
                                                        placeholder="Address"
                                                        name="shippingAddress"
                                                        disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    //  className={this.state.customerInfo.customerNameState}
                                                        onChange={event => {
                                                        // this.change(event, "address", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                shippingAddress: {
                                                                    ...prevState.customerInfo.shippingAddress,
                                                                    address: value
                                                                }
                                                            }                                                    
                                                        }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Country: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.billingAddress.country}
                                                        type="text"
                                                        placeholder="Billing Country"
                                                        name="billingCountry"
                                                        onChange={event => {
                                                        //   this.change(event, "customerCompany", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                billingAddress: {
                                                                    ...prevState.customerInfo.billingAddress,
                                                                    country: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        State: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.billingAddress.state}
                                                        type="text"
                                                        placeholder="Billing State"
                                                        name="billingState"
                                                        onChange={event => {
                                                        //   this.change(event, "customerCompany", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                billingAddress: {
                                                                    ...prevState.customerInfo.billingAddress,
                                                                    state: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }                                               
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Country: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.shippingAddress.country}
                                                        type="text"
                                                        placeholder="Shipping Country"
                                                        name="shippingCountry"
                                                        disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                        onChange={event => {
                                                        //   this.change(event, "customerCompany", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                shippingAddress: {
                                                                    ...prevState.customerInfo.shippingAddress,
                                                                    country: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }                                                
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        State: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.shippingAddress.state}
                                                        type="text"
                                                        placeholder="Shipping State"
                                                        name="shippingState"
                                                        disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                        onChange={event => {
                                                        //   this.change(event, "customerCompany", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                shippingAddress: {
                                                                    ...prevState.customerInfo.shippingAddress,
                                                                    state: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        City: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.billingAddress.city}
                                                        type="text"
                                                        placeholder="Billing City"
                                                        name="billingCity"
                                                        onChange={event => {
                                                        //   this.change(event, "customerCompany", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                billingAddress: {
                                                                    ...prevState.customerInfo.billingAddress,
                                                                    city: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Postal Code: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.billingAddress.postalCode}
                                                        type="text"
                                                        placeholder="Billing Postal Code"
                                                        name="billingPostalCode"
                                                        onChange={event => {
                                                        //   this.change(event, "customerCompany", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                billingAddress: {
                                                                    ...prevState.customerInfo.billingAddress,
                                                                    postalCode: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        City: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.shippingAddress.city}
                                                        type="text"
                                                        placeholder="Shipping City"
                                                        name="shippingCity"
                                                        disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                        onChange={event => {
                                                        //   this.change(event, "customerCompany", "length", 3);
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                shippingAddress: {
                                                                    ...prevState.customerInfo.shippingAddress,
                                                                    city: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Postal Code:  
                                                    </div>
                                                    <FormControl
                                                        value={this.state.customerInfo.shippingAddress.postalCode}
                                                        type="text"
                                                        placeholder="Shipping Postal Code"
                                                        name="shippingPostalCode"
                                                        disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,                                                      
                                                                shippingAddress: {
                                                                    ...prevState.customerInfo.shippingAddress,
                                                                    postalCode: value
                                                                }
                                                            }                                                        
                                                        }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewCustomerPopupModal: false })}>Close</Button>
                                        <Button bsStyle="primary" fill wd onClick={e => this.createCustomerSubmit(this.state.customerInfo)} disabled={!this.state.isNewCustomerFormValid}>Save</Button>                                   
                                    </Modal.Footer>                             
                            </Modal>

                            {this.state.showCreateRecurringModal &&
                                <RecurringModal showCreateRecurringModal={this.handleCreateRecurringClick} parentCallback={this.createRecurringCallbackFunction} CustomerRef={this.state.SalesReceipt.CustomerRef} ProfileParams={this.state.ProfileParams} RecurringParams={this.state.RecurringParams} CustomerProfileInfo={this.state.CustomerProfileInfo} IsCreditCard={this.state.IsCreditCard} IsDebitCard={this.state.IsDebitCard} IsACH={this.state.IsACH} />
                            }  
                        </div>
                    }
                />
            </div>
        );
    }
}

export default CreateRecurring;