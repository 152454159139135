import React, { Component } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Panel,
    Tab,
    Nav,
    NavItem,
    Alert,
    InputGroup,
    OverlayTrigger,
    Tooltip,

} from "react-bootstrap";
import Datetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
//import ChartistGraph from "react-chartist";
import Chart from "react-apexcharts";
import Card from "components/Card/Card.jsx";
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import {
    dataPie,
    // dataSales,
    // optionsSales,
    // responsiveSales,
    // dataBar,
    // optionsBar,
    // responsiveBar,
    // table_data
} from "variables/Variables.jsx";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: true,
            transaction_stats: {
                all: 0,
                creditcard: 0,
                ach: 0,
                amount: 0
            },
            // 
            splinePaymentOptions: {
                chart: {
                    height: 350,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#008ffb", '#fb404b'],
                fill: {
                    type: 'gradient'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'date',
                    categories: [
                        "2020-04-01", "2020-04-02", "2020-04-03", "2020-04-04", "2020-04-05", "2020-04-06", "2020-04-07", "2020-04-08", "2020-04-09", "2020-04-10",
                        "2020-04-11", "2020-04-12", "2020-04-13", "2020-04-14", "2020-04-15", "2020-04-16", "2020-04-17", "2020-04-18", "2020-04-19", "2020-04-20",
                        "2020-04-21", "2020-04-22", "2020-04-23", "2020-04-24", "2020-04-25", "2020-04-26", "2020-04-27", "2020-04-28", "2020-04-29", "2020-04-30"
                    ]
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy'
                    },
                },
                legend: {
                    show: false,
                },
            },
            splinePaymentData: [{
                name: 'Approved',
                data: [0]
            },
            // {
            //     name: 'eCheck (ACH)',
            //     data: [11, 32, 45, 32, 34, 52, 41, 65, 27, 75, 11, 32, 45, 32, 34, 52, 41, 65, 27, 75, 11, 32, 45, 32, 34, 52, 41, 65, 27, 75]
            // },
            {
                name: 'Declined',
                data: [0]
            }],
        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    /** HANDLER */
    fromDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.getDashboardStats();
    }

    handleTransactionWidgetClick(transactionStatus, Count) {
        console.log("transaction status is =  " + transactionStatus + "  count = " + Count);
        // this.props.history.push({
        //     pathname: '/qbfinancial/transactions',
        //     state: { invoice_status: transactionStatus.toLowerCase() }
        // });        
    }

    setFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.setFromToDates();
        this.getDashboardStats();
    }

    getDashboardStats = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate
        }
        try {
            for (var d = this.state.from_date; d <= this.state.to_date; d.setDate(d.getDate() + 1)) {
                this.state.splinePaymentOptions.xaxis.categories.push(this.formatDate(d));
            }
            console.log(this.state.splinePaymentOptions.xaxis.categories);
        } catch (error) {
            console.log(error)
        }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getPaymentStatsRequest(query);
    };

    onSetPaymentStatsResult = (result) => {
        let stats = result.payment_stats;
        this.setState({
            transaction_stats: {
                all: stats.total_trans,
                creditcard: stats.credit_card ? parseFloat(stats.credit_card).toFixed(2) : "0.00",
                ach: stats.ach ? parseFloat(stats.ach).toFixed(2) : "0.00",
                amount: stats.amount ? parseFloat(stats.amount).toFixed(2) : "0.00"
            }
        });
        this.setState({ ShowProgressBar: false });
        // this.setState({ payment_stats: { amount: parseFloat(payment_stats.amount).toFixed(2) } })

        // this.setState({
        //     splinePaymentData: [
        //         { name: 'Approved', data: [payment_stats.approved] },
        //         { name: 'Declined', data: [payment_stats.declined] }]
        // })

    }
    /** Api's Implementation */
    // GET request for dashboard stats
    _getPaymentStatsRequest(params = "") {
        // this.setState({ ShowProgressBar: true });
        // this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.QuickBooksDesktop;
        NetworkService.getPaymentStatsRequest(app_id, params)
            .then(data => {
                // this.setState({ ShowProgressBar: false });

                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                        this.onSetPaymentStatsResult(data.data);
                    }

                } else {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }


    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        const customLegenSize = {
            fontSize: "16px",
            fontWeight: "300px",
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} sm={6}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">Total Transactions</Tooltip>}>
                                        <span onClick={this.handleTransactionWidgetClick.bind(this, "all", this.state.transaction_stats.all)}>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-line-chart text-success" style={{ fontSize: "60px", verticalAlign: "bottom" }} />}
                                                statsText="Total Transactions"
                                                statsValue={this.state.transaction_stats.all}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="totalPaymentsToolTip">Total Payments</Tooltip>}>
                                        <span onClick={this.handleTransactionWidgetClick.bind(this, "all")}>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-money text-info" style={{ fontSize: "70px", verticalAlign: "bottom" }} />}
                                                statsText="Total Payments"
                                                statsValue={this.state.transaction_stats.amount}
                                            // statsIcon={<i className="pe-7s-credit" />}
                                            // statsIconText="View now"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="creditCardTransactionToolTip">Credit Card Transactions</Tooltip>}>
                                        <span onClick={this.handleTransactionWidgetClick.bind(this, "all", this.state.transaction_stats.all)}>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-credit-card" style={{ fontSize: "60px", color: "#0075c1", verticalAlign: "bottom" }} />}
                                                statsText="Credit Card Trans."
                                                statsValue={this.state.transaction_stats.creditcard}
                                            // statsIcon={<i className="pe-7s-news-paper" />}
                                            // statsIconText="View now"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={3} sm={6}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="achTransactionToolTip">eCheck(ACH) Transactions</Tooltip>}>
                                        <span onClick={this.handleTransactionWidgetClick.bind(this, "all", this.state.transaction_stats.all)}>
                                            <StatsCard
                                                bigIcon={<i className="fa fa-list-alt text-warning" style={{ fontSize: "60px", verticalAlign: "bottom" }} />}
                                                statsText="eCheck(ACH) Trans."
                                                statsValue={this.state.transaction_stats.ach}
                                            // statsIcon={<i className="pe-7s-news-paper" />}
                                            // statsIconText="View now"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <br />
                            {this.state.ShowProgressBar ?
                                <div>
                                    <CustomProgressBar></CustomProgressBar>
                                    <br />
                                </div>
                                : ""
                            }
                            {/* <Row>
                                <Col md={12}>
                                    <Card
                                        title="Daily Transactions Graph"
                                        ctTextCenter
                                        content={
                                            <Chart
                                                options={this.state.splinePaymentOptions}
                                                series={this.state.splinePaymentData}
                                                type="area" height={350} />
                                        }
                                        legend={
                                            <div className="text-center">
                                                <span style={customLegenSize}> <i className="fa fa-circle" style={{ color: "#008ffb" }} /> Approved </span>
                                                <span style={customLegenSize}><i className="fa fa-circle text-danger" style={{ color: "#F44336" }} /> Delined </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                         */}
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Dashboard;