/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Standalone/Dashboard.jsx";
import Settings from "views/Standalone/Settings.jsx";
import Transactions from "views/Standalone/Transactions.jsx";
import Invoices from "views/Standalone/Invoices.jsx";
import Recurring from "views/Online/Recurring.jsx";
import SalesReceipt from "views/Standalone/CreateSalesReceipt";
import AuthNetPayment from "views/Online/AuthNetPayment.jsx";
import NMIPayment from "views/Online/NMIPayment.jsx";
import CCPayment from "views/Online/CCPayment.jsx";
import invoiceDetail from "views/Online/Detail.jsx"

var StandaloneRoutes = [
  {
    path: "/dashboard",
    layout: "/standalone",
    name: "Dashboard",
    icon: "fa fa-dashboard",
    component: Dashboard
  },
  {
    path: "/invoices",
    layout: "/standalone",
    name: "Invoices",
    icon: "fa fa-newspaper-o",
    component: Invoices
  },
  // {
  //   path: "/payment",    
  //   name: "payment",
  //   component: AuthNetPayment,
  //   layout: "/invoice"
  // },
  // {
  //   path: "/nmipayment",    
  //   name: "payment",
  //   component: NMIPayment,
  //   layout: "/invoice"
  // },
  // {
  //   path: "/ccpayment",    
  //   name: "payment",
  //   component: CCPayment,
  //   layout: "/invoice"
  // },
  // {
  //   path: "/detail",
  //   name: "invoice detail",
  //   component: invoiceDetail,
  //   layout: "/invoice"
  // },
   {
    path: "/salesreceipt",
    layout: "/standalonecreate",
    name: "Sales Receipt",
    icon: "fa fa-file-text-o",
    component: SalesReceipt
  },
  {
    path: "/transactions",
    layout: "/standalone",
    name: "Transactions",
    icon: "fa fa-money",
    component: Transactions
  },
  {
    path: "/settings",
    layout: "/standalone",
    name: "Settings",
    icon: "fa fa-gears",
    component: Settings
  }
];

export default StandaloneRoutes;
