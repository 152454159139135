// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import Input from "@material-ui/core/Input";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
  
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};
  
`;

const DangerText = styled.p`
  font-size: 1.2rem;
  margin: 5px 0 0 0;
  color: #ff3860;
  ${({ styled }) => ({ ...styled })};
`;

const BACKSPACE_KEY_CODE = 8;
global.isRoutingNumberValid = false;

type Props = {
  onError?: Function,
  containerClassName: string,
  dangerTextClassName: string,
  dangerTextStyle: Object,
  fieldClassName: string,
  fieldStyle: Object,
  inputComponent: Function | Object | string,
  invalidClassName: string,
  invalidStyle: Object,
  customTextLabels: Object,
  formControlProps:  PropTypes.object,
  classes: PropTypes.object.isRequired,
};
type State = {
  errorText: ?string
};

const inputRenderer = ({ inputComponent, props }: Object) => {
  const Input = inputComponent || 'input';
  return <Input {...props} />;
};

class RoutingNumberInput extends Component<Props, State> {
  static defaultProps = {
    containerClassName: '',
    dangerTextClassName: '',
    dangerTextStyle: {},
    fieldClassName: '',
    fieldStyle: {},
    inputComponent: 'input',
    invalidClassName: 'is-invalid',
    invalidStyle: {},
    customTextLabels: {},
    formControlProps:{},
    classes: {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      errorText: null
    };
  }

  checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key) || e.target.value.length > 8) {
      e.preventDefault();
    }
  };


  handleRoutingNumberBlur = (
    { onBlur }: { onBlur?: ?Function } = { onBlur: null }
  ) => (e: SyntheticInputEvent<*>) => {
   // alert(e.target.value);
    var regex=/^\d{9}$/;
    const { customTextLabels } = this.props;
    this.setFieldValid();
    if(!regex.test(e.target.value))     
    {
        global.isRoutingNumberValid = false;
        this.setFieldInvalid(
          customTextLabels.invalidCvc || 'Routing number is invalid',
          'RoutingNumber'
        );
    }          
  };
  
  handleRoutingNumberKeyPress = (e: any) => {
     this.checkIsNumeric(e);
  };

  handleKeyDown = (ref: any) => {
    return (e: SyntheticInputEvent<*>) => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
        ref.focus();
      }
    };
  };

  handleRoutingNumberChange = (
    { onChange }: { onChange?: ?Function } = { onChange: null }
  ) => (e: SyntheticInputEvent<*>) => {
    const { customTextLabels } = this.props;
    const routingNumberLength = e.target.value.length;    

    this.setFieldValid();
    if (routingNumberLength > 17 || routingNumberLength < 4) {
     
        global.isRoutingNumberValid = false;
        this.setFieldInvalid(
          customTextLabels.invalidCvc || 'Routing number is invalid',
          'RoutingNumber'
        );
    }
    else
    {
        global.isRoutingNumberValid = true;
    }
  };

  setFieldInvalid = (errorText: string, inputName?: string) => {
    
    this.setState({ errorText });

  };

  setFieldValid = () => {
    const { invalidClassName } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.remove(invalidClassName);
    this.setState({ errorText: null });
  };

  render = () => {
    const { errorText } = this.state;
    const {
      dangerTextClassName,
      dangerTextStyle,
      fieldClassName,
      fieldStyle,
      inputComponent,
      invalidStyle,
      classes,
      formControlProps,
      error,
      success,
      labelText,
      id,
      value,
      labelProps,
      inputProps
    } = this.props;

    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    
    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });    
    
    return (
      <FormControl 
      {...formControlProps}
      className={formControlProps.className}
      >   
        <FieldWrapper
          id="field-wrapper"
          className={fieldClassName}
          styled={fieldStyle}
          invalidStyled={invalidStyle}
        >
          {labelText !== undefined ? (
        <InputLabel
          className= {!errorText && classes.labelRoot + labelClasses} 
          htmlFor={id}
          {...labelProps}
        >
            {labelText}
            </InputLabel>
          ) : null}
          <Input
             data-max="9999999999999"
                name="RoutingNumber" 
                value={value}
                id="RoutingNumber"
                 maxLength= '4'
                 minLength= '17'
                autoComplete= 'off'
                classes={{
                  root: marginTop,
                  disabled: classes.disabled,
                  underline: underlineClasses
               }}
                type= 'tel'
                onBlur= {this.handleRoutingNumberBlur()}
                onChange= {this.handleRoutingNumberChange()}
                onKeyPress= {this.handleRoutingNumberKeyPress}
                
                fullWidth= {true}
                {...inputProps}
          >
          
          </Input>
          
        </FieldWrapper>
        {errorText && (
          <DangerText className={dangerTextClassName} styled={dangerTextStyle}>
            {errorText}
          </DangerText>
        )}
      </FormControl>
    );
  };
}

export default  withStyles(customInputStyle)(RoutingNumberInput);
