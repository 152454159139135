import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
    FormControl,

} from "react-bootstrap";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from "react-select";
import Datetime from "react-datetime";
import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import Chip from '@material-ui/core/Chip';
import NetworkService from "services/NetworkService";
import BatchEmailModule from 'views/Components/BatchEmailModule.jsx';
// import ReactToPrint from 'react-to-print';

class InvoiceByUser extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            invoiceStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                 { value: "open,close,paid,past_due", label: "All" },
                { value: "open", label: "Open" },
                { value: "past_due", label: "Past Due" },
                { value: "paid", label: "Paid" },
                { value: "close", label: "Canceled" },
            ],
            filter_invoice: { value: "open,close,paid,past_due", label: "All" },
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            showActionButtons: "none",
            showInvoiceSendButton: "none",
            showInvoiceCloseButton: "none",
            showCreatePaymentLinkButton: "none",
            showPrintButton: "none",
            showBatchEmail: "none",
            showSendInvoiceModal: false,
            showCloseInvoiceModal: false,
            showCreateEmailReminderModal: false,
            showCreatePaymentLinkInvoiceModal: false,
            errorSendInvoiceEmail: "",
            sendInvoiceClassName: "",
            customerEmail: "ahsan766@gmail.com",
            rawInvoiceData: [],
            selectedInvoiceIds: "",
            data: [],
            columns: [
                {
                    title: 'Inv. ID',
                    field: 'invoicenumber',
                    cellStyle: {
                        textAlign: "left",
                    },
                    headerStyle: {
                        textAlign: "left",
                    },
                    customFilterAndSearch: (term, rowData) => (rowData.invoicenumber.props.children.props.children.toLowerCase()).indexOf(term) != -1                   
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'Patient Name',
                    field: 'merchantDefinedField1',
                    headerStyle: {
                        textAlign: "left",
                    },
                },                
                {
                    title: 'Inv. #',
                    field: 'merchantDefinedField3',
                    type: 'date',
                    headerStyle: {
                        textAlign: "left",
                    }
                },
                {
                    title: 'Inv. Date',
                    field: 'invoicedate',
                    type: 'date',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                
                // {
                //     title: 'Balance',
                //     field: 'balance',
                //     cellStyle: {
                //         textAlign: "center",
                //     },
                // },
                {
                    title: 'Amount',
                    field: 'total',
                    cellStyle: {
                        textAlign: "center",
                    },
                },
                {
                    title: 'Inv. Status',
                    field: 'invoicestatus',
                    headerStyle: {
                        textAlign: "right"
                    },
                     cellStyle: {
                    //     width: 'calc(13%)',
                         textAlign: "center",
                     },
                },
                {
                    title: 'Email Status',
                    field: 'emailstatus',
                    cellStyle: {
                        textAlign: "center",
                    },
                }
            ],
            options: {
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                selectionProps: rowData => ({
                    color: 'primary',
                }),
                showTitle: false,
                actionsColumnIndex: -1,
                loadingType: "overlay",
                rowStyle: rowData => ({
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"
                }),
            },
            createInvoiceToolTip: <Tooltip id="createInvoiceToolTip">Create Invoice</Tooltip>,
            printInvoiceToolTip: <Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>,
            batchEmailInvoiceToolTip: <Tooltip id="batchEmailInvoiceToolTip">Send Selected Invoices</Tooltip>,
            sendInvoiceToolTip: <Tooltip id="sendInvoiceToolTip">Email Invoice</Tooltip>,
            createPaymentLinkInvoiceToolTip: <Tooltip id="createPaymentLinkInvoiceToolTip">Create Payment Link</Tooltip>,
            closeInvoiceToolTip: <Tooltip id="closeInvoiceToolTip">Cancel Invoice</Tooltip>,
            createEmailReminderToolTip: <Tooltip id="createEmailReminderToolTip">Create Email Reminder</Tooltip>,
            collectPaymnetToolTip: <Tooltip id="collectPaymnetToolTip">Collect Payment</Tooltip>,
            invoiceLinkToolTip: <Tooltip id="invoiceLinkToolTip">Invoice Link</Tooltip>,
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={this.state.createInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateInvoice(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>                            
                            <OverlayTrigger placement="top" overlay={this.state.batchEmailInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleBatchEmailClick(e)} style={{ marginRight: '10px', display: this.state.showBatchEmail }}> <i className="fa fa-send" ></i> </Button>
                            </OverlayTrigger>                           
                            <OverlayTrigger placement="top" overlay={this.state.sendInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleSendInvoice(e)} style={{ marginRight: '10px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-envelope" ></i> </Button>
                            </OverlayTrigger>
                            {/* <OverlayTrigger placement="top" overlay={this.state.createPaymentLinkInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreatePaymentLinkPopup(e)} style={{ marginRight: '10px', display: this.state.showCreatePaymentLinkButton }}> <i className="fa fa-link" ></i> </Button>
                            </OverlayTrigger> */}
                            <OverlayTrigger placement="top" overlay={this.state.closeInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCloseInvoice(e)} style={{ marginRight: '10px', display: this.state.showInvoiceCloseButton }}> <i className="fa fa-close" ></i> </Button>
                            </OverlayTrigger>
                            
                            {/* <OverlayTrigger placement="top" overlay={this.state.createEmailReminderToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateEmailReminder(e)} style={{ marginRight: '10px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-calendar-check-o" ></i> </Button>
                            </OverlayTrigger> */}
                            <OverlayTrigger placement="top" overlay={this.state.collectPaymnetToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePaymentInvoice(e)} style={{ marginRight: '10px', display: this.state.showActionButtons }}> <i className="fa fa-credit-card" ></i> </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
            },
            singleEmailInvoiceData: {
                customerName: "",
                customerEmail: "",
                invoiceNumber: "",
                balance: "",
                index: "",
                invoiceStatus: "",
                canceledDate: "",
            },
            emailReminderInfo: {
                dayFrequency: "31",
                numberOfReminders: "0",
            },
            IsEmailUntilPaid: true,
            IsEmailTheCustomerTimes: false,
            selectedInvoiceIndex: [],
            showBatchEmailModal: false,
            BatchEmailInvoices: [],
            isInvoiceLinkCreated: false,
            popupErrorMessage: "" ,
            invoicePaymentLink: "" ,
            users: [],
            user:"",
        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }

        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        }

        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        
        // if(this.props.location.state && this.props.location.state.invoice_status)
        // {
        //     let invoiceStatusFilter = this.props.location.state ? this.props.location.state.invoice_status ? this.props.location.state.invoice_status : "open" : "open";
        //     this.state.filter_invoice = { value: invoiceStatusFilter, label: invoiceStatusFilter[0].toUpperCase() + invoiceStatusFilter.slice(1) }
        // }        
        this.GetUsers(); 
        document.getElementById("loaderDiv").style.display = "none";
        this.SetFromToDates();
      //  this.filterInvoice();

        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    GetUsers() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing;
        NetworkService.getDependentUsersRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setUsersResult(data.data.items);
                } 
            }).catch(error => {
                this.filterTransactions()
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setUsersResult(users) {  
        if(users != null && users != undefined){
            const newState = []; 
            newState.push({ value: "", label: "Select Term", isDisabled: true });           
            users.map(data => {
                const x = { value: data["user_id"], label: data["user_name"] }
                newState.push(x);
            });               
            this.state.user = newState[1];                
            if(this.state.user == "" && newState.length >= 1)
            {
                this.setState({ user: newState[1] });        
            }
            this.setState({ users: newState });            
        }
        this.filterInvoice()
    }

    handleBatchEmailClick(e) {       
       this.setState({ showBatchEmailModal: true });       
    }

    batchEmailCallbackFunction = (childData) => {
        this.setState({ showBatchEmailModal: childData })
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    /** HANDLER */
    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.filterInvoice();
    }

    handleCreateInvoice(e) {
        this.props.history.push('/nmi/createinvoice');
        e.preventDefault();
    }

    handlePaymentInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
        });
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        this.props.history.push({
            pathname: '/nmi/nmipayment',
            state: { invoiceData: invoice }
        });
    }

    handleSendInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const x = {
                customerName: invoice.CustomerRef.name,
                customerEmail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            if (x.customerEmail != "") {
                this.setState({ sendInvoiceClassName: "valid" });
            }
            else {
                this.setState({ sendInvoiceClassName: "" });
            }
            this.setState({ singleEmailInvoiceData: x })
        })
        this.setState({ showSendInvoiceModal: true })
    }

    handleCreatePaymentLinkPopup(e){
        e.preventDefault();
        this.setState({ isInvoiceLinkCreated: false });
        this.setState({ popupErrorMessage: "" });
        this.setState({ invoicePaymentLink: "" });  
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const data = {
                customerId: invoice.CustomerRef.value,
                customerName: invoice.CustomerRef.name,
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            
            this.setState({ singleEmailInvoiceData: data })
        })
        this.setState({ showCreatePaymentLinkInvoiceModal: true });
    }

    handleCreatePaymentLinkInvoice(e){
        let invoice = null;
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
        })   
        const payload = {               
            "customer_id": invoice.CustomerRef.value,
            "customer_name": invoice.CustomerRef.name || null,
            "invoice_id": invoice.Id,                
            "invoice_number": invoice.MerchantDefinedField3 || null,
        }
        this._postCreatePaymentLinkInvoiceRequest(payload);
    }

    handleCloseInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const data = {
                invoiceId: invoice.DocNumber,
                customerName: invoice.CustomerRef.name,
                invoiceStatus: invoice.InvoiceStatus,
                customerEmail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            this.setState({ singleEmailInvoiceData: data });
            //this._postCloseInvoiceRequest(data);
        })

        this.setState({ showCloseInvoiceModal: true })
    }

    handleCreateEmailReminder(e) {
        e.preventDefault();
        this.setState({ IsEmailUntilPaid: true });
        this.setState({ IsEmailTheCustomerTimes: false });
        this.setState(prevState => ({
            ...prevState,
            emailReminderInfo: {
                ...prevState.emailReminderInfo,
                numberOfReminders: "0",
                dayFrequency: "31"
            }
        }));
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const x = {
                customerName: invoice.CustomerRef.name,
                customerEmail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            this.setState({ singleEmailInvoiceData: x })
        })
        this.setState({ showCreateEmailReminderModal: true })
    }

    handleEmailReminderTimeRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "emailuntilpaid") {
            this.setState({ IsEmailUntilPaid: true });
            this.setState({ IsEmailTheCustomerTimes: false });
            this.setState(prevState => ({
                ...prevState,
                emailReminderInfo: {
                    ...prevState.emailReminderInfo,
                    numberOfReminders: "0"
                }
            }));
        }
        else {
            this.setState({ IsEmailTheCustomerTimes: true });
            this.setState({ IsEmailUntilPaid: false });
            this.setState(prevState => ({
                ...prevState,
                emailReminderInfo: {
                    ...prevState.emailReminderInfo,
                    numberOfReminders: "1"
                }
            }));
        }
    }

    filterInvoice = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
            "status": this.state.filter_invoice.value,
            "user": this.state.user.value
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');
        this.setState({ showBatchEmail: "none" });
        this.getInvoiceDetailsRequest(query);
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleSendInvoiceEmail(e) {
        if (this.state.sendInvoiceClassName === "valid") {
            e.preventDefault();
            let invoice = [];
            this.state.selectedInvoiceIndex.map(index => {
                invoice.push(this.state.rawInvoiceData[index]);
            })
            const customer_email = this.state.singleEmailInvoiceData.customerEmail
            const payload = {
                "ToEmails": [customer_email],
                "Invoices": invoice
            }
            this.postEmailInvoiceRequest(payload);
        }
    }

    handleCloseInvoiceStatus(e) {
        e.preventDefault();        
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const data = {
                invoiceId: invoice.DocNumber,
                customerName: invoice.CustomerRef.name,
                invoiceStatus: invoice.InvoiceStatus,
                customerEmail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
                canceledDate: this.formatDate(new Date())
            }
            this.setState({ singleEmailInvoiceData: data });
            this._postCloseInvoiceRequest(data);
        })
    }

    handleCreateEmailReminderSubmit(e) {
        e.preventDefault();
        let invoice = null;
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
        })
        const customer_email = this.state.singleEmailInvoiceData.customerEmail
        const emailReminderInfo = this.state.emailReminderInfo
        console.log(invoice)
        let payload = {
            "invoice_id": invoice.Id,
            "doc_number": invoice.DocNumber,
            "customer_id": invoice.CustomerRef.value,
            "customer_name": invoice.CustomerRef.name,
            "customer_email": customer_email,
            "interval": emailReminderInfo.dayFrequency,
            "frequency": emailReminderInfo.numberOfReminders,
            "invoice":invoice,
            "EmailReminderInfo": emailReminderInfo,
        }
        this.postInvoiceEmailReminderRequest(payload);
    }

    handleKeyPressInput(e) {
        var charCode = (e.which) ? e.which : e.keyCode;
        if (charCode === 13 && this.state.sendInvoiceClassName === "valid") {
            this.handleSendInvoiceEmail(e);
        }
    };

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            singleEmailInvoiceData: {
                ...prevState.singleEmailInvoiceData,
                customerEmail: value
            }
        }));
        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendInvoiceEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )
            });
            this.setState({
                sendInvoiceClassName: "error"
            });
        }
        else {
            this.setState({ errorSendInvoiceEmail: null });
            this.setState({ sendInvoiceClassName: "valid" });
        }
    }

    handleInvoiceDetailClick(invoice) {
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        this.props.history.push('/nmi/detail/' + invoice.Id + '/' + this.state.user.value);
    }

    handleSelectedRowData(data, length) {
        let newStates = [];
        let invoiceIds = "";
        this.state.BatchEmailInvoices = [];
        data.map(d => {
            newStates.push(d.index);
            invoiceIds = invoiceIds + "," + d.id;
            let invoice = this.state.rawInvoiceData[d.index];
            if(invoice.IsPaid === false && invoice.InvoiceStatus !="closed" && invoice.BillEmail != null && invoice.BillEmail.Address != null && invoice.BillEmail.Address != ""){
                this.state.BatchEmailInvoices.push(invoice)
            }

        });
        this.state.selectedInvoiceIndex = newStates;
        this.state.selectedInvoiceIds = this.replaceAt(invoiceIds, 0, '');
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    if (data.statuscode === 0) {
                        let processor = data.data.items;
                        this.setProcessorResult(processor);
                    }
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setProcessorResult = (processors) => {
        let tokenize_key = null;
        let processor = null;
        processors.map(data => {
            if (data.is_default == true) {
                processor = data;
            }
        });

        if (processor != null) {
            if (processor.method_type == "nmi") {
                tokenize_key = JSON.stringify({ "PPType": "nmi", "PPToken": processor.TokenizationKey })
            } else if (processor.method_type == "cardconnect") {
                tokenize_key = JSON.stringify({ "PPType": "cardconnect", "PPToken": processor.configuration.URL })
            }
        }
        localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
    }

    /** API CALLING */

    // GET request for invoice details
    getInvoiceDetailsRequest(params = "") {
        this.setState({ ShowProgressBar: true });
        const url = BASE_URL + "api/v1/invoice/member/?" + params;
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.onSetResult(data.data);
                this.setState({ ShowProgressBar: false });
            } else {
                this.setState({ ShowProgressBar: false });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

    onSetResult = (result) => {
        const newSeries = [];
        var showCustomField1 = false;
        var showCustomField2 = false;
        result.items.map((invoice, index) => {
            const data = {
                index: index,
                id: invoice.Id,
                invoicenumber: //invoice.DocNumber,
                <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                        <a className="invoicelink" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}>
                            {invoice.DocNumber}
                        </a>
                    </OverlayTrigger>,
                customername: invoice.CustomerRef ? invoice.CustomerRef.name : "(Not Specified)",
                customeremail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                merchantDefinedField1: invoice.MerchantDefinedField1 ? invoice.MerchantDefinedField1 : "" + " " + invoice.MerchantDefinedField2 ? invoice.MerchantDefinedField2 : "",
               // merchantDefinedField2: invoice.MerchantDefinedField2,
                merchantDefinedField3: invoice.MerchantDefinedField3,
                invoicedate: invoice.TxnDate,
                duedate: invoice.DueDate,
                balance: '$' + parseFloat(invoice.Balance).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
                total: '$' + parseFloat(invoice.TotalAmt).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
                hiddeninvoicestatus: invoice.InvoiceStatus,
                invoicestatus: (invoice.InvoiceStatus === "open") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"Open"}></Chip>
                    : (invoice.InvoiceStatus === "paid") ? <Chip style={{ backgroundColor: "#00e396", color: "white", height: 25 }} label={"Paid"}></Chip>
                        : (invoice.InvoiceStatus === "partially paid") ? <Chip style={{ backgroundColor: "#05C8FF", color: "white", height: 25 }} label={"partially paid"}></Chip>
                            : (invoice.InvoiceStatus === "past due") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"past due"}></Chip>
                                : (invoice.InvoiceStatus === "closed") ? <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label={"Canceled"}></Chip>
                                : "",
               
                //paymentstatus: invoice.IsPaid ? <Chip style={{ backgroundColor: "#00e396", color: "white", height: 25 }} label="Paid"></Chip> : parseFloat(invoice.Balance).toFixed(2) < parseFloat(invoice.TotalAmt).toFixed(2) ? <Chip style={{ backgroundColor: "#feb019", color: "white", height: 25 }} label="Partially Paid"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Unpaid"></Chip>,
                // action: <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                //     <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}> <i className="fa fa-clipboard" ></i> </Button>
                // </OverlayTrigger>,
                emailstatus: (invoice.SendGridEvent === "not sent") ? <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label={"Not Sent"}></Chip>
                    : (invoice.SendGridEvent === "read") ? <Chip style={{ backgroundColor: "#00e396", color: "white", height: 25 }} label={"Read"}></Chip>
                        : (invoice.SendGridEvent === "delivered") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"Delivered"}></Chip>
                            : (invoice.SendGridEvent === "email sent") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"Sent"}></Chip>
                                : (invoice.SendGridEvent === "click") ? <Chip style={{ backgroundColor: "#05C8FF", color: "white", height: 25 }} label={"Clicked"}></Chip>
                                    : (invoice.SendGridEvent === "processed") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"Processed"}></Chip>
                                        : (invoice.SendGridEvent === "open") ? <Chip style={{ backgroundColor: "#775DFF", color: "white", height: 25 }} label={"Opened"}></Chip>
                                            : (invoice.SendGridEvent === "spamreport") ? <Chip style={{ backgroundColor: "#FF88FB", color: "white", height: 25 }} label={"Spam Report"}></Chip>
                                                : (invoice.SendGridEvent === "bounce") ? <Chip style={{ backgroundColor: "#ffa534", color: "white", height: 25 }} label={"Bounced"}></Chip>
                                                    : "",
            }

            if(data.merchantDefinedField1 != null && data.merchantDefinedField1 != "")
            {
                showCustomField1 = true;
            }
            if(data.merchantDefinedField3 != null && data.merchantDefinedField3 != "")
            {
                showCustomField2 = true;
            }

            newSeries.push(data);

        });
        this.setState({ rawInvoiceData: result.items });
        if(!showCustomField2)
        {
            this.state.columns[3].name = "";
            this.state.columns[3].hidden = true;
            this.state.columns[3].width = -1;
        }
        if(!showCustomField1)
        {
            this.state.columns[2].name = "";
            this.state.columns[2].hidden = true;
            this.state.columns[2].width = -1;
        }

        this.setState({ data: newSeries });
    }

    // POST request
    postEmailInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/invoice/email/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        fetch(url, {
            method: "POST",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "ToEmails": data["ToEmails"],
                "Invoices": data["Invoices"]
            })
        }).then(response => {
            return response.json()
        }).then(data => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendInvoiceModal: false });
            if (data != null && data.statuscode == 0) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            } else {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            }
        }).catch(error => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendInvoiceModal: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

    _postCreatePaymentLinkInvoiceRequest(data){
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ isInvoiceLinkCreated: false });
        this.setState({ popupErrorMessage: "" });
        this.setState({ invoicePaymentLink: "" });  

        const app_id = ApplicationIDEnum.NMIInvoicing;

        NetworkService.postInvoiceLinkRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });                

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.statuscode == 0){
                        this.setState({ popupErrorMessage: data.statusmessage });    
                        this.setState({ invoicePaymentLink: data.data.url });  
                        this.setState({ isInvoiceLinkCreated: true });
                    }
                    else{
                        this.setState({ popupErrorMessage: data.statusmessage });
                    }
                }
                else {
                    this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
                }                
                setTimeout(() => {
                    this.setState({ popupErrorMessage: "" })
                }, 6000);
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ popupErrorMessage: "" })
                }, 5000);
            });
    }

    copyLinkToClipboard (e) {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        var copyText = document.getElementById("txtInvoicePaymentLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);

        el.value = copyText.value;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        this.setState({ errorCode: 0 });
        this.setState({ popupErrorMessage: "Invoice payment link copied successfully." });
        setTimeout(() => {
            this.setState({ popupErrorMessage: "" })
        }, 6000);
    }

    _postCloseInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing;

        NetworkService.postCloseInvoiceRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showCloseInvoiceModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if( data.statuscode == 0){
                        this.setState({ errorMessage: "Invoice Canceled Successfully" });
                    }
                    else{                        
                        this.setState({ errorMessage: data.statusmessage });
                    }                    
                    this.filterInvoice();
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showCloseInvoiceModal: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    //Post email reminder    
    postInvoiceEmailReminderRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing;

        NetworkService.postInvoiceReminder(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showCreateEmailReminderModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showCreateEmailReminderModal: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    handlePrintClick(e) {
        e.preventDefault();
        let invoiceIds = "";
        this.state.selectedInvoiceIndex.map(index => {
            invoiceIds = invoiceIds + "," + (this.state.rawInvoiceData[index].Id);
        })
        invoiceIds = this.replaceAt(invoiceIds, 0, '');
    }


    replaceAt(str, index, ch) {
        return str.replace(/./g, (c, i) => i === index ? ch : c)
    }

    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>Invoice Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="invoiceStatus"
                                            value={this.state.filter_invoice}
                                            onChange={value => {
                                                this.setState({ filter_invoice: value });
                                            }
                                            }
                                            options={this.state.invoiceStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>User</span>                                   
                                    <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="users"
                                            isDisabled={this.state.ShowProgressBar}
                                            value={this.state.user}
                                            onChange={value => {
                                                this.setState({ user: value }); 
                                            }
                                            }
                                            options={this.state.users}
                                        />
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>

                                        }
                                        <MaterialTable
                                            //title="Styling with MuiThemeProvider Preview"
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            onSelectionChange={(rows) => {
                                                this.handleSelectedRowData(rows, rows.length);                                                    
                                                if (rows.length > 0) {
                                                    this.setState({ showPrintButton: "" });
                                                    if (rows.length === 1 && rows[0].hiddeninvoicestatus != "closed") {
                                                        let invoice = {};
                                                        this.state.selectedInvoiceIndex.map(index => {
                                                            invoice = this.state.rawInvoiceData[index];
                                                        });
                                                        if (invoice.IsPaid) {
                                                            this.setState({ showInvoiceSendButton: "none" });
                                                            this.setState({ showActionButtons: "none" });
                                                            this.setState({ showInvoiceCloseButton: "none" });
                                                            this.setState({ showCreatePaymentLinkButton: "none" });
                                                        }
                                                        else {
                                                            this.setState({ showInvoiceSendButton: "" });
                                                            this.setState({ showCreatePaymentLinkButton: "" });  
                                                            this.setState({ showActionButtons: "" });
                                                            if(invoice.InvoiceStatus != "partially paid" && invoice.InvoiceStatus != "paid"){
                                                                this.setState({ showInvoiceCloseButton: "" });
                                                            }
                                                            else{
                                                                this.setState({ showInvoiceCloseButton: "none" });
                                                            }
                                                        }                                                       
                                                        this.setState({ showBatchEmail: "none" });                                                        
                                                    }
                                                    else {
                                                        this.setState({ showInvoiceSendButton: "none" });
                                                        this.setState({ showCreatePaymentLinkButton: "none" });
                                                        this.setState({ showActionButtons: "none" });
                                                        this.setState({ showBatchEmail: "none" });
                                                        if (rows.length > 1 && rows.length <= 10) {
                                                            this.setState({ showBatchEmail: "" });
                                                        }
                                                        else{
                                                            this.setState({ showBatchEmail: "none" });
                                                        }
                                                        this.setState({ showInvoiceCloseButton: "none" });
                                                    }
                                                }
                                                else {
                                                    this.setState({ showPrintButton: "none" });
                                                    this.setState({ showActionButtons: "none" });
                                                    this.setState({ showInvoiceSendButton: "none" });
                                                    this.setState({ showCreatePaymentLinkButton: "none" });
                                                    this.setState({ showInvoiceCloseButton: "none" });
                                                    
                                                }
                                            }
                                            }
                                            components={this.state.components}

                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showSendInvoiceModal}
                                        onHide={() => this.setState({ showSendInvoiceModal: false })}
                                        backdrop="static">
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer Email :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerEmail}</h5>
                                                        {/* <FormGroup >
                                                            <FormControl
                                                                value={this.state.singleEmailInvoiceData.customerEmail}
                                                                placeholder="Email"
                                                                type="email"
                                                                autoComplete="off"
                                                                className={this.state.sendInvoiceClassName}
                                                                onChange={event => this.validateEmail(event)}
                                                                onKeyPress={event => this.handleKeyPressInput(event)}
                                                            />
                                                            {this.state.errorSendInvoiceEmail}
                                                        </FormGroup> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleSendInvoiceEmail(e)}>Send</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showCloseInvoiceModal}
                                        onHide={() => this.setState({ showCloseInvoiceModal: false })}
                                        backdrop="static">
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Cancel Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Amount :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice Status :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceStatus}</h5>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showCloseInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleCloseInvoiceStatus(e)}>Cancel Invoice</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showCreateEmailReminderModal}
                                        onHide={() => this.setState({ showCreateEmailReminderModal: false })}
                                        backdrop="static">
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Invoice - Email Reminder</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer Email :</h5>
                                                    </Col>
                                                    <Col md={8} >
                                                        <FormGroup >
                                                            <FormControl
                                                                value={this.state.singleEmailInvoiceData.customerEmail}
                                                                placeholder="Email"
                                                                focus={true}
                                                                type="email"
                                                                autoComplete="off"
                                                                className={this.state.sendInvoiceClassName}
                                                                onChange={event => this.validateEmail(event)}
                                                            />
                                                            {this.state.errorSendInvoiceEmail}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div style={{ textAlign: "left", marginLeft: "13px", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "16px", fontWeight: "bold", lineHeight: "3em" }}>
                                                            Set how often you want to send email to the customer
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <h5>Email the customer every </h5>
                                                    </Col>
                                                    <Col md={4}>
                                                        <InputGroup>
                                                            <FormControl
                                                                value={this.state.emailReminderInfo.dayFrequency}
                                                                type="number"
                                                                name="dayFrequency"
                                                                style={{ textAlign: "center" }}
                                                                min="1"
                                                                step="1"
                                                                max="31"
                                                                onChange={event => {
                                                                    var value = event.target.value;
                                                                    this.setState(prevState => ({
                                                                        ...prevState,
                                                                        emailReminderInfo: {
                                                                            ...prevState.emailReminderInfo,
                                                                            dayFrequency: value
                                                                        }
                                                                    }));
                                                                }
                                                                }
                                                            />
                                                            <InputGroup.Addon>Days</InputGroup.Addon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} style={{ marginLeft: "20px" }}>
                                                        <Radio
                                                            number="emailuntilpaid"
                                                            option="emailuntilpaid"
                                                            name="ReminderTimeRadio"
                                                            onChange={this.handleEmailReminderTimeRadio}
                                                            checked={this.state.IsEmailUntilPaid}
                                                            className="PrivateSwitchBase-input-538"
                                                            label="Email until paid"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} style={{ marginLeft: "33px" }}>
                                                        <Radio
                                                            number="emailthecustomer"
                                                            option="emailthecustomer"
                                                            name="ReminderTimeRadio"
                                                            onChange={this.handleEmailReminderTimeRadio}
                                                            checked={this.state.IsEmailTheCustomerTimes}
                                                            className="PrivateSwitchBase-input-538"
                                                            label="Email the customer"
                                                        />
                                                    </Col>
                                                    <Col md={4} style={{ marginLeft: "15px" }}>
                                                        <InputGroup>
                                                            <FormControl
                                                                value={this.state.emailReminderInfo.numberOfReminders}
                                                                type="number"
                                                                disabled={!this.state.IsEmailTheCustomerTimes}
                                                                name="numberOfReminders"
                                                                style={{ textAlign: "center" }}
                                                                min="1"
                                                                step="1"
                                                                onChange={event => {
                                                                    var value = event.target.value;
                                                                    this.setState(prevState => ({
                                                                        ...prevState,
                                                                        emailReminderInfo: {
                                                                            ...prevState.emailReminderInfo,
                                                                            numberOfReminders: value
                                                                        }
                                                                    }));
                                                                }
                                                                }
                                                            />
                                                            <InputGroup.Addon>Times</InputGroup.Addon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showCreateEmailReminderModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleCreateEmailReminderSubmit(e)}>Save</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>

                            {/* Create Payment Link Modal */}
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showCreatePaymentLinkInvoiceModal}
                                        onHide={() => this.setState({ showCreatePaymentLinkInvoiceModal: false })}
                                        backdrop="static"
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Create Payment Link</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {this.state.popupErrorMessage &&
                                                <div>
                                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                        <span>
                                                            {this.state.popupErrorMessage}
                                                        </span>
                                                    </Alert>
                                                </div>
                                            }
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                {this.state.isInvoiceLinkCreated && !this.state.ShowPopupProgressBar &&
                                                    <Row>
                                                        <Col md={9} className="text-left">
                                                            <FormControl
                                                                    value={this.state.invoicePaymentLink}
                                                                    type="text"
                                                                    id="txtInvoicePaymentLink"
                                                                    readOnly={true}
                                                                    style={{ height: "30px", marginTop: "10px" }}                                                                
                                                                />
                                                        </Col>
                                                        <Col md={3} className="text-left">
                                                            <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={e => this.copyLinkToClipboard(e)}>
                                                                <i className="fa fa-copy"></i> Copy Link
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showCreatePaymentLinkInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleCreatePaymentLinkInvoice(e)}>Create Link</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {this.state.showBatchEmailModal &&
                                        <BatchEmailModule showBatchEmailModal={this.handleBatchEmailClick} parentCallback={this.batchEmailCallbackFunction} Invoices={this.state.BatchEmailInvoices} />
                                    }
                                </Col>
                            </Row>  
                        </div>
                    }
                />
            </div>
        );
    }
}

export default InvoiceByUser;






