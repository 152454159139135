/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const styles = {
  dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    }
}

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: null,

      firstName: "",
      firstNameError: null,

      lastName: "",
      lastNameError: null,

      address: "",
      addressError: null,

      city: "",
      cityError: null,

      country: "",
      countryError: null,

      state: "",
      stateError: null,

      postalCode: "",
      postalCodeError: null,

      amountToCharge: props.amountToCharge || "0.00",
      firstNameValid: true,
      lastNameValid: true,
      FormValid: false,
      FormErrors: { firstName: '', lastName: '' },
    };
  }

  _grabUserInput() {
    return {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      postalCode: this.state.postalCode,
      amount: this.state.amountToCharge
    };
  }

  componentWillReceiveProps(props) {
    if (props) {
      if (props.getForm().step1Completed) {
        props.jumpToStep(1);
      }
    }
  }

  handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
  };

  handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'firstName':
                this.setState({firstName: value});                   
                this.validateField(name, value);
                break;
            case 'lastName':
                this.setState({lastName: value});                   
                this.validateField(name, value);
                break;
            case "Address":
                this.setState({address: value});                   
                this.validateField(name, value);
                break;
            case "Country":
                this.setState({country: value});                   
                this.validateField(name, value);
                break;
            case "City":
                this.setState({city: value});                   
                this.validateField(name, value);
                break;
            case "State":
                this.setState({state: value});                   
                this.validateField(name, value);
                break;
            case "PostalCode":
                this.setState({postalCode: value});                   
                this.validateField(name, value);
                break;
                break;
            default:
                break;
        }
    }

  validateField(fieldName, value) {
        let fieldValidationErrors = this.state.FormErrors;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        switch (fieldName) {
            case 'firstName':
                firstNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.firstName = firstNameValid ? '' : ' is invalid';
                this.state.firstNameValid = firstNameValid ? true : false;
                break;
            case 'lastName':
                lastNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.lastName = lastNameValid ? '' : ' is invalid';
                this.state.lastNameValid = lastNameValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid
        }, this.validateForm);
    }

    validateForm() {
            this.setState({ FormValid: this.state.firstNameValid && this.state.lastNameValid })
    }

  isValidated() {
    if(!this.state.FormValid){
      this.validateField("firstName", this.state.firstName);
      this.validateField("lastName", this.state.lastName);
      return false;
    }

    if (this.props.getForm().step1Completed) {
      return true;
    } else {
      let userInput = this._grabUserInput();
      this.props.onTrigger(userInput, "step1");
      return false;
    }
  }


  render() {
    return (
      <div className="wizard-step">
        {/* First and Last Name Row */}
        <Row>          
          <Col xs={12} sm={12} md={6}>
              <InputLabel
                  style={styles.dropDownFontStyle}
              >
                  First Name
              </InputLabel>
              <CustomInput
                  isRequired={true}
                  id="firstName"
                  name="firstName"
                  value={this.state.firstName}
                  showErrorMessage={!this.state.firstNameValid}
                  errorMessage="First name is invalid"
                  formControlProps={{
                      fullWidth: true,
                      error: !this.state.firstNameValid,
                      onChange: (e) => { this.handleUserInput(e) },
                      onKeyPress: (e) => { this.handleKeyPressInput(e) },
                      onBlur: (e) => { this.validateField(e.target.name, e.target.value) }
                  }}
                  inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                  labelProps={{ style: { fontWeight: "bold" } }}
              />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <InputLabel style={styles.dropDownFontStyle}>
                    Last Name
            </InputLabel>
            <CustomInput
                isRequired={true}
                id="lastName"
                name="lastName"
                value={this.state.lastName}
                showErrorMessage={!this.state.lastNameValid}
                errorMessage="Last name is invalid"
                formControlProps={{
                    fullWidth: true,
                    error: !this.state.lastNameValid,
                    onChange: (e) => { this.handleUserInput(e) },
                    onKeyPress: (e) => { this.handleKeyPressInput(e) },
                    onBlur: (e) => { this.validateField(e.target.name, e.target.value) }
                }}
                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                labelProps={{ style: { fontWeight: "bold" } }}
            />
          </Col>
        </Row>

        {/* Address Row */}
        <Row>          
          <Col xs={12} sm={12} md={12}>
              <InputLabel  style={styles.dropDownFontStyle}>
                  Address
              </InputLabel>
              <CustomInput
                  isRequired={true}
                  id="address"
                  name="Address"
                  value={this.state.address}
                  formControlProps={{
                      fullWidth: true,
                      onChange: (e) => { this.handleUserInput(e) }
                  }}
                  inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                  labelProps={{ style: { fontWeight: "bold" } }}
              />
          </Col>
        </Row>

        {/* City and State Row */}
        <Row>
          <Col xs={12} sm={12} md={6}>
              <InputLabel style={styles.dropDownFontStyle}>
                  City
              </InputLabel>
              <CustomInput
                  id="city"
                  name="City"
                  value={this.state.city}
                  formControlProps={{
                      fullWidth: true,                      
                      onChange: (e) => { this.handleUserInput(e) }
                  }}
                  inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                  labelProps={{ style: { fontWeight: "bold" } }}
              />
          </Col>
          <Col xs={12} sm={12} md={6}>
              <InputLabel style={styles.dropDownFontStyle}>
                  State
              </InputLabel>
              <CustomInput
                  id="state"
                  name="State"
                  value={this.state.state}
                  formControlProps={{
                      fullWidth: true,
                      onChange: (e) => { this.handleUserInput(e) }
                  }}
                  inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                  labelProps={{ style: { fontWeight: "bold" } }}
              />
          </Col>
        </Row>

        {/* Postal and Country Row */}
        <Row>
          <Col xs={12} sm={12} md={6}>
              <InputLabel style={styles.dropDownFontStyle}>
                  Postal Code
              </InputLabel>
              <CustomInput
                  id="postal-code"
                  name="PostalCode"
                  value={this.state.postalCode}
                  formControlProps={{
                      fullWidth: true,                     
                      onChange: (e) => { this.handleUserInput(e) }
                  }}
                  inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                  labelProps={{ style: { fontWeight: "bold" } }}
              />
          </Col>
          <Col xs={12} sm={12} md={6}>
              <InputLabel style={styles.dropDownFontStyle}>
                  Country
              </InputLabel>
              <CustomInput
                  // labelText="Country"
                  id="country"
                  name="Country"
                  value={this.state.country}
                  formControlProps={{
                      fullWidth: true,
                      onChange: (e) => { this.handleUserInput(e) }
                  }}
                  inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                  labelProps={{ style: { fontWeight: "bold" } }}
              />
          </Col>
        </Row>
        <br />
        <Button
            bsStyle="info"
            fill
            wd
            pullRight
            onClick={e=>this.isValidated()}
          >
            Next
          </Button>
        <Row hidden>
          <Col md={10} mdOffset={1}>
            <FormGroup>
              <ControlLabel>
                Email <span className="text-danger">*</span>
              </ControlLabel>
              <FormControl
                type="email"
                name="email"
                placeholder="ex: hello@creative-tim.com"
                onChange={event => this.setState({ email: event.target.value })}
              />
              {this.state.emailError}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Step1;
