import React, { Component } from "react";
import {
    Row,
    Col,
    Tab,
    Nav,
    Alert,
    NavItem,
    OverlayTrigger,
    Tooltip,
    Modal,
    FormGroup,
    FormControl,
    InputGroup,

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Datetime from "react-datetime";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import Select from "react-select";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import SessionTimeout from 'components/SessionTimeout';
import NetworkService from "services/NetworkService";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';


class CustomersAndProducts extends Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            isFormValid: true,
            ShowProgressBar: false,
            errorMessage: "",
            errorCode: "0",
            customerColumns: [                
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Email',
                    field: 'customeremail',
                    headerStyle: {
                        textAlign: "left",
                    },
                    cellStyle: {
                    },
                },
                {
                    title: 'Address',
                    field: 'customeraddress',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'City',
                    field: 'customercity',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'State',
                    field: 'customerstate',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'Country',
                    field: 'customercountry',
                    headerStyle: {
                        textAlign: "left",
                    },
                },
                {
                    title: 'Phone',
                    field: 'customerphone',
                    headerStyle: {
                        textAlign: "left"                       
                    }
                },
                // {
                //     title: 'Is Active',
                //     field: 'customerisactive',
                // }                             
            ],
            productColumns: [                
                {
                    title: 'Name',
                    field: 'name',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(15%)'
                    },
                    cellStyle: {
                        width: 'calc(15%)'
                    },
                },
                {
                    title: 'Description',
                    field: 'description',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Type',
                    field: 'type',
                    headerStyle: {
                        textAlign: "left",
                    },
                    cellStyle: {
                    },
                },
                {
                    title: 'Cost($)',
                    field: 'unitprice',
                    headerStyle: {
                        textAlign: "right",
                    },
                    cellStyle: {
                        textAlign: "right",
                    },
                },
                {
                    title: 'Qty On Hand',
                    field: 'qtyonhand',
                    headerStyle: {
                        textAlign: "right",
                    },
                    cellStyle: {
                        textAlign: "right",
                    },
                },
                {
                    title: 'Expense Account',
                    field: 'expenseaccount',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(18%)'
                    },
                    cellStyle: {
                        width: 'calc(18%)'
                    },
                },
                {
                    title: 'Income Account',
                    field: 'incomeaccount',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(18%)'
                    },
                    cellStyle: {
                        width: 'calc(18%)'
                    },
                },
                // {
                //     title: 'Is Active',
                //     field: 'customerisactive',
                // }                             
            ],
            customerTableOptions: {
                //selection: true,
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    
                },
                rowStyle: rowData => ({
                     backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"
                }),
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,
                loadingType: "overlay",
            },
            
            editCustomerToolTip: <Tooltip id="editCustomerToolTip">Edit Customer</Tooltip>,
            deleteCustomerToolTip: <Tooltip id="deleteCustomerToolTip">Delete Customer</Tooltip>,
            createCustomerToolTip: <Tooltip id="deleteCustomerToolTip">Create New Customer</Tooltip>,
            createNewItemToolTip: <Tooltip id="deleteCustomerToolTip">Create New Item/Product</Tooltip>,
            customerActions:  [            
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.editCustomerToolTip}>
                        <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleEditCustomerPopup(rowData)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.deleteCustomerToolTip}>
                        <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleDeleteCustomerPopup(rowData)
                }
            ],
            customerComponents: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={this.state.createCustomerToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleOpenNewCustomerPopup(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
            },
            itemComponents: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={this.state.createNewItemToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleOpenNewItemPopup(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
            },
            customers:[],
            customerInfo:{
                Id:"",
                SyncToken: "",
                Active: "",
                customerName: "",
                customerEmail: "",
                customerPhone: "",
                billingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""
                },
                shippingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""    
                },
                customerNameState: "valid",
                customerNameErrorMessage: "",
                customerEmailState: "valid",
                customerEmailErrorMessage: ""
            },            
            isCustomerFormValid: true,            
            editItemToolTip: <Tooltip id="editCustomerToolTip">Edit Product</Tooltip>,
            deleteItemToolTip: <Tooltip id="deleteCustomerToolTip">Delete Product</Tooltip>,
            productActions:  [            
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.editItemToolTip}>
                        <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleEditProductPopup(rowData)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.deleteItemToolTip}>
                        <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleDeleteProductPopup(rowData)
                }
            ],
            products:[],
            itemInfo:{
                id: "",
                synctoken: "",
                active: "",
                name: "",
                sku: "",
                price: 0.00,
                itemType:[        
                     { value: "", label: "Select Type", isDisabled: true },           
                     { value: "Service", label: "Service" },
                     { value: "NonInventory", label: "Non Inventory" },
                     { value: "Inventory", label: "Inventory" }
                ],
                selectedItemType: { value: "Service", label: "Service" },
                category:[],
                selectedCategory: {value: "", label: ""},
                salesInformation:"",
                expenseAccount:[],
                selectedExpenseAccount: { value: "", label: "" },
                incomeAccount:[],
                selectedIncomeAccount:  {value: "", label: ""},
                inventoryAssetAccount:[],
                selectedInventoryAssetAccount: {value: "", label: ""},
                quantityOnHand: 0,
                quantityOnHandDate: new Date(),
                quantityOnHandDateServer: "",
                nameState: "valid",
                nameErrorMessage: "",
                quantityErrorMessage: "",
                quantityState: "valid",
                isInvenotryItem: false,
                taxable: false,
            },
            showNewCustomerPopupModal: false,
            showNewItemPopupModal: false,
            isNewItemFormValid: false,
            showEditCustomerPopupModal: false,
            showDeleteCustomerPopupModal: false,
            showUpdateItemPopupModal: false,
            showDeleteProductPopupModal: false,
            productErrorCode: "0",
            productErrorMessage: "",
            customerErrorCode: "0",
            customerErrorMessage: ""
        };        
    };    

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this._getCustomerRequest();
        this._getItemListingRequest();
    }

    _getCustomerRequest() {
        const app_id = 2
        this.setState({ShowProgressBar: true});
        NetworkService.getCustomerRequest(app_id)
            .then(data => {
                if (data != null) {
                    if (data.data != null) {
                        this.setCustomerResult(data.data.items);
                    }
                }
            }).catch(error => {
                this.setState({ShowProgressBar: false});
                console.log("Error loading customers: " + error);
            });
    }
    
    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: value });
    }

    handleOpenNewCustomerPopup(e){        
        this.setState({ShowPopupProgressBar:false});
        this.state.customerInfo.customerName =  "";
        this.state.customerInfo.customerEmail =  "";
        this.state.customerInfo.customerCompany =  "";
        this.state.customerInfo.customerPhone =  "";
        this.state.customerInfo.customerFax =  "";
        this.state.customerInfo.customerWebsite =  "";
        this.state.customerInfo.customerNote =  "";
        this.state.customerInfo.shippingAddress.address =  "";
        this.state.customerInfo.billingAddress.address = "";
        this.state.customerInfo.shippingAddress.country =  "";
        this.state.customerInfo.billingAddress.country =  "";
        this.state.customerInfo.shippingAddress.state =  "";
        this.state.customerInfo.billingAddress.state=  "";
        this.state.customerInfo.shippingAddress.city =  "";
        this.state.customerInfo.billingAddress.city =  "";
        this.state.customerInfo.shippingAddress.postalCode =  "";
        this.state.customerInfo.billingAddress.postalCode =  "";
        this.state.customerInfo.customerNameState = "";
        this.state.customerInfo.customerNameErrorMessage = "";
        this.state.customerInfo.customerEmailState = "";
        this.state.customerInfo.customerEmailErrorMessage = "";
        this.state.isCustomerFormValid = false;
        this.setState({showNewCustomerPopupModal: true});
    }

    handleOpenNewItemPopup(e) {               
        this.state.itemInfo.name = "";
        this.state.itemInfo.sku = "";
        this.state.itemInfo.price = 0.00;   
        this.state.itemInfo.selectedItemType = { value: "Service", label: "Service" };
        this.state.itemInfo.salesInformation = "";               
        this.state.itemInfo.quantityOnHand = 0;
        this.state.itemInfo.quantityOnHandDate = new Date();
        this.state.itemInfo.quantityOnHandDateServer = "";
        this.state.itemInfo.nameState = "";
        this.state.itemInfo.nameErrorMessage = "";
        this.state.itemInfo.quantityErrorMessage = "";
        this.state.itemInfo.quantityState = "";
        this.state.itemInfo.isInvenotryItem = false;
        this.state.itemInfo.taxable = false;
        this.state.isNewItemFormValid = false;        
        this.setState({showNewItemPopupModal: true}); 
        this._getAccountRequest();
    }


    setCustomerResult(customerList) {  
        localStorage.setItem(StorageEnum.QBO_CUSTOMERS, JSON.stringify(customerList));      
        const newSeries = [];
        customerList.map((customer, index) => {
            const data = {
                index: index,
                id: customer.Id,   
                synctoken: customer.SyncToken,
                active: customer.Active,         
                customername: customer.DisplayName,
                customeremail: customer.PrimaryEmailAddr ? customer.PrimaryEmailAddr.Address : "",
                customeraddress: customer.BillAddr ? customer.BillAddr.Line1 : "",
                customercity: customer.BillAddr ? customer.BillAddr.City : "",
                customerstate: customer.BillAddr ? customer.BillAddr.CountrySubDivisionCode : "",
                customercountry: customer.BillAddr ? customer.BillAddr.Country : "",
                postalcode: customer.BillAddr ? customer.BillAddr.PostalCode : "",
                customerphone: customer.PrimaryPhone ? customer.PrimaryPhone.FreeFormNumber : "",
                customerisactive: customer.Active == true ? "Yes" : "No",
               
            }
            newSeries.push(data);

        });
        this.setState({ShowProgressBar: false});
        this.setState({ customers: newSeries });
    }

    handleEditCustomerPopup(customer) {
        this.state.customerInfo.Id = customer.id;
        this.state.customerInfo.SyncToken = customer.synctoken;
        this.state.customerInfo.Active = customer.active;
        this.state.customerInfo.customerName = customer.customername;
        this.state.customerInfo.customerEmail = customer.customeremail;
        this.state.customerInfo.customerPhone = customer.customerphone;
        this.state.customerInfo.billingAddress.address = customer.customeraddress;
        this.state.customerInfo.billingAddress.country = customer.customercountry;
        this.state.customerInfo.billingAddress.state = customer.customerstate;
        this.state.customerInfo.billingAddress.city = customer.customercity;
        this.state.customerInfo.billingAddress.postalCode = customer.postalcode;

        this.setState({ showEditCustomerPopupModal: true });        
    }

    handleDeleteCustomerPopup(customer) {
        this.state.customerInfo.Id = customer.id;
        this.state.customerInfo.SyncToken = customer.synctoken;
        this.state.customerInfo.Active = customer.active;
        this.state.customerInfo.customerName = customer.customername;
        this.state.customerInfo.customerEmail = customer.customeremail;
        this.state.customerInfo.customerPhone = customer.customerphone;
        this.state.customerInfo.billingAddress.address = customer.customeraddress;
        this.state.customerInfo.billingAddress.country = customer.customercountry;
        this.state.customerInfo.billingAddress.state = customer.customerstate;
        this.state.customerInfo.billingAddress.city = customer.customercity;
        this.state.customerInfo.billingAddress.postalCode = customer.postalcode;

        this.setState({ showDeleteCustomerPopupModal: true });        
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    validateCustomer(){
        if (this.state.customerInfo.customerEmailErrorMessage === "") {
            if (this.state.customerInfo.customerEmailState === "" || this.state.customerInfo.customerEmailState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmailErrorMessage: (
                            <small className="text-danger">
                                Email is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmailState:  "valid",
                        customerEmailErrorMessage: ""
                    }
                }));                
            }
        }

        if (this.state.customerInfo.customerNameErrorMessage === "") {
            if (this.state.customerInfo.customerNameState === "" || this.state.customerInfo.customerNameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerNameErrorMessage: (
                            <small className="text-danger">
                                Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerNameState:  "valid",
                        customerNameErrorMessage: ""
                    }
                }));                
            }
        }       

        if(this.state.customerInfo.customerEmailState === "valid" && this.state.customerInfo.customerNameState === "valid"){
            this.setState({ isCustomerFormValid: true });
        }
        else{
            this.setState({ isCustomerFormValid: false });
        }
    }


    ValidateEmailAddress(event, name) {
        var value = event.target.value;  
        this.setState(prevState => ({
            ...prevState,
            customerInfo: {
                ...prevState.customerInfo,
                customerEmail:  value
            }
        }));
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmailErrorMessage: (
                            <small className="text-danger">
                                Invalid email address.
                            </small>
                        )
                    }
                }));
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmailState: "error"
                    }
                }));                
                this.setState({ isCustomerFormValid: false });    
        }
        else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmailState:  "valid",
                        customerEmailErrorMessage: ""
                    }
                }));
              if(this.state.customerInfo.customerNameState === "valid"){
                    this.setState({ isCustomerFormValid: true });
                }   
        }
    }

    updateDeleteCustomerSubmit = (customer, isDelete = false) =>
    {
        if(customer.isShippingAddressSameAsBillingAddress)
        {
            customer.shippingAddress.address = customer.billingAddress.address;
            customer.shippingAddress.country = customer.billingAddress.country;
            customer.shippingAddress.state = customer.billingAddress.state;
            customer.shippingAddress.city = customer.billingAddress.city;
            customer.shippingAddress.postalCode = customer.billingAddress.postalCode;
        }

        const payload = {
            "FullyQualifiedName": null, 
            "PrimaryEmailAddr": {
              "Address": customer.customerEmail
            }, 
            "Id": customer.Id,
            "SyncToken": customer.SyncToken,
            "Active": isDelete == true ? false : customer.Active,
            "DisplayName": customer.customerName, 
            "Suffix": null, 
            "Title": null, 
            "MiddleName": null, 
            "Notes": customer.customerNote, 
            "FamilyName": null, 
            "PrimaryPhone": {
              "FreeFormNumber": customer.customerPhone
            }, 
            "CompanyName": customer.customerCompany, 
            "BillAddr": {
              "CountrySubDivisionCode": null, 
              "City": customer.billingAddress.city, 
              "PostalCode": customer.billingAddress.postalCode, 
              "Line1": customer.billingAddress.address, 
              "Country": customer.billingAddress.country
            },
            "ShipAddr": {
                "CountrySubDivisionCode": null, 
                "City": customer.billingAddress.city, 
                "PostalCode":  customer.billingAddress.postalCode, 
                "Line1":  customer.billingAddress.address, 
                "Country": customer.billingAddress.country
              }, 
            "GivenName": null,
            "WebAddr":{
                "URI":customer.customerWebsite
            }
          }

        this._postUpdateDeleteCustomerRequest(payload, isDelete);
    }

    _postUpdateDeleteCustomerRequest(body, isDelete = false) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });
        const app_id = 2;
        NetworkService.postUpdateDeleteCustomerRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });

                if (data != null) {                    
                    this.setState({ customerErrorCode: data.statuscode });
                    this.setState({ customerErrorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        if(isDelete){
                            this.setState({ showDeleteCustomerPopupModal: false });
                            this.setState({errorMessage: "Customer deleted successfully." });
                        }
                        else{
                            this.setState({ showEditCustomerPopupModal: false});
                            this.setState({errorMessage: "Customer updated successfully." });
                        }                      
                        
                        if (data.data != null) {
                            this._getCustomerRequest();
                         }
                    }
                    
                } else {
                    this.setState({ customerErrorCode: "1" });
                    this.setState({ customerErrorMessage: "Oops! Something went wrong, please try again." });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ customerErrorCode: "1" });
                this.setState({ customerErrorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ customerErrorMessage: "" })
        }, 8000);   
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 8000); 
    }

    _getItemListingRequest() {
        const app_id = 2
        NetworkService.getItemListingRequest(app_id)
            .then(data => {
                if (data != null) {
                    if (data.data != null)
                        this.setItemsResult(data.data.items);
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setItemsResult(items) {          
        localStorage.setItem("items", JSON.stringify(items));         
        const newSeries = [];
        items.map((item, index) => {
            const data = {               
                index: index,
                id: item.Id,   
                synctoken: item.SyncToken,
                invstartdate: item.InvStartDate,
                active: item.Active,      
                name: item.Name,
                description: item.Description,
                type: item.Type,
                unitprice: parseFloat(item.UnitPrice).toFixed(2), 
                qtyonhand: item.QtyOnHand,
                //selectedCategory: item.selectedCategory
                expenseaccount: item.ExpenseAccountRef ? item.ExpenseAccountRef.name : "",                
                expenseaccountid: item.ExpenseAccountRef ? item.ExpenseAccountRef.value : "",
                incomeaccount: item.IncomeAccountRef ? item.IncomeAccountRef.name : "",                
                incomeaccountid: item.IncomeAccountRef ? item.IncomeAccountRef.value : "",
                inventoryassetaccount: item.AssetAccountRef ? item.AssetAccountRef.name : "",
                inventoryassetaccountid: item.AssetAccountRef ? item.AssetAccountRef.value : "",
               
            }
            newSeries.push(data);

        });
        this.setState({ShowProgressBar: false});
        this.setState({ products: newSeries });
    }

    handleDeleteProductPopup(item) {
        this.setState({ showDeleteProductPopupModal: true });
        this.state.itemInfo.id = item.id;
        this.state.itemInfo.synctoken = item.synctoken;
        this.state.itemInfo.active = item.active;
        this.state.itemInfo.name = item.name;
        this.state.itemInfo.description = item.description;
        this.state.itemInfo.price = item.unitprice;  
        this.state.itemInfo.type = item.type;
        this.state.itemInfo.selectedIncomeAccount = {name : item.incomeaccount, value : item.incomeaccountid};
        this.state.itemInfo.selectedCategory = {name: "", value: ""};
        this.state.itemInfo.selectedExpenseAccount = {name : item.expenseaccount, value : item.expenseaccountid};
        this.state.itemInfo.selectedInventoryAssetAccount = {name : item.inventoryassetaccount, value : item.inventoryassetaccountid};

    }    

    handleEditProductPopup(item) {
        this.state.itemInfo.id = item.id;
        this.state.itemInfo.synctoken = item.synctoken;
        this.state.itemInfo.active = item.active;

        this.state.itemInfo.name = item.name;
        this.state.itemInfo.description = item.description;
        this.state.itemInfo.price = item.unitprice;  
        if(item.type == "Inventory"){
            this.state.itemInfo.isInvenotryItem = true;
            this.setState(prevState => ({
                itemInfo: {
                    ...prevState.itemInfo,
                    isInvenotryItem: true
                }
            }));
            this.state.itemInfo.quantityOnHandDate =  new Date(item.invstartdate);
            this.state.itemInfo.selectedInventoryAssetAccount = {value: item.inventoryassetaccountid, label: item.inventoryassetaccount}; 
        }
        else{
            this.state.itemInfo.isInvenotryItem = false;
            this.setState(prevState => ({
                itemInfo: {
                    ...prevState.itemInfo,
                    isInvenotryItem: false
                }
            }));
        }
        this.state.itemInfo.selectedItemType =  { value: item.type, label: item.type };                
        this.state.itemInfo.selectedCategory = this.state.itemInfo.category[0];
        this.state.itemInfo.selectedExpenseAccount = { value: item.expenseaccountid, label: item.expenseaccount };
        this.state.itemInfo.selectedIncomeAccount =  {value: item.incomeaccountid, label: item.incomeaccount};                
        this.state.itemInfo.salesInformation = item.description;
        this.state.itemInfo.quantityOnHand = item.qtyonhand;     
        this.state.itemInfo.taxable = item.taxable;        
        this.state.itemInfo.nameErrorMessage = "";
        this.state.itemInfo.quantityErrorMessage = ""; 
        this.state.isItemFormValid = true;
        this.setState({showUpdateItemPopupModal: true});
        this._getAccountRequest();
        //this._getCategoryTypeItemRequest();
    }

    validateItem() {
        if (this.state.itemInfo.nameErrorMessage === "") {
            if (this.state.itemInfo.nameState === "" || this.state.itemInfo.nameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameErrorMessage: (
                            <small className="text-danger">
                                Item Name is required.
                            </small>
                        )
                    }
                }));
                this.setState({ isItemFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameState:  "valid",
                        nameErrorMessage: ""
                    }
                }));                
            }
        }

        if(this.state.itemInfo.selectedItemType.value === "Inventory"){
            if(this.state.itemInfo.quantityOnHand !== "")
            {
                if(parseInt(this.state.itemInfo.quantityOnHand) !== "NAN"){                    
                     this.state.itemInfo.quantityState = "valid";   
                     this.state.itemInfo.quantityErrorMessage = "";                    
                }
                else{
                     this.state.itemInfo.quantityState = "error";   
                     this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Invalid Quantity.</small>;                      
                }
            } 
            else{
                 this.state.itemInfo.quantityState = "error";   
                 this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Quantity is Required.</small>;                 
            }
        }
        else{
             this.state.itemInfo.quantityState = "valid";   
             this.state.itemInfo.quantityErrorMessage = "";
        }
        if(this.state.itemInfo.nameState === "valid" && this.state.itemInfo.quantityState === "valid"){
            this.setState({ isItemFormValid: true });
        }
        else{
            this.setState({ isItemFormValid: false });
        }

    }

    validateNewItem() {
        if (this.state.itemInfo.nameErrorMessage === "") {
            if (this.state.itemInfo.nameState === "" || this.state.itemInfo.nameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameErrorMessage: (
                            <small className="text-danger">
                                Item Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameState:  "valid",
                        nameErrorMessage: ""
                    }
                }));                
            }
        }

        if(this.state.itemInfo.selectedItemType.value === "Inventory"){
            if(this.state.itemInfo.quantityOnHand !== "")
            {
                if(parseInt(this.state.itemInfo.quantityOnHand) !== "NAN"){                    
                     this.state.itemInfo.quantityState = "valid";   
                     this.state.itemInfo.quantityErrorMessage = "";                    
                }
                else{
                     this.state.itemInfo.quantityState = "error";   
                     this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Invalid Quantity.</small>;                      
                }
            } 
            else{
                 this.state.itemInfo.quantityState = "error";   
                 this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Quantity is Required.</small>;                 
            }
        }
        else{
             this.state.itemInfo.quantityState = "valid";   
             this.state.itemInfo.quantityErrorMessage = "";
        }

        

        if(this.state.itemInfo.nameState === "valid" && this.state.itemInfo.quantityState === "valid"){
            this.setState({ isNewItemFormValid: true });
        }
        else{
            this.setState({ isNewItemFormValid: false });
        }

    }
    _getAccountRequest(){        
        this.setState({ errorMessage: "" });
         this.setState({ShowPopupProgressBar:true});
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getAccountRequest(app_id)
            .then(data => {
                 this.setState({ShowPopupProgressBar:false});    
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });
                   if(data.statuscode == 0){
                        const account = data.data.items;
                        this.setAccountResult(account);
                   }
                } else {
                }
            }).catch(error => {
                this.setState({ShowPopupProgressBar:false});  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setAccountResult(accounts){
        const incomeAccounts = [];
        const expenseAccounts = [];
        const assetAccounts = [];        
        incomeAccounts.push({value: "", label: "Select Account", isDisabled: true});
        expenseAccounts.push({value: "", label: "Select Account", isDisabled: true});
        let selectedIncomeAccount1 = "";
        let selectedExpenseAccount1 = "";
        let selectedAssetAccount1 = "";
        this.setState({accounts: accounts});
        accounts.map(data => {
            if(data.Classification == "Revenue" || data.Classification == "Income" ||  data.AccountType == "Income"){           
                incomeAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                if(data.Name == "Services"){
                    selectedIncomeAccount1 = {value: data.Id, label: data.Name +"|"+ data.AccountType};
                }
            }
            else if((data.Classification == "Expense" || data.AccountType == "Expense") && 
                (data.AccountSubType == "SuppliesMaterialsCogs" || data.AccountSubType == "ShippingFreightDeliveryCos" || 
                data.AccountSubType == "OtherMiscellaneousServiceCost" || data.AccountSubType == "Utilities")){
                expenseAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                if(data.Name == "Cost of Goods Sold"){
                    selectedExpenseAccount1 =  {value: data.Id, label: data.Name +"|"+ data.AccountType};
                }
            }
            else if(data.AccountSubType == "Inventory"){
                assetAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                if(data.Name == "Inventory Asset"){
                    selectedAssetAccount1 =  {value: data.Id, label: data.Name +"|"+ data.AccountType};
                }
            }
                
        });        
        if(selectedIncomeAccount1 == ""){
            selectedIncomeAccount1 = incomeAccounts[1];
        }

        if(selectedExpenseAccount1 == ""){
            selectedExpenseAccount1 = expenseAccounts[1];
        }
        if(selectedAssetAccount1 == ""){
            selectedAssetAccount1 = assetAccounts[0];
        }
        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                incomeAccount: incomeAccounts,
                selectedIncomeAccount:  selectedIncomeAccount1
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                expenseAccount: expenseAccounts,
                selectedExpenseAccount: selectedExpenseAccount1
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                inventoryAssetAccount: assetAccounts,
                selectedInventoryAssetAccount: selectedAssetAccount1
            }
        }));        
    }

    updateDeleteItemSubmit = (item, isDelete = false) =>
    {
        const payload = {
            "Id": item.id,
            "SyncToken": item.synctoken,
            "Active": isDelete == true ? false : item.active,    
            "TrackQtyOnHand": this.state.itemInfo.isInvenotryItem? true : false, 
            "Name": item.name, 
            "UnitPrice": item.price,  
            "IncomeAccountRef": item.selectedIncomeAccount,
            "AssetAccountRef": item.selectedInventoryAssetAccount,
            "Type": item.selectedItemType.value, 
           // "ItemCategoryType": item.selectedCategory, 
            "ExpenseAccountRef": item.selectedExpenseAccount,
            "QtyOnHand": item.quantityOnHand,
            "InvStartDate": item.quantityOnHandDate,
            "Description": isDelete ? item.description : item.salesInformation ,
            "Taxable": item.taxable
          }
        this._postUpdateDeleteItemRequest(payload, isDelete);        
    }

    _postUpdateDeleteItemRequest(body, isDelete = false) {
        this.setState({ShowItemPopupProgressBar: true});
        this.setState({ productErrorMessage: "" });
        const app_id = 2;         
        NetworkService.postUpdateDeleteItemRequest(app_id, body)
            .then(data => {                
                this.setState({ ShowItemPopupProgressBar: false });                

                if (data != null) {
                    this.setState({ productErrorCode: data.statuscode });
                    this.setState({ productErrorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        if(isDelete){                            
                            this.setState({ showDeleteProductPopupModal: false });
                            this.setState({errorMessage: "Product deleted successfully." });
                        }
                        else{
                            this.setState({ showUpdateItemPopupModal: false });
                            this.setState({errorMessage: "Product updated successfully." });                            
                        }                                              
                        this._getItemListingRequest();                        
                    }
                    
                } else {
                    this.setState({ productErrorMessage: "Oops! Something went wrong, please try again." });
                }
            }).catch(error => {
                this.setState({ productErrorMessage: "Oops! Something went wrong, please try again." });
            });
            
        setTimeout(() => {
            this.setState({ productErrorMessage: "" })
        }, 8000);  
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 8000);     
    }

    createCustomerSubmit = (customer) =>
    {
        if(customer.isShippingAddressSameAsBillingAddress)
        {
            customer.shippingAddress.address = customer.billingAddress.address;
            customer.shippingAddress.country = customer.billingAddress.country;
            customer.shippingAddress.state = customer.billingAddress.state;
            customer.shippingAddress.city = customer.billingAddress.city;
            customer.shippingAddress.postalCode = customer.billingAddress.postalCode;
        }

        const payload = {
            "FullyQualifiedName": null, 
            "PrimaryEmailAddr": {
              "Address": customer.customerEmail
            }, 
            "DisplayName": customer.customerName, 
            "Suffix": null, 
            "Title": null, 
            "MiddleName": null, 
            "Notes": customer.customerNote, 
            "FamilyName": null, 
            "PrimaryPhone": {
              "FreeFormNumber": customer.customerPhone
            }, 
            "CompanyName": customer.customerCompany, 
            "BillAddr": {
              "CountrySubDivisionCode": null, 
              "City": customer.billingAddress.city, 
              "PostalCode": customer.billingAddress.postalCode, 
              "Line1": customer.billingAddress.address, 
              "Country": customer.billingAddress.country
            },
            "ShipAddr": {
                "CountrySubDivisionCode": null, 
                "City": customer.shippingAddress.city, 
                "PostalCode": customer.shippingAddress.postalCode, 
                "Line1": customer.shippingAddress.address, 
                "Country": customer.shippingAddress.country
              }, 
            "GivenName": null,
            "WebAddr":{
                "URI":customer.customerWebsite
            }
          }

        this._postCreateCustomerRequest(payload);
    }

    _postCreateCustomerRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 

        NetworkService.postCreateCustomerRequest(app_id, body)
            .then(data => {

                this.setState({ ShowPopupProgressBar: false });
                this.setState({showNewCustomerPopupModal: false});

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });                    
                    if(data.statuscode == 0)
                    {
                        if (data.data != null) {
                            this.setState({ errorMessage: "Customer created successfully" });
                            this._getCustomerRequest();
                         }
                    }
                    
                } else {
                    this.setState({ errorMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({showNewCustomerPopupModal: false});
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 8000);    
    }

    createItemSubmit = (item) =>
    {
        const payload = {
            "TrackQtyOnHand": this.state.itemInfo.isInvenotryItem? true : false, 
            "Name": item.name, 
            "UnitPrice": item.price,
           // "QtyOnHand": item.price, 
            "SKU": item.sku,
            "IncomeAccountRef":item.selectedIncomeAccount, //  {value: "", label: ""},// item.selectedIncomeAccount, 
            "AssetAccountRef": item.selectedInventoryAssetAccount, //{value: "", label: ""}, //item.selectedInventoryAssetAccount, 
            "Type": item.selectedItemType.value, 
            "ExpenseAccountRef": item.selectedExpenseAccount,// {value: "", label: ""}, //item.selectedExpenseAccount,
            "QtyOnHand": item.quantityOnHand,
            "InvStartDate": item.quantityOnHandDate,
            "Description": item.salesInformation,
            "Taxable": item.taxable
          }

        this._postCreateItemRequest(payload);
        
    }

    _postCreateItemRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });                    
                    if(data.statuscode == 0)
                    {
                        if (data.data != null) {
                            this.setState({ errorMessage: "Item created successfully" });
                            this._getItemListingRequest();
                         }
                    }
                    
                } else {
                    this.setState({ errorMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
            
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }


    render() {        
       return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    ctFullWidth
                    content={
                        <div>                            
                            <Tab.Container id="tabs-with-dropdown" defaultActiveKey="customerkey">
                                <Row className="clearfix">
                                    <Col sm={12}>
                                        <Nav bsStyle="tabs">                                            
                                            <NavItem eventKey="customerkey">
                                                <i className="fa fa-user" /> Customers
                                            </NavItem>
                                            <NavItem eventKey="productkey">
                                                <i className="fa fa-lock" /> Products
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content animation>
                                            <Tab.Pane eventKey="customerkey">
                                                <Row>
                                                    <Col md={12}>
                                                        {this.state.errorMessage != "" &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                        <span>
                                                                            {this.state.errorMessage}
                                                                        </span>
                                                                    </Alert>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        
                                                        <br />
                                                        <MuiThemeProvider theme={this.theme}>
                                                            {this.state.ShowProgressBar &&
                                                                <div>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                    <br />
                                                                </div>

                                                            }
                                                            <MaterialTable                                                                
                                                                columns={this.state.customerColumns}
                                                                data={this.state.customers}
                                                                options={this.state.customerTableOptions}
                                                                actions={this.state.customerActions}
                                                                components={this.state.customerComponents}
                                                                // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                                                               
                                                            />
                                                        </MuiThemeProvider>
                                                        <Modal
                                                            show={this.state.showNewCustomerPopupModal}
                                                            onHide={() => this.setState({ showNewCustomerPopupModal: false })}
                                                            backdrop="static"
                                                            bsSize="large"       
                                                            >
                                                            {this.state.ShowPopupProgressBar &&
                                                                <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                </div>
                                                            }
                                            
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Customer Information</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div>
                                                                    <Row>
                                                                        <Col md={3}>
                                                                        <FormGroup>
                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Customer Name: <span className="star">*</span>
                                                                            </div>
                                                                            <FormControl
                                                                                value={this.state.customerInfo.customerName}
                                                                                type="text"
                                                                                placeholder="Customer Name"
                                                                                name="customerName"
                                                                                onChange={event => {
                                                                                    var value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerName: value
                                                                                        }
                                                                                    }));
                                                                                    if(this.verifyLength(value, 2))
                                                                                    {
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerNameState: "valid",
                                                                                            customerNameErrorMessage: ""
                                                                                        }
                                                                                    }));
                                                                                    }
                                                                                    else{
                                                                                        this.setState(prevState => ({
                                                                                            ...prevState,
                                                                                            customerInfo: {
                                                                                                ...prevState.customerInfo,
                                                                                                customerNameErrorMessage: (
                                                                                                    <small className="text-danger">
                                                                                                        Invalid Customer Name.
                                                                                                    </small>
                                                                                                )
                                                                                            }
                                                                                        }));
                                                                                        this.setState({ isCustomerFormValid: false });
                                                                                    }                                                    
                                                                                }
                                                                                }
                                                                                onBlur={e => this.validateCustomer()}
                                                                            />
                                                                            {this.state.customerInfo.customerNameErrorMessage}
                                                                        </FormGroup>
                                                                    </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Customer Email: <span className="star">*</span>
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.customerEmail}
                                                                                    placeholder="Customer Email"
                                                                                    name="customerEmail"
                                                                                    type="email"
                                                                                    className={this.state.customerInfo.customerEmailState}
                                                                                    onChange={event =>
                                                                                        this.ValidateEmailAddress(event, "customerEmail")
                                                                                    }
                                                                                onBlur={e => this.validateCustomer()}
                                                                                />
                                                                                {this.state.customerInfo.customerEmailErrorMessage}
                                                                            </FormGroup>
                                                                        </Col>                                    
                                                                        <Col md={3}>
                                                                        <FormGroup>
                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Company Name: 
                                                                            </div>
                                                                            <FormControl
                                                                                value={this.state.customerInfo.customerCompany}
                                                                                type="text"
                                                                                placeholder="Company Name"
                                                                                name="customerCompany"
                                                                                onChange={event => {
                                                                                    this.change(event, "customerCompany", "length", 3);
                                                                                    var value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerCompany: value
                                                                                        }
                                                                                    }));
                                                                                }
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Phone Number: 
                                                                            </div>
                                                                            <FormControl
                                                                            type="number"
                                                                            placeholder="Phone Number"
                                                                            name="customerPhone"
                                                                            value={this.state.customerInfo.customerPhone}
                                                                            onChange={event => {
                                                                                this.change(event, "customerPhone", "length", 7);
                                                                                var value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerPhone: value
                                                                                        }
                                                                                    }));
                                                                            }
                                                                            }                                                                       
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    </Row>
                                                                    <Row>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Fax: 
                                                                            </div>
                                                                            <FormControl
                                                                            type="number"
                                                                            placeholder="Fax Number"
                                                                            name="customerFax"
                                                                            value={this.state.customerInfo.customerFax}
                                                                            onChange={event => {
                                                                                this.change(event, "customerFax", "length", 7);
                                                                                var value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerFax: value
                                                                                        }
                                                                                    }));
                                                                            }
                                                                            }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Website: 
                                                                            </div>
                                                                            <FormControl
                                                                                value={this.state.customerInfo.customerWebsite}
                                                                                type="text"
                                                                                placeholder="Website"
                                                                                name="customerWebsite"
                                                                                onChange={event => {
                                                                                    this.change(event, "customerWebsite", "length", 8);
                                                                                    var value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerWebsite: value
                                                                                        }
                                                                                    }));
                                                                                }
                                                                                }                                                                            
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                            Note:
                                                                            </div>
                                                                        <FormGroup controlId="formControlsTextarea">

                                                                            <FormControl
                                                                                rows="2"
                                                                                value={this.state.customerInfo.customerNote}
                                                                                componentClass="textarea"
                                                                                bsClass="form-control"
                                                                                placeholder="Note"
                                                                                maxLength="200"
                                                                                onChange={event => {
                                                                                    const note = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerNote: note
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    </Row>
                                                                    <legend> </legend> 
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Billing Address: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.address}
                                                                                    type="text"
                                                                                    placeholder="Address"
                                                                                    name="billingAddress"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                address: value
                                                                                            }
                                                                                        }
                                                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <Row>
                                                                                    <Col md={4}>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Shippng Address: 
                                                                            
                                                                                </div>
                                                                                    </Col>
                                                                                    <Col md={8} style={{marginTop: "-32px"}}>
                                                                                    <CustomCheckbox
                                                                                            name="CheckBox"
                                                                                            checked={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                                                            labelText="Same as billing address"
                                                                                            labelProps={{style: {fontSize: "16px", marginTop:"12px"}}} 
                                                                                        formControlProps={{
                                                                                            onChange:(e) => {
                                                                                            var value = e.target.checked;
                                                                                            this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    customerInfo: {
                                                                                                        ...prevState.customerInfo,
                                                                                                        isShippingAddressSameAsBillingAddress: value
                                                                                                    }
                                                                                                }));
                                                                                            }
                                                                                        }}  
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.shippingAddress.address}
                                                                                    type="text"
                                                                                    placeholder="Address"
                                                                                    name="shippingAddress"
                                                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}                                                                                
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            shippingAddress: {
                                                                                                ...prevState.customerInfo.shippingAddress,
                                                                                                address: value
                                                                                            }
                                                                                        }                                                    
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Country: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.country}
                                                                                    type="text"
                                                                                    placeholder="Billing Country"
                                                                                    name="billingCountry"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                country: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    State: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.state}
                                                                                    type="text"
                                                                                    placeholder="Billing State"
                                                                                    name="billingState"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                state: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Country: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.shippingAddress.country}
                                                                                    type="text"
                                                                                    placeholder="Shipping Country"
                                                                                    name="shippingCountry"
                                                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            shippingAddress: {
                                                                                                ...prevState.customerInfo.shippingAddress,
                                                                                                country: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    State: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.shippingAddress.state}
                                                                                    type="text"
                                                                                    placeholder="Shipping State"
                                                                                    name="shippingState"
                                                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            shippingAddress: {
                                                                                                ...prevState.customerInfo.shippingAddress,
                                                                                                state: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                    <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    City: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.city}
                                                                                    type="text"
                                                                                    placeholder="Billing City"
                                                                                    name="billingCity"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                city: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Postal Code: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.postalCode}
                                                                                    type="text"
                                                                                    placeholder="Billing Postal Code"
                                                                                    name="billingPostalCode"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                postalCode: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    City: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.shippingAddress.city}
                                                                                    type="text"
                                                                                    placeholder="Shipping City"
                                                                                    name="shippingCity"
                                                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            shippingAddress: {
                                                                                                ...prevState.customerInfo.shippingAddress,
                                                                                                city: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Postal Code:  
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.shippingAddress.postalCode}
                                                                                    type="text"
                                                                                    placeholder="Shipping Postal Code"
                                                                                    name="shippingPostalCode"
                                                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            shippingAddress: {
                                                                                                ...prevState.customerInfo.shippingAddress,
                                                                                                postalCode: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>                                
                                                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewCustomerPopupModal: false })}>Close</Button>
                                                                    <Button bsStyle="primary" fill wd onClick={e => this.createCustomerSubmit(this.state.customerInfo)} disabled={!this.state.isCustomerFormValid}>Save</Button>                                   
                                                                </Modal.Footer>                             
                                                        </Modal> 
                                                        <Modal
                                                            show={this.state.showEditCustomerPopupModal}
                                                            onHide={() => this.setState({ showEditCustomerPopupModal: false })}
                                                            backdrop="static"
                                                            bsSize="medium"       
                                                            >
                                                            {this.state.ShowPopupProgressBar &&
                                                                <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                </div>
                                                            }
                                                            {this.state.customerErrorMessage != "" &&
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Alert bsStyle={this.state.customerErrorCode == 0 ? "success" : "danger"}>
                                                                            <span>
                                                                                {this.state.customerErrorMessage}
                                                                            </span>
                                                                        </Alert>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Customer Information</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                        <FormGroup>
                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Customer Name: <span className="star">*</span>
                                                                            </div>
                                                                            <FormControl
                                                                                value={this.state.customerInfo.customerName}
                                                                                type="text"
                                                                                placeholder="Customer Name"
                                                                                name="customerName"
                                                                                onChange={event => {
                                                                                    var value = event.target.value;
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerName: value
                                                                                        }
                                                                                    }));
                                                                                    if(this.verifyLength(value, 2))
                                                                                    {
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,
                                                                                            customerNameState: "valid",
                                                                                            customerNameErrorMessage: ""
                                                                                        }
                                                                                    }));
                                                                                    }
                                                                                    else{
                                                                                        this.setState(prevState => ({
                                                                                            ...prevState,
                                                                                            customerInfo: {
                                                                                                ...prevState.customerInfo,
                                                                                                customerNameErrorMessage: (
                                                                                                    <small className="text-danger">
                                                                                                        Invalid Customer Name.
                                                                                                    </small>
                                                                                                )
                                                                                            }
                                                                                        }));
                                                                                        this.setState({ isCustomerFormValid: false });
                                                                                    }                                                    
                                                                                }
                                                                                }
                                                                                onBlur={e => this.validateCustomer()}
                                                                            />
                                                                            {this.state.customerInfo.customerNameErrorMessage}
                                                                        </FormGroup>
                                                                    </Col>
                                                                        <Col md={4}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Customer Email: <span className="star">*</span>
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.customerEmail}
                                                                                    placeholder="Customer Email"
                                                                                    name="customerEmail"
                                                                                    type="email"
                                                                                    className={this.state.customerInfo.customerEmailState}
                                                                                    onChange={event =>
                                                                                        this.ValidateEmailAddress(event, "customerEmail")
                                                                                    }
                                                                                onBlur={e => this.validateCustomer()}
                                                                                />
                                                                                {this.state.customerInfo.customerEmailErrorMessage}
                                                                            </FormGroup>
                                                                        </Col>  
                                                                    <Col md={4}>
                                                                        <FormGroup>
                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Phone Number: 
                                                                            </div>
                                                                            <FormControl
                                                                                placeholder="Phone Number"
                                                                                name="customerPhone"
                                                                                value={this.state.customerInfo.customerPhone}
                                                                                onChange={event => {
                                                                                    //this.setState({ user: { phonenumber: event.target.value } })
                                                                                    this.change(event, "customerPhone", "length", 7);
                                                                                    var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                            ...prevState,
                                                                                            customerInfo: {
                                                                                                ...prevState.customerInfo,
                                                                                                customerPhone: value
                                                                                            }
                                                                                        }));
                                                                                }
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    </Row>
                                                                    <legend> </legend> 
                                                                    <Row>
                                                                        <Col md={8}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Address: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.address}
                                                                                    type="text"
                                                                                    placeholder="Address"
                                                                                    name="billingAddress" 
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                address: value
                                                                                            }
                                                                                        }
                                                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>  
                                                                        <Col md={4}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    City: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.city}
                                                                                    type="text"
                                                                                    placeholder="Billing City"
                                                                                    name="billingCity"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                city: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>                                                                      
                                                                    </Row>
                                                                    <Row>                                                                        
                                                                        <Col md={4}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    State: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.state}
                                                                                    type="text"
                                                                                    placeholder="Billing State"
                                                                                    name="billingState"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                state: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>                                                                                                                                        
                                                                        <Col md={4}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Postal Code: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.postalCode}
                                                                                    type="text"
                                                                                    placeholder="Billing Postal Code"
                                                                                    name="billingPostalCode"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                postalCode: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <FormGroup>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                    Country: 
                                                                                </div>
                                                                                <FormControl
                                                                                    value={this.state.customerInfo.billingAddress.country}
                                                                                    type="text"
                                                                                    placeholder="Billing Country"
                                                                                    name="billingCountry"
                                                                                    onChange={event => {
                                                                                        var value = event.target.value;
                                                                                        this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        customerInfo: {
                                                                                            ...prevState.customerInfo,                                                      
                                                                                            billingAddress: {
                                                                                                ...prevState.customerInfo.billingAddress,
                                                                                                country: value
                                                                                            }
                                                                                        }                                                        
                                                                                    }));
                                                                                    }
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>                                
                                                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showEditCustomerPopupModal: false })}>Close</Button>
                                                                    <Button bsStyle="primary" fill wd onClick={e => this.updateDeleteCustomerSubmit(this.state.customerInfo)} disabled={!this.state.isCustomerFormValid}>Update</Button>                                   
                                                                </Modal.Footer>                             
                                                        </Modal> 
                                                        <Modal
                                                            show={this.state.showDeleteCustomerPopupModal}
                                                            onHide={() => this.setState({ showDeleteCustomerPopupModal: false })}
                                                            bsSize="medium"  
                                                            >
                                                                    {this.state.ShowPopupProgressBar &&
                                                                        <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                                                            <CustomProgressBar></CustomProgressBar>
                                                                        </div>
                                                                    }
                                                                    {this.state.customerErrorMessage != "" &&
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <Alert bsStyle={this.state.customerErrorCode == 0 ? "success" : "danger"}>
                                                                                    <span>
                                                                                        {this.state.customerErrorMessage}
                                                                                    </span>
                                                                                </Alert>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                                        <Modal.Title style={{ color: "white" }}>Delete Customer</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="modal-body text-center">
                                                                            <Row>
                                                                                <Col xs={4} sm={4} md={2} className="text-left">
                                                                                    <h5>Name: </h5>
                                                                                </Col> <Col xs={8} sm={8} md={10} className="text-left">
                                                                                    <h5>{this.state.customerInfo.customerName}</h5>
                                                                                </Col>                                                                                
                                                                            </Row>  
                                                                            <Row>
                                                                                <Col xs={4} sm={4} md={2} className="text-left">
                                                                                    <h5>Email: </h5>
                                                                                </Col> <Col xs={8} sm={8} md={10} className="text-left">
                                                                                    <h5>{this.state.customerInfo.customerEmail}</h5>
                                                                                </Col>
                                                                            </Row>  
                                                                            <Row>
                                                                                <Col xs={4} sm={4} md={2} className="text-left">
                                                                                    <h5>Address: </h5>
                                                                                </Col> <Col xs={8} sm={8} md={10} className="text-left">
                                                                                    <h5>{this.state.customerInfo.billingAddress.address}</h5>
                                                                                </Col>
                                                                            </Row>  
                                                                            <Row>
                                                                            <Col xs={4} sm={4} md={2} className="text-left">
                                                                                    <h5>City: </h5>
                                                                                </Col> <Col xs={8} sm={8} md={4} className="text-left">
                                                                                    <h5>{this.state.customerInfo.billingAddress.city}</h5>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={2} className="text-left">
                                                                                    <h5>State: </h5>
                                                                                </Col> <Col xs={12} sm={12} md={4} className="text-left">
                                                                                    <h5>{this.state.customerInfo.billingAddress.state}</h5>
                                                                                </Col>
                                                                            </Row>  
                                                                            <Row>
                                                                                
                                                                                <Col xs={12} sm={12} md={2} className="text-left">
                                                                                    <h5>Postal: </h5>
                                                                                </Col> <Col xs={12} sm={12} md={4} className="text-left">
                                                                                    <h5>{this.state.customerInfo.billingAddress.postalCode}</h5>
                                                                                </Col>
                                                                                <Col xs={12} sm={12} md={2} className="text-left">
                                                                                    <h5>Country: </h5>
                                                                                </Col> <Col xs={12} sm={12} md={4} className="text-left">
                                                                                    <h5>{this.state.customerInfo.billingAddress.country}</h5>
                                                                                </Col>
                                                                            </Row>                                              
                                                                            
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showDeleteCustomerPopupModal: false })}>Close</Button>
                                                                        <Button bsStyle="primary" fill wd onClick={e => this.updateDeleteCustomerSubmit(this.state.customerInfo, true)}>Submit</Button>
                                                                    </Modal.Footer>
                                                        </Modal>                                    
                                                        
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>

                                            {/* Change Password Tab */}
                                            <Tab.Pane eventKey="productkey">                                                
                                                        <Row>
                                                            <Col md={12}>
                                                                {this.state.errorMessage != "" &&
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                                <span>
                                                                                    {this.state.errorMessage}
                                                                                </span>
                                                                            </Alert>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                {this.state.ShowProgressBar &&
                                                                    <Row >
                                                                        <Col md={6}>
                                                                            <CustomProgressBar></CustomProgressBar>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                <br />
                                                                <MuiThemeProvider theme={this.theme}>
                                                                    {this.state.ShowProgressBar &&
                                                                        <div>
                                                                            <CustomProgressBar></CustomProgressBar>
                                                                            <br />
                                                                        </div>

                                                                    }
                                                                    <MaterialTable                                                                
                                                                        columns={this.state.productColumns}
                                                                        data={this.state.products}
                                                                        options={this.state.customerTableOptions}
                                                                        actions={this.state.productActions}
                                                                        components={this.state.itemComponents}                                                                        
                                                                        // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                                                               
                                                                    />
                                                                </MuiThemeProvider>
                                                                <Modal
                                                                    show={this.state.showNewItemPopupModal}
                                                                    onHide={() => this.setState({ showNewItemPopupModal: false })}
                                                                    bsSize="large" backdrop="static"      
                                                                    >
                                                                    {this.state.ShowPopupProgressBar &&
                                                                        <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                                                            <CustomProgressBar></CustomProgressBar>
                                                                        </div>
                                                                    }
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Product/Service information</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    <FormGroup>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                        Item Name: <span className="star">*</span>
                                                                                    </div>
                                                                                    <FormControl
                                                                                            value={this.state.itemInfo.name}
                                                                                            type="text"
                                                                                            placeholder="Item Name"
                                                                                            name="name"
                                                                                            onChange={event => {
                                                                                                var value = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        name: value
                                                                                                    }
                                                                                                }));
                                                                                                if(this.verifyLength(value, 2))
                                                                                                {
                                                                                                    this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        nameState: "valid",
                                                                                                        nameErrorMessage: ""
                                                                                                    }
                                                                                                    }));
                                                                                                    this.state.itemInfo.nameState = "valid"
                                                                                                    this.validateNewItem();
                                                                                                }
                                                                                                else{
                                                                                                    this.setState(prevState => ({
                                                                                                        ...prevState,
                                                                                                        itemInfo: {
                                                                                                            ...prevState.itemInfo,
                                                                                                            nameErrorMessage: (
                                                                                                                <small className="text-danger">
                                                                                                                    Invalid Item Name.
                                                                                                                </small>
                                                                                                            )
                                                                                                        }
                                                                                                    }));
                                                                                                    this.setState({ isNewItemFormValid: false });
                                                                                                }                                                    
                                                                                            }
                                                                                            }
                                                                                            onBlur={e => this.validateNewItem()}
                                                                                    />
                                                                                    {this.state.itemInfo.nameErrorMessage}
                                                                                </FormGroup>
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            SKU: 
                                                                                        </div>
                                                                                        <FormControl
                                                                                            value={this.state.itemInfo.sku}
                                                                                            type="text"
                                                                                            placeholder="SKU"
                                                                                            name="sku"
                                                                                            onChange={event => {
                                                                                                var value = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        sku : value
                                                                                                    }
                                                                                                }));  
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <FormGroup>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                        Price/Rate: 
                                                                                    </div>
                                                                                    <FormControl
                                                                                        value={this.state.itemInfo.price}
                                                                                        type="number"
                                                                                        placeholder="Price/Rate"
                                                                                        name="rate"
                                                                                        min="0.00"
                                                                                        style={{ textAlign: "center" }}
                                                                                        onKeyPress= {this.handleRateKeyPress}
                                                                                        step="0.01"
                                                                                        onChange={event => {
                                                                                            var value = event.target.value;
                                                                                                        this.setState(prevState => ({
                                                                                                        itemInfo: {
                                                                                                            ...prevState.itemInfo,
                                                                                                            price: value                                                                       
                                                                                                    }}));                                                                                                                
                                                                                            }
                                                                                        }
                                                                                        onBlur={event => {
                                                                                            var value = event.target.value;
                                                                                                        this.setState(prevState => ({
                                                                                                        itemInfo: {
                                                                                                            ...prevState.itemInfo,
                                                                                                            price: parseFloat(value).toFixed(2)                                                                            
                                                                                                    }}));                                                                                                                
                                                                                            
                                                                                        }}
                                                                                    /> 
                                                                                    </FormGroup>                                                      
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Item Type: <span className="star">*</span>
                                                                                        </div>
                                                                                        <Select
                                                                                            className="react-select primary"
                                                                                            classNamePrefix="react-select"
                                                                                            name="itemtype"
                                                                                            value={this.state.itemInfo.selectedItemType}
                                                                                            onChange={value => {
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        selectedItemType: value
                                                                                                    }
                                                                                                }));
                                                                                                let accounts = this.state.accounts; 
                                                                                                let incomeAccounts = [];
                                                                                                let expenseAccounts = [];
                                                                                                incomeAccounts.push({value: "", label: "Select Account", isDisabled: true});
                                                                                                expenseAccounts.push({value: "", label: "Select Account", isDisabled: true});
                                                                                                let selectedIncomeAccount = "";
                                                                                                let selectedExpenseAccount = "";
                                                                                                if(value.value === "Inventory"){                                  
                                                                                                    accounts.map(data => {
                                                                                                        if((data.Classification == "Revenue" || data.Classification == "Income" ||  data.AccountType == "Income") && (data.AccountSubType == "SalesOfProductIncome"))
                                                                                                            incomeAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                                                                                                            if(data.Name == "Sales of Product Income"){
                                                                                                                selectedIncomeAccount = {value: "79", label: "Sales of Product Income|Income"};
                                                                                                            }

                                                                                                    })                                                                
                                                                                                    this.setState(prevState => ({
                                                                                                        itemInfo: {
                                                                                                            ...prevState.itemInfo,
                                                                                                            incomeAccount: incomeAccounts
                                                                                                        }
                                                                                                    }));
                                                                                                    accounts.map(data => {
                                                                                                    if(data.AccountSubType == "SuppliesMaterialsCogs"){
                                                                                                            expenseAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                                                                                                            if(data.Name == "Cost of Goods Sold"){
                                                                                                                selectedExpenseAccount =  {value: data.Id, label: data.Name +"|"+ data.AccountType};
                                                                                                            }
                                                                                                        }
                                                                                                    });
                                                                                                    this.setState(prevState => ({
                                                                                                        itemInfo: {
                                                                                                            ...prevState.itemInfo,
                                                                                                            expenseAccount: expenseAccounts
                                                                                                        }
                                                                                                    }));


                                                                                                    this.state.itemInfo.isInvenotryItem = true;
                                                                                                    this.state.itemInfo.quantityOnHand = 0;
                                                                                                    this.state.itemInfo.quantityErrorMessage = "";
                                                                                                    if(selectedIncomeAccount == ""){
                                                                                                        selectedIncomeAccount = incomeAccounts[1];
                                                                                                    }
                                                                                                    if(selectedExpenseAccount == ""){
                                                                                                        selectedExpenseAccount = expenseAccounts[1];
                                                                                                    }
                                                                                                    this.state.itemInfo.selectedIncomeAccount =  selectedIncomeAccount;
                                                                                                    
                                                                                                }
                                                                                                else{
                                                                                                    accounts.map(data => {
                                                                                                        if(data.Classification == "Revenue" || data.Classification == "Income" ||  data.AccountType == "Income")
                                                                                                            incomeAccounts.push({value:data.Id, label:data.Name +"|"+ data.AccountType, AccountSubType : data.AccountSubType});
                                                                                                            if(data.Name == "Services"){
                                                                                                                selectedIncomeAccount = {value: data.Id, label: data.Name +"|"+ data.AccountType};
                                                                                                            }
                                                                                                    })                                                              
                                                                                                    this.setState(prevState => ({
                                                                                                        itemInfo: {
                                                                                                            ...prevState.itemInfo,
                                                                                                            incomeAccount: incomeAccounts
                                                                                                        }
                                                                                                    }));
                                                                                                    
                                                                                                    
                                                                                                    this.state.itemInfo.isInvenotryItem = false;
                                                                                                    this.state.itemInfo.quantityOnHand = null;
                                                                                                    this.state.itemInfo.quantityErrorMessage = "";
                                                                                                    if(selectedIncomeAccount == "")
                                                                                                    {
                                                                                                        selectedIncomeAccount = incomeAccounts[0];
                                                                                                    }
                                                                                                    this.state.itemInfo.selectedIncomeAccount=  selectedIncomeAccount;
                                                                                                }
                                                                                            }
                                                                                            }
                                                                                            options={this.state.itemInfo.itemType}
                                                                                            placeholder="Select Item Type"
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Income Account: <span className="star">*</span>
                                                                                        </div>
                                                                                        <Select
                                                                                            className="react-select primary"
                                                                                            classNamePrefix="react-select"
                                                                                            name="incomeAccount"
                                                                                            value={this.state.itemInfo.selectedIncomeAccount}
                                                                                            onChange={value => {
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        selectedIncomeAccount: value
                                                                                                    }
                                                                                                }));
                                                                                            }
                                                                                            }
                                                                                            options={this.state.itemInfo.incomeAccount}
                                                                                            placeholder="Select Income Account"
                                                                                            onBlur={e => this.validateNewItem()}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            {this.state.itemInfo.isInvenotryItem && 
                                                                            <Row>
                                                                                <Col md={3}>
                                                                                <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Qty On Hand: <span className="star">*</span>
                                                                                        </div>
                                                                                            <FormControl
                                                                                                value={this.state.itemInfo.quantityOnHand}
                                                                                                type="number"
                                                                                                placeholder="Qty on Hand"
                                                                                                name="quantityOnHand"
                                                                                                style={{ textAlign: "center" }}
                                                                                                step="1"
                                                                                                onChange={event => {
                                                                                                            var value = event.target.value;
                                                                                                            this.state.itemInfo.quantityOnHand = value;                                                                
                                                                                                            this.validateNewItem();
                                                                                                        }                                                                                                                      
                                                                                                    } 
                                                                                                    onBlur={e => this.validateNewItem()}                                                          
                                                                                            />
                                                                                            {this.state.itemInfo.quantityErrorMessage} 
                                                                                    </FormGroup>        
                                                                                </Col>
                                                                                <Col md={3}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                        Inv. Start Date: <span className="star">*</span>
                                                                                        </div>
                                                                                        <InputGroup>
                                                                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                                                                            <Datetime
                                                                                                timeFormat={false}
                                                                                                closeOnSelect={true}
                                                                                                className="react-calendar"
                                                                                                inputProps={{ placeholder: "As of Date" }}
                                                                                                onChange={this.quantityOnHandDateHandler}
                                                                                                value={this.state.itemInfo.quantityOnHandDate}
                                                                                            // dateFormat={'YYYY-MM-DD'}
                                                                                            />
                                                                                            <InputGroup.Addon></InputGroup.Addon>
                                                                                        </InputGroup>
                                                                                    </FormGroup>
                                                                                </Col>                                            
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Expense Account: <span className="star">*</span>
                                                                                        </div>
                                                                                        <Select
                                                                                            className="react-select primary"
                                                                                            classNamePrefix="react-select"
                                                                                            name="expenseAccount"
                                                                                            value={this.state.itemInfo.selectedExpenseAccount}
                                                                                            onChange={value => {
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        selectedExpenseAccount: value
                                                                                                    }
                                                                                                }));
                                                                                            }
                                                                                            }
                                                                                            options={this.state.itemInfo.expenseAccount}
                                                                                            placeholder="Select Expense Account"
                                                                                        />
                                                                                    </FormGroup>                                                
                                                                                </Col>
                                                                            </Row>
                                                                            }                                   
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Sales Information:
                                                                                </div>
                                                                                <FormGroup controlId="formControlsTextarea">
                                                                                        <FormControl
                                                                                            rows="2"
                                                                                            value={this.state.itemInfo.salesInformation}
                                                                                            componentClass="textarea"
                                                                                            bsClass="form-control"
                                                                                            placeholder="Sales Information"
                                                                                            maxLength="200"
                                                                                            onChange={event => {
                                                                                                const msg = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        salesInformation: msg
                                                                                                    }
                                                                                                }));
                                                                                            }}
                                                                                            onBlur={e => this.validateNewItem()}
                                                                                        />
                                                                                    </FormGroup>
                                                                                
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <br/>
                                                                                    <CustomCheckbox
                                                                                        name="ItemTaxableCheckBox"
                                                                                        checked={this.state.itemInfo.taxable}
                                                                                        labelText="Taxable"
                                                                                        labelProps={{style: {fontSize: "16px", marginTop:"5px"}}} 
                                                                                        formControlProps={{
                                                                                        onChange:(e) => {                                                    
                                                                                            var value = e.target.checked;
                                                                                            this.setState(prevState => ({
                                                                                                ...prevState,
                                                                                                itemInfo: {
                                                                                                    ...prevState.itemInfo,
                                                                                                    taxable: value
                                                                                                }
                                                                                            }));
                                                                                        }
                                                                                    }}  
                                                                                    />
                                                                                </Col>
                                                                            </Row>

                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>                                   
                                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewItemPopupModal: false })}>Close</Button>
                                                                        <Button bsStyle="primary" fill wd onClick={e => this.createItemSubmit(this.state.itemInfo)} disabled={!this.state.isNewItemFormValid}>Save</Button>
                                                                    </Modal.Footer>
                                                                </Modal>  
                                                                <Modal
                                                                    show={this.state.showUpdateItemPopupModal}
                                                                    onHide={() => this.setState({ showUpdateItemPopupModal: false })}
                                                                    bsSize="large"       
                                                                    >
                                                                    {this.state.ShowItemPopupProgressBar &&
                                                                        <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                                                            <CustomProgressBar></CustomProgressBar>
                                                                        </div>
                                                                    }
                                                                    {this.state.productErrorMessage != "" &&
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <Alert bsStyle={this.state.productErrorCode == 0 ? "success" : "danger"}>
                                                                                    <span>
                                                                                        {this.state.productErrorMessage}
                                                                                    </span>
                                                                                </Alert>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Product/Service information</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div>
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    <FormGroup>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                        Item Name: <span className="star">*</span>
                                                                                    </div>
                                                                                    <FormControl
                                                                                            value={this.state.itemInfo.name}
                                                                                            type="text"
                                                                                            placeholder="Item Name"
                                                                                            name="name"
                                                                                            onChange={event => {
                                                                                                var value = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        name: value
                                                                                                    }
                                                                                                }));
                                                                                                if(this.verifyLength(value, 2))
                                                                                                {
                                                                                                    this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        nameState: "valid",
                                                                                                        nameErrorMessage: ""
                                                                                                    }
                                                                                                    }));
                                                                                                    this.state.itemInfo.nameState = "valid"
                                                                                                    this.validateItem();
                                                                                                }
                                                                                                else{
                                                                                                    this.setState(prevState => ({
                                                                                                        ...prevState,
                                                                                                        itemInfo: {
                                                                                                            ...prevState.itemInfo,
                                                                                                            nameErrorMessage: (
                                                                                                                <small className="text-danger">
                                                                                                                    Invalid Item Name.
                                                                                                                </small>
                                                                                                            )
                                                                                                        }
                                                                                                    }));
                                                                                                    this.setState({ isItemFormValid: false });
                                                                                                }                                                    
                                                                                            }
                                                                                            }
                                                                                            onBlur={e => this.validateItem()}
                                                                                    />
                                                                                    {this.state.itemInfo.nameErrorMessage}
                                                                                </FormGroup>
                                                                                </Col>
                                                                                <Col md={4}>
                                                                                    <FormGroup>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                        Price/Rate: 
                                                                                    </div>
                                                                                    <FormControl
                                                                                                value={this.state.itemInfo.price}
                                                                                                type="number"
                                                                                                placeholder="Price/Rate"
                                                                                                name="rate"
                                                                                                min="0.00"
                                                                                                style={{ textAlign: "center" }}
                                                                                                onKeyPress= {this.handleRateKeyPress}
                                                                                                step="0.01"
                                                                                                onChange={event => {
                                                                                                    var value = event.target.value;
                                                                                                                this.setState(prevState => ({
                                                                                                                itemInfo: {
                                                                                                                    ...prevState.itemInfo,
                                                                                                                    price: value                                                                       
                                                                                                            }}));                                                                                                                
                                                                                                    }
                                                                                                }
                                                                                                onBlur={event => {
                                                                                                    var value = event.target.value;
                                                                                                                this.setState(prevState => ({
                                                                                                                itemInfo: {
                                                                                                                    ...prevState.itemInfo,
                                                                                                                    price: parseFloat(value).toFixed(2)                                                                            
                                                                                                            }}));                                                                                                                
                                                                                                    
                                                                                                }}
                                                                                            /> 
                                                                                    </FormGroup>                                                      
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Item Type: <span className="star">*</span>
                                                                                        </div>
                                                                                        <Select
                                                                                            className="react-select primary"
                                                                                            classNamePrefix="react-select"
                                                                                            name="itemtype"
                                                                                            value={this.state.itemInfo.selectedItemType}
                                                                                            onChange={value => {
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        selectedItemType: value
                                                                                                    }
                                                                                                }));
                                                                                                if(value.value === "Inventory"){
                                                                                                    this.state.itemInfo.isInvenotryItem = true;
                                                                                                    this.state.itemInfo.selectedInventoryAssetAccount = this.state.itemInfo.inventoryAssetAccount[1];
                                                                                                    this.state.itemInfo.quantityOnHand = 0;
                                                                                                    this.state.itemInfo.quantityErrorMessage = "";
                                                                                                }
                                                                                                else{
                                                                                                    this.state.itemInfo.isInvenotryItem = false;
                                                                                                    this.state.itemInfo.quantityOnHand = null;
                                                                                                    // this.state.itemInfo.selectedInventoryAssetAccount = null;
                                                                                                    this.state.itemInfo.quantityErrorMessage = "";
                                                                                                }
                                                                                            }
                                                                                            }
                                                                                            options={this.state.itemInfo.itemType}
                                                                                            placeholder="Select Item Type"
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Income Account: <span className="star">*</span>
                                                                                        </div>
                                                                                        <Select
                                                                                            className="react-select primary"
                                                                                            classNamePrefix="react-select"
                                                                                            name="incomeAccount"
                                                                                            value={this.state.itemInfo.selectedIncomeAccount}
                                                                                            onChange={value => {
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        selectedIncomeAccount: value
                                                                                                    }
                                                                                                }));
                                                                                            }
                                                                                            }
                                                                                            options={this.state.itemInfo.incomeAccount}
                                                                                            placeholder="Select Income Account"
                                                                                            onBlur={e => this.validateItem()}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            {this.state.itemInfo.isInvenotryItem && 
                                                                            <Row>
                                                                                <Col md={3}>
                                                                                <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Qty On Hand: <span className="star">*</span>
                                                                                        </div>
                                                                                            <FormControl
                                                                                                value={this.state.itemInfo.quantityOnHand}
                                                                                                type="number"
                                                                                                placeholder="Qty on Hand"
                                                                                                name="quantityOnHand"
                                                                                                style={{ textAlign: "center" }}
                                                                                                step="1"
                                                                                                onChange={event => {
                                                                                                            var value = event.target.value;
                                                                                                            this.state.itemInfo.quantityOnHand = value;                                                                
                                                                                                            this.validateItem();
                                                                                                        }                                                                                                                      
                                                                                                    } 
                                                                                                    onBlur={e => this.validateItem()}                                                          
                                                                                            />
                                                                                            {this.state.itemInfo.quantityErrorMessage} 
                                                                                    </FormGroup>        
                                                                                </Col>
                                                                                <Col md={3}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Inv. Start Date: <span className="star">*</span>
                                                                                        </div>
                                                                                        <InputGroup>
                                                                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                                                                            <Datetime
                                                                                                timeFormat={false}
                                                                                                closeOnSelect={true}
                                                                                                className="react-calendar"
                                                                                                inputProps={{ placeholder: "As of Date" }}
                                                                                                onChange={this.quantityOnHandDateHandler}
                                                                                                value={this.state.itemInfo.quantityOnHandDate}
                                                                                            // dateFormat={'YYYY-MM-DD'}
                                                                                            />
                                                                                            <InputGroup.Addon></InputGroup.Addon>
                                                                                        </InputGroup>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            Expense Account: <span className="star">*</span>
                                                                                        </div>
                                                                                        <Select
                                                                                            className="react-select primary"
                                                                                            classNamePrefix="react-select"
                                                                                            name="expenseAccount"
                                                                                            value={this.state.itemInfo.selectedExpenseAccount}
                                                                                            onChange={value => {
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        selectedExpenseAccount: value
                                                                                                    }
                                                                                                }));
                                                                                            }
                                                                                            }
                                                                                            options={this.state.itemInfo.expenseAccount}
                                                                                            placeholder="Select Expense Account"
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            }                                                                            
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                Sales Information:
                                                                                </div>
                                                                                <FormGroup controlId="formControlsTextarea">
                                                                                        <FormControl
                                                                                            rows="2"
                                                                                            value={this.state.itemInfo.salesInformation}
                                                                                            componentClass="textarea"
                                                                                            bsClass="form-control"
                                                                                            placeholder="Sales Information"
                                                                                            maxLength="200"
                                                                                            onChange={event => {
                                                                                                const msg = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    itemInfo: {
                                                                                                        ...prevState.itemInfo,
                                                                                                        salesInformation: msg
                                                                                                    }
                                                                                                }));
                                                                                            }}
                                                                                            onBlur={e => this.validateItem()}
                                                                                        />
                                                                                    </FormGroup>
                                                                                
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <br/>
                                                                                    <CustomCheckbox
                                                                                        name="ItemEditTaxableCheckBox"
                                                                                        checked={this.state.itemInfo.taxable}
                                                                                        labelText="Taxable"
                                                                                        labelProps={{style: {fontSize: "16px", marginTop:"5px"}}} 
                                                                                        formControlProps={{
                                                                                        onChange:(e) => {                                                    
                                                                                            var value = e.target.checked;
                                                                                            this.setState(prevState => ({
                                                                                                ...prevState,
                                                                                                itemInfo: {
                                                                                                    ...prevState.itemInfo,
                                                                                                    taxable: value
                                                                                                }
                                                                                            }));
                                                                                        }
                                                                                    }}  
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>                                   
                                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showUpdateItemPopupModal: false })}>Close</Button>
                                                                        <Button bsStyle="primary" fill wd onClick={e => this.updateDeleteItemSubmit(this.state.itemInfo)} disabled={!this.state.isItemFormValid}>Save</Button>
                                                                    </Modal.Footer>
                                                                </Modal>                         
                                                                <Modal
                                                                    show={this.state.showDeleteProductPopupModal}
                                                                    onHide={() => this.setState({ showDeleteProductPopupModal: false })}
                                                                    >
                                                                    {this.state.ShowItemPopupProgressBar &&
                                                                        <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                                                            <CustomProgressBar></CustomProgressBar>
                                                                        </div>
                                                                    }
                                                                    {this.state.productErrorMessage != "" &&
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <Alert bsStyle={this.state.productErrorCode == 0 ? "success" : "danger"}>
                                                                                    <span>
                                                                                        {this.state.productErrorMessage}
                                                                                    </span>
                                                                                </Alert>
                                                                            </Col>
                                                                        </Row>
                                                                    }

                                                                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                                        <Modal.Title style={{ color: "white" }}>Delete Product</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="modal-body text-center">
                                                                            <Row>
                                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                                    <h5>Name : </h5>
                                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                                    <h5>{this.state.itemInfo.name}</h5>
                                                                                </Col>
                                                                            </Row>  
                                                                            <Row>
                                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                                    <h5>Description : </h5>
                                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                                    <h5>{this.state.itemInfo.description}</h5>
                                                                                </Col>
                                                                            </Row>  
                                                                            <Row>
                                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                                    <h5>Type : </h5>
                                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                                    <h5>{this.state.itemInfo.type}</h5>
                                                                                </Col>
                                                                            </Row>                                               
                                                                            <Row>
                                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                                    <h5>Price :</h5>
                                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                                    <h5>{this.state.itemInfo.price}</h5>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showDeleteProductPopupModal: false })}>Close</Button>
                                                                        <Button bsStyle="primary" fill wd onClick={e => this.updateDeleteItemSubmit(this.state.itemInfo, true)}>Submit</Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </Col>
                                                        </Row>                                                   
                                            </Tab.Pane>
                                            
                                        
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    }
                />
            </div>
        );
    }
}


export default CustomersAndProducts;