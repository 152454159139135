import React, { Component } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import creditCardIcon from "assets/img/icons/creditcard.png";
import achIcon from "assets/img/icons/ach.png";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import CreditCardInput from 'utils/Credit_Card_Input.js';
import ExpInput from 'utils/Exp_Input.js';
import CVVInput from 'utils/CVV_Input.js';
import AccountNumberInput from 'utils/AccountNumber_Input.js';
import RoutingNumberInput from 'utils/RoutingNumber_Input.js';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButton/CustomButton.jsx";
import NMICollectJSInlinePage from "views/Components/NMICollectJSInlinePage";
import SessionTimeout from 'components/SessionTimeout';

// #############################
const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb("#000") +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb("#0075c1") +
    ",.4)"
};

const styles = {   
    dropDownFontStyle:{
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: "#AAAAAA",
    fontSize: "13px",
    marginTop: "2px",
    marginBottom: "-2px",
   // fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "1.42857"
  },
  select: {
      width: "100%",
     marginTop: 0,
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "1.42857",
    textDecoration: "none",
   // textTransform: "uppercase",
    color: "#3C4858",
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)"
    },
    "& + input + svg": {
      transition: "all 300ms linear"
    }
  },
  selectMenuItem: {
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "2",
    whiteSpace: "nowrap",
    color: "#333",
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: "#0075c1",
      color:  "#FFF",
      ...primaryBoxShadow
    }
  }
}    
class AuthNetPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerProfile: "none",
            customerProfiles: [
                { value: "123456789", label: "XXXXXX6789", isACHProfile: false },
                { value: "9876541234", label: "XXXXX1234", isACHProfile: false },
                { value: "987651111", label: "XXXXX1111", isACHProfile: false},
                { value: "9876522222", label: "XXXXX2222", isACHProfile: false },
                { value: "987653333", label: "XXXXX3333", isACHProfile: true },
                { value: "98765444444", label: "XXXXX4444", isACHProfile: true }
            ],
            Customer: {
                CardHolderName: '',
                CardNumber: '',
                CardExpiry: '',
                GatewayExpiryDate: '',
                CardCVV: '',
                AccountNumber: '',
                AccountType: 'checking',
                RoutingNumber: '',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: false,
                CardHolderNameValid: true,
                AccountNameValid: true,
                AccountNumberValid: true,
                RoutingNumberValid: true,
                CardNumberValid: true,
                CardExpiryValid: true,
                CardCVVValid: true,
                AmountValid: true,
                CheckBoxChecked: false,
                FormErrors: { CardHolderName: '', AccountName: '' },
            },
            Invoice: [],
            invoiceNumber:"",
            customerName: "",
            invoiceDate: "",
            dueDate: "",
            Amount: 0.00,
            totalAmount: 0.00,
            amountToCharge: 0.00,
            remainingBalance: 0.00,
            customerEmail: "",
            invoiceData: null,
            paymentIcon: creditCardIcon,
            IsCreditCard: true,
            IsACH: false,
            isAuthenticated: true,

        }
        this.handleCharged = this.handleCharged.bind(this);
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }
    
    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        this.state.invoiceData = this.props.location.state ? this.props.location.state.invoiceData : null;
        if(this.state.invoiceData !== null)
        {
            this.setState({ Invoice: this.state.invoiceData || [] });
            this.setState({ Amount: this.state.invoiceData.Balance });
            this.setState({ totalAmount: this.state.invoiceData.Balance });            
            this.setState({ amountToCharge: this.state.invoiceData.Balance });
            this.setState({ customerEmail: this.state.invoiceData.BillEmail ? this.state.invoiceData.BillEmail.Address : "" });
            this.setState({ invoiceNumber: this.state.invoiceData.DocNumber});
            this.setState({ customerName: this.state.invoiceData.CustomerRef.name});
            this.setState({ invoiceDate: this.state.invoiceData.TxnDate});
            this.setState({ dueDate: this.state.invoiceData.DueDate});
        }
        else
        {
             this.props.history.push('/invoice/invoices');
        }
        document.getElementById("loaderDiv").style.display = "none";
    }

    handleAmountChange = e => {
        const { name, value } = e.target;
        if(parseFloat(value) > parseFloat(this.state.totalAmount))
        {
             this.state.Customer.AmountValid = false;
             this.validateField(name, value)
            return false;
        }

        if(parseFloat(value) <= 0)
        {
             this.state.Customer.AmountValid = false;
             this.validateField(name, value)
             return false;
        }
        else
        {
             this.state.Customer.AmountValid = true;
        }

        this.setState(prevState => ({
                            Amount:
                                {
                                    ...prevState.Amount, Amount: value
                                }
                        }), () => { this.validateField(name, value) })
                        
        this.setState({remainingBalance: parseFloat(this.state.totalAmount) - parseFloat(value)})
        this.setState({amountToCharge : value});      
    };

    handleRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "creditcard") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsACH: false });
            this.setState({ paymentIcon: creditCardIcon });
        }
        else {
            this.setState({ IsACH: true });
            this.setState({ IsCreditCard: false });
            this.setState({ paymentIcon: achIcon });
        }
        this.state.Customer.CheckBoxChecked = false;
        this.state.Customer.FormValid = false; 
    };

    handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        //alert(charCode);
        //97-122, 32,65-90'
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };

    handleOnBlurInput(e) {
        // console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        this.validateField(e.target.name, e.target.value)
        if (e.target.name === "CardExpiry" && this.state.Customer.CardExpiryValid === true) {
            var dt_year = e.target.value.split("/")[1]; //"20"+
            var dt_month = e.target.value.split("/")[0];

            this.setState(prevState => ({
                Customer:
                    {
                        ...prevState.Customer, GatewayExpiryDate: dt_year + "-" + dt_month
                    }
            }), () => console.log(this.state.Customer.GatewayExpiryDate));
        }
    };

    handleUserInput(e) {
        // console.log('handleUserInput is calling..' + e.target.name);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'CardHolderName':
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardHolderName: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CardNumber":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardNumber: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CardExpiry":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardExpiry: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CardCVV":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardCVV: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, Address: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, Country: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, City: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, State: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, PostalCode: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                this.setState(prevState => ({
                    Amount:
                        {
                            ...prevState.Amount, Amount: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CheckBox":
                this.validateField(name, e.target.checked)
                break;
            case "AccountName":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, AccountName: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, AccountNumber: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, RoutingNumber: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountType":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, AccountType: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            default:
                break;
        }

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.Customer.FormErrors;
        let cardHolderNameValid = this.state.Customer.CardHolderNameValid;
        let cardNumberValid = this.state.Customer.CardNumberValid;
        let cardExpiryValid = this.state.Customer.CardExpiryValid;
        let cardCVVValid = this.state.Customer.CardCVVValid;
        let checkBoxChecked = this.state.Customer.CheckBoxChecked;

        let accountNumberValid = this.state.Customer.AccountNumberValid;
        let accountNameValid = this.state.Customer.AccountNameValid;
        let routingNumberValid = this.state.Customer.RoutingNumberValid;
        let amountValid = this.state.Customer.AmountValid;
        switch (fieldName) {
            case 'CardHolderName':
                cardHolderNameValid = true;//value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.CardHolderName = cardHolderNameValid ? '' : ' is invalid';
                this.state.Customer.CardHolderNameValid = cardHolderNameValid ? true : false;
                break;
            case 'CardNumber':
                cardNumberValid = global.isCardNumberValid;
                this.state.Customer.CardNumberValid = cardNumberValid ? true : false;
                break;
            case 'CardExpiry':
                cardExpiryValid = global.isCardExpiryValid;
                this.state.Customer.CardExpiryValid = cardExpiryValid ? true : false;
                break;
            case 'CardCVV':
                cardCVVValid = global.isCardCVVValid;
                this.state.Customer.CardCVVValid = cardCVVValid ? true : false;
                break;
            case 'CheckBox':
                checkBoxChecked = value;
                this.state.Customer.CheckBoxChecked = value;
                break;
            case 'Amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.Customer.AmountValid = amountValid ? true : false;
                this.state.Amount = value;
                break;
            case 'AccountName':
                accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.Customer.AccountNameValid = accountNameValid ? true : false;
                break;
            case 'AccountNumber':
                accountNumberValid = value.match(/^\d{4,17}$/);
                this.state.Customer.AccountNumberValid = accountNumberValid ? true : false;
                break;
            case 'RoutingNumber':
                routingNumberValid = value.match(/^\d{9}$/);
                this.state.Customer.RoutingNumberValid = routingNumberValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            CardHolderNameValid: cardHolderNameValid,
            CardNumberValid: cardNumberValid,
            CardExpiryValid: cardExpiryValid,
            CardCVVValid: cardCVVValid,
            CheckBoxChecked: checkBoxChecked,
            AccountNameValid: accountNameValid,
            AccountNumberValid: accountNumberValid,
            RoutingNumberValid: routingNumberValid,
            AmountValid: amountValid
        }, this.validateForm);

    }

    validateForm() {
        //console.log(this.state.Customer.CardNumberValid);
        if (this.state.IsCreditCard) {
            this.setState(prevState => ({
                Customer:
                    {
                        ...prevState.Customer, FormValid: this.state.IsCreditCard && this.state.Customer.CardCVVValid && this.state.Customer.CardExpiryValid
                            && this.state.Customer.CardHolderNameValid && this.state.Customer.CardNumberValid && this.state.Customer.AmountValid &&
                            this.state.Customer.CheckBoxChecked
                    }
            }), () => console.log())
        }
        else if (this.state.IsACH) {
            this.setState(prevState => ({
                Customer:
                    {
                        ...prevState.Customer, FormValid: this.state.Customer.AccountNameValid && this.state.Customer.AccountNumberValid
                            && this.state.Customer.AmountValid && this.state.Customer.RoutingNumberValid
                    }
            }), () => console.log())
        }
    }

    handleCharged = (event) =>{
        // console.log(this.state.Customer);
        this.setState({ShowProgressBar : true});
        const invoice_info = this.state.Invoice;
        // this.state.Invoice.forEach((element) => {
        //   invoice_info = element;
        // });
        const payment_info = this.state.Customer;
        const amount = this.state.Amount;

        let cardInfo = null;
        let achInfo = null;
        if (this.state.IsCreditCard){
        cardInfo = {
            "CardHolder": payment_info.CardHolderName,
            "CardNumber": payment_info.CardNumber,
            "Cvv": payment_info.CardCVV,
            "Month": payment_info.CardExpiry.split("/")[0],
            "Year": payment_info.CardExpiry.split("/")[1],
            "CardType": "MasterCard"
        }
        }else if (this.state.IsACH){
        achInfo = {
            "AccountHolder": payment_info.AccountName,
            "AccountNumber": payment_info.AccountNumber,
            "RoutingNumber": payment_info.RoutingNumber,
            "AccountType": payment_info.AccountType,
        }
        }

        const data = {
        "InvoiceInfo": {
            "Id": invoice_info.Id,
            "DocNumber": [invoice_info.DocNumber],
            "Type": "payment"
        },
        "SalesReceiptInfo": {
            "Id": [],
            "DocNumber": [],
            "Type": "salesreceipt"
        },
        "PaymentInfo": {
            "CardInfo": cardInfo,
            "AchInfo": achInfo,
            "ProfileInfo": {
                "ProfileId": payment_info.ProfileId,
                "CreateProfile": payment_info.IsCreateProfile
            },
            "BillingInfo": {
                "Country": payment_info.Country,
                "City": payment_info.City,
                "State": payment_info.State,
                "Street": payment_info.Address,
                "PostalCode": payment_info.PostalCode
            },
            "TransactionInfo": {
                "TransType": "sale",
                "Amount": amount,
                "ActionType": "invoice",
                "CurrencyCode": "USD"
            }
        },
        "CustomerRef": {
            "Name": invoice_info ? invoice_info.CustomerRef.name : null,
            "Value": invoice_info ? invoice_info.CustomerRef.value : null,
            "Email": invoice_info ? invoice_info.CustomerRef.Email : null
        }
        }

        // if(AuthorizNet)  //will call this for authorizeNet
        // {
        this.sendPaymentDataToAnet(data);
        // }
        // else  //will call this for CardConnect
        // {
        //this.processSale(data);
        //}
    }

    sendPaymentDataToAnet = (data, paymentProfile) => {
        
        // console.log("sendPaymentDataToAnet called");
        return false;

        //event.preventDefault();
        var authData = {};
            authData.clientKey = "4ahddMU8J583MwDm9uYTL5h38zzdbR99Urj3D7XA7gxL2bN5T2MTh3KY8zZfUU3c";
            authData.apiLoginID = "6hh74hL5gH5";

        var secureData = {};
        secureData.authData = authData;
        
        var cardData = {};
        var bankData = {};
        //If credit card
            if (this.state.IsCreditCard){
                var cardInfo = data["PaymentInfo"]["CardInfo"];
                cardData.cardNumber = cardInfo.CardNumber;
                cardData.month = cardInfo.Month;
                cardData.year = cardInfo.Year; 
                cardData.cardCode = cardInfo.Cvv; 

                secureData.cardData = cardData;
            }
            //if ACH/Bank
            else if (this.state.IsACH){
            var bankInfo = data["PaymentInfo"]["AchInfo"];   
            bankData.accountNumber = bankInfo.AccountNumber;
            bankData.routingNumber = bankInfo.RoutingNumber;
            bankData.nameOnAccount = bankInfo.AccountHolder;
            bankData.accountType = bankInfo.AccountType;

            secureData.bankData = bankData;
            }


        let lastResponse = null;

        window.Accept.dispatchData(secureData, response => {
            // just in case it still gets called twice, ignore the second call
            if (response == lastResponse) {
                // console.log("skipping duplicated responseHandler() call because it's the same response as the previous response");
                return;
            }
            lastResponse = response;

            // console.log("responseHandler function called");
            try{
            if (response.messages.resultCode === "Error") {
                // stopLoading();
                var i = 0;
                while (i < response.messages.message.length) {
                    // console.log(
                    //     response.messages.message[i].code + ": " +
                    //     response.messages.message[i].text
                    // );
                    // only show the user the first error message
                    if (i == 0) {
                        var error = response.messages.message[i].text;
                        console.error("Error", error);
                    }	
                    i = i + 1;
                }
            } else {
                this.onReceiveOpaqueData(response.opaqueData);
            }
            } catch(error){
            // console.log(error);
            }
        });
     };

    onReceiveOpaqueData=(opaqueData) =>{
        // console.log(opaqueData);
        document.getElementById("dataDescriptor").value = opaqueData.dataDescriptor;
        document.getElementById("dataValue").value = opaqueData.dataValue;

        // this.state.Customer.CardHolderName = '';
        this.state.Customer.CardNumber = '';
        this.state.Customer.CardExpiry = '';
        this.state.Customer.CardCVV = '';
        this.state.Customer.AccountNumber = '';
        this.state.Customer.RoutingNumber = '';
        // this.state.Customer.AccountName = '';

        const invoice_info = this.state.Invoice;
        const payment_info = this.state.Customer;
        const amount = this.state.Amount;

        const payload = {
            "InvoiceInfo": {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "payment"
            },
            "SalesReceiptInfo": {
                "Id": [],
                "DocNumber": [],
                "Type": "salesreceipt"
            },
            "PaymentInfo": {
                "CardInfo": null,
                "AchInfo": null,
                "AcceptPaymentInfo":{
                "dataDescriptor": opaqueData.dataDescriptor,
                "dataValue": opaqueData.dataValue,
                "dataName": this.state.Customer.CardHolderName ? this.state.Customer.CardHolderName : this.state.Customer.AccountName
                },
                "ProfileInfo": {
                    "ProfileId": payment_info.ProfileId,
                    "CreateProfile": payment_info.IsCreateProfile
                },
                "BillingInfo": {
                    "Country": payment_info.Country,
                    "City": payment_info.City,
                    "State": payment_info.State,
                    "Street": payment_info.Address,
                    "PostalCode": payment_info.PostalCode
                },
                "TransactionInfo": {
                    "TransType": "sale",
                    "Amount": amount,
                    "ActionType": "invoice",
                    "CurrencyCode": "USD"
                }
            },
            "CustomerRef": {
                "Name": invoice_info ? invoice_info.CustomerRef.name : null,
                "Value": invoice_info ? invoice_info.CustomerRef.value : null,
                "Email": invoice_info ? invoice_info.CustomerRef.Email : null
            }
        }
        
        //Ahsan...
        //Call server request here now and post data for payment request..
        this.postANetDataToEPayServer(payload);
        
        // document.getElementById("paymentForm").submit();
    };

    /** POST request to process opaque data request on server */
    postANetDataToEPayServer = (data) => {
            const url = BASE_URL + "api/v1/payment/sale/";
            this.invoice_token = sessionStorage.getItem('CP-KEY') || "3416a75f4cea9109507cacd8e2f2aefc" ;// || "a5771bce93e200c36f7cd9dfd0e5deaa";

            fetch(url,{
                method: "POST",
                headers: {'CP-KEY': "Token " + this.invoice_token,
                        'Content-Type': 'application/json'
                        },
                body: JSON.stringify({
                "InvoiceInfo": data['InvoiceInfo'],
                "SalesReceiptInfo": data['SalesReceiptInfo'],
                "PaymentInfo": data["PaymentInfo"],
                "CustomerRef": data["CustomerRef"],
                "AcceptPaymentInfo": data["AcceptPaymentInfo"]
                })          
            })
            .then(response => { 
                return response.json();     
            })
            .then(data => {
                if(data != null){
                    this.setState({SaleResponse:data.data});
                    this.setState({StatusCode : data.statuscode});
                    this.setState({StatusMessage : data.statusmessage});
                    this.setState({ShowProgressBar : false});
                    if(data.statuscode == 0)
                    {
                    this.setState({Customer : 
                    {
                        FormValid : false,
                        CardHolderNameValid: true,
                        AccountNameValid: true,
                        AccountNumberValid: true,
                        RoutingNumberValid: true,
                        CardNumberValid: true,
                        CardExpiryValid: true,
                        CardCVVValid: true,
                        AmountValid: true,
                        CheckBoxChecked: false,
                        CardHolderName: '',
                        CardNumber: '',
                        CardExpiry: '',
                        GatewayExpiryDate: '',
                        CardCVV: '',
                        AccountNumber: '',
                        RoutingNumber: '',
                        AccountName: '',
                        Address: '',
                        Country: '',
                        City: '',
                        State: '',
                        PostalCode: '',
                        FormErrors: {CardHolderName: '', AccountName: ''},
                        }
                    });                   
                    this.setState({Amount : parseFloat(global.InvoiceAmount - data.data.trans_amount).toFixed(2)});
                    this.setState({BalanceDue : parseFloat(global.InvoiceAmount - data.data.trans_amount).toFixed(2)}); 
                    }
                }
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ShowProgressBar : false}); 
            });
    };

    handleCancelPayment(e) {
        this.props.history.push('/invoice/invoices');
        e.preventDefault();
    };

    render() {
        return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    content={
                        <div>
                            <Row>
                                <Col md={7}>
                                <Card                                
                                            content={
                                                <div>
                                                <div style={{color:"white", backgroundColor:"#0075c1",marginLeft: "-15px", marginRight: "-15px", marginTop: "-15px"}}>
                                                <Row style={{ marginLeft: 0, marginRight: 0}}>
                                                <Col  xs={12} sm={12} md={7}>
                                                <h3><strong>Invoice # {this.state.invoiceNumber} </strong> </h3>
                                                </Col>
                                                <Col  xs={12} sm={12} md={2}>
                                                <h3><strong> Amount: </strong></h3>
                                                </Col>
                                                <Col style={{textAlign:"right"}} xs={12} sm={12} md={3}>
                                                <h3><strong>${parseFloat(this.state.amountToCharge).toFixed(2)}</strong></h3>
                                                </Col>
                                                </Row>
                                                </div>
                                                <br/>
                                                
                                                <Row>
                                                    <Col  xs={12} sm={12} md={3}>
                                                        <h5>Customer : </h5>                
                                                    </Col> <Col  xs={12} sm={12} md={9}>
                                                        <h5>{this.state.customerName}</h5>
                                                    </Col>
                                                 </Row>
                                                 <Row>
                                                     <Col  xs={12} sm={12} md={3}>
                                                        <h5>Email : </h5>
                                                    </Col> <Col  xs={12} sm={12} md={9}>
                                                        <h5>{this.state.customerEmail}</h5>
                                                    </Col>
                                                 </Row>                                                 
                                                 <Row>
                                                     <Col  xs={12} sm={12} md={3}>
                                                        <h5>Invoice Date : </h5>
                                                    </Col> <Col  xs={12} sm={12} md={9}>
                                                        <h5>{this.state.invoiceDate}</h5>
                                                    </Col>
                                                 </Row>
                                                 <Row>
                                                     <Col  xs={12} sm={12} md={3}>
                                                        <h5>Due Date : </h5>
                                                    </Col> <Col  xs={12} sm={12} md={9}>
                                                        <h5>{this.state.dueDate}</h5>
                                                    </Col>
                                                 </Row>
                                                 <br/>
                                                 <br/>
                                                 <Row>
                                                 <Col  xs={12} sm={12} md={5}>
                                                 </Col>
                                                     <Col  xs={12} sm={12} md={4}>
                                                        <h5>Total Amount : </h5>
                                                    </Col> <Col  style={{textAlign:"right", marginLeft:"-25px"}}  xs={12} sm={12} md={3}>
                                                        <h5>${parseFloat(this.state.totalAmount).toFixed(2)}</h5>
                                                    </Col>
                                                 </Row>
                                                 <Row>
                                                 <Col  xs={12} sm={12} md={5}>
                                                 </Col>
                                                     <Col  xs={12} sm={12} md={4}>
                                                        <h5>Amount to Charge :</h5>
                                                    </Col> <Col style={{textAlign:"right"}} xs={12} sm={12} md={3}>
                                                       <FormControl
                                                            value={parseFloat(this.state.amountToCharge).toFixed(2)}
                                                            type="number"
                                                            name="amountToCharge"
                                                            min="0.00"
                                                            style={{ textAlign: "right" }}
                                                            step="0.01"
                                                            onChange={this.handleAmountChange}
                                                        />
                                                    </Col>
                                                 </Row>
                                                 <Row>
                                                 <Col  xs={12} sm={12} md={5}>
                                                 </Col>
                                                     <Col  xs={12} sm={12} md={4}>
                                                        <h5>Remaining Balance :</h5>
                                                    </Col> <Col  style={{textAlign:"right", marginLeft:"-25px"}}  xs={12} sm={12} md={3}>
                                                        <h5>${parseFloat(this.state.remainingBalance).toFixed(2)}</h5>
                                                    </Col>
                                                 </Row>
                                                </div>
                                            }
                                            />
                                </Col>
                                <Col md={5}>
                                    {/* <Row>
                                        <Col md={3}>
                                            <img src={this.state.paymentIcon} width="70px" height="42px" alt="..." />
                                        </Col>
                                        <Col md={2}>
                                        </Col>
                                        <Col md={3}>
                                            <Radio
                                                number="creditcard"
                                                option="creditcard"
                                                name="radio"
                                                onChange={this.handleRadio}
                                                checked={this.state.IsCreditCard}
                                                className="PrivateSwitchBase-input-538"
                                                label="Credit Card"
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Radio
                                                number="ach"
                                                option="ach"
                                                name="radio"
                                                onChange={this.handleRadio}
                                                checked={this.state.IsACH}
                                                label="eCheck (ACH)"
                                            />
                                        </Col>                                      
                                    </Row>
                                      <br/> */}
                                     <Card
                                            content={
                                                <div>
                                    {this.state.IsCreditCard &&                                        
                                                <div>
                                                <Row>
                                                      <Col xs={12} sm={12} md={12}>
                                                       <InputLabel
                                                            htmlFor="simple-select"
                                                            style={styles.dropDownFontStyle}
                                                        >
                                                            Customer Profile
                                                        </InputLabel>
                                                        <Select
                                                            style = {styles.select}
                                                            value={this.state.customerProfile}
                                                            onChange={ (e) => this.handleUserInput(e)}
                                                            inputProps={{
                                                            name: "CustomerProfile",
                                                            id: "CustomerProfile",
                                                            }}                            
                                                        >
                                                            <MenuItem
                                                            style={styles.selectMenuItem}
                                                            value="none"
                                                            >
                                                            None
                                                            </MenuItem>                                                            
                                                            {                                     
                                                                this.state.customerProfiles.map((prop, key) => 
                                                                     ( prop.isACHProfile === false &&
                                                                    <MenuItem
                                                                        style={styles.selectMenuItem}
                                                                        value={prop.value}>
                                                                        {prop.label}
                                                                        </MenuItem>      
                                                                     ))
                                                            }                                                                                                                       
                                                        </Select>
                                                       </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <CustomInput
                                                                labelText="Name on Card"
                                                                isRequired={true}
                                                                id="CardHolderName"
                                                                name="CardHolderName"
                                                                value={this.state.Customer.CardHolderName}
                                                                showErrorMessage={!this.state.Customer.CardHolderNameValid}
                                                                errorMessage="Card holder name is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                    error: !this.state.Customer.CardHolderNameValid,
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    //onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px" } }} // font size of input text 
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <CreditCardInput
                                                                labelText="Card Number"
                                                                name="CardNumber"
                                                                id="CardNumber"
                                                                value={this.state.Customer.CardNumber}
                                                               // className={classes.cardTitle}
                                                                formControlProps={{//cardNumberInputProps={{
                                                                    error: !this.state.Customer.CardNumberValid,
                                                                  //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) },
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onError: err => console.log(`number error: ${err}`)
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px" } }} // font size of input text  
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                    <Col xs={12} sm={12} md={6}>
                                                    <ExpInput        
                                                        labelText="MM/YY"   
                                                        name = "CardExpiry"
                                                        value={this.state.Customer.CardExpiry}
                                                        fullWidth= {true}
                                                        formControlProps={{//cardExpiryInputProps={{
                                                            error: !this.state.Customer.CardExpiryValid,
                                                           // disabled: this.state.BalanceDue === "0.00",
                                                            onBlur: (e) => {this.handleOnBlurInput(e)},
                                                            onChange: (e) => {this.handleUserInput(e)},
                                                            onError: err => console.log(`expiry error: ${err}`)
                                                            }}  
                                                        inputProps={{style: {  fontFamily: "sans-serif", fontSize: "18px"}}}   
                                                        labelProps={{style: {fontWeight: "bold"}}}     
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6}>
                                                    <CVVInput 
                                                        labelText="CVV" 
                                                        name="CardCVV" 
                                                        value={this.state.Customer.CardCVV}
                                                        formControlProps={{
                                                        error: !this.state.Customer.CardCVVValid, 
                                                        //   disabled: this.state.BalanceDue === "0.00",
                                                            onBlur: (e) => {this.handleOnBlurInput(e)},
                                                            onChange: (e) => {this.handleUserInput(e)},
                                                            onError: err => console.log(`cvc error: ${err}`)
                                                            }}  
                                                        inputProps={{style: {  fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                        labelProps={{style: {fontWeight: "bold"}}}          
                                                    />
                                                    </Col>
                                                    </Row>
                                                </div>
                                    }
                                    {this.state.IsACH &&
                                                <div>
                                                    <Row>
                                                      <Col xs={12} sm={12} md={12}>
                                                       <InputLabel
                                                            htmlFor="simple-select"
                                                            style={styles.dropDownFontStyle}
                                                        >
                                                            Customer Profile
                                                        </InputLabel>
                                                        <Select
                                                            style = {styles.select}
                                                            value={this.state.customerProfile}
                                                            onChange={ (e) => this.handleUserInput(e)}
                                                            inputProps={{
                                                            name: "CustomerProfile",
                                                            id: "CustomerProfile",
                                                            }}                            
                                                        >
                                                            <MenuItem
                                                            style={styles.selectMenuItem}
                                                            value="none"
                                                            >
                                                            None
                                                            </MenuItem>                                                            
                                                            {                                     
                                                                this.state.customerProfiles.map((prop, key) => 
                                                                     ( prop.isACHProfile === true &&
                                                                    <MenuItem
                                                                        style={styles.selectMenuItem}
                                                                        value={prop.value}>
                                                                        {prop.label}
                                                                        </MenuItem>      
                                                                     ))
                                                            }                                                                                                                       
                                                        </Select>
                                                       </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col xs={12} sm={12} md={12}>
                                                       <CustomInput
                                                            labelText="Account Name"
                                                            isRequired = {true}
                                                            id="AccountName"
                                                            name="AccountName"
                                                            value={this.state.Customer.AccountName}
                                                            showErrorMessage={!this.state.Customer.AccountNameValid}
                                                            errorMessage="Account name is invalid"  
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                error: !this.state.Customer.AccountNameValid,
                                                                //disabled: this.state.BalanceDue === "0.00",
                                                                onChange:(e) => {this.handleUserInput(e)},
                                                                onKeyPress:(e) => {this.handleKeyPressInput(e)},
                                                                onBlur:(e) => {this.handleOnBlurInput(e)},
                                                            }}
                                                            inputProps={{style: {  fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                            labelProps={{style: {fontWeight: "bold"}}}
                                                            />
                                                       </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col xs={12} sm={12} md={12}>
                                                      <AccountNumberInput
                                                            labelText="Account Number"
                                                            isRequired = {true}
                                                            id="AccountNumber"
                                                            name="AccountNumber"
                                                            value={this.state.Customer.AccountNumber}
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                error: !this.state.Customer.AccountNumberValid,
                                                                disabled: this.state.BalanceDue === "0.00",
                                                                onBlur: (e) => {this.handleOnBlurInput(e)},
                                                                onChange:(e) => {this.handleUserInput(e)}
                                                            }}
                                                            inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                            labelProps={{style: {fontWeight: "bold"}}}
                                                            />
                                                       </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col xs={12} sm={12} md={12}>
                                                      <RoutingNumberInput
                                                        labelText="Routing Number"
                                                        isRequired = {true}
                                                        id="RoutingNumber"
                                                        name="RoutingNumber"
                                                        value={this.state.Customer.RoutingNumber}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            error: !this.state.Customer.RoutingNumberValid,
                                                           // disabled: this.state.BalanceDue === "0.00",
                                                            onChange:(e) => {this.handleUserInput(e)},
                                                            onBlur: (e) => {this.handleOnBlurInput(e)}
                                                        }}
                                                        inputProps={{style: {fontFamily: "sans-serif", fontSize: "18px"}}}  
                                                        labelProps={{style: {fontWeight: "bold"}}}
                                                        />
                                                      </Col>
                                                    </Row>                                                    
                                                    <Row>
                                                      <Col xs={12} sm={12} md={12}>
                                                       <InputLabel
                                                            htmlFor="simple-select"
                                                            style={styles.dropDownFontStyle}
                                                        >
                                                            Account Type
                                                        </InputLabel>
                                                        <Select
                                                            style = {styles.select}
                                                            value={this.state.Customer.AccountType}
                                                            onChange={ (e) => this.handleUserInput(e)}
                                                            inputProps={{
                                                            name: "AccountType",
                                                            id: "AccountType",
                                                            }}                            
                                                        >
                                                            <MenuItem
                                                            disabled
                                                            style={styles.selectMenuItem}
                                                            >
                                                            Select Account Type
                                                            </MenuItem>
                                                            <MenuItem
                                                            style={styles.selectMenuItem}
                                                            value="checking"
                                                            >
                                                            Checking
                                                            </MenuItem>
                                                            <MenuItem
                                                            style={styles.selectMenuItem}
                                                            value="savings"
                                                            >
                                                            Savings
                                                            </MenuItem>
                                                            <MenuItem
                                                             style={styles.selectMenuItem}
                                                            value="businessChecking"
                                                            >
                                                            Business Checking
                                                            </MenuItem>
                                                        </Select>
                                                       </Col>
                                                    </Row>
                                                </div>
                                    }
                                    <Row>
                                    <Col xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Address"
                                        isRequired = {true}
                                        id="address"
                                        name="Address"
                                        value={this.state.Customer.Address}
                                        formControlProps={{
                                            fullWidth: true,
                                           // disabled: this.state.BalanceDue === "0.00",
                                            onChange:(e) => {this.handleUserInput(e)}
                                        }}
                                        inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                        labelProps={{style: {fontWeight: "bold"}}}
                                        />   
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <CustomInput
                                                    labelText="Country"
                                                    id="country"
                                                    name="Country"
                                                    value={this.state.Customer.Country}
                                                    formControlProps={{
                                                    fullWidth: true,
                                                   // disabled: this.state.BalanceDue === "0.00",
                                                    onChange:(e) => {this.handleUserInput(e)}
                                                    }}
                                                    inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}}
                                                    labelProps={{style: {fontWeight: "bold"}}}
                                                />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                             <CustomInput
                                                labelText="State"
                                                id="state"
                                                name="State"
                                                value={this.state.Customer.State}
                                                formControlProps={{
                                                fullWidth: true,
                                               // disabled: this.state.BalanceDue === "0.00",
                                                onChange:(e) => {this.handleUserInput(e)}
                                                }}
                                                inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                labelProps={{style: {fontWeight: "bold"}}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="City"
                                                id="city"
                                                name="City"
                                                value={this.state.Customer.City}
                                                formControlProps={{
                                                fullWidth: true,
                                                disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                onChange:(e) => {this.handleUserInput(e)}
                                                }}
                                                inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                labelProps={{style: {fontWeight: "bold"}}} 
                                            />
                                            </Col>
                                             <Col xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    labelText="Postal Code"
                                                    id="postal-code"
                                                    name="PostalCode"
                                                    value={this.state.Customer.PostalCode}
                                                    formControlProps={{
                                                    fullWidth: true,
                                                    disabled: this.state.BalanceDue === "0.00",
                                                    onChange:(e) => {this.handleUserInput(e)}
                                                    }}
                                                    inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                    labelProps={{style: {fontWeight: "bold"}}} 
                                                />
                                            </Col>
                                            </Row>
                                            { this.state.IsCreditCard &&   
                                                <Row>
                                                    <Col xs={12} sm={12} md={12}>
                                                        <CustomCheckbox
                                                            name="CheckBox"
                                                            checked={this.state.Customer.CheckBoxChecked}
                                                            //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                            labelText={"I agree to pay ($" + parseFloat(this.state.amountToCharge).toFixed(2)+ ") according to the card issuer agreement."}
                                                            labelProps={{style: {fontSize: "15px"}}} 
                                                            formControlProps={{
                                                            onChange:(e) => {this.handleUserInput(e)}
                                                            }}  
                                                        />
                                            
                                                    </Col>
                                                </Row>
                                            }
                                
                                
                                     <Row>
                                     <br/>
                                         <input type="hidden" name="dataValue" id="dataValue" />
                                         <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
                                        <Col xs={12} sm={12} md={6}>    
                                        <Button bsStyle="primary" style={{width:"100%"}}  fill type="submit" onClick={this.handleCharged} disabled={!this.state.Customer.FormValid}>                                                   
                                                CHARGE 
                                              </Button> 
                                         </Col>
                                         <Col xs={12} sm={12} md={6}>    
                                        <Button style={{width:"100%"}} onClick={e => this.handleCancelPayment(e)}>                                                   
                                                Cancel 
                                              </Button> 
                                         </Col>
                                     </Row>
                                
                               </div>
                                            }
                                            
                                            />
                                </Col>
                                <Col md={5}>
                                <Row>
                                <Card
                                            content={
                                                <div>
                                    <NMICollectJSInlinePage></NMICollectJSInlinePage>
                                    </div>
                                            }
                                            
                                            />
                                </Row>
                                </Col>
                            </Row>

                        </div>
                    }
                     
                />
            </div>
        )
    };
}

export default AuthNetPayment;