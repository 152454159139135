import React from 'react';
import Card from "components/Card/Card.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import creditCardIcon from "assets/img/icons/creditcard.png";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import AmountInput from 'utils/Amount_Input.js';
import achIcon from "assets/img/icons/ach.png";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Alert    
} from "react-bootstrap";

const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
        color: "green",//successColor[2], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    errorResponse: {
        color: "red",//dangerColor[0], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    amountLabel: {
        // fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginTop: "20px",
        minHeight: "auto",
        marginBottom: "0px",
    },
}
  
class VirtualTerminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowProgressBar: false,
            errorCode: null,
            errorMessage: "",
            Transaction:{
                type: "",
                payment_token: "",
                ccnumber: "",
                ccexp: "",
                cvv: "",
                checkname: "",
                checkaba: "",
                checkaccount: "",
                account_holder_type: "", //Values: 'business' or 'personal'
                account_type: "",
                sec_code: "", //Values: 'PPD', 'WEB', 'TEL', or 'CCD'
                amount: 0.00,
                currency: "USD",
                payment: "", // Values: 'creditcard', 'check', or 'cash'
                dup_seconds: 0,
                order_description: "",
                orderid: "",
                ponumber: "",
                first_name: "",
                last_name: "",
                email: "",
                company: "",
                address1: "",
                city: "",
                state: "",
                zip: "",
                country: "",
                phone: "",
                fax: "",
                customer_receipt: false,
                process_type: "virtualterminal",
                customer_vault_id: ""               
            },
            FormErrors: { firstName: '', lastName: '', CardHolderName: '', AccountName: '' },
            amount: parseFloat(0).toFixed(2),
            amountErrorMessage: "",
            amountValid: true,
            firstNameValid: true,
            lastNameValid: true,
            emailErrorMessage: "",
            emailState: "",  
            paymentIcon: creditCardIcon,             
            IsCreditCard: true,
            IsACH: false,
            IsCreditCardEnabled: true,
            IsACHEnabled: true,
            isSubmitting: false,
            IsProfileSale: false,
            isValid: true
        }
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }        
        document.getElementById("loaderDiv").style.display = "none";
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        } else {
            const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
            if (document.getElementById(data_tokenize.PPToken)) {
                this.configureCollectJS();
            }
            else {
                this.loadNMIScriptTag(data_tokenize.PPToken);
            }
            let ach = (localStorage.getItem("is_ach_enabled") == "true")
            let creditcard = (localStorage.getItem("is_creditcard_enabled") == "true")
            this.setState({ IsACH: ach, IsACHEnabled: ach })
            this.setState({ IsCreditCard: creditcard, IsCreditCardEnabled: creditcard })
            if (ach && creditcard) {
                this.setState({ IsACH: false })
            }
        }
        this.state.customerData = this.props.location.state ? this.props.location.state.customerData : null;//JSON.parse(localStorage.getItem("invoicecustomer"));
        if (this.state.customerData !== null) { 
            var custData = this.state.customerData;
            this.setState(prevState => ({
                Transaction:
                {
                    ...prevState.Transaction, 
                    first_name: custData.firstname,
                    last_name: custData.lastname,
                    ccnumber: custData.payment == "CreditCard" ? custData.details : null,
                    customer_vault_id: custData.customer_vault_id,
                    ccexp: custData.ccexp,
                    checkname: custData.customer, 
                    checkaccount: custData.payment == "Check" ? custData.details : null,
                    email: custData.email,
                }
            }));            
            if(custData.payment == "Check")
            {
                this.setState({IsCreditCard: false });
                this.setState({IsACH: true });
            }else{
                this.setState({IsCreditCard: true });
                this.setState({IsACH: false }); 
            }
            this.setState({IsProfileSale: true });
        }

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 3000);
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handleCancelPayment(e) {
        this.props.history.push('/nmi/invoices');
        e.preventDefault();
    };

    handleRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        this.setState({ errorMessage: "" });
        if (target.value === "creditcard") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsACH: false });
            this.setState({ paymentIcon: creditCardIcon });
            this.setState(prevState => ({
                Transaction: {
                    ...prevState.Transaction,
                    payment: "creditcard"
                }
            }));
        }
        else {
            this.setState({ IsACH: true });
            this.setState({ IsCreditCard: false });
            this.setState({ paymentIcon: achIcon });            
            this.setState(prevState => ({
                Transaction: {
                    ...prevState.Transaction,
                    payment: "check"
                }
            }));            
        } 
        
        this.configureCollectJS();

        if (document.getElementById("creditCardErrorMessage")) {
            document.getElementById("creditCardErrorMessage").style.display = "none";
        }
        if (document.getElementById("expErrorMessage")) {
            document.getElementById("expErrorMessage").style.display = "none";
        }            
        if (document.getElementById("cvvErrorMessage")) {
            document.getElementById("cvvErrorMessage").style.display = "none";
        }
        if (document.getElementById("accountNameErrorMessage")) {
            document.getElementById("accountNameErrorMessage").style.display = "none";
        }
        if (document.getElementById("accountNumberErrorMessage")) {
            document.getElementById("accountNumberErrorMessage").style.display = "none";
        }
        if (document.getElementById("routingNumberErrorMessage")) {
            document.getElementById("routingNumberErrorMessage").style.display = "none";
        }
    };

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'first_name':
                this.setState(prevState => ({
                    Transaction:
                    {
                        ...prevState.Transaction, first_name: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case 'last_name':
                this.setState(prevState => ({
                    Transaction:
                    {
                        ...prevState.Transaction, last_name: value
                    }
                }), () => { this.validateField(name, value) })
                break;            
            case "amount":
                global.InvoiceAmount = value;
                this.setState(prevState => ({
                    amount:
                    {
                        ...prevState.Amount, Amount: value
                    }
                }), () => { this.validateField(name, value) })
                this.setState({
                    amountErrorMessage: ""
                });
                break;
            default:
                break;
        }
    }

    handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        //97-122, 32,65-90'
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };

    handleOnBlurInput(e) {
        this.validateField(e.target.name, e.target.value)        
    };

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.FormErrors;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let amountValid = this.state.amountValid;
        switch (fieldName) {
            case 'first_name':
                firstNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.firstName = firstNameValid ? '' : ' is invalid';
                this.state.firstNameValid = firstNameValid ? true : false;
                break;
            case 'last_name':
                lastNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.lastName = lastNameValid ? '' : ' is invalid';
                this.state.lastNameValid = lastNameValid ? true : false;
                break;
            case 'amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.amountValid = amountValid ? true : false;
                this.state.amount = value;
                break;
            default:
                break;
        }
        this.setState({
            FormErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            amountValid: amountValid
        });
    }

    handleSubmit = (event) => {
        this.setState({ StatusMessage: "" });
        this.setState({ShowProgressBar: true});
        var isFormValid = true;
        let amountValid = this.state.amountValid; 
        amountValid = this.state.amount.match(/^\d+(\.\d{1,9})?$/);        
        if(amountValid && this.state.amount > 0){
            this.state.amountValid = true;
        }
        else{
            this.state.amountValid = false;
            this.setState({
                amountErrorMessage: (
                    <small className="text-danger">
                        Amount is invalid.
                    </small>
                )
            });
            isFormValid = false;
        }

        if(this.state.IsProfileSale){
            event.preventDefault();
            if (!isFormValid) {                
                this.setState({ShowProgressBar: false});
                return false;
            }else{
                this.handleProfileCharged();
            }
        }
        else{
            this.Validate(null,null);            
            if (this.state.IsCreditCard) {
                if (document.getElementById("creditCardErrorMessage").innerHTML !== "") {
                    document.getElementById("creditCardErrorMessage").style.display = "block";
                    isFormValid = false;
                }
                if (document.getElementById("expErrorMessage").innerHTML !== "") {
                    document.getElementById("expErrorMessage").style.display = "block";
                    isFormValid = false;
                }
                if (document.getElementById("cvvErrorMessage").innerHTML !== "") {
                    document.getElementById("cvvErrorMessage").style.display = "block";
                    isFormValid = false;
                }
            }
            else if (this.state.IsACH) {
                if (document.getElementById("accountNameErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNameErrorMessage").style.display = "block";
                    isFormValid = false;
                }
                if (document.getElementById("accountNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNumberErrorMessage").style.display = "block";
                    isFormValid = false;
                }
                if (document.getElementById("routingNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("routingNumberErrorMessage").style.display = "block";
                    isFormValid = false;
                }
            }
            let fieldValidationErrors = this.state.FormErrors;
            let firstNameValid = this.state.firstNameValid;
            let lastNameValid = this.state.lastNameValid;
                   
            firstNameValid = this.state.Transaction.first_name.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
            fieldValidationErrors.firstName = firstNameValid ? '' : ' is invalid';
            if(firstNameValid){
                this.state.firstNameValid = true;
            }
            else{
                this.state.firstNameValid = false;
                isFormValid = false;
            }        
            lastNameValid = this.state.Transaction.last_name.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
            fieldValidationErrors.lastName = lastNameValid ? '' : ' is invalid';
            if(lastNameValid){
                this.state.lastNameValid = true;
            }
            else{
                this.state.lastNameValid = false;
                isFormValid = false;
            }        
             
            if (!isFormValid || !this.state.isValid) {
                event.preventDefault();
                this.setState({ShowProgressBar: false});
                return false;
            }
            else {
                this.setState({ isSubmitting: true });
                event.preventDefault();
                window.CollectJS.startPaymentRequest();
            }    
        }    
    }

    configureCollectJS() {
        try {
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                callback: (token) => {
                    this.finishSubmit(token);                     
                },
                validationCallback: function (field, status, message) {
                    if (status) {
                        var message = field + " is now OK 1 : " + message;
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "none";
                                document.getElementById("creditCardErrorMessage").innerHTML = "";
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "none";
                                document.getElementById("expErrorMessage").innerHTML = "";
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "none";
                                document.getElementById("cvvErrorMessage").innerHTML = "";
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "none";
                                document.getElementById("accountNameErrorMessage").innerHTML = "";
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "none";
                                document.getElementById("accountNumberErrorMessage").innerHTML = "";
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "none";
                                document.getElementById("routingNumberErrorMessage").innerHTML = "";
                                break;
                        }                        
                    } else {
                        var message = field + " is now Invalid : " + message + "  status=" + status;
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                                }
                                else {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Invalid card number";
                                }
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                                }
                                else {
                                    document.getElementById("expErrorMessage").innerHTML = "Invalid expiry date";
                                }
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                                }
                                else {
                                    document.getElementById("cvvErrorMessage").innerHTML = "Invalid CVV";
                                }
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                                }
                                else {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Invalid account name";
                                }
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                                }
                                else {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Invalid account number";
                                }
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                                }
                                else {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Invalid routing number";
                                }
                                break;
                        }
                    }
                },
                invalidCss: {
                    'border-bottom-color': 'red',
                    'border-width': '2px'
                },                
                customCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#AAAAAA',
                    'border-style': 'solid',
                    'border-width': '1px',
                    'font-family': 'sans-serif',
                    'font-size': '18px'
                },
                placeholderCss: {
                    "color": "#AAAAAA",
                    "font-weight": "bold",
                    "font-size": "16px"
                },
                focusCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#0075c1',
                    'border-width': '2px'
                },
                fields: {
                    ccnumber: {
                        selector: '#ccnumber',
                    },
                    ccexp: {
                        selector: '#ccexp'
                    },
                    cvv: {
                        selector: '#cvv',
                    },
                    checkaccount: {
                        selector: "#accountnumber",
                    },
                    checkaba: {
                        selector: "#routingnumber",
                    },
                    checkname: {
                        selector: "#accountname",
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    finishSubmit(response) {
        const { isSubmitting, ...formData } = { ...this.state };
        formData.token = response.token;      
        this.handleCharged(response);
        //post this form data to server api....
    }

    ValidateEmailAddress(event, name) {
        var value = event.target.value;
        this.setState(prevState => ({
            ...prevState,
            Transaction: {
                ...prevState.Transaction,
                email: value
            }
        }));
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            this.setState({
                emailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });            
            this.setState({
                emailState: "error"
            });                                              
        }
        else {
            this.state.emailState = "valid";
            this.state.emailErrorMessage = "";
        }

        if (this.state.emailState === "valid" || this.state.emailState === "") {
            this.setState({ isValid: true });
        }
        else {
            this.setState({ isValid: false });
        }
    }
    
    handleCharged = (value) => {
        const amount = this.state.amount;
        let transaction = this.state.Transaction; 
        transaction.amount = amount;
        transaction.type = "sale";
        if (this.state.IsCreditCard) {
            transaction.payment_token = value.token;
            transaction.ccnumber = value.card.number;
            transaction.ccexp = value.card.exp;
            transaction.cardtype =  value.card.type;
            transaction.payment = "creditcard";

        } else if (this.state.IsACH) {
            transaction.payment_token = value.token;
            transaction.checkname = value.check.name;
            transaction.checkaccount = value.check.account;
            transaction.checkaba = value.check.aba;
            transaction.account_type = "checking";
            transaction.payment = "check";              
        }       

        console.log(transaction);
        this._postCreateTransactionRequest(transaction);
    }

    handleProfileCharged(){
        const amount = this.state.amount;
        let transaction = this.state.Transaction; 
        transaction.amount = amount;
        transaction.type = "profile_sale";
        if (this.state.IsCreditCard) {                        
            transaction.payment = "creditcard";
        } else if (this.state.IsACH) {           
            transaction.account_type = "checking";
            transaction.payment = "check";              
        }
        console.log(transaction);
        this._postCreateTransactionRequest(transaction);
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    if (data.statuscode === 0) {
                        let processor = data.data.items;
                        this.setProcessorResult(processor);
                    }
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setProcessorResult = (processor) => {
        processor.map(data => {
            const configuration = data.configuration;
            if (configuration != null || configuration != "undefined") {
                const tokenizationKey = configuration.TokenizationKey;
                if (document.getElementById(tokenizationKey)) {
                    this.configureCollectJS();
                }
                else {
                    this.loadNMIScriptTag(tokenizationKey);
                }
            } else {
                this.setState({ StatusCode: 1 });
                this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Reseller and Support team..." });
            }
        });
    }

    loadNMIScriptTag(tokenizationKey) {
        let script = document.createElement("script");
        script.src = "https://secure.networkmerchants.com/token/Collect.js";
        script.async = true;
        script.setAttribute(
            "data-tokenization-key",
            tokenizationKey
        );
        script.setAttribute(
            "id",
            tokenizationKey
        );
        script.onload = () => {
            this.configureCollectJS();
        }
        document.head.appendChild(script);
    }

    Validate(event, name) {  
        // if (1 == 1 ) {
        //     this.setState({ isValid: true });
        // }
        // else {
        //     this.setState({ isValid: false });
        // }
    }

    _postCreateTransactionRequest(payload) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //set appid here...
        NetworkService.postVTPaymentRequest(app_id, payload)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data !== null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode === 0) {
                        this.props.history.push({
                            pathname: '/nmi/virtualterminal',
                            state: { errorMessage: data.statusmessage, errorCode: 0 }
                        });
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    render() {
        return (
            <div className="ii-main-content">
                <Card                   
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            {this.state.ShowProgressBar &&
                                <div>
                                    <CustomProgressBar></CustomProgressBar>
                                    <br/>
                                </div>
                            }  
                            <form onSubmit={this.handleSubmit}>                             
                                <Row>
                                    <Col md={7}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Company:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.company}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="Company"
                                                        name="company"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    company: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Customer Email: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.email}
                                                        placeholder="Email"
                                                        disabled={this.state.ShowProgressBar}
                                                        name="email"
                                                        type="email"
                                                        className={this.state.emailState}
                                                        onChange={event =>
                                                            this.ValidateEmailAddress(event, "email")
                                                        }
                                                        onBlur={event => this.Validate(event, "email")}
                                                    />
                                                    {this.state.emailErrorMessage}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Phone:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.phone}
                                                        type="text"
                                                        placeholder="Phone"
                                                        name="phone"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    phone: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>                                        
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Address:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.address1}
                                                        type="text"
                                                        placeholder="Address"
                                                        name="address1"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    address1: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        City:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.city}
                                                        type="text"
                                                        placeholder="City"
                                                        name="city"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    city: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        State:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.state}
                                                        type="text"
                                                        placeholder="State"
                                                        name="state"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    state: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>                                        
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Zip Code:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.zip}
                                                        type="text"
                                                        placeholder="Zip Code"
                                                        name="zip"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    zip: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Country:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.country}
                                                        type="text"
                                                        placeholder="Country"
                                                        name="country"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    country: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>  
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Order ID:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.orderid}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="Order ID"
                                                        name="orderid"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    orderid: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        PO Number:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.ponumber}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="PO Number"
                                                        name="ponumber"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    ponumber: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Order Description:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Transaction.order_description}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="Order Description"
                                                        name="order_description"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Transaction: {
                                                                    ...prevState.Transaction,
                                                                    order_description: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={5}>
                                        {this.state.IsCreditCardEnabled && this.state.IsACHEnabled &&
                                            <div>
                                                <Row>
                                                    <Col md={3}>
                                                        <img src={this.state.paymentIcon} width="70px" height="42px" alt="..." />
                                                    </Col>
                                                    <Col md={2}>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Radio
                                                            number="creditcard"
                                                            option="creditcard"
                                                            name="radio"
                                                            disabled={this.state.IsProfileSale}
                                                            onChange={this.handleRadio}
                                                            checked={this.state.IsCreditCard}
                                                            className="PrivateSwitchBase-input-538"
                                                            label="Credit Card"
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Radio
                                                            number="ach"
                                                            option="ach"
                                                            name="radio"
                                                            disabled={this.state.IsProfileSale}
                                                            onChange={this.handleRadio}
                                                            checked={this.state.IsACH}
                                                            label="eCheck (ACH)"
                                                        />
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>
                                        }
                                        <Card 
                                            content={
                                                <div>                                                
                                                    <Row>
                                                        <Col xs={5} sm={5} md={5}>
                                                            <h4 style={styles.amountLabel}>Amount ($):</h4>
                                                        </Col>
                                                        <Col xs={7} sm={7} md={7}>
                                                            <AmountInput
                                                                name="amount"
                                                                id="amount"
                                                                value={this.state.amount}                                                                
                                                                labelText=""  
                                                                formControlProps={{
                                                                    fullWidth: true,                                                                   
                                                                    error: !this.state.amountValid,
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) },
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontSize: 28, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11 } }} // font size of input text          
                                                                labelProps={{ style: { fontSize: 20, fontWeight: "bold", marginTop: 6 } }}
                                                            />
                                                            {this.state.amountErrorMessage}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                First Name
                                                            </InputLabel>
                                                            <CustomInput
                                                                isRequired={true}
                                                                id="first_name"
                                                                name="first_name"
                                                                value={this.state.Transaction.first_name}
                                                                showErrorMessage={!this.state.firstNameValid}
                                                                errorMessage="First name is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    error: !this.state.firstNameValid,
                                                                    disabled: false,
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                Last Name
                                                            </InputLabel>
                                                            <CustomInput
                                                                isRequired={true}
                                                                id="last_name"
                                                                name="last_name"
                                                                value={this.state.Transaction.last_name}
                                                                showErrorMessage={!this.state.lastNameValid}
                                                                errorMessage="Last name is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    error: !this.state.lastNameValid,
                                                                    disabled: false,
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />                                                            
                                                        </Col>
                                                    </Row>
                                                    {this.state.IsProfileSale && 
                                                        <div>
                                                            <div style={{ display: this.state.IsCreditCard ? "block" : "none" }}>                                                    
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} >
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Card Number
                                                                        </InputLabel>                                                                        
                                                                        <CustomInput
                                                                            id="ccnumber"
                                                                            name="ccnumber"
                                                                            value={this.state.Transaction.ccnumber}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: true
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        /> 
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={6}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            MM/YY
                                                                        </InputLabel>
                                                                        <CustomInput
                                                                            id="ccexp"
                                                                            name="ccexp"
                                                                            value={this.state.Transaction.ccexp}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: true
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        /> 
                                                                    </Col>
                                                                    <Col xs={12} sm={12} md={6}>
                                                                        <InputLabel htmlFor="simple-select" style={styles.dropDownFontStyle}>
                                                                            CVV
                                                                        </InputLabel>
                                                                        <CustomInput
                                                                            id="cvv"
                                                                            name="cvv"
                                                                            value="XXX"
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: true
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />   
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Account Name
                                                                        </InputLabel>
                                                                        <CustomInput
                                                                            id="accountname"
                                                                            name="accountname"
                                                                            value={this.state.Transaction.checkname}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: true
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />                                                                         
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Account Number
                                                                        </InputLabel>                                                                        
                                                                        <CustomInput
                                                                            id="accountnumber"
                                                                            name="accountnumber"
                                                                            value={this.state.Transaction.checkaccount}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: true
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />                                                                          
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Routing Number
                                                                        </InputLabel>
                                                                        <CustomInput
                                                                            id="routingnumber"
                                                                            name="routingnumber"
                                                                            value="xxxxxxxxx"
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: true
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />   
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!this.state.IsProfileSale && 
                                                        <div>
                                                             <div style={{ display: this.state.IsCreditCard ? "block" : "none" }}>                                                    
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} >
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Card Number
                                                                        </InputLabel>
                                                                        <div id="ccnumber" />
                                                                        <div style={{ display: "none", color: "red", fontSize: "12px" }} name="creditCardErrorMessage" id="creditCardErrorMessage">Card number is required
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={6}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            MM/YY
                                                                        </InputLabel>
                                                                        <div id="ccexp" />
                                                                        <div style={{ display: "none", color: "red", fontSize: "12px" }} name="expErrorMessage" id="expErrorMessage">Expiry date is required
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} sm={12} md={6}>
                                                                        <InputLabel htmlFor="simple-select" style={styles.dropDownFontStyle}>
                                                                            CVV
                                                                        </InputLabel>
                                                                        <div id="cvv" />
                                                                        <div style={{ display: "none", color: "red", fontSize: "12px" }} name="cvvErrorMessage" id="cvvErrorMessage">CVV date is required
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Account Name
                                                                        </InputLabel>
                                                                        <div id="accountname" />
                                                                        <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNameErrorMessage" id="accountNameErrorMessage">Account name is required
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Account Number
                                                                        </InputLabel>
                                                                        <div id="accountnumber" />
                                                                        <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNumberErrorMessage" id="accountNumberErrorMessage">Account number is required
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                                            Routing Number
                                                                        </InputLabel>
                                                                        <div id="routingnumber" />
                                                                        <div style={{ display: "none", color: "red", fontSize: "12px" }} name="routingNumberErrorMessage" id="routingNumberErrorMessage">Routing number is required
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>                                                 
                                                        </div>
                                                    }
                                                   </div>
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div style={{ textAlign: "right", marginBottom: "20px" }}>
                                            <br />                                       
                                            <Button bsStyle="primary" style={{ width: "15%", marginRight: 20 }} wd onClick={e => this.handleCancelPayment(e)}>Cancel</Button>
                                            <Button bsStyle="primary" style={{ width: "15%" }} fill type="submit">Charge</Button>
                                        </div>
                                    </Col>
                                </Row>  
                            </form>                         
                        </div>
                    }
                />
            </div>
        )
    }
}

export default VirtualTerminal;      