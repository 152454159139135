/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Invoices from "views/NMI/Invoices.jsx";
import InvoiceByUser from "views/NMI/InvoiceByUser.jsx";
import CreateInvoice from "views/NMI/CreateInvoice.jsx";
import VirtualTerminal from "views/NMI/VirtualTerminal.jsx";
import Payments from "views/NMI/Payments.jsx";
import TransactionReport from "views/NMI/TransactionReport.jsx";
import Products from "views/NMI/Products.jsx";
import NMIPayment from "views/NMI/NMIPayment.jsx";
import Customers from "views/NMI/Customers.jsx";
import Plans from "views/NMI/Plans";
import Subscriptions from "views/NMI/Subscriptions";
import CreateSubscription from "views/NMI/CreateSubscription";
import AccountingPackage from "views/NMI/AccountingPackage.jsx";
import Settings from "views/NMI/Settings.jsx";
import invoiceDetail from "views/NMI/Detail.jsx";

var NMIRoutes = [  
  {
    path: "/invoices",
    layout: "/nmi",
    name: "Invoices",
    icon: "fa fa-newspaper-o",
    component: Invoices
  },     
  {
    path: "/createinvoice",    
    layout: "/nmi",
    name: "Create Invoice",
    icon: "fa fa-file-text-o",
    component: CreateInvoice,    
  },  
  // {
  //   path: "/virtualterminal",    
  //   layout: "/nmi",
  //   name: "Virtual Terminal",
  //   icon: "fa fa-usd",
  //   component: VirtualTerminal,    
  // }, 
  // {
  //   collapse: true,
  //   path: "/recurring",
  //   name: "Recurring",
  //   icon: "fa fa-refresh",
  //   views: [
  //     {
  //       path: "/subscriptions",
  //       layout: "/nmi",
  //       name: "Subscriptions",
  //       icon: "fa fa-calendar",
  //       component: Subscriptions
  //     },
  //     {
  //       path: "/plans",
  //       layout: "/nmi",
  //       name: "Plans",
  //       icon: "fa fa-life-ring",
  //       component: Plans
  //     }
  //   ]
  // },    
  {
    path: "/products",
    layout: "/nmi",
    name: "Products/Items",
    icon: "fa fa-gift",
    component: Products
  },
  {
    path: "/customers",
    layout: "/nmi",
    name: "Customers",
    icon: "fa fa-users",
    component: Customers
  },
  {
    path: "/nmipayment",    
    name: "payment",
    component: NMIPayment,
    layout: "/nmi"
  },
  {
    path: "/detail",
    name: "invoice detail",
    component: invoiceDetail,
    layout: "/nmi"
  },  
  {
    path: "/createsubscription",    
    layout: "/nmi",
    name: "Create Subscription",
    icon: "fa fa-file-text-o",
    component: CreateSubscription,    
  },
  {
    path: "/payments",
    layout: "/nmi",
    name: "Payments",
    icon: "fa fa-money",
    component: Payments
  },
  {
    path: "/invoicebyuser",
    layout: "/nmi",
    name: "Invoices By User",
    icon: "fa fa-newspaper-o",
    component: InvoiceByUser
  },
  {
    path: "/transactionreport",
    layout: "/nmi",
    name: "Transaction Report",
    icon: "fa fa-bar-chart",
    component: TransactionReport
  },  
  {
      path: "/settings",
      layout: "/nmi",
      name: "Settings",
      icon: "fa fa-gears",
      component: Settings
    },
  {
    path: "/accountingpackage",
    layout: "/nmi",
    name: "accounting package",
    icon: "fa fa-cubes",
    component: AccountingPackage
  }
];

export default NMIRoutes;
