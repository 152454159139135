import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
    FormControl,

} from "react-bootstrap";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from "react-select";
import Datetime from "react-datetime";
import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Chip from '@material-ui/core/Chip';
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import NetworkService from "services/NetworkService";
import BatchEmailModule from 'views/Components/BatchEmailModule.jsx';
import SessionTimeout from 'components/SessionTimeout';
import Common from "utils/Common.js";
// import ReactToPrint from 'react-to-print';

class Invoices extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            invoiceStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                { value: "all", label: "All" },
                { value: "due", label: "Due" },
                { value: "overdue", label: "Overdue" },
                { value: "paid", label: "Paid" },
            ],
            filter_invoice: { value: "all", label: "All" },
            //filter_invoice: "all",
            queryFromDate: "",
            queryToDate: "",            
            showActionButtons: "none",
            showUpdateInvoiceButton: "none",
            showCreateInvoice: "",
            showInvoiceSendButton: "none",
            showCreatePaymentLinkButton: "none",
            showTextSMSInvoiceButton: "none",
            showPrintButton: "none",
            showBatchEmail: "none",
            showSendInvoiceModal: false,
            showCreatePaymentLinkInvoiceModal: false,
            showInvoiceUpdateModal: false,
            showTextSMSInvoiceModal: false,
            errorSendInvoiceEmail: "",
            phoneNumberErrorMessage: "",
            sendInvoiceClassName: "",
            phoneNumberState: "",
            customerEmail: "john112233@mailinator.com",
            rawInvoiceData: [],
            selectedInvoiceIds: "",
            data: [
                // { invoicenumber: '654541', customername: 'Can dynamically add', invoicedate: '2020-01-01', duedate: '2020-12-05', balance: '$633.21', total: '$633.21', invoicestatus: 'Paid', emailstatus: 'not sent' },
            ],
            columns: [                
                {                    
                    title: 'Invoice #',
                    field: 'invoicenumber',
                    headerStyle: {
                        textAlign: "left",
                        width: "calc(10%)",
                    },
                    cellStyle: {
                        width: "calc(10%)",
                    },
                    customFilterAndSearch: (term, rowData) => {    
                        if(rowData.invoicenumber.props.children.props.children != null)
                        {
                            return (rowData.invoicenumber.props.children.props.children.toLowerCase() : -1).indexOf(term) !== -1;     
                        }
                    }
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(30%)'
                    },
                    cellStyle: {
                        width: 'calc(30%)'
                    },
                },
                {
                    title: 'Invoice Date',
                    field: 'invoicedate',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "center",
                    }
                },
                {
                    title: 'Due Date',
                    field: 'duedate',
                    headerStyle: {
                        textAlign: "right"
                    },
                    cellStyle: {
                        textAlign: "center",
                    }
                },
                {
                    title: 'Balance',
                    field: 'balance',
                    cellStyle: {
                        textAlign: "center",
                    }
                },
                {
                    title: 'Total',
                    field: 'total',
                    cellStyle: {
                        textAlign: "center",
                    }
                },
                {
                    title: 'Invoice Status',
                    field: 'invoicestatus',
                    sortable: false,
                    cellStyle: {
                        textAlign: "center",
                    },
                   // customFilterAndSearch: (term, rowData) => (rowData.invoicestatus.props.label.toLowerCase()).indexOf(term) != -1
                },
                {
                    title: 'Email Status',
                    field: 'emailstatus',
                    sortable: false,
                    cellStyle: {
                        textAlign: "center",
                    },
                    customFilterAndSearch: (term, rowData) => (rowData.emailstatus.props.label.toLowerCase()).indexOf(term) != -1
                }
            ],
            options: {
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                selectionProps: rowData => ({
                    color: 'primary',
                }),
                showTitle: false,
                actionsColumnIndex: -1,
                loadingType: "overlay",
                rowStyle: rowData => ({
                    // backgroundColor: "#ffffff",
                    // textAlign: "right",
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"                   
                }),
            },
            printInvoiceToolTip: <Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>,
            batchEmailInvoiceToolTip: <Tooltip id="batchEmailInvoiceToolTip">Batch Email</Tooltip>,
            sendInvoiceToolTip: <Tooltip id="sendInvoiceToolTip">Email Invoice</Tooltip>,
            createPaymentLinkInvoiceToolTip: <Tooltip id="createPaymentLinkInvoiceToolTip">Create Payment Link</Tooltip>,            
            textInvoiceToolTip: <Tooltip id="smsInvoiceToolTip">Text/SMS Invoice</Tooltip>,
            collectPaymnetToolTip: <Tooltip id="collectPaymnetToolTip">Collect Payment</Tooltip>,
            updateInvoiceInQuickBooksToolTip: <Tooltip id="collectPaymnetToolTip">Update Invoice In QuickBooks</Tooltip>,
            invoiceLinkToolTip: <Tooltip id="invoiceLinkToolTip">Invoice Link</Tooltip>,
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />

                        <div style={{ padding: '10px' }}>
                            {/* <span className="react-tagsinput-tag tag-fill tag-red">Hi</span> */}
                            <OverlayTrigger placement="top" overlay={<Tooltip id="ttCreateInvoice">Create Invoice</Tooltip>}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateInvoice(e)} style={{ marginRight: '10px',  display: this.state.showCreateInvoice }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="ttEditInvoice">Edit Invoice</Tooltip>}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleEditInvoice(e)} style={{ marginRight: '15px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-edit" ></i> </Button>
                            </OverlayTrigger>

                            {/* <ReactToPrint
                                trigger={() =>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '10px', display: this.state.showPrintButton }}> <i className="fa fa-print" ></i> </Button>
                                    </OverlayTrigger>
                                }
                                content={() => this.componentRef}
                            /> */}
                            {/* <div style={{ display: "none" }}> <ComponentToPrint ref={el => (this.componentRef = el)} dataFromParent={this.state.selectedInvoiceIds} /> </div> */}
                            <OverlayTrigger placement="top" overlay={this.state.batchEmailInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleBatchEmailClick(e)}  style={{ marginRight: '10px', display: this.state.showBatchEmail }}> <i className="fa fa-send" ></i> </Button>
                            </OverlayTrigger>


                            <OverlayTrigger placement="top" overlay={this.state.sendInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleSendInvoice(e)} style={{ marginRight: '10px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-envelope" ></i> </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={this.state.textInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleTextSMSInvoice(e)} style={{ marginRight: '10px', display: this.state.showTextSMSInvoiceButton }}> <i className="fa fa-comments" ></i> </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={this.state.createPaymentLinkInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreatePaymentLinkPopup(e)} style={{ marginRight: '10px', display: this.state.showCreatePaymentLinkButton }}> <i className="fa fa-link" ></i> </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={this.state.collectPaymnetToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePaymentInvoice(e)} style={{ marginRight: '10px', display: this.state.showActionButtons }}> <i className="fa fa-credit-card" ></i> </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={this.state.updateInvoiceInQuickBooksToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleUpdateInvoiceInQuickBooks(e)} style={{ marginRight: '10px', display: this.state.showUpdateInvoiceButton }}> <i className="fa fa-check" ></i> </Button>
                            </OverlayTrigger>
                            
                            {/* <OverlayTrigger placement="top" overlay={this.state.invoiceLinkToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '10px', display: this.state.showActionButtons }}> <i className="fa fa-link" ></i> </Button>
                            </OverlayTrigger> */}
                        </div>
                    </div>
                ),
                // Action: props => (
                //     <Button
                //         onClick={(event) => props.action.onClick(event, props.data)}>
                //         My Button
                //     </Button>
                // ),
            },
            actions: [
                {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => alert("You saved " + rowData.name)
                }
            ],
            singleEmailInvoiceData: {
                customerName: "",
                customerEmail: "",
                invoiceNumber: "",
                balance: "",
                index: "",
                customerPhoneNumber: "",
                defaultPhoneNumberCheckBox: true,
                customerID: "",
            },
            selectedInvoiceIndex: [],
            Permissions: null,
            showBatchEmailModal: false,
            BatchEmailInvoices: [],
            proceesorSettingError: "",
            isAuthenticated: true,
            isInvoiceLinkCreated: false,
            invoicePaymentLink: "",
            popupErrorMessage: ""

        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }
    
    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }

        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        }
        
        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        let invoiceStatusFilter = this.props.location.state ? this.props.location.state.invoice_status ? this.props.location.state.invoice_status : "all" : "all";
        this.state.filter_invoice = { value: invoiceStatusFilter, label: invoiceStatusFilter[0].toUpperCase() + invoiceStatusFilter.slice(1) }
        this.state.Permissions = JSON.parse(localStorage.getItem(StorageEnum.PERMISSIONS));        
        if(this.state.Permissions != null && this.state.Permissions != undefined && this.state.Permissions["invoices"] != undefined){
            if(this.state.Permissions["invoices"][0]["createinvoice"] == false){
                this.setState({ showCreateInvoice: "none" });
            }
        }

        document.getElementById("loaderDiv").style.display = "none";
        if( this.props.location.state && this.props.location.state.queryFromDate && this.props.location.state.queryFromDate != "")
        {
            this.state.queryFromDate =this.props.location.state.queryFromDate;
            this.state.queryToDate =this.props.location.state.queryToDate;
            this.setState({ from_date: Common.formatDate(this.props.location.state.queryFromDate)  });
            this.setState({ to_date: Common.formatDate(this.props.location.state.queryToDate)  });
            if(this.props.location.state.invoices != undefined && this.props.location.state.rawInvoiceData != undefined ){
                this.setState({ data: this.props.location.state.invoices });
                this.setState({ rawInvoiceData: this.props.location.state.rawInvoiceData });
            }
            else{
                this.filterInvoice();
            }
        }
        else{
            this.SetFromToDates();
            this.filterInvoice();

            if (this.state.errorMessage !== "") {
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            }
        }        

        var data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        if (data_tokenize == null || data_tokenize == undefined) {
            this.setState({proceesorSettingError: Common.getProcessorSettingsErrorMessage()})   
        }

    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }
    /** HANDLER */

    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        //console.log("from : " + this.state.queryFromDate + "  to : " + this.state.queryToDate);

        this.filterInvoice();
    }

    handleCreateInvoice(e) {
        this.props.history.push('/invoice/create');
        e.preventDefault();
    }

    handlePaymentInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
        });
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        if (data_tokenize.PPType.toLowerCase() === "cardconnect") {
            this.props.history.push({
                pathname: '/invoice/ccpayment',
                state: { invoiceData: invoice }
            });
        }
        else if (data_tokenize.PPType.toLowerCase() === "nmi") {
            this.props.history.push({
                pathname: '/invoice/nmipayment',
                state: { invoiceData: invoice }
            });
        }
    }

    handleSendInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const x = {
                customerName: invoice.CustomerRef.name,
                customerEmail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            if (x.customerEmail != "") {
                this.setState({ sendInvoiceClassName: "valid" });
            }
            else {
                this.setState({ sendInvoiceClassName: "" });
            }
            this.setState({ errorSendInvoiceEmail: "" });
            this.setState({ singleEmailInvoiceData: x })
        })
        this.setState({ showSendInvoiceModal: true });
    }

    handleCreatePaymentLinkPopup(e){
        e.preventDefault();
        this.setState({ isInvoiceLinkCreated: false });
        this.setState({ popupErrorMessage: "" });
        this.setState({ invoicePaymentLink: "" });  
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const data = {
                customerId: invoice.CustomerRef.value,
                customerName: invoice.CustomerRef.name,
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            
            this.setState({ singleEmailInvoiceData: data })
        })
        this.setState({ showCreatePaymentLinkInvoiceModal: true });
    }

    handleUpdateInvoiceInQuickBooks(e){
        e.preventDefault();      
        this.setState({ popupErrorMessage: "" });
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            invoice = this.state.rawInvoiceData[index];
            const data = {
                customerId: invoice.CustomerRef.value,
                customerName: invoice.CustomerRef.name,
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            
            this.setState({ singleEmailInvoiceData: data })
        })
        this.setState({ showInvoiceUpdateModal: true });
    }

    handleBatchEmailClick(e) {       
       this.setState({ showBatchEmailModal: true });       
    }

    batchEmailCallbackFunction = (childData) => {
        this.setState({ showBatchEmailModal: childData })
    }

    handleTextSMSInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            console.log(invoice);
            const x = {
                customerName: invoice.CustomerRef.name,
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
                defaultPhoneNumberCheckBox: true
            }
            if (x.customerPhoneNumber != "") {
                this.setState({ phoneNumberState: "valid" });
            }
            else {
                this.setState({ phoneNumberState: "" });
            }
            this.setState({ phoneNumberErrorMessage: "" });
            this.setState({ singleEmailInvoiceData: x })
        })
        this.setState({ showTextSMSInvoiceModal: true });
    }

    filterInvoice = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
            "status": this.state.filter_invoice.value
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this.setState({ showBatchEmail: "none" });
        this.getInvoiceDetailsRequest(query);
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleKeyPressInput(e) {
        var charCode = (e.which) ? e.which : e.keyCode;
        if (charCode === 13 && this.state.sendInvoiceClassName === "valid") {
            this.handleSendInvoiceEmail(e);
        }
    };

    handleTextSMSKeyPressInput(e) {
        var charCode = (e.which) ? e.which : e.keyCode;
        if (charCode === 13 && this.state.phoneNumberState === "valid") {
            this.handleTextSMSInvoiceRequest(e);
        }
    };


    handleSendInvoiceEmail(e) {
        if (this.state.sendInvoiceClassName === "valid") {
            e.preventDefault();
            let invoice = [];
            this.state.selectedInvoiceIndex.map(index => {
                invoice.push(this.state.rawInvoiceData[index]);
            })
            const customer_email = this.state.singleEmailInvoiceData.customerEmail
            const payload = {
                "ToEmails": [customer_email],
                "Invoices": invoice
            }
            this.postEmailInvoiceRequest(payload);
        }
    }

    handleCreatePaymentLinkInvoice(e){
        let invoice = null;
            this.state.selectedInvoiceIndex.map(index => {
                invoice = this.state.rawInvoiceData[index];
            })   
        const payload = {               
                "customer_id": invoice.CustomerRef.value,
                "customer_name": invoice.CustomerRef.name || null,
                "invoice_id": invoice.Id,                
                "invoice_number": invoice.DocNumber || null,
            }
            this._postCreatePaymentLinkInvoiceRequest(payload);
    }

    handleTextSMSInvoiceRequest(e) {
        if (this.state.singleEmailInvoiceData.defaultPhoneNumberCheckBox ||
            (this.state.singleEmailInvoiceData.customerPhoneNumber && this.state.phoneNumberState === "valid")) {
            e.preventDefault();
            let invoice = null;
            this.state.selectedInvoiceIndex.map(index => {
                invoice = this.state.rawInvoiceData[index];
            })           
            let customer_phone = null;
            if(!this.state.singleEmailInvoiceData.defaultPhoneNumberCheckBox){
                customer_phone = "+1" + this.state.singleEmailInvoiceData.customerPhoneNumber;
            }
            const payload = {
                "To":customer_phone,
                "customer_id": invoice.CustomerRef.value,
                "customer_name": invoice.CustomerRef.name || null,
                "invoice_id": invoice.Id,
                "due_date": invoice.DueDate,
                "doc_number": invoice.DocNumber || null,
                "amount": invoice.Balance,
                "message": null,
                "phone_number": customer_phone
            }
            this._postTextSMSInvoiceRequest(payload);
        }
        else if (this.state.phoneNumberState === "") {
            this.setState({
                phoneNumberErrorMessage: (
                    <small className="text-danger">
                        Customer Phone Number is required.
                    </small>
                )
            });
        }
        else {
            this.setState({
                phoneNumberErrorMessage: (
                    <small className="text-danger">
                        Invalid Phone Number, must be greater than or equal to 7 digits.
                    </small>
                )
            });
        }
    }

    handleInvoiceUpdate(e) {
        let invoice = [];
            this.state.selectedInvoiceIndex.map(index => {
                invoice.push(this.state.rawInvoiceData[index]);
            })
            const payload = {
                "Invoice": invoice
            }
            this.postUpdateInvoiceRequest(payload);
    }

    postUpdateInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const url = BASE_URL + "api/v1/invoice/paidinvoice/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.QuickBooksOnline;

        fetch(url, {
            method: "POST",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "Invoice": data["Invoice"]
            })
        }).then(response => {
            return response.json()
        }).then(data => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showInvoiceUpdateModal: false });
            if (data != null && (data.statuscode == 0 || data.statuscode == "0")) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            } else {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            }
        }).catch(error => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendInvoiceModal: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            singleEmailInvoiceData: {
                ...prevState.singleEmailInvoiceData,
                customerPhoneNumber: value
            }
        }));

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            singleEmailInvoiceData: {
                ...prevState.singleEmailInvoiceData,
                customerEmail: value
            }
        }));
        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        ///^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendInvoiceEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )
            });
            this.setState({
                sendInvoiceClassName: "error"
            });

        }
        else {
            this.setState({ errorSendInvoiceEmail: null });
            this.setState({ sendInvoiceClassName: "valid" });
        }
    }

    onPhoneNumberBlur() {
        if (this.state.phoneNumberState === "" || this.state.phoneNumberState === "error") {
            if (this.state.singleEmailInvoiceData.customerPhoneNumber == "") {
                this.setState({
                    phoneNumberErrorMessage: (
                        <small className="text-danger">
                            Customer Phone Number is required.
                        </small>
                    )
                });
            } else {
                this.setState({
                    phoneNumberErrorMessage: (
                        <small className="text-danger">
                            Invalid Phone Number, must be greater than or equal to 7 digits.
                        </small>
                    )
                });
            }

        }
        else {
            this.setState({ phoneNumberState: "valid" });
            this.setState({ phoneNumberErrorMessage: null });
        }
    }

    handlePhoneNumberCheckBox(e) {
        var cbValue = e.target.checked;
        this.setState(prevState => ({
            singleEmailInvoiceData: {
                ...prevState.singleEmailInvoiceData,
                defaultPhoneNumberCheckBox: cbValue,
                customerPhoneNumber: ""
            }
        }));

        if (cbValue) {
            this.setState({ phoneNumberState: "valid" });
        }
        else {
            this.setState({ phoneNumberState: "" });
        }
         this.setState({ phoneNumberErrorMessage: "" });
    }

    handleInvoiceDetailClick(invoice) {
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        //this.props.history.push('/invoice/detail/' + invoice.Id);
        this.props.history.push({
                pathname: '/invoice/detail/' + invoice.Id,
                state: { queryFromDate: this.state.queryFromDate, queryToDate: this.state.queryToDate, invoices: this.state.data, rawInvoiceData: this.state.rawInvoiceData, statusFilter: this.state.filter_invoice.value}
            });
    }

    handleEditInvoice(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];            
        })

        this.props.history.push('/invoice/edit/' + invoice.Id);
       // e.preventDefault();
    }

    handleSelectedRowData(data, length) {
        let newStates = [];
        let invoiceIds = "";
        this.state.BatchEmailInvoices = [];
        data.map(d => {
            newStates.push(d.index);
            invoiceIds = invoiceIds + "," + d.id;
            let invoice = this.state.rawInvoiceData[d.index];
            if(invoice.IsPaid === false && invoice.BillEmail != null && invoice.BillEmail.Address != null && invoice.BillEmail.Address != ""){
                this.state.BatchEmailInvoices.push(invoice)
            }
        });
         
        // this.setState({ selectedInvoiceIndex: newStates });
        this.state.selectedInvoiceIndex = newStates;
        this.state.selectedInvoiceIds = this.replaceAt(invoiceIds, 0, '');
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = ApplicationIDEnum.QuickBooksOnline;
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    if (data.statuscode === 0) {
                        let processor = data.data.items;
                        this.setProcessorResult(processor);
                    }
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setProcessorResult = (processors) => {
        let tokenize_key = null;
        let processor = null;
        processors.map(data => {
            if (data.is_default == true) {
                processor = data;
            }
        });

        if (processor != null) {
            if (processor.method_type == "nmi") {
                tokenize_key = JSON.stringify({ "PPType": "nmi", "PPToken": processor.TokenizationKey })
            } else if (processor.method_type == "cardconnect") {
                tokenize_key = JSON.stringify({ "PPType": "cardconnect", "PPToken": processor.configuration.URL })
            }
        }
        localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
    }

    /** API CALLING */

    // GET request for invoice details
    getInvoiceDetailsRequest(params = "") {
        this.setState({ ShowProgressBar: true });

        const url = BASE_URL + "api/v1/invoice/filter/?" + params;
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.QuickBooksOnline;

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.onSetResult(data.data);
                this.setState({ ShowProgressBar: false });
            } else {
                this.setState({ ShowProgressBar: false });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });


    }

    onSetResult = (result) => {
        const newSeries = [];
        // const button = <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
        //     <Button className="btn btn-primary btn-fill btn-round btn-icon"> <i className="fa fa-mail-forward" ></i> </Button>
        // </OverlayTrigger>

        result.items.map((invoice, index) => {
            //irfan remove this line
           // invoice.IsPaymentCharged = true;
            const data = {
                index: index,
                id: invoice.Id,
                invoiceNo: invoice.DocNumber,
                invoicenumber:
                    <OverlayTrigger placement="top" overlay={<Tooltip id="invoiceDetailToolTip">View Invoice Detail</Tooltip>}>
                        <a className="invoicelink" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}>
                            {invoice.DocNumber}
                        </a>
                    </OverlayTrigger>,
                customername: invoice.CustomerRef.name,
                customeremail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoicedate: Common.formatDate(invoice.TxnDate), 
                duedate: Common.formatDate(invoice.DueDate),
                balance: '$' + parseFloat(invoice.Balance).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
                total: '$' + parseFloat(invoice.TotalAmt).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
                invoicestatus: invoice.IsPaymentCharged && invoice.IsPaid == false && invoice.total == invoice.Balance ? <OverlayTrigger placement="top" overlay={<Tooltip id="invoiceDetailToolTip20">This invoice is paid, but not updated in QuickBooks, please select it and click on the Update Invoice button.</Tooltip>}>                
                    <Chip style={{ backgroundColor: "#ff6a00", color: "white", height: 25 }} label="Paid But Not Updated"></Chip>
                </OverlayTrigger>: invoice.IsPaid ? <Chip style={{ backgroundColor: "#25D366", color: "white", height: 25 }} label="Paid"></Chip> : parseFloat(invoice.Balance).toFixed(2) < parseFloat(invoice.TotalAmt).toFixed(2) ? <Chip style={{ backgroundColor: "#feb019", color: "white", height: 25 }} label="Partially Paid"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Unpaid"></Chip>,
                // action: <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                //     <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}> <i className="fa fa-clipboard" ></i> </Button>
                // </OverlayTrigger>,
                emailstatus: (invoice.SendGridEvent === "not sent") ? <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label={"Not Sent"}></Chip>
                    : (invoice.SendGridEvent === "read") ? <Chip style={{ backgroundColor: "#25D366", color: "white", height: 25 }} label={"Read"}></Chip>
                        : (invoice.SendGridEvent === "delivered") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"Delivered"}></Chip>
                            : (invoice.SendGridEvent === "email sent") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"Sent"}></Chip>
                                : (invoice.SendGridEvent === "click") ? <Chip style={{ backgroundColor: "#05C8FF", color: "white", height: 25 }} label={"Clicked"}></Chip>
                                    : (invoice.SendGridEvent === "processed") ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={"Processed"}></Chip>
                                        : (invoice.SendGridEvent === "open") ? <Chip style={{ backgroundColor: "#775DFF", color: "white", height: 25 }} label={"Opened"}></Chip>
                                            : (invoice.SendGridEvent === "spamreport") ? <Chip style={{ backgroundColor: "#FF88FB", color: "white", height: 25 }} label={"Spam Report"}></Chip>
                                                : (invoice.SendGridEvent === "bounce") ? <Chip style={{ backgroundColor: "#ffa534", color: "white", height: 25 }} label={"Bounced"}></Chip>
                                                    : (invoice.SendGridEvent === "deferred") ? <Chip style={{ backgroundColor: "#a7c74a", color: "white", height: 25 }} label={"Deferred"}></Chip>
                                                        : (invoice.SendGridEvent === "blocked") ? <Chip style={{ backgroundColor: "#9c0953", color: "white", height: 25 }} label={"Blocked"}></Chip>
                                                            : (invoice.SendGridEvent === "dropped") ? <Chip style={{ backgroundColor: "#9c0935", color: "white", height: 25 }} label={"Dropped"}></Chip>
                                                                : "",
            }
            newSeries.push(data);

        });
        this.setState({ rawInvoiceData: result.items });
        this.setState({ data: newSeries });
    }

    // POST request
    postEmailInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const url = BASE_URL + "api/v1/invoice/email/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = ApplicationIDEnum.QuickBooksOnline;

        fetch(url, {
            method: "POST",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "ToEmails": data["ToEmails"],
                "Invoices": data["Invoices"]
            })
        }).then(response => {
            return response.json()
        }).then(data => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendInvoiceModal: false });
            if (data != null && (data.statuscode == 0 || data.statuscode == "0")) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            } else {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            }
        }).catch(error => {
            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendInvoiceModal: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

    _postTextSMSInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.QuickBooksOnline;

        NetworkService.postInvoiceTextSMS(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showTextSMSInvoiceModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                // this.setState({ showTextSMSInvoiceModal: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    _postCreatePaymentLinkInvoiceRequest(data){
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ isInvoiceLinkCreated: false });
        this.setState({ popupErrorMessage: "" });
        this.setState({ invoicePaymentLink: "" });  

        const app_id = ApplicationIDEnum.QuickBooksOnline;

        NetworkService.postInvoiceLinkRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });                

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.statuscode == 0){
                        this.setState({ popupErrorMessage: data.statusmessage });    
                        this.setState({ invoicePaymentLink: data.data.url });  
                        this.setState({ isInvoiceLinkCreated: true });
                    }
                    else{
                        this.setState({ popupErrorMessage: data.statusmessage });
                    }
                }
                else {
                    this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
                }                
                setTimeout(() => {
                    this.setState({ popupErrorMessage: "" })
                }, 6000);
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ popupErrorMessage: "Oops! Something went wrong, please try again." });
                setTimeout(() => {
                    this.setState({ popupErrorMessage: "" })
                }, 5000);
            });
    }

    copyLinkToClipboard (e) {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        var copyText = document.getElementById("txtInvoicePaymentLink");
        copyText.select();
        copyText.setSelectionRange(0, 99999);

        el.value = copyText.value;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        this.setState({ errorCode: 0 });
        this.setState({ popupErrorMessage: "Invoice payment link copied successfully." });
        setTimeout(() => {
            this.setState({ popupErrorMessage: "" })
        }, 6000);
    }

    handlePrintClick(e) {
        e.preventDefault();
        let invoiceIds = "";
        this.state.selectedInvoiceIndex.map(index => {
            invoiceIds = invoiceIds + "," + (this.state.rawInvoiceData[index].Id);
        })
        invoiceIds = this.replaceAt(invoiceIds, 0, '');
        console.log(invoiceIds);
    }


    replaceAt(str, index, ch) {
        return str.replace(/./g, (c, i) => i === index ? ch : c)
    }

    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    content={
                        <div>
                            {this.state.proceesorSettingError &&
                                <div>
                                    <Alert bsStyle= "info">
                                        <span>
                                            {this.state.proceesorSettingError}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3} xs={12}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime                                              
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3} xs={12}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2} xs={12}>
                                    <span>Invoice Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="invoiceStatus"
                                            value={this.state.filter_invoice}
                                            onChange={value => {
                                                this.setState({ filter_invoice: value });
                                            }
                                            }
                                            options={this.state.invoiceStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2} xs={12}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>                                   
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>

                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>

                                        }
                                        <MaterialTable
                                            //title="Styling with MuiThemeProvider Preview"
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            onSelectionChange={(rows) => {
                                                console.log('You selected ' + rows.length + ' rows');
                                                this.handleSelectedRowData(rows, rows.length);
                                                if (rows.length > 0 && !this.state.proceesorSettingError) {
                                                    this.setState({ showPrintButton: "" });
                                                    if (rows.length === 1) {
                                                        let invoice = {};
                                                        this.state.selectedInvoiceIndex.map(index => {
                                                            invoice = this.state.rawInvoiceData[index];
                                                        });
                                                        console.log(invoice)
                                                        if (invoice.IsPaid || (invoice.IsPaymentCharged && invoice.total == invoice.Balance)) {
                                                            if(invoice.IsPaid)
                                                            {
                                                                this.setState({ showUpdateInvoiceButton: "none" });      
                                                                this.setState({ showActionButtons: "none" });
                                                                this.setState({ showInvoiceSendButton: "none" });
                                                                this.setState({ showTextSMSInvoiceButton: "none" });
                                                                this.setState({ showCreatePaymentLinkButton: "none"});
                                                            }
                                                            else if(invoice.IsPaymentCharged )
                                                            {
                                                                this.setState({ showUpdateInvoiceButton: "" });                                                                
                                                            }
                                                        }
                                                        else {
                                                            if(this.state.Permissions != null && this.state.Permissions != undefined && this.state.Permissions["invoices"] != undefined && this.state.Permissions["invoices"][1]["sendemail"] == false){
                                                                this.setState({ showInvoiceSendButton: "none" });
                                                            }
                                                            else{
                                                                this.setState({ showInvoiceSendButton: "" });
                                                            }
                                                            if(this.state.Permissions != null && this.state.Permissions != undefined && this.state.Permissions["invoices"] != undefined && this.state.Permissions["invoices"][2]["sendsms"] == false){
                                                                this.setState({ showTextSMSInvoiceButton: "none" });
                                                            }
                                                            else{
                                                                this.setState({ showTextSMSInvoiceButton: "" });
                                                            }
                                                            if(this.state.Permissions != null && this.state.Permissions != undefined && this.state.Permissions["invoices"] != undefined && this.state.Permissions["invoices"][3]["collectpayment"] == false){
                                                                this.setState({ showActionButtons: "none" });
                                                                this.setState({ showCreatePaymentLinkButton: "none" });                                                                
                                                            }
                                                            else{
                                                                this.setState({ showActionButtons: "" });
                                                                this.setState({ showCreatePaymentLinkButton: "" });  
                                                            }                                                                                                                      
                                                        }                                                        
                                                        this.setState({ showBatchEmail: "none" });
                                                    }
                                                    else {
                                                        this.setState({ showInvoiceSendButton: "none" });                                                      
                                                        this.setState({ showTextSMSInvoiceButton: "none" });
                                                        this.setState({ showCreatePaymentLinkButton: "none" }); 
                                                        this.setState({ showActionButtons: "none" });
                                                        this.setState({ showUpdateInvoiceButton: "none" }); 
                                                        if (rows.length > 1 && rows.length <= 10) {
                                                            this.setState({ showBatchEmail: "" });
                                                        }
                                                        else{
                                                            this.setState({ showBatchEmail: "none" });
                                                        }
                                                    }
                                                }
                                                else {
                                                    this.setState({ showPrintButton: "none" });
                                                    this.setState({ showActionButtons: "none" });
                                                    this.setState({ showUpdateInvoiceButton: "none" }); 
                                                    this.setState({ showInvoiceSendButton: "none" });  
                                                    this.setState({ showCreatePaymentLinkButton: "none" });                                                  
                                                    this.setState({ showTextSMSInvoiceButton: "none" });
                                                    this.setState({ showBatchEmail: "none" });
                                                }
                                            }
                                            }
                                            components={this.state.components}
                                        // actions={this.state.actions}

                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>

                            {/* Email Modal */}
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showSendInvoiceModal}
                                        onHide={() => this.setState({ showSendInvoiceModal: false })}
                                        backdrop="static"
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer Email :</h5>
                                                    </Col>
                                                    <Col md={8} >
                                                        <FormGroup >
                                                            <FormControl
                                                                value={this.state.singleEmailInvoiceData.customerEmail}
                                                                placeholder="Email"
                                                                type="email"
                                                                autoComplete="off"
                                                                className={this.state.sendInvoiceClassName}
                                                                onChange={event => this.validateEmail(event)}
                                                                onKeyPress={event => this.handleKeyPressInput(event)}
                                                            />
                                                            {this.state.errorSendInvoiceEmail}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleSendInvoiceEmail(e)}>Send Email</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>

                            {/* SMS Modal */}
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showTextSMSInvoiceModal}
                                        onHide={() => this.setState({ showTextSMSInvoiceModal: false })}
                                        backdrop="static"
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Text/SMS Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <h5>Customer Name:</h5>
                                                    </Col>
                                                    <Col md={7} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={5} className="text-left">
                                                        <h5>Invoice Number:</h5>
                                                    </Col>
                                                    <Col md={6} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <h5>Amount to collect:</h5>
                                                    </Col>
                                                    <Col md={7} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5} className="text-left">
                                                        <h5>Use Default Number:</h5>
                                                    </Col>
                                                    <Col md={7} className="text-left">
                                                        <CustomCheckbox
                                                            name="defaultPhoneNumberCheckBox"
                                                            checked={this.state.singleEmailInvoiceData.defaultPhoneNumberCheckBox}
                                                            // labelText={"Create Profile"}
                                                            labelProps={{ style: { fontSize: "17px" } }}
                                                            formControlProps={{
                                                                onChange: (e) => { this.handlePhoneNumberCheckBox(e) }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                {!this.state.singleEmailInvoiceData.defaultPhoneNumberCheckBox &&
                                                    <Row>
                                                        <Col md={5} className="text-left">
                                                            <h5>Phone Number:</h5>
                                                        </Col>
                                                        <Col md={7} >
                                                            <InputGroup>  
                                                                <InputGroup.Addon style={{border: "1px solid #ccc", backgroundColor:"#f5f5f5"}}><span>{" +1 "}</span></InputGroup.Addon>
                                                                <FormControl
                                                                    value={this.state.singleEmailInvoiceData.customerPhoneNumber}
                                                                    type="number"
                                                                    placeholder="Customer Phone Number"
                                                                    className={this.state.phoneNumberState}
                                                                    name="phoneNumber"
                                                                    onChange={event =>
                                                                        this.change(event, "phoneNumber", "length", 7)
                                                                    }
                                                                    onKeyPress={event => this.handleTextSMSKeyPressInput(event)}
                                                                    onBlur={e => this.onPhoneNumberBlur()}
                                                                />                                                               
                                                            </InputGroup>  
                                                             {this.state.phoneNumberErrorMessage}
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showTextSMSInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleTextSMSInvoiceRequest(e)}>Send SMS</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>

                            {/* Create Payment Link Modal */}
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showCreatePaymentLinkInvoiceModal}
                                        onHide={() => this.setState({ showCreatePaymentLinkInvoiceModal: false })}
                                        backdrop="static"
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Create Payment Link</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {this.state.popupErrorMessage &&
                                                <div>
                                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                        <span>
                                                            {this.state.popupErrorMessage}
                                                        </span>
                                                    </Alert>
                                                </div>
                                            }
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                {this.state.isInvoiceLinkCreated && !this.state.ShowPopupProgressBar &&
                                                    <Row>
                                                        <Col md={9} className="text-left">
                                                            <FormControl
                                                                    value={this.state.invoicePaymentLink}
                                                                    type="text"
                                                                    id="txtInvoicePaymentLink"
                                                                    readOnly={true}
                                                                    style={{ height: "30px", marginTop: "10px" }}                                                                
                                                                />
                                                        </Col>
                                                        <Col md={3} className="text-left">
                                                            <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={e => this.copyLinkToClipboard(e)}>
                                                                <i className="fa fa-copy"></i> Copy Link
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showCreatePaymentLinkInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleCreatePaymentLinkInvoice(e)}>Create Link</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            {/* Update Invoice  Modal */}
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showInvoiceUpdateModal}
                                        onHide={() => this.setState({ showInvoiceUpdateModal: false })}
                                        backdrop="static"
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Update Invoice (Mark as Paid)</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {this.state.popupErrorMessage &&
                                                <div>
                                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                        <span>
                                                            {this.state.popupErrorMessage}
                                                        </span>
                                                    </Alert>
                                                </div>
                                            }
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.singleEmailInvoiceData.invoiceNumber}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.singleEmailInvoiceData.balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>    
                                                <Row>
                                                <Col md={12} className="text-left">
                                                <h5><b>This invoice has been paid by the customer but not updated in QuickBooks, please update and mark it in QuickBooks as paid. </b></h5>
                                                </Col>
                                                </Row>                                            
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showInvoiceUpdateModal: false })}>Close</Button>
                                            {/* <Button bsStyle="primary" fill wd onClick={e => this.handleInvoiceUpdate(e)}>Update Invoice</Button> */}
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.showBatchEmailModal &&
                                        <BatchEmailModule showBatchEmailModal={this.handleBatchEmailClick} parentCallback={this.batchEmailCallbackFunction} Invoices={this.state.BatchEmailInvoices }  AppId={ApplicationIDEnum.QuickBooksOnline}/>
                                    }
                                </Col>
                            </Row>                            
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Invoices;

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHTMLDiv: true,
            InvoiceRawHtml: null,
            prevValue: "",
        }
    }

    getInvoicePreview(invoice_id) {
        const params = {
            "invoice_id": invoice_id
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }

    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {

        const app_id = ApplicationIDEnum.QuickBooksOnline;
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    const content_type = data.data.content_type;
                    if (content_type === "application/html") {
                        this.setState({ showHTMLDiv: true })
                    } else {
                        this.setState({ showHTMLDiv: false });
                        this.renderPDFView(data.data.html || null, "pdf-canvas");
                    }
                    this.setState({ InvoiceRawHtml: data.data.html || null });
                } else {
                }
            }).catch(error => {
                this.setState({ InvoiceRawHtml: "<h1>Oops! Something went wrong, please try again.</h1>" });
            });
    }

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {

            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    rawMarkup(value) {
        if (this.state.prevValue != value && value != null) {
            this.state.prevValue = value;
            this.getInvoicePreview(value);
        }
        return { __html: this.state.InvoiceRawHtml };
    };


    render() {
        return (
            <div>
                <div style={{ display: "none" }} dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />

                {this.state.showHTMLDiv &&
                    <div dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />
                }
                {!this.state.showHTMLDiv &&
                    <div id="pdf-canvas" height="0"></div>
                }
            </div>
        );
    }
}






