/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
// react component that creates a dropdown menu for selection
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CreditCardInput from 'utils/Credit_Card_Input.js';
import ExpInput from 'utils/Exp_Input.js';
import CVVInput from 'utils/CVV_Input.js';
import AccountNumberInput from 'utils/AccountNumber_Input.js';
import RoutingNumberInput from 'utils/RoutingNumber_Input.js';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButton/CustomButton.jsx";

const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb("#000") +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb("#0075c1") +
    ",.4)"
};

const styles = {   
    dropDownFontStyle:{
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: "#AAAAAA",
    fontSize: "13px",
    marginTop: "2px",
    marginBottom: "-2px",
   // fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "1.42857"
  },
  select: {
      width: "100%",
     marginTop: 0,
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "1.42857",
    textDecoration: "none",
   // textTransform: "uppercase",
    color: "#3C4858",
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)"
    },
    "& + input + svg": {
      transition: "all 300ms linear"
    }
  },
  selectMenuItem: {
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "2",
    whiteSpace: "nowrap",
    color: "#333",
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: "#0075c1",
      color:  "#FFF",
      ...primaryBoxShadow
    }
  }
}    



class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      cardExpiry: "",
      cardCVV: "",

      achAccountName: "",
      achAccountNumber: "",
      achRoutingNumber: "",
      achAccountType: "checking",
      achEntityType: "",

      cardNumberValid: true,
      cardExpiryValid: true,
      cardCVVValid: true,

      accountNameValid: true,
      accountNumberValid: true,
      routingNumberValid: true,

      languageSelect: null,
      languageError: null,

      formUrl: props.getForm().formUrl,
      isActionTypeCC: props.getActionTypeCC,
      FormErrors: { CardHolderName: '', AccountName: '' },
    };

    this.handleCCOnSubmit = this.handleCCOnSubmit.bind(this);
    this.handleACHOnSubmit = this.handleACHOnSubmit.bind(this);
  }

  handleOnBlurInput(e) {
        // console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        this.validateField(e.target.name, e.target.value)
  }

  handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'CardNumber':
                this.setState({cardNumber: value}, () => { this.validateField(name, value) })
                break;            
            case "CardExpiry":
                this.setState({cardExpiry: value}, () => { this.validateField(name, value) })
                break;
            case "CardCVV":
                this.setState({cardCVV: value}, () => { this.validateField(name, value) })                
                break;                          
            case "achAccountName":
                this.setState({achAccountName: value}, () => { this.validateField(name, value) })  
                break;
            case "AccountNumber":
                this.setState({achAccountNumber: value}, () => { this.validateField(name, value) }) 
                break;
            case "RoutingNumber":
                this.setState({achRoutingNumber: value}, () => { this.validateField(name, value) })                 
                break;
            case "AccountType":
                this.setState({achAccountType: value}, () => { this.validateField(name, value) })                 
                break;
            default:
                break;
        }
    }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.FormErrors;
    let cardNumberValid = this.state.cardNumberValid;
    let cardExpiryValid = this.state.CardExpiryValid;
    let cardCVVValid = this.state.cardCVVValid;

    let accountNumberValid = this.state.accountNumberValid;
    let accountNameValid = this.state.accountNameValid;
    let routingNumberValid = this.state.routingNumberValid;
    switch (fieldName) {           
        case 'CardNumber':
              cardNumberValid = global.isCardNumberValid;              
              this.setState({cardNumberValid: cardNumberValid })
            break;
        case 'CardExpiry':
             cardExpiryValid = global.isCardExpiryValid;
             this.setState({cardExpiryValid: cardExpiryValid });             
            break;
        case 'CardCVV':
              cardCVVValid = global.isCardCVVValid;
              this.setState({cardCVVValid: cardCVVValid }); 
            break;
        case 'achAccountName':
            accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); 
            fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
            this.setState({accountNameValid: accountNameValid });             
            break;
        case 'AccountNumber':
            accountNumberValid = value.match(/^\d{4,17}$/);
            this.setState({accountNumberValid: accountNumberValid });                   
            break;
        case 'RoutingNumber':
            routingNumberValid = value.match(/^\d{9}$/);
            this.setState({routingNumberValid: routingNumberValid})
            break;
        default:
            break;
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(props) {
    if (props) {
      if (props.getForm().step2Completed) {
        props.jumpToStep(2);
      }
    }
  }

  isValidated() {
    // return this.props.getForm().step2Completed ? true : false;
  }

  handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
  };

  handleCCOnSubmit = (event) => {
    if(this.state.cardNumber != "" && this.state.cardNumberValid && this.state.cardExpiry != "" && this.state.cardExpiryValid && this.state.cardCVV && this.state.cardCVVValid){

    }
    else{
      this.validateField("CardNumber", this.state.cardNumber);
      this.validateField("CardExpiry", this.state.cardExpiry);
      this.validateField("CardCVV", this.state.cardCVV);
      return false;
    }

    let formData = new FormData()
    formData.append("billing-cc-number", this.state.cardNumber);
    var expDate = this.state.cardExpiry;
    expDate = expDate.replace("/","");
    formData.append("billing-cc-exp", expDate);
    formData.append("billing-cvv", this.state.cardCVV);

    let data = {
      "formData": formData,
      "formUrl": this.state.formUrl
    }
    event.preventDefault();
    this.props.onTrigger(data, "step2");
  };

  handleACHOnSubmit = (event) => {
    if(this.state.achAccountName != "" && this.state.accountNameValid && this.state.achAccountNumber != "" && this.state.accountNumberValid && this.state.achRoutingNumber && this.state.routingNumberValid){

    }
    else{
      this.validateField("achAccountName", this.state.achAccountName);
      this.validateField("AccountNumber", this.state.achAccountNumber);
      this.validateField("RoutingNumber", this.state.achRoutingNumber);
      return false;
    }
    
    let formData = new FormData()
    formData.append("billing-account-name", this.state.achAccountName);
    formData.append("billing-account-number", this.state.achAccountNumber);
    formData.append("billing-routing-number", this.state.achRoutingNumber);
    formData.append("billing-account-type", this.state.achAccountType);

    let data = {
      "formData": formData,
      "formUrl": this.state.formUrl
    }
    event.preventDefault();
    this.props.onTrigger(data, "step2");
  };

  showCCForm() {
    return (
      <form id="cc_form" action={this.state.formUrl} >        
        <Row>
            <Col xs={12} sm={12} md={12}>
                <CreditCardInput
                    labelText="Card Number"
                    name="CardNumber"
                    id="CardNumber"
                    value={this.state.cardNumber}
                    formControlProps={{
                        error: !this.state.cardNumberValid,
                        onBlur: (e) => { this.handleOnBlurInput(e) },
                        onChange: (e) => { this.handleUserInput(e) },
                        onError: err => console.log(`number error: ${err}`)
                    }}
                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px" } }} // font size of input text                     
                    labelProps={{ style: { fontWeight: "bold" } }}
                />
            </Col>
        </Row>
        <Row>
          <Col md={6} mdOffset={0}>            
            <ExpInput        
              labelText="MM/YY"   
              name = "CardExpiry"
              value={this.state.cardExpiry}
              fullWidth= {true}
              formControlProps={{//cardExpiryInputProps={{
                  error: !this.state.cardExpiryValid,
                  // disabled: this.state.BalanceDue === "0.00",
                  onBlur: (e) => {this.handleOnBlurInput(e)},
                  onChange: (e) => {this.handleUserInput(e)},
                  onError: err => console.log(`expiry error: ${err}`)
                  }}  
              inputProps={{style: {  fontFamily: "sans-serif", fontSize: "18px"}}}   
              labelProps={{style: {fontWeight: "bold"}}}     
            />
          </Col>
          <Col md={6}>           
            <CVVInput 
                labelText="CVV" 
                name="CardCVV" 
                value={this.state.cardCVV}
                formControlProps={{
                error: !this.state.cardCVVValid, 
                    onBlur: (e) => {this.handleOnBlurInput(e)},
                    onChange: (e) => {this.handleUserInput(e)},
                    onError: err => console.log(`cvc error: ${err}`)
                    }}  
                inputProps={{style: {  fontFamily: "sans-serif", fontSize: "18px"}}} 
                labelProps={{style: {fontWeight: "bold"}}}          
            />
          </Col>
        </Row>
        <div  style={{ marginTop:"20px"}}>
          {/* <input type="submit" value="Finish" /> */}
          <Button
            bsStyle="info"
            fill
            wd
            pullRight
            onClick={this.handleCCOnSubmit}
          >
            Charge
          </Button>
        </div>

      </form>
    );
  }

  showACHForm() {
    return (
      <form id="ach_form" action={this.state.formUrl} >
        <Row>
          <Col md={12} mdOffset={0}>
            <CustomInput
                labelText="Account Name"
                isRequired = {true}
                id="achAccountName"
                name="achAccountName"
                value={this.state.achAccountName}
                showErrorMessage={!this.state.accountNameValid}
                errorMessage="Account name is invalid"  
                formControlProps={{
                    fullWidth: true,
                    error: !this.state.accountNameValid,
                    onChange:(e) => {this.handleUserInput(e)},
                    onKeyPress:(e) => {this.handleKeyPressInput(e)},
                    onBlur:(e) => {this.handleOnBlurInput(e)},
                }}
                inputProps={{style: {  fontFamily: "sans-serif", fontSize: "18px"}}} 
                labelProps={{style: {fontWeight: "bold"}}}
                />           
          </Col>
        </Row>
        <Row>
          <Col md={12} mdOffset={0}>            
            <AccountNumberInput
                labelText="Account Number"
                isRequired = {true}
                id="AccountNumber"
                name="AccountNumber"
                value={this.state.achAccountNumber}
                formControlProps={{
                    fullWidth: true,
                    error: !this.state.accountNumberValid,
                    onBlur: (e) => {this.handleOnBlurInput(e)},
                    onChange:(e) => {this.handleUserInput(e)}
                }}
                inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                labelProps={{style: {fontWeight: "bold"}}}
                />
          </Col>
          </Row>
          <Row>
            <Col md={12} mdOffset={0}>            
              <RoutingNumberInput
                labelText="Routing Number"
                isRequired = {true}
                id="RoutingNumber"
                name="RoutingNumber"
                value={this.state.achRoutingNumber}
                formControlProps={{
                    fullWidth: true,
                    error: !this.state.routingNumberValid,
                    onChange:(e) => {this.handleUserInput(e)},
                    onBlur: (e) => {this.handleOnBlurInput(e)}
                }}
                inputProps={{style: {fontFamily: "sans-serif", fontSize: "18px"}}}  
                labelProps={{style: {fontWeight: "bold"}}}
                />
            </Col>
          </Row>
          <Row>
            <Col md={12} mdOffset={0}>       
              <InputLabel htmlFor="simple-select" style={styles.dropDownFontStyle}>
                  Account Type
              </InputLabel>
              <Select style = {styles.select} value={this.state.achAccountType}
                  onChange={ (e) => this.handleUserInput(e)}
                  inputProps={{
                  name: "AccountType",
                  id: "AccountType",
                  }}                            
              >
                  <MenuItem disabled style={styles.selectMenuItem}>
                    Select Account Type
                  </MenuItem>
                  <MenuItem style={styles.selectMenuItem} value="checking">
                  Checking
                  </MenuItem>
                  <MenuItem style={styles.selectMenuItem} value="savings">
                    Savings
                  </MenuItem>
                  <MenuItem style={styles.selectMenuItem}  value="businessChecking">
                    Business Checking
                  </MenuItem>
              </Select>
              </Col>
          </Row>
        <div style={{ marginTop:"20px"}}>
          {/* <input type="submit" value="Finish" /> */}
          <Button
            bsStyle="info"
            fill
            wd
            pullRight
            onClick={this.handleACHOnSubmit}
          >
            Charge
          </Button>
        </div>

      </form>
    );
  }

  render() {
    return (
      <div className="wizard-step">
        <h5 className="text-center">

        </h5>
        {/* CC Form */}
        {this.state.isActionTypeCC == true &&
        this.showCCForm()
        }

        {/* ACH Form */}
        {this.state.isActionTypeCC == false &&
        this.showACHForm()
        }
      </div>
    );
  }
}

export default Step2;
