/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/CustomerPortal/Dashboard.jsx";
import Settings from "views/CustomerPortal/Settings.jsx";
import Transactions from "views/CustomerPortal/Transactions.jsx";
import Invoices from "views/CustomerPortal/Invoices.jsx";
import Payment from "views/CustomerPortal/Payment.jsx";
import NMIPayment from "views/CustomerPortal/NMIPayment.jsx";
import CCPayment from "views/CustomerPortal/CCPayment.jsx";
import CCCustomerPayment from "views/CustomerPortal/CCCustomerPayment.jsx"
import invoiceDetail from "views/CustomerPortal/Detail.jsx";

var CustomerPortalRoutes = [
  {
    path: "/dashboard",
    layout: "/customerportal",
    name: "Dashboard",
    icon: "fa fa-dashboard",
    component: Dashboard
  },
  {
    path: "/invoices",
    layout: "/customerportal",
    name: "Invoices",
    icon: "fa fa-newspaper-o",
    component: Invoices
  },
  {
    path: "/payment",    
    name: "payment",
    component: Payment,
    layout: "/customerportal"
  },
  {
    path: "/nmipayment",    
    name: "payment",
    component: NMIPayment,
    layout: "/customerportal"
  },
  {
    path: "/customerpayment",    
    name: "payment",
    component: CCCustomerPayment,
    layout: "/customerportal"
  },  
  {
    path: "/ccpayment",    
    name: "payment",
    component: CCPayment,
    layout: "/customerportal"
  },
  {
    path: "/detail",
    name: "invoice detail",
    component: invoiceDetail,
    layout: "/customerportal"
  },  
  {
    path: "/transactions",
    layout: "/customerportal",
    name: "Transactions",
    icon: "fa fa-money",
    component: Transactions
  },
  {
    path: "/settings",
    layout: "/customerportal",
    name: "Settings",
    icon: "fa fa-gears",
    component: Settings
  }
  
];

export default CustomerPortalRoutes;
