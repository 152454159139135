import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    InputGroup,
    Alert,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from 'moment';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import creditCardIcon from "assets/img/icons/creditcard.png";
import achIcon from "assets/img/icons/ach.png";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js"

const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
        color: "green",//successColor[2], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    errorResponse: {
        color: "red",//dangerColor[0], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
}

class CreateSubscription extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            ShowProgressBar: false,
            errorMessage: "",
            errorCode: 0,  
            Subscription: {                
                start_date: "",
                payment_token: "",
                ccnumber: "",
                ccexp: "",
                cardtype: "",
                payment: "",
                checkname: "",
                checkaccount: "",
                checkaba: "",
                account_type: "",
                currency: "USD",
                account_holder_type: "",
                sec_code: "",
                first_name: "",
                last_name: "",
                address1: "",
                state: "",
                city: "",
                zip: "",
                country: "",                
                phone: "",
                email: "",
                company: "",                
                orderid: "",
                order_description: "",
                ponumber: "",
                customer_receipt: false
            },     
            planInfo:{
                plan_id: "",
                plan_payments: "0",
                plan_name: "",
                plan_amount: "0.00",  
                day_frequency: "31",
                month_frequency: "",                       
                day_of_month: ""
            },      
            planAmountState: "",
            planAmountErrorMessage: "",
            selectPlanState: "",
            selectPlanErrorMessage: "",
            IsDayFrequency: true,
            IsMonthFrequency: false,
            IsChargeUntilCanceled: true,
            IsChargeTheCustomer: false,

            firstNameState: "",
            firstNameErrorMessage: "",
            lastNameState: "",
            lastNameErrorMessage: "",
            emailErrorMessage: "",
            emailState: "",  
            paymentIcon: creditCardIcon,             
            IsCreditCard: true,
            IsACH: false,
            isSubmitting: false,            
            plan: {
                    value: "",
                    label: "Select Plan",
                    isDisabled: true
                },
            plans: [{
                        value: "",
                        label: "Select Plan",
                        isDisabled: true
                    },
                    // {
                    //     value:"123",
                    //     label:"Everyday Plan 1"
                    // },
                    // {
                    //     value:"456",
                    //     label:"Every month Plan 2"
                    // }
                ],
            subscriptionDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            IsCustomPlan: false,
            isValid: true

        }
        this.subscriptionDateHandler = this.subscriptionDateHandler.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        } else {
            const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
            if (document.getElementById(data_tokenize.PPToken)) {
                this.configureCollectJS();
                console.log("tokenization key exists : " + data_tokenize.PPToken + "  loading configuration CollectJS");
            }
            else {
                this.loadNMIScriptTag(data_tokenize.PPToken);
            }
        }       
        //this._getPlansRequest();
        this.SetSubscriptionDates(); 
        this.getRecurringPlanas();
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 3000);

    }

    //Need to call this for getting plan list...
    getRecurringPlanas(){
        //get request for plans
        this._getRecurringPlanRequest();
    }

    setPlansResult(data) {
        const newState = [];

        newState.push({ value: "", label: "Select Plan", isDisabled: true });
        data.map(data => {
            const x = { value: data["ID"], label: data["Name"] }
            newState.push(x);
        });        
        this.setState({ plans: newState });

    }

    handleChargeTimeRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "chargeuntilcanceled") {
            this.setState({ IsChargeUntilCanceled: true }); 
            this.setState({ IsChargeTheCustomer: false });   
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    plan_payments: "0"
                }
            }));        
        }
        else {
            this.setState({ IsChargeTheCustomer: true });   
            this.setState({ IsChargeUntilCanceled: false }); 
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    plan_payments: "1"
                }
            }));        
        }
    }

    handleFrequencyRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "dayfrequency") {
            this.setState({ IsDayFrequency: true }); 
            this.setState({ IsMonthFrequency: false });  
            this.setState(prevState => ({
                        ...prevState,
                        planInfo: {
                            ...prevState.planInfo,
                            day_frequency: 31
                        }
                    }));    
            this.setState(prevState => ({
                        ...prevState,
                        planInfo: {
                            ...prevState.planInfo,
                            day_of_month: ""
                        }
                    })); 
            this.setState(prevState => ({
                        ...prevState,
                        planInfo: {
                            ...prevState.planInfo,
                            month_frequency: ""
                        }
                    }));    
 
        }
        else {
            this.setState({ IsMonthFrequency: true });   
            this.setState({ IsDayFrequency: false }); 
            this.setState(prevState => ({
                        ...prevState,
                        planInfo: {
                            ...prevState.planInfo,
                            day_of_month: "1"
                        }
                    })); 
            this.setState(prevState => ({
                        ...prevState,
                        planInfo: {
                            ...prevState.planInfo,
                            month_frequency: "1"
                        }
                    }));  
            this.setState(prevState => ({
                        ...prevState,
                        planInfo: {
                            ...prevState.planInfo,
                            day_frequency: ""
                        }
                    }));        
        }
    };

    subscriptionDateHandler = (date) => {
        if(date._isValid)        
        {
            this.setState({ subscriptionDate: date.format("MM/DD/YYYY") });
            this.setState(prevState => ({
                Subscription: {
                    ...prevState.Subscription,
                    start_date: date.format("YYYYMMDD")
                }
            }));
        }
    };

    SetSubscriptionDates() {
        this.setState(prevState => ({
            Subscription: {
                ...prevState.Subscription,
                start_date: this.formatDate(this.state.subscriptionDate, "YYYYMMDD")
            }
        }));
    }

    formatDate(date, format) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if(format === "YYYYMMDD"){
            return [year, month, day].join('');
        }
        else if(format === undefined || format == null){       
            return [year, month, day].join('-');
        }
        else if(format === "MM/DD/YYYY"){
            return [month, day, year].join('/');
        }
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }   

    handleCancelCreateSubscription(e) {
        this.props.history.push('/nmi/subscriptions');
        e.preventDefault();
    }

    handleCreateSubcription(subscribe, event){
        event.preventDefault();
        this._postCreateSubscriptionRequest(subscribe)
    }

    ValidateEmailAddress(event, name) {
        var value = event.target.value;
        this.setState(prevState => ({
            ...prevState,
            Subscription: {
                ...prevState.Subscription,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {            
            this.setState({
                emailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });            
            this.setState({
                emailState: "error"
            });                                              
        }
        else {
            this.state.emailState = "valid";
            this.state.emailErrorMessage = "";
        }

        if (this.state.emailState === "valid" || this.state.emailState === "") {
            this.setState({ isValid: true });
        }
        else {
            this.setState({ isValid: false });
        }
    }

    handleRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        this.setState({ errorMessage: "" });
        if (target.value === "creditcard") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsACH: false });
            this.setState({ paymentIcon: creditCardIcon });
            this.setState(prevState => ({
                Subscription: {
                    ...prevState.Subscription,
                    payment: "creditcard"
                }
            }));
        }
        else {
            this.setState({ IsACH: true });
            this.setState({ IsCreditCard: false });
            this.setState({ paymentIcon: achIcon });            
            this.setState(prevState => ({
                Subscription: {
                    ...prevState.Subscription,
                    payment: "check"
                }
            }));
            
        } 
        
        this.configureCollectJS();

        if (document.getElementById("creditCardErrorMessage")) {
        document.getElementById("creditCardErrorMessage").style.display = "none";
        }
        if (document.getElementById("expErrorMessage")) {
        document.getElementById("expErrorMessage").style.display = "none";
        }            
        if (document.getElementById("cvvErrorMessage")) {
        document.getElementById("cvvErrorMessage").style.display = "none";
        }
        if (document.getElementById("accountNameErrorMessage")) {
        document.getElementById("accountNameErrorMessage").style.display = "none";
        }
        if (document.getElementById("accountNumberErrorMessage")) {
        document.getElementById("accountNumberErrorMessage").style.display = "none";
        }
        if (document.getElementById("routingNumberErrorMessage")) {
        document.getElementById("routingNumberErrorMessage").style.display = "none";
        }

    };

    handleSubmit = (event) => {
        this.setState({ StatusMessage: "" });
        this.setState({ShowProgressBar: true});
        this.Validate(null,null);
        var isCCFormValid = true;
        if (this.state.IsCreditCard) {
            if (document.getElementById("creditCardErrorMessage").innerHTML !== "") {
                document.getElementById("creditCardErrorMessage").style.display = "block";
                isCCFormValid = false;
            }
            if (document.getElementById("expErrorMessage").innerHTML !== "") {
                document.getElementById("expErrorMessage").style.display = "block";
                isCCFormValid = false;
            }
            if (document.getElementById("cvvErrorMessage").innerHTML !== "") {
                document.getElementById("cvvErrorMessage").style.display = "block";
                isCCFormValid = false;
            }
        }
        else if (this.state.IsACH) {
            if (document.getElementById("accountNameErrorMessage").innerHTML !== "") {
                document.getElementById("accountNameErrorMessage").style.display = "block";
                isCCFormValid = false;
            }
            if (document.getElementById("accountNumberErrorMessage").innerHTML !== "") {
                document.getElementById("accountNumberErrorMessage").style.display = "block";
                isCCFormValid = false;
            }
            if (document.getElementById("routingNumberErrorMessage").innerHTML !== "") {
                document.getElementById("routingNumberErrorMessage").style.display = "block";
                isCCFormValid = false;
            }
        }
        if (!isCCFormValid || !this.state.isValid) {
            event.preventDefault();
            this.setState({ShowProgressBar: false});
            return false;
        }
        else {
            this.setState({ isSubmitting: true });
            event.preventDefault();
            window.CollectJS.startPaymentRequest();
        }
        
    }

    configureCollectJS() {
        try {
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                callback: (token) => {
                    this.finishSubmit(token);                     
                },
                validationCallback: function (field, status, message) {
                    if (status) {
                        // console.log(document.getElementById(field).value);
                        var message = field + " is now OK 1 : " + message;
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "none";
                                document.getElementById("creditCardErrorMessage").innerHTML = "";
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "none";
                                document.getElementById("expErrorMessage").innerHTML = "";
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "none";
                                document.getElementById("cvvErrorMessage").innerHTML = "";
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "none";
                                document.getElementById("accountNameErrorMessage").innerHTML = "";
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "none";
                                document.getElementById("accountNumberErrorMessage").innerHTML = "";
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "none";
                                document.getElementById("routingNumberErrorMessage").innerHTML = "";
                                break;
                        }                        
                    } else {
                        var message = field + " is now Invalid : " + message + "  status=" + status;
                        //  document.getElementById(field).classList.add("invalidCss");
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                                }
                                else {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Invalid card number";
                                }
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                                }
                                else {
                                    document.getElementById("expErrorMessage").innerHTML = "Invalid expiry date";
                                }
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                                }
                                else {
                                    document.getElementById("cvvErrorMessage").innerHTML = "Invalid CVV";
                                }
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                                }
                                else {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Invalid account name";
                                }
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                                }
                                else {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Invalid account number";
                                }
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                                }
                                else {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Invalid routing number";
                                }
                                break;
                        }
                    }
                    console.log(message);
                },
                invalidCss: {
                    'border-bottom-color': 'red',
                    'border-width': '2px'
                },                
                customCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#AAAAAA',
                    'border-style': 'solid',
                    'border-width': '1px',
                    'font-family': 'sans-serif',
                    'font-size': '18px'
                },
                placeholderCss: {
                    "color": "#AAAAAA",
                    "font-weight": "bold",
                    "font-size": "16px"
                },
                focusCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#0075c1',
                    'border-width': '2px'
                },
                fields: {
                    ccnumber: {
                        selector: '#ccnumber',
                    },
                    ccexp: {
                        selector: '#ccexp'
                    },
                    cvv: {
                        selector: '#cvv',
                    },
                    checkaccount: {
                        selector: "#accountnumber",
                    },
                    checkaba: {
                        selector: "#routingnumber",
                    },
                    checkname: {
                        selector: "#accountname",
                    }
                }
            });
        } catch (error) {
            console.log(error);
            //this._getActiveProcessorRequest();
        }
    }

    finishSubmit(response) {
        const { isSubmitting, ...formData } = { ...this.state };
        formData.token = response.token;      
        this.handleCharged(response);
        //post this form data to server api....
    }

    handlePlanChanged(selectedPlan) {
        this.setState({plan: selectedPlan});
        this.setState({selectPlanState: "valid"});
        this.setState({selectPlanErrorMessage: ""})
        this.setState(prevState => ({
            planInfo:
            {
                ...prevState.planInfo,
                plan_id: selectedPlan.value
            }
        }));             
    }

    handleCharged = (value) => {        
        const invoice_info = this.state.invoiceData;
        const payment_info = this.state.Customer;
        const amount = this.state.Amount;
        let subscription = this.state.Subscription; 
        if (this.state.IsCreditCard) {
            subscription.payment_token = value.token;
            subscription.ccnumber = value.card.number;
            subscription.ccexp = value.card.exp;
            subscription.cardtype =  value.card.type;
            subscription.payment = "creditcard";

        } else if (this.state.IsACH) {
            subscription.payment_token = value.token;
            subscription.checkname = value.check.name;
            subscription.checkaccount = value.check.account;
            subscription.checkaba = value.check.aba;
            subscription.account_type = "checking";
            subscription.payment = "check";              
        }

        if(this.state.IsCustomPlan){
            subscription.plan_payments = this.state.planInfo.plan_payments;
            subscription.plan_amount = this.state.planInfo.plan_amount;
            subscription.day_frequency = this.state.planInfo.day_frequency;
            subscription.month_frequency = this.state.planInfo.month_frequency;
            subscription.day_of_month = this.state.planInfo.day_of_month;
        }
        else{
            subscription.plan_id = this.state.planInfo.plan_id;
        }         

        console.log(subscription);
        this._postCreateSubscriptionRequest(subscription);
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    if (data.statuscode === 0) {
                        let processor = data.data.items;
                        this.setProcessorResult(processor);
                    }
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setProcessorResult = (processor) => {
        processor.map(data => {
            const configuration = data.configuration;
            if (configuration != null || configuration != "undefined") {
                const tokenizationKey = configuration.TokenizationKey;
                if (document.getElementById(tokenizationKey)) {
                    this.configureCollectJS();
                    console.log("tokenization key exists : " + tokenizationKey + "  loading configuration CollectJS");
                }
                else {
                    this.loadNMIScriptTag(tokenizationKey);
                }
            } else {
                this.setState({ StatusCode: 1 });
                this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Reseller and Support team..." });
            }
        });
    }

    loadNMIScriptTag(tokenizationKey) {
        let script = document.createElement("script");
        script.src = "https://secure.networkmerchants.com/token/Collect.js";
        script.async = true;
        script.setAttribute(
            "data-tokenization-key",
            tokenizationKey
        );
        script.setAttribute(
            "id",
            tokenizationKey
        );
        script.onload = () => {
            this.configureCollectJS();
        }
        document.head.appendChild(script);
    }

    handleCheckBoxChange(e) {
        this.setState({IsCustomPlan: e.target.checked});                                                        
        this.setState({ selectPlanErrorMessage: "" });
        this.setState({ planAmountErrorMessage: "" });        
        this.setState({
            plan: {
                    value: "",
                    label: "Select Plan",
                    isDisabled: true
                }
        });
        this.setState(prevState => ({
            planInfo:
            {
                ...prevState.planInfo,
                plan_id: ""                                                                    
            }
        }));
    }
    

    Validate(event, name) {       
        if (name === "email") {
            if (this.state.emailState === "error") {
                this.setState({
                    emailErrorMessage: (
                        <small className="text-danger">
                            Invalid email address.
                        </small>
                    )
                });
            }
            else if (this.state.emailState === "valid" || this.state.emailState === "") {
                this.setState({
                    emailErrorMessage: ""
                });
            }
        }

        if(!this.state.IsCustomPlan){
            if (this.state.selectPlanState === "" || this.state.selectPlanState === "error") {
                this.setState({
                    selectPlanErrorMessage: (
                        <small className="text-danger">
                            Plan is required.
                        </small>
                    )
                });
            }
            else{                
                this.setState({ selectPlanErrorMessage: "" });
            }
        }
        else{
            if(this.state.planAmountState === "" || this.state.planAmountState === "error"){
                this.setState({
                    planAmountErrorMessage: (
                        <small className="text-danger">
                            Invalid amount.
                        </small>
                    )
                });

            }
            else{
                this.setState({ planAmountErrorMessage: "" });
            }
        }
        

        if ((this.state.emailState === "valid" || this.state.emailState === "") && 
        ((!this.state.IsCustomPlan && this.state.selectPlanState === "valid")
        || (this.state.IsCustomPlan && this.state.planAmountState === "valid")) ) {
            this.setState({ isValid: true });
        }
        else {
            this.setState({ isValid: false });
        }
    }
   
    /** API CALLING */

    _postCreateSubscriptionRequest(payload) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //set appid here...
        NetworkService.postCreateRecurringSubscriberRequest(app_id, payload)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data !== null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode === 0) {
                        this.props.history.push({
                            pathname: '/nmi/subscriptions',
                            state: { errorMessage: data.statusmessage, errorCode: 0 }
                        });
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _getRecurringPlanRequest(){
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);

        NetworkService.getRecurringPlanListRequest(app_id)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewPlanPopupModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });                    
                    if(data.statuscode == 0)
                    {                       
                        if (data.data != null) {
                            this.setPlanListingResult(data.data.items);
                         }
                    }
                    else{
                        this.setState({ errorMessage: data.statusmessage });
                    }
                    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
            
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    setPlanListingResult = (result) => {
        const newSeries = [];

        result.map((data, index) =>{
            try{
                const plan = {
                    "index": index,
                    "label": data.Name,
                    "value": data.Id,
                }
                newSeries.push(plan);
            }
            catch (why) {
                console.log(why)
            }
        });

        this.setState({plans: newSeries});
    }


    /** Render Function */
    render() {  
        const yesterday = moment().subtract(1, 'day');
        const disablePastDates = current => {
            return current.isAfter(yesterday);
        };      
        return (
            <div className="ii-main-content">
                {this.state.ShowProgressBar &&
                    <div style={{ marginBottom: "5px" }}>
                        <CustomProgressBar></CustomProgressBar>
                    </div>
                }
                <Card
                    textCenter
                    content={
                        <div>
                            <form onSubmit={this.handleSubmit}>
                                {this.state.errorMessage != "" &&
                                    <div>
                                        <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                            <span>
                                                {this.state.errorMessage}
                                            </span>
                                        </Alert>
                                    </div>
                                }
                                <Row>
                                    <Col md={7}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        First Name:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.first_name}
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    first_name: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                        onBlur={event => {
                                                            var value = event.target.value;
                                                            if (value.length > 0 && this.verifyLength(value, 2)) {
                                                                this.setState({
                                                                    firstNameState: "valid",
                                                                    firstNameErrorMessage: ""
                                                                });
                                                            }
                                                            else if (value.length > 0) {
                                                                this.setState({
                                                                    firstNameErrorMessage: (
                                                                        <small className="text-danger">
                                                                            Invalid First Name.
                                                                        </small>
                                                                    )
                                                                });
                                                            }
                                                        }
                                                        }
                                                    />
                                                    {this.state.firstNameErrorMessage}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Last Name:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.last_name}
                                                        type="text"
                                                        placeholder="Last Name"
                                                        name="last_name"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    last_name: value
                                                                }
                                                            }));

                                                        }
                                                        }
                                                        onBlur={event => {
                                                            var value = event.target.value;
                                                            if (value.length > 0 && this.verifyLength(value, 2)) {
                                                                this.setState({
                                                                    lastNameState: "valid",
                                                                    lastNameErrorMessage: ""
                                                                });
                                                            }
                                                            else if (value.length > 0) {
                                                                this.setState({
                                                                    lastNameErrorMessage: (
                                                                        <small className="text-danger">
                                                                            Invalid Last Name.
                                                                        </small>
                                                                    )
                                                                });
                                                            }
                                                        }
                                                        }
                                                    />
                                                    {this.state.lastNameErrorMessage}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Customer Email: 
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.email}
                                                        placeholder="Email"
                                                        disabled={this.state.ShowProgressBar}
                                                        name="email"
                                                        type="email"
                                                        className={this.state.emailState}
                                                        onChange={event =>
                                                            this.ValidateEmailAddress(event, "email")
                                                        }
                                                        onBlur={event => this.Validate(event, "email")}
                                                    />
                                                    {this.state.emailErrorMessage}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Company:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.company}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="Company"
                                                        name="company"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    company: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Phone:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.phone}
                                                        type="text"
                                                        placeholder="Phone"
                                                        name="phone"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    phone: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Website:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.website}
                                                        type="text"
                                                        placeholder="Website"
                                                        name="website"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    website: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Address:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.address1}
                                                        type="text"
                                                        placeholder="Address"
                                                        name="address1"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    address1: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        City:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.city}
                                                        type="text"
                                                        placeholder="City"
                                                        name="city"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    city: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        State:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.state}
                                                        type="text"
                                                        placeholder="State"
                                                        name="state"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    state: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>                                        
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Zip Code:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.zip}
                                                        type="text"
                                                        placeholder="Zip Code"
                                                        name="zip"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    zip: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Country:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.country}
                                                        type="text"
                                                        placeholder="Country"
                                                        name="country"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    country: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>  
                                        </Row>
                                        <legend></legend>                                    
                                        <Row>
                                            <Col xs={4} sm={12} md={4}>                                           
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Subscription Date:
                                                    </div>
                                                    <InputGroup>
                                                        <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                                        <Datetime
                                                            id="subscriptionDate"
                                                            timeFormat={false}
                                                            disabled={this.state.ShowProgressBar}
                                                            closeOnSelect={true}
                                                            isValidDate={disablePastDates}
                                                            className="react-calendar"
                                                            inputProps={{ placeholder: "Start Date", style:{zIndex: 0} }}
                                                            onChange={value => this.subscriptionDateHandler(value)}
                                                            value={this.state.subscriptionDate}
                                                        />
                                                        <InputGroup.Addon></InputGroup.Addon>
                                                    </InputGroup>
                                                </FormGroup>                                            
                                            </Col>                                        
                                            <Col xs={8} sm={12} md={8}>   
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Select Subscription Plan: <span className="star">*</span>
                                                </div>
                                                <Select                                            
                                                    classNamePrefix="react-select"
                                                    name="achProfiles"
                                                    isDisabled={this.state.ShowProgressBar || this.state.IsCustomPlan}
                                                    value={this.state.plan}
                                                    onChange={value => {
                                                            this.handlePlanChanged(value)
                                                        }
                                                    }
                                                    style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}
                                                    options={this.state.plans}
                                                    placeholder="Select Plan"
                                                />
                                                {this.state.selectPlanErrorMessage}
                                                </FormGroup>
                                            </Col>                                    
                                        </Row> 
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <CustomCheckbox
                                                    name="CustomPlanCheckBox"
                                                    checked={this.state.IsCustomPlan}                                                
                                                    labelText={"Create a custom subscription"}
                                                    labelProps={{ style: { fontSize: "17px", marginTop: "4px" } }}
                                                    formControlProps={{
                                                        onChange: (e) => { this.handleCheckBoxChange(e) }                                                    
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={5}>
                                        <Row>
                                            <Col md={3}>
                                                <img src={this.state.paymentIcon} width="70px" height="42px" alt="..." />
                                            </Col>
                                            <Col md={2}>
                                            </Col>
                                            <Col md={3}>
                                                <Radio
                                                    number="creditcard"
                                                    option="creditcard"
                                                    name="radio"
                                                    onChange={this.handleRadio}
                                                    checked={this.state.IsCreditCard}
                                                    className="PrivateSwitchBase-input-538"
                                                    label="Credit Card"
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Radio
                                                    number="ach"
                                                    option="ach"
                                                    name="radio"
                                                    onChange={this.handleRadio}
                                                    checked={this.state.IsACH}
                                                    label="eCheck (ACH)"
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                            <Card
                                                content={
                                                    <div>
                                                        <div style={{ display: this.state.IsCreditCard ? "block" : "none" }}>                                                    
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} >
                                                                    <InputLabel style={styles.dropDownFontStyle}>
                                                                        Card Number
                                                                    </InputLabel>
                                                                    <div id="ccnumber" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="creditCardErrorMessage" id="creditCardErrorMessage">Card number is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel style={styles.dropDownFontStyle}>
                                                                        MM/YY
                                                                    </InputLabel>
                                                                    <div id="ccexp" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="expErrorMessage" id="expErrorMessage">Expiry date is required
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel htmlFor="simple-select" style={styles.dropDownFontStyle}>
                                                                        CVV
                                                                    </InputLabel>
                                                                    <div id="cvv" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="cvvErrorMessage" id="cvvErrorMessage">CVV date is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle}>
                                                                        Account Name
                                                                    </InputLabel>
                                                                    <div id="accountname" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNameErrorMessage" id="accountNameErrorMessage">Account name is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle}>
                                                                        Account Number
                                                                    </InputLabel>
                                                                    <div id="accountnumber" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNumberErrorMessage" id="accountNumberErrorMessage">Account number is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle}>
                                                                        Routing Number
                                                                    </InputLabel>
                                                                    <div id="routingnumber" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="routingNumberErrorMessage" id="routingNumberErrorMessage">Routing number is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        <br />                                    
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Order ID:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.orderid}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="Order ID"
                                                        name="orderid"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    orderid: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        PO Number:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.ponumber}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="PO Number"
                                                        name="ponumber"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    ponumber: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Order Description:
                                                    </div>
                                                    <FormControl
                                                        value={this.state.Subscription.order_description}
                                                        type="text"
                                                        disabled={this.state.ShowProgressBar}
                                                        placeholder="Order Description"
                                                        name="order_description"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                Subscription: {
                                                                    ...prevState.Subscription,
                                                                    order_description: value
                                                                }
                                                            }));
                                                        }
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {this.state.IsCustomPlan && 
                                        <div>    
                                            <br/>                                    
                                            <Row>  
                                                <Col md={5}> 
                                                    <Row>                                                          
                                                        <Col md={6}>                                
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "3em" }}>
                                                                Amount to charge each time: <span className="star">*</span>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>                                    
                                                                <FormControl
                                                                    value={this.state.planInfo.plan_amount}
                                                                    type="number"
                                                                    placeholder="Amount"
                                                                    name="plan_amount"
                                                                    min="0.00"
                                                                    style={{ textAlign: "center" }}
                                                                    step="0.01"
                                                                    onChange={event => {
                                                                        var value = event.target.value;
                                                                        this.setState(prevState => ({
                                                                            ...prevState,
                                                                            planInfo: {
                                                                                ...prevState.planInfo,
                                                                                plan_amount: value
                                                                            }
                                                                            }));                                                                        
                                                                        if(parseFloat(value).toFixed(2) > 0){
                                                                            this.setState({planAmountState: "valid"});
                                                                            this.setState({planAmountErrorMessage: ""});
                                                                        }
                                                                        else{
                                                                            this.setState({planAmountState: "error"});
                                                                            this.setState({planAmountErrorMessage: <small className="text-danger">
                                                                                    Invalid amount.
                                                                            </small>});
                                                                        }

                                                                        }                                                                    

                                                                    }
                                                                    onBlur={event => {
                                                                        var value = event.target.value;
                                                                        this.setState(prevState => ({
                                                                            ...prevState,
                                                                            planInfo: {
                                                                                ...prevState.planInfo,
                                                                                plan_amount: parseFloat(value).toFixed(2)  
                                                                            }
                                                                        }));                                                                                                                 
                                                                        if(parseFloat(value).toFixed(2) > 0){
                                                                            this.setState({planAmountState: "valid"});
                                                                            this.setState({planAmountErrorMessage: ""});
                                                                        }
                                                                        else{
                                                                            this.setState({planAmountState: "error"});
                                                                            this.setState({planAmountErrorMessage: <small className="text-danger">
                                                                                    Invalid amount.
                                                                            </small>})
                                                                        }
                                                                    }}
                                                                />
                                                                {this.state.planAmountErrorMessage}
                                                            </FormGroup>
                                                        </Col> 
                                                    </Row>  
                                                </Col> 
                                                <Col md={2}>
                                                    <Radio
                                                        number="dayfrequency"
                                                        option="dayfrequency"
                                                        name="FrequencyRadio"
                                                        onChange={this.handleFrequencyRadio}
                                                        checked={this.state.IsDayFrequency}
                                                        className="PrivateSwitchBase-input-538"
                                                        label="Charge the customer every"
                                                    />
                                                </Col>   
                                                <Col md={2}>
                                                    <InputGroup>
                                                        <FormControl
                                                            value={this.state.planInfo.day_frequency}
                                                            type="number"
                                                            disabled={!this.state.IsDayFrequency}                                                    
                                                            name="day_frequency"
                                                            style={{ textAlign: "center" }}
                                                            min="1"
                                                            step="1"
                                                            max="31"
                                                            onChange={event => {
                                                                var value = event.target.value;                                                            
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    planInfo: {
                                                                        ...prevState.planInfo,
                                                                        day_frequency: value
                                                                    }
                                                                }));
                                                                }
                                                            }    
                                                        />
                                                        <InputGroup.Addon>Days</InputGroup.Addon>
                                                    </InputGroup>
                                                </Col>  
                                            </Row>                                                                      
                                            <Row>
                                                <Col md={5}> 
                                                    <Row>
                                                        <Col md={6}>
                                                            <Radio
                                                                number="chargeuntilcanceled"
                                                                option="chargeuntilcanceled"
                                                                name="ChargeTimeRadio"
                                                                onChange={this.handleChargeTimeRadio}
                                                                checked={this.state.IsChargeUntilCanceled}
                                                                className="PrivateSwitchBase-input-538"
                                                                label= "Charge until canceled" 
                                                            />
                                                        </Col>                                             
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>                                    
                                                            <Radio
                                                                number="chargethecustomer"
                                                                option="chargethecustomer"
                                                                name="ChargeTimeRadio"
                                                                onChange={this.handleChargeTimeRadio}
                                                                checked={this.state.IsChargeTheCustomer}
                                                                className="PrivateSwitchBase-input-538"
                                                                label="Charge the customer"
                                                            />
                                                        </Col> 
                                                        <Col md={4}>
                                                            <InputGroup>
                                                                <FormControl
                                                                    value={this.state.planInfo.plan_payments}
                                                                    type="number"
                                                                    disabled={!this.state.IsChargeTheCustomer}
                                                                    name="plan_payments"
                                                                    style={{ textAlign: "center" }}
                                                                    min="0"
                                                                    step="1"
                                                                    onChange={event => {
                                                                        var value = event.target.value;                                                
                                                                        this.setState(prevState => ({
                                                                            ...prevState,
                                                                            planInfo: {
                                                                                ...prevState.planInfo,
                                                                                plan_payments: value
                                                                            }
                                                                        }));
                                                                        }
                                                                    }    
                                                                />
                                                                <InputGroup.Addon>Times</InputGroup.Addon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>    
                                                </Col>                                           
                                                <Col md={2}>
                                                    <Radio
                                                        number="monthfrequency"
                                                        option="monthfrequency"
                                                        name="FrequencyRadio"
                                                        onChange={this.handleFrequencyRadio}
                                                        checked={this.state.IsMonthFrequency}
                                                        label="Charge the customer on day"
                                                    />
                                                </Col>                                            
                                                <Col md={4}> 
                                                    <Row>      
                                                        <Col md={3}>                             
                                                            <FormControl
                                                                value={this.state.planInfo.day_of_month}
                                                                type="number"
                                                                disabled={!this.state.IsMonthFrequency}
                                                                name="day_of_month"
                                                                style={{ textAlign: "center" }}
                                                                min="1"
                                                                step="1"
                                                                max="31"
                                                                onChange={event => {
                                                                    var value = event.target.value;                                                                
                                                                    this.setState(prevState => ({
                                                                        ...prevState,
                                                                        planInfo: {
                                                                            ...prevState.planInfo,
                                                                            day_of_month: value
                                                                        }
                                                                    }));
                                                                    }
                                                                }    
                                                            />    
                                                        </Col>
                                                        <Col md={2}>                                     
                                                            <span style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "3em" }}>
                                                                of every
                                                            </span> 
                                                        </Col>
                                                        <Col md={3}>                                  
                                                            <FormControl
                                                                value={this.state.planInfo.month_frequency}
                                                                type="number"
                                                                disabled={!this.state.IsMonthFrequency}
                                                                name="month_frequency"
                                                                style={{ textAlign: "center" }}
                                                                min="1"
                                                                step="1"
                                                                max="24"
                                                                onChange={event => {
                                                                    var value = event.target.value;                                                    
                                                                    this.setState(prevState => ({
                                                                        ...prevState,
                                                                        planInfo: {
                                                                            ...prevState.planInfo,
                                                                            month_frequency: value
                                                                        }
                                                                    }));
                                                                    }
                                                                }    
                                                            />   
                                                        </Col>
                                                        <Col md={2}>                                     
                                                            <span style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "3em" }}>
                                                                month(s) 
                                                            </span> 
                                                        </Col>
                                                    </Row> 
                                                </Col>            
                                            </Row>                                        
                                                                    
                                        </div>  
                                        }
                                    </Col>
                                </Row>                              
                                <Row>
                                    <Col md={12}>
                                        <div style={{ textAlign: "right", marginBottom: "20px" }}>
                                            <br />
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelCreateSubscription(e)}>Cancel</Button>
                                            <Button bsStyle="primary" fill  type="submit" wd disabled={this.state.ShowProgressBar} onChange={e => console.log(e)}>Create Subscription</Button>                                       
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    }
                />                
            </div>
        );
    }
}

export default CreateSubscription;