export let Units = [
    {value:"ACR", label:"Acre"},
{value:"AMH", label:"Ampere-hour (3.6 kC)"},
{value:"AMP", label:"Ampere"},
{value:"APZ", label:"Ounce GB, US (31.10348 g)"},
{value:"ARE", label:"Are (100 m2)"},
{value:"ASM", label:"Alcoholic strength by mass"},
{value:"ASV", label:"Alcoholic strength by volume"},
{value:"ATM", label:"Standard atmosphere (101325 Pa)"},
{value:"ATT", label:"Technical atmosphere (98066.5 Pa)"},
{value:"BAR", label:"Bar"},
{value:"BFT", label:"Board foot"},
{value:"BHP", label:"Brake horse power (245.7 watts)"},
{value:"BHX", label:"Hundred boxes"},
{value:"BIL", label:"Billion EUR"},
{value:"BLD", label:"Dry barrel (115.627 dm3)"},
{value:"BLL", label:"Barrel (petroleum) (158.987 dm3)"},
{value:"BQL", label:"Becquerel"},
{value:"BTU", label:"British thermal unit (1.055 kilojoules)"},
{value:"BUA", label:"Bushel (35.2391 dm3)"},
{value:"BUI", label:"Bushel (36.36874 dm3)"},
{value:"CCT", label:"Carrying capacity in metric tonnes"},
{value:"CDL", label:"Candela"},
{value:"CEL", label:"Degree Celsius"},
{value:"CEN", label:"Hundred"},
{value:"CGM", label:"Centigram"},
{value:"CKG", label:"Coulomb per kilogram"},
{value:"CLF", label:"Hundred leaves"},
{value:"CLT", label:"Centilitre"},
{value:"CMK", label:"Square centimeter"},
{value:"CMQ", label:"Cubic centimeter"},
{value:"CMT", label:"Centimetre"},
{value:"CNP", label:"Hundred packs"},
{value:"CNT", label:"Cental GB (45.359237 kg)"},
{value:"COU", label:"Coulomb"},
{value:"CTM", label:"Metric carat (200 mg = 2.10-4 kg)"},
{value:"CUR", label:"Curie"},
{value:"CWA", label:"Hundredweight US (45.3592 kg)"},
{value:"CWI", label:"Long hundredweight GB (50.802345 kg)"},
{value:"DAA", label:"Decare"},
{value:"DAD", label:"Ten days"},
{value:"DAY", label:"Day"},
{value:"DCP", label:"Dozen pieces"},
{value:"DLT", label:"Decilitre"},
{value:"DMK", label:"Square decimeter"},
{value:"DMQ", label:"Cubic decimeter (dm3)"},
{value:"DMT", label:"Decimetre"},
{value:"DPT", label:"Displacement tonnage"},
{value:"DRA", label:"Dram US (3.887935 g)"},
{value:"DRI", label:"Dram GB (1.771745 g)"},
{value:"DRL", label:"Dozen rolls"},
{value:"DRM", label:"Drachm GB (3.887935 g)"},
{value:"DTH", label:"Hectokilogram"},
{value:"DTN", label:"Quintal, metric (100 kg)"},
{value:"DWT", label:"Pennyweight GB, US (1.555174 g)"},
{value:"DZN", label:"Dozen"},
{value:"DZP", label:"Dozen packs"},
{value:"DZR", label:"Dozen pairs"},
{value:"EAC", label:"Each"},
{value:"FAH", label:"Degree Fahrenheit"},
{value:"FAR", label:"Farad"},
{value:"FOT", label:"Foot (0.3048 m)"},
{value:"FTK", label:"Square foot"},
{value:"FTQ", label:"Cubit foot"},
{value:"GBQ", label:"Gigabecquerel"},
{value:"GFI", label:"Gram of fissile isotopes"},
{value:"GGR", label:"Great gross (12 gross)"},
{value:"GIA", label:"Gill (11.8294 cm3)"},
{value:"GII", label:"Gill (0.142065 dm3)"},
{value:"GLD", label:"Dry gallon (4.404884 dm3)"},
{value:"GLI", label:"Gallon (4.546092 dm3)"},
{value:"GLL", label:"Liquid gallon (3.78541 dm3)"},
{value:"GRM", label:"Gram"},
{value:"GRN", label:"Grain GB, US (64.798910 mg)"},
{value:"GRO", label:"Gross"},
{value:"GRT", label:"Gross (register) ton"},
{value:"GWH", label:"Gigawatt-hour (1 million kW/h)"},
{value:"HAR", label:"Hectare"},
{value:"HBA", label:"Hectobar"},
{value:"HGM", label:"Hectogram"},
{value:"HIU", label:"Hundred international units"},
{value:"HLT", label:"Hectolitre"},
{value:"HMQ", label:"Million cubic metres"},
{value:"HMT", label:"Hectometre"},
{value:"HPA", label:"Hectolitre of pure alcohol"},
{value:"HTZ", label:"Hertz"},
{value:"HUR", label:"Hour"},
{value:"INH", label:"Inch (25.4 mm)"},
{value:"INK", label:"Square inch"},
{value:"INQ", label:"Cubic inch"},
{value:"JOU", label:"Joule"},
{value:"KBA", label:"Kilobar"},
{value:"KEL", label:"Degree Kelvin"},
{value:"KGM", label:"Kilogram"},
{value:"KGS", label:"Kilogram per second"},
{value:"KHZ", label:"Kilohertz"},
{value:"KJO", label:"Kilojoule"},
{value:"KMH", label:"Kilometre per hour"},
{value:"KMK", label:"Square kilometer"},
{value:"KMQ", label:"Kilogram per cubic meter"},
{value:"KMT", label:"Kilometre"},
{value:"KNI", label:"Kilogram of nitrogen"},
{value:"KNS", label:"Kilogram of named substance"},
{value:"KNT", label:"Knot (1 nautical mile per hour)"},
{value:"KPA", label:"Kilopascal"},
{value:"KPH", label:"Kilogram of potassium hydroxide"},
{value:"KPO", label:"Kilogram of potassium oxide"},
{value:"KPP", label:"Kilogram of phosphorus pentoxide"},
{value:"KSD", label:"Kilogram of substance 90% dry"},
{value:"KSH", label:"Kilogram of sodium hydroxide"},
{value:"KTN", label:"Kilotonne"},
{value:"KUR", label:"Kilogram of uranium"},
{value:"KVA", label:"Kilovolt-ampere"},
{value:"KVR", label:"Kilovar"},
{value:"KVT", label:"Kilovolt"},
{value:"KWH", label:"Kilowatt-hour"},
{value:"KWT", label:"Kilowatt"},
{value:"LBR", label:"Pound GB, US (0.45359237 kg)"},
{value:"LEF", label:"Leaf"},
{value:"LPA", label:"Litre of pure alcohol"},
{value:"LTN", label:"Long ton GB, US (1.0160469 t)"},
{value:"LTR", label:"Litre (1 dm3)"},
{value:"LUM", label:"Lumen"},
{value:"LUX", label:"Lux"},
{value:"MAL", label:"Megalitre"},
{value:"MAM", label:"Megametre"},
{value:"MAW", label:"Megawatt"},
{value:"MBE", label:"Thousand standard brick equivalent"},
{value:"MBF", label:"Thousand board feet (2.36 m3)"},
{value:"MBR", label:"Millibar"},
{value:"MCU", label:"Millicurie"},
{value:"MGM", label:"Milligram"},
{value:"MHZ", label:"Megahertz"},
{value:"MIK", label:"Square mile"},
{value:"MIL", label:"Thousand"},
{value:"MIN", label:"Minute"},
{value:"MIO", label:"Million"},
{value:"MIU", label:"Million international units"},
{value:"MLD", label:"Milliard"},
{value:"MLT", label:"Millilitre"},
{value:"MMK", label:"Square millimeter"},
{value:"MMQ", label:"Cubic millimeter"},
{value:"MMT", label:"Millimetre"},
{value:"MON", label:"Month"},
{value:"MPA", label:"Megapascal"},
{value:"MQH", label:"Cubic metre per hour"},
{value:"MQS", label:"Cubic metre per second"},
{value:"MSK", label:"Metre per second squared"},
{value:"MTK", label:"Square metre"},
{value:"MTQ", label:"Cubic metre"},
{value:"MTR", label:"Metre"},
{value:"MTS", label:"Metre per second"},
{value:"MVA", label:"Megavolt-ampere (1000 KVA)"},
{value:"MWH", label:"Megawatt-hour (100 kW/h)"},
{value:"NAR" , label:"Number of articles"},
{value:"NCL", label:"Number of cells"},
{value:"NEW", label:"Newton"},
{value:"NIU", label:"Number of international units"},
{value:"NMB", label:"Number"},
{value:"NMI", label:"Nautical mile (1852 m)"},
{value:"NMP", label:"Number of packs"},
{value:"NMR", label:"Number of pairs"},
{value:"NPL", label:"Number of parcels"},
{value:"NPT", label:"Number of parts"},
{value:"NRL", label:"Number of rolls"},
{value:"NTT", label:"Net (register) ton"},
{value:"OHM", label:"Ohm"},
{value:"ONZ", label:"Ounce GB, US (28.349523 g)"},
{value:"OZA", label:"Fluid ounce (29.5735 cm3)"},
{value:"OZI", label:"Fluid ounce (28.413 cm3)"},
{value:"PAL", label:"Pascal"},
{value:"PCE", label:"Piece"},
{value:"PGL", label:"Proof gallon"},
{value:"PTD", label:"Dry pint (0.55061 dm3)"},
{value:"PTI", label:"Pint (0.568262 dm3)"},
{value:"PTL", label:"Liquid pint (0.473176 dm3)"},
{value:"QAN", label:"Quarter (of a year)"},
{value:"QTD", label:"Dry quart (1.101221 dm3)"},
{value:"QTI", label:"Quart"},
{value:"QTL", label:"Liquid quart (0.946353 dm3)"},
{value:"QTR", label:"Quarter, GB (12.700586 kg)"},
{value:"RPM", label:"Revolution per minute"},
{value:"RPS", label:"Revolution per second"},
{value:"SAN", label:"Half year (six months)"},
{value:"SCO", label:"Score"},
{value:"SCR", label:"Scruple GB, US (1.295982 g)"},
{value:"SEC", label:"Second"},
{value:"SET", label:"Set"},
{value:"SHT", label:"Shipping ton"},
{value:"SIE", label:"Siemans"},
{value:"SMI", label:"Statute) mile (1609.344 m)"},
{value:"SST", label:"Short standard"},
{value:"STI", label:"Stone GB (6.350293 kg)"},
{value:"STN", label:"Short ton GB, US (0.90718474 t)"},
{value:"TAH", label:"Thousand ampere-hour"},
{value:"TDK", label:"Square yard"},
{value:"TNE", label:"Tonne (1000 kg)"},
{value:"TPR", label:"Ten pairs"},
{value:"TQD", label:"Thousand cubic metres per day"},
{value:"TSH", label:"Ton of steam per hour"},
{value:"WCD", label:"Cord (3.63 m3)"},
{value:"WSD", label:"Standard"},
{value:"YDQ", label:"Cubic yard"},
]