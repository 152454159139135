import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Media, 
  FormGroup,
  ControlLabel,
  FormControl,
  Alert
} from "react-bootstrap";

import Card from "components/Card/LoginCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum, SoftwareTypeEnum } from "utils/constant.js";
import NetworkService from "services/NetworkService";
import Sign_in_intuit_default from "assets/img/buttons/Sign_in_intuit_default.png";
import Sign_in_intuit_hover from "assets/img/buttons/Sign_in_intuit_hover.png";
import ConnectToIntuitSSOButton from "views/Components/QuickbooksSSO.jsx";
import Common from "utils/Common.js";

global.successfullRegistration = false;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      errorCode: 1,
      ShowProgressBar: false,
      email: "",
      password: "",
      emailClassName: "",
      passwordClassName: "",
      emailErrorLogin: null,
      passwordErrorLogin: null,
      IsForgotPassword: false,
      IsForgotPasswordLinkSent: false,
      permissions: {},
      intuitButton: Sign_in_intuit_default,
    };
  }

  linkClick(e, name) {
    if (name == "ForgotPassword") {
      this.setState({ IsForgotPassword: true });
    }
    else if(name == "CustomerPortalLogin"){
      window.location.href = BASE_URL + "#/customer/login";
      e.preventDefault();
    }
    else {
      this.setState({ IsForgotPassword: false });
    }
    this.setState({ emailClassName: "" });
    this.setState({ passwordClassName: "" });
    this.setState({ emailErrorLogin: null });
    this.setState({ passwordErrorLogin: null });
    e.preventDefault();
  }

  registrationClick(e) {
    this.props.history.push('/account/registration');
    e.preventDefault();
  }

  resellerRegistrationClick(e) {
    this.props.history.push('/account/resellerregistration');
    e.preventDefault();
  }

  forgotPasswordSubmitClick = (event) => {
    if (this.isValidated()) {
      this.setState({ ShowProgressBar: true });
      localStorage.clear();
      this._postForgotPassword({ "email": this.state.email });
    }
  }

  componentDidMount() {
    document.getElementById("loaderDiv").style.display = "none";
    localStorage.clear();
  }

  handleLoginEmail(event) {
    this.setState({
      email: event.target.value
    });
    if(event.target.value == "")
    {
      this.setState({
        emailErrorLogin: (Common.getRequiredEmailErrorMessage()),
        emailClassName: "error"
      });
    }
    else if (Common.getEmailRegex().test(event.target.value) === false) {
      this.setState({
        emailErrorLogin: (Common.getInvalidEmailErrorMessage()),
        emailClassName: "error"
      });
    }
    else {
      this.setState({ emailErrorLogin: null, emailClassName: "valid" });
    }
  }

  handleLoginPassword(event) {
    this.setState({
      password: event.target.value
    });
    this.setState({ passwordErrorLogin: null });
  }

  handleLoginSubmit() {
    if (this.isValidated()) {
      this.setState({ ShowProgressBar: true });
      localStorage.clear();
      this.postLogin(this.state);  
    }
    else {
      console.log('invalid email or password');
    }
  }

  handleIntuitLoginSubmit(e) {
    this.setState({ ShowProgressBar: true });
    localStorage.clear();
    this._getIntuitLoginRequest();
  }

  isValidated() {
    let isValid = true;
    if(this.state.email == "" ){
      this.setState({
          emailErrorLogin: (Common.getRequiredEmailErrorMessage()),
          emailClassName: "error"
        });
        isValid = false;
    }
    else if (Common.getEmailRegex().test(this.state.email) === false) {
       this.setState({
          emailErrorLogin: (Common.getInvalidEmailErrorMessage()),
          emailClassName: "error"
        });
      isValid = false;
    }
    else {
      this.setState({ emailErrorLogin: null });
    }

    if (this.state.IsForgotPassword === false) {
      var passwordRE = new RegExp(Common.getPasswordRegex());
      if (passwordRE.test(this.state.password) === false) {
        this.setState({passwordErrorLogin: Common.getPasswordErrorMessage()});
        isValid = false;
      }
      else{
        this.setState({ passwordErrorLogin: null });
      }     
    }
    else {
      this.setState({ passwordErrorLogin: null });
    }

    return isValid;
  }

  handleKeyPressInput(e) { //= event => {
    var charCode = (e.which) ? e.which : e.keyCode;
    //alert(charCode);
    //97-122, 32,65-90'
    if (charCode === 13 && this.isValidated()) {
      if (this.state.IsForgotPassword === true) {
        this.forgotPasswordSubmitClick();
      }
      else {
        this.handleLoginSubmit();
      }
    }
  };

  setResult(data) {
    let uuid = data.access_token;
    let full_name = data.full_name;
    //  global.SuccessfulLoggedIn = true;
    let processor = data.processor;
    let tokenize_key = null;
    let permissions = data.permissions;
    if (processor != null && Object.keys(processor).length != 0) {
      if (processor.ProcessorType == "nmi") {
        tokenize_key = JSON.stringify({ "PPType": processor.ProcessorType, "PPToken": processor.ProcessorConfig.TokenizationKey })
        // let gatewayJS = document.createElement("script");
        // gatewayJS.src = "https://secure.networkmerchants.com/js/v1/Gateway.js";
        // gatewayJS.async = true;        
        // document.head.appendChild(gatewayJS); 
      } else if (processor.ProcessorType == "cardconnect") {
        tokenize_key = JSON.stringify({ "PPType": processor.ProcessorType, "PPToken": processor.ProcessorConfig.URL })
      }
      localStorage.setItem("is_ach_enabled", processor.ProcessorConfig.supported_methods.ach);
      localStorage.setItem("is_creditcard_enabled", processor.ProcessorConfig.supported_methods.creditcard);
      localStorage.setItem("is_debitcard_enabled", processor.ProcessorConfig.supported_methods.debitcard);
      localStorage.setItem("is_quickclick_enabled", processor.ProcessorConfig.supported_methods.quickclick);      
      localStorage.setItem("is_threestep_enabled", processor.ProcessorConfig.supported_methods.threestep)
    }
    let software_type = data.software_type != null && data.software_type.value != null ? data.software_type.value : 1;
    let is_connected = data.software_type != null && data.software_type.is_connected != null ? data.software_type.is_connected : false;
    if (software_type == 1) {
      localStorage.setItem("is_qbo_connected", is_connected);
    }
    else if (software_type == 4) {
      localStorage.setItem("is_nmiinvoicing_connected", is_connected);
    }
    else if (software_type == 5) {
      localStorage.setItem("is_standalone_connected", is_connected);
    }
    localStorage.setItem(StorageEnum.UUID, uuid);
    localStorage.setItem(StorageEnum.FULLNAME, full_name);
    localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
    localStorage.setItem(StorageEnum.SOFTWARE_TYPE, software_type);
    localStorage.setItem(StorageEnum.APPID, data.app_id);
    let login_ref = data.type;
    localStorage.setItem("login_user_type", login_ref.name);
    //Need to add below from server wit same response..
    // let permissions = JSON.stringify({Permissions: 
    //   {
    //     invoices:[{createinvoice: true},
    //                 {sendemail: true},
    //                 {sendsms: true},
    //                 {collectpayment: true}],
    //     salesreceipt:[{createsalesreceipt: true}],
    //     payments:[{viewpayments: true}],
    //     //accountingpackage:[{connection: true}],
    //     recurring:[{createrecurring:true}]
    //   }});   
    localStorage.setItem(StorageEnum.PERMISSIONS, JSON.stringify(permissions));
  }

  /** API'S CALLING */
  // POST request of login
  postLogin = (postData) => {
    this.setState({ errorMessage: "" });
    let url = BASE_URL + "api/v1/account/login/";
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "email": postData.email,
        "password": postData.password
      })
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data != null) {
          this.setState({ errorMessage: data.statusmessage });         
          this.setState({ ShowProgressBar: false });

          if (data.statuscode === 0) {
            this.setState({ errorCode: data.statuscode });
            let login_ref = data.data.type;
            if ((login_ref.value == 3 && login_ref.name == "Merchant") || (login_ref.value == 5 && login_ref.name == "Staff")) {
              this.setResult(data.data);
              let softwareType = localStorage.getItem(StorageEnum.SOFTWARE_TYPE);
              if (softwareType == SoftwareTypeEnum.QuickBooksOnline) {
                if (localStorage.getItem("is_qbo_connected") == "false") {
                  this.props.history.push('/invoice/accountingpackage');
                }
                else {
                  this.props.history.push('/invoice/dashboard');
                }
              }
              else if (softwareType == SoftwareTypeEnum.QuickBooksDesktop) {
                this.props.history.push('/qbfinancial/dashboard');
              }
              else if (softwareType == SoftwareTypeEnum.QuickBooksPOS) {
                this.props.history.push('/qbpos/dashboard');
              }
              else if (softwareType == SoftwareTypeEnum.NMIInvoicing) {
                if (localStorage.getItem("is_nmiinvoicing_connected") == "false") {
                  this.props.history.push('/nmi/accountingpackage');
                }
                else {
                  this.props.history.push('/nmi/invoices');
                }
              }
              //this.props.history.push('/account/home');
            }
            else if (login_ref.value == 2 && login_ref.name == "Reseller") {
              let uuid = data.data.access_token;
              let full_name = data.data.full_name;
              let processor = data.data.processor;
              localStorage.setItem(StorageEnum.UUID, uuid);
              localStorage.setItem(StorageEnum.FULLNAME, full_name);
              if (processor != null && Object.keys(processor).length != 0) {
                localStorage.setItem("ProcessorType", processor.ProcessorType);
              }
              localStorage.setItem("login_user_type", "Reseller");

              this.props.history.push('/reseller/transactions');
            }
            else if (login_ref.value == 4 && login_ref.name == "Admin") {
              let uuid = data.data.access_token;
              let full_name = data.data.full_name;

              //  global.SuccessfulLoggedIn = true;
              localStorage.setItem(StorageEnum.UUID, uuid);
              localStorage.setItem(StorageEnum.FULLNAME, full_name);
              localStorage.setItem("login_user_type", "Admin");
              this.props.history.push('/admin/transactions');
            }
            // else if (login_ref.value == 6 && login_ref.name == "Customer") {
            //   this.setResult(data.data);
            //   let customer_ref = data.data.customer_ref || null;
            //   const customer_id = customer_ref ? customer_ref.value : null;
            //   const customer_name = customer_ref ? customer_ref.name : null;

            //   localStorage.setItem(StorageEnum.CUSTOMER_ID, customer_id);
            //   localStorage.setItem(StorageEnum.CUSTOMER_NAME, customer_name);

            //   this.props.history.push('/customerportal/dashboard');
            // }
            else {
              this.setState({ errorCode: 1 });
              this.setState({ errorMessage: "Invalid login credentials" });
            }
          } else {
            this.setState({ errorCode: 1 });
            this.setState({ errorMessage: "Invalid login credentials" });
          }
        }
      })
      .catch(error => {
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        this.setState({ ShowProgressBar: false });
      });;
  }

  // POST request of forget password
  _postForgotPassword = (data) => {
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });

    NetworkService.postForgetPasswordRequest(data)
      .then(data => {
        this.setState({ errorMessage: data.statusmessage });
        this.setState({ errorCode: data.statuscode });
        this.setState({ ShowProgressBar: false });

        if (data.statuscode === 0) {
          this.setState({ isValidUser: true });
          this.setState({ IsForgotPasswordLinkSent: true });
        } else {
          this.setState({ isValidUser: false });
          this.setState({ IsForgotPasswordLinkSent: false });
          //setTimeout(this.closeMessage, 2000);  
        }
      }).catch(error => {
        this.setState({ isValidUser: false });
        this.setState({ IsForgotPasswordLinkSent: false });
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        this.setState({ ShowProgressBar: false });
      });
  }

  // POST request of Intuit login
  _getIntuitLoginRequest = () => {
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });

    NetworkService.getIntuitLoginRequest()
      .then(data => {
        // this.setState({ errorMessage: data.statusmessage });
        // this.setState({ errorCode: data.statuscode });
        this.setState({ ShowProgressBar: false });

        window.parent.location.href=data.url;
        // if (data.statuscode === 0) {
        //   this.setState({ isValidUser: true });
        //   this.setState({ IsForgotPasswordLinkSent: true });
        // } else {
        //   this.setState({ isValidUser: false });
        //   this.setState({ IsForgotPasswordLinkSent: false });
        //   //setTimeout(this.closeMessage, 2000);  
        // }
      }).catch(error => {
        this.setState({ isValidUser: false });
        this.setState({ IsForgotPasswordLinkSent: false });
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        this.setState({ ShowProgressBar: false });
      });
  }

  render() {
    const customButtonStyle = {
      width: "100%",
      marginBottom: "20px"      
    };
    const customLinkStyle = {
      color: "#0075c1",
      fontSize: "16px",
    }
    const customTextColor = {
      color: "#0075c1",
    };
    return (
      <Grid>
        <br/>
        {global.successfullRegistration &&
          <div>            
            <Row>
              <Col md={10} mdOffset={1}>
                <br /><br />
                <Alert bsStyle="success">                  
                  <span>
                    Account created successfully, a verification email has been sent to your email address. Please verify and login.
                    </span>
                </Alert>
              </Col>
            </Row>
          </div>
        }
        <Row>
        {/* */}
          {/* <Col md={6} sm={6}>
            <div>
              <a style={customLinkStyle} href="#" target="_blank" onClick={e => this.resellerRegistrationClick(e, "Registration")}>Reseller Signup</a>                         
            </div>
          </Col> */}          
          <Col md={6} sm={6}  mdOffset={3} smOffset={3}>
            <form>
              {this.state.ShowProgressBar &&
                <div>
                  <br /><br />
                  <CustomProgressBar></CustomProgressBar>
                </div>
              }
              {this.state.errorMessage != "" &&
                <div>
                  <br /><br />
                  <Alert bsStyle={this.state.errorCode == "0" || this.state.errorCode == 0 ? "success" : "danger"}>
                    <span>
                      {this.state.errorMessage}
                    </span>
                  </Alert>
                </div>
              }
              <Card
                textLeft
                title={this.state.IsForgotPassword === false ? "Merchant/Reseller - Sign In" : "Forgot Password"}
                
                category={this.state.IsForgotPassword === false ? "Sign in with your Instant Invoice Account " : "Enter email to recover your password"}                
                content={
                  <div>
                    {this.state.IsForgotPassword === false &&
                      <div>
                        <FormGroup>
                          <ControlLabel>Email address <span className="star">*</span></ControlLabel>
                          <FormControl
                            value={this.state.email}
                            placeholder="Enter email"
                            type="email"
                            className={this.state.emailClassName}
                            // onChange={event => this.handleLoginEmail(event)}
                            onChange={event => {
                              this.setState({ email: event.target.value });
                              this.setState({ emailErrorLogin: null });
                              this.setState({ emailClassName: "" });
                            }
                            }
                            onBlur={event => this.handleLoginEmail(event)}
                            onKeyPress={event => this.handleKeyPressInput(event)}
                          />
                          {this.state.emailErrorLogin}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>Password  <span className="star">*</span></ControlLabel>
                          <FormControl
                            value={this.state.password}
                            placeholder="Password"
                            type="password" autoComplete="off"
                            className={this.state.passwordClassName}
                            onChange={event => this.handleLoginPassword(event)}
                            onBlur={event => this.handleLoginPassword(event)}
                            onKeyPress={event => this.handleKeyPressInput(event)}
                          />
                          {this.state.passwordErrorLogin}
                        </FormGroup>
                      </div>}
                    {this.state.IsForgotPassword === true &&
                      <div>
                        <FormGroup>
                          <ControlLabel>Email address <span className="star">*</span></ControlLabel>
                          <FormControl
                            value={this.state.email}
                            placeholder="Enter email"
                            type="email"
                            className={this.state.emailClassName}
                            // onChange={event => this.handleLoginEmail(event)}
                            onChange={event => {
                              this.setState({ email: event.target.value });
                              this.setState({ emailErrorLogin: null });
                              this.setState({ emailClassName: "" });
                            }
                            }
                            onBlur={event => this.handleLoginEmail(event)}
                            onKeyPress={event => this.handleKeyPressInput(event)}
                          />
                          {this.state.emailErrorLogin}
                        </FormGroup>
                      </div>
                    }
                  </div>


                }
                legend={
                  <div>
                    {this.state.IsForgotPassword === false &&
                      <div>
                        <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleLoginSubmit.bind(this)}>
                          SIGN IN
                        </Button>
                        <br />
                       
                        {/* <img alt="SignIn Intuit"
                          style={{ marginRight: "10px", height: "26px" }}
                          src={this.state.intuitButton}
                          onMouseEnter={() => { this.setState({ intuitButton: Sign_in_intuit_hover }) }}
                          onMouseOut={() => { this.setState({ intuitButton: Sign_in_intuit_default }) }}
                          onMouseDown={() => { this.setState({ intuitButton: Sign_in_intuit_hover }) }}
                          onMouseUp={() => { this.setState({ intuitButton: Sign_in_intuit_default }) }}
                          onClick={(e) => { this.handleIntuitLoginSubmit() }}
                        /> */}
                        {/* <a style={customLinkStyle} href="#" target="_blank" onClick={e => this.linkClick(e, "ForgotPassword")} > Forgot Password?</a> */}
                        <Button bsStyle="primary" style={{width:"48%", marginRight:"10px"}} onClick={e => this.linkClick(e, "ForgotPassword")}>
                          Forgot Password?
                        </Button>
                        {/* <span style={customLinkStyle}>  |  </span>  */}
                        {/* <a style={customLinkStyle} href="#" target="_blank" onClick={e => this.linkClick(e, "CustomerPortalLogin")} > Customer Portal Login</a> */}
                        <Button bsStyle="primary" style={{width:"48%"}} onClick={e => this.linkClick(e, "CustomerPortalLogin")}>
                          Customer Portal Login
                        </Button>
                        {/* <ConnectToIntuitSSOButton></ConnectToIntuitSSOButton> */}
                        {/* <span style={customLinkStyle}>  |  </span>  */}
                        {/* <a style={customLinkStyle} href="#" target="_blank" onClick={e => this.registrationClick(e, "Registration")}> Register Online</a>                          */}
                      </div>
                    }
                    {this.state.IsForgotPassword === true &&
                      <div>
                        <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.forgotPasswordSubmitClick}>
                          SUBMIT
                       </Button>
                        <br />
                        <a style={customLinkStyle} href="#" id="BackToLogin" value="BackToLogin" onClick={e => this.linkClick(e, "BackToLogin")}>
                          Back to Login
                            </a>
                      </div>
                    }
                  </div>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={2}>
          </Col>
          <Col md={4}>            
              <Media style={customTextColor}>
                <Media.Left>
                  <div className="icon" style={{ fontSize: 30, marginRight: 30 }}>
                    <i className="fa fa-users" />
                  </div>
                </Media.Left>
                <Media.Body>
                  <Media.Heading ><strong> Merchant Sign Up</strong></Media.Heading>
                      Don't have an Instant Invoice account? Sign up today with your email or Intuit account.
                      <br/><br/>
                     <Button bsStyle="primary" fill onClick={e => this.registrationClick(e, "Registration")}>
                        Sign Up Today!
                      </Button> 
                      <img alt="SignIn Intuit"
                          style={{ marginLeft: "5px", height: "37.5px" }}
                          src={this.state.intuitButton}
                          onMouseEnter={() => { this.setState({ intuitButton: Sign_in_intuit_hover }) }}
                          onMouseOut={() => { this.setState({ intuitButton: Sign_in_intuit_default }) }}
                          onMouseDown={() => { this.setState({ intuitButton: Sign_in_intuit_hover }) }}
                          onMouseUp={() => { this.setState({ intuitButton: Sign_in_intuit_default }) }}
                          onClick={(e) => { this.handleIntuitLoginSubmit() }}
                        />                   
                </Media.Body>
              </Media>
          </Col>
          <Col md={4} >  
            <Media style={customTextColor}>
              <Media.Left>
                <div className="icon" style={{ fontSize: 30, marginRight: 30 }}>
                  <i className="fa fa-handshake-o" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading><strong>Reseller/ISO Sign Up</strong></Media.Heading>
                    Not a registered Reseller yet? Click here and you can board your own merchants.
                    <br/><br/>
                    <Button bsStyle="primary" fill onClick={e => this.resellerRegistrationClick(e, "Registration")}>
                        Sign Up Today!
                    </Button> 
              </Media.Body>
            </Media>  
           

          </Col>
          <Col md={2}>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Login;