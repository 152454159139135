/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Online/Dashboard.jsx";
import Settings from "views/Online/Settings.jsx";
import Payments from "views/Online/Payments.jsx";
import Invoices from "views/Online/Invoices.jsx";
import Recurring from "views/Online/Recurring.jsx";
import SalesReceipt from "views/Online/CreateSalesReceipt";
import CreateInvoice from "views/Online/CreateInvoice.jsx";
import CreateRecurring from "views/Online/CreateRecurring.jsx";
import EditInvoice from "views/Online/EditInvoice.jsx";
import AuthNetPayment from "views/Online/AuthNetPayment.jsx";
import NMIPayment from "views/Online/NMIPayment.jsx";
import CCPayment from "views/Online/CCPayment.jsx";
import invoiceDetail from "views/Online/Detail.jsx";
import AccountingPackage from "views/Online/AccountingPackage.jsx";
import CustomersAndProducts from "views/Online/CustomersAndProducts.jsx";

var InvoiceRoutes = [
  {
    path: "/dashboard",
    layout: "/invoice",
    name: "Dashboard",
    icon: "fa fa-dashboard",
    component: Dashboard
  },
  {
    path: "/invoices",
    layout: "/invoice",
    name: "Invoices",
    icon: "fa fa-newspaper-o",
    component: Invoices
  },
  {
    path: "/authnetpayment",    
    name: "payment",
    component: AuthNetPayment,
    layout: "/invoice"
  },
  {
    path: "/nmipayment",    
    name: "payment",
    component: NMIPayment,
    layout: "/invoice"
  },
  {
    path: "/ccpayment",    
    name: "payment",
    component: CCPayment,
    layout: "/invoice"
  },
  {
    path: "/detail",
    name: "invoice detail",
    component: invoiceDetail,
    layout: "/invoice"
  },
  {
    path: "/recurring",
    layout: "/invoice",
    name: "Recurring",
    icon: "fa fa-refresh",
    component: Recurring
  },
  {
    path: "/create",    
    name: "Create Invoice",
    mini: "RP",
    component: CreateInvoice,
    layout: "/invoice"
  },
  {
    path: "/edit",    
    name: "Edit Invoice",
    mini: "RP",
    component: EditInvoice,
    layout: "/invoice"
  },
  {
    path: "/createrecurring",    
    name: "Create Recurring",
    mini: "RP",
    component: CreateRecurring,
    layout: "/invoice"
  },
  {
    path: "/salesreceipt",
    layout: "/invoice",
    name: "Sales Receipt",
    icon: "fa fa-file-text-o",
    component: SalesReceipt
  },
  {
    path: "/payments",
    layout: "/invoice",
    name: "Payments",
    icon: "fa fa-money",
    component: Payments
  },
  {
    path: "/customersproducts",
    layout: "/invoice",
    name: "Customers & Products",
    icon: "fa fa-users",
    component: CustomersAndProducts
  },
  {
    path: "/settings",
    layout: "/invoice",
    name: "Settings",
    icon: "fa fa-gears",
    component: Settings
  },
  {
    path: "/accountingpackage",
    layout: "/invoice",
    name: "accounting package",
    icon: "fa fa-cubes",
    component: AccountingPackage
  }
  
];

export default InvoiceRoutes;
