import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,

} from "react-bootstrap";
import MaterialTable, { MTableToolbar } from 'material-table';
import Datetime from "react-datetime";
import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import NetworkService from "services/NetworkService";
// import ReactToPrint from 'react-to-print';

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            invoiceStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                { value: "all", label: "All" },
                { value: "open", label: "Open" },
                { value: "past_due", label: "Past Due" },
                { value: "paid", label: "Paid" },
                { value: "close", label: "Close" },
            ],
            filter_invoice: { value: "all", label: "All" },
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            showSubscriptionSendButton: "none",
            showSendSubscriptionModal: false,
            errorSendSubscriptionEmail: "",
            sendSubscriptionClassName: "",
            customerEmail: "ahsan766@gmail.com",
            rawInvoiceData: [],
            selectedInvoiceIds: "",
            data: [
            ],
            columns: [
                {
                    title: 'Customer',
                    field: 'customer',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(30%)'
                    },
                    cellStyle: {
                        width: 'calc(30%)'
                    },
                },
                {
                    title: 'Subscription ID',
                    field: 'sub_id',
                    headerStyle: {
                        textAlign: "left",                      
                    },
                },
                {
                    title: 'Charge Amount ($)',
                    field: 'charge_amt',
                    cellStyle: {
                       width: 'calc(18%)',
                       textAlign: 'center'
                    }, 
                },
                {
                    title: 'Completed',
                    field: 'completed_payment',
                    cellStyle: {
                       textAlign: 'center'
                    },
                },
                {
                    title: 'Attempted',
                    field: 'attempted_payment',
                    cellStyle: {
                       textAlign: 'center'
                    },
                },
                {
                    title: 'Remaining',
                    field: 'remaining_payment',
                    cellStyle: {
                       textAlign: 'center'
                    },
                },
                {
                    title: 'Charging At',
                    field: 'charged_at',
                    cellStyle: {
                        width: 'calc(25%)',
                       textAlign: 'center'
                    },
                },
                // {
                //     title: 'Action',
                //     field: 'action',
                //     cellStyle: {
                //         width: 'calc(5%)'
                //     },
                // }
            ],
            options: {
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                selection: false,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"                   
                }),
            },
            createInvoiceToolTip: <Tooltip id="createInvoiceToolTip">Create Subscription</Tooltip>,
            sendInvoiceToolTip: <Tooltip id="sendInvoiceToolTip">Send Subscription</Tooltip>,
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={this.state.createInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateSubscription(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={this.state.sendInvoiceToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleSendSubscription(e)} style={{ marginRight: '10px', display: this.state.showSubscriptionSendButton }}> <i className="fa fa-envelope" ></i> </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
            },
            actions: [
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editSubscriptionToolTip">Edit</Tooltip>}>
                //         <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => { console.log(rowData, event) }
                // },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="deleteSubscriptionToolTip">Delete</Tooltip>}>
                        <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => {this.handleDeleteSubscriptionPopup(rowData, event)}
                }
            ],
            singleEmailInvoiceData: {
                customerName: "",
                customerEmail: "",
                invoiceNumber: "",
                balance: "",
                index: ""
            },
            selectedInvoiceIndex: [],
            showDeleteSubscriptionPopupModal: false,
            deletedSubscription:{},
        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.SetFromToDates();
        this.filterSubscriptions();
        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    /** HANDLER */
    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.filterSubscriptions();
    }

    handleCreateSubscription(e) {
        this.props.history.push('/nmi/createsubscription');
        e.preventDefault();
    }

    handleSendSubscription(e) {
        e.preventDefault();
        let invoice = {};
        this.state.selectedInvoiceIndex.map(index => {
            invoice = this.state.rawInvoiceData[index];
            const x = {
                customerName: invoice.CustomerRef.name,
                customerEmail: invoice.BillEmail ? invoice.BillEmail.Address : "",
                invoiceNumber: invoice.DocNumber,
                balance: invoice.Balance,
            }
            this.setState({ singleEmailInvoiceData: x })
        })
        this.setState({ showSendSubscriptionModal: true })

    }

    handleEditSubscription(e){

    }

    handleDeleteSubscriptionPopup(subscription, e) {
       console.log(subscription);
       this.setState({deletedSubscription : subscription});
       this.setState({ showDeleteSubscriptionPopupModal: true });
    }
    
    handleDeleteSubscriptionSubmit(subscription){
        const payload = {"index": subscription.index, "subscription_id": subscription.sub_id}
        // alert(rowVal.sub_id);
        this._postDeleteRecurringSubscriberRequest(payload)
    }

    filterSubscriptions = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getSubscribePlanDetailRequest(query);
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            singleEmailInvoiceData: {
                ...prevState.singleEmailInvoiceData,
                customerEmail: value
            }
        }));
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendSubscriptionEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )

            });
            this.setState({
                sendSubscriptionClassName: "error"
            });

        }
        else {
            this.setState({ errorSendSubscriptionEmail: null });
            this.setState({ sendSubscriptionClassName: "valid" });
        }
    }

    handleSubscriptionDetailClick(invoice) {
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        this.props.history.push('/invoice/detail/' + invoice.Id);
    }

    handleSelectedRowData(data, length) {
        let newStates = [];
        let invoiceIds = "";
        data.map(d => {
            newStates.push(d.index);
            invoiceIds = invoiceIds + "," + d.id;
        });
        this.state.selectedInvoiceIndex = newStates;
        this.state.selectedInvoiceIds = this.replaceAt(invoiceIds, 0, '');

    }

    /** API CALLING */

    // GET request for invoice details

    _getSubscribePlanDetailRequest(params = "") {
        this.setState({ ShowProgressBar: true });       

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);

        NetworkService.getRecurringSubscriberListRequest(app_id, params)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });                    
                    if (data.statuscode == 0 && data.data != null) {
                        this.setSubcriberPlanResult(data.data.items);
                    }
                    else{
                        this.setState({ errorMessage: data.statusmessage });
                    }

                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    setSubcriberPlanResult = (result) => {
        const newSeries = [];
        result.map((data, index) => {
            const x = {
                index: index,
                sub_id: data.SubId,
                customer: data.Customer,
                completed_payment: data.CompletedPayment,
                attempted_payment: data.AttemptedPayment,
                remaining_payment: data.RemainingPayment,
                charged_at: data.NextChargedAt,
                charge_amt: parseFloat(data.PlanAmount).toFixed(2)
                // total: '$' + parseFloat(invoice.TotalAmt).toFixed(2),
                // invoicestatus: invoice.IsPaid ? <Chip style={{ backgroundColor: "#00e396", color: "white", height: 25 }} label="Paid"></Chip> : parseFloat(invoice.Balance).toFixed(2) < parseFloat(invoice.TotalAmt).toFixed(2) ? <Chip style={{ backgroundColor: "#feb019", color: "white", height: 25 }} label="Partially Paid"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Unpaid"></Chip>,
                // action: <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                //     <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}> <i className="fa fa-clipboard" ></i> </Button>
                // </OverlayTrigger>,                
            }
            newSeries.push(x);

        });
        this.setState({ data: newSeries });
    }

    _postDeleteRecurringSubscriberRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postDeleteRecurringSubscriberRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showDeleteSubscriptionPopupModal: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if(data.statuscode == "0")
                    {
                        this.filterSubscriptions();
                        this.setState({ errorMessage: "Subscription deleted successfully." });
                    }
                    else{
                        this.setState({ errorMessage: data.statusmessage });
                    }
                } 
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    replaceAt(str, index, ch) {
        return str.replace(/./g, (c, i) => i === index ? ch : c)
    }

    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>
                                        }
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            components={this.state.components}
                                            actions={this.state.actions}
                                            onSelectionChange={(rows) => {
                                                console.log('You selected ' + rows.length + ' rows');
                                                this.handleSelectedRowData(rows, rows.length);

                                                if (rows.length > 0) {
                                                    if (rows.length === 1) {
                                                        let invoice = {};
                                                        this.state.selectedInvoiceIndex.map(index => {
                                                            invoice = this.state.rawInvoiceData[index];
                                                        });
                                                        this.setState({ showSubscriptionSendButton: "" });
                                                    }
                                                    else {
                                                        this.setState({ showSubscriptionSendButton: "none" });
                                                    }
                                                }
                                                else {
                                                    this.setState({ showSubscriptionSendButton: "none" });
                                                }
                                            }
                                            }
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                            <Modal
                                show={this.state.showDeleteSubscriptionPopupModal}
                                onHide={() => this.setState({ showDeleteSubscriptionPopupModal: false })}
                                // dialogClassName="modal-dialog-custom2"
                            >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }
                                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                    <Modal.Title style={{ color: "white" }}>Delete Subscription</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="modal-body text-center">
                                        <Row>
                                            <Col xs={5} sm={6} md={5} className="text-left">
                                                <h5>Customer : </h5>
                                            </Col> <Col xs={7} sm={6} md={7} className="text-left">
                                                <h5>{this.state.deletedSubscription.customer}</h5>
                                            </Col>
                                        </Row>                                        
                                        <Row>
                                            <Col xs={5} sm={6} md={5} className="text-left">
                                                <h5>Subscription ID :</h5>
                                            </Col> 
                                            <Col xs={7} sm={6} md={7} className="text-left">
                                                <h5>{this.state.deletedSubscription.sub_id}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} sm={6} md={5} className="text-left">
                                                <h5>Charge Amount :</h5>
                                            </Col> <Col xs={7} sm={6} md={7} className="text-left">
                                                <h5>${this.state.deletedSubscription.charge_amt}</h5>
                                            </Col>
                                        </Row>                            
                                        <Row>
                                            <Col xs={5} sm={6} md={5} className="text-left">
                                                <h5>Completed Payment :</h5>
                                            </Col> <Col xs={5} sm={6} md={5} className="text-left">
                                                <h5>{this.state.deletedSubscription.completed_payment}</h5>
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col xs={5} sm={6} md={5} className="text-left">
                                                <h5>Remaining Payment :</h5>
                                            </Col> <Col xs={7} sm={6} md={7} className="text-left">
                                                <h5>
                                                <span>{this.state.deletedSubscription.remaining_payment == "until_canceled"
                                                ? "Until Canceled" : this.state.deletedSubscription.remaining_payment}
                                                </span>
                                                </h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showDeleteSubscriptionPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.handleDeleteSubscriptionSubmit(this.state.deletedSubscription)}>Submit</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Subscriptions;







