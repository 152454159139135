import React, { Component } from "react";
import {
    Row,
    Col,
    InputGroup,
    FormGroup,
    ControlLabel,
    FormControl,
    Tab,
    Nav,
    Alert,
    NavItem,
    Tooltip,
    OverlayTrigger,
    Modal,

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { TrixEditor } from "react-trix";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import Chip from '@material-ui/core/Chip';
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { BASE_URL, StorageEnum } from "utils/constant.js"
import TagsInput from 'react-tagsinput';
import NetworkService from "services/NetworkService";
import SessionTimeout from 'components/SessionTimeout';
import Common from "utils/Common.js";
import ISC250Terminal from "views/Modals/ISC250.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.js";


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormValid: true,
            isPaymentEmailFormValid: true,
            ShowProgressBar: false,
            errorMessage: "",
            errorCode: "0",
            senderNameErrorMessage: "",
            senderNameState: "",
            senderEmailErrorMessage: "",
            senderEmailState: "",
            subjectErrorMessage: "",
            subjectState: "",
            bodyErrorMessage: "",            
            bodyState: "",
            Email: {
                senderName: "",
                senderEmail: "",
                bccEmails: [],
                bccEmail: "",
                ccEmails: [],
                subject: "",
                body: "",
                companyLogo: "",
                templates: [
                    {
                        value: "",
                        label: "Select Template",
                        isDisabled: true
                    },
                    { value: "Template1", label: "Template 1" },
                    { value: "Template2", label: "Template 2" }
                ],
                selectedTemplate: "",
                isDefaultTemplateChecked: false
            },
            PaymentEmail:{
                senderName: "",
                senderEmail: "",
                bccEmails: [],
                bccEmail: "",
                ccEmails: [],
               // subject: "",
            },
            otherOptions: {
                isShowConfirmationBeforeSendingInvoiceChecked: false,
                isSendPaymentReceiptAfterTrasnsactionChecked: false,
                isEnableFeedbackOnCustomerPortalChecked: false,
                isEnableInvoiceDefaultTemplateChecked: false,
                isEmailReceiptDialog: false,
                selectedDepositToAccount: "",
                depositToAccounts: [],
                defaultTerm: ""
            },
            invoiceTemplate: {
                template_id: 1,
                template_color: "blue",
            },
            Profile: {
                Company: {
                    Name: "",
                    Phone: "",
                    Address: "",
                    Country: "",
                    City: "",
                    State: "",
                    PostalCode: "",
                    WebSite: "",
                    Fax: "",
                },
                User: {
                    Email: "",
                    FirstName: "",
                    LastName: "",
                }
            },
            FirstNameState: "",
            LastNameState: "",
            EmailState: "",
            websiteState: "",
            companynameState: "",
            PhoneNumberState: "",
            FirstNameErrorMessage: "",
            LastNameErrorMessage: "",
            EmailErrorMessage: "",
            PhoneNumberErrorMessage: "",
            currentPassword: "",
            currentPasswordState: "",
            currentPasswordErrorMessage: "",
            newPassword: "",
            newPasswordState: "",
            newPasswordErrorMessage: "",
            confirmNewPassword: "",
            confirmNewPasswordState: "",
            confirmNewPasswordErrorMessage: "",
            isPasswordFormValid: false,
            addNewTemplateToolTip: <Tooltip id="addNewTemplateToolTip">Add new template</Tooltip>,
            terminalOptions: {
                IngenicoISC250: false
            },
            proceesorSettingError: "",
            isAuthenticated: true,
            isImageChange: false,
            terms: [],
            showConvFeePopupModal: false,
            showConvFeeDeletePopupModal: false,
            ConvFee: "",//{title: "Conv. Fee", type: "Percent", amount: "3.00"},
            ConvFeeModel: {
                Title: "",
                Description: "",
                Amount: "",
                IsPercentage: true,
                IsFixed: false,
                convFeeTitleErrorMessage: "",
                convFeeTitleState: "",
                convFeeAmountErrorMessage: "",
                convFeeAmountState: false
            }
           // term: "",
        };        
        
        this.handleBccEmailsChange = this.handleBccEmailsChange.bind(this);
        this.handleCCEmailsChange = this.handleCCEmailsChange.bind(this);

        this.handlePaymentBccEmailsChange = this.handlePaymentBccEmailsChange.bind(this);
        this.handlePaymentCCEmailsChange = this.handlePaymentCCEmailsChange.bind(this);
        
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";

        this.getEmailSettingRequest();
        this.getProfileRequest();
        this._getAccountRequest();
        this.GetSetTerms();  
        this.GetConvFee();
        var data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        if (data_tokenize == null || data_tokenize == undefined) {
            this.setState({proceesorSettingError: Common.getProcessorSettingsErrorMessage()})   
        }
    }

    //#region Profile
    // GET request to fetch merchant profile
    getProfileRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/merchant/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token
            },
        }).then(response => {
            return response.json()
        }).then(data => {
            if (data != null) {

                this.setState({ ShowProgressBar: false });
                if (data.statuscode == 0) {

                    const companyData = data.data.company;
                    const userData = data.data.user;

                    const company = { ...this.state.Profile.Company };
                    company.Name = companyData.name;
                    company.Phone = companyData.phone_number;
                    company.Address = companyData.address;
                    company.Country = companyData.country;
                    company.City = companyData.city;
                    company.State = companyData.state;
                    company.PostalCode = companyData.postal_code;
                    company.Fax = companyData.fax;
                    company.WebSite = companyData.website;

                    const user = { ...this.state.Profile.User }
                    user.Email = userData.email;
                    user.FirstName = userData.first_name;
                    user.LastName = userData.last_name;

                    this.setState({ Profile: { Company: company, User: user } });
                }
                else {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                }
            }
        }).catch(error => {
            // this.successAlert.bind(this);
            console.log(error);
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    GetSetTerms(){
        if((localStorage.getItem("is_qbo_recently_connected") == "true" || localStorage.getItem("is_qbo_connected") == "true" ) && (localStorage.getItem("terms") == null || localStorage.getItem("terms") == "null" || localStorage.getItem("terms") == "[]")){
            this._getTermsRequest();      
        }
        else{
             this.setTermsResult(JSON.parse(localStorage.getItem("terms")), true); 
        }
    }

    _getTermsRequest() {
        this.setState({ errorMessage: "" });
        const app_id = 2;
        NetworkService.getTermsRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setTermsResult(data.data.items, false);
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setTermsResult(terms, isFromCache = false) {           
        if(!isFromCache)
        {
            localStorage.setItem("terms", null);
            localStorage.setItem("terms", JSON.stringify(terms)); 
        }
        const newState = [];
        
        newState.push({value: "", label: "Select Term", isDisabled: true});
        if(terms != null && terms != undefined && terms.length > 0)
        {
            terms.map(data => {
                const x = { value: data["Id"], label: data["Name"] }
                newState.push(x);
            });   
        }
        // newState.map(newTerm => 
        // {
        //   if(newTerm.label == 'Due on receipt')
        //   {
        //       this.state.term = newTerm;
        //   }
        // }); 
        if(this.state.term == "" && newState.length >= 1)
        {
            this.setState({ term: newState[1] });        
        }
        this.setState({ terms: newState });        

       
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
        const app_id = 2;
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
            this.setState({ ConvFee: convfee[0] });   
    }

    handlerProfileSave = (profileInfo) => {
        this.postProfileRequest(profileInfo);
    }

    // POST request for profile update
    postProfileRequest = (profileInfo) => {
        if (this.isProfileValidated()) {
            this.setState({ ShowProgressBar: true });
            this.setState({ errorMessage: "" });
            const auth_token = localStorage.getItem(StorageEnum.UUID);
            const url = BASE_URL + "api/v1/merchant/";

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + auth_token
                },
                body: JSON.stringify({
                    "name": profileInfo.Company.Name,
                    "phone": profileInfo.Company.Phone,
                    "fax": profileInfo.Company.Fax,
                    "website": profileInfo.Company.WebSite,
                    "address": profileInfo.Company.Address,
                    "city": profileInfo.Company.City,
                    "state": profileInfo.Company.State,
                    "country": profileInfo.Company.Country,
                    "postalcode": profileInfo.Company.PostalCode,
                    "first_name": profileInfo.User.FirstName,
                    "last_name": profileInfo.User.LastName,
                })
            }).then(response => {
                return response.json()
            }).then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        console.log(data.data);
                        this.setState({ errorMessage: "Profile saved successfully." });
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                console.log(error)
            });
        }

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    profileValueChange(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }

    ValidateProfileEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                EmailErrorMessage: (
                    <small className="text-danger">
                        Invalid Email Address.
                    </small>
                )
            });
            this.setState({
                EmailState: "error"
            });
        }
        else {
            this.setState({ EmailState: "valid" });
            this.setState({ EmailErrorMessage: null });
        }

    }

    AddressChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Address: val
                }
            }
        }))
    }

    selectCountry(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Country: val
                }
            }
        }))
    }

    selectRegion(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    State: val
                }
            }
        }))
    }

    CityChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    City: val
                }
            }
        }))
    }

    PostalCodeChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    PostalCode: val
                }
            }
        }))
    }

    isProfileValidated() {
        let isValid = true;
        if (this.state.Profile.User.FirstName === "") {
            this.setState({
                FirstNameErrorMessage: (
                    <small className="text-danger">
                        First Name is Required.
                    </small>
                )
            });
            this.setState({ FirstNameState: "error" });
            isValid = false;
        }
        else if (this.state.FirstNameState === "error") {
            this.setState({
                FirstNameErrorMessage: (
                    <small className="text-danger">
                        Invalid First Name.
                    </small>
                )
            });
            isValid = false;
        }
        else {
            this.setState({ FirstNameErrorMessage: null });
        }

        if (this.state.Profile.User.LastName === "") {
            this.setState({
                LastNameErrorMessage: (
                    <small className="text-danger">
                        Last Name is Required.
                    </small>
                )
            });
            this.setState({ LastNameState: "error" });
            isValid = false;
        }
        else if (this.state.LastNameState === "error") {
            this.setState({
                LastNameErrorMessage: (
                    <small className="text-danger">
                        Invalid Last Name.
                    </small>
                )
            });
            isValid = false;
        }
        else {
            this.setState({ LastNameErrorMessage: null });
        }

        if (this.state.Profile.Company.Phone === "") {
            this.setState({
                PhoneNumberErrorMessage: (
                    <small className="text-danger">
                        Phone Number is Required.
                    </small>
                )
            });
            this.setState({ PhoneNumberState: "error" });
            isValid = false;
        }
        else if (this.state.PhoneNumberState == "error") {
            this.setState({
                PhoneNumberErrorMessage: (
                    <small className="text-danger">
                        Invalid Phone Number.
                    </small>
                )
            });
            this.setState({ PhoneNumberState: "error" });
            isValid = false;
        }
        else {
            this.setState({ PhoneNumberErrorMessage: null });
        }

        return isValid;
    }
    //#endregion

    //#region Reset Password
    saveChangePassword() {
        if (this.ValidatePasswords()) {
            const password_info = {
                current_password: this.state.currentPassword,
                new_password: this.state.newPassword,
                confirm_password: this.state.confirmNewPassword
            };

            this.setState({ ShowProgressBar: true });
            NetworkService.postChangePasswordRequest(password_info)
                .then(data => {
                    if (data != null) {
                        this.setState({ ShowProgressBar: false });
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                        if(data.statuscode == 0){
                            this.setState({currentPassword:""});
                            this.setState({newPassword: ""});
                            this.setState({confirmNewPassword:""});
                        }  
                    }
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                })
                .catch(error => {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                })
        }
    }

    ValidatePasswords() {
        this.setState({isPasswordFormValid: true});
        var passwordRE = new RegExp(Common.getPasswordRegex());
        if (passwordRE.test(this.state.currentPassword) === false) {
             this.setState({currentPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
        } 
        else {
            this.setState({ currentPasswordErrorMessage: null});
        }

        if (passwordRE.test(this.state.newPassword) === false) {            
            this.setState({newPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
        }
        else {
            this.setState({ newPasswordErrorMessage: null});            
        }

        if (passwordRE.test(this.state.confirmNewPassword) === false) {            
            this.setState({confirmNewPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
        }
        else {
            this.setState({ confirmNewPasswordErrorMessage: null});            
        }

        if (this.state.isPasswordFormValid && this.state.newPassword != this.state.confirmNewPassword) {
            this.setState({
                confirmNewPasswordErrorMessage: (
                    <small className="text-danger">
                        New Password and Confirm Password do not match.
                    </small>
                )
            });
            this.state.isPasswordFormValid = false;
        }       
        
        return  this.state.isPasswordFormValid;
    }
    //#endregion

    handleBodyChange(html, text) {
        // html is the new html content
        // text is the new text content
        if (text != null && text != undefined) {
            html = html + " " + text;
            var div = document.createElement("div");
            html = html.replace("<div>", "").replace("</div>", "")
            html = "<div>" + html + "[sdfsdf]" + "</div>";
        }

        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                body: html
            }
        }));

        this.setState({ bodyState: html != "" ? "valid" : "" });
        this.setState({ bodyErrorMessage: html != "" ? null : <small className="text-danger"> Body is Required. </small> });
    }

    handleEditorReady(editor, bodyHTML) {
        // this is a reference back to the editor if you want to
        // do editing programatically
        var div = document.createElement("div");
        div.innerHTML = bodyHTML;
        var text = div.textContent || div.innerText || "";

        bodyHTML = bodyHTML.replace(/<style([\s\S]*?)<\/style>/gi, '');
        bodyHTML = bodyHTML.replace(/<script([\s\S]*?)<\/script>/gi, '');
        bodyHTML = bodyHTML.replace(/<\/div>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<\/li>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<li>/ig, '  *  ');
        bodyHTML = bodyHTML.replace(/<\/ul>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<\/p>/ig, '\n');
        bodyHTML = bodyHTML.replace(/<br\s*[\/]?>/gi, "\n");
        bodyHTML = bodyHTML.replace(/<[^>]+>/ig, '');
        editor.insertString(bodyHTML);
    }

    ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                senderEmail: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });
            this.setState({
                senderEmailState: "error"
            });
        }
        else {
            this.setState({ senderEmailState: "valid" });
            this.setState({ senderEmailErrorMessage: null });
        }

    }

    handleCompanyLogoUpload(value) {
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                companyLogo: value
            }
        }));
        this.setState({isImageChange: true});
        
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                [stateName]: value
            }
        }));
    }

    paymentChange(event, stateName, stateName2 , type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName2 + "State"]: "valid" });
                    this.setState({ [stateName2 + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName2 + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            PaymentEmail: {
                ...prevState.Email,
                [stateName]: value
            }
        }));
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    isValidated() {
        if (this.state.senderNameState === "" || this.state.senderNameState === "error") {
            this.setState({
                senderNameErrorMessage: (
                    <small className="text-danger">
                        Sender Name is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderNameErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.senderEmailState === "" || this.state.senderEmailState === "error") {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Email is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderEmailErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.subjectState === "" || this.state.subjectState === "error") {
            this.setState({
                subjectErrorMessage: (
                    <small className="text-danger">
                        Subject is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ subjectErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.bodyState === "" || this.state.bodyState === "error") {
            this.setState({
                bodyErrorMessage: (
                    <small className="text-danger">
                        Body is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ bodyErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        return this.state.isFormValid;

    }

    handleDefaultTemplateCheck = event => {
        const target = event.target;
        // this.setState({
        //     isDefaultTemplateChecked: target.checked
        // });
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                isDefaultTemplateChecked: target.checked
            }
        }));
    };

    handleShowConfirmationBeforeSendingInvoiceCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isShowConfirmationBeforeSendingInvoiceChecked: target.checked
            }
        }));
    };

    handleSendPaymentReceiptAfterTrasnsactionCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isSendPaymentReceiptAfterTrasnsactionChecked: target.checked
            }
        }));
    };

    handleEnableFeedbackOnCustomerPortalCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isEnableFeedbackOnCustomerPortalChecked: target.checked
            }
        }));
    };

    handleEnableInvoiceDefaultTemplateCheck = event => {
        const target = event.target;
        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                isEnableInvoiceDefaultTemplateChecked: target.checked
            }
        }));
    };

    handlerEmailSettingsSave = (invoiceEmailSettings, paymentEmailSettings) => {
        if (this.isValidated()) {
            // const invoiceContact = {
            //     sender_name: emailSettings.senderName,
            //     sender_email: emailSettings.senderEmail,
            //     bcc_emails: emailSettings.bccEmails,
            //     cc_emails: emailSettings.ccEmails,
            //     subject: emailSettings.subject,
            //     body: emailSettings.body,
            //     templates: emailSettings.templates
            // }
            // const setting = { "InvoiceContacts": invoiceContact }

           // this.postEmailSettingsRequest(setting);

            let formData = new FormData();
            formData.append("Setting", JSON.stringify({
                "InvoiceContacts":{
                "sender_name": invoiceEmailSettings.senderName,
                "sender_email": invoiceEmailSettings.senderEmail,
                "bcc_emails": invoiceEmailSettings.bccEmails,
                "cc_emails": invoiceEmailSettings.ccEmails,
                "subject": invoiceEmailSettings.subject,
                "body": invoiceEmailSettings.body,
                "templates": invoiceEmailSettings.templates              
             },
             "PaymentContacts":{
                "sender_name": paymentEmailSettings.senderName,
                "sender_email": paymentEmailSettings.senderEmail,
                "bcc_emails": paymentEmailSettings.bccEmails,
                "cc_emails": paymentEmailSettings.ccEmails,
              //  "subject": paymentEmailSettings.subject
             }
            }));
            formData.append("file", invoiceEmailSettings.companyLogo);
            this.postEmailSettingsRequest(formData, false)

        }
        else {
            console.log("form not valid");
        }
    }

    handlerAppOptionsSettingsSave = (appOptions) => {
        const options = {
            ShowConfirmationDialog: appOptions.isShowConfirmationBeforeSendingInvoiceChecked,
            InvoiceReceiptDialog: appOptions.isSendPaymentReceiptAfterTrasnsactionChecked,
            EmailReceiptDialog: appOptions.isEmailReceiptDialog,
            IsDefaultTemplate: appOptions.isEnableInvoiceDefaultTemplateChecked,
            DepositAccountID: appOptions.selectedDepositToAccount,
            DefaultTerm: appOptions.defaultTerm,
        }
        this.postEmailSettingsRequest(options, true);
    }

    handlerIngenicoISC250 = (value) => {
        this.setState(prevState => ({
            terminalOptions: {
                ...prevState.terminalOptions,
                ISC250Terminal: value
            }
        }));
    }

    handleBccEmailsChange(tags) {
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                bccEmails: tags || []
            }
        }));
    }

    handlePaymentBccEmailsChange(tags) {        
        this.setState(prevState => ({
            PaymentEmail: {
                ...prevState.PaymentEmail,
                bccEmails: tags || []
            }
        }));

    }

    handleCCEmailsChange(tags) {
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                ccEmails: tags || []
            }
        }));
        
    }
    
    handlePaymentCCEmailsChange(tags) {
        this.setState(prevState => ({
            PaymentEmail: {
                ...prevState.PaymentEmail,
                ccEmails: tags || []
            }
        }));
    }

    onSetEmailResult = (result) => {

       

        const invoiceContacts = result.InvoiceContacts
        const paymentContacts = result.PaymentContacts
        const templateID = result.TemplateID
        const templateColor = result.TemplateColor
        const depositToAccount = result.DepositAccountID


        const payment_email_setting = { ...this.state.PaymentEmail }
        const email_setting = { ...this.state.Email }
        email_setting.senderName = invoiceContacts != null ? invoiceContacts.sender_name : "";
        payment_email_setting.senderName = paymentContacts != null ? paymentContacts.sender_name : "";
        if (invoiceContacts != null && invoiceContacts.sender_name != "") {
            this.setState({ senderNameState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.senderEmail = invoiceContacts != null ? invoiceContacts.sender_email : "";
        payment_email_setting.senderEmail = paymentContacts != null ? paymentContacts.sender_email : "";
        if (invoiceContacts != null && invoiceContacts.sender_email != "") {
            this.setState({ senderEmailState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.subject = invoiceContacts != null ? invoiceContacts.subject : "";
        if (invoiceContacts != null && invoiceContacts.subject != "") {
            this.setState({ subjectState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.body = invoiceContacts != null ? invoiceContacts.body : "";
        if (invoiceContacts != null && invoiceContacts.body != "") {
            this.setState({ bodyState: "valid" });
        }
        else {
            this.setState({ isFormValid: false });
        }
        email_setting.bccEmails = invoiceContacts != null ? invoiceContacts.bcc_emails : [];
        email_setting.ccEmails = invoiceContacts != null ? invoiceContacts.cc_emails : [];

        payment_email_setting.bccEmails = paymentContacts != null ? paymentContacts.bcc_emails : [];
        payment_email_setting.ccEmails = paymentContacts != null ? paymentContacts.cc_emails : [];        

        email_setting.companyLogo = result.EmailBodyImage;

        const invoiceTemplate = { ...this.state.invoiceTemplate }
        invoiceTemplate.templateID = templateID
        invoiceTemplate.templateColor = templateColor
        const otherOptions = { ...this.state.otherOptions }
        otherOptions.isShowConfirmationBeforeSendingInvoiceChecked = result.ShowConfirmationDialog
        otherOptions.isSendPaymentReceiptAfterTrasnsactionChecked = result.InvoiceReceiptDialog
        otherOptions.isEmailReceiptDialog = result.EmailReceiptDialog
        otherOptions.isEnableInvoiceDefaultTemplateChecked = result.IsDefaultTemplate
        otherOptions.selectedDepositToAccount = result.DepositAccountID
        otherOptions.defaultTerm = result.DefaultTerm
        this.setState({ Email: email_setting });
        this.setState({PaymentEmail : payment_email_setting});
        this.setState({ invoiceTemplate: invoiceTemplate });
        this.setState({ otherOptions: otherOptions });
    }

    setAccountResult(accounts) {
        const newState = []
        accounts.map(data => {
            newState.push({ value: data.Id, label: data.Name + " | " + data.AccountType });
        });

        this.setState(prevState => ({
            otherOptions: {
                ...prevState.otherOptions,
                depositToAccounts: newState
            }
        }));

    }

    /** API Calling */

    getEmailSettingRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const url = BASE_URL + "api/v1/settings/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.onSetEmailResult(data.data);
                this.setState({ ShowProgressBar: false });
                if (data.statuscode == 0) {

                }
                else {
                    this.setState({ errorMessage: data.statusmessage });
                }

            } else {

            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    // POST request for invoice email setting
    postEmailSettingsRequest = (settings, isJSONRequest) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        if(isJSONRequest){
            const url = BASE_URL + "api/v1/settings/";
            const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
            const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
            fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": access_token,
                    "Appid": app_id,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "Setting": settings
                })
            }).then(response => {
                return response.json();
            }).then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorCode: data.statuscode });
                } else {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorCode: "1" });
                    this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: "1" });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        }
        else{
            const app_id = 2;
            NetworkService.postMerchantSettingsRequest(settings, app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.setState({ errorMessage: data.statusmessage });
                            if (data.data.items != undefined) {
                                this.setResult(data.data.items);
                            }
                        }
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        }

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);        
    }

    _getAccountRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getAccountRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    // this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        const account = data.data.items;
                        this.setAccountResult(account);
                    }


                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    ISC250CallbackFunction = (childData) => {
        this.setState(prevState => ({
            terminalOptions: {
                ...prevState.terminalOptions,
                ISC250Terminal: childData
            }
        }));
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }

    
    handlerConvFeeCreatePopup() {
        // this.state.ConvFeeModel.Title = "";
        // this.state.ConvFeeModel.Description = "";
        // this.state.ConvFeeModel.Amount = "";
        this.setState(prevState => ({
            ...prevState,
            ConvFeeModel: {
                ...prevState.ConvFeeModel,
                IsPercentage: true,
                IsFixed: false,
                Amount: 0.00,
                convFeeAmountState: "",
                convFeeAmountErrorMessage: "",
                Title: "",
                Description: "",
                convFeeTitleState: "",
                convFeeTitleErrorMessage: "",

            }
        }));

        this.setState({ showConvFeePopupModal: true });
    }

    handlerConvFeeDeletePopup() {
        this.setState({ showConvFeeDeletePopupModal: true });
    }

    handleConvFeeRadio = event => {
        const target = event.target;
        if (target.value === "percentage") {
            this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                        IsPercentage: true,
                        IsFixed: false,
                        Amount: 0.00,
                        convFeeAmountState: "",
                        convFeeAmountErrorMessage: ""
                    }
                }));
        }
        else{
            this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                        IsPercentage: false,
                        IsFixed: true,
                        Amount: 0.00,
                        convFeeAmountState: "",
                        convFeeAmountErrorMessage: ""
                    }
                }));
        }
                
        this.setState({ StatusMessage: "" });              
    };

    handleConvFeeAmountChange = e => {
        const { name, value } = e.target;
        if(value == "")
        {
            this.setState(prevState => ({
                ...prevState,
                ConvFeeModel: {
                    ...prevState.ConvFeeModel,
                    convFeeAmountState: "invalid",
                    convFeeAmountErrorMessage: (
                        <small className="text-danger">
                            Amount is required.
                        </small>
                    )
                }
            })); 
            return false;
        }
        else if (this.state.ConvFeeModel.IsPercentage && (parseFloat(value) > parseFloat(3.99) || parseFloat(value) < parseFloat(0.01))) {          
            this.setState(prevState => ({
                ...prevState,
                ConvFeeModel: {
                    ...prevState.ConvFeeModel,
                    convFeeAmountState: "invalid",
                    convFeeAmountErrorMessage: (
                        <small className="text-danger">
                            Value must be greater than 0.01 and less than 3.99
                        </small>
                    )
                }
            }));    
            return false;
        }
        else if (this.state.ConvFeeModel.IsFixed && (parseFloat(value) > parseFloat(100) || parseFloat(value) < parseFloat(0.01))) {          
            this.setState(prevState => ({
                ...prevState,
                ConvFeeModel: {
                    ...prevState.ConvFeeModel,
                    convFeeAmountState: "invalid",
                    convFeeAmountErrorMessage: (
                        <small className="text-danger">
                            Value must be enter from 0.01 upto 100.00
                        </small>
                    )
                }
            }));    
            return false;
        }

        this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                         Amount: value,
                        convFeeAmountState: "valid",
                        convFeeAmountErrorMessage: ""
                    }
                    }));
    };

    handleConvFeeCreate(convFee) {
        if(convFee.convFeeAmountState == "valid" &&  convFee.convFeeTitleState == "valid")
        {
            const data = {
                "name": convFee.Title,
                "description": convFee.Description,
                "value": convFee.Amount,
                "is_percentage": convFee.IsPercentage,
                "is_active": true
            }
            console.log(data);
            this._postConvFeeRequest(data);
        }
        else{
            if(convFee.convFeeAmountState != "valid") 
            {
                    this.setState(prevState => ({
                    ...prevState,
                    ConvFeeModel: {
                        ...prevState.ConvFeeModel,
                        convFeeAmountState: false,
                        convFeeAmountErrorMessage: (
                            <small className="text-danger">
                                Invalid amount value.
                            </small>
                        )
                    }
                }));  
             }
             if(convFee.convFeeTitleState != "valid") 
            {
                this.setState(prevState => ({
                ...prevState,
                ConvFeeModel: {
                    ...prevState.ConvFeeModel,
                    convFeeTitleState: false,
                    convFeeTitleErrorMessage: (
                        <small className="text-danger">
                            Invalid title field.
                        </small>
                    )
                }
                })); 
            } 
        }
    }

    handleDeleteConvFeeSubmit(convFee){
         const data = {
            "id": convFee.id
        }
        console.log(data);
        this._postDeleteConvFeeRequest(data);
    }

     _postConvFeeRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 

        NetworkService.postConvFeeCreateRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showConvFeePopupModal: false });
                if (data != null) {
                    if (data.statuscode == 0) {                       
                        this.GetConvFee();                       
                    }
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showConvFeePopupModal: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    _postDeleteConvFeeRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 

        NetworkService.postConvFeeDeleteRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showConvFeeDeletePopupModal: false });
                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ConvFee: ""})
                        if (data.data != null) {
                           // this.filterTransactions();
                        }
                    }
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ showConvFeePopupModal: false });
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);
            });
    }

    render() {
        const customChipStyle = {
            fontSize: "1.5rem", marginRight: "5px", marginTop: "10px"
        }
        const EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
        const companyLogoToolTip = (<Tooltip style={{ fontSize: 12 }} id="companyLogo_tooltip">Company Logo will appear in the email after the body. </Tooltip>);
        return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    ctFullWidth
                    content={
                        <div>
                            {this.state.proceesorSettingError &&
                                <div style={{marginLeft:"15px", marginRight:"15px"}}>                                    
                                    <Alert bsStyle= "info">
                                        <span>
                                            {this.state.proceesorSettingError}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Tab.Container id="tabs-with-dropdown" defaultActiveKey="email">
                                <Row className="clearfix">
                                    <Col sm={12}>
                                        <Nav bsStyle="tabs">
                                            <NavItem eventKey="email">
                                                <i className="fa fa-envelope-o" /> Email
                                            </NavItem>
                                            <NavItem eventKey="profliekey">
                                                <i className="fa fa-user" /> Profile
                                            </NavItem>
                                            <NavItem eventKey="changepassword">
                                                <i className="fa fa-lock" /> Change Password
                                            </NavItem>
                                            <NavItem eventKey="convfee">
                                                <i className="fa fa-dollar" /> Non-Cash Adjustment
                                            </NavItem>
                                            <NavItem eventKey="other">
                                                <i className="fa fa-cogs" /> Other Settings
                                            </NavItem>
                                            {/* <NavItem eventKey="terminal">
                                                <i className="fa fa-usb" /> Hardware/Terminals
                                            </NavItem> */}
                                            {/* <NavItem eventKey="template">
                                                <i className="fa fa-file-text-o" /> Template
                                            </NavItem> */}
                                        </Nav>
                                    </Col>
                                    <Col sm={10}>
                                        <Tab.Content animation>
                                            {/* Email Tab */}
                                            <Tab.Pane eventKey="email">
                                                <Tab.Container id="tabs-with-dropdown" defaultActiveKey="invoiceemail">
                                                    <Row className="clearfix">
                                                        <Col sm={12}>
                                                            <Nav bsStyle="tabs">
                                                                <NavItem eventKey="invoiceemail">
                                                                    <i className="fa fa-envelope-o" /> Invoice Email Settings
                                                                </NavItem>
                                                                <NavItem eventKey="paymenteemail">
                                                                    <i className="fa fa-newspaper-o" /> Transaction/Payment Email Settings
                                                                </NavItem>                                                                
                                                            </Nav>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <Tab.Content animation>
                                                                <Tab.Pane eventKey="invoiceemail">
                                                                    <Card
                                                                        content={
                                                                            <div>
                                                                                {this.state.ShowProgressBar &&
                                                                                    <div>
                                                                                        <CustomProgressBar></CustomProgressBar>
                                                                                        <br />
                                                                                    </div>
                                                                                }
                                                                                {this.state.errorMessage != "" &&
                                                                                    <div>
                                                                                        <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                                                            <span>
                                                                                                {this.state.errorMessage}
                                                                                            </span>
                                                                                        </Alert>
                                                                                    </div>
                                                                                }
                                                                                <Row>
                                                                                    <Col md={8}>
                                                                                        <Row>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        Sender Name: <span className="star">*</span>
                                                                                                    </div>
                                                                                                    <FormControl
                                                                                                        value={this.state.Email.senderName}
                                                                                                        type="text"
                                                                                                        placeholder="Sender Name"
                                                                                                        name="senderName"
                                                                                                        className={this.state.senderNameState}
                                                                                                        onChange={event =>
                                                                                                            this.change(event, "senderName", "length", 2)
                                                                                                        }
                                                                                                        onBlur={e => this.isValidated()}
                                                                                                    />
                                                                                                    {this.state.senderNameErrorMessage}
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        Sender Email: <span className="star">*</span>
                                                                                                    </div>
                                                                                                    <FormControl
                                                                                                        readOnly
                                                                                                        value={this.state.Email.senderEmail}
                                                                                                        placeholder="Sender Email"
                                                                                                        name="senderEmail"
                                                                                                        type="email"
                                                                                                        className={this.state.senderEmailState}
                                                                                                        onChange={event =>
                                                                                                            this.ValidateEmailAddress(event)
                                                                                                        }
                                                                                                        onBlur={e => this.isValidated()}
                                                                                                    />
                                                                                                    {this.state.senderEmailErrorMessage}
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>

                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        BCC Email:
                                                                                                    </div>
                                                                                                    <TagsInput
                                                                                                        maxTags={3}
                                                                                                        value={this.state.Email.bccEmails}
                                                                                                        onChange={this.handleBccEmailsChange}                                                                                                     
                                                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                                                        onlyUnique
                                                                                                        addOnPaste
                                                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                                                        pasteSplit={data => {
                                                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                                                        }}
                                                                                                        tagProps={{ className: 'react-tagsinput-tag tag-fill tag-blue' }}
                                                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                                                    />                                                                        
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        CC Email:
                                                                                                    </div>
                                                                                                    <TagsInput
                                                                                                        maxTags={3}
                                                                                                        value={this.state.Email.ccEmails}
                                                                                                        onChange={this.handleCCEmailsChange}
                                                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                                                        onlyUnique
                                                                                                        addOnPaste
                                                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                                                        pasteSplit={data => {
                                                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                                                        }}
                                                                                                        tagProps={{
                                                                                                            className: 'react-tagsinput-tag tag-fill tag-blue',
                                                                                                            classNameRemove: 'react-tagsinput-remove'
                                                                                                        }}
                                                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>                                                                        
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <Row>
                                                                                            <Col md={12}>
                                                                                                <FormGroup >
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        Company Logo: <OverlayTrigger placement="right" overlay={companyLogoToolTip}>
                                                                                                                            <i className="fa fa-question-circle"></i>
                                                                                                                        </OverlayTrigger>
                                                                                                    </div>
                                                                                                    <Card
                                                                                                        textCenter
                                                                                                        content={
                                                                                                            <div className="text-center">
                                                                                                                {this.state.isImageChange ?
                                                                                                                    <ImageUpload
                                                                                                                        isMerchantLogo={false}
                                                                                                                        parentComponent={data => this.handleCompanyLogoUpload(data)} />
                                                                                                                    :
                                                                                                                    <ImageUpload
                                                                                                                        isMerchantLogo={true}
                                                                                                                        merchantLogo={this.state.Email.companyLogo}
                                                                                                                        parentComponent={data => this.handleCompanyLogoUpload(data)} />
                                                                                                                }
                                                                                                            </div>
                                                                                                        }
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <FormGroup>
                                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                Subject: <span className="star">*</span>
                                                                                            </div>
                                                                                            <FormControl
                                                                                                value={this.state.Email.subject}
                                                                                                type="text"
                                                                                                placeholder="Subject"
                                                                                                name="subject"
                                                                                                className={this.state.subjectState}
                                                                                                onChange={event =>
                                                                                                    this.change(event, "subject", "length", 2)
                                                                                                }
                                                                                                onBlur={e => this.isValidated()}
                                                                                            />
                                                                                            {this.state.subjectErrorMessage}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        <FormGroup>
                                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                Body:  <span className="star">*</span>
                                                                                            </div>
                                                                                            <TrixEditor
                                                                                                className="custom-css-class"
                                                                                                autoFocus={true}
                                                                                                placeholder="Email's Body"
                                                                                                value={this.state.Email.body}
                                                                                                onChange={e => this.handleBodyChange(e)}
                                                                                                onEditorReady={e => {
                                                                                                    setTimeout(() => {
                                                                                                        this.handleEditorReady(e, this.state.Email.body);
                                                                                                    }, 1000);
                                                                                                }
                                                                                                }
                                                                                            />
                                                                                            {this.state.bodyErrorMessage}
                                                                                        </FormGroup>
                                                                                    </Col>                                                                
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <FormGroup>
                                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                Supported Tags:
                                                                                            </div>
                                                                                            <Chip style={customChipStyle} label="[customer_name]" onClick={e => this.handleBodyChange(this.state.Email.body, "[customer_name]")} />
                                                                                            <Chip style={customChipStyle} label="[invoice_number]" />
                                                                                            <Chip style={customChipStyle} label="[amount]" />
                                                                                            <Chip style={customChipStyle} label="[full_name]" />
                                                                                            <Chip style={customChipStyle} label="[company_name]" />
                                                                                            <Chip style={customChipStyle} label="[company_phone]" />
                                                                                            <Chip style={customChipStyle} label="[company_weburl]" />
                                                                                            <Chip style={customChipStyle} label="[date]" />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                {/* <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                Template:
                                                                                            </div>
                                                                                            <Select
                                                                                                className="react-select primary"
                                                                                                classNamePrefix="react-select"
                                                                                                name="templates"
                                                                                                value={this.state.Email.selectedTemplate}
                                                                                                onChange={value =>
                                                                                                    this.setState(prevState => ({
                                                                                                        Email: {
                                                                                                            ...prevState.Email,
                                                                                                            selectedTemplate: value
                                                                                                        }
                                                                                                    }))
                                                                                                }
                                                                                                options={this.state.Email.templates}
                                                                                                placeholder="Select Template"
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={1}>
                                                                                        <FormGroup>
                                                                                            <OverlayTrigger placement="top" overlay={this.state.addNewTemplateToolTip}>
                                                                                                <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '10px', marginTop: '25px' }}>
                                                                                                    <i className="fa fa-plus" ></i>
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <FormGroup style={{ marginTop: '33px' }}>
                                                                                            <Checkbox
                                                                                                number="DefaultTemplatCheckBox"
                                                                                                label="Set template as default"
                                                                                                onChange={this.handleDefaultTemplateCheck}
                                                                                                checked={this.state.Email.isDefaultTemplateChecked}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row> */}

                                                                                
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="paymenteemail">
                                                                    <Card
                                                                        content={
                                                                            <div>
                                                                                {this.state.ShowProgressBar &&
                                                                                    <div>
                                                                                        <CustomProgressBar></CustomProgressBar>
                                                                                        <br />
                                                                                    </div>
                                                                                }
                                                                                {this.state.errorMessage != "" &&
                                                                                    <div>
                                                                                        <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                                                            <span>
                                                                                                {this.state.errorMessage}
                                                                                            </span>
                                                                                        </Alert>
                                                                                    </div>
                                                                                }
                                                                                <Row>
                                                                                    <Col md={8}>                                                                                        
                                                                                        <Row>
                                                                                            <Col md={12}>
                                                                                                <Alert bsStyle= "info">
                                                                                                    <span>
                                                                                                        Receive Emails/Payment Receipts when customers perform transactions/payments.
                                                                                                    </span>
                                                                                                </Alert>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        Sender Name: 
                                                                                                    </div>
                                                                                                    <FormControl
                                                                                                        value={this.state.PaymentEmail.senderName}
                                                                                                        type="text"
                                                                                                        placeholder="Sender Name"
                                                                                                        name="senderName"
                                                                                                        className={this.state.paymentSenderNameState}
                                                                                                        onChange={event =>
                                                                                                            this.paymentChange(event, "senderName", "paymentSenderName" , "length", 2)
                                                                                                        }
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        Sender Email: 
                                                                                                    </div>
                                                                                                    <FormControl
                                                                                                        readOnly
                                                                                                        value={this.state.Email.senderEmail}
                                                                                                        placeholder="Sender Email"
                                                                                                        name="paymentSenderEmail"
                                                                                                        type="email"
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>

                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        BCC Email:
                                                                                                    </div>
                                                                                                    <TagsInput
                                                                                                        maxTags={3}
                                                                                                        value={this.state.PaymentEmail.bccEmails}
                                                                                                        onChange={this.handlePaymentBccEmailsChange}
                                                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                                                        onlyUnique
                                                                                                        addOnPaste
                                                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                                                        pasteSplit={data => {
                                                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                                                        }}
                                                                                                        tagProps={{ className: 'react-tagsinput-tag tag-fill tag-blue' }}
                                                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                                                    />                                                                        
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                            <Col md={12}>
                                                                                                <FormGroup>
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        CC Email:
                                                                                                    </div>
                                                                                                    <TagsInput
                                                                                                        maxTags={3}
                                                                                                        value={this.state.PaymentEmail.ccEmails}
                                                                                                        onChange={this.handlePaymentCCEmailsChange}
                                                                                                        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
                                                                                                        onlyUnique
                                                                                                        addOnPaste
                                                                                                        validationRegex={EMAIL_VALIDATION_REGEX}
                                                                                                        pasteSplit={data => {
                                                                                                            return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
                                                                                                        }}
                                                                                                        tagProps={{
                                                                                                            className: 'react-tagsinput-tag tag-fill tag-blue',
                                                                                                            classNameRemove: 'react-tagsinput-remove'
                                                                                                        }}
                                                                                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Add upto 3 emails' }}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                            {/* <Col md={12}>
                                                                                                <FormGroup>
                                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                        Subject: 
                                                                                                    </div>
                                                                                                    <FormControl
                                                                                                        value={this.state.PaymentEmail.subject}
                                                                                                        type="text"
                                                                                                        placeholder="Subject"
                                                                                                        name="subject"
                                                                                                        className={this.state.paymentSubjectState}
                                                                                                        onChange={event =>
                                                                                                            this.paymentChange(event, "subject", "paymentSubject", "length", 2)
                                                                                                        }
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </Col>                                                                      */}
                                                                                        </Row>
                                                                                    </Col>                                                                                    
                                                                                </Row>
                                                                                
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                                <div style={{ textAlign: "left" }}>
                                                    <br />
                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerEmailSettingsSave(this.state.Email, this.state.PaymentEmail)}>Save Changes</Button>
                                                </div>
                                                
                                            </Tab.Pane>

                                            {/* Profile Setting Tab */}
                                            <Tab.Pane eventKey="profliekey">
                                                <Row>
                                                    <Col md={12}>
                                                        {this.state.errorMessage != "" &&
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                        <span>
                                                                            {this.state.errorMessage}
                                                                        </span>
                                                                    </Alert>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {this.state.ShowProgressBar &&
                                                            <Row >
                                                                <Col md={12}>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <br />
                                                        <Card
                                                            content={
                                                                <div>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                            First Name: <span className="star">*</span>
                                                                                        </div>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            placeholder="First Name"
                                                                                            name="FirstName"
                                                                                            className={this.state.FirstNameState}
                                                                                            value={this.state.Profile.User.FirstName}
                                                                                            onChange={event => {
                                                                                                this.state.Profile.User.FirstName = event.target.value;
                                                                                                this.profileValueChange(event, "FirstName", "length", 2)
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.FirstNameErrorMessage}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Last Name: <span className="star">*</span>
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            placeholder="Last Name"
                                                                                            autoComplete="off"
                                                                                            name="LastName"
                                                                                            className={this.state.LastNameState}
                                                                                            value={this.state.Profile.User.LastName}
                                                                                            onChange={event => {
                                                                                                this.state.Profile.User.LastName = event.target.value;
                                                                                                this.profileValueChange(event, "LastName", "length", 2)
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.LastNameErrorMessage}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Company: <span className="star"></span>
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            disabled={false}
                                                                                            placeholder="Company"
                                                                                            name="companyname"
                                                                                            className={this.state.companynameState}
                                                                                            value={this.state.Profile.Company.Name}
                                                                                            onChange={event => {
                                                                                                const val = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    Profile: {
                                                                                                        ...prevState.Profile,
                                                                                                        Company: {
                                                                                                            ...prevState.Profile.Company,
                                                                                                            Name: val
                                                                                                        }
                                                                                                    }
                                                                                                }))
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.companynameErrorMessage}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Email adress: <span className="star">*</span>
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="email"
                                                                                            disabled={true}
                                                                                            placeholder="Enter Email"
                                                                                            name="Email"
                                                                                            className={this.state.EmailState}
                                                                                            value={this.state.Profile.User.Email}
                                                                                        />
                                                                                        {this.state.EmailErrorMessage}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Phone Number: <span className="star"></span>
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="number"
                                                                                            placeholder="Phone Number"
                                                                                            className={this.state.PhoneNumberState}
                                                                                            name="Phone"
                                                                                            value={this.state.Profile.Company.Phone}
                                                                                            onChange={event => {
                                                                                                this.state.Profile.Company.Phone = event.target.value;
                                                                                                this.profileValueChange(event, "PhoneNumber", "length", 7);
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.PhoneNumberErrorMessage}

                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Website:
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            placeholder="Website"
                                                                                            name="Website"
                                                                                            value={this.state.Profile.Company.WebSite}
                                                                                            onChange={event => {
                                                                                                const val = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    Profile: {
                                                                                                        ...prevState.Profile,
                                                                                                        Company: {
                                                                                                            ...prevState.Profile.Company,
                                                                                                            WebSite: val
                                                                                                        }
                                                                                                    }
                                                                                                }))
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Address:
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            placeholder="Address"
                                                                                            name="Address"
                                                                                            value={this.state.Profile.Company.Address}
                                                                                            onChange={event => this.AddressChange(event.target.value)}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Country:
                                                                                        </ControlLabel>
                                                                                        <CountryDropdown
                                                                                            value={this.state.Profile.Company.Country}
                                                                                            id="country"
                                                                                            onChange={(val) => this.selectCountry(val)}
                                                                                            classes="form-control"
                                                                                            valueType="short"
                                                                                            whitelist={['GB', 'US', 'CA']}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            State/Region:
                                                                                        </ControlLabel>
                                                                                        <RegionDropdown
                                                                                            country={this.state.Profile.Company.Country}
                                                                                            value={this.state.Profile.Company.State}
                                                                                            defaultOptionLabel="Select State"
                                                                                            countryValueType="short"
                                                                                            id="region"
                                                                                            //labelType="short"
                                                                                            onChange={(val) => this.selectRegion(val)}
                                                                                            classes="form-control"
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            City:
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            placeholder="City"
                                                                                            name="City"
                                                                                            value={this.state.Profile.Company.City}
                                                                                            onChange={event => this.CityChange(event.target.value)}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>
                                                                                            Postal Code: <span className="star"></span>
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            type="number"
                                                                                            placeholder="Postal Code"
                                                                                            name="postalcode"
                                                                                            value={this.state.Profile.Company.PostalCode}
                                                                                            onChange={event => this.PostalCodeChange(event.target.value)}
                                                                                        />

                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <div style={{ textAlign: "left" }}>
                                                                                <br />
                                                                                <Button bsStyle="primary" fill wd onClick={e => this.handlerProfileSave(this.state.Profile)}>Save Changes</Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>

                                            {/* Change Password Tab */}
                                            <Tab.Pane eventKey="changepassword">
                                                <Card
                                                    plain
                                                    content={
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                                {this.state.errorMessage != "" &&
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                                <span>
                                                                                    {this.state.errorMessage}
                                                                                </span>
                                                                            </Alert>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                {this.state.ShowProgressBar &&
                                                                    <Row >
                                                                        <Col md={6}>
                                                                            <CustomProgressBar></CustomProgressBar>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                <br />
                                                                <Row>
                                                                    <Col md={6} >
                                                                        <Card
                                                                            pink
                                                                            content={
                                                                                <div >
                                                                                    <form>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>Current Password
                                                                                                <span className="star"> *</span>
                                                                                            </ControlLabel>
                                                                                            <FormControl
                                                                                                value={this.state.currentPassword}
                                                                                                placeholder="Current Password"
                                                                                                autoComplete="off"
                                                                                                name="currentPassword"
                                                                                                type="password"
                                                                                                className={this.state.currentPasswordState}
                                                                                                onChange={event => {
                                                                                                    this.setState({ currentPassword: event.target.value });
                                                                                                    this.setState({ currentPasswordState: "" });
                                                                                                    this.setState({ "currentPasswordErrorMessage": null });
                                                                                                }
                                                                                                }
                                                                                            />
                                                                                            {this.state.currentPasswordErrorMessage}
                                                                                        </FormGroup>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>New Password  <span className="star">*</span></ControlLabel>
                                                                                            <FormControl
                                                                                                value={this.state.newPassword}
                                                                                                placeholder="New Password"
                                                                                                autoComplete="off"
                                                                                                name="newPassword"
                                                                                                type="password"
                                                                                                className={this.state.newPasswordState}
                                                                                                onChange={event => {
                                                                                                    this.setState({ newPassword: event.target.value });
                                                                                                    this.setState({ newPasswordState: "" });
                                                                                                    this.setState({ newPasswordErrorMessage: null });
                                                                                                }
                                                                                                }
                                                                                            />
                                                                                            {this.state.newPasswordErrorMessage}
                                                                                        </FormGroup>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>Confirm Password  <span className="star">*</span></ControlLabel>
                                                                                            <FormControl
                                                                                                value={this.state.confirmNewPassword}
                                                                                                placeholder="Confirm Password"
                                                                                                autoComplete="off"
                                                                                                name="confirmNewPassword"
                                                                                                type="password"
                                                                                                className={this.state.confirmNewPasswordState}
                                                                                                onChange={event => {
                                                                                                    this.setState({ confirmNewPassword: event.target.value });
                                                                                                    this.setState({ confirmNewPasswordState: "" });
                                                                                                    this.setState({ confirmNewPasswordErrorMessage: null });
                                                                                                }
                                                                                                }
                                                                                                onBlur={e => this.ValidatePasswords()}
                                                                                            />
                                                                                            {this.state.confirmNewPasswordErrorMessage}
                                                                                        </FormGroup>

                                                                                        <br />
                                                                                        <div style={{ textAlign: "left" }}>
                                                                                            <Button bsStyle="primary" fill wd onClick={e => this.saveChangePassword()}>Save Changes</Button>
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    }
                                                />
                                            </Tab.Pane>
                                            
                                            {/* Non-Cash Adjustment Tab */}
                                            <Tab.Pane eventKey="convfee">
                                                {this.state.ShowProgressBar &&
                                                    <div>
                                                        <CustomProgressBar></CustomProgressBar>
                                                        <br />
                                                    </div>
                                                }
                                                {this.state.errorMessage != "" &&
                                                    <div>
                                                        <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                            <span>
                                                                {this.state.errorMessage}
                                                            </span>
                                                        </Alert>
                                                    </div>
                                                }                                                
                                                <Card
                                                    title="Non-Cash Adjustment"
                                                    content={
                                                        <div>
                                                            <div style={{ textAlign: "left" }}>
                                                                <br />                                                                
                                                                {this.state.ConvFee == "" && 
                                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerConvFeeCreatePopup()}>Create New</Button>
                                                                }
                                                                {this.state.ConvFee != "" &&
                                                                    <div style={{width: "700px"}}>
                                                                        <Card
                                                                            content={
                                                                                <div>
                                                                                    <br/>
                                                                                    <div style={{ color: "white", backgroundColor: "#0075c1" }}>
                                                                                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                                            <Col xs={6} sm={6} md={6} style={{ marginTop: 5 }}>
                                                                                                <h5>Title/Name</h5>
                                                                                            </Col>
                                                                                            <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                                <h5> Amount  </h5>
                                                                                            </Col>
                                                                                            <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                                <h5>Type </h5>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                                                        <Col xs={6} sm={6} md={6} style={{ marginTop: 5 }}>
                                                                                            <h5>{this.state.ConvFee.name}</h5>
                                                                                        </Col>
                                                                                        <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                            <h5>{parseFloat(this.state.ConvFee.value).toFixed(2)}</h5>
                                                                                        </Col>
                                                                                        <Col xs={3} sm={3} md={3} style={{ marginTop: 5 }}>
                                                                                            <h5>{this.state.ConvFee.is_percentage == true ? "Percent" : "Fixed Amount"} </h5>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <br/>
                                                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerConvFeeDeletePopup()}>Delete </Button>
                                                                                    
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                                <Modal show={this.state.showConvFeePopupModal} onHide={() => this.setState({ showConvFeePopupModal: false })}>
                                                    {this.state.ShowPopupProgressBar &&
                                                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                            <CustomProgressBar></CustomProgressBar>
                                                        </div>
                                                    }

                                                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                        <Modal.Title style={{ color: "white" }}>Create Non-Cash Adjustment</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="modal-body text-center">
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Title : </h5>
                                                                </Col> 
                                                                <Col xs={12} sm={12} md={6} className="text-left">
                                                                    <FormGroup>                                                                       
                                                                        <FormControl
                                                                            value={this.state.ConvFeeModel.Title}
                                                                            type="text"
                                                                            placeholder="Title"
                                                                            name="convfeetitle"
                                                                            onChange={event => {
                                                                                var value = event.target.value;
                                                                                this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        Title: value
                                                                                    }
                                                                                }));
                                                                                if(this.verifyLength(value, 2))
                                                                                {
                                                                                    this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        convFeeTitleState: "valid",
                                                                                        convFeeTitleErrorMessage: ""
                                                                                    }
                                                                                    }));
                                                                                }
                                                                                else{
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        ConvFeeModel: {
                                                                                            ...prevState.ConvFeeModel,
                                                                                            convFeeTitleState: "invalid",
                                                                                            convFeeTitleErrorMessage: (
                                                                                                <small className="text-danger">
                                                                                                    Title must be at least 2 characters.
                                                                                                </small>
                                                                                            )
                                                                                        }
                                                                                    }));
                                                                                }                                                    
                                                                            }
                                                                            }
                                                                            onBlur={event => {
                                                                                var value = event.target.value;
                                                                                if(this.verifyLength(value, 2))
                                                                                {
                                                                                    this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        convFeeTitleState: "valid",
                                                                                        convFeeTitleErrorMessage: ""
                                                                                    }
                                                                                }));
                                                                                }
                                                                                else{
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        ConvFeeModel: {
                                                                                            ...prevState.ConvFeeModel,
                                                                                            convFeeTitleState: "invalid",
                                                                                            convFeeTitleErrorMessage: (
                                                                                                <small className="text-danger">
                                                                                                    Title must be at least 2 characters.
                                                                                                </small>
                                                                                            )
                                                                                        }
                                                                                    }));
                                                                                }  
                                                                            }}
                                                                        />
                                                                        {this.state.ConvFeeModel.convFeeTitleErrorMessage}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Description : </h5>
                                                                </Col> 
                                                                <Col xs={12} sm={12} md={6} className="text-left">
                                                                    <FormGroup>                                                                       
                                                                        <FormControl
                                                                            value={this.state.ConvFeeModel.Description}
                                                                            type="text"
                                                                            placeholder="Description"
                                                                            name="convfeedescription"
                                                                            onChange={event => {
                                                                                var value = event.target.value;
                                                                                this.setState(prevState => ({
                                                                                    ...prevState,
                                                                                    ConvFeeModel: {
                                                                                        ...prevState.ConvFeeModel,
                                                                                        Description: value
                                                                                    }
                                                                                }));                                                   
                                                                            }
                                                                            }
                                                                            
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row> 
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Type : </h5>
                                                                </Col>                                                                                                      
                                                                <Col md={3} style={{marginTop: "-5px", marginLeft: "-25px"}}>
                                                                    <Radio
                                                                        number="percentage"
                                                                        option="percentage"
                                                                        name="radio"
                                                                        onChange={this.handleConvFeeRadio}
                                                                        checked={this.state.ConvFeeModel.IsPercentage}
                                                                        className="PrivateSwitchBase-input-538"
                                                                        label="Percent"
                                                                    />
                                                                </Col>
                                                                <Col md={4} style={{marginTop: "-5px"}}>
                                                                    <Radio
                                                                        number="fixedamount"
                                                                        option="fixedamount"
                                                                        name="radio"
                                                                        onChange={this.handleConvFeeRadio}
                                                                        checked={this.state.ConvFeeModel.IsFixed}
                                                                        label="Fixed Amount"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Amount/Rate :</h5>
                                                                </Col>
                                                                <Col style={{ textAlign: "right" }} xs={12} sm={12} md={3}>
                                                                    <InputGroup>
                                                                        {this.state.ConvFeeModel.IsFixed && 
                                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                                        }    
                                                                        <FormControl
                                                                            value={parseFloat(this.state.ConvFeeModel.Amount).toFixed(2)}
                                                                            type="number"
                                                                            name="amount"
                                                                            min="0.01"
                                                                            style={{ textAlign: "right" }}
                                                                            step="0.01"
                                                                            onChange={this.handleConvFeeAmountChange}
                                                                            onBlur={this.handleConvFeeAmountChange}                                                                            
                                                                        />
                                                                        {this.state.ConvFeeModel.IsPercentage &&
                                                                            <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-percent" /></InputGroup.Addon>
                                                                        }
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4}>                                                                   
                                                                </Col>
                                                                <Col style={{ textAlign: "left", marginTop: "-5px" }} xs={12} sm={12} md={8}>                                                                    
                                                                        {this.state.ConvFeeModel.convFeeAmountErrorMessage}                                                                    
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showConvFeePopupModal: false })}>Close</Button>
                                                        <Button bsStyle="primary" fill wd onClick={e => this.handleConvFeeCreate(this.state.ConvFeeModel)}>Submit</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                <Modal show={this.state.showConvFeeDeletePopupModal} onHide={() => this.setState({ showConvFeeDeletePopupModal: false })}>
                                                    {this.state.ShowPopupProgressBar &&
                                                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                            <CustomProgressBar></CustomProgressBar>
                                                        </div>
                                                    }
                                                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                                        <Modal.Title style={{ color: "white" }}>Delete Non-Cash Adjustment</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="modal-body text-center">
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Title : </h5>
                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                    <h5>{this.state.ConvFee.name}</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Amount :</h5>
                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                    <h5>{parseFloat(this.state.ConvFee.value).toFixed(2)}</h5>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={4} className="text-left">
                                                                    <h5>Type :</h5>
                                                                </Col> <Col xs={12} sm={12} md={8} className="text-left">
                                                                     <h5>{this.state.ConvFee.is_percentage == true ? "Percent" : "Fixed Amount"} </h5>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showConvFeeDeletePopupModal: false })}>Close</Button>
                                                        <Button bsStyle="primary" fill wd onClick={e => this.handleDeleteConvFeeSubmit(this.state.ConvFee)}>Submit</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </Tab.Pane>

                                            {/* Other Settings Tab */}
                                            <Tab.Pane eventKey="other">
                                                {this.state.ShowProgressBar &&
                                                    <div>
                                                        <CustomProgressBar></CustomProgressBar>
                                                        <br />
                                                    </div>
                                                }
                                                {this.state.errorMessage != "" &&
                                                    <div>
                                                        <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                            <span>
                                                                {this.state.errorMessage}
                                                            </span>
                                                        </Alert>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <Card
                                                        title="Receipt"
                                                        content={
                                                            <div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <FormGroup style={{ marginTop: '5px' }}>
                                                                            <Checkbox
                                                                                number="ShowConfirmationBeforeSendingInvoice"
                                                                                label="Show confirmation before sending invoice."
                                                                                onChange={this.handleShowConfirmationBeforeSendingInvoiceCheck}
                                                                                checked={this.state.otherOptions.isShowConfirmationBeforeSendingInvoiceChecked}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup style={{ marginTop: '20px' }}>
                                                                            <Checkbox
                                                                                number="SendPaymentReceiptAfterTrasnsaction"
                                                                                label="Send payment receipt after trasnsaction."
                                                                                onChange={this.handleSendPaymentReceiptAfterTrasnsactionCheck}
                                                                                checked={this.state.otherOptions.isSendPaymentReceiptAfterTrasnsactionChecked}
                                                                            />
                                                                        </FormGroup>
                                                                        {/* <FormGroup style={{ marginTop: '20px' }}>
                                                                        <Checkbox
                                                                            number="EnableFeedbackOnCustomerPortal"
                                                                            label="Enable Feedback on customer portal."
                                                                            onChange={this.handleEnableFeedbackOnCustomerPortalCheck}
                                                                            checked={this.state.otherOptions.isEnableFeedbackOnCustomerPortalChecked}
                                                                        />
                                                                    </FormGroup> */}
                                                                        {/* <FormGroup style={{ marginTop: '20px' }}>
                                                                        <Checkbox
                                                                            number="EnableInvoiceDefaultTemplate"
                                                                            label="Enable QuickBooks Invoice default template."
                                                                            onChange={this.handleEnableInvoiceDefaultTemplateCheck}
                                                                            checked={this.state.otherOptions.isEnableInvoiceDefaultTemplateChecked}
                                                                        />
                                                                    </FormGroup> */}
                                                                    </Col>
                                                                </Row>
                                                                {/* <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <div style={{ marginTop: '10px', fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                            Deposit To Account:
                                                                        </div>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="depositToAccounts"
                                                                            value={this.state.otherOptions.selectedDepositToAccount}
                                                                            onChange={value =>
                                                                                this.setState(prevState => ({
                                                                                    otherOptions: {
                                                                                        ...prevState.otherOptions,
                                                                                        selectedDepositToAccount: value
                                                                                    }
                                                                                }))
                                                                            }
                                                                            options={this.state.otherOptions.depositToAccounts}
                                                                            placeholder="Select Deposit To Account"
                                                                        />
                                                                    </FormGroup>

                                                                </Col>
                                                            </Row> */}
                                                                {/* <div style={{ textAlign: "left" }}>
                                                                <br />
                                                                <Button bsStyle="primary" fill wd onClick={e => this.handlerAppOptionsSettingsSave(this.state.otherOptions)}>Save Changes</Button>
                                                            </div> */}
                                                            </div>
                                                        }

                                                    />
                                                }
                                                <Card
                                                    title="QuickBooks Online"
                                                    content={
                                                        <div>

                                                            <Row>
                                                                <Col md={12}>
                                                                    <FormGroup style={{ marginTop: '5px' }}>
                                                                        <Checkbox
                                                                            number="EnableInvoiceDefaultTemplate"
                                                                            label="Enable QuickBooks Invoice default template."
                                                                            onChange={this.handleEnableInvoiceDefaultTemplateCheck}
                                                                            checked={this.state.otherOptions.isEnableInvoiceDefaultTemplateChecked}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <div style={{ marginTop: '5px', fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                            Deposit To Account:
                                                                        </div>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="depositToAccounts"
                                                                            value={this.state.otherOptions.selectedDepositToAccount}
                                                                            onChange={value =>
                                                                                this.setState(prevState => ({
                                                                                    otherOptions: {
                                                                                        ...prevState.otherOptions,
                                                                                        selectedDepositToAccount: value
                                                                                    }
                                                                                }))
                                                                            }
                                                                            options={this.state.otherOptions.depositToAccounts}
                                                                            placeholder="Select Deposit To Account"
                                                                        />
                                                                    </FormGroup>

                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                            Default Terms:                                                                           
                                                                        </div>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="terms"
                                                                            isDisabled={this.state.ShowProgressBar}
                                                                            value={this.state.otherOptions.defaultTerm || "Due on receipt"}
                                                                            onChange={value =>
                                                                                this.setState(prevState => ({
                                                                                    otherOptions: {
                                                                                        ...prevState.otherOptions,
                                                                                        defaultTerm: value
                                                                                    }
                                                                                }))
                                                                            }
                                                                            options={this.state.terms}
                                                                            placeholder="Select Term"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <div style={{ textAlign: "left" }}>
                                                                <br />
                                                                <Button bsStyle="primary" fill wd onClick={e => this.handlerAppOptionsSettingsSave(this.state.otherOptions)}>Save Changes</Button>
                                                            </div>
                                                        </div>
                                                    }

                                                />
                                            </Tab.Pane>

                                            {/* Template Setting Tab */}
                                            <Tab.Pane eventKey="template">
                                                <Card
                                                    content={
                                                        <div>
                                                        </div>
                                                    }

                                                />
                                            </Tab.Pane>

                                            {/* Hardware/Terminal Setting Tab */}
                                            <Tab.Pane eventKey="terminal">
                                                <Card
                                                    content={
                                                        <div style={{ textAlign: "left" }}>
                                                            <br />
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Col md={4}>
                                                                        <Button bsStyle="primary" fill wd onClick={e => this.handlerIngenicoISC250(true)}>Ingenico iSC250</Button>
                                                                    </Col>
                                                                    <Col md={8}>
                                                                        {this.state.terminalOptions.ISC250Terminal && <ISC250Terminal isModalDialogShow={true} parentCallback={this.ISC250CallbackFunction}></ISC250Terminal>}

                                                                    </Col>
                                                                </Col>
                                                            </Row>
                                                            {/* <ISC250Terminal></ISC250Terminal> */}

                                                        </div>

                                                    }

                                                />
                                            </Tab.Pane>
                                        
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    }
                />
            </div>
        );
    }
}


export default Settings;