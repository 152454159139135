import React from 'react';
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import boltLogo from "assets/img/icons/boltlogo.png";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import paidimage from "assets/img/paidimage.png";
import SessionTimeout from 'components/SessionTimeout';
import Bolt from './Bolt';
import Autocomplete from "react-google-autocomplete";
import { Input } from "@material-ui/core";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Form,
    OverlayTrigger,
    Tooltip,
    Modal,
    ButtonGroup
} from "react-bootstrap";


const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
        color: "green",//successColor[2], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    errorResponse: {
        color: "red",//dangerColor[0], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    watermark: {
        content: "",
        backgroundImage: `url(${paidimage})`,
        opacity: "0.2",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        position: "absolute"
    },
    disabledbutton: {
        pointerEvents: "none",
        opacity: 0.4
    }
}


class CCPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Customer: {
                CardHolderName: '',
                AccountType: 'checking',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: true,
                CardHolderNameValid: true,
                AccountNameValid: true,
                IsTokenValid: false,
                AmountValid: true,
                FormErrors: { CardHolderName: '', AccountName: '' },
                AccountNumber: "",
                RoutingNumber: "",
                AccountNumberValid: true,
                RoutingNumberValid: true,
                IsPostalCodeAvailable: true,
                IsCityAvailable: true,
            },
            Invoice: [],
            invoiceNumber: "",
            customerName: "",
            invoiceDate: "",
            dueDate: "",
            Amount: 0.00,
            totalAmount: 0.00,
            amountToCharge: 0.00,
            remainingBalance: 0.00,
            customerEmail: "",
            invoiceData: [],
            IsCreditCard: true,
            IsDebitCard: false,
            IsACH: false,
            IsCreditCardEnabled: true,
            IsDebitCardEnabled: true,
            IsACHEnabled: true,
            StatusCode: false,
            StatusMessage: "",
            isPaid: false,
            ccProfiles: [],
            achProfiles: [],
            isProfileSelected: false,

            emvToken: '',
            expiryDate: '',
            hostName: '',
            hostPort: '',
            copilotURL: '',
            boltIcon: boltLogo,
            showBoltPaymentModal: false,
            isAuthenticated: true,
            ConvFee: "",
            ConvFeeAmount: 0.00,
            groupButtonCreditCardStyle: "primary active",
            groupButtonDebitCardStyle: "default",
            groupButtonACHStyle: "default",
            lastState: "creditcard",
        }
        this.handleCharged = this.handleCharged.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null || localStorage.getItem(StorageEnum.IS_FROM_CP) == "true" || (localStorage.getItem("login_user_type") != "Merchant" && localStorage.getItem("login_user_type") != "Staff")){
            this.props.history.push('/account/login');
        }
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        } else {
            const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
            this.state.copilotURL = data_tokenize.PPToken;
            let ach = (localStorage.getItem("is_ach_enabled") == "true")           
            let creditcard = (localStorage.getItem("is_creditcard_enabled") == "true")     
            let debitcard = (localStorage.getItem("is_debitcard_enabled") == "true")   
            this.setState({IsACH: ach, IsACHEnabled: ach })
            this.setState({IsCreditCard: creditcard, IsCreditCardEnabled: creditcard, IsDebitCardEnabled: debitcard })
            if(ach && creditcard){
                this.setState({IsACH: false })
            }
        }

        this.state.invoiceData = this.props.location.state ? this.props.location.state.invoiceData : JSON.parse(localStorage.getItem("Invoice"));
        
        if (this.state.invoiceData !== null) {
            this.setState({ Invoice: this.state.invoiceData || [] });
            this.setState({ Amount: this.state.invoiceData.Balance });
            this.setState({ totalAmount: this.state.invoiceData.Balance });
            this.setState({ amountToCharge: parseFloat(this.state.invoiceData.Balance).toFixed(2) });
            this.setState({ customerEmail: this.state.invoiceData.BillEmail ? this.state.invoiceData.BillEmail.Address : "" });
            this.setState({ invoiceNumber: this.state.invoiceData.DocNumber });
            this.setState({ customerName: this.state.invoiceData.CustomerRef.name });
            this.setState({ invoiceDate: this.state.invoiceData.TxnDate });
            this.setState({ dueDate: this.state.invoiceData.DueDate });
            global.InvoiceAmount = parseFloat(this.state.invoiceData.Balance).toFixed(2);
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, CardHolderName: this.state.invoiceData.CustomerRef.name,
                    AccountName: this.state.invoiceData.CustomerRef.name,
                    Address: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.Line1 : "",
                    Country: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.Country : "",
                    State: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.CountrySubDivisionCode : "",
                    City: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.City : "",
                    PostalCode: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.PostalCode : "",
                    ProfileId: {value: "none", label: "None"},
                }
            }));   
            this.GetConvFee();        
            this.getPaymentProfiles(this.state.invoiceData.CustomerRef.value);
        }
        else
        {
             this.props.history.push('/invoice/invoices');
        }
        document.getElementById("loaderDiv").style.display = "none";
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
         const app_id = 2;
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } 
            }).catch(error => {  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
        {
            let convFeeAmount = 0.00;
            if(convfee[0].is_percentage){
                convFeeAmount = (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) 
                this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) })
            }
            else{
                convFeeAmount =  parseFloat(convfee[0].value)
                this.setState({ ConvFeeAmount : parseFloat(convfee[0].value)});
            }            
            this.setState({ ConvFee: convfee[0] }); 
            
            global.InvoiceAmount = parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2);
            this.setState({ Amount: parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2) });

            this.setState({ totalAmount: parseFloat(parseFloat(this.state.invoiceData.Balance ) + convFeeAmount).toFixed(2) });
            this.setState({ amountToCharge: parseFloat(parseFloat(this.state.invoiceData.Balance ) + convFeeAmount).toFixed(2) });

        }
        this.setState({ showLoader: false });   
    }
    
    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }

    callbackFunction = (childData) => {
        this.setState({ showBoltPaymentModal: childData })
    }

    handleTokenEvent = (event) => {
        window.addEventListener(
            'message',
            (event) => {
                const token = JSON.parse(event.data)
                let mytoken = document.getElementById('mytoken')
                mytoken.value = token.message;

                if (token.errorCode == "0" && token.errorMessage == "") {
                    this.setState({ IsTokenValid: true });                   
                }
                else {
                    this.setState({ IsTokenValid: false });
                }
                const expField = token.expiry

                const tkn = mytoken.value
                const dte = expField

                this.setState({ emvToken: tkn, expiryDate: dte });

                const emvData = {  token: tkn, expiryDate: dte };
                 this.validateForm();
                // this.props.tokenProps.userEmvData(emvData)
                event.preventDefault()
            },
            false
        )
    }

    handleAmountChange = e => {
        const { name, value } = e.target;
        if (parseFloat(value) > parseFloat(this.state.totalAmount)) {
            this.state.Customer.AmountValid = false;
            this.validateField(name, value)
            return false;
        }

        if (parseFloat(value) <= 0) {
            this.state.Customer.AmountValid = false;
            this.validateField(name, value)
            //  return false;
        }
        else {
            this.state.Customer.AmountValid = true;
        }

        this.setState(prevState => ({
            ...prevState.Amount, Amount: value
        }), () => { this.validateField(name, value) })

        this.setState({ remainingBalance: parseFloat(this.state.totalAmount) - parseFloat(value) })
        this.setState({ amountToCharge: value });
    };

    ccHandleRadio = (event) => {
        const target = event.target;
        this.setState({ StatusMessage: "" });
        if (target.name === "creditcard" && this.state.lastState != "creditcard") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: false,
                    ProfileId: {label: "None",value: "none"}
                }
            }));
            this.setState({ lastState: target.name});
            global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) + parseFloat(this.state.ConvFeeAmount)).toFixed(2);
            this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.setState({ totalAmount: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.setState({ amountToCharge: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.state.groupButtonCreditCardStyle = "primary active";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "default";
        }
        else if (target.name === "debitcard") {
            this.setState({ IsDebitCard: true });
            this.setState({ IsCreditCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: false,
                    ProfileId: {label: "None",value: "none"}
                }
            }));
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ totalAmount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ amountToCharge: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "primary active";
            this.state.groupButtonACHStyle= "default";
        }
        else if(target.name === "ach") {
            this.setState({ IsACH: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsCreditCard: false });
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ totalAmount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ amountToCharge: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            if (this.state.Customer.AmountValid === true) {
                this.setState(prevState => ({
                    Customer: {
                        ...prevState.Customer,
                        FormValid: false,
                        ProfileId: {label: "None",value: "none"}
                    }
                }));
            }
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "primary active";
        }


        this.setState({ isProfileSelected: false });        
    };

    handleRateKeyPress = (e) => {
        var charCode = (e.which) ? e.which : e.keyCode;        
        this.checkIsNumeric(e);
    };

    checkIsNumeric = (e) => {      
        var charCode = (e.which) ? e.which : e.keyCode;          
        if(e.target.value.indexOf(".") !== -1)
        {
            if (charCode == 46 || e.target.value.length > 16) {
                e.preventDefault();
            }
        }
        else if (charCode != 46 && (!/^\d*$/.test(e.target.value) || e.target.value.length > 16)) {
            e.preventDefault();
        }
    };

    handleKeyPressInput(e) { 
        var charCode = (e.which) ? e.which : e.keyCode;
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };

    handleOnBlurInput(e) {
        // console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        const name = e.target.name;
        switch (name) {
            case "AccountNumber":
            case "RoutingNumber":
                if (this.state.Customer.RoutingNumber && this.state.Customer.AccountNumber) {
                    let account = `${this.state.Customer.RoutingNumber}/${this.state.Customer.AccountNumber}`
                    const payload = {
                        "account": account,
                        "source": "iToke",
                        "encryptionhandler": null,
                        "unique": false,
                        "expiry": null,
                        "cvv": null
                    }
                    this._postCardSecureTokenize(payload).then(event => {
                        let mytoken = document.getElementById('mytoken')
                        mytoken.value = event.token;

                        if (event.errorcode == 0) {

                            let acct_number = document.getElementById('accountnumber')
                            acct_number.value = "XXXXX" + acct_number.value.slice(-4);

                            let rout_number = document.getElementById('routingnumber')
                            rout_number.value = "XXXXX" + rout_number.value.slice(-4);

                            this.setState(prevState => ({
                                Customer:
                                {
                                    ...prevState.Customer, AccountNumber: "XXXXX" + acct_number.value.slice(-4)
                                }
                            }))

                            this.setState(prevState => ({
                                Customer:
                                {
                                    ...prevState.Customer, RoutingNumber: "XXXXX" + rout_number.value.slice(-4)
                                }
                            }))                            
                        }
                        this.setState({
                            IsTokenValid: event.errorcode == 0 ? true : false
                        });
                        this.setState({
                            emvToken: event.token,
                            expiryDate: null
                        })
                        this.validateForm();                        

                        event.preventDefault()
                    }).catch(error => {
                        // console.log(error)
                    })
                }
                break;
            default:
                break;
        }

        this.validateField(e.target.name, e.target.value);

    };

    handleProfileChanged(prof) {
        if (prof.value != "none") {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, IsCreateProfile: false
                }
            }));

            this.setState({ isProfileSelected: true });
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer,
                    ProfileId: prof.value,
                    CardHolderName: prof.cardholder_name,
                    Address: prof.address,
                    Country: prof.country,
                    State: prof.state,
                    City: prof.city,
                    PostalCode: prof.postal_code,

                }
            }));

        }
        else {
            this.setState({ isProfileSelected: false });
        }
    }

    handleUserInput(e) {
        // console.log('handleUserInput is calling..' + e.target.name);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'CardHolderName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardHolderName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Address: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, PostalCode: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                this.setState(prevState => ({
                    Amount:
                    {
                        ...prevState.Amount, Amount: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CreateProfileCheckBox":
                // console.log(e.target.checked);
                var cbValue = e.target.checked;
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, IsCreateProfile: cbValue
                    }
                }));
                break;            
            case "AccountName":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountType":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountType: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, RoutingNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            default:
                break;
        }

    }

    handleBoltPayment(e) {  
            
        if (this.state.showBoltPaymentModal) {
            this.setState({ showBoltPaymentModal: false });
            setTimeout(() => {
                this.setState({ showBoltPaymentModal: true });
            }, 100);
        }
        else {
            this.setState({ showBoltPaymentModal: true });
            
            const invoice_info = this.state.invoiceData;
            const payment_info = this.state.Customer;
            const amount = this.state.Amount;
     
            let cardInfo = null;
            let achInfo = null;
            let profileInfo = null;
    
            const data = {
                "InvoiceInfo": {
                    "Id": invoice_info.Id,
                    "DocNumber": [invoice_info.DocNumber],
                    "Type": "payment",
                    "Invoice": invoice_info
                },
                "SalesReceiptInfo": {
                    "Id": [],
                    "DocNumber": [],
                    "Type": "salesreceipt"
                },
                "PaymentInfo": {
                    "CardInfo": cardInfo,
                    "AchInfo": achInfo,
                    "ProfileInfo": profileInfo,
                    "BillingInfo": {
                        "Country": payment_info.Country,
                        "City": payment_info.City,
                        "State": payment_info.State,
                        "Street": payment_info.Address,
                        "PostalCode": payment_info.PostalCode
                    },
                    "TransactionInfo": {
                        "TransType": "bolt_sale",
                        "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                        "Amount": amount,
                        "ActionType": "invoice",
                        "CurrencyCode": "USD"
                    }
                },
                "CustomerRef": {
                    "Name": invoice_info.CustomerRef ? invoice_info.CustomerRef.name : null,
                    "Value": invoice_info.CustomerRef ? invoice_info.CustomerRef.value : null,
                    "Email": invoice_info.BillEmail ? invoice_info.BillEmail.Address : null
                }
            }

            setTimeout(() => {
                this._postProcessBoltRequest(data);
            }, 150);
        }
    }

    handleCancelPayment(e) {
        this.props.history.push('/invoice/invoices');
        e.preventDefault();
    };

    handleSubmit = (event) => {
        this.validateForm();
        if(this.state.isProfileSelected)
        {
             this.setState({ ShowProgressBar: true });
            setTimeout(() => {
                this.handleCharged(this.state.Customer.ProfileId);
            }, 500);
        }
        else if(this.state.IsACH || (this.state.IsTokenValid && this.state.emvToken != ""))
        {
            this.handleCharged(this.state);
        }
        else{
             event.preventDefault();
             return false;
        }
    }

    handleCharged = (value) => {
        const invoice_info = this.state.invoiceData;
        const payment_info = this.state.Customer;
        const amount = this.state.Amount;
 
        let cardInfo = null;
        let achInfo = null;
        let profileInfo = {
            "ProfileId": null,
            "CreateProfile": payment_info.IsCreateProfile
        }
        if (this.state.isProfileSelected) {
            let card_type2 = "";
            if(this.state.IsCreditCard)
            {
                card_type2 = "credit"
            }
            else if(this.state.IsDebitCard)
            {
                card_type2 = "debit"
            }
            else
            {
                card_type2 = "ach"
            }
            profileInfo = {
                "ProfileId": payment_info.ProfileId.value,
                "CreateProfile": payment_info.IsCreateProfile,
                "FirstName": payment_info.CardHolderName,
                "LastName": null,
                "CardHolder": payment_info.CardHolderName,
                "CardType": card_type2,
                "COF": "M"
            }
        } else if (this.state.IsCreditCard || this.state.IsDebitCard) {
            cardInfo = {
                "CardHolder": payment_info.CardHolderName,
                "CardNumber": value.emvToken,
                "Cvv": null,
                "Month": null,
                "Year": null,
                "CardType": null,
                "Token": value.emvToken
            }
        } else if (this.state.IsACH) {
            achInfo = {
                "AccountHolder": payment_info.AccountName,
                "AccountNumber": null,
                "RoutingNumber": null,
                "AccountType": "",//payment_info.AccountType,
                "Token": value.emvToken
            }
        }

        const data = {
            "InvoiceInfo": {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "payment",
                "Invoice": invoice_info
            },
            "SalesReceiptInfo": {
                "Id": [],
                "DocNumber": [],
                "Type": "salesreceipt"
            },
            "PaymentInfo": {
                "CardInfo": cardInfo,
                "AchInfo": achInfo,
                "ProfileInfo": profileInfo,
                "BillingInfo": {
                    "Country": payment_info.Country,
                    "City": payment_info.City,
                    "State": payment_info.State,
                    "Street": payment_info.Address,
                    "PostalCode": payment_info.PostalCode
                },
                "TransactionInfo": {
                    "TransType": this.state.isProfileSelected ? "profile_sale" : this.state.IsACH ? "ach_sale" : "sale",
                    "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                    "Amount": parseFloat(amount).toFixed(2),
                    "ActionType": "invoice",
                    "CurrencyCode": "USD"
                }
            },
            "CustomerRef": {
                "Name": invoice_info.CustomerRef ? invoice_info.CustomerRef.name : null,
                "Value": invoice_info.CustomerRef ? invoice_info.CustomerRef.value : null,
                "Email": invoice_info.BillEmail ? invoice_info.BillEmail.Address : null
            }
        }
        console.log(data);
        this._postCollectPaymentRequest(data)
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.Customer.FormErrors;
        let cardHolderNameValid = this.state.Customer.CardHolderNameValid;

        let accountNameValid = this.state.Customer.AccountNameValid;
        let amountValid = this.state.Customer.AmountValid;
        switch (fieldName) {
            case 'CardHolderName':
                cardHolderNameValid = true; // value.match(/^[a-zA-Z ]{2,30}$/); 
                fieldValidationErrors.CardHolderName = cardHolderNameValid ? '' : ' is invalid';
                this.state.Customer.CardHolderNameValid = cardHolderNameValid ? true : false;
                break;            
            case 'Amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.Customer.AmountValid = amountValid ? true : false;
                this.state.Amount = value;
                break;
            case 'AccountName':
                accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.Customer.AccountNameValid = accountNameValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            CardHolderNameValid: cardHolderNameValid,
            AccountNameValid: accountNameValid,
            AmountValid: amountValid
        }, this.validateForm);

    }

    validateForm() {
        if (this.state.IsCreditCard || this.state.IsDebitCard) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: (this.state.IsCreditCard || this.state.IsDebitCard) 
                        && this.state.Customer.CardHolderNameValid && this.state.Customer.AmountValid                       
                }
            }), () => console.log())
        }
        else if (this.state.IsACH) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: this.state.Customer.AccountNameValid 
                        && this.state.Customer.AmountValid 
                }
            }), () => console.log())
        }
    }

    setAddressFields(jsonData) {       
        let isPostalCodeAvailable = false;
        let isCityAvailable = false;
        
        // Loop through the address components
        for (const component of jsonData.address_components) {
          // Extract the first element of the "types" array          
          if (component.types.includes("postal_code")) {
                {
                    isPostalCodeAvailable = true;
                }
            }
            if (component.types.includes("locality")) {
                {
                    isCityAvailable = true;
                }
            }
            
          const firstType = component.types[0];
      
          // Set the variables based on the first type
          switch (firstType) {
            case 'postal_code':
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, PostalCode: component.short_name
                }
            }))
              break;
            case 'locality':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: component.short_name
                    }
                }))
              break;
            case 'administrative_area_level_1':              
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: component.short_name
                    }
                }))
              break;
            case 'country':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: component.short_name
                    }
                }))
              break;
          }
        }
        
        if(!isPostalCodeAvailable){
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, PostalCode: ""
                }
            }))
        }
        if(!isCityAvailable){
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, City: ""
                }
            }))
        }

        this.setState(prevState => ({
            Customer:
            {
                ...prevState.Customer, IsPostalCodeAvailable: isPostalCodeAvailable, IsCityAvailable: isCityAvailable
            }
        }))
        
    }

      
    renderTokenizerUi() {
        // const url = `https://${this.site}.cardconnect.com:${this.port}/itoke/ajax-tokenizer.html`
        const url = `${this.state.copilotURL}/itoke/ajax-tokenizer.html`
        var padding = this.state.IsACH ? "0px 0 7px"  : "6px 0 7px";
        const cssStyle = 'css=.success{color:green;border-color:green;}.error{color:red;border-color:red;}label{font-family:sans-serif;font-weight:bold;font-size:14px;margin-top:12px;margin-bottom:0px;line-height:1.42857;color:rgba(0, 0, 0, 0.54)}input{font:inherit;color: currentColor;font-family:sans-serif;font-size:18px;margin-top:-5px;color:currentColor;width:100%;border: 0px;border-bottom: 1px solid;border-bottom-color: rgba(227,227,227,1); outline: none;display:block;padding:' + padding + ';margin-bottom: -6px;background:none;box-sizing: content-box;}select{font:inherit;color: currentColor;outline: none;font-family:sans-serif;height:1.1875em;font-size:18px;margin-top:-5px;color:currentColor;border:0;border-bottom: 1px solid; border-bottom-color: rgba(227,227,227,1);padding: 6px 0 7px;background:none;box-sizing: content-box; width:44%;margin-bottom: 10px;}';

        const params = new URLSearchParams({
            maskfirsttwo: false,
            useexpiry: this.state.IsACH ? false : true,
            usemonthnames: this.state.IsACH ? false : true,
            usecvv: this.state.IsACH ? false : true,
            cardnumbernumericonly: this.state.IsACH ? false : true,
            orientation: 'default',
            invalidinputevent: true,
            enhancedresponse: true,
            formatinput: true,
            tokenizewheninactive: true,
            inactivityto: this.state.IsACH ? 5000 : 4000,
            fullmobilekeyboard: true
        })

        const iFrameUrl = url + '?' + params + '&' + encodeURI(cssStyle)

        return (
            <Card
                content={
                    <div>
                        {this.state.ShowProgressBar &&
                            <div style={{ marginBottom: "2px" }}>
                                <CustomProgressBar></CustomProgressBar>
                            </div>

                        }
                        {this.state.StatusCode == 0 &&
                            <p style={styles.successResponse}>{this.state.StatusMessage}</p>
                        }
                        {this.state.StatusCode != 0 &&
                            <p style={styles.errorResponse}>{this.state.StatusMessage}</p>
                        }
                        <div style={{ display: this.state.IsACH ? "none" : "block" }}>
                            {this.state.ccProfiles.length > 2 &&
                                <Row>
                                    <Col xs={12} sm={12} md={12}>
                                        <InputLabel style={styles.dropDownFontStyle}>
                                            Select Profile
                                        </InputLabel>
                                        <Select
                                            classNamePrefix="react-select"
                                            name="ccProfiles"
                                            isDisabled={this.state.isPaid || this.state.invoiceData.IsPaid || this.state.ShowProgressBar}
                                            value={this.state.Customer.ProfileId}
                                            onChange={profile => {
                                                this.handleProfileChanged(profile)
                                                this.setState(prevState => ({
                                                    Customer:
                                                    {
                                                        ...prevState.Customer, ProfileId: profile
                                                    }
                                                }));
                                            }
                                            }
                                            options={this.state.ccProfiles}
                                            placeholder="Select Profile"
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <InputLabel style={styles.dropDownFontStyle}>
                                        Name on Card
                                    </InputLabel>
                                    <CustomInput
                                        isRequired={true}
                                        id="CardHolderName"
                                        name="CardHolderName"
                                        value={this.state.Customer.CardHolderName}
                                        showErrorMessage={!this.state.Customer.CardHolderNameValid}
                                        errorMessage="Card holder name is invalid"
                                        formControlProps={{
                                            fullWidth: true,
                                            disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,                                            
                                            error: !this.state.Customer.CardHolderNameValid,
                                            onChange: (e) => { this.handleUserInput(e) },
                                            //onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                        }}
                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                        labelProps={{ style: { fontWeight: "bold" } }}
                                    />
                                </Col>
                            </Row>
                            {this.state.isProfileSelected == false &&
                                <Row>
                                    <Col xs={12} sm={12} md={12}>
                                        <FormGroup controlId='tokenEvent' style={{ marginLeft: "-6px", marginBottom: "0px" }}>
                                            <iframe
                                                title='CardPointeTokenizer'
                                                id='tokenframe'
                                                name='tokenframe'
                                                src={iFrameUrl}
                                                frameBorder='0'
                                                scrolling='no'
                                                width='100%'
                                                height='180'
                                                onLoad={this.handleTokenEvent}
                                            >
                                            </iframe>
                                            <input type='hidden' name='token' id='mytoken' />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </div>

                        <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                            {this.state.achProfiles.length > 2 &&
                                <Row>
                                    <Col xs={12} sm={12} md={12}>
                                        <InputLabel style={styles.dropDownFontStyle}>
                                            Select Profile
                                        </InputLabel>
                                        <Select
                                            classNamePrefix="react-select"
                                            name="achProfiles"
                                            isDisabled={this.state.isPaid || this.state.invoiceData.IsPaid || this.state.ShowProgressBar}
                                            value={this.state.Customer.ProfileId}
                                            onChange={profile => {
                                                this.handleProfileChanged(profile)
                                                this.setState(prevState => ({
                                                    Customer:
                                                    {
                                                        ...prevState.Customer, ProfileId: profile
                                                    }
                                                }));
                                            }
                                            }
                                            options={this.state.achProfiles}
                                            placeholder="Select Profile"
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <InputLabel style={styles.dropDownFontStyle}>
                                        Account Name
                                    </InputLabel>
                                    <CustomInput
                                        isRequired={true}
                                        id="AccountName"
                                        name="AccountName"
                                        value={this.state.Customer.AccountName}
                                        showErrorMessage={!this.state.Customer.AccountNameValid}
                                        errorMessage="Account name is invalid"
                                        formControlProps={{
                                            fullWidth: true,
                                            disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                            //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                            error: !this.state.Customer.AccountNameValid,
                                            onChange: (e) => { this.handleUserInput(e) },
                                            onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                        }}
                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                        labelProps={{ style: { fontWeight: "bold" } }}
                                    />
                                </Col>
                            </Row>
                            {this.state.isProfileSelected == false &&
                                <Row>                                   
                                    <Col xs={12} sm={12} md={12}>
                                        <InputLabel style={styles.dropDownFontStyle} >
                                            Account Number
                                        </InputLabel>
                                        <CustomInput
                                            isRequired={true}
                                            id="accountnumber"
                                            name="AccountNumber"
                                            value={this.state.Customer.AccountNumber}
                                            type='number'
                                            showErrorMessage={!this.state.Customer.AccountNumberValid}
                                            errorMessage="Account Number is invalid"
                                            formControlProps={{
                                                fullWidth: true,
                                                disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                // disabled: this.state.BalanceDue === "0.00",
                                                onChange: (e) => { this.handleUserInput(e) },
                                                onBlur: (e) => { this.handleOnBlurInput(e) }
                                            }}
                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                            labelProps={{ style: { fontWeight: "bold" } }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12}>
                                        <InputLabel style={styles.dropDownFontStyle} >
                                            Routing Number
                                        </InputLabel>
                                        <CustomInput
                                            isRequired={true}
                                            id="routingnumber"
                                            name="RoutingNumber"
                                            value={this.state.Customer.RoutingNumber}
                                            type="number"
                                            showErrorMessage={!this.state.Customer.RoutingNumberValid}
                                            errorMessage="Routing Number is invalid"
                                            formControlProps={{
                                                fullWidth: true,
                                                disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                // disabled: this.state.BalanceDue === "0.00",
                                                onChange: (e) => { this.handleUserInput(e) },
                                                onBlur: (e) => { this.handleOnBlurInput(e) }
                                            }}
                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                            labelProps={{ style: { fontWeight: "bold" } }}
                                        />
                                    </Col>
                                </Row>
                            }
                        </div>

                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <InputLabel
                                    style={styles.dropDownFontStyle}
                                >
                                    Address
                            </InputLabel>
                                {/* <CustomInput
                                    isRequired={true}
                                    id="address"
                                    name="Address"
                                    value={this.state.Customer.Address}
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                        // disabled: this.state.BalanceDue === "0.00",
                                        onChange: (e) => { this.handleUserInput(e) }
                                    }}
                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                    labelProps={{ style: { fontWeight: "bold" } }}
                                    inputRef={materialRef}
                                /> */}
                               <Input
                                    fullWidth
                                    color="secondary"
                                    disabled={this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected}                                    
                                    inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                                    <Autocomplete
                                        apiKey={"AIzaSyAJHQAxrJ3oCdJA5Fb8odNzNlgX36awEz8"}
                                        style={{ width: "100%", fontFamily: "sans-serif", fontSize: "18px", marginTop: "0px",  border: 'none' }}
                                        placeholder=""
                                        onPlaceSelected={(place) => {
                                            this.setAddressFields(place);                                        
                                            this.setState(prevState => ({
                                                Customer:
                                                {
                                                    ...prevState.Customer, Address: place.formatted_address
                                                }
                                            }), () => { this.validateField("Address", place.formatted_address) })
                                        }}
                                        options={{
                                            types: ["geocode"],
                                            componentRestrictions: { country: "US" },
                                        }}
                                        defaultValue={this.state.Customer.Address}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={6}>
                                <InputLabel
                                    style={styles.dropDownFontStyle}
                                >
                                    City
                                </InputLabel>
                                <CustomInput
                                    //  labelText="City"
                                    id="city"
                                    name="City"
                                    value={this.state.Customer.City}
                                    formControlProps={{
                                        fullWidth: true,  
                                        disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected || this.state.Customer.IsCityAvailable,
                                        //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                        onChange: (e) => { this.handleUserInput(e) }
                                    }}
                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                    labelProps={{ style: { fontWeight: "bold" } }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <InputLabel style={styles.dropDownFontStyle}>
                                    State
                                </InputLabel>
                                <CustomInput
                                    //labelText="State"
                                    id="state"
                                    name="State"
                                    value={this.state.Customer.State}
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected || true,
                                        // disabled: this.state.BalanceDue === "0.00",
                                        onChange: (e) => { this.handleUserInput(e) }
                                    }}
                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                    labelProps={{ style: { fontWeight: "bold" } }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={6}>
                                <InputLabel style={styles.dropDownFontStyle} >
                                    Postal Code
                                </InputLabel>
                                <CustomInput
                                    // labelText="Postal Code"
                                    id="postal-code"
                                    name="PostalCode"
                                    value={this.state.Customer.PostalCode}
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected || this.state.Customer.IsPostalCodeAvailable,
                                        // disabled: this.state.BalanceDue === "0.00",
                                        onChange: (e) => { this.handleUserInput(e) }
                                    }}
                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                    labelProps={{ style: { fontWeight: "bold" } }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <InputLabel
                                    style={styles.dropDownFontStyle}
                                >
                                    Country
                            </InputLabel>
                                <CustomInput
                                    // labelText="Country"
                                    id="country"
                                    name="Country"
                                    value={this.state.Customer.Country}
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected || true,
                                        // disabled: this.state.BalanceDue === "0.00",
                                        onChange: (e) => { this.handleUserInput(e) }
                                    }}
                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                    labelProps={{ style: { fontWeight: "bold" } }}
                                />
                            </Col>
                        </Row>
                        {this.state.isProfileSelected == false && !(this.state.isPaid || this.state.invoiceData.IsPaid) &&
                            <div style={{ marginTop: "10px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12}>
                                        <CustomCheckbox
                                            name="CreateProfileCheckBox"
                                            checked={this.state.Customer.IsCreateProfile}
                                            labelText={"Create Profile"}
                                            isDisabled={this.state.isPaid || this.state.invoiceData.IsPaid}
                                            labelProps={{ style: { fontSize: "17px" } }}
                                            formControlProps={{
                                                onChange: (e) => { this.handleUserInput(e) }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        }
                       
                        <Row>
                            <br />
                            <Col xs={12} sm={12} md={6}>
                                <Button bsStyle="primary" style={{ width: "100%" }} fill onClick={e => this.handleSubmit(e)} type="submit" disabled={this.state.isProfileSelected && !this.state.isPaid ? false : !this.state.Customer.FormValid || this.state.emvToken == "" || this.state.isPaid || this.state.invoiceData.IsPaid || this.state.ShowProgressBar}>
                                    CHARGE
                                </Button>
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <Button style={{ width: "100%" }} onClick={e => this.handleCancelPayment(e)} disabled={this.state.ShowProgressBar}>
                                     {
                                        this.state.isPaid || this.state.invoiceData.IsPaid ? "Go Back" : "Cancel"
                                    } 
                                </Button>
                            </Col>
                        </Row>

                    </div>
                }
            />

        )
    }

    renderForms() {
        const form = this.renderTokenizerUi()
        return form
    }

    setProcessorResult = (processor) => {
        processor.map(data => {
            const configuration = data.configuration;
            if (configuration != null || configuration != "undefined") {
                this.state.copilotURL = configuration.URL;
            } else {
                this.setState({ StatusCode: 1 });
                this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Customer Support Team..." });
            }
        });
    }

    setProfileResult = (result) => {
        let profiles = [{
            value: "",
            label: "Select Proflie",
            isDisabled: true
        },
        {
            value: "none",
            label: "None"
        }
        ]
        let achProfilesArray = [{
            value: "",
            label: "Select Proflie",
            isDisabled: true
        },
        {
            value: "none",
            label: "None"
        }
        ]

        result.map(data => {
            if (data.card_type != "ECHK" && data.card_type != "ACH" && data.card_type != "ECHEK") {
                profiles.push({
                    value: data.token,
                    label: data.last_digits,
                    cardholder_name: data.cardholder_name,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postal_code: data.postal,
                    card_type: data.card_type
                });
            }
            else {
                achProfilesArray.push({
                    value: data.token,
                    label: data.last_digits,
                    cardholder_name: data.cardholder_name,
                    first_name: null,
                    last_name: null,
                    address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postal_code: data.postal,
                    card_type: data.card_type
                });
            }
        });

        this.setState({ ccProfiles: profiles });
        this.setState({ achProfiles: achProfilesArray })
    }

    getPaymentProfiles(customer_id) {
        const payload = {
            "customer_id": customer_id,
            "realm_id": null
        }

        this._getProfileByIdRequest(payload)
    }
    /**
     * API CALLING
     */

    _postCollectPaymentRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postCollectPaymentRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    // console.log(data.statuscode + '  ' + data.statusmessage)
                    this.setState({ StatusCode: data.statuscode });
                    this.setState({ StatusMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        var updatedInvoice = this.state.invoiceData;      
                        updatedInvoice.Balance = parseFloat(this.state.remainingBalance).toFixed(2); 
                        if (parseFloat(this.state.remainingBalance).toFixed(2) == "0.00") {
                            this.setState({ Amount: 0.00});
                            this.setState({ isPaid: true });
                            updatedInvoice.IsPaid = true;
                        }
                        else {
                            this.setState({ Amount: parseFloat(this.state.remainingBalance).toFixed(2)});
                            this.setState({ totalAmount: parseFloat(this.state.remainingBalance).toFixed(2) });
                            this.setState({ amountToCharge: parseFloat(this.state.remainingBalance).toFixed(2) });
                            this.setState({ remainingBalance: 0.00 });
                        }
                        localStorage.setItem("Invoice", JSON.stringify(updatedInvoice)); 
                    }
                    if (!this.state.isProfileSelected) {
                        if (this.state.IsCreditCard || this.state.IsDebitCard) {
                            setTimeout(() => {
                                var iframe = document.getElementById('tokenframe');
                                iframe.src = iframe.src;
                            }, 1000);
                        }                        
                        this.setState({ IsTokenValid: false });
                        this.setState({ emvToken: "", expiryDate: "" });
                        this.validateForm();
                    }


                    //  if (data.data != null)
                    //     this.setItemsResult(data.data.items);
                } else {
                    this.setState({ StatusCode: -1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    // this.setState({ StatusCode: data.statuscode });
                    // this.setState({ StatusMessage: data.statusmessage });
                    if (data.statuscode === 0) {

                        let processor = data.data.items;

                        this.setProcessorResult(processor);
                    }
                    //  if (data.data != null)
                    //     this.setItemsResult(data.data.items);
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getProfileByIdRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postProfileByIdRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    this.setState({ StatusCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this.setProfileResult(data.data.items);
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    async _postCardSecureTokenize(data) {
        let url = `${this.state.copilotURL}/cardsecure/api/v1/ccn/tokenize`
        
        let response = await (fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }).then(res => {
            return res.json()
        }).catch(err => {
            // console.log('Error: ', err)
        })
        )
        return response
    }

    _postProcessBoltRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postProcessBoltRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    // this.setState({ StatusCode: data.statuscode });
                    // this.setState({ StatusMessage: data.statusmessage });
                    if (data.statuscode === 0) {}
                    else{
                        this.setState({ StatusCode: 1 });
                        this.setState({ StatusMessage: data.statusmessage });
                        this.setState({showBoltPaymentModal:false});
                    }
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                    this.setState({showBoltPaymentModal:false});
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
                this.setState({showBoltPaymentModal:false});
            });
    } 


    render() {
        const invalidCss1 = {
            borderBottomColor: 'red',
            borderWidth: '2px'
        };
        return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    content={
                        <div>
                            <Row>
                                <Col md={7}>
                                    <Card
                                        content={
                                            <div>
                                                <div style={{ color: "white", backgroundColor: "#0075c1", marginLeft: "-15px", marginRight: "-15px", marginTop: "-15px" }}>
                                                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                        <Col xs={12} sm={12} md={7}>
                                                            <h3><strong>Invoice # {this.state.invoiceNumber} </strong> </h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={2}>
                                                            <h3><strong> Amount: </strong></h3>
                                                        </Col>
                                                        <Col style={{ textAlign: "right" }} xs={12} sm={12} md={3}>
                                                            <h3><strong>${parseFloat(this.state.amountToCharge).toFixed(2)}</strong></h3>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <br />
                                                {
                                                    (this.state.isPaid || this.state.invoiceData.IsPaid ) &&
                                                    <div style={styles.watermark}>
                                                    </div>
                                                }
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Customer : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Email : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.customerEmail}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Invoice Date : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.invoiceDate}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Due Date : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.dueDate}</h5>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <br />
                                                <Row>
                                                    <Col xs={12} sm={12} md={5}>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <h5>Total Amount : </h5>
                                                    </Col> <Col style={{ textAlign: "right", marginLeft: "-25px" }} xs={12} sm={12} md={3}>
                                                        <h5>${parseFloat(this.state.totalAmount).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={5}>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <h5>Amount to Charge :</h5>
                                                    </Col> <Col style={{ textAlign: "right" }} xs={12} sm={12} md={3}>
                                                        <FormControl
                                                            value={this.state.amountToCharge}
                                                            disabled={this.state.isPaid || this.state.invoiceData.IsPaid}
                                                            readOnly={this.state.IsCreditCard && this.state.ConvFee != "" }
                                                            type="number"
                                                            name="amountToCharge"
                                                            min="0.00"
                                                            style={{ textAlign: "right" }}
                                                            step="0.01"
                                                            onChange={this.handleAmountChange}
                                                            onKeyPress= {this.handleRateKeyPress}
                                                            onBlur={event => {
                                                                this.setState({ amountToCharge : parseFloat(this.state.amountToCharge).toFixed(2) });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={5}>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <h5>Remaining Balance :</h5>
                                                    </Col> <Col style={{ textAlign: "right", marginLeft: "-25px" }} xs={12} sm={12} md={3}>
                                                        <h5>${parseFloat(this.state.remainingBalance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>

                                            </div>
                                        }
                                    />
                                </Col>
                                <Col md={5}>
                                    {/* <Col md={3} style={{display:"none"}}>
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Process via Bolt</Tooltip>}>
                                        <img width="74px" height="45px"
                                            src={this.state.boltIcon}                                                
                                            onMouseEnter={() => {this.setState({boltIcon : bolthover})}}
                                            onMouseOut={() => {this.setState({boltIcon : boltLogo})}}
                                            onMouseDown={() => {this.setState({boltIcon : boltClicked})}}
                                            onMouseUp={() => {this.setState({boltIcon : boltLogo })}}
                                            onClick={e => this.handleBoltPayment(e)}
                                            />
                                            </OverlayTrigger>
                                    </Col> */} 
                                    <div>                                    
                                        <Row>
                                            <div className="text-center">
                                                <ButtonGroup>
                                                    {this.state.IsCreditCardEnabled &&
                                                        <Button bsStyle={this.state.groupButtonCreditCardStyle} name="creditcard" onClick={e => this.ccHandleRadio(e)}  fill={this.state.IsCreditCard} wd disabled={this.state.isPaid || this.state.invoiceData.IsPaid}>
                                                            <span className="btn-label" style={{marginRight:"10px"}}>
                                                            <i className="fa fa-credit-card" />
                                                            </span>
                                                            Credit Card
                                                        </Button>
                                                    }
                                                    {this.state.IsDebitCardEnabled &&
                                                        <Button bsStyle={this.state.groupButtonDebitCardStyle} name="debitcard" onClick={e => this.ccHandleRadio(e)} fill={this.state.IsDebitCard} wd disabled={this.state.isPaid || this.state.invoiceData.IsPaid}> 
                                                            <span className="btn-label" style={{marginRight:"10px"}}>
                                                            <i className="fa fa-credit-card-alt" />
                                                            </span>Debit Card
                                                        </Button>
                                                    }
                                                    {this.state.IsACHEnabled && 
                                                        <Button bsStyle={this.state.groupButtonACHStyle} name="ach" onClick={e => this.ccHandleRadio(e)} fill={this.state.IsACH} wd disabled={this.state.isPaid || this.state.invoiceData.IsPaid}>
                                                            <span className="btn-label" style={{marginRight:"10px"}}>
                                                            <i className="fa fa-bank" />
                                                            </span>Bank/ACH
                                                        </Button>
                                                    }
                                                </ButtonGroup>
                                            </div>                                                    
                                        </Row>
                                        <br />
                                    </div>
                                   
                                    {this.state.IsCreditCard && this.state.ConvFee != "" && 
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <InputLabel style={{ color:"black", fontSize: 17, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11}}>
                                                    {this.state.ConvFee.name} &nbsp;&nbsp; {this.state.ConvFee.is_percentage == false && "$"}{parseFloat(this.state.ConvFee.value).toFixed(2)}{this.state.ConvFee.is_percentage == true && "%"}
                                                </InputLabel>
                                            </Col>
                                        </Row>
                                    }
                                    {this.renderForms()}
                                </Col>


                            </Row>

                        </div>
                    }

                />
                <Row>
                    <Col>
                        {this.state.showBoltPaymentModal &&
                            <Bolt showBoltPaymentModal={this.handleBoltPayment} parentCallback={this.callbackFunction} Amount={this.state.Amount} />
                        }
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={12}>
                        <Modal
                            show={this.state.show1111BoltPaymentModal}
                            onHide={() => this.setState({ show1111BoltPaymentModal: false })}  
                            backdrop="static"                  
                        >
                            {this.state.ShowPopupProgressBar &&
                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            } */}
                            {/* <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                            </Modal.Header> */}
                            {/* <Modal.Body>
                                <div className="modal-body text-center" >
                                    <img src={cardSwipe} />                                      
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ show1111BoltPaymentModal: false })}>Close</Button>                                
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row> */}
            </div>
        )
    }
}

export default CCPayment;  