/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Dashboard from "views/Financial/Dashboard.jsx";
import Transactions from "views/Financial/Transactions.jsx";
import Download from "views/Financial/Download.jsx";
import Settings from "views/Financial/Settings.jsx";

var QBFinancialRoutes = [
    {
      path: "/dashboard",
      layout: "/qbfinancial",
      name: "Dashboard",
      icon: "fa fa-dashboard",
      component: Dashboard
    },
    {
      path: "/transactions",
      layout: "/qbfinancial",
      name: "Transactions",
      icon: "fa fa-money",
      component: Transactions      
    },
    {
      path: "/settings",
      layout: "/qbfinancial",
      name: "Settings",
      icon: "fa fa-gears",
      component: Settings
    },
    {
      path: "/download",
      layout: "/qbfinancial",
      name: "Download",
      icon: "fa fa-download",
      component: Download      
    }
];

export default QBFinancialRoutes;    