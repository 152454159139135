// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import Input from "@material-ui/core/Input";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
  
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};
  
`;

const DangerText = styled.p`
  font-size: 1.1rem;
  margin: 5px 0 0 0;
  color: #ff3860;
  ${({ styled }) => ({ ...styled })};
`;

const BACKSPACE_KEY_CODE = 8;
global.isCardAmountValid = false;
global.InvoiceAmount = "0.00";

type Props = {
  onError?: Function,
  cardCVCInputProps: Object,
  containerClassName: string,
  containerStyle: Object,
  dangerTextClassName: string,
  dangerTextStyle: Object,
  fieldClassName: string,
  fieldStyle: Object,
  inputComponent: Function | Object | string,
  invalidClassName: string,
  invalidStyle: Object,
  customTextLabels: Object,
  formControlProps:  PropTypes.object,
  classes: PropTypes.object.isRequired,
};
type State = {
  errorText: ?string
};

const inputRenderer = ({ inputComponent, props }: Object) => {
  const Input = inputComponent || 'input';
  return <Input {...props} />;
};

class AmountInput extends Component<Props, State> {
  cvcField: any;

  static defaultProps = {
    cardCVCInputProps: {},
    containerClassName: '',
    containerStyle: {},
    dangerTextClassName: '',
    dangerTextStyle: {},
    fieldClassName: '',
    fieldStyle: {},
    inputComponent: 'input',
    invalidClassName: 'is-invalid',
    invalidStyle: {},
    customTextLabels: {},
    formControlProps:{},
    classes: {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      errorText: null
    };
  }

  checkIsAmountValid = (e: any) => {
    //if (!/^\d*$/.test(e.key))  
     
    var charCode = (e.which) ? e.which : e.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode === 46 || charCode === 8))
      e.preventDefault();
    else {
      var len = e.target.value.length;
      var index = e.target.value.indexOf('.');
      if (index > 0 && charCode === 46) {
        e.preventDefault();
      }
      // if(index === 1 && charCode === 46)
      // {
      //   e.preventDefault();
      // }
      // if (index > 0) {
      //   var CharAfterdot = (len + 1) - index;
      //   //alert(CharAfterdot);
      //   var re=/^\d+(\.\d{1,2})?$/;
      //   var num = e.target.value;     

      //    if (CharAfterdot > 3) {          
      //       if(!re.test(num))
      //       {
      //         e.preventDefault();
      //       }  
      //    }
      // }
      if(len > 12)
      {
        e.preventDefault();
      }
    }
    // var enteredAmountValue =e.target.value +''+ e.key;
    // if(enteredAmountValue > 500.45)  //replace 45.45 with invoice total payable amount
    // {
    //   e.preventDefault();
    // }
  };


  handleAmountBlur = (
    { onBlur }: { onBlur?: ?Function } = { onBlur: null }
  ) => (e: SyntheticInputEvent<*>) => {
   // alert(e.target.value);
    var regex=/^\d+(\.\d{1,9})?$/;
    var invoiceAmount = global.InvoiceAmount; 
    const { customTextLabels } = this.props;
    e.target.value = parseFloat(e.target.value).toFixed(2);
    this.setFieldValid();
    if(!regex.test(e.target.value) || e.target.value.length > 13)    
    {
        global.isCardAmountValid = false;
        this.setFieldInvalid(
          customTextLabels.invalidCvc || 'Amount is invalid',
          'cardCVC'
        );
    }
    else if(parseFloat(e.target.value) > parseFloat(invoiceAmount))
    {
        global.isCardAmountValid = false;
        this.setFieldInvalid(
         customTextLabels.invalidCvc || 'Amount should be less than invoice total amount'
      );
    }
    else
    {      
       global.isCardAmountValid = true;
    }

    const { cardCVCInputProps } = this.props;
    cardCVCInputProps.onBlur && cardCVCInputProps.onBlur(e);
    onBlur && onBlur(e);
   
  };
  
  handleCardCVCKeyPress = (e: any) => {
    this.checkIsAmountValid(e);
  };

  handleKeyDown = (ref: any) => {
    return (e: SyntheticInputEvent<*>) => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
        ref.focus();
      }
    };
  };

  setFieldInvalid = (errorText: string, inputName?: string) => {
    const { invalidClassName, onError } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.add(invalidClassName);
    this.setState({ errorText });

    if (inputName) {
      const { onError } = this.props[`${inputName}InputProps`];
      onError && onError(errorText);
    }

    if (onError) {
      onError({ inputName, error: errorText });
    }
  };

  setFieldValid = () => {
    const { invalidClassName } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.remove(invalidClassName);
    this.setState({ errorText: null });
  };

  render = () => {
    const { errorText } = this.state;
    const {
      containerStyle,
      dangerTextClassName,
      dangerTextStyle,
      fieldClassName,
      fieldStyle,
      inputComponent,
      invalidStyle,
      classes,
      formControlProps,
      error,
      success,
      labelText,
      id,
      name,
      readOnly,
      value,
      labelProps,
      inputProps
    } = this.props;

    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    
    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });    
    
    return (
      <FormControl 
      {...formControlProps}
      className={formControlProps.className}
      >   
        <FieldWrapper
          id="field-wrapper"
          className={fieldClassName}
          styled={fieldStyle}
          invalidStyled={invalidStyle}
        >
          {labelText !== undefined ? (
        <InputLabel
          className= {!errorText && classes.labelRoot + labelClasses}          
          htmlFor={id}
          {...labelProps}
        >
            {labelText}
            </InputLabel>
          ) : null}
          <Input
             data-max="9999999999999"
              id= {id}
              name={name}
              readOnly= {readOnly}
              value={value}
                ref = {cvcField => {
                  this.cvcField = cvcField;
                }}
                 maxLength= '13'
                autoComplete= 'off'
                classes={{
                  root: marginTop,
                  disabled: classes.disabled,                  
                  underline: underlineClasses,
                  input: classes.inputTextRight
               }}
                type= 'tel'
                onBlur= {this.handleAmountBlur()}
                onKeyPress= {this.handleCardCVCKeyPress}
                
                fullWidth= {true}
                {...inputProps}
          >
          </Input>
          
        </FieldWrapper>
        {errorText && (
          <DangerText className={dangerTextClassName} styled={dangerTextStyle}>
            {errorText}
          </DangerText>
        )}
      </FormControl>
    );
  };
}

export default  withStyles(customInputStyle)(AmountInput);

