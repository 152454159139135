import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,

} from "react-bootstrap";
import MaterialTable from 'material-table';
import Select from "react-select";
import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Chip from '@material-ui/core/Chip';
import NetworkService from "services/NetworkService";
import Datetime from "react-datetime";
// import ReactToPrint from 'react-to-print';

class Customers extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            ShowProgressBar: false,
            currency: {},
            currencies: [
                {
                    Value: "USD",
                    Name: "USD"
                }
            ],
            data: [
                // { invoicenumber: '654541', customername: 'Can dynamically add', invoicedate: '2020-01-01', duedate: '2020-12-05', balance: '$633.21', total: '$633.21', invoicestatus: 'Paid', emailstatus: 'not sent' },
            ],
            columns: [
                {
                    title: 'Customer Vault ID	',
                    field: 'customer_vault_id',
                },
                {
                    title: 'Customer',
                    field: 'customer',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(30%)'
                    },
                    cellStyle: {
                        width: 'calc(30%)'
                    },
                },   
                {
                    title: 'Email',
                    field: 'email',
                },               
                {
                    title: 'Payment',
                    field: 'payment'
                },
                {
                    title: 'Details',
                    field: 'details',
                },
                {
                    title: 'Card Status',
                    field: 'cc_exp'
                },
                {
                    title: 'Creation Date',
                    field: 'createddate'
                },
                {
                    hidden: true,
                    title: 'First Name',
                    field: 'firstname'
                },
                {
                    hidden: true,
                    title: 'Last Name',
                    field: 'lastname'
                },
                {
                    hidden: true,
                    title: 'ccexp',
                    field: 'ccexp'
                }
            ],
            options: {
                pageSize: 25,
                pageSizeOptions: [10, 25, 50, 100],
                paging: true,
                toolbar: true,
                //  selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    //backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"                   
                }),
            },            
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">Create Invoice</Tooltip>}>
                        <i className="fa fa-file-text-o" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => { this.handleCreateInvoice(rowData); }
                },
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="makeSaleToolTip">Make a Sale</Tooltip>}>
                //         <i className="fa fa-credit-card-alt" style={{ color: "#0075c1", marginRight: "8px" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => { this.handleCreateSale(rowData); }
                // },
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editCustomerToolTip">Edit</Tooltip>}>
                //         <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => { console.log(rowData); }
                // },
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="deleteCustomerToolTip">Delete</Tooltip>}>
                //         <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => { this.handleDeleteCustomerVault(event, rowData); }
                // }
            ],                     
            queryFromDate: "",
            queryToDate: "",
            from_date: new Date(date.getFullYear(), 0, 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        }

        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        document.getElementById("loaderDiv").style.display = "none";
        // this.setCurrenciesResult(this.state.currencies);
        this.SetFromToDates();
        this.filterCustomer();
        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.filterCustomer();
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handleDeleteCustomerVault(e, data) {
        e.preventDefault();
        const payload = { "index": data.index, "vault_id": data.customer_vault_id }
        this._postDeleteNMICustomerRequest(payload);
    }

    checkCardExpiry = (val) => {
        if (val == null || val == undefined) {
            return null;
        }
        let now = new Date();
        const month = val.substring(0, 2);
        const year = "20".concat(val.substring(2));
        const card_exp = new Date(year, month, 0);
        const one_day = 1000 * 60 * 60 * 24;
        const remaining_days = parseInt(Math.round(card_exp.getTime() - now.getTime()) / (one_day));
        let msg = "Expiry : " + this.formatDate(card_exp);
        if (remaining_days > 0 && remaining_days < 31) {
            msg = "Credit Card Expires Within 30 Days"
        }
        else if (remaining_days < 1) {
            msg = "Credit Card Expired"
        }
        return msg
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    filterCustomer = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getCustomerRequest(query);
    };
    /** API Calling */

    _postDeleteNMICustomerRequest(body) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postDeleteNMICustomerRequest(app_id, body)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        this.setDeleteCustomerResult(body);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    setDeleteCustomerResult = (result) => {
        const index = this.state.data.indexOf({ "index": result.index });
        if (index === -1) { return; }
        // this.setState({data: this.state.data.splice(result.index, 1)});
    }

    _getCustomerRequest(params = "") {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getCustomerRequest(app_id, params)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });                    
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.setCustomerResult(data.data.items);
                        }
                    }
                    else{
                        //this.setState({ errorMessage: data.statusmessage });
                    }
                }
                else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    handleCreateSale(customer){
        console.log(customer);
        //localStorage.setItem("salecustomer", JSON.stringify(customer));
        this.props.history.push({
            pathname: '/nmi/virtualterminal',
            state: { customerData: customer }
        });
    }

    handleCreateInvoice(customer) {
        console.log(customer);
        //localStorage.setItem("invoicecustomer", JSON.stringify(customer));
        this.props.history.push({
            pathname: '/nmi/createinvoice',
            state: { customerData: customer }
        });
    }

    setCustomerResult(customers, isFromCache = false) {
        if (!isFromCache) {
            localStorage.setItem(StorageEnum.QBO_CUSTOMERS, null);
            localStorage.setItem(StorageEnum.QBO_CUSTOMERS, JSON.stringify(customers));
        }
        const newState = []
        if (customers != null && JSON.stringify(customers) !== JSON.stringify({})) {
            customers.map((data, index) => {
                try {
                    const exp_msg = this.checkCardExpiry(data['CCExp']);
                    const x = {
                        index: index,
                        firstname: data.FirstName,
                        lastname: data.LastName,
                        ccexp: data.CCExp,
                        customer: data["DisplayName"],
                        customer_vault_id: data["CustomerVaultID"],
                        createddate: data['CreatedDate'],
                        email: data['PrimaryEmailAddr'] != null ? data['PrimaryEmailAddr']["Address"] : "",
                        cc_exp: exp_msg != null ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={exp_msg}></Chip> : "",
                        payment: data["Type"],
                        details: data["CCNumber"] != null ? data["CCNumber"] : data["CheckAccount"],
                        
                    }
                    newState.push(x);
                }
                catch (why) {
                    console.log(why)
                }
            });
        }
        this.setState({ data: newState });
    }


    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>
                                        }
                                        <MaterialTable
                                            //title="Styling with MuiThemeProvider Preview"
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            onSelectionChange={(rows) => {
                                                console.log('You selected ' + rows.length + ' rows');
                                            }
                                            }
                                            //  components={this.state.components}
                                            actions={this.state.actions}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Customers;








