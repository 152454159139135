import RequestService from 'services/RequestService'
import { BASE_URL } from "utils/constant.js"
import React, { Component } from "react";

class Common {
    getItemListingRequest(app_id) {
        const url = `${BASE_URL}api/v1/items/filter/`
        return RequestService.getRequest(url, app_id)
    }   

    ValidatePasswords(currentState) {
        if (currentState.state.newPasswordState === "" || currentState.state.newPasswordState === "error") {
            currentState.setState({
                newPasswordErrorMessage: (
                    <small className="text-danger">
                        New password is required.
                    </small>
                )
            });
            currentState.state.isPasswordFormValid = false;
        }
        else {
            currentState.setState({ newPasswordErrorMessage: null });
            currentState.state.isPasswordFormValid = true;
        }
        if (currentState.state.confirmNewPasswordState === "" || currentState.state.confirmNewPasswordState === "error") {
            currentState.setState({
                confirmNewPasswordErrorMessage: (
                    <small className="text-danger">
                        Confirm Password is Required.
                    </small>
                )
            });
            currentState.state.isPasswordFormValid = false;
        }
        else {
            if (currentState.state.newPasswordState === "valid" && currentState.state.newPassword != currentState.state.confirmNewPassword) {
                currentState.setState({
                    confirmNewPasswordErrorMessage: (
                        <small className="text-danger">
                            New Password and Confirm Password do not match.
                        </small>
                    )
                });
                currentState.state.isPasswordFormValid = false;
            }
            else {
                currentState.setState({ confirmNewPasswordErrorMessage: null });
                currentState.state.isPasswordFormValid = true;
            }
        }
        return currentState.state.isPasswordFormValid;
    }

    getProcessorSettingsErrorMessage(){
        return "Note:- You cannot accept payments as your processor settings are not configured. Please, contact Support@InstantInvoice.com for more details.";
    }

    getEmailRegex(){
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    getRequiredEmailErrorMessage(){
        return <small className="text-danger">Email is required and format should be <i>john@doe.com</i>.</small>
    }

    getInvalidEmailErrorMessage(){
        return <small className="text-danger">Invalid email address, the format should be <i>john@doe.com</i>.</small>
    }

    getPasswordRegex(){
      return "^(" +
        "(?=.*[a-z])|" +      
        "(?=.*[A-Z])|" +
        "(?=.*[0-9])|" +
        "((?=.*[a-z])(?=.*[A-Z]))|" +
        "((?=.*[a-z])(?=.*[0-9]))|" +      
        "((?=.*[A-Z])(?=.*[0-9]))|" + 
        "((?=.*[a-z])(?=.*[!@#$%^&*]))|" +
        "((?=.*[A-Z])(?=.*[!@#$%^&*]))|" + 
        "((?=.*[A-Z])(?=.*[a-z]))|" +
        "((?=.*[0-9])(?=.*[a-z]))|" +
        "((?=.*[0-9])(?=.*[A-Z]))|" + 
        "((?=.*[!@#$%^&*])(?=.*[a-z]))|" +
        "((?=.*[!@#$%^&*])(?=.*[A-Z]))|" + 
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|" +
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&]))|" +
        "((?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]))|" +   
        "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&]))|" +
        "((?=.*[a-z])(?=.*[!@#$%^&])(?=.*[A-Z]))|" +
        "((?=.*[a-z])(?=.*[!@#$%^&])(?=.*[0-9]))|" +
        "((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))|" +
        "((?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&]))|" +
        "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]))|" +
        "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]))|" +
        "((?=.*[A-Z])(?=.*[!@#$%^&])(?=.*[a-z]))|" +
        "((?=.*[A-Z])(?=.*[!@#$%^&])(?=.*[0-9]))|" +      
        "((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|" +
        "((?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^&]))|" +
        "((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]))|" +  
        "((?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&]))|" +
        "((?=.*[0-9])(?=.*[!@#$%^&])(?=.*[a-z]))|" +
        "((?=.*[0-9])(?=.*[!@#$%^&])(?=.*[A-Z]))|" +
        "((?=.*[!@#$%^&])(?=.*[a-z])(?=.*[A-Z]))|" +
        "((?=.*[!@#$%^&])(?=.*[a-z])(?=.*[0-9]))|" +
        "((?=.*[!@#$%^&])(?=.*[A-Z])(?=.*[a-z]))|" + 
        "((?=.*[!@#$%^&])(?=.*[A-Z])(?=.*[0-9]))|" + 
        "((?=.*[!@#$%^&])(?=.*[0-9])(?=.*[a-z]))|" +
        "((?=.*[!@#$%^&])(?=.*[0-9])(?=.*[A-Z]))|" +
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]))|" + 
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&])(?=.*[0-9]))|" + 
        "((?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&]))|" +  
        "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&])(?=.*[A-Z]))|" + 
        "((?=.*[a-z])(?=.*[!@#$%^&])(?=.*[A-Z])(?=.*[0-9]))|" +
        "((?=.*[a-z])(?=.*[!@#$%^&])(?=.*[0-9])(?=.*[A-Z]))|" + 
        "((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&]))|" +
        "((?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&])(?=.*[0-9]))|" +
        "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^&]))|" +
        "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&])(?=.*[a-z]))|" +
        "((?=.*[A-Z])(?=.*[!@#$%^&])(?=.*[a-z])(?=.*[0-9]))|" + 
        "((?=.*[A-Z])(?=.*[!@#$%^&])(?=.*[0-9])(?=.*[a-z]))|" + 
        "((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&]))|" + 
        "((?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^&])(?=.*[A-Z]))|" + 
        "((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&]))|" + 
        "((?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&])(?=.*[a-z]))|" + 
        "((?=.*[0-9])(?=.*[!@#$%^&])(?=.*[a-z])(?=.*[A-Z]))|" + 
        "((?=.*[0-9])(?=.*[!@#$%^&])(?=.*[A-Z])(?=.*[a-z]))|" + 
        "((?=.*[!@#$%^&])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|" + 
        "((?=.*[!@#$%^&])(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]))|" + 
        "((?=.*[!@#$%^&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))|" + 
        "((?=.*[!@#$%^&])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]))|" + 
        "((?=.*[!@#$%^&])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|" + 
        "((?=.*[!@#$%^&])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]))" + 
      ")(?=.{4,})";
    }

    getPasswordErrorMessage(){
        return <small className="text-danger">The password must be at least 4 characters.</small>
    }

    formatDate(date) {
           var month = date.split('-')[1];
          var  day = date.split('-')[2];
          var  year = date.split('-')[0];

        // if (month.length < 2)
        //     month = '0' + month;
        // if (day.length < 2)
        //     day = '0' + day;
        return [month, day, year].join('/');
    }

    formatDate2(date, format) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if(format === undefined || format == null || format == "YYYY-MM-DD"){       
            return [year, month, day].join('-');
        }
        else if(format === "MM/DD/YYYY"){
            return [month, day, year].join('/');
        }
    }
}

export default new Common()