import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    ButtonGroup
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AmountInput from 'utils/Amount_Input.js';
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import InstantInvoiceLoader from "assets/img/icons/ii.gif";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import paidimage from "assets/img/paidimagecp.png";
import NetworkService from "services/NetworkService";
import { StorageEnum } from "utils/constant.js";
import { Input } from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";

const styles = {
    overlay: {
        position: "fixed",
        //  display: "none",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: "2"
    },
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
        color: "green",//successColor[2], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    errorResponse: {
        color: "red",//dangerColor[0], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    watermark: {
        content: "",
        backgroundImage: `url(${paidimage})`,
        opacity: "0.2",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        position: "absolute"
    },
    amountLabel: {
        // fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginTop: "20px",
        minHeight: "auto",
        marginBottom: "0px",
    },
}

class CCPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Customer: {
                CardHolderName: '',
                GatewayExpiryDate: '',
                AccountNumber: '',
                AccountType: 'checking',
                RoutingNumber: '',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: false,
                CardHolderNameValid: true,
                AccountNameValid: true,
                AccountNumberValid: true,
                RoutingNumberValid: true,
                AmountValid: true,
                IsTokenValid: false,
                CheckBoxChecked: false,
                FormErrors: { CardHolderName: '', AccountName: '' },
                IsPostalCodeAvailable: true,
                IsCityAvailable: true,
            },
            Amount: 0.00,
            remainingBalance: 0.00,
            NMI: false,
            CardConnect: true,
            AuthorizeNet: false,
            ShowProgressBar: false,
            InvoiceRawHtml: null,
            Invoice: [],
            IsCreditCard: true,
            IsDebitCard: false,
            IsACH: false,
            IsCreditCardEnabled: true,
            IsDebitCardEnabled: true,
            IsACHEnabled: true,
            showHTMLDiv: true,
            URLToken: "",
            showLoader: true,
            emvToken: '',
            expiryDate: '',
            hostName: '',
            hostPort: '',
            copilotURL: '',
            ConvFee: "",
            ConvFeeAmount: 0.00,
            groupButtonCreditCardStyle: "primary active",
            groupButtonDebitCardStyle: "default",
            groupButtonACHStyle: "default",
            lastState: "creditcard",
        }
        this.handleCharged = this.handleCharged.bind(this);
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            console.log('hi');
        }
        this.state.URLToken = this.extractTokenFromUrl();        
        this.getPaymentKeyDetail(this.state.URLToken);        
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
         const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } else {
                    this.setState({ showLoader: false });  
                }
            }).catch(error => {
                this.setState({ showLoader: false });  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
        {
            let convFeeAmount = 0.00;
            if(convfee[0].is_percentage){
                convFeeAmount = (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) 
                this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) })
            }
            else{
                convFeeAmount =  parseFloat(convfee[0].value)
                this.setState({ ConvFeeAmount : parseFloat(convfee[0].value)});
            }            
            this.setState({ ConvFee: convfee[0] }); 
            
            global.InvoiceAmount = parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2);
            this.setState({ Amount: parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2) });
        }
        this.setState({ showLoader: false });   
    }

    handleTokenEvent = (event) => {
        window.addEventListener(
            'message',
            (event) => {
                const token = JSON.parse(event.data)
                let mytoken = document.getElementById('mytoken')
                console.log(event.data);
                mytoken.value = token.message;

                if (token.errorCode == "0" && token.errorMessage == "") {
                    this.setState({
                        IsTokenValid: true
                    });
                    this.validateForm();
                }
                else {
                    this.setState({
                        IsTokenValid: false
                    });
                    this.validateForm();
                }
                const expField = token.expiry

                const tkn = mytoken.value
                const dte = expField

                this.setState({
                    emvToken: tkn,
                    expiryDate: dte
                })

                const emvData = {
                    token: tkn,
                    expiryDate: dte
                }

                // this.props.tokenProps.userEmvData(emvData)
                console.log(emvData);
                event.preventDefault()
            },
            false
        )
    }

    ccHandleRadio = (event) => {
        const target = event.target;
        this.setState({ StatusMessage: "" });
        if (target.name === "creditcard" && this.state.IsCreditCard == false) {
            this.setState({ IsCreditCard: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: false
                }
            }));
            this.setState({ lastState: target.name});
            global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) + parseFloat(this.state.ConvFeeAmount)).toFixed(2);
            this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.state.groupButtonCreditCardStyle = "primary active";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "default";
        }
        else if (target.name === "debitcard") {
            this.setState({ IsDebitCard: true });
            this.setState({ IsCreditCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: false
                }
            }));
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "primary active";
            this.state.groupButtonACHStyle= "default";
        }
        else if(target.name === "ach") {
            this.setState({ IsACH: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsCreditCard: false });
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            if (this.state.Customer.AmountValid === true) {
                this.setState(prevState => ({
                    Customer: {
                        ...prevState.Customer,
                        FormValid: false
                    }
                }));
            }
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "primary active";
        }              

        this.state.Customer.CheckBoxChecked = false;
        //  this.state.Customer.FormValid = false;
    };

    handleSubmit = (event) => {
        this.setState({ StatusMessage: "" });
        this.validateForm();

        if (!this.state.Customer.FormValid) {
            event.preventDefault();
            return false;
        }
        else {
            this.setState({ ShowProgressBar: true });
            this.handleCharged(this.state);
        }
    }

    handleCharged = (value) => {
        const invoice_info = this.state.Invoice;
        const payment_info = this.state.Customer;
        const amount = this.state.Amount;

        let cardInfo = null;
        let achInfo = null;
        if (this.state.IsCreditCard || this.state.IsDebitCard) {
            cardInfo = {
                "CardHolder": payment_info.CardHolderName,
                "CardNumber": value.emvToken,
                "Cvv": null,
                "Month": null,
                "Year": null,
                "CardType": null,
                "Token": value.emvToken
            }
        } else if (this.state.IsACH) {
            achInfo = {
                "AccountHolder": payment_info.AccountName,
                "AccountNumber": payment_info.AccountNumber,
                "RoutingNumber": payment_info.RoutingNumber,
                "AccountType": payment_info.AccountType,
                "Token": value.emvToken                
            }
        }
        const data = {
            "InvoiceInfo": {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "payment",
                "Invoice": invoice_info
            },
            "SalesReceiptInfo": {
                "Id": [],
                "DocNumber": [],
                "Type": "salesreceipt"
            },
            "PaymentInfo": {
                "CardInfo": cardInfo,
                "AchInfo": achInfo,
                "ProfileInfo": {
                    "ProfileId": payment_info.ProfileId,
                    "CreateProfile": payment_info.IsCreateProfile
                },
                "BillingInfo": {
                    "Country": payment_info.Country,
                    "City": payment_info.City,
                    "State": payment_info.State,
                    "Street": payment_info.Address,
                    "PostalCode": payment_info.PostalCode
                },
                "TransactionInfo": {
                    "TransType": this.state.IsACH ? "ach_sale" : "sale",
                    "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                    "Amount": parseFloat(amount).toFixed(2),
                    "ActionType": "invoice",
                    "CurrencyCode": "USD"
                }
            },
            "CustomerRef": {
                "Name": invoice_info.CustomerRef ? invoice_info.CustomerRef.name : null,
                "Value": invoice_info.CustomerRef ? invoice_info.CustomerRef.value : null,
                "Email": invoice_info.BillEmail ? invoice_info.BillEmail.Address : null
            }
        }
        this.setState({ remainingBalance: parseFloat(global.InvoiceAmount) - parseFloat(amount) })
        this._postCollectPaymentRequest(data)
    }

    handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        //alert(charCode);
        //97-122, 32,65-90'
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };

    handleOnBlurInput(e) {
        console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case "AccountNumber":
            case "RoutingNumber":
                if (this.state.Customer.RoutingNumber && this.state.Customer.AccountNumber) {
                    let account = `${this.state.Customer.RoutingNumber}/${this.state.Customer.AccountNumber}`
                    const payload = {
                        "account": account,
                        "source": "iToke",
                        "encryptionhandler": null,
                        "unique": false,
                        "expiry": null,
                        "cvv": null
                    }
                    this._postCardSecureTokenize(payload).then(event => {
                        // const token = JSON.parse(event)
                        let mytoken = document.getElementById('mytoken')
                        mytoken.value = event.token;

                        if (event.errorcode == 0) {

                            let acct_number = document.getElementById('accountnumber')
                            acct_number.value = "XXXXX" + acct_number.value.slice(-4);

                            let rout_number = document.getElementById('routingnumber')
                            rout_number.value = "XXXXX" + rout_number.value.slice(-4);

                            this.setState(prevState => ({
                                Customer:
                                {
                                    ...prevState.Customer, AccountNumber: "XXXXX" + acct_number.value.slice(-4)
                                }
                            }))

                            this.setState(prevState => ({
                                Customer:
                                {
                                    ...prevState.Customer, RoutingNumber: "XXXXX" + rout_number.value.slice(-4)
                                }
                            }))
                        }
                        this.setState({
                            IsTokenValid: event.errorcode == 0 ? true : false
                        });

                        this.setState({
                            emvToken: event.token,
                            expiryDate: null
                        })
                        this.validateForm();

                        event.preventDefault()
                    }).catch(error => {
                        console.log(error)
                    })
                }
                break;
            default:
                break;
        }
        this.validateField(name, value);

    };

    handleUserInput(e) {
        console.log('handleUserInput is calling..' + e.target.name);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'CardHolderName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardHolderName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Address: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, PostalCode: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                // this.setState(prevState => ({
                //     Amount:
                //     {
                //         ...prevState.Amount, Amount: value
                //     }
                // }), () => { this.validateField(name, value) })

                this.setState(({ Amount: value }), () => { this.validateField(name, value) });
                this.setState({ remainingBalance: global.InvoiceAmount - value })
                break;
            case "CheckBox":
                this.validateField(name, e.target.checked)
                break;
            case "AccountName":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, RoutingNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountType":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountType: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            default:
                break;
        }

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.Customer.FormErrors;
        let cardHolderNameValid = this.state.Customer.CardHolderNameValid;
        let checkBoxChecked = this.state.Customer.CheckBoxChecked;

        let accountNumberValid = this.state.Customer.AccountNumberValid;
        let accountNameValid = this.state.Customer.AccountNameValid;
        let routingNumberValid = this.state.Customer.RoutingNumberValid;
        let amountValid = this.state.Customer.AmountValid;
        switch (fieldName) {
            case 'CardHolderName':
                cardHolderNameValid = true;//value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.CardHolderName = cardHolderNameValid ? '' : ' is invalid';
                this.state.Customer.CardHolderNameValid = cardHolderNameValid ? true : false;
                break;
            case 'CheckBox':
                checkBoxChecked = value;
                this.state.Customer.CheckBoxChecked = value;
                break;
            case 'Amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.Customer.AmountValid = amountValid ? true : false;
                this.state.Amount = value;
                break;
            case 'AccountName':
                accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.Customer.AccountNameValid = accountNameValid ? true : false;
                break;
            case 'AccountNumber':
                accountNumberValid = value.match(/^\d{4,17}$/);
                this.state.Customer.AccountNumberValid = accountNumberValid ? true : false;
                break;
            case 'RoutingNumber':
                routingNumberValid = value.match(/^\d{9}$/);
                this.state.Customer.RoutingNumberValid = routingNumberValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            CardHolderNameValid: cardHolderNameValid,
            CheckBoxChecked: checkBoxChecked,
            AccountNameValid: accountNameValid,
            AccountNumberValid: accountNumberValid,
            RoutingNumberValid: routingNumberValid,
            AmountValid: amountValid
        }, this.validateForm);

    }

    validateForm() {
        //console.log(this.state.Customer.CardNumberValid);
        if (this.state.IsCreditCard) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: (this.state.IsCreditCard || this.state.IsDebitCard) && this.state.IsTokenValid
                        && this.state.Customer.CardHolderNameValid && this.state.Customer.AmountValid
                        && this.state.Customer.CheckBoxChecked && this.state.emvToken != ""
                }
            }), () => console.log())
        }
        else if (this.state.IsACH) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: this.state.Customer.AccountNameValid && this.state.IsTokenValid
                        && this.state.Customer.AmountValid && this.state.emvToken != ""
                }
            }), () => console.log())
        }
    }

    rawMarkup(value) {
        const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: this.state.InvoiceRawHtml };
    };

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {

            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    getInvoicePreview(invoice_id = "") {
        const params = { "invoice_id": invoice_id }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }


    getPaymentKeyDetail(value) {
        const params = { "key": value }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getVerifyPaymentKeyRequest(query);

    }

    setInvoicePreviewResult = (data) => {
        this.setState({ InvoiceRawHtml: data.data.html || null });

        data.data.invoice.map(data => {
            this.setState({ Invoice: data });            
            
            global.InvoiceAmount = parseFloat(data.Balance).toFixed(2);
            this.setState({ Amount: parseFloat(data.Balance).toFixed(2) }); 
            if(data.BillEmail != null && data.BillEmail != undefined){
                localStorage.setItem("CustomerLoginEmail", data.BillEmail.Address);
            }
            if (parseFloat(data.Balance).toFixed(2) == "0.00" ) {
                this.setState({ isPaid: true });
            }
            let ach = data.AllowOnlineACHPayment;
            let creditCard = data.AllowOnlineCreditCardPayment;
            
            if(ach == true || creditCard == true){
                if(this.state.IsACHEnabled && (this.state.IsCreditCardEnabled || this.state.IsDebitCardEnabled))
                {
                    this.setState({IsACHEnabled: ach, IsCreditCardEnabled: creditCard,
                    IsDebitCardEnabled: this.state.IsDebitCardEnabled});
                }
                if(creditCard == false)
                {
                    this.setState({groupButtonCreditCardStyle: "default"})
                    this.setState({groupButtonACHStyle: "primary active"})
                    this.setState({IsACH: ach })
                }
            }
        })
        const content_type = data.data.content_type
        if (content_type === "application/html") {
            this.setState({ showHTMLDiv: true });
        } else {
            this.setState({ showHTMLDiv: false })
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas")
        }            
        if(this.state.IsCreditCardEnabled){
            this.GetConvFee();
        }
        else{
            this.setState({ showLoader: false });   
        }
        
    }


    setPaymentKeyResult = (data) => {
        let uuid = data.access_token;
        let full_name = data.full_name;
        let invoice_id = data.inv_id;
        let app_id = data.app_id;
        //  global.SuccessfulLoggedIn = true;
        let processor = data.processor;
        let tokenize_key = null;
        if (processor != null) {
            if (processor.ProcessorType == "cardconnect") {
                this.state.copilotURL = processor.ProcessorConfig.URL;
            }
            let ach = processor.ProcessorConfig.supported_methods.ach            
            let creditcard = processor.ProcessorConfig.supported_methods.creditcard
            let debitcard = processor.ProcessorConfig.supported_methods.debitcard
            this.setState({IsACH: ach, IsACHEnabled: ach })
            this.setState({IsCreditCard: creditcard, IsCreditCardEnabled: creditcard, IsDebitCardEnabled: debitcard })
            if(ach && (creditcard || debitcard)){
                this.setState({IsACH: false })
            }
            if(creditcard == false)
            {
                this.setState({groupButtonCreditCardStyle: "default"})
                if(debitcard){
                    this.setState({groupButtonDebitCardStyle: "primary active"})
                    this.setState({IsDebitCard: debitcard});
                }
                else{
                    this.setState({groupButtonACHStyle: "primary active"})
                    this.setState({IsACH: ach })
                }

            }
        }

        localStorage.setItem(StorageEnum.UUID, uuid);
        localStorage.setItem(StorageEnum.FULLNAME, full_name);
        localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
        localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
        localStorage.setItem(StorageEnum.IS_FROM_CP, "true");        
        sessionStorage.setItem("INV_ID", invoice_id);
        sessionStorage.setItem(StorageEnum.APPID, app_id);

        this.getInvoicePreview(invoice_id);

    }

    /**
     * API Calling
     */

    /** GET request to verify customer payment key */
    _getVerifyPaymentKeyRequest = (params) => {
        // this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.getVerifyPaymentKeyRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this.setPaymentKeyResult(data.data);
                    }
                    else {
                        this.setState({ showLoader: false });
                    }
                } else {
                    this.setState({ showLoader: false });
                }
            }).catch(error => {
                //this.setState({ ShowProgressBar: false });
                this.setState({ showLoader: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {
        this.setState({ errorMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    this.setInvoicePreviewResult(data);
                } else {
                    this.setState({ showLoader: false });
                }
            }).catch(error => {
                this.setState({ showLoader: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    /** POST request for payment through CardConnect */
    _postCollectPaymentRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.postCollectPaymentRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    console.log(data.statuscode + '  ' + data.statusmessage)
                    this.setState({ StatusCode: data.statuscode });
                    this.setState({ StatusMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        if (parseFloat(this.state.remainingBalance).toFixed(2) == "0.00") {
                            this.setState({ isPaid: true });
                        }
                        else {
                            this.setState({ Amount: parseFloat(this.state.remainingBalance).toFixed(2) });
                            global.InvoiceAmount = parseFloat(this.state.remainingBalance).toFixed(2);
                        }
                    }
                    if (this.state.IsCreditCard || this.state.IsDebitCard) {
                        setTimeout(() => {
                            var iframe = document.getElementById('tokenframe');
                            iframe.src = iframe.src;
                        }, 1000);
                    }
                    else {
                         this.setState(prevState => ({
                                Customer:
                                {
                                    ...prevState.Customer, AccountNumber: "", RoutingNumber: ""
                                }
                            }));


                        // setTimeout(() => {
                        //     var iframe = document.getElementById('achtokenframe');
                        //     iframe.src = iframe.src;
                        // }, 1000);
                    }
                    this.setState({ IsTokenValid: false });
                    this.setState({ emvToken: "", expiryDate: "" });
                    this.validateForm();

                } else {
                    this.setState({ StatusCode: -1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    /** POST request for CardConnect CardSecure api */
    async _postCardSecureTokenize(data) {
        let url = `${this.state.copilotURL}/cardsecure/api/v1/ccn/tokenize`

        let response = await (fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }).then(res => {
            return res.json()
        }).catch(err => {
            console.log('Error: ', err)
        })
        )
        return response
    }

    extractTokenFromUrl = () => {
        // First parse and obtain invoice token form request url        
        // let url = BASE_URL + "apps/customerportal/invoice/?q=e4da3b7fbbce2345d7772b0674a318d5";
        const url = window.location.href;
        const path = url.split("=");
        const token = (path[1] || "");
        return token;
    };

    setAddressFields(jsonData) {       
        let isPostalCodeAvailable = false;
        let isCityAvailable = false;
        
        // Loop through the address components
        for (const component of jsonData.address_components) {
          // Extract the first element of the "types" array          
          if (component.types.includes("postal_code")) {
                {
                    isPostalCodeAvailable = true;
                }
            }
            if (component.types.includes("locality")) {
                {
                    isCityAvailable = true;
                }
            }
            
          const firstType = component.types[0];
      
          // Set the variables based on the first type
          switch (firstType) {
            case 'postal_code':
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, PostalCode: component.short_name
                }
            }))
              break;
            case 'locality':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: component.short_name
                    }
                }))
              break;
            case 'administrative_area_level_1':              
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: component.short_name
                    }
                }))
              break;
            case 'country':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: component.short_name
                    }
                }))
              break;
          }
        }
        
        if(!isPostalCodeAvailable){
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, PostalCode: ""
                }
            }))
        }
        if(!isCityAvailable){
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, City: ""
                }
            }))
        }

        this.setState(prevState => ({
            Customer:
            {
                ...prevState.Customer, IsPostalCodeAvailable: isPostalCodeAvailable, IsCityAvailable: isCityAvailable
            }
        }))
        
    }

    render() {
        // const url = 'https://fts.cardconnect.com:6443/itoke/ajax-tokenizer.html'
        const url = `${this.state.copilotURL}/itoke/ajax-tokenizer.html`
        console.log(url);
        var padding = this.state.IsACH ? "0px 0 7px" : "6px 0 7px";
        const cssStyle = 'css=.success{color:green;border-color:green;}.error{color:red;border-color:red;}label{font-family:sans-serif;font-weight:bold;font-size:14px;margin-top:12px;margin-bottom:0px;color:rgba(0, 0, 0, 0.54)}input{font:inherit;color: currentColor;font-family:sans-serif;height:1.1875em;font-size:18px;margin-top:-5px;color:currentColor;width:100%;border: 0px;border-bottom: 1px solid;border-bottom-color: rgba(227,227,227,1); outline: none;display:block;padding:' + padding + ';margin-bottom: -6px;background:none;box-sizing: content-box;}select{font:inherit;color: currentColor;outline: none;font-family:sans-serif;height:1.1875em;font-size:18px;margin-top:-5px;color:currentColor;border:0;border-bottom: 1px solid; border-bottom-color: rgba(227,227,227,1);padding: 6px 0 7px;background:none;box-sizing: content-box; width:46%;margin-bottom: 10px;}';
        // line-height:1.42857;

        const params = new URLSearchParams({
            maskfirsttwo: false,
            useexpiry: this.state.IsACH ? false : true,
            usemonthnames: this.state.IsACH ? false : true,
            usecvv: this.state.IsACH ? false : true,
            cardnumbernumericonly: this.state.IsACH ? false : true,
            orientation: 'default',
            invalidinputevent: true,
            enhancedresponse: true,
            formatinput: true,
            tokenizewheninactive: true,
            inactivityto: this.state.IsACH ? 5000 : 4000,
            fullmobilekeyboard: true
        });

        const iFrameUrl = url + '?' + params + '&' + encodeURI(cssStyle);
        
        return (
            <div className="ii-main-content">
                {this.state.showLoader &&
                    <div style={styles.overlay}>
                        <div style={{ position: "fixed", top: "calc(50% - 100px)", left: "calc(50% - 142px)"}}>
                            <img src={InstantInvoiceLoader} width="300px" height="105px" />
                        </div> 
                    </div>
                }
                <Card
                    content={
                        <Row>
                            { !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))  &&
                            <Col md={8}>
                                <Card
                                    content={
                                        <div>
                                            {
                                                this.state.showHTMLDiv ? <div dangerouslySetInnerHTML={this.rawMarkup(this.state.Invoice || null)} /> : <div id="pdf-canvas" height="0"> </div>
                                            }
                                            {
                                                this.state.isPaid &&
                                                <div style={styles.watermark}>
                                                </div>
                                            }
                                        </div>
                                    }
                                />
                            </Col>
                             }
                            <Col md={4}>
                                {this.state.CardConnect &&
                                    <div>
                                            <div>                                    
                                                <Row>
                                                    <div className="text-center">
                                                        <ButtonGroup>
                                                            {this.state.IsCreditCardEnabled &&
                                                                <Button bsStyle={this.state.groupButtonCreditCardStyle} name="creditcard" onClick={e => this.ccHandleRadio(e)}  fill={this.state.IsCreditCard} wd>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-credit-card" />
                                                                    </span>
                                                                    Credit Card
                                                                </Button>
                                                            }
                                                            {this.state.IsDebitCardEnabled &&
                                                                <Button bsStyle={this.state.groupButtonDebitCardStyle} name="debitcard" onClick={e => this.ccHandleRadio(e)} fill={this.state.IsDebitCard} wd>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-credit-card-alt" />
                                                                    </span>Debit Card
                                                                </Button>
                                                            }
                                                            {this.state.IsACHEnabled && 
                                                                <Button bsStyle={this.state.groupButtonACHStyle} name="ach" onClick={e => this.ccHandleRadio(e)} fill={this.state.IsACH} wd>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-bank" />
                                                                    </span>Bank/ACH
                                                                </Button>
                                                            }
                                                        </ButtonGroup>
                                                    </div>                                                    
                                                </Row>
                                                <br />
                                            </div>
                                        
                                        {this.state.IsCreditCard && this.state.ConvFee != "" && 
                                            <Row>
                                                <Col xs={12} sm={12} md={12}>
                                                    <InputLabel style={{ color:"black", fontSize: 17, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11}}>
                                                        {this.state.ConvFee.name} &nbsp;&nbsp; {this.state.ConvFee.is_percentage == false && "$"}{parseFloat(this.state.ConvFee.value).toFixed(2)}{this.state.ConvFee.is_percentage == true && "%"}
                                                    </InputLabel>
                                                </Col>
                                            </Row>
                                        }
                                        <Card
                                            content={
                                                <div>
                                                    {this.state.ShowProgressBar &&
                                                        <div style={{ marginBottom: "2px" }}>
                                                            <CustomProgressBar></CustomProgressBar>
                                                        </div>

                                                    }
                                                    {this.state.StatusCode == 0 &&
                                                        <p style={styles.successResponse}>{this.state.StatusMessage}</p>
                                                    }
                                                    {this.state.StatusCode != 0 &&
                                                        <p style={styles.errorResponse}>{this.state.StatusMessage}</p>
                                                    }                                                   
                                                    <Row>
                                                        <Col xs={5} sm={5} md={5}>
                                                            <h4 style={styles.amountLabel}>Amount ($):</h4>
                                                        </Col>
                                                        <Col xs={7} sm={7} md={7}>
                                                            <AmountInput
                                                                name="Amount"
                                                                id="Amount"
                                                                value={this.state.Amount} 
                                                                labelText=""
                                                                readOnly={this.state.IsCreditCard && this.state.ConvFee != "" } 
                                                                formControlProps={{
                                                                    fullWidth: true,                                                                    
                                                                    disabled: this.state.isPaid, //this.state.Amount == "0.00" && this.state.StatusCode === 0,
                                                                    error: !this.state.Customer.AmountValid,
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) },
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onError: err => console.log(`cvc error: ${err}`)
                                                                }}
                                                                inputProps={{ style: { fontSize: 28, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11 } }} // font size of input text          
                                                                labelProps={{ style: { fontSize: 20, fontWeight: "bold", marginTop: 6 } }}
                                                            />                                                            
                                                        </Col>
                                                    </Row>
                                                    <div style={{ display: this.state.IsACH ? "none" : "block" }}>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <InputLabel style={styles.dropDownFontStyle}>
                                                                    Card Holder's Name
                                                                </InputLabel>
                                                                <CustomInput
                                                                    isRequired={true}
                                                                    id="CardHolderName"
                                                                    name="CardHolderName"
                                                                    value={this.state.Customer.CardHolderName}
                                                                    showErrorMessage={!this.state.Customer.CardHolderNameValid}
                                                                    errorMessage="Card holder name is invalid"
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        disabled: this.state.isPaid,
                                                                        //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                        error: !this.state.Customer.CardHolderNameValid,
                                                                        onChange: (e) => { this.handleUserInput(e) },
                                                                        //onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                        onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                    }}
                                                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                    labelProps={{ style: { fontWeight: "bold" } }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <FormGroup controlId='tokenEvent' style={{ marginLeft: "-6px", marginBottom: "0px" }} >
                                                                    <iframe
                                                                        title='CardPointeTokenizer'
                                                                        id='tokenframe'
                                                                        name='tokenframe'
                                                                        src={iFrameUrl}
                                                                        frameBorder='0'
                                                                        scrolling='no'
                                                                        width='100%'
                                                                        height='180'
                                                                        onLoad={this.handleTokenEvent}
                                                                    >
                                                                    </iframe>
                                                                    <input type='hidden' name='token' id='mytoken' />
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>
                                                    </div>
                                                    <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <InputLabel style={styles.dropDownFontStyle} >
                                                                    Account Name
                                                                </InputLabel>
                                                                <CustomInput
                                                                    isRequired={true}
                                                                    id="AccountName"
                                                                    name="AccountName"
                                                                    value={this.state.Customer.AccountName}
                                                                    showErrorMessage={!this.state.Customer.AccountNameValid}
                                                                    errorMessage="Account name is invalid"
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        disabled: this.state.isPaid,
                                                                        //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                        error: !this.state.Customer.AccountNameValid,
                                                                        onChange: (e) => { this.handleUserInput(e) },
                                                                        onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                        onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                    }}
                                                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                    labelProps={{ style: { fontWeight: "bold" } }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}>
                                                                    Routing And Account No. <small>(Default Format: RoutingNo/AccountNo e.g 036001808/123456789)</small>
                                                                    </InputLabel>  
                                                                    <FormGroup
                                                                        controlId='tokenEvent'
                                                                        style={{ marginLeft: "-6px", marginBottom: "0px" }}
                                                                    >
                                                                        <iframe
                                                                            title='CardPointeTokenizer'
                                                                            id='achtokenframe'
                                                                            name='achtokenframe'
                                                                            src={iFrameUrl}
                                                                            frameBorder='0'
                                                                            scrolling='no'
                                                                            width='100%'
                                                                            height='33'
                                                                            onLoad={this.handleTokenEvent}
                                                                        >
                                                                        </iframe>
                                                                        <input type='hidden' name='token' id='mytoken' />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> */}
                                                        <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <InputLabel style={styles.dropDownFontStyle} >
                                                                Account Number
                                                            </InputLabel>
                                                            <CustomInput
                                                                //  labelText="Address"
                                                                isRequired={true}
                                                                id="accountnumber"
                                                                name="AccountNumber"
                                                                value={this.state.Customer.AccountNumber}
                                                                type='number'
                                                                showErrorMessage={!this.state.Customer.AccountNumberValid}
                                                                errorMessage="Account Number is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid,
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <InputLabel style={styles.dropDownFontStyle} >
                                                                Routing Number
                                                            </InputLabel>
                                                            <CustomInput
                                                                isRequired={true}
                                                                id="routingnumber"
                                                                name="RoutingNumber"
                                                                value={this.state.Customer.RoutingNumber}
                                                                type="number"
                                                                showErrorMessage={!this.state.Customer.RoutingNumberValid}
                                                                errorMessage="Routing Number is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        </Row>
                                                    </div>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <InputLabel style={styles.dropDownFontStyle} >
                                                                Address
                                                                    </InputLabel>
                                                            {/* <CustomInput
                                                                //  labelText="Address"
                                                                isRequired={true}
                                                                id="address"
                                                                name="Address"
                                                                value={this.state.Customer.Address}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            /> */}
                                                            <Input
                                                                fullWidth
                                                                color="secondary"
                                                                disabled={this.state.isPaid}                                                                
                                                                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                                                                <Autocomplete
                                                                    apiKey={"AIzaSyAJHQAxrJ3oCdJA5Fb8odNzNlgX36awEz8"}
                                                                    style={{ width: "100%", fontFamily: "sans-serif", fontSize: "18px", marginTop: "0px",  border: 'none' }}
                                                                    placeholder=""
                                                                    onPlaceSelected={(place) => {
                                                                        this.setAddressFields(place);                                        
                                                                        this.setState(prevState => ({
                                                                            Customer:
                                                                            {
                                                                                ...prevState.Customer, Address: place.formatted_address
                                                                            }
                                                                        }), () => { this.validateField("Address", place.formatted_address) })
                                                                    }}
                                                                    options={{
                                                                        types: ["geocode"],
                                                                        componentRestrictions: { country: "US" },
                                                                    }}
                                                                    defaultValue={this.state.Customer.Address}
                                                                    />
                                                                )}
                                                                />

                                                                
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6}>
                                                            <InputLabel style={styles.dropDownFontStyle}>
                                                                City
                                                            </InputLabel>
                                                            <CustomInput
                                                                id="city"
                                                                name="City"
                                                                value={this.state.Customer.City}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.Customer.IsCityAvailable,
                                                                    //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>                                                        
                                                        <Col xs={6} sm={6} md={6}>
                                                            <InputLabel style={styles.dropDownFontStyle}>
                                                                State
                                                            </InputLabel>
                                                            <CustomInput
                                                                id="state"
                                                                name="State"
                                                                value={this.state.Customer.State}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || true,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>                                                        
                                                        <Col xs={6} sm={6} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}>
                                                                Postal Code
                                                            </InputLabel>
                                                            <CustomInput
                                                                // labelText="Postal Code"
                                                                id="postal-code"
                                                                name="PostalCode"
                                                                value={this.state.Customer.PostalCode}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.Customer.IsPostalCodeAvailable,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6}>
                                                            <InputLabel style={styles.dropDownFontStyle}>
                                                                Country
                                                            </InputLabel>
                                                            <CustomInput
                                                                // labelText="Country"
                                                                id="country"
                                                                name="Country"
                                                                value={this.state.Customer.Country}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || true,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    { (this.state.IsCreditCard || this.state.IsDebitCard) &&
                                                        <div style={{ marginTop: "20px" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <CustomCheckbox
                                                                        name="CheckBox"
                                                                        checked={this.state.Customer.CheckBoxChecked}
                                                                        isDisabled={this.state.isPaid}
                                                                        //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                                        labelText={"I agree to pay ($" + parseFloat(this.state.Amount).toFixed(2) + ") according to the card issuer agreement."}
                                                                        labelProps={{ style: { fontSize: "15px" } }}
                                                                        formControlProps={{
                                                                            onChange: (e) => { this.handleUserInput(e) }
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    <Row>
                                                        <br />
                                                        <Col xs={12} sm={12} md={12}>
                                                            <Button bsStyle="primary" style={{ width: "100%" }} fill onClick={e => this.handleSubmit(e)} type="submit" disabled={!this.state.Customer.FormValid || this.state.isPaid || this.state.ShowProgressBar}>
                                                                CHARGE
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            }
                                        />

                                    </div>
                                }
                                {this.state.CardConnect &&
                                    <div>
                                    </div>
                                }
                                {this.state.AuthorizeNet &&
                                    <div>
                                    </div>
                                }
                            </Col>
                        </Row>
                    }
                />
            </div>
        );
    }
}

export default CCPayment;