import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
    FormControl,
    Alert
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import MaterialTable, { MTableToolbar } from 'material-table';
import Datetime from "react-datetime";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import NetworkService from "services/NetworkService"
 

class Transactions extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            showSendReceiptPopupModal: false,
            ShowPopupProgressBar: false,
            errorCode: null,
            errorMessage: "",
            receiptModel: {
                customername: "",
                amount: 0.00,
                paymenttype: "",
                paymentnumber: "",
                customeremail: "",
            },
            voidModel: {
                customername: "",
                amount: 0.00,
                paymenttype: "",
                paymentnumber: ""
            },
            refundModel: {
                customername: "",
                amount: 0.00,
                paymenttype: "",
                paymentnumber: ""
            },
            amountToRefund: 0.00,
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            queryFromDate: "",
            queryToDate: "",
            ShowProgressBar: false,
            transactionStatus: [
                { value: "", label: "Select Status", isDisabled: true },
                { value: "all", label: "All" },
                { value: "approved", label: "Approved" },
                { value: "declined", label: "Declined" },
            ],
            filter_transaction: { value: "all", label: "All" },
            data: [              
            ],
            columns: [
                {
                    title: 'Trans.ID',
                    field: 'tansactionid',
                },
                {
                    title: 'Customer Name',
                    field: 'customername',
                    cellStyle: {
                        width: 'calc(100% - 0px)'
                    },
                },
                {
                    title: 'Date',
                    field: 'date',
                    type: 'date'
                },
                {
                    title: 'Amount',
                    field: 'amount',
                },
                {
                    title: 'Trans.Type',
                    field: 'transactiontype'
                },
                {
                    title: 'Status',
                    field: 'transactionstatus'
                },
                {
                    title: 'Type',
                    field: 'paymenttype'
                },
                {
                    title: 'No.',
                    field: 'paymentnumber'
                }
            ],
            subTransactionColumns: [
                {
                    title: 'Trans.ID',
                    field: 'tansactionid',
                },
                {
                    title: 'Date',
                    field: 'date',
                    type: 'date'
                },
                {
                    title: 'Amount',
                    field: 'amount',
                },
                {
                    title: 'Trans.Type',
                    field: 'transactiontype'
                },
                {
                    title: 'Status',
                    field: 'transactionstatus'
                },
            ],
            options: {
                //selection: true,
                pageSize: 50,
                pageSizeOptions: [25, 50, 100, 200, 500],
                paging: true,
                toolbar: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                     backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"                   
                    //backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            subTransactionOptions: {
                //selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#eeeeee',
                    fontWeight: "bold",
                    zIndex: 4,
                    //  color: '#FFF'
                },
                paging: false,
                toolbar: false,
                sorting: false,
                draggable: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            detailPanel: [
                rowData => ({
                    disabled: !rowData.expandable,
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionExpandToolTip">Expand</Tooltip>}><i className="fa fa-plus" style={{ color: "#0075c1", display: !rowData.expandable ? "none" : "block" }}></i></OverlayTrigger>, //<UnfoldMore className={!rowData.expandable && classes.displayNone} />,
                    openIcon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionCollapseToolTip">Collapse</Tooltip>}><i className="fa fa-minus" style={{ color: "#0075c1" }}></i></OverlayTrigger>,
                    render: () => {
                        return (
                            <Row>
                                <Col md={10} mdOffset={1}>
                                    <MuiThemeProvider theme={this.theme}>
                                        <MaterialTable
                                            columns={this.state.subTransactionColumns}
                                            data={rowData.subTrans}
                                            options={this.state.subTransactionOptions}
                                            actions={this.state.subTransactionActions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                        //  detailPanel={this.state.detailPanel}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        )
                    },
                })
            ],
            emailReceiptToolTip: <Tooltip id="emailReceiptToolTip">Email Receipt</Tooltip>,
            printReceiptToolTip: <Tooltip id="printReceiptToolTip">Print Receipt</Tooltip>,
            voidToolTip: <Tooltip id="voidToolTip">Void</Tooltip>,
            refundToolTip: <Tooltip id="refundToolTip">Refund</Tooltip>,
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.emailReceiptToolTip}>
                        <i className="fa fa-envelope" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleOpenSendReceiptPopup(rowData)
                }
            ],
            subTransactionActions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={this.state.emailReceiptToolTip}>
                        <i className="fa fa-envelope" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleOpenSendReceiptPopup(rowData)
                },
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={this.state.printReceiptToolTip}>
                //         <i className="fa fa-print" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => alert("You saved " + rowData.customername)
                // }
            ],

        }
        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.SetFromToDates();

        this.filterTransactions();
    }

    SetFromToDates() {        
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    /** HANDLER */

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ errorMessage: "" });
        // console.log("from date: " + this.state.queryFromDate + "   to date : " + this.state.queryToDate);

        this.filterTransactions();
    }

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            receiptModel: {
                ...prevState.receiptModel,
                customer_email: value
            }
        }));
        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendInvoiceEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )

            });
            this.setState({
                sendInvoiceClassName: "error"
            });

        }
        else {
            this.setState({ errorSendInvoiceEmail: null });
            this.setState({ sendInvoiceClassName: "valid" });
        }
    }

    filterTransactions = () => {
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
            "status": this.state.filter_transaction.value
        }
        console.log(params);
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getTransactionsRequest(query);
    };

    setPaymentListResult(result) {
        const newSeries = [];
        const salesData = [];
        const voidRefundData = [];
        console.log(result);
        result.map((data, index) => {
            if(data.parent_ref_id === null || data.parent_ref_id === "")
            {
                salesData.push({
                    index: index,
                    expandable: false,
                    id: data.trans_id,
                    tansactionid: data.ref_id,
                    customername: data.customer_name,
                    date: data.trans_date,
                    amount: parseFloat(data.trans_amount).toFixed(2),
                    transactiontype: data.trans_type,
                    transactionstatus: data.status,
                    paymenttype: data.process_type,
                    paymentnumber: data.invoice_no,
                    ref_id: data.ref_id,
                    customer_email: data.customer_email,
                    subTrans: [],
                });
            }
        });

        result.map((vdata, index2) => {
            if(vdata.parent_ref_id !== null && vdata.parent_ref_id !== "")
            {
                voidRefundData.push({
                    index: index2,
                    expandable: false,
                    id: vdata.trans_id,
                    tansactionid: vdata.ref_id,
                    customername: vdata.customer_name,
                    date: vdata.trans_date,
                    amount: parseFloat(vdata.trans_amount).toFixed(2),
                    transactiontype: vdata.trans_type,
                    transactionstatus: vdata.status,
                    paymenttype: vdata.process_type,
                    paymentnumber: vdata.invoice_no,
                    ref_id: vdata.ref_id,
                    parent_ref_id: vdata.parent_ref_id,
                    customer_email: vdata.customer_email
                });
            }
        });
        
        salesData.forEach(function(sale) {
            voidRefundData.forEach(function(voidRefund){
                if(sale.ref_id === voidRefund.parent_ref_id)
                {
                    sale.expandable = true;
                    voidRefund.customername = sale.customername;
                    voidRefund.customer_email = sale.customer_email;    
                    voidRefund.paymentnumber = sale.paymentnumber;                
                    sale.subTrans.push(voidRefund);
                }
            });
            newSeries.push(sale);
        });

        this.setState({ data: newSeries });
    }

    // GET request for transaction details
    _getTransactionsRequest(params = "") {
        this.setState({ errorMessage: "" });
        this.setState({ ShowProgressBar: true });
        const app_id = ApplicationIDEnum.QuickBooksPOS;
        NetworkService.getPaymentListRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    //    this.setState({ errorMessage: data.statusmessage });

                    if (data.data != null) {
                        this.setPaymentListResult(data.data.items);
                        this.setState({ ShowProgressBar: false });
                    }

                } else {
                }
            }).catch(error => {
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({ ShowProgressBar: false });
            });

    }

    onSetResult = (result) => {
        const newSeries = [];
        result.items.map((transaction, index) => {
            const data = {
                index: index,
            }
            newSeries.push(data);
        });
    }

    handleOpenSendReceiptPopup(transaction) {
        this.state.receiptModel = transaction;
        this.setState({ showSendReceiptPopupModal: true });
        console.log(this.state.receiptModel);
    }

    handleSendReceiptEmail(e, receipt) {
        console.log(receipt);
        e.preventDefault();
        const customer_email = receipt.customer_email
        const payload = {
            "ToEmails": [customer_email],
            "Id": receipt.id,
            "Receipt": receipt
        }
        this._postEmailReceiptRequest(payload);
    }

    // POST request
    _postEmailReceiptRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.QuickBooksPOS;

        NetworkService.postEmailReceiptRequest(app_id, data)
        .then(data => {

            this.setState({ ShowPopupProgressBar: false });
            this.setState({ showSendReceiptPopupModal: false });
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                if (data.statuscode == 0) {
                    if (data.data != null) {

                    }
                }
                setTimeout(() => {
                    this.setState({ errorMessage: "" })
                }, 5000);

            } else {
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            this.setState({ showSendReceiptPopupModal: false });
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        });
    }

  
    handleVoidTransactionSubmit(transaction) {
        console.log(transaction);
        this._postVoidTransactionRequest(transaction);
    }
         
    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            // dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            //  dateFormat={'YYYY-MM-DD'}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <span>Transaction Status</span>
                                    <FormGroup>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="transactionStatus"
                                            value={this.state.filter_transaction}
                                            onChange={value => {
                                                this.setState({ filter_transaction: value });
                                            }
                                            }
                                            options={this.state.transactionStatus}
                                            placeholder="Select Status"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br/>
                                            </div>

                                        }
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            actions={this.state.actions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            detailPanel={this.state.detailPanel}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                            <Modal
                                show={this.state.showSendReceiptPopupModal}
                                onHide={() => this.setState({ showSendReceiptPopupModal: false })}
                            >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }
                                <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                    <Modal.Title style={{ color: "white" }}>Send Receipt</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="modal-body text-center" >
                                        <Row>
                                            <Col xs={12} sm={12} md={5} className="text-left">
                                                <h5>Customer : </h5>
                                            </Col> <Col xs={12} sm={12} md={7} className="text-left">
                                                <h5>{this.state.receiptModel.customername}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={5} className="text-left">
                                                <h5>{this.state.receiptModel.paymenttype} No :</h5>
                                            </Col> <Col xs={12} sm={12} md={7} className="text-left">
                                                <h5>{this.state.receiptModel.paymentnumber}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={5} className="text-left">
                                                <h5>Amount :</h5>
                                            </Col> <Col xs={12} sm={12} md={7} className="text-left">
                                                <h5>${this.state.receiptModel.amount}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={5} className="text-left">
                                                <h5>Customer Email :</h5>
                                            </Col>
                                            <Col md={7} >
                                                <FormGroup >
                                                    <FormControl
                                                        value={this.state.receiptModel.customer_email}
                                                        placeholder="Email"
                                                        type="email"
                                                        autoComplete="off"
                                                        className={this.state.sendInvoiceClassName}
                                                        onChange={event => this.validateEmail(event)}
                                                    />
                                                    {this.state.errorSendInvoiceEmail}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendReceiptPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.handleSendReceiptEmail(e, this.state.receiptModel)}>Send</Button>
                                </Modal.Footer>

                            </Modal>
                            
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Transactions;