import React from 'react';
import Card from "components/Card/Card.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {
    Row,
    Col,
    Alert,
    Modal,
    FormGroup,
    FormControl,
} from "react-bootstrap";
 
const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    customButtonStyle: {
        minWidth: "100px",
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        marginRight: "10px"
    }
}
class ISC250Terminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowProgressBar: false,
            errorCode: null,
            errorMessage: "",
            isModalDialogShow: this.props.isModalDialogShow,
            terminals: this.props.terminals,
            terminalNames:[],
            deviceNickname: "",
            deviceSN: "",
            deviceNicknameErrorMessage: "",
            deviceSNErrorMessage: "",
            deviceNicknameState: "",
            deviceSNState: "",
            isValid: false,
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        let terminalNameArray = [];
        if(this.state.terminals != undefined ){
        this.state.terminals.map((el, i) => {            
            terminalNameArray.push(el.deviceName)
        });  
        }                  
        this.setState({ terminalNames: terminalNameArray });
        document.getElementById("loaderDiv").style.display = "none";        
        /** calling network api */
    }

    _postNMITerminalRegister(data) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2;
        NetworkService.postNMITerminalRegister(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: "Device registered successfully." });
                    } else {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                    }                    
                }
                else {
                    this.setState({ errorCode: 1 });
                    this.setState({ errorMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    handlerSaveButton(e) { 
        if(this.state.isValid){
            let payload = {
                device_name: this.state.deviceNickname,
                device_code: this.state.deviceSN
            }
            this._postNMITerminalRegister(payload);
        }
    }

    handlerCloseButton(e) {
        this.setState({ isModalDialogShow: false });
        this.props.parentCallback(false);   
    }

    handlerUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case "DeviceSN":
                this.state.deviceSN = value;
                this.onDeviceSNBlur();
                break;
            case "DeviceNickname":
                this.state.deviceNickname = value;
                this.onDeviceNicknameBlur();
                break;
            default:
                break;
        }
    }

    onDeviceNicknameBlur() {
        if (this.state.deviceNickname == "") {
            this.setState({
                deviceNicknameErrorMessage: (
                    <small className="text-danger">
                        Device Nickname is required.
                    </small>
                )
            });
            this.state.deviceNicknameState = "error";
        } else if (this.state.deviceNickname.length <= 2) {
            this.setState({
                deviceNicknameErrorMessage: (
                    <small className="text-danger">
                        Invalid Device Nickname.
                    </small>
                )
            });
            this.state.deviceNicknameState = "error";
        } else if(this.state.terminalNames.includes(this.state.deviceNickname)){
             this.setState({
                deviceNicknameErrorMessage: (
                    <small className="text-danger">
                        Device Nickname already exists.
                    </small>
                )
            });
            this.state.deviceNicknameState = "error";
        } else {
            this.state.deviceNicknameState = "valid";
            this.setState({ deviceNicknameErrorMessage: null });
        }
        if(this.state.deviceNicknameState === "valid" && this.state.deviceSNState === "valid"){
            this.setState({isValid: true})
        }else{
            this.setState({isValid: false})
        }
    }

    onDeviceSNBlur() {
        if (this.state.deviceSN == "") {
            this.setState({
                deviceSNErrorMessage: (
                    <small className="text-danger">
                        Device Registration Code is required.
                    </small>
                )
            });
             this.state.deviceSNState = "error";
        } else if (this.state.deviceSN.length <= 4) {
            this.setState({
                deviceSNErrorMessage: (
                    <small className="text-danger">
                        Invalid Registration Code.
                    </small>
                )
            });
            this.state.deviceSNState = "error";
        } else {
            this.state.deviceSNState = "valid";
            this.setState({ deviceSNErrorMessage: null });
        }

        if(this.state.deviceNicknameState === "valid" && this.state.deviceSNState === "valid"){
            this.setState({isValid: true})
        }else{
            this.setState({isValid: false})
        }
    }

    onCloseModalDialog() {
        this.setState({ isModalDialogShow: false });
        this.props.parentCallback(false);   
    }

    /** API's calling */
    _postISC250TerminalSetting() {
        const url = BASE_URL + "api/v1/erp/status";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token
            }
        }).then(response => {
            return response.json()
        }).then(data => {
            this.setState({ errorCode: data.statuscode });
            this.setState({ ShowProgressBar: false });

            if (data != null && data.statuscode == 0) {
                data.data.forEach((element) => {
                    switch (element.type) {
                        case "quickbooks": {
                            this.setState({ is_qbo_connected: element.is_connected });
                            break;
                        }
                        default: {
                            console.log("Service not available");
                        }
                    }
                });
            }
            else {
                console.log("Service not available");
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    render() {
        return (
            <div>
                
                <Row>
                    <Col md={12} sm={12}>
                        <Modal
                            show={this.state.isModalDialogShow}
                            onHide={() => this.onCloseModalDialog()}
                            backdrop="static"                                   
                        >
                            {this.state.ShowProgressBar &&
                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                <Modal.Title style={{ color: "white" }}>Registration - Ingenico iSC250</Modal.Title>
                            </Modal.Header>                            
                            <Modal.Body>
                                <div className="modal-body">
                                    {this.state.errorMessage &&
                                        <div>
                                            <Alert style={{marginTop:"-15px"}} bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                <span>
                                                    {this.state.errorMessage}
                                                </span>
                                            </Alert>
                                        </div>
                                    }                                     
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Device Nickname: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            id="devicenickname"
                                            type="text"
                                            placeholder="Device Nickname"
                                            name="DeviceNickname"
                                            className={this.state.deviceNicknameState}
                                            value={this.state.deviceNickname}
                                            onChange={event => { this.handlerUserInput(event) }}
                                            onBlur={e => this.onDeviceNicknameBlur()}
                                        />
                                        {this.state.deviceNicknameErrorMessage}
                                    </FormGroup>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Registration Code: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            id="devicesn"
                                            type="text"
                                            placeholder="Registration Code"
                                            name="DeviceSN"
                                            className={this.state.deviceSNState}
                                            value={this.state.deviceSN}
                                            onChange={event => { this.handlerUserInput(event) }}
                                            onBlur={e => this.onDeviceSNBlur()}
                                        />
                                        {this.state.deviceSNErrorMessage}
                                    </FormGroup>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={this.handlerCloseButton.bind(this)}>Close</Button>
                                <Button bsStyle="primary" fill wd onClick={this.handlerSaveButton.bind(this)}  disabled={!this.state.isValid}>Register</Button>
                            </Modal.Footer>
                        </Modal>                        
                    </Col>
                </Row>

            </div>
        )
    }
}

export default ISC250Terminal;
