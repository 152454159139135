/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

class LoginCard extends Component {
  render() {
    const customHeaderStyle = {
      margin: 0,
      color: "#0075c1",
      fontSize: "30px",
      fontWeight:"bold"
    }
    const customCategoryStyle = {
      marginTop: "10px",
      fontWeight: "bold",
    }
    
    const customDivStyle = {
      padding: "10px",
      marginTop: "10px",
      maxWidth: "100%"
    }
    return (
        <div style={customDivStyle}
          className={
            "card" +
            (this.props.hidden ? " card-hidden" : "") +
            (this.props.calendar ? " card-calendar" : "") +
            (this.props.plain ? " card-plain" : "") +
            (this.props.wizard ? " card-wizard" : "")
          }
        >
          {this.props.title !== undefined || this.props.category !== undefined ? (
            <div
              className={"header" + (this.props.textCenter ? " text-center" : "")}
            >
              <h4 style={customHeaderStyle}>{this.props.title}</h4>
              <p style={customCategoryStyle}>{this.props.category}</p>
            </div>
          ) : (
              ""
            )}
          <div
            className={
              "content" +
              (this.props.ctAllIcons ? " all-icons" : "") +
              (this.props.ctFullWidth ? " content-full-width" : "") +
              (this.props.ctTextCenter ? " text-center" : "") +
              (this.props.tableFullWidth ? " table-full-width" : "")
            }
          >
            {this.props.content}
          </div>
          {this.props.stats !== undefined || this.props.legend !== undefined ? (
            <div
              className={
                "footer" + (this.props.ftTextCenter ? " text-center" : "")
              }
            >
              {this.props.legend !== undefined ? (
                <div className="legend">{this.props.legend}</div>
              ) : null}
              {this.props.stats !== undefined ? <hr /> : null}
              {this.props.stats !== undefined ? (
                <div className="stats">{this.props.stats}</div>
              ) : null}
            </div>
          ) : null}
        </div>
    );
  }
}

export default LoginCard;
