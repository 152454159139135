/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Instant Invoice (https://www.creative-tim.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
//import "assets/css/pe-icon-7-stroke.css";
import "assets/css/instantinvoice.css";

import AccountLayout from "layouts/Account.jsx";
import CreateLayout from "layouts/Create.jsx";
import StandaloneCreateLayout from "layouts/StandaloneCreate.jsx";
import CustomerLayout from "layouts/Customer.jsx";
import CustomerPortalLayout from "layouts/CustomerPortal.jsx";
import InvoiceLayout from "layouts/Invoice.jsx";
import NMILayout from "layouts/NMI.jsx";
import StandaloneLayout from "layouts/Standalone.jsx";
import AdminLayout from "layouts/Admin.jsx";
import ResellerLayout from "layouts/Reseller.jsx";
import QBFinancialLayout from "layouts/QBFinancial.jsx";
import QBPOSLayout from "layouts/QBPOS.jsx";
// import asyncComponent from './AsyncComponent.js'

const hist = createBrowserHistory();

// ReactPixel.init("111649226022273");
// ReactPixel.pageView();
// ReactPixel.fbq("track", "PageView");

// ReactGA.initialize("UA-46172202-11");
// ReactGA.set({ page: window.location.pathname + window.location.search });
// ReactGA.pageview(window.location.pathname + window.location.search);

// const Login = asyncComponent(() =>
//     import('views/Account/Login.jsx').then(module => module.default)
// )

// const Account = asyncComponent(() =>
//     import('layouts/Account.jsx').then(module => module.default)
// )

// const Admin = asyncComponent(() =>
//     import('layouts/Admin.jsx').then(module => module.default)
// )

// const Create = asyncComponent(() =>
//     import('layouts/Create.jsx').then(module => module.default)
// )

// const Customer = asyncComponent(() =>
//     import('layouts/Customer.jsx').then(module => module.default)
// )

// const Invoice = asyncComponent(() =>
//     import('layouts/Invoice.jsx').then(module => module.default)
// )

// const QBFinancial = asyncComponent(() =>
//     import('layouts/QBFinancial.jsx').then(module => module.default)
// )

hist.listen(location => {
  // ReactGA.set({ page: window.location.pathname + window.location.search });
  // ReactGA.pageview(window.location.pathname + window.location.search);

  // ReactPixel.pageView();
  // ReactPixel.fbq("track", "PageView");
});
ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/account" render={props => <AccountLayout {...props} />} />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/reseller" render={props => <ResellerLayout {...props} />} />
      <Route path="/create" render={props => <CreateLayout {...props} />} />
      <Route path="/standalonecreate" render={props => <StandaloneCreateLayout {...props} />} />      
      <Route path="/customer" render={props => <CustomerLayout {...props} />} />  
      <Route path="/customerportal" render={props => <CustomerPortalLayout {...props} />} />
      <Route path="/invoice" render={props => <InvoiceLayout {...props} />} />  
      <Route path="/nmi" render={props => <NMILayout {...props} />} />  
      <Route path="/standalone" render={props => <StandaloneLayout {...props} />} />
      <Route path="/qbfinancial" render={props => <QBFinancialLayout {...props} />} />     
      <Route path="/qbpos" render={props => <QBPOSLayout {...props} />} />            
      <Redirect from="/" to="/account/login" />
    </Switch>
    {/* <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/account" component={Account} />
      <Route path="/admin" component={Admin} />
      <Route path="/create" component={Create} />
      <Route path="/customer" component={Customer} />
      <Route path="/invoice" component={Invoice} />
      <Route path="/qbfinancial" component={QBFinancial} />
    </Switch> */}
  </HashRouter>,
  document.getElementById("root")
);
