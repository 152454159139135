import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Tab,
    Nav,
    NavItem,
    Alert,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js";
import cardconnect_logo from "assets/img/cardconnect_logo.png";
import nmi_logo from "assets/img/nmi_logo.png";
import Switch from "react-bootstrap-switch";
import SweetAlert from "react-bootstrap-sweetalert";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import NetworkService from "services/NetworkService";
import Common from "utils/Common.js";


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AuthorizeNetState: false,
            CardConnectState: false,
            NMIState: false,
            AuthorizeNetAPILoginID: "",
            AuthorizeNetTransactionKey: "",
            AuthorizeNetClientKey: "",
            CardConnectMerchantID: "",
            CardConnectUserName: "",
            CardConnectPassword: "",
            NMISecurityKey: "",
            NMITokenizationKey: "",
            NMIUserName: "",
            NMIPassword: "",
            alert: null,
            FirstNameState: "",
            LastNameState: "",
            EmailState: "",
            websiteState: "",
            companynameState: "",
            PhoneNumberState: "",
            FirstNameErrorMessage: "",
            LastNameErrorMessage: "",
            EmailErrorMessage: "",
            PhoneNumberErrorMessage: "",
            isFormValid: false,
            Profile: {
                Company: {
                    Name: "",
                    Phone: "",
                    Address: "",
                    Country: "",
                    City: "",
                    State: "",
                    PostalCode: "",
                    WebSite: "",
                    Fax: "",
                },
                User: {
                    Email: "",
                    FirstName: "",
                    LastName: "",
                }
            },
            Processor: {
                MethodID: "",
                Configuration: {},
                IsDefault: false,
                IsStatus: false,
                IsVoidRefund: false,
                IsBankAccount: false,
                Logo: null,
                MethodName: "",
                MethodType: "",
                Details: "",
                AuthorizeNet: {
                    ClientKey: "",
                    APILoginID: "",
                    TransactionKey: "",

                },
                CardConnect: {
                    MerchantID: "",
                    UserName: "",
                    Password: "",
                },
                NMI: {
                    SecurityKey: "",
                    TokenizationKey: "",
                    UserName: "",
                    Password: "",
                },
                USAePay: {},
                WePay: {},
            },
            ProcessorList: [],
            currentPassword: "",
            currentPasswordState: "",
            currentPasswordErrorMessage: "",
            newPassword: "",
            newPasswordState: "",
            newPasswordErrorMessage: "",
            confirmNewPassword: "",
            confirmNewPasswordState: "",
            confirmNewPasswordErrorMessage: "",
            errorMessage: "",
            errorCode: 1,
            isPasswordFormValid: false,
            ShowProgressBar: false,
            nmiSecurityKeyError: "",
            nmiTokenizationKeyError: "",
            nmiUsernameError: "",
            nmiPasswordError: ""
        };
    };


    toggleProcessorState = (processorName, isChecked) => {
        if (processorName === "authorizenet") {
            this.setState({ AuthorizeNetState: isChecked });
            this.setState({ NMIState: !isChecked });
            this.setState({ CardConnectState: !isChecked });
        }
        else if (processorName === "cardconnect") {
            this.setState({ CardConnectState: isChecked });
            this.setState({ AuthorizeNetState: !isChecked });
            this.setState({ NMIState: !isChecked });
        }
        else if (processorName === "nmi") {
            this.setState({ NMIState: isChecked });
            this.setState({ CardConnectState: !isChecked });
            this.setState({ AuthorizeNetState: !isChecked });
        }
    };

    successAlert(msg) {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    title={msg}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >

                </SweetAlert>
            )
        });
    }

    hideAlert() {
        console.log('Hiding alert...');
        this.setState({
            alert: null
        });
    }

    isValidated(processorName) {
        let isValid = true;
        switch (processorName) {
            case "authorizenet":
                break;
            case "cardconnect":
                break;
            case "nmi":
                if (this.state.Processor.NMI.SecurityKey === "") {
                    this.setState({
                        nmiSecurityKeyError: (
                            <small className="text-danger">
                                NMI Security Key is required.
                            </small>
                        )
                    });
                    isValid = false;
                };
                if (this.state.Processor.NMI.TokenizationKey === "") {
                    this.setState({
                        nmiTokenizationKeyError: (
                            <small className="text-danger">
                                NMI Tokenization Key is required.
                            </small>
                        )
                    });
                    isValid = false;
                }
                if (this.state.Processor.NMI.UserName === "") {
                    this.setState({
                        nmiUsernameError: (
                            <small className="text-danger">
                                NMI User Name is required.
                            </small>
                        )
                    });
                    isValid = false;
                };
                if (this.state.Processor.NMI.Password === "") {
                    this.setState({
                        nmiPasswordError: (
                            <small className="text-danger">
                                NMI Password is required.
                            </small>
                        )
                    });
                    isValid = false;
                };
                break;
            default:
                break;
        }
        return isValid;
    };

    saveChangePassword() {
        if (this.ValidatePasswords()) {
            const password_info = {
                current_password: this.state.currentPassword,
                new_password: this.state.newPassword,
                confirm_password: this.state.confirmNewPassword
            };

            this.setState({ ShowProgressBar: true });
            NetworkService.postChangePasswordRequest(password_info)
                .then(data => {
                    if (data != null) {
                        this.setState({ ShowProgressBar: false });
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                        if (data.statuscode === 0) {
                        }
                        else {

                        }
                    }
                })
                .catch(error => {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                })
        }
    }

    saveSettings = (processorName) => {
        if (this.isValidated(processorName)) {
            if (this.state.ProcessorList.length > 0) {
                this.state.ProcessorList.forEach(element => {
                    // element.is_default = false;
                    let configuration = {};
                    switch (processorName) {
                        case "authorizenet":
                            configuration = {
                                "LoginKey": this.state.Processor.AuthorizeNet.APILoginID,
                                "TransactionKey": this.state.Processor.AuthorizeNet.TransactionKey,
                                "ClientKey": this.state.Processor.AuthorizeNet.ClientKey,
                                SupportedACH: this.state.Processor.IsBankAccount,
                                "supported_methods": {
                                    "ach": true
                                }
                            }
                            break;
                        case "cardconnect":
                            configuration = {
                                UserName: this.state.Processor.CardConnect.UserName,
                                MerchantID: this.state.Processor.CardConnect.MerchantID,
                                Password: this.state.Processor.CardConnect.Password,
                                URL: "https://fts.prinpay.com:8443/cardconnect/rest/",
                                SupportedACH: this.state.Processor.IsBankAccount,
                                supported_methods: {
                                    ach: this.state.Processor.IsBankAccount
                                },
                                Hardware: {
                                    bolt: {
                                        auth: "JBcUkMQErjB3/1jZnJ5yhFu/rEgsVr0nb58f0Pwbrdo=",
                                        merchant_id: "800000000027",
                                        terminals: [
                                        ]
                                    }
                                }
                            }
                            break;
                        case "nmi":
                            configuration = {
                                SecurityKey: this.state.Processor.NMI.SecurityKey,
                                TokenizationKey: this.state.Processor.NMI.TokenizationKey,
                                UserName: this.state.Processor.NMI.UserName,
                                Password: this.state.Processor.NMI.Password,
                                SupportedACH: this.state.Processor.IsBankAccount,
                                supported_methods: {
                                    ach: this.state.Processor.IsBankAccount
                                },
                            }
                            break;
                        default:
                            break;
                    }

                    if (processorName === element.MethodType) {
                        const payload = {
                            "is_default": true,
                            "configuration": configuration,
                            "method_id": element.MethodID,
                            "type": element.MethodType
                        }
                        // API  calling
                        this.postPaymentProcessor(payload);
                    }
                    //this.setState({element.is_default : false});
                });
            }
            else {
                let configuration = {};
                let paymentMethodID = 0;
                switch (processorName) {
                    case "authorizenet":
                        configuration = {
                            "LoginKey": this.state.Processor.AuthorizeNet.APILoginID,
                            "TransactionKey": this.state.Processor.AuthorizeNet.TransactionKey,
                            "ClientKey": this.state.Processor.AuthorizeNet.ClientKey,
                            "supported_methods": {
                                "ach": true
                            }
                        }
                        paymentMethodID = 3;
                        break;
                    case "cardconnect":
                        configuration = {
                            UserName: this.state.Processor.CardConnect.UserName,
                            MerchantID: this.state.Processor.CardConnect.MerchantID,
                            Password: this.state.Processor.CardConnect.Password,
                            URL: "https://fts.prinpay.com:8443/cardconnect/rest/",
                            Hardware: {
                                bolt: {
                                    auth: "JBcUkMQErjB3/1jZnJ5yhFu/rEgsVr0nb58f0Pwbrdo=",
                                    merchant_id: "800000000027",
                                    terminals: [

                                    ]
                                }
                            },
                            supported_methods: {
                                ach: true
                            }
                        }
                        paymentMethodID = 1;
                        break;
                    case "nmi":
                        paymentMethodID = 2;
                        configuration = {
                            SecurityKey: this.state.Processor.NMI.SecurityKey,
                            TokenizationKey: this.state.Processor.NMI.TokenizationKey,
                            UserName: this.state.Processor.NMI.UserName,
                            Password: this.state.Processor.NMI.Password,
                            supported_methods: {
                                ach: true
                            }
                        }
                        break;
                    default:
                        break;
                }

                const payload = {
                    "is_default": true,
                    "configuration": configuration,
                    "method_id": paymentMethodID,
                    "type": processorName
                }

                // API  calling
                this.postPaymentProcessor(payload);
            }
        }
    };

    handleCancelSettings(e) {
        this.props.history.push('/account/home');
        e.preventDefault();
    }

    // Handler Profile save
    handlerProfileSave = (profileInfo) => {
        this.postProfileRequest(profileInfo);
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }

    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }

    ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                EmailErrorMessage: (
                    <small className="text-danger">
                        Invalid Email Address.
                    </small>
                )
            });
            this.setState({
                EmailState: "error"
            });
        }
        else {
            this.setState({ EmailState: "valid" });
            this.setState({ EmailErrorMessage: null });
        }

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    ValidatePasswords() {        
        if (this.state.currentPasswordState === "" || this.state.currentPasswordState === "error") {
            this.setState({
                currentPasswordErrorMessage: (
                    <small className="text-danger">
                        Current password is required.
                    </small>
                )
            });
            this.setState({ isPasswordFormValid: false });
        }
        else {
            this.setState({ currentPasswordErrorMessage: null });
            this.setState({ isPasswordFormValid: true });
        }
        Common.ValidatePasswords(this);   
    }

    isProfileValidated() {
        if (this.state.Profile.User.FirstName === "" || this.state.Profile.User.FirstName === "error") {
            this.setState({
                FirstNameErrorMessage: (
                    <small className="text-danger">
                        First Name is Required.
                    </small>
                )
            });
            this.setState({ FirstNameState: "error" });
            this.state.isFormValid = false;
        }
        else {
            this.setState({ FirstNameErrorMessage: null });
            this.state.isFormValid = true;
        }

        if (this.state.Profile.User.LastName === "" || this.state.Profile.User.LastName === "error") {

            this.setState({
                LastNameErrorMessage: (
                    <small className="text-danger">
                        Last Name is Required.
                    </small>
                )
            });

            this.setState({ LastNameState: "error" });
            this.state.isFormValid = false;
        }
        else {
            this.setState({ LastNameErrorMessage: null });
            this.state.isFormValid = true;
        }

        if (this.state.Profile.User.Email === "" || this.state.Profile.User.Email === "error") {
            if (this.state.Profile.User.Email != "") {
                this.setState({
                    EmailErrorMessage: (
                        <small className="text-danger">
                            Invalid Email Address.
                        </small>
                    )
                });
            }
            else {
                this.setState({
                    EmailErrorMessage: (
                        <small className="text-danger">
                            Email is Required.
                        </small>
                    )
                });
            }
            this.state.isFormValid = false;
        }
        else {
            this.setState({ EmailErrorMessage: null });
            this.state.isFormValid = true;
        }


        if (this.state.Profile.Company.Phone === "" || this.state.Profile.Company.Phone === "undefine") {
            this.setState({
                PhoneNumberErrorMessage: (
                    <small className="text-danger">
                        Phone Number is Required.
                    </small>
                )
            });
            this.setState({ PhoneNumberState: "error" });
            this.state.isFormValid = false;
        }
        else {
            this.setState({ PhoneNumberErrorMessage: null });
            this.state.isFormValid = true;
        }

        return this.state.isFormValid;

    }

    selectCountry(val) {

        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Country: val
                    // Country: {
                    //    ...prevState.Profile.Company.Country,
                    //    flag: false
                    // }
                }
            }
        }))
    }

    AddressChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Address: val
                }
            }
        }))
    }

    CityChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    City: val
                }
            }
        }))
    }

    PostalCodeChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    PostalCode: val
                }
            }
        }))
    }


    selectRegion(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    State: val
                    // Country: {
                    //    ...prevState.Profile.Company.Country,
                    //    flag: false
                    // }
                }
            }
        }))
        //this.setState({ state: val });
    }

    setResult = (result) => {

        let tokenize_key = null;
        if (result != null) {
            if (result.ProcessorType == "nmi") {
                tokenize_key = JSON.stringify({ "PPType": result.ProcessorType, "PPToken": result.ProcessorConfig.TokenizationKey })

            } else if (result.ProcessorType == "cardconnect") {

            }
            localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);
        }
    }
    /**
     * API Request Calling
     */

    // POST request update payment processor configutaion to server
    postPaymentProcessor = (processor) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/paymentmethods/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token
            },
            body: JSON.stringify({
                is_default: processor.is_default,
                configuration: processor.configuration,
                method_id: processor.method_id,
                type: processor.type
            })
        }).then(response => {
            return response.json()
        }).then(data => {
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                this.setState({ ShowProgressBar: false });

                this.setResult(data.data);

            } else {
                this.setState({ errorMessage: "Service not available" });
                this.setState({ ShowProgressBar: false });
            }
        }).catch(error => {
            // this.successAlert.bind(this);
            console.log(error);
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    };

    // GET request to fetch payment processor settings
    getPaymentProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/paymentmethods/"
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "applicaiton/json",
                "Authorization": access_token
            }
        }).then(response => {
            return response.json()
        }).then(data => {
            if (data != null) {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: data.statuscode });
                if (data.statuscode === 0) {
                    let response = data.data;
                    this._load_processor_settings(response);
                }
                else {
                    this.setState({ errorMessage: data.statusmessage });
                }
            } else {
                this.setState({ errorMessage: "Service not available" });
                this.setState({ ShowProgressBar: false });
            }
        }).catch(error => {
            // Show any excepton occur
            console.log(error);
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }
    // Map processor settings in payment processor object
    _load_processor_settings(settings) {
        let processor_list = []
        for (let item of settings.items) {
            const processor = { ...this.state.Processor };
            processor.MethodID = item.id
            processor.Configuration = item.configuration
            processor.IsDefault = item.is_default
            processor.IsStatus = item.is_status
            processor.IsVoidRefund = item.is_voidrefund
            processor.IsBankAccount = item.is_bankaccount
            processor.Logo = item.logo
            processor.MethodName = item.method_name
            processor.MethodType = item.method_type
            processor.Details = item.details
            let processorConfiguration = {}
            try {
                processorConfiguration = processor.Configuration ? processor.Configuration : {};
            } catch (error) {
                console.log(error)
            }
            if (item.method_type === "authorizenet") {
                this.state.AuthorizeNetState = item.is_default;
                this.state.Processor.AuthorizeNet.APILoginID = processorConfiguration.LoginKey || '';
                this.state.Processor.AuthorizeNet.ClientKey = processorConfiguration.ClientKey || '';
                this.state.Processor.AuthorizeNet.TransactionKey = processorConfiguration.TransactionKey || '';
                this.state.Processor.IsBankAccount = processorConfiguration.SupportedACH || false;
            }
            else if (item.method_type === "cardconnect") {
                this.state.CardConnectState = item.is_default;
                this.state.Processor.CardConnect.MerchantID = processorConfiguration.MerchantID || '';
                this.state.Processor.CardConnect.UserName = processorConfiguration.UserName || '';
                this.state.Processor.CardConnect.Password = processorConfiguration.Password || '';
                this.state.Processor.IsBankAccount = processorConfiguration.SupportedACH || false;

            }
            else if (item.method_type === "nmi") {
                this.state.NMIState = item.is_default;
                this.state.Processor.NMI.SecurityKey = processorConfiguration.SecurityKey || '';
                this.state.Processor.NMI.TokenizationKey = processorConfiguration.TokenizationKey || '';
                this.state.Processor.NMI.UserName = processorConfiguration.UserName || '';
                this.state.Processor.NMI.Password = processorConfiguration.Password || '';
                this.state.Processor.IsBankAccount = processorConfiguration.SupportedACH || false;
            }

            processor_list.push(processor)
        }
        this.setState({ ProcessorList: processor_list })
        // return processor_list;
    }

    // GET request to fetch merchant profile
    getProfileRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/merchant/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token
            },
        }).then(response => {
            return response.json()
        }).then(data => {
            if (data != null) {

                this.setState({ ShowProgressBar: false });
                if (data.statuscode === 0) {

                    const companyData = data.data.company;
                    const userData = data.data.user;

                    const company = { ...this.state.Profile.Company };
                    company.Name = companyData.name;
                    company.Phone = companyData.phone_number;
                    company.Address = companyData.address;
                    company.Country = companyData.country;
                    company.City = companyData.city;
                    company.State = companyData.state;
                    company.PostalCode = companyData.postal_code;
                    company.Fax = companyData.fax;
                    company.WebSite = companyData.website;

                    const user = { ...this.state.Profile.User }
                    user.Email = userData.email;
                    user.FirstName = userData.first_name;
                    user.LastName = userData.last_name;

                    this.setState({ Profile: { Company: company, User: user } });
                }
                else {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                }
            }
        }).catch(error => {
            // this.successAlert.bind(this);
            console.log(error);
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    // POST request for profile update
    postProfileRequest = (profileInfo) => {
        if (this.isProfileValidated()) {
            this.setState({ ShowProgressBar: true });
            this.setState({ errorMessage: "" });
            const auth_token = localStorage.getItem(StorageEnum.UUID);
            const url = BASE_URL + "api/v1/merchant/";

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + auth_token
                },
                body: JSON.stringify({
                    "name": profileInfo.Company.Name,
                    "phone": profileInfo.Company.Phone,
                    "fax": profileInfo.Company.Fax,
                    "website": profileInfo.Company.WebSite,
                    "address": profileInfo.Company.Address,
                    "city": profileInfo.Company.City,
                    "state": profileInfo.Company.State,
                    "country": profileInfo.Company.Country,
                    "postalcode": profileInfo.Company.PostalCode,
                    "first_name": profileInfo.User.FirstName,
                    "last_name": profileInfo.User.LastName,
                })
            }).then(response => {
                return response.json()
            }).then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode === 0) {
                        console.log(data.data);
                        this.setState({ errorMessage: "Profile saved successfully." });
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                console.log(error)
            });
        }

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) === null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        // call get profile api
        this.getProfileRequest();

        // call get payment processor api
        this.getPaymentProcessorRequest();
    }

    /**
     * Render view
     */
    render() {
        const nmiLogoClass =
        {
            width: "370px",
            height: "80px",
            marginLeft: "-10px"
        };
        const cardconnectLogoClass =
        {
            marginTop: "10px",
            width: "300px",
            height: "78px",
            marginLeft: "30px"
        };


        return (
            <Row>
                <Col md={8} mdOffset={2}>
                    <div className="ii-main-content">
                        <Card
                            ctFullWidth
                            content={
                                <Tab.Container id="nav-with-icons" defaultActiveKey="profliekey">
                                    <Row className="clearfix">
                                        <Col sm={12}>
                                            <Nav bsStyle="tabs">
                                                <NavItem eventKey="profliekey">
                                                    <i className="fa fa-user" /> Profile
                                                </NavItem>
                                                 {false &&
                                                    <NavItem eventKey="paymentprocessor">
                                                        <i className="fa fa-cogs" /> Payment Processor
                                                    </NavItem>
                                                 }
                                                <NavItem eventKey="changepassword">
                                                    <i className="fa fa-lock" /> Change Password
                                                </NavItem>
                                                {/* <NavItem eventKey="conveniencefee">
                                            <i className="fa fa-file-text-o" /> Convenience Fee
                            </NavItem> */}
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content animation>
                                                <Tab.Pane eventKey="profliekey">
                                                    <Row>
                                                        <Col md={12}>
                                                            {this.state.errorMessage != "" &&
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Alert bsStyle={this.state.errorCode === 0 ? "success" : "danger"}>
                                                                            <span>
                                                                                {this.state.errorMessage}
                                                                            </span>
                                                                        </Alert>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {this.state.ShowProgressBar &&
                                                                <Row >
                                                                    <Col md={12}>
                                                                        <CustomProgressBar></CustomProgressBar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <br />
                                                            <Card
                                                                content={
                                                                    <div>
                                                                        <Row>

                                                                            <Col md={12}>
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                                First Name: <span className="star">*</span>
                                                                                            </div>
                                                                                            <FormControl
                                                                                                type="text"
                                                                                                placeholder="First Name"
                                                                                                name="FirstName"
                                                                                                className={this.state.FirstNameState}
                                                                                                value={this.state.Profile.User.FirstName}
                                                                                                onChange={event => {
                                                                                                    //  this.setState({ User: { FirstName: event.target.value } });
                                                                                                    this.state.Profile.User.FirstName = event.target.value;
                                                                                                    this.change(event, "FirstName", "length", 2)
                                                                                                }
                                                                                                }
                                                                                            //  onBlur={e => this.isProfileValidated()}
                                                                                            />
                                                                                            {this.state.FirstNameErrorMessage}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Last Name: <span className="star">*</span>
                                                                                            </ControlLabel>
                                                                                            <FormControl
                                                                                                type="text"
                                                                                                placeholder="Last Name"
                                                                                                autoComplete="off"
                                                                                                name="LastName"
                                                                                                className={this.state.LastNameState}
                                                                                                value={this.state.Profile.User.LastName}
                                                                                                onChange={event => {
                                                                                                    this.state.Profile.User.LastName = event.target.value;
                                                                                                    this.change(event, "LastName", "length", 2)
                                                                                                }
                                                                                                }
                                                                                            //  onBlur={e => this.isProfileValidated()}
                                                                                            />
                                                                                            {this.state.LastNameErrorMessage}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Company: <span className="star"></span>
                                                                                            </ControlLabel>
                                                                                            <FormControl
                                                                                                type="text"
                                                                                                disabled={false}
                                                                                                placeholder="Company"
                                                                                                name="companyname"
                                                                                                className={this.state.companynameState}
                                                                                                value={this.state.Profile.Company.Name}
                                                                                                onChange={event =>
                                                                                                    {
                                                                                                        const val = event.target.value;
                                                                                                        this.setState(prevState => ({
                                                                                                            ...prevState,
                                                                                                            Profile: {
                                                                                                                ...prevState.Profile,
                                                                                                                Company: {
                                                                                                                    ...prevState.Profile.Company,
                                                                                                                    Name: val
                                                                                                                }
                                                                                                            }
                                                                                                        }))
                                                                                                    }                                                                                                 
                                                                                                }
                                                                                            />
                                                                                            {this.state.companynameErrorMessage}
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Email adress: <span className="star">*</span>
                                                                                            </ControlLabel>
                                                                                            <FormControl
                                                                                                type="email"
                                                                                                disabled={true}
                                                                                                placeholder="Enter Email"
                                                                                                name="Email"
                                                                                                className={this.state.EmailState}
                                                                                                value={this.state.Profile.User.Email}
                                                                                                onChange={event => {
                                                                                                    this.ValidateEmailAddress(event);
                                                                                                    this.state.Profile.User.Email = event.target.value;
                                                                                                    this.change(event, "Email", "email", 2)
                                                                                                }

                                                                                                }
                                                                                            // onBlur={e => this.isProfileValidated()}
                                                                                            />
                                                                                            {this.state.EmailErrorMessage}

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Phone Number: <span className="star"></span>
                                                                                            </ControlLabel>
                                                                                            <FormControl
                                                                                                type="number"
                                                                                                placeholder="Phone Number"
                                                                                                className={this.state.PhoneNumberState}
                                                                                                name="Phone"
                                                                                                value={this.state.Profile.Company.Phone}
                                                                                                onChange={event => {
                                                                                                    this.state.Profile.Company.Phone = event.target.value;
                                                                                                    this.change(event, "PhoneNumber", "length", 7);
                                                                                                }
                                                                                                }
                                                                                                //   onBlur={e => this.isValidated()}
                                                                                                type="number"
                                                                                            />
                                                                                            {this.state.PhoneNumberErrorMessage}

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Website:
                                                                      </ControlLabel>
                                                                                            <FormControl
                                                                                                type="text"
                                                                                                placeholder="Website"
                                                                                                name="Website"
                                                                                                value={this.state.Profile.Company.WebSite}
                                                                                                onChange={event =>
                                                                                                    {
                                                                                                        const val = event.target.value;
                                                                                                        this.setState(prevState => ({
                                                                                                            ...prevState,
                                                                                                            Profile: {
                                                                                                                ...prevState.Profile,
                                                                                                                Company: {
                                                                                                                    ...prevState.Profile.Company,
                                                                                                                    WebSite: val
                                                                                                                }
                                                                                                            }
                                                                                                        }))
                                                                                                    }
                                                                                                    // this.setState({ Company: { Website: event.target.value } })

                                                                                                }
                                                                                            // onBlur={e => this.isValidated()}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Address:
                                                                      </ControlLabel>
                                                                                            <FormControl
                                                                                                type="text"
                                                                                                placeholder="Address"
                                                                                                name="Address"
                                                                                                value={this.state.Profile.Company.Address}
                                                                                                onChange={event => this.AddressChange(event.target.value)}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Country:
                                                                      </ControlLabel>
                                                                                            <CountryDropdown
                                                                                                value={this.state.Profile.Company.Country}
                                                                                                id="country"
                                                                                                onChange={(val) => this.selectCountry(val)}
                                                                                                classes="form-control"
                                                                                                valueType="short"
                                                                                                whitelist={['GB', 'US', 'CA']}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                State/Region:
                                                                      </ControlLabel>
                                                                                            <RegionDropdown
                                                                                                country={this.state.Profile.Company.Country}
                                                                                                value={this.state.Profile.Company.State}
                                                                                                defaultOptionLabel="Select State"
                                                                                                countryValueType="short"
                                                                                                id="region"
                                                                                                //labelType="short"
                                                                                                onChange={(val) => this.selectRegion(val)}
                                                                                                classes="form-control"
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                City:
                                                                      </ControlLabel>
                                                                                            <FormControl
                                                                                                type="text"
                                                                                                placeholder="City"
                                                                                                name="City"
                                                                                                value={this.state.Profile.Company.City}
                                                                                                onChange={event => this.CityChange(event.target.value)}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <ControlLabel>
                                                                                                Postal Code: <span className="star"></span>
                                                                                            </ControlLabel>
                                                                                            <FormControl
                                                                                                type="number"
                                                                                                placeholder="Postal Code"
                                                                                                name="postalcode"
                                                                                                value={this.state.Profile.Company.PostalCode}
                                                                                                onChange={event => this.PostalCodeChange(event.target.value)}
                                                                                                type="number"
                                                                                            />

                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <div style={{ textAlign: "right" }}>
                                                                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelSettings(e)}>Cancel</Button>
                                                                                    <Button bsStyle="primary" fill wd onClick={e => this.handlerProfileSave(this.state.Profile)}>Save Changes</Button>
                                                                                </div>
                                                                            </Col>
                                                                            {/* <LoopCircleLoading /> */}
                                                                        </Row>
                                                                    </div>
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Tab.Pane>
                                                {false &&
                                                <Tab.Pane eventKey="paymentprocessor">
                                                    <Card
                                                        plain
                                                        content={
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    {this.state.errorMessage != "" &&
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <Alert bsStyle={this.state.errorCode === 0 ? "success" : "danger"}>
                                                                                    <span>
                                                                                        {this.state.errorMessage}
                                                                                    </span>
                                                                                </Alert>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    {this.state.ShowProgressBar &&
                                                                        <Row >
                                                                            <Col md={12}>
                                                                                <CustomProgressBar></CustomProgressBar>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    <br />

                                                                    <Row>
                                                                        {/** * NMI Form **/}
                                                                        {this.state.NMIState ?
                                                                            <Col md={6} >
                                                                                <Card
                                                                                    pink
                                                                                    content={
                                                                                        <div >
                                                                                            <div style={{ textAlign: "center" }}>
                                                                                                <img src={nmi_logo} style={nmiLogoClass} />
                                                                                            </div>
                                                                                            <br />
                                                                                            <form>
                                                                                                <FormGroup>
                                                                                                    <ControlLabel>Security Key
                                                                                                        <OverlayTrigger placement="right" overlay={<Tooltip style={{ fontSize: 12 }} id="terms_tooltip">Private Security Key: Private key used with Direct Post, Three Step Redirect and Query APIs</Tooltip>}>
                                                                                                            <i className="fa fa-question-circle"> </i>
                                                                                                        </OverlayTrigger>
                                                                                                        <span className="star"> *</span>
                                                                                                    </ControlLabel>
                                                                                                    <FormControl
                                                                                                        value={this.state.Processor.NMI.SecurityKey}
                                                                                                        placeholder="Security Key"
                                                                                                        className={this.state.securityKeyClassName}
                                                                                                        disabled={true} //{!this.state.NMIState}
                                                                                                        onChange={event => {
                                                                                                            this.state.Processor.NMI.SecurityKey = event.target.value;
                                                                                                            //this.setState({securityKeyClassName: ""});
                                                                                                            this.setState({ nmiSecurityKeyError: "" });
                                                                                                            const nmiModel = { ...this.state.Processor.NMI };
                                                                                                            nmiModel.SecurityKey = event.target.value;
                                                                                                            this.setState({ ...this.state.Processor.NMI, nmiModel })
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                    {this.state.nmiSecurityKeyError}
                                                                                                </FormGroup>
                                                                                                <FormGroup>
                                                                                                    <ControlLabel>Tokenization Key
                                                                                                        <OverlayTrigger placement="right" overlay={<Tooltip style={{ fontSize: 12 }} id="terms_tooltip">Publice Security Key: Publice key used with Collect.js </Tooltip>}>
                                                                                                            <i className="fa fa-question-circle"> </i>
                                                                                                        </OverlayTrigger>
                                                                                                        <span className="star"> *</span>
                                                                                                    </ControlLabel>
                                                                                                    <FormControl
                                                                                                        value={this.state.Processor.NMI.TokenizationKey}
                                                                                                        placeholder="Tokenization Key"
                                                                                                        className={this.state.tokenizationKeyClassName}
                                                                                                        disabled={true} //{!this.state.NMIState}
                                                                                                        onChange={event => {
                                                                                                            this.state.Processor.NMI.TokenizationKey = event.target.value;
                                                                                                            this.setState({ nmiTokenizationKeyError: "" });
                                                                                                            const nmiModel = { ...this.state.Processor.NMI };
                                                                                                            nmiModel.TokenizationKey = event.target.value;
                                                                                                            this.setState({ ...this.state.Processor.NMI, nmiModel })
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                    {this.state.nmiTokenizationKeyError}
                                                                                                </FormGroup>
                                                                                                <FormGroup>
                                                                                                    <ControlLabel>User Name  <span className="star">*</span></ControlLabel>
                                                                                                    <FormControl
                                                                                                        value={this.state.Processor.NMI.UserName}
                                                                                                        placeholder="User Name"
                                                                                                        autoComplete="off"
                                                                                                        className={this.state.userNameClassName}
                                                                                                        disabled={true} //{!this.state.NMIState}
                                                                                                        onChange={event => {
                                                                                                            this.state.Processor.NMI.UserName = event.target.value;
                                                                                                            //this.setState({userNameClassName: ""});
                                                                                                            this.setState({ nmiUsernameError: "" });
                                                                                                            const nmiModel = { ...this.state.Processor.NMI }
                                                                                                            nmiModel.UserName = event.target.value;
                                                                                                            this.setState({ ...this.state.Processor.NMI, nmiModel })
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                    {this.state.nmiUsernameError}
                                                                                                </FormGroup>
                                                                                                <FormGroup>
                                                                                                    <ControlLabel>Password  <span className="star">*</span></ControlLabel>
                                                                                                    <FormControl
                                                                                                        value={this.state.Processor.NMI.Password}
                                                                                                        placeholder="Password"
                                                                                                        autoComplete="off"
                                                                                                        className={this.state.passwordClassName}
                                                                                                        disabled={true} //{!this.state.NMIState}
                                                                                                        //  type="password"
                                                                                                        onChange={event => {
                                                                                                            this.state.Processor.NMI.Password = event.target.value;
                                                                                                            //this.setState({passwordClassName: ""});
                                                                                                            this.setState({ nmiPasswordError: "" });
                                                                                                            const nmiModel = { ...this.state.Processor.NMI }
                                                                                                            nmiModel.Password = event.target.value;
                                                                                                            this.setState({ ...this.state.Processor.NMI, nmiModel })
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                    {this.state.nmiPasswordError}
                                                                                                </FormGroup>
                                                                                                {/* <div className={classes.block}> */}
                                                                                                <br />
                                                                                                {/* className={classes.labelMarginRight} */}

                                                                                                {/* <DropdownButton title="..." key="1" id={`dropdown-basic-1`} noCaret>

                                                                                                <MenuItem eventKey="1">
                                                                                                    <label style={{ textAlign: "left", marginRight: "20px" }}>ACH</label>
                                                                                                    <Switch
                                                                                                        defaultValue={this.state.Processor.IsBankAccount}
                                                                                                        onChange={event => {
                                                                                                            this.state.Processor.IsBankAccount = event.state.value;
                                                                                                            const processorModel = { ...this.state.Processor }
                                                                                                            processorModel.IsBankAccount = event.state.value;
                                                                                                            this.setState({ ...this.state.Processor.IsBankAccount, processorModel })
                                                                                                        }}
                                                                                                    />
                                                                                                </MenuItem>
                                                                                                <MenuItem eventKey="2">
                                                                                                    <label style={{ textAlign: "left", marginRight: "20px" }}>Use as Default</label>
                                                                                                    <Switch
                                                                                                        defaultValue={this.state.NMIState}
                                                                                                        value={this.state.NMIState}
                                                                                                        onChange={event => this.toggleProcessorState("nmi", event.state.value)}
                                                                                                    />
                                                                                                </MenuItem>
                                                                                            </DropdownButton> */}

                                                                                                <br />
                                                                                                <div style={{ textAlign: "right" }}>
                                                                                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelSettings(e)}>Cancel</Button>
                                                                                                    {/* <Button bsStyle="primary" disabled={!this.state.NMIState} fill wd onClick={e => this.saveSettings("nmi")}>Save Changes</Button> */}
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                            </Col>

                                                                            : ""}
                                                                        {/** * Card Connect Form *  */}
                                                                        {this.state.CardConnectState ?
                                                                            <Col md={6}>
                                                                                <Card
                                                                                    content={
                                                                                        <div >
                                                                                            <div style={{ textAlign: "center" }}>
                                                                                                <img src={cardconnect_logo} style={cardconnectLogoClass} />
                                                                                            </div>

                                                                                            <form>
                                                                                                <FormGroup>
                                                                                                    <ControlLabel>Merchant ID <span className="star">*</span></ControlLabel>
                                                                                                    <FormControl
                                                                                                        // value={this.state.Processor.CardConnect.MerchantID}
                                                                                                        placeholder="Merchant ID"
                                                                                                        className={this.state.merchantIDClassName}
                                                                                                        disabled={true}//{!this.state.CardConnectState}
                                                                                                        value={this.state.Processor.CardConnect.MerchantID}
                                                                                                        onChange={event => {
                                                                                                            this.change(event, "CardConnectMerchantID", "length", 5);
                                                                                                            this.state.Processor.CardConnect.MerchantID = event.target.value;
                                                                                                            const cardconnectModel = { ...this.state.Processor.CardConnect }
                                                                                                            cardconnectModel.MerchantID = event.target.value;
                                                                                                            this.setState({ ...this.state.Processor.CardConnect, cardconnectModel })
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                    {/* {this.state.emailErrorLogin} */}
                                                                                                </FormGroup>
                                                                                                <FormGroup>
                                                                                                    <ControlLabel>User Name <span className="star">*</span></ControlLabel>
                                                                                                    <FormControl
                                                                                                        //  value={this.state.Processor.CardConnect.UserName}
                                                                                                        placeholder="User Name"
                                                                                                        className={this.state.userNameClassName}
                                                                                                        disabled={true}//{!this.state.CardConnectState}
                                                                                                        value={this.state.Processor.CardConnect.UserName}
                                                                                                        onChange={event => {
                                                                                                            this.change(event, "CardConnectMerchantID", "length", 5);
                                                                                                            this.state.Processor.CardConnect.UserName = event.target.value;
                                                                                                            const cardconnectModel = { ...this.state.Processor.CardConnect }
                                                                                                            cardconnectModel.UserName = event.target.value;
                                                                                                            this.setState({ ...this.state.Processor.CardConnect, cardconnectModel })
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                    {/* {this.state.emailErrorLogin} */}
                                                                                                </FormGroup>
                                                                                                <FormGroup>
                                                                                                    <ControlLabel>Password  <span className="star">*</span></ControlLabel>
                                                                                                    <FormControl
                                                                                                        //value={this.state.Processor.CardConnect.Password}
                                                                                                        placeholder="Password"
                                                                                                        autoComplete="off"
                                                                                                        id="CardConnectPassword"
                                                                                                        className={this.state.passwordClassName}
                                                                                                        disabled={true}//{!this.state.CardConnectState}
                                                                                                        value={this.state.Processor.CardConnect.Password}
                                                                                                        //type="password"
                                                                                                        onChange={event => {
                                                                                                            this.change(event, "CardConnectUserName", "length", 6);
                                                                                                            this.state.Processor.CardConnect.Password = event.target.value;
                                                                                                            const cardconnectModel = { ...this.state.Processor.CardConnect }
                                                                                                            cardconnectModel.Password = event.target.value;
                                                                                                            this.setState({ ...this.state.Processor.CardConnect, cardconnectModel })
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                    {/* {this.state.passwordErrorLogin} */}
                                                                                                </FormGroup>
                                                                                                {/* className={classes.block} */}

                                                                                                <div>
                                                                                                    {/* <br />
                                                                                                    <label style={{ textAlign: "left", marginRight: "20px" }}>Use as Default</label>
                                                                                                    <Switch onText="" offText=""
                                                                                                        defaultValue={this.state.CardConnectState}
                                                                                                        value={this.state.CardConnectState}
                                                                                                        onChange={event => this.toggleProcessorState("cardconnect", event.state.value)}
                                                                                                    /> */}


                                                                                                </div>
                                                                                                <div >
                                                                                                    <Row>
                                                                                                        <Col md={2}>
                                                                                                            <DropdownButton title="..." bsStyle="small" dropup noCaret>

                                                                                                                <MenuItem eventKey="1">
                                                                                                                    <label style={{ textAlign: "left", marginRight: "20px" }}>ACH</label>
                                                                                                                    <Switch
                                                                                                                        defaultValue={this.state.Processor.IsBankAccount}
                                                                                                                        disabled={true}
                                                                                                                        onChange={event => {
                                                                                                                            this.state.Processor.IsBankAccount = event.state.value;
                                                                                                                            const processorModel = { ...this.state.Processor }
                                                                                                                            processorModel.IsBankAccount = event.state.value;
                                                                                                                            this.setState({ ...this.state.Processor.IsBankAccount, processorModel })
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </MenuItem>
                                                                                                                <MenuItem eventKey="2">
                                                                                                                    <label style={{ textAlign: "left", marginRight: "20px" }}>Use as Default</label>
                                                                                                                    <Switch
                                                                                                                        defaultValue={this.state.CardConnectState}
                                                                                                                        disabled={true}
                                                                                                                        value={this.state.CardConnectState}
                                                                                                                        onChange={event => this.toggleProcessorState("cardconnect", event.state.value)}
                                                                                                                    />
                                                                                                                </MenuItem>
                                                                                                                {/* <MenuItem eventKey="2">Bolt</MenuItem> */}
                                                                                                                <MenuItem divider />
                                                                                                            </DropdownButton>
                                                                                                        </Col>
                                                                                                        <Col md={5}>
                                                                                                        </Col>
                                                                                                        <Col md={4}>
                                                                                                            <Button bsStyle="primary" style={{ marginRight: 10 }} wd onClick={e => this.handleCancelSettings(e)}>Cancel</Button>
                                                                                                            {/* <Button bsStyle="primary" fill wd disabled={!this.state.CardConnectState} onClick={e => this.saveSettings("cardconnect")}>Save Changes</Button> */}
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>

                                                                                            </form>
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            : ""}
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                        }
                                                    />
                                                </Tab.Pane>
                                                }
                                                <Tab.Pane eventKey="conveniencefee">
                                                    <Card
                                                        title="Legal items"
                                                        category="More information here"
                                                        content={
                                                            <div>
                                                                <p>
                                                                    The first thing you notice when you hold the phone is how
                                                                    great it feels in your hand. The cover glass curves down
                                                                    around the sides to meet the anodized aluminum enclosure
                                                                    in a remarkable, simplified design.
                      </p>
                                                                <p>
                                                                    Larger, yet dramatically thinner.It’s one continuous form
                                                                    where hardware and software function in perfect unison,
                                                                    creating a new generation of phone that’s better by any
                                                                    measure.
                      </p>
                                                            </div>
                                                        }
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="changepassword">
                                                    <Card
                                                        plain
                                                        content={
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    {this.state.errorMessage != "" &&
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <Alert bsStyle={this.state.errorCode === 0 ? "success" : "danger"}>
                                                                                    <span>
                                                                                        {this.state.errorMessage}
                                                                                    </span>
                                                                                </Alert>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    {this.state.ShowProgressBar &&
                                                                        <Row >
                                                                            <Col md={12}>
                                                                                <CustomProgressBar></CustomProgressBar>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                    <br />
                                                                    <Row>
                                                                        <Col md={6} >
                                                                            <Card
                                                                                pink
                                                                                content={
                                                                                    <div >
                                                                                        <form>
                                                                                            <FormGroup>
                                                                                                <ControlLabel>Current Password
                                                                                                    <span className="star"> *</span>
                                                                                                </ControlLabel>
                                                                                                <FormControl
                                                                                                    value={this.state.currentPassword}
                                                                                                    placeholder="Current Password"
                                                                                                    autoComplete="off"
                                                                                                    name="currentPassword"
                                                                                                    type="password"
                                                                                                    className={this.state.currentPasswordState}
                                                                                                    onChange={event => {
                                                                                                        this.change(event, "currentPassword", "length", 4)
                                                                                                    }
                                                                                                    }
                                                                                                />
                                                                                                {this.state.currentPasswordErrorMessage}
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <ControlLabel>New Password  <span className="star">*</span></ControlLabel>
                                                                                                <FormControl
                                                                                                    value={this.state.newPassword}
                                                                                                    placeholder="New Password"
                                                                                                    autoComplete="off"
                                                                                                    name="newPassword"
                                                                                                    type="password"
                                                                                                    className={this.state.newPasswordState}
                                                                                                    onChange={event => {
                                                                                                        this.change(event, "newPassword", "length", 6)
                                                                                                    }
                                                                                                    }
                                                                                                />
                                                                                                {this.state.newPasswordErrorMessage}
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <ControlLabel>Confirme Password  <span className="star">*</span></ControlLabel>
                                                                                                <FormControl
                                                                                                    value={this.state.confirmNewPassword}
                                                                                                    placeholder="Confirme Password"
                                                                                                    autoComplete="off"
                                                                                                    name="confirmNewPassword"
                                                                                                    type="password"
                                                                                                    className={this.state.confirmNewPasswordState}
                                                                                                    onChange={event => {
                                                                                                        this.change(event, "confirmNewPassword", "length", 4);
                                                                                                    }
                                                                                                    }
                                                                                                    onBlur={e => this.ValidatePasswords()}
                                                                                                />
                                                                                                {this.state.confirmNewPasswordErrorMessage}
                                                                                            </FormGroup>

                                                                                            <br />
                                                                                            <div style={{ textAlign: "right" }}>

                                                                                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelSettings(e)}>Cancel</Button>
                                                                                                <Button bsStyle="primary" disabled={!this.state.isPasswordFormValid} fill wd onClick={e => this.saveChangePassword()}>Save Changes</Button>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            }
                        />
                    </div>
                </Col>
            </Row>
        );
    }
}

export default Settings;
