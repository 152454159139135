// @flow

import React, { Component } from 'react';
import payment from 'payment';
import styled from 'styled-components';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import Input from "@material-ui/core/Input";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import {
  formatCvc,
  hasCVCReachedMaxLength,
  isHighlighted
} from 'utils/formatter';

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};
  
`;

const DangerText = styled.p`
  font-size: 1.2rem;
  margin: 5px 0 0 0;
  color: #ff3860;
  ${({ styled }) => ({ ...styled })};
`;

const BACKSPACE_KEY_CODE = 8;
global.isCardCVVValid = false;

type Props = {
  cardCVCInputRenderer: Function,
  onError?: Function,
  cardCVCInputProps: Object,
  containerClassName: string,
  containerStyle: Object,
  dangerTextClassName: string,
  dangerTextStyle: Object,
  fieldClassName: string,
  fieldStyle: Object,
  inputComponent: Function | Object | string,
  invalidClassName: string,
  invalidStyle: Object,
  customTextLabels: Object,
  formControlProps:  PropTypes.object,
  classes: PropTypes.object.isRequired,
};
type State = {
  errorText: ?string
};

const inputRenderer = ({ inputComponent, props }: Object) => {
  const Input = inputComponent || 'input';
  return <Input {...props} />;
};

class CVVInput extends Component<Props, State> {
  cvcField: any;

  static defaultProps = {
    cardCVCInputRenderer: inputRenderer,
    cardCVCInputProps: {},
    containerClassName: '',
    containerStyle: {},
    dangerTextClassName: '',
    dangerTextStyle: {},
    fieldClassName: '',
    fieldStyle: {},
    inputComponent: 'input',
    invalidClassName: 'is-invalid',
    invalidStyle: {},
    customTextLabels: {},
    formControlProps:{},
    classes: {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      errorText: null
    };
  }

  checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };


  handleCardCVCBlur = (
    { onBlur }: { onBlur?: ?Function } = { onBlur: null }
  ) => (e: SyntheticInputEvent<*>) => {
    const { customTextLabels } = this.props;
    if (!payment.fns.validateCardCVC(e.target.value)) {
      global.isCardCVVValid = false;
      this.setFieldInvalid(
        customTextLabels.invalidCvc || 'CVC is invalid',
        'cardCVC'
      );
    }
    else
    {
       global.isCardCVVValid = true;
    }

    const { cardCVCInputProps } = this.props;
    cardCVCInputProps.onBlur && cardCVCInputProps.onBlur(e);
    onBlur && onBlur(e);
  };

  handleCardCVCChange = (
    { onChange }: { onChange?: ?Function } = { onChange: null }
  ) => (e: SyntheticInputEvent<*>) => {
    const { customTextLabels } = this.props;
    const value = formatCvc(e.target.value);
    this.cvcField.value = value;
    const CVC = value;
    const CVCLength = CVC.length;    

    this.setFieldValid();
    if (CVCLength >= 4) {
      if (!payment.fns.validateCardCVC(CVC, null)) {
        global.isCardCVVValid = false;
        this.setFieldInvalid(
          customTextLabels.invalidCvc || 'CVC is invalid',
          'cardCVC'
        );
      }
      else
      {
         global.isCardCVVValid = true;
      }
    }
    else if(CVCLength < 3)
    {
      global.isCardCVVValid = false;
    }

    const { cardCVCInputProps } = this.props;
    cardCVCInputProps.onChange && cardCVCInputProps.onChange(e);
    onChange && onChange(e);
  };

  handleCardCVCKeyPress = (e: any) => {
    const value = e.target.value;
    this.checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(' / ').join('').length;
      if (hasCVCReachedMaxLength(null, valueLength)) {
        e.preventDefault();
      }
      if(valueLength >= 2)
      {
        global.isCardCVVValid = true;
      }
      else
      {
        global.isCardCVVValid = false;
      }
    }
  };


  handleKeyDown = (ref: any) => {
    return (e: SyntheticInputEvent<*>) => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
        ref.focus();
      }
    };
  };

  setFieldInvalid = (errorText: string, inputName?: string) => {
    const { invalidClassName, onError } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.add(invalidClassName);
    this.setState({ errorText });

    if (inputName) {
      const { onError } = this.props[`${inputName}InputProps`];
      onError && onError(errorText);
    }

    if (onError) {
      onError({ inputName, error: errorText });
    }
  };

  setFieldValid = () => {
    const { invalidClassName } = this.props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.remove(invalidClassName);
    this.setState({ errorText: null });
  };

  render = () => {
    const { errorText } = this.state;
    const {
      cardCVCInputProps,
      cardCVCInputRenderer,
      containerClassName,
      containerStyle,
      dangerTextClassName,
      dangerTextStyle,
      fieldClassName,
      fieldStyle,
      inputComponent,
      invalidStyle,
      customTextLabels,
      classes,
      formControlProps,
      error,
      success,
      labelText,
      id,
      value,
      labelProps,
      inputProps
    } = this.props;

    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    
    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: !error,
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: labelText === undefined
    });

    return (
      <FormControl 
      // className={containerClassName} styled={containerStyle}
      {...formControlProps}
     // className='MuiFormControl-root undefined CustomInput-formControl-132 MuiFormControl-fullWidth'
      className={formControlProps.className}
      >   
        <FieldWrapper
          id="field-wrapper"
          className={fieldClassName}
          styled={fieldStyle}
          invalidStyled={invalidStyle}
        >
          {labelText !== undefined ? (
        <InputLabel
          className= {!errorText && classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
            {labelText}
            </InputLabel>
          ) : null}
          <Input
             data-max="99999"
              id= 'cvc'
              name='CardCVV'
              value={value}
                ref = {cvcField => {
                  this.cvcField = cvcField;
                }}
                 maxLength= '5'
                autoComplete= 'off'
                classes={{
                  root: marginTop,
                  disabled: classes.disabled,
                  underline: underlineClasses
               }}
               // placeholder= {customTextLabels.cvcPlaceholder || 'CVC'}
                type= 'tel'
               // ...cardCVCInputProps
                onBlur= {this.handleCardCVCBlur()}
                onChange= {this.handleCardCVCChange()}
                onKeyPress= {this.handleCardCVCKeyPress}
                fullWidth= {true}
                {...inputProps}
          >
          {/* {cardCVCInputRenderer({
              inputComponent,
              handleCardCVCChange: onChange =>
                this.handleCardCVCChange({ onChange }),
              handleCardCVCBlur: onBlur => this.handleCardCVCBlur({ onBlur }),
           })} */}
          </Input>
          
        </FieldWrapper>
        {errorText && (
          <DangerText className={dangerTextClassName} styled={dangerTextStyle}>
            {errorText}
          </DangerText>
        )}
      </FormControl>
    );
  };
}

export default  withStyles(customInputStyle)(CVVInput);

