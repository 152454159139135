// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import InputLabel from "@material-ui/core/InputLabel";
import CustomCheckBoxStyles from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";



const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};

`;


 
type Props = {
  formControlProps:  PropTypes.object,
  classes: PropTypes.object.isRequired,
};

var divStyle = {
  marginTop: "0px"
};

class CustomCheckBox extends Component<Props, State> {
 
  static defaultProps = {
    formControlProps:{},
    classes: {} 
  };

  // constructor(props: Props) {
  //   super(props);
  // }

  render = () => {
    const {
      classes,
      formControlProps,
      labelText,
      labelProps,
      name,
      checked,
      isDisabled,
      id     
    } = this.props;


    return (
      <div style={divStyle}
      {...formControlProps}
      className={formControlProps.className }
      >   
        <FieldWrapper>                            
          <Checkbox   
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        checked={checked}
        disabled={isDisabled}
        name={name}
        classes={{
          checked: classes.checked
        }}/>
        <InputLabel  {...labelProps}>
          {labelText}
        </InputLabel>
        
        </FieldWrapper>
      </div>
    );
  };
}

export default  withStyles(CustomCheckBoxStyles)(CustomCheckBox);
