import React, { Component } from "react";
import { Row, Col, Modal, FormControl, ControlLabel, FormGroup, Alert } from "react-bootstrap";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import { StorageEnum } from "utils/constant.js";
import Select from "react-select";
import { BOLT_URL, GATEWAY_API_URL, USER_NAME, PASSWORD } from "utils/constant.js";

class CreateReseller extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ShowProgressBar: false,
            isFormValid: false,
            errorMessage: "",
            errorCode: 1,
            firstnameState: "",
            lastnameState: "",
            companynameState: "",
            addressState: "",
            reselleraffiliationState: "",
            emailState: "",
            phonenumberState: "",
          //  passwordState: "",
            paymentmethodState: "",
            firstNameErrorMessage: "",
            companyNameErrorMessage: "",
            addressErrorMessage: "",
            resellerAffiliationErrorMessage: "",
            lastNameErrorMessage: "",            
            emailErrorMessage: "",
            phonenumberErrorMessage: "",
         //   passwordErrorMessage: "",
            errorRegistrationMessage: "",  
            paymentmethodErrorMessage: "",
            user: {
                firstname: "",
                lastname: "",
                companyname: "",
                address: "",
                reselleraffiliation: "",
                email: "",
                phonenumber: "",
                paymentMethodType: "",
                password: "",
                status: "active"
            }
        }
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        else if (localStorage.getItem("login_user_type") != "Admin") {
            this.props.history.goBack();
        }
        document.getElementById("loaderDiv").style.display = "none";
    }


    onFirstNameBlur() {
        if (this.state.firstNameState === "" || this.state.firstNameState === "error") {
            this.setState({
                firstNameErrorMessage: (
                    <small className="text-danger">
                        First Name is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ firstNameState: "valid" });
            this.setState({ firstNameErrorMessage: null });
            this.isValidated();
        }
    }

    onLastNameBlur() {
        if (this.state.lastNameState === "" || this.state.lastNameState === "error") {
            this.setState({
                lastNameErrorMessage: (
                    <small className="text-danger">
                        Last Name is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ lastNameState: "valid" });
            this.setState({ lastNameErrorMessage: null });
            this.isValidated();
        }
    }

    onCompanyNameBlur(){
        if (this.state.companyNameState === "" || this.state.companyNameState === "error") {
            this.setState({
                companyNameErrorMessage: (
                    <small className="text-danger">
                        Company Name is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ companyNameState: "valid" });
            this.setState({ companyNameErrorMessage: null });
            this.isValidated();
        }
    }
    
    onAddressBlur(){
        if (this.state.addressState === "" || this.state.addressState === "error") {
            this.setState({
                addressErrorMessage: (
                    <small className="text-danger">
                        Address is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ addressState: "valid" });
            this.setState({ addressErrorMessage: null });
            this.isValidated();
        }
    }

    onResellerAffiliationBlur(){
        if (this.state.resellerAffiliationState === "" || this.state.resellerAffiliationState === "error") {
            this.setState({
                resellerAffiliationErrorMessage: (
                    <small className="text-danger">
                        Reseller/ISO Affiliation is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ resellerAffiliationState: "valid" });
            this.setState({ resellerAffiliationErrorMessage: null });
            this.isValidated();
        }
    }


    onEmailBlur() {
        if (this.state.emailState === "" || this.state.emailState === "error") {
            this.setState({
                emailErrorMessage: (
                    <small className="text-danger">
                        Email is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ emailState: "valid" });
            this.setState({ emailErrorMessage: null });
            this.isValidated();
        }
    }

    onPhoneNumberBlur() {
        if (this.state.phonenumberState === "" || this.state.phonenumberState === "error") {
            this.setState({
                phonenumberErrorMessage: (
                    <small className="text-danger">
                        Phone Number is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ phonenumberState: "valid" });
            this.setState({ phonenumberErrorMessage: null });
            this.isValidated();
        }
    }

    onPaymentmethodBlur() {
        if (this.state.paymentmethodState === "" || this.state.paymentmethodState === "error") {
            this.setState({
                paymentmethodErrorMessage: (
                    <small className="text-danger">
                        Payment Method is required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ paymentmethodState: "valid" });
            this.setState({ paymentmethodErrorMessage: null });
            this.isValidated();
        }
    }
   

    // onPasswordBlur() {
    //     if (this.state.passwordState === "" || this.state.passwordState === "error") {
    //         this.setState({
    //             passwordErrorMessage: (
    //                 <small className="text-danger">
    //                     Password is required.
    //                 </small>
    //             )
    //         });
    //         this.setState({ isFormValid: false });
    //     }
    //     else {
    //         this.setState({ passwordState: "valid" });
    //         this.setState({ passwordErrorMessage: null });
    //         this.isValidated();
    //     }
    // }

    isValidated() {
        if (this.state.firstNameState === "valid" && this.state.lastNameState === "valid"
            && this.state.companyNameState === "valid"   
            && this.state.addressState === "valid"   
            && this.state.resellerAffiliationState === "valid"               
            && this.state.emailState === "valid"
            && this.state.phonenumberState === "valid"
            && (this.state.paymentMethodType === "NMI" || this.state.paymentMethodType === "CardConnect")) {            
            
            this.setState({ isFormValid: true });
        }
        else {
            this.setState({ isFormValid: false });
            return false;
        }
        return this.state.isFormValid;
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });                   
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                [stateName]: value
            }
        }));

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handleCancelCreateReseller(e) {
        this.props.history.push('/admin/reseller');
        e.preventDefault();
    }

    ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                emailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });
            this.setState({
                emailState: "error"
            });
        }
        else {
            this.setState({ emailState: "valid" });
            this.setState({ emailErrorMessage: null });
        }
    }

    handleSetPaymentMethod(event) {
        const { label, value } = event;
        this.setState({ paymentMethodType: value });
    }
    
    callingResellerCreate(state) {      
        
        let formData = new FormData();
        formData.append("first_name", state.user.firstname);
        formData.append("last_name", state.user.lastname);
        formData.append("company_name", state.user.companyname);     
        formData.append("address", state.user.address);  
        formData.append("reseller_affiliation", state.user.reselleraffiliation);            
        formData.append("email", state.user.email);
        formData.append("phone_number", state.user.phonenumber);
        formData.append("password", state.user.phonenumber.slice(-4));
        formData.append("processor_type", state.user.paymentMethodType.value);
        formData.append("status", true);     
        this._resellerCreateRequest(formData);
    }
    /** API CALLING */

    // POST request for boarding Reseller
    _resellerCreateRequest = (body) => {
        if (this.isValidated()) {
            this.setState({ ShowProgressBar: true });
            this.setState({ errorRegistrationMessage: "" });
            NetworkService.postResellerBoardingRequest(body)
                .then(data => {
                    if (data != null) {
                        this.setState({ ShowProgressBar: false });
                        this.setState({ errorMessage: data.statusmessage });
                        this.setState({ errorCode: data.statuscode });
                        if (data.statuscode == 0) {
                            this.setState({ errorMessage: data.statusmessage });
                            this.props.history.push({
                                pathname: '/admin/resellers',
                                state: { errorMessage: data.statusmessage, errorCode: 0 }
                            });
                        }
                    } else {
                    }
                }).catch(error => {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                });
        }
        else {
        }
    }

    render() {
        const marginBottomStyle = {
            marginBottom: "10px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            {this.state.ShowProgressBar &&
                                <div style={{ marginBottom: "2px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                            <br />
                            <Card
                                title="Reseller Info"
                                content={
                                    <div>
                                        <Row>                                            
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                First Name: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="First Name"
                                                                name="firstname"
                                                                className={this.state.firstNameState}
                                                                value={this.state.user.firstname}
                                                                onChange={event => {
                                                                    this.change(event, "firstname", "length", 2)
                                                                }
                                                                }
                                                                onBlur={e => this.onFirstNameBlur()}
                                                            />
                                                            {this.state.firstNameErrorMessage}
                                                        </FormGroup>
                                                    </Col>  
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Last Name: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Last Name"
                                                                name="lastname"
                                                                className={this.state.lastNameState}
                                                                value={this.state.user.lastname}
                                                                onChange={event => {
                                                                    this.change(event, "lastname", "length", 2)
                                                                }
                                                                }
                                                                onBlur={e => this.onLastNameBlur()}
                                                            />
                                                            {this.state.lastNameErrorMessage}
                                                        </FormGroup>
                                                    </Col>  
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Email adress: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="email"
                                                                placeholder="Enter Email"
                                                                name="email"
                                                                className={this.state.emailState}
                                                                value={this.state.user.email}
                                                                onChange={event =>
                                                                    this.ValidateEmailAddress(event)
                                                                }
                                                                onBlur={e => this.onEmailBlur()}
                                                            />
                                                            {this.state.emailErrorMessage}
                                                        </FormGroup>
                                                    </Col>                                                                                                                                                        
                                                </Row>     
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Company Name: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Company Name"
                                                                name="companyname"
                                                                className={this.state.lastNameState}
                                                                value={this.state.user.companyname}
                                                                onChange={event => {
                                                                    this.change(event, "companyname", "length", 2)
                                                                }
                                                                }
                                                                onBlur={e => this.onCompanyNameBlur()}
                                                            />
                                                            {this.state.companyNameErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Address: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Address"
                                                                name="address"
                                                                className={this.state.addressState}
                                                                value={this.state.user.address}
                                                                onChange={event => {
                                                                    this.change(event, "address", "length", 2)
                                                                }
                                                                }
                                                                onBlur={e => this.onAddressBlur()}
                                                            />
                                                            {this.state.addressErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Phone Number: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="number"
                                                                placeholder="Phone Number"
                                                                className={this.state.user.phonenumberState}
                                                                name="phonenumber"
                                                                value={this.state.user.phonenumber}
                                                                onChange={event =>
                                                                    //this.setState({ user: { phonenumber: event.target.value } })
                                                                    this.change(event, "phonenumber", "length", 7)
                                                                }                                                               
                                                                type="number"
                                                                onBlur={e => this.onPhoneNumberBlur()}
                                                            />
                                                            {this.state.phonenumberErrorMessage}

                                                        </FormGroup>
                                                    </Col>  
                                                </Row>                                                                                                                                         
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Reseller/ISO Affiliation: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Reseller/ISO Affiliation"
                                                                name="reselleraffiliation"
                                                                className={this.state.resellerAffiliationState}
                                                                value={this.state.user.reselleraffiliation}
                                                                onChange={event => {
                                                                    this.change(event, "reselleraffiliation", "length", 2)
                                                                }
                                                                }
                                                                onBlur={e => this.onResellerAffiliationBlur()}
                                                            />
                                                            {this.state.resellerAffiliationErrorMessage}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Processor Affiliation: <span className="star">*</span>
                                                            </div>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="paymentmethod"
                                                                value={this.state.user.paymentMethodType}
                                                                onChange={event => {
                                                                    this.handleSetPaymentMethod(event);
                                                                    this.setState({
                                                                        paymentmethodErrorMessage: null, paymentmethodState: "valid"
                                                                    });
                                                                    this.setState(prevState => ({
                                                                        user: {
                                                                            ...prevState.user,
                                                                            paymentMethodType: event
                                                                        }
                                                                    }));
                                                                }
                                                                }
                                                                onBlur={e => this.onPaymentmethodBlur()}
                                                                // onBlur={e => this.isValidated()}
                                                                options={[
                                                                    {
                                                                        value: "",
                                                                        label: "Select Processor Affiliation",
                                                                        isDisabled: true
                                                                    },
                                                                    { value: "NMI", label: "NMI" },
                                                                    { value: "CardConnect", label: "Card Connect" },
                                                                    // { value: "AuthorizeNet", label: "Authorize Net" }

                                                                ]}
                                                                placeholder="Payment Method"
                                                            />
                                                            {this.state.paymentmethodErrorMessage}

                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md={4}>
                                                        <FormGroup style={marginBottomStyle}>
                                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                Password: <span className="star">*</span>
                                                            </div>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Password"
                                                                autoComplete="off"
                                                                name="password"
                                                                className={this.state.passwordState}
                                                                value={this.state.user.password}
                                                                onChange={event =>
                                                                {
                                                                    this.change(event, "password", "length", 4);
                                                                    this.isValidated();
                                                                }}
                                                                onBlur={e => this.onPasswordBlur()}
                                                            />
                                                            {this.state.passwordErrorMessage}
                                                        </FormGroup>
                                                    </Col> */}
                                                </Row>                                                
                                            </Col>
                                        </Row>
                                    </div>
                                }>

                            </Card>
                            

                            <br />
                            <Row>
                                <Col md={12}>
                                    <div style={{ textAlign: "right", marginBottom: "20px" }}>
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelCreateReseller(e)}>Cancel</Button>
                                        <Button bsStyle="primary" fill onClick={e => this.callingResellerCreate(this.state)} disabled={!this.state.isFormValid}>
                                            Create Reseller
                                        </Button>
                                    </div>
                                </Col>
                            </Row>                           
                        </div>
                    }

                />


            </div>
        );
    }
}

export default CreateReseller;