import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Alert,
    ButtonGroup,
    FormControl,
    Modal,
    InputGroup,

} from "react-bootstrap";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NetworkService from "services/NetworkService";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Common from "utils/Common.js";

const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb("#000") +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb("#0075c1") +
    ",.4)"
};

const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    select: {
      width: "100%",
     marginTop: 0,
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "1.42857",
    textDecoration: "none",
   // textTransform: "uppercase",
    color: "#3C4858",
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)"
    },
    "& + input + svg": {
      transition: "all 300ms linear"
    }
  }
}

class Recurring extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            alert: null,
            show: false,
            RecurringStartDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
            PastDateMonth: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            processorType: "nmi",
            Customer: {
                CustomerName:'',
                FirstName: '',
                LastName: '',
                Id: '',
                CardHolderName: '',
                AccountType: 'checking',
                AccountHolderType: {value: "personal", label: "Personal"},  
                SecCode: {value: "PPD", label: "PPD"},
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: true,                
                CardHolderNameValid: true,                
                AccountNameValid: true,               
                AccountNumber: "",
                RoutingNumber: "",
                AccountNumberValid: true,
                RoutingNumberValid: true
            },
            showCreateRecurringModal: false,
            ShowPopupProgressBar: false,
            invoice: {},
            RecurringParams:{     
                TemplateName: "",
                day_frequency: "31",
                month_frequency: "",
                day_of_month: "",
                occurrence: null,
                start_date: ""
            },
            ProfileParams:{
                profile_id: null
            },
            FirstNameValid: true,
            LastNameValid: true,
            TemplateNameValid: true,
            templateNameErrorMessage: "", 
            isCreatingRecurring: false,
            IsCreditCard: true,
            IsDebitCard: false,
            IsACH: false,
            IsCreditCardEnabled: true,
            IsDebitCardEnabled: false,
            IsACHEnabled: true,
            ccProfiles: [],
            achProfiles: [],
            isProfileSelected: false,
            FormErrors: { CardHolderName: '', AccountName: '', TemplateName: '' },
            IsDayFrequency: true,
            IsMonthFrequency: false,
            IsChargeUntilCanceled: true,
            IsChargeTheCustomer: false,
            FormValid: true,

            emvToken: '',
            expiryDate: '',
            hostName: '',
            hostPort: '',
            copilotURL: '',
            ConvFee: "",
            is_percentage:  false,
            ConvFeeAmount: 0.00,
            groupButtonCreditCardStyle: "primary active",
            groupButtonDebitCardStyle: "default",
            groupButtonACHStyle: "default",
            lastState: "creditcardrecurring",
            accounts:[],
            secCodes:[]
        }
        this.recurringStartDateHandler = this.recurringStartDateHandler.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };

    componentDidMount() {        
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
        this.state.processorType = data_tokenize.PPType.toLowerCase();
        if(data_tokenize.PPType.toLowerCase() === "nmi"){     
            this.loadNMIScriptTag(data_tokenize.PPToken);
        }else if(data_tokenize.PPType.toLowerCase() === "cardconnect"){
            this.state.copilotURL = data_tokenize.PPToken;
        }
        let ach = (localStorage.getItem("is_ach_enabled") == "true")           
        let creditcard = (localStorage.getItem("is_creditcard_enabled") == "true")      
        let debitcard = (localStorage.getItem("is_debitcard_enabled") == "true")   
        this.setState({IsACH: ach, IsACHEnabled: ach })        
        this.setState({IsCreditCard: creditcard, IsCreditCardEnabled: creditcard, IsDebitCardEnabled: debitcard })
        if(ach && creditcard){
            this.setState({IsACH: false })
        }
        this.state.RecurringParams.TemplateName = "";
        this.state.RecurringParams.day_frequency = "31";
        this.state.RecurringParams.month_frequency = "";
        this.state.RecurringParams.day_of_month = "";   
        this.state.RecurringParams.occurrence = null;   
        this.state.RecurringParams.start_date  = Common.formatDate2(this.state.RecurringStartDate, "YYYY-MM-DD");
        this.state.IsDayFrequency = true;
        this.state.IsMonthFrequency = false;
        this.state.IsChargeUntilCanceled = true;
        this.state.IsChargeTheCustomer = false;
        this.getPaymentProfiles(this.props.CustomerRef.value);
        this.GetConvFee();
        this.setRecurringInfo();
        this.setAccountsAndSecCodes();
    }

    setAccountsAndSecCodes() {                   
        const accountState = [];
        accountState.push({value: "", label: "Select Account", isDisabled: true});
        accountState.push({value: "personal", label: "Personal"});
        accountState.push({value: "business", label: "Business"});
       // newState.push({value: "businessChecking", label: "Business Checking"});        
        this.setState({ accounts: accountState });        

        const secCodeState = [];
        secCodeState.push({value: "", label: "Select Sec Code", isDisabled: true});
        secCodeState.push({value: "PPD", label: "PPD"});
       // secCodeState.push({value: "WEB", label: "WEB"});
       // secCodeState.push({value: "TEL", label: "TEL"});
        secCodeState.push({value: "CCD", label: "CCD"});
        this.setState({ secCodes: secCodeState });         
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
         const app_id = 2;
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } 
            }).catch(error => {  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
        {         
            this.setState({ ConvFee: convfee[0] }); 
        } 
    }


    componentWillUnmount() {
        this.setState({ showCreateRecurringModal: false });
        this.props.parentCallback(false);
    }

    recurringStartDateHandler = (date) => {
        if(date._isValid)        
        {
            if(date <= new Date())
            {
                this.basicAlert();
                return false;
            }
            this.state.RecurringStartDate = date.format("MM-DD-YYYY");
            this.setState(prevState => ({
                RecurringParams: {
                    ...prevState.RecurringParams,
                    start_date: date.format("YYYY-MM-DD")
                }
            }));
        }
    };

    basicAlert() {
        this.setState({
          alert: (
            <SweetAlert
                style={{  display: "block", position:"fixed", marginLeft:"-222px", marginTop: "-170px" }}
                title="Error!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="info"
            >
                Month must be greater than the current month.
            </SweetAlert>
          )
        });
    }

    hideAlert() {
        this.setState({
          alert: null
        });
    }

    setRecurringInfo = () => {
        if (this.props != null || this.props != undefined) {
            this.state.showCreateRecurringModal = this.props.showCreateRecurringModal;           
            this.setState(prevState => ({
                ...prevState,
                Customer: {
                    ...prevState.Customer,
                    CustomerName: this.props.CustomerRef.name,
                    Id: this.props.CustomerRef.value,
                    ProfileId: {value: "none", label: "None"},
                }
            }));      
            if(this.props.ProfileParams != undefined && this.props.ProfileParams.profile_id != undefined && this.props.ProfileParams.profile_id != null && this.props.RecurringParams.TemplateName != ""){                                                           
                this.setState({IsCreditCard: this.props.IsCreditCard, IsACH: this.props.IsACH, IsDebitCard: this.props.IsDebitCard});                 
                this.setState(prevState => ({
                            ProfileParams: {
                                ...prevState.ProfileParams,
                                profile_id: this.props.ProfileParams.profile_id
                            }
                        }));
                this.setState({Customer: this.props.CustomerProfileInfo});
                this.state.RecurringParams.TemplateName = this.props.RecurringParams.TemplateName;
                this.state.RecurringParams.day_frequency = this.props.RecurringParams.day_frequency;
                this.state.RecurringParams.month_frequency = this.props.RecurringParams.month_frequency;
                this.state.RecurringParams.day_of_month = this.props.RecurringParams.day_of_month;   
                this.state.RecurringParams.occurrence = this.props.RecurringParams.occurrence; 
                this.state.RecurringParams.start_date = this.props.RecurringParams.start_date; 
                if(this.props.RecurringParams.day_frequency != null && this.props.RecurringParams.day_frequency != undefined && this.props.RecurringParams.day_frequency != ""){    
                    this.state.IsDayFrequency = true;
                    this.state.IsMonthFrequency = false;
                }
                else{
                    this.state.IsDayFrequency = false;
                    this.state.IsMonthFrequency = true;
                }
                if(this.props.RecurringParams.occurrence != null && this.props.RecurringParams.occurrence != undefined && this.props.RecurringParams.occurrence == "0"){    
                    this.state.IsChargeUntilCanceled = true;
                    this.state.IsChargeTheCustomer = false;
                }
                else{
                    this.state.IsChargeUntilCanceled = false;
                    this.state.IsChargeTheCustomer = true;
                }
                this.setState({ isProfileSelected: true });
                this.setState({FormValid: this.state.RecurringParams.TemplateName.length >= 2 && this.state.TemplateNameValid});
            } 
        }
    }

    getPaymentProfiles(customer_id) {
        const payload = {
            "customer_id": customer_id,
            "realm_id": null
        }
        this._getProfileByIdRequest(payload)
    }

    _getProfileByIdRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postProfileByIdRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    this.setState({ StatusCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this.setProfileResult(data.data.items);
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    setProfileResult = (result) => {
        let profiles = [{
            value: "",
            label: "Select Proflie",
            isDisabled: true
        },
        {
            value: "none",
            label: "None"
        }
        ]
        let achProfilesArray = [{
            value: "",
            label: "Select Proflie",
            isDisabled: true
        },
        {
            value: "none",
            label: "None"
        }
        ]

        result.map(data => {
            if (data.card_type != "ECHK" && data.card_type != "ACH" && data.card_type != "ECHEK") {
                profiles.push({
                    value: data.token,
                    label: data.last_digits,
                    cardholder_name: data.cardholder_name,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postal_code: data.postal,
                    card_type: data.card_type
                });
            }
            else {
                achProfilesArray.push({
                    value: data.token,
                    label: data.last_digits,
                    cardholder_name: data.cardholder_name,
                    first_name: null,
                    last_name: null,
                    address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postal_code: data.postal,
                    card_type: data.card_type
                });
            }
        });

        this.setState({ ccProfiles: profiles });
        this.setState({ achProfiles: achProfilesArray });
        console.log(this.state.ccProfiles);
    }

    handleProfileChanged(prof) {
        if (prof.value != "none") {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, IsCreateProfile: false
                }
            }));

            this.setState({ isProfileSelected: true });
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer,
                    ProfileId: prof.value,
                    CardHolderName: prof.cardholder_name,
                    Address: prof.address,               
                    City: prof.city,
                    State: prof.state,
                    Country: prof.country,    
                    PostalCode: prof.postal_code,

                },
                ProfileParams: {
                    ...prevState.ProfileParams,
                    profile_id: prof.value
                }
            }));

            this.setState({FormValid: this.state.RecurringParams.TemplateName.length >= 2 && this.state.TemplateNameValid});
        }
        else {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer,
                    ProfileId: "",
                    CardHolderName: this.state.CustomerName,
                    FirstName: this.state.CustomerName ? this.state.CustomerName.Split(' ')[0] : "",
                    LastName: this.state.CustomerName && this.state.CustomerName.Split(' ').length > 1 ? this.state.CustomerName.Split(' ')[1] : "",
                    Address: "",
                    City: "",
                    State: "",
                    PostalCode: "",
                    Country: ""
                    
                },
                ProfileParams: {
                    ...prevState.ProfileParams,
                    profile_id: null
                }
            }));
            this.setState({ isProfileSelected: false });
        }
    }
    
    handleFrequencyRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "dayfrequency") {
            this.setState({ IsDayFrequency: true });
            this.setState({ IsMonthFrequency: false });
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    day_frequency: 31
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    day_of_month: ""
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    month_frequency: ""
                }
            }));
        }
        else {
            this.setState({ IsMonthFrequency: true });
            this.setState({ IsDayFrequency: false });
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    day_of_month: "1"
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    month_frequency: "1"
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    day_frequency: ""
                }
            }));
        }
    };

    handleChargeTimeRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "chargeuntilcanceled") {
            this.setState({ IsChargeUntilCanceled: true });
            this.setState({ IsChargeTheCustomer: false });
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    occurrence: null
                }
            }));
        }
        else {
            this.setState({ IsChargeTheCustomer: true });
            this.setState({ IsChargeUntilCanceled: false });
            this.setState(prevState => ({
                ...prevState,
                RecurringParams: {
                    ...prevState.RecurringParams,
                    occurrence: "1"
                }
            }));
        }
    }

    createRecurringSubmit = (event) => {         
        if (this.state.isProfileSelected) {        
            this.handlerCloseModal();
        }
        else{
            if(this.state.processorType == "nmi") 
            {
                var isCCFormValid = true;
                if(this.state.IsCreditCard || this.state.IsDebitCard){
                    if(document.getElementById("creditCardErrorMessage").innerHTML !== "") {
                        document.getElementById("creditCardErrorMessage").style.display = "block";                            
                        isCCFormValid = false;
                    }
                    if(document.getElementById("expErrorMessage").innerHTML !== "") {
                        document.getElementById("expErrorMessage").style.display = "block";
                        isCCFormValid = false;
                    }
                    if(document.getElementById("cvvErrorMessage").innerHTML !== ""){
                        document.getElementById("cvvErrorMessage").style.display = "block";
                        isCCFormValid = false;
                    }
                }
                else if(this.state.IsACH){
                    if(document.getElementById("accountNameErrorMessage").innerHTML !== "") {
                        document.getElementById("accountNameErrorMessage").style.display = "block";                            
                        isCCFormValid = false;
                    }
                    if(document.getElementById("accountNumberErrorMessage").innerHTML !== "") {
                        document.getElementById("accountNumberErrorMessage").style.display = "block";                            
                        isCCFormValid = false;
                    }
                    if(document.getElementById("routingNumberErrorMessage").innerHTML !== "") {
                        document.getElementById("routingNumberErrorMessage").style.display = "block";                            
                        isCCFormValid = false;
                    }              
                }
                if(!isCCFormValid || !this.state.FormValid)
                {               
                    let templateNameValid = this.state.TemplateNameValid;        
                    templateNameValid = /^.{2,30}$/.test(this.state.RecurringParams.TemplateName)
                    this.setState({TemplateNameValid: templateNameValid});

                    let firstNameValid = this.state.FirstNameValid;        
                    firstNameValid = /^.{2,30}$/.test(this.state.Customer.FirstName)
                    this.setState({FirstNameValid: firstNameValid});

                    let lastNameValid = this.state.LastNameValid;        
                    lastNameValid = /^.{2,30}$/.test(this.state.Customer.LastName)
                    this.setState({LastNameValid: lastNameValid});

                    event.preventDefault();
                    return false;
                }
                else {
                    this.setState({ ShowPopupProgressBar: true });
                    this.setState({ isSubmitting: true });

                    event.preventDefault();
                    window.CollectJS.startPaymentRequest();
                }
            }
            else{
                this.handlePrfileCreate(null);
            }            
        }
        return false;                          
    }

    _postCreateProfileRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = ApplicationIDEnum.QuickBooksOnline; 

        NetworkService.postCreateProcessorProfile(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        this.state.ProfileParams.profile_id =  data.data.profile_id;
                        this.setState(prevState => ({
                            ProfileParams: {
                                ...prevState.ProfileParams,
                                profile_id: data.data.profile_id
                            }
                        }));
                        this.handlerCloseModal();
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    //#endregion

    handlerCloseModal = () => {
        this.setState({ showCreateRecurringModal: false });    
        this.props.parentCallback(false, this.state.RecurringParams, this.state.ProfileParams, this.state.Customer, this.state.IsCreditCard, this.state.IsDebitCard, this.state.IsACH);           
    }

    handleRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        this.setState({ StatusMessage: "" });
        if(this.state.processorType == "cardconnect"){
            this.setState({IsTokenValid: false});
            this.setState({emvToken: "", expiryDate: ""});
            this.validateForm();            
        }

        if (target.name === "creditcardrecurring") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    ProfileId: {label: "None",value: "none"}
                }
            })); 
            this.setState({ lastState: target.name});
            this.state.groupButtonCreditCardStyle = "primary active";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "default";

        }
        else if(target.name === "debitcardrecurring"){
            this.setState({ IsDebitCard: true });
            this.setState({ IsCreditCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    ProfileId: {label: "None",value: "none"}
                }
            })); 
            this.setState({ lastState: target.name});
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "primary active";
            this.state.groupButtonACHStyle= "default";
        }
        else {            
            this.setState({ IsACH: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsCreditCard: false });
            
            this.setState({ lastState: target.name});            
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    ProfileId: {label: "None",value: "none"}
                }
            }));             
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "primary active";

        }
        if(this.state.processorType == "nmi")
        {
            this.configureCollectJS();  
            document.getElementById("creditCardErrorMessage").style.display = "none";                            
            document.getElementById("expErrorMessage").style.display = "none";                            
            document.getElementById("cvvErrorMessage").style.display = "none";                            
            document.getElementById("accountNameErrorMessage").style.display = "none";                            
            document.getElementById("accountNumberErrorMessage").style.display = "none";                            
            document.getElementById("routingNumberErrorMessage").style.display = "none";
        }
        this.setState({ isProfileSelected: false });        
    };

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'TemplateName':
                this.setState(prevState => ({
                    RecurringParams: {
                        ...prevState.RecurringParams,
                        TemplateName: value
                    }
                 }), () => { this.validateField(name, value) })
                break;
            case 'CardHolderName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardHolderName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case 'FirstName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, FirstName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case 'LastName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, LastName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Address: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, PostalCode: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                this.setState(prevState => ({
                    Amount:
                    {
                        ...prevState.Amount, Amount: value
                    }
                }), () => { this.validateField(name, value) })
                break;                       
            case "AccountName":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountHolderType":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountHolderType: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, RoutingNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            default:
                break;
        }

    }

    handleOnBlurInput(e) {
        // console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        const name = e.target.name;
        switch (name) {
            case "AccountNumber":
            case "RoutingNumber":
                if (this.state.Customer.RoutingNumber && this.state.Customer.AccountNumber) {
                    let account = `${this.state.Customer.RoutingNumber}/${this.state.Customer.AccountNumber}`
                    const payload = {
                        "account": account,
                        "source": "iToke",
                        "encryptionhandler": null,
                        "unique": false,
                        "expiry": null,
                        "cvv": null
                    }
                    this._postCardSecureTokenize(payload).then(event => {
                        let mytoken = document.getElementById('mytoken')
                        mytoken.value = event.token;

                        if (event.errorcode == 0) {

                            let acct_number = document.getElementById('accountnumber')
                            acct_number.value = "XXXXX" + acct_number.value.slice(-4);

                            let rout_number = document.getElementById('routingnumber')
                            rout_number.value = "XXXXX" + rout_number.value.slice(-4);

                            this.setState(prevState => ({
                                Customer:
                                {
                                    ...prevState.Customer, AccountNumber: "XXXXX" + acct_number.value.slice(-4)
                                }
                            }))

                            this.setState(prevState => ({
                                Customer:
                                {
                                    ...prevState.Customer, RoutingNumber: "XXXXX" + rout_number.value.slice(-4)
                                }
                            }))                            
                        }
                        this.setState({
                            IsTokenValid: event.errorcode == 0 ? true : false
                        });
                        this.setState({
                            emvToken: event.token,
                            expiryDate: null
                        })
                        this.validateForm();                        

                        event.preventDefault()
                    }).catch(error => {
                        // console.log(error)
                    })
                }
                break;
            default:
                break;
        }

        this.validateField(e.target.name, e.target.value);
    };

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.FormErrors;
        let cardHolderNameValid = this.state.Customer.CardHolderNameValid;
        let firstNameValid = this.state.FirstNameValid;
        let lastNameValid = this.state.LastNameValid;

        let accountNameValid = this.state.Customer.AccountNameValid;
        let templateNameValid = this.state.TemplateNameValid;
        switch (fieldName) {
            case 'TemplateName':
                templateNameValid = /^.{2,30}$/.test(value) 
                fieldValidationErrors.TemplateName = templateNameValid ? '' : ' is invalid';
                this.state.TemplateNameValid = templateNameValid ? true : false;
                break;             
            case 'CardHolderName':
                cardHolderNameValid = /^.{2,30}$/.test(value) 
                fieldValidationErrors.CardHolderName = cardHolderNameValid ? '' : ' is invalid';
                this.state.Customer.CardHolderNameValid = cardHolderNameValid;
                break;  
             case 'FirstName':
                firstNameValid = /^.{2,30}$/.test(value) 
                this.state.FirstNameValid = firstNameValid;
                break;
            case 'LastName':
                lastNameValid = /^.{2,30}$/.test(value) 
                this.state.LastNameValid = lastNameValid;
                break;
            case 'AccountName':
                accountNameValid = /^.{2,30}$/.test(value) 
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.Customer.AccountNameValid = accountNameValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            CardHolderNameValid: cardHolderNameValid,
            AccountNameValid: accountNameValid,
            TemplateNameValid: templateNameValid
        }, this.validateForm);

    }

    validateForm() {
        if(this.state.isProfileSelected){
            this.setState({FormValid: this.state.RecurringParams.TemplateName.length >= 2 && this.state.TemplateNameValid});
        }
        else if(this.state.processorType == "nmi")
        {
            if (this.state.IsCreditCard || this.state.IsDebitCard) {                           
                this.setState({FormValid: (this.state.IsCreditCard || this.state.IsDebitCard) && this.state.FirstNameValid && this.state.LastNameValid  && this.state.RecurringParams.TemplateName.length >= 2 && this.state.TemplateNameValid });
            }
            else if (this.state.IsACH) {
                this.setState({FormValid: this.state.Customer.AccountNameValid && this.state.FirstNameValid && this.state.LastNameValid && this.state.RecurringParams.TemplateName.length >= 2 && this.state.TemplateNameValid });            
            }
        }
        else{
            if (this.state.IsCreditCard || this.state.IsDebitCard) {           
                this.setState({FormValid:  (this.state.IsCreditCard || this.state.IsDebitCard) && this.state.Customer.CardHolderNameValid && this.state.RecurringParams.TemplateName.length >= 2 && this.state.TemplateNameValid });
            }
            else if (this.state.IsACH) {
                this.setState({FormValid: this.state.Customer.AccountNameValid && this.state.RecurringParams.TemplateName.length >= 2 && this.state.TemplateNameValid });            
            }
        }
        
    }

    handleTokenEvent = (event) => {
        window.addEventListener(
            'message',
            (event) => {
                const token = JSON.parse(event.data)
                let mytoken = document.getElementById('mytoken')
                mytoken.value = token.message;

                if (token.errorCode == "0" && token.errorMessage == "") {
                    this.setState({ IsTokenValid: true });                   
                }
                else {
                    this.setState({ IsTokenValid: false });
                }
                const expField = token.expiry

                const tkn = mytoken.value
                const dte = expField

                this.setState({ emvToken: tkn, expiryDate: dte });

                const emvData = {  token: tkn, expiryDate: dte };
                 this.validateForm();
                // this.props.tokenProps.userEmvData(emvData)
                event.preventDefault()
            },
            false
        )
    }
    
    renderTokenizerUi() {
        // const url = `https://${this.site}.cardconnect.com:${this.port}/itoke/ajax-tokenizer.html`
        const url = `${this.state.copilotURL}/itoke/ajax-tokenizer.html`
        var padding = this.state.IsCreditCard || this.state.IsDebitCard ? "6px 0 7px" : "0px 0 7px";
        const cssStyle = 'css=.success{color:green;border-color:green;}.error{color:red;border-color:red;}label{font-family:sans-serif;font-weight:bold;font-size:14px;margin-top:12px;margin-bottom:0px;color:rgba(0, 0, 0, 0.54)}input{font:inherit;color: currentColor;font-family:sans-serif;height:1.1875em;font-size:18px;margin-top:-5px;color:currentColor;width:100%;border: 0px;border-bottom: 1px solid;border-bottom-color: rgba(227,227,227,1); outline: none;display:block;padding:' + padding + ';margin-bottom: -6px;background:none;box-sizing: content-box;}select{font:inherit;color: currentColor;outline: none;font-family:sans-serif;height:1.1875em;font-size:18px;margin-top:-5px;color:currentColor;border:0;border-bottom: 1px solid; border-bottom-color: rgba(227,227,227,1);padding: 6px 0 7px;background:none;box-sizing: content-box; width:42%;margin-bottom: 10px;}';

        const params = new URLSearchParams({
            maskfirsttwo: false,
            useexpiry: this.state.IsCreditCard || this.state.IsDebitCard ? true : false,
            usemonthnames: this.state.IsCreditCard || this.state.IsDebitCard ? true : false,
            usecvv: this.state.IsCreditCard || this.state.IsDebitCard ? true : false,
            cardnumbernumericonly: this.state.IsCreditCard || this.state.IsDebitCard ? true : false,
            orientation: 'default',
            invalidinputevent: true,
            enhancedresponse: true,
            formatinput: true,
            tokenizewheninactive: true,
            inactivityto: this.state.IsCreditCard || this.state.IsDebitCard ? 4000 : 5000,
            fullmobilekeyboard: true
        })

        const iFrameUrl = url + '?' + params + '&' + encodeURI(cssStyle)

        return (            
                <div>
                    {this.state.ShowProgressBar &&
                        <div style={{ marginBottom: "2px" }}>
                            <CustomProgressBar></CustomProgressBar>
                        </div>

                    }
                    {this.state.StatusCode == 0 &&
                        <p style={styles.successResponse}>{this.state.StatusMessage}</p>
                    }
                    {this.state.StatusCode != 0 &&
                        <p style={styles.errorResponse}>{this.state.StatusMessage}</p>
                    }
                    <div style={{ display: (this.state.IsCreditCard || this.state.IsDebitCard) ? "block" : "none" }}>
                        {this.state.ccProfiles.length > 2 &&
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <InputLabel style={styles.dropDownFontStyle}>
                                        Select Profile
                                    </InputLabel>
                                    <Select
                                        classNamePrefix="react-select"
                                        name="ccProfiles"
                                        isDisabled={this.state.ShowProgressBar}
                                        value={this.state.Customer.ProfileId}
                                        onChange={profile => {
                                            this.handleProfileChanged(profile)
                                            this.setState(prevState => ({
                                                Customer:
                                                {
                                                    ...prevState.Customer, ProfileId: profile
                                                }
                                            }));
                                        }
                                        }
                                        options={this.state.ccProfiles}
                                        placeholder="Select Profile"
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <InputLabel style={styles.dropDownFontStyle}>
                                    Name on Card
                                </InputLabel>
                                <CustomInput
                                    isRequired={true}
                                    id="CardHolderName"
                                    name="CardHolderName"
                                    value={this.state.Customer.CardHolderName}
                                    showErrorMessage={!this.state.Customer.CardHolderNameValid}
                                    errorMessage="Card holder name is invalid"
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: this.state.isProfileSelected,                                            
                                        error: !this.state.Customer.CardHolderNameValid,
                                        onChange: (e) => { this.handleUserInput(e) },
                                        //onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                        onBlur: (e) => { this.handleOnBlurInput(e) }
                                    }}
                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                    labelProps={{ style: { fontWeight: "bold" } }}
                                />
                            </Col>
                        </Row>
                        {this.state.isProfileSelected == false &&
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <FormGroup controlId='tokenEvent' style={{ marginLeft: "-6px", marginBottom: "0px" }}>
                                        <iframe
                                            title='CardPointeTokenizer'
                                            id='tokenframe'
                                            name='tokenframe'
                                            src={iFrameUrl}
                                            frameBorder='0'
                                            scrolling='no'
                                            width='100%'
                                            height= {!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? '180' : '220'}
                                            onLoad={this.handleTokenEvent}
                                        >
                                        </iframe>
                                        <input type='hidden' name='token' id='mytoken' />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </div>

                    <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                        {this.state.achProfiles.length > 2 &&
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <InputLabel style={styles.dropDownFontStyle}>
                                        Select Profile
                                    </InputLabel>
                                    <Select
                                        classNamePrefix="react-select"
                                        name="achProfiles"
                                        isDisabled={this.state.ShowProgressBar}
                                        value={this.state.Customer.ProfileId}
                                        onChange={profile => {
                                            this.handleProfileChanged(profile)
                                            this.setState(prevState => ({
                                                Customer:
                                                {
                                                    ...prevState.Customer, ProfileId: profile
                                                }
                                            }));
                                        }
                                        }
                                        options={this.state.achProfiles}
                                        placeholder="Select Profile"
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <InputLabel style={styles.dropDownFontStyle}>
                                    Account Name
                                </InputLabel>
                                <CustomInput
                                    isRequired={true}
                                    id="AccountName"
                                    name="AccountName"
                                    value={this.state.Customer.AccountName}
                                    showErrorMessage={!this.state.Customer.AccountNameValid}
                                    errorMessage="Account name is invalid"
                                    formControlProps={{
                                        fullWidth: true,
                                        disabled: this.state.isProfileSelected,
                                        //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                        error: !this.state.Customer.AccountNameValid,
                                        onChange: (e) => { this.handleUserInput(e) },
                                        onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                        onBlur: (e) => { this.handleOnBlurInput(e) }
                                    }}
                                    inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                    labelProps={{ style: { fontWeight: "bold" } }}
                                />
                            </Col>
                        </Row>
                        {this.state.isProfileSelected == false &&
                            <Row>                                   
                                <Col xs={12} sm={12} md={12}>
                                    <InputLabel style={styles.dropDownFontStyle} >
                                        Account Number
                                    </InputLabel>
                                    <CustomInput
                                        isRequired={true}
                                        id="accountnumber"
                                        name="AccountNumber"
                                        value={this.state.Customer.AccountNumber}
                                        type='number'
                                        showErrorMessage={!this.state.Customer.AccountNumberValid}
                                        errorMessage="Account Number is invalid"
                                        formControlProps={{
                                            fullWidth: true,
                                            disabled: this.state.isProfileSelected,
                                            // disabled: this.state.BalanceDue === "0.00",
                                            onChange: (e) => { this.handleUserInput(e) },
                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                        }}
                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                        labelProps={{ style: { fontWeight: "bold" } }}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <InputLabel style={styles.dropDownFontStyle} > Routing Number</InputLabel>
                                    <CustomInput
                                        isRequired={true}
                                        id="routingnumber"
                                        name="RoutingNumber"
                                        value={this.state.Customer.RoutingNumber}
                                        type="number"
                                        showErrorMessage={!this.state.Customer.RoutingNumberValid}
                                        errorMessage="Routing Number is invalid"
                                        formControlProps={{
                                            fullWidth: true,
                                            disabled: this.state.isProfileSelected,
                                            onChange: (e) => { this.handleUserInput(e) },
                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                        }}
                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                        labelProps={{ style: { fontWeight: "bold" } }}
                                    />
                                </Col>
                            </Row>
                        }
                    </div>

                    {this.renderContactInformation()}
                </div>
        )
    }

    renderForms() {
        const form = this.renderTokenizerUi()
        return form
    }

    renderContactInformation(){
        return (
            <div>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <InputLabel style={styles.dropDownFontStyle}> Address
                    </InputLabel>
                        <CustomInput
                            isRequired={true}
                            id="address"
                            name="Address"
                            value={this.state.Customer.Address}
                            formControlProps={{
                                fullWidth: true,
                                disabled: this.state.isProfileSelected,
                                // disabled: this.state.BalanceDue === "0.00",
                                onChange: (e) => { this.handleUserInput(e) }
                            }}
                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                            labelProps={{ style: { fontWeight: "bold" } }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <InputLabel style={styles.dropDownFontStyle} > City </InputLabel>
                        <CustomInput
                            id="city"
                            name="City"
                            value={this.state.Customer.City}
                            formControlProps={{
                                fullWidth: true,
                                disabled: this.state.isProfileSelected,
                                onChange: (e) => { this.handleUserInput(e) }
                            }}
                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                            labelProps={{ style: { fontWeight: "bold" } }}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <InputLabel style={styles.dropDownFontStyle} > State </InputLabel>
                        <CustomInput
                            id="state"
                            name="State"
                            value={this.state.Customer.State}
                            formControlProps={{
                                fullWidth: true,
                                disabled: this.state.isProfileSelected,
                                onChange: (e) => { this.handleUserInput(e) }
                            }}
                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                            labelProps={{ style: { fontWeight: "bold" } }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <InputLabel style={styles.dropDownFontStyle} > Postal Code </InputLabel>
                        <CustomInput
                            id="postal-code"
                            name="PostalCode"
                            value={this.state.Customer.PostalCode}
                            formControlProps={{
                                fullWidth: true,
                                disabled: this.state.isProfileSelected,
                                onChange: (e) => { this.handleUserInput(e) }
                            }}
                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                            labelProps={{ style: { fontWeight: "bold" } }}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <InputLabel style={styles.dropDownFontStyle} > Country </InputLabel>
                        <CustomInput
                            id="country"
                            name="Country"
                            value={this.state.Customer.Country}
                            formControlProps={{
                                fullWidth: true,
                                disabled: this.state.isProfileSelected,
                                onChange: (e) => { this.handleUserInput(e) }
                            }}
                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                            labelProps={{ style: { fontWeight: "bold" } }}
                        />
                    </Col>
                </Row>
                {/* {this.state.isProfileSelected == false &&                      
                    <div style={{ marginTop: "10px" }}>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <CustomCheckbox
                                    name="CreateProfileCheckBox"
                                    checked={true}
                                    labelText={"Create Profile for Recurring"}
                                    isDisabled={true}
                                    labelProps={{ style: { fontSize: "17px" } }}
                                />
                            </Col>
                        </Row>
                    </div>   
                } */}
            </div>
        )
    }

    configureCollectJS() {
        try{
            window.CollectJS.configure({           
                variant: 'inline',
                styleSniffer: true,
                callback: (token) => {
                    this.finishSubmit(token)
                },
                validationCallback: function (field, status, message) {
                    if (status) {
                        // console.log(document.getElementById(field).value);
                        var message = field + " is now OK 1 : " + message;
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "none";
                                document.getElementById("creditCardErrorMessage").innerHTML = "";
                            break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "none";
                                document.getElementById("expErrorMessage").innerHTML = "";
                            break; 
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "none";
                                document.getElementById("cvvErrorMessage").innerHTML = "";
                            break;    
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "none";
                                document.getElementById("accountNameErrorMessage").innerHTML = "";
                            break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "none";
                                document.getElementById("accountNumberErrorMessage").innerHTML = "";
                            break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "none";
                                document.getElementById("routingNumberErrorMessage").innerHTML = "";
                            break;                    
                        }    
                    } else {
                        var message = field + " is now Invalid : " + message + "  status=" + status;
                    switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                                }
                                else
                                {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Invalid card number";
                                }
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                                }
                                else
                                {
                                    document.getElementById("expErrorMessage").innerHTML = "Invalid expiry date";
                                }                        
                                break;  
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                                }
                                else
                                {
                                    document.getElementById("cvvErrorMessage").innerHTML = "Invalid CVV";
                                }                        
                                break;  
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                                }
                                else
                                {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Invalid account name";
                                }    
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                                }
                                else
                                {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Invalid account number";
                                }    
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "block";
                                if(message.indexOf("empty") > -1)
                                {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                                }
                                else
                                {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Invalid routing number";
                                }    
                                break;
                                break;                                                             
                    }
                    }
                    console.log(message);
                },
                invalidCss: {
                    'border-bottom-color': 'red',
                    'border-width': '2px'
                },
                customCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#AAAAAA',
                    'border-style': 'solid',
                    'border-width': '1px',
                    'font-family': 'sans-serif',
                    'font-size': '18px'
                },
                placeholderCss: {
                    "color": "#AAAAAA",
                    "font-weight": "bold",
                    "font-size": "16px"
                },
                focusCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#0075c1',
                    'border-width': '2px'
                },            
                fields: {
                    ccnumber: {
                        selector: '#ccnumber',
                    },
                    ccexp: {
                        selector: '#ccexp'
                    },
                    cvv: {
                        selector: '#cvv',
                    },
                    checkaccount: {
                        selector: "#accountnumber",
                    },
                    checkaba: {
                        selector: "#routingnumber",
                    },
                    checkname: {
                        selector: "#accountname",
                    }
                }
            });
        }catch (error) {
            this._getActiveProcessorRequest();
        }
    }

    loadNMIScriptTag(tokenizationKey){
        let script = document.createElement("script");
        script.src = "https://secure.networkmerchants.com/token/Collect.js"; 
        script.async = true;
        script.setAttribute(
            "data-tokenization-key", 
            tokenizationKey
        );
        script.setAttribute(
            "id",
            tokenizationKey
        );
        script.onload = () => {
            this.configureCollectJS();
        }
        document.head.appendChild(script);
    }

    finishSubmit(response) {        
        const { isSubmitting, ...formData } = { ...this.state };
        formData.token = response.token;
        console.log(formData);

        this.handlePrfileCreate(response);
    }

    handlePrfileCreate = (value) => { 
        const CustomerInfo = this.state.Customer;
        let cardInfo = null;
        let achInfo = null;
        if (this.state.IsCreditCard || this.state.IsDebitCard) {
            cardInfo = {
                cardType: "",
                expDate:"",
                cardHolderName: CustomerInfo.CardHolderName,
                last4digit : this.state.processorType == "nmi" ? value.card.number.substring(value.card.number.length-4) : this.state.emvToken.substring(this.state.emvToken.length-4)
            }
        } else if (this.state.IsACH) {
            achInfo = {
                accountType: CustomerInfo.AccountType,
                secCode: CustomerInfo.AccountHolderType.value == "personal" ? "PPD" : "CCD",
                accountName: CustomerInfo.AccountName,
                accountNumber: CustomerInfo.AccountNumber,
                routingNumber: CustomerInfo.RoutingNumber,
                cardType: "ECHK"
            }
        }
        let profileInfo = {
            cardSecureToken: this.state.processorType == "nmi" ? value.token : this.state.emvToken,
            profileData: "",
            realmId: "",
            isCreateProfile: true,                
            achInfo: achInfo,
            cardInfo: cardInfo,
            customerRef:{
                customerId: CustomerInfo.Id,
                customerName: CustomerInfo.CustomerName,
                customerEmail: CustomerInfo.CustomerEmail,
                firstName:  CustomerInfo.FirstName,
                lastName: CustomerInfo.LastName                
            },
            billingInfo:{
                address: CustomerInfo.Address,
                country: CustomerInfo.Country,
                city: CustomerInfo.City,
                state: CustomerInfo.State,
                postalCode: CustomerInfo.PostalCode
            }
        }
        this._postCreateProfileRequest(profileInfo);
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });
        const app_id = 2;
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                if (data != null) {
                    if(data.statuscode === 0) {
                        let processor = data.data.items;           
                        this.setProcessorResult(processor);
                    }

                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            }); 
    }

    setProcessorResult = (processor) => {       
        if (processor.ProcessorType == "nmi"){
           processor.map(data =>{
                const configuration = data.configuration;
                if (configuration != null || configuration != "undefined"){
                    const tokenizationKey = configuration.TokenizationKey;
                    this.loadNMIScriptTag(tokenizationKey);                    
                }else{
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Reseller" });
                }
            });
        }else if(processor.ProcessorType == "cardconnect"){
            processor.map(data => {
                const configuration = data.configuration;
                if (configuration != null || configuration != "undefined") {
                    this.state.copilotURL = configuration.URL;
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Customer Support Team..." });
                }
            });
        }else if(processor.ProcessorType == null || processor.ProcessorType == undefined)
        {
            this.setState({ShowProgressBar: false});
        }               
    }

    disablePastDt= (date) => {
        return date.isAfter(this.state.PastDateMonth); //(moment().subtract(1, 'month'));
    };

    render() {
        return (
            <div className="main-content">
                {this.state.alert}
                <Row>
                    <Col md={12}>
                        
                        <Modal
                            show={this.state.showCreateRecurringModal}
                            onHide={() => this.setState({ showCreateRecurringModal: false })}
                            bsSize="large" 
                            backdrop="static"     
                            //dialogClassName="modal-dialog-email-statistics-custom"
                            >
                            {this.state.ShowPopupProgressBar && 
                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                <Modal.Title style={{ color: "white" }}>Create Recurring {this.state.Customer.CustomerName ? "(" +this.state.Customer.CustomerName+")" : ""}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{paddingTop:"0px"}}>
                                <div className="modal-body text-left" >     
                                    <div>
                                        {this.state.errorMessage &&
                                            <div>
                                                <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                                    <span>
                                                        {this.state.errorMessage}
                                                    </span>
                                                </Alert>
                                            </div>
                                        }
                                    </div>
                                       <Row>
                                            <Col md={6}>                                                
                                                <Row>                                                    
                                                    <Col md={12}>                                                        
                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                            Template Name
                                                        </InputLabel>
                                                        <CustomInput                                                       
                                                            isRequired={true}
                                                            id="TemplateName"
                                                            name="TemplateName"
                                                            value={this.state.RecurringParams.TemplateName}
                                                            showErrorMessage={!this.state.TemplateNameValid}
                                                            errorMessage="Template name is invalid"
                                                            formControlProps={{
                                                                fullWidth: true,                                           
                                                                error: !this.state.TemplateNameValid,
                                                                onChange: (e) => { this.handleUserInput(e) },
                                                                onBlur: (e) => { this.handleUserInput(e) }
                                                            }}
                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                        />
                                                    </Col>                                                    
                                                </Row>
                                                <Row>
                                                    <Col md={12}>    
                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                            Set how often you want to charge the customer
                                                        </InputLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} style={{ marginLeft: "20px" }}>
                                                        <Radio
                                                            number="dayfrequency"
                                                            option="dayfrequency"
                                                            name="FrequencyRadio"
                                                            onChange={this.handleFrequencyRadio}
                                                            checked={this.state.IsDayFrequency}
                                                            className="PrivateSwitchBase-input-538"
                                                            label="Charge the customer every"
                                                        />
                                                    </Col>
                                                </Row>    
                                                <Row>      
                                                    <Col  xs={1} md={1}>
                                                    </Col>
                                                    <Col  xs={5} md={5}>
                                                        <InputGroup>
                                                            <FormControl
                                                                value={this.state.RecurringParams.day_frequency}
                                                                type="number"
                                                                disabled={!this.state.IsDayFrequency}
                                                                // placeholder="Days"
                                                                name="day_frequency"
                                                                style={{ textAlign: "center" }}
                                                                min="1"
                                                                step="1"
                                                                max="31"
                                                                onChange={event => {
                                                                    var value = event.target.value;
                                                                    if(value > 31 || value < 1){
                                                                        return false
                                                                    }
                                                                    this.setState(prevState => ({
                                                                        ...prevState,
                                                                        RecurringParams: {
                                                                            ...prevState.RecurringParams,
                                                                            day_frequency: value
                                                                        }
                                                                    }));
                                                                }
                                                                }
                                                            />
                                                            <InputGroup.Addon>Days</InputGroup.Addon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} style={{ marginLeft: "20px" }}>
                                                        <Radio
                                                            number="monthfrequency"
                                                            option="monthfrequency"
                                                            name="FrequencyRadio"
                                                            //disabled={true}
                                                            onChange={this.handleFrequencyRadio}
                                                            checked={this.state.IsMonthFrequency}
                                                            label="Charge the customer on day"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={1} md={1}>
                                                    </Col>
                                                    <Col xs={5} md={5}>
                                                        <Row>
                                                            <Col  xs={12} md={12}>
                                                                <InputGroup>
                                                                    <FormControl
                                                                        value={this.state.RecurringParams.day_of_month}
                                                                        type="number"
                                                                        disabled={!this.state.IsMonthFrequency}
                                                                        name="day_of_month"
                                                                        style={{ textAlign: "center" }}
                                                                        min="1"
                                                                        step="1"
                                                                        max="31"
                                                                        onChange={event => {
                                                                            var value = event.target.value;
                                                                            if(value > 31 || value < 1){
                                                                                return false
                                                                            }
                                                                            this.setState(prevState => ({
                                                                                ...prevState,
                                                                                RecurringParams: {
                                                                                    ...prevState.RecurringParams,
                                                                                    day_of_month: value
                                                                                }
                                                                            }));
                                                                        }
                                                                        }
                                                                    />
                                                                    <InputGroup.Addon>Of Every</InputGroup.Addon>
                                                                </InputGroup>
                                                            </Col>
                                                           
                                                        </Row>
                                                    </Col>

                                                    <Col xs={5} md={5}>
                                                        <Row>
                                                            <Col xs={12} md={12}>
                                                                <InputGroup>
                                                                    <FormControl
                                                                        value={this.state.RecurringParams.month_frequency}
                                                                        type="number"
                                                                        disabled={!this.state.IsMonthFrequency}
                                                                        // placeholder="Days"
                                                                        name="month_frequency"
                                                                        style={{ textAlign: "center" }}
                                                                        min="1"
                                                                        step="1"
                                                                        max="12"
                                                                        onChange={event => {
                                                                            var value = event.target.value;
                                                                            if(value > 12 || value < 1){
                                                                                return false
                                                                            }
                                                                            this.setState(prevState => ({
                                                                                ...prevState,
                                                                                RecurringParams: {
                                                                                    ...prevState.RecurringParams,
                                                                                    month_frequency: value
                                                                                }
                                                                            }));
                                                                        }
                                                                        }
                                                                    />
                                                                    <InputGroup.Addon>Month(s)</InputGroup.Addon>
                                                                </InputGroup>
                                                            </Col>                                                            
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col md={4}>
                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                            Start Date 
                                                        </InputLabel>
                                                    </Col>
                                                    <Col md={7}>
                                                    <InputGroup>                                                       
                                                        <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                                        <Datetime
                                                            id="recurringStartDate"
                                                          //  dateFormat="MM-DD-YYYY"
                                                            timeFormat={false}
                                                            disabled={this.state.ShowProgressBar}
                                                            isValidDate={this.disablePastDt}
                                                            closeOnSelect={true}
                                                            minDate={new Date()}
                                                            className="react-calendar"
                                                            inputProps={{ placeholder: "Start Date" }}
                                                            onChange={this.recurringStartDateHandler}
                                                            value={this.state.RecurringStartDate}                                                            
                                                        />                                                         
                                                        <InputGroup.Addon></InputGroup.Addon>
                                                    </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col  xs={12} md={12}>    
                                                        <InputLabel style={styles.dropDownFontStyle}>
                                                        Set how many times you wish to charge the customer?
                                                        </InputLabel>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12} style={{ marginLeft: "20px" }}>
                                                        <Radio
                                                            number="chargeuntilcanceled"
                                                            option="chargeuntilcanceled"
                                                            name="ChargeTimeRadio"
                                                            onChange={this.handleChargeTimeRadio}
                                                            checked={this.state.IsChargeUntilCanceled}
                                                            className="PrivateSwitchBase-input-538"
                                                            label="Charge until canceled"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6} md={6} style={{ marginLeft: "20px" }}>
                                                        <Radio
                                                            number="chargethecustomer"
                                                            option="chargethecustomer"
                                                            name="ChargeTimeRadio"
                                                            onChange={this.handleChargeTimeRadio}
                                                            checked={this.state.IsChargeTheCustomer}
                                                            className="PrivateSwitchBase-input-538"
                                                            label="Charge the customer"
                                                        />
                                                    </Col>
                                                   
                                                    <Col xs={5} md={5}>
                                                        <InputGroup>
                                                            <FormControl
                                                                value={this.state.RecurringParams.occurrence}
                                                                type="number"
                                                                disabled={!this.state.IsChargeTheCustomer}
                                                                // placeholder="Days"
                                                                name="occurrence"
                                                                style={{ textAlign: "center" }}
                                                                min="1"
                                                                step="1"
                                                                onChange={event => {
                                                                    var value = event.target.value;
                                                                    if(value > 9999 || value < 1){
                                                                        return false
                                                                    }
                                                                    this.setState(prevState => ({
                                                                        ...prevState,
                                                                        RecurringParams: {
                                                                            ...prevState.RecurringParams,
                                                                            occurrence: value
                                                                        }
                                                                    }));
                                                                }
                                                                }
                                                            />
                                                            <InputGroup.Addon>Times</InputGroup.Addon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>                                                
                                            </Col>
                                            <Col md={6}>
                                            <div>
                                            <div>
                                                <Row>
                                                    <div className="text-center">
                                                        <ButtonGroup>
                                                            {this.state.IsCreditCardEnabled &&
                                                                <Button bsStyle={this.state.groupButtonCreditCardStyle} name="creditcardrecurring" onClick={e => this.handleRadio(e)}  fill={this.state.IsCreditCard} wd disabled={this.state.isPaid}>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-credit-card" />
                                                                    </span>
                                                                    Credit Card
                                                                </Button>
                                                            }
                                                            {this.state.IsDebitCardEnabled &&
                                                                <Button bsStyle={this.state.groupButtonDebitCardStyle} name="debitcardrecurring" onClick={e => this.handleRadio(e)} fill={this.state.IsDebitCard} wd disabled={this.state.isPaid}> 
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-credit-card-alt" />
                                                                    </span>Debit Card
                                                                </Button>
                                                            }
                                                            {this.state.IsACHEnabled && 
                                                                <Button bsStyle={this.state.groupButtonACHStyle} name="achrecurring" onClick={e => this.handleRadio(e)} fill={this.state.IsACH} wd disabled={this.state.isPaid}>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-bank" />
                                                                    </span>Bank/ACH
                                                                </Button>
                                                            }
                                                        </ButtonGroup>
                                                    </div>                                                    
                                                </Row>
                                                <br />
                                            </div>
                                            {this.state.IsCreditCard && this.state.ConvFee != "" && 
                                                <Row>
                                                    <Col xs={12} sm={12} md={12}>
                                                        <InputLabel style={{ color:"black", fontSize: 17, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11}}>
                                                            {this.state.ConvFee.name} &nbsp;&nbsp; {this.state.ConvFee.is_percentage == false && "$"}{parseFloat(this.state.ConvFee.value).toFixed(2)}{this.state.ConvFee.is_percentage == true && "%"}
                                                        </InputLabel>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                                {/* {this.state.IsCreditCardEnabled && this.state.IsACHEnabled && 
                                                <div>
                                                    <Row>                                                        
                                                        <Col xs={3} md={4}>                                            
                                                                <img src={this.state.paymentIcon} width="70px" height="42px" alt="..." />                                            
                                                        </Col>                                        
                                                        <Col xs={4} md={4} style={{textAlign: "right"}}>
                                                            <Radio
                                                                number="creditcardrecurring"
                                                                option="creditcardrecurring"                                                                
                                                                name="radio1"
                                                                onChange={this.handleRadio}
                                                                checked={this.state.IsCreditCard}
                                                                className="PrivateSwitchBase-input-538"
                                                                label="Credit Card"
                                                            />
                                                        </Col>
                                                        <Col xs={5} md={4}>
                                                            <Radio
                                                                number="achrecurring"
                                                                option="achrecurring"
                                                                name="radio1"
                                                                onChange={this.handleRadio}
                                                                checked={this.state.IsACH}
                                                                label="eCheck (ACH)"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div>
                                                } */}
                                                {this.state.processorType == "cardconnect" && this.renderForms()}
                                                {this.state.processorType == "nmi" && 
                                                    <form onSubmit={this.createRecurringSubmit}>  
                                                        {this.state.ccProfiles.length > 2 && (this.state.IsCreditCard || this.state.IsDebitCard) && 
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle}>
                                                                        Select Profile
                                                                    </InputLabel>
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        name="ccProfiles"
                                                                        isDisabled={this.state.ShowProgressBar}
                                                                        value={this.state.Customer.ProfileId}
                                                                        onChange={profile => {
                                                                            this.handleProfileChanged(profile)
                                                                            this.setState(prevState => ({
                                                                                Customer:
                                                                                {
                                                                                    ...prevState.Customer, ProfileId: profile
                                                                                }
                                                                            }));
                                                                        }
                                                                        }
                                                                        options={this.state.ccProfiles}
                                                                        placeholder="Select Profile"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }  
                                                        {this.state.achProfiles.length > 2 && this.state.IsACH &&
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle}>
                                                                        Select Profile
                                                                    </InputLabel>
                                                                    <Select
                                                                        classNamePrefix="react-select"
                                                                        name="achProfiles"
                                                                        isDisabled={this.state.ShowProgressBar}
                                                                        value={this.state.Customer.ProfileId}
                                                                        onChange={profile => {
                                                                            this.handleProfileChanged(profile)
                                                                            this.setState(prevState => ({
                                                                                Customer:
                                                                                {
                                                                                    ...prevState.Customer, ProfileId: profile
                                                                                }
                                                                            }));
                                                                        }
                                                                        }
                                                                        options={this.state.achProfiles}
                                                                        placeholder="Select Profile"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        }                                                    
                                                        <Row>
                                                            <Col xs={12} sm={12} md={6}>
                                                                <InputLabel style={styles.dropDownFontStyle}>
                                                                    First Name
                                                                </InputLabel>
                                                                <CustomInput                                                                  
                                                                        isRequired={true}
                                                                        id="FirstName"
                                                                        name="FirstName"
                                                                        value={this.state.Customer.FirstName}
                                                                        showErrorMessage={!this.state.FirstNameValid}
                                                                        errorMessage="First name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isProfileSelected, 
                                                                            error: !this.state.FirstNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={6}>
                                                                <InputLabel style={styles.dropDownFontStyle} >
                                                                        Last Name
                                                                </InputLabel>
                                                                <CustomInput                                                                  
                                                                        isRequired={true}
                                                                        id="LastName"
                                                                        name="LastName"
                                                                        value={this.state.Customer.LastName}
                                                                        showErrorMessage={!this.state.LastNameValid}
                                                                        errorMessage="Last name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,                                                                            
                                                                            disabled: this.state.isProfileSelected,                                            
                                                                            error: !this.state.LastNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                            </Col>
                                                        </Row>
                                                        <div style={{ display: this.state.IsCreditCard || this.state.IsDebitCard ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} >
                                                                    <InputLabel style={styles.dropDownFontStyle} >
                                                                        Card Number
                                                                    </InputLabel>
                                                                    <div id="ccnumber"/>
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="creditCardErrorMessage" id="creditCardErrorMessage">Card number is required                                                                                                                                    
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel style={styles.dropDownFontStyle} >
                                                                        MM/YY
                                                                    </InputLabel>
                                                                    <div id="ccexp" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="expErrorMessage" id="expErrorMessage">Expiry date is required                                                                                                                                    
                                                                    </div>                                                                 
                                                                </Col>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel htmlFor="simple-select" style={styles.dropDownFontStyle} >
                                                                        CVV
                                                                    </InputLabel>
                                                                    <div id="cvv" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="cvvErrorMessage" id="cvvErrorMessage">CVV date is required                                                                                                                                  
                                                                    </div>                                                                     
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle} >
                                                                        Account Name
                                                                    </InputLabel>
                                                                    <div id="accountname" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="accountNameErrorMessage" id="accountNameErrorMessage">Account name is required                                                                                                                                  
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle} >
                                                                        Account Number
                                                                    </InputLabel>
                                                                    <div id="accountnumber" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="accountNumberErrorMessage" id="accountNumberErrorMessage">Account number is required                                                                                                                                  
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel style={styles.dropDownFontStyle} >
                                                                        Routing Number
                                                                    </InputLabel>
                                                                    <div id="routingnumber" />
                                                                    <div style={{display:"none", color:"red", fontSize:"12px" }} name="routingNumberErrorMessage" id="routingNumberErrorMessage">Routing number is required                                                                                                                                  
                                                                    </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <InputLabel htmlFor="simple-select" style={styles.dropDownFontStyle}>
                                                                            Account Type
                                                                        </InputLabel>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="accounts"
                                                                            isDisabled={this.state.ShowProgressBar}
                                                                            value={this.state.Customer.AccountHolderType}
                                                                            onChange={value =>
                                                                                this.setState(prevState => ({
                                                                                    Customer: {
                                                                                        ...prevState.Customer,
                                                                                        AccountHolderType: value
                                                                                    }
                                                                                }))
                                                                            }
                                                                            options={this.state.accounts}
                                                                            placeholder="Account Type"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {this.renderContactInformation()}
                                                        <br/><br/><br/><br/>
                                                        <Modal.Footer>
                                                            <Row>
                                                                <Col xs={4} sm={4} md={4}>  
                                                                    <Button bsStyle="primary" style={{ marginLeft: "-8px" }} wd onClick={() => this.handlerCloseModal()} disabled={this.state.isCreatingRecurring}>Close</Button>
                                                                </Col>
                                                                <Col xs={8} sm={8} md={8}>  
                                                                    <Button bsStyle="primary" style={{ width: "100%",  marginLeft: "8px" }} fill type="submit"
                                                                        disabled={this.state.ShowProgressBar}
                                                                    >
                                                                    {this.state.isProfileSelected ? "Save Recurring": "Create Profile & Save Recurring"}
                                                                    </Button> 
                                                                </Col>
                                                            </Row>
                                                        </Modal.Footer>
                                                    </form> 
                                                }
                                            </Col>
                                        </Row>                                                       
                                </div>
                            </Modal.Body>
                            {this.state.processorType == "cardconnect" &&
                                <Modal.Footer>
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.handlerCloseModal()} disabled={this.state.isCreatingRecurring}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.createRecurringSubmit(e)}
                                    disabled={this.state.isProfileSelected && this.state.FormValid ? false : !this.state.FormValid || this.state.emvToken == "" || this.state.ShowProgressBar}
                                    >
                                        {this.state.isProfileSelected ? "Save Recurring": "Create Profile & Save Recurring"}
                                    </Button>                                   
                                </Modal.Footer>
                            }
                        </Modal>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Recurring;