import React from 'react';
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import Button from "components/CustomButton/CustomButton.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import creditCardIcon from "assets/img/icons/creditcard.png";
import achIcon from "assets/img/icons/ach.png";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import {  StorageEnum } from "utils/constant.js";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import paidimage from "assets/img/paidimage.png";
import SessionTimeout from 'components/SessionTimeout';
import { Row, Col, FormControl, ButtonGroup } from "react-bootstrap";


const styles = {
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
        color: "green",//successColor[2], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    errorResponse: {
        color: "red",//dangerColor[0], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    watermark: {
        content: "",
        backgroundImage: `url(${paidimage})`,
        opacity: "0.2",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        position: "absolute"
    },
}


class NMIPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Customer: {
                CardHolderName: '',
                firstName: '',
                lastName: '',
                CardNumber: '',
                CardExpiry: '',
                GatewayExpiryDate: '',
                CardCVV: '',
                AccountNumber: '',
                AccountType: 'checking',
                RoutingNumber: '',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: true,
                CardHolderNameValid: true,
                firstNameValid: true,
                lastNameValid: true,
                AccountNameValid: true,
                AccountNumberValid: true,
                RoutingNumberValid: true,
                CardNumberValid: true,
                CardExpiryValid: true,
                CardCVVValid: true,
                AmountValid: true,
                FormErrors: { firstName: '', lastName: '', CardHolderName: '', AccountName: '' },
            },
            Invoice: [],
            invoiceNumber: "",
            customerName: "",
            invoiceDate: "",
            dueDate: "",
            Amount: 0.00,
            totalAmount: 0.00,
            amountToCharge: 0.00,
            remainingBalance: 0.00,
            customerEmail: "",
            invoiceData: [],
            paymentIcon: creditCardIcon,
            IsCreditCard: true,
            IsACH: false,
            isSubmitting: false,
            StatusCode: false,
            StatusMessage: "",
            isPaid: false,
            profiles: [],
            achProfiles: [],
            isProfileSelected: false,
            isAuthenticated: true,
            ConvFee: "",
            ConvFeeAmount: 0.00,
            groupButtonCreditCardStyle: "primary active",
            groupButtonDebitCardStyle: "default",
            groupButtonACHStyle: "default",
            lastState: "creditcard",
        }
        this.handleCharged = this.handleCharged.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        if (localStorage.getItem(StorageEnum.PPBUNDLE) == null || localStorage.getItem(StorageEnum.PPBUNDLE) === "null") {
            this._getActiveProcessorRequest();
        } else {
            const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
            if (document.getElementById(data_tokenize.PPToken)) {
                this.configureCollectJS();
                console.log("tokenization key exists : " + data_tokenize.PPToken + "  loading configuration CollectJS");
            }
            else {
                this.loadNMIScriptTag(data_tokenize.PPToken);
            }
            let ach = (localStorage.getItem("is_ach_enabled") == "true")
            let creditcard = (localStorage.getItem("is_creditcard_enabled") == "true")
            this.setState({ IsACH: ach, IsACHEnabled: ach })
            this.setState({ IsCreditCard: creditcard, IsCreditCardEnabled: creditcard })
            if (ach && creditcard) {
                this.setState({ IsACH: false })
            }
        }

        this.state.invoiceData = this.props.location.state ? this.props.location.state.invoiceData : JSON.parse(localStorage.getItem("Invoice"));
        
        if (this.state.invoiceData !== null) {            
            this.setState({ Amount: this.state.invoiceData.Balance });
            this.setState({ totalAmount: this.state.invoiceData.Balance });
            this.setState({ amountToCharge: parseFloat(this.state.invoiceData.Balance).toFixed(2) });
            this.setState({ customerEmail: this.state.invoiceData.BillEmail ? this.state.invoiceData.BillEmail.Address : "" });
            this.setState({ invoiceNumber: this.state.invoiceData.DocNumber });
            this.setState({ customerName: this.state.invoiceData.CustomerRef.name });
            this.setState({ invoiceDate: this.state.invoiceData.TxnDate });
            this.setState({ dueDate: this.state.invoiceData.DueDate });        
            let ach1 = this.state.invoiceData.AllowOnlineACHPayment;
            let creditCard1 = this.state.invoiceData.AllowOnlineCreditCardPayment;
            if(ach1 == true || creditCard1 == true){
                if(this.state.IsACHEnabled && (this.state.IsCreditCardEnabled || this.state.IsDebitCardEnabled)){
                    this.setState({IsACHEnabled: ach1, IsCreditCardEnabled: creditCard1,
                        IsDebitCardEnabled: creditCard1});
                }
                if(creditCard1 == false)
                {
                    this.setState({groupButtonCreditCardStyle: "default"})
                    this.setState({groupButtonACHStyle: "primary active"})
                    this.setState({IsACH: ach1 })
                }   
            }
            global.InvoiceAmount = parseFloat(this.state.invoiceData.Balance).toFixed(2);
            var custNameArr = this.state.invoiceData.CustomerRef.name.split(' ');
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, CardHolderName: this.state.invoiceData.CustomerRef.name,
                    Address: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.Line1 : "",
                    Country: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.Country : "",
                    State: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.CountrySubDivisionCode : "",
                    City: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.City : "",
                    PostalCode: (this.state.invoiceData.BillAddr !== "" && this.state.invoiceData.BillAddr !== null) ? this.state.invoiceData.BillAddr.PostalCode : "",
                    ProfileId: {value: "none", label: "None"},
                }
            }));
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, firstName: custNameArr[0]
                }
            }));
            if (custNameArr.length > 1) {
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, lastName: custNameArr[custNameArr.length - 1]
                    }
                }));
            }

            this.GetConvFee(); 
            this.getPaymentProfiles(this.state.invoiceData.CustomerRef.value);
        }
        else
        {
             this.props.history.push('/invoice/invoices');
        }
        document.getElementById("loaderDiv").style.display = "none";
        // this._getActiveProcessorRequest();
        // this.state.invoiceData = this.props.location.state ? this.props.location.state.invoiceData : null;
        // console.log(this.state.invoiceData);
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
         const app_id = 2;
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } 
            }).catch(error => {  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
        {
            let convFeeAmount = 0.00;
            if(convfee[0].is_percentage){
                convFeeAmount = (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) 
                this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) })
            }
            else{
                convFeeAmount =  parseFloat(convfee[0].value)
                this.setState({ ConvFeeAmount : parseFloat(convfee[0].value)});
            }            
            this.setState({ ConvFee: convfee[0] }); 
            
            global.InvoiceAmount = parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2);
            this.setState({ Amount: parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2) });

            this.setState({ totalAmount: parseFloat(parseFloat(this.state.invoiceData.Balance ) + convFeeAmount).toFixed(2) });
            this.setState({ amountToCharge: parseFloat(parseFloat(this.state.invoiceData.Balance ) + convFeeAmount).toFixed(2) });

        }
        this.setState({ showLoader: false });   
    }
    
    handleRateKeyPress = (e) => {
        var charCode = (e.which) ? e.which : e.keyCode;        
        this.checkIsNumeric(e);
    };

    checkIsNumeric = (e) => {      
        var charCode = (e.which) ? e.which : e.keyCode;          
        if(e.target.value.indexOf(".") !== -1)
        {
            if (charCode == 46 || e.target.value.length > 16) {
                e.preventDefault();
            }
        }
        else if (charCode != 46 && (!/^\d*$/.test(e.target.value) || e.target.value.length > 16)) {
            e.preventDefault();
        }
    };

    handleAmountChange = e => {
        const { name, value } = e.target;
        if (parseFloat(value) > parseFloat(this.state.totalAmount)) {
            this.state.Customer.AmountValid = false;
            this.validateField(name, value)
            return false;
        }

        if (parseFloat(value) <= 0) {
            this.state.Customer.AmountValid = false;
            this.validateField(name, value)
            //  return false;
        }
        else {
            this.state.Customer.AmountValid = true;
        }

        this.setState(prevState => ({
            ...prevState.Amount, Amount: value
        }), () => { this.validateField(name, value) })

        this.setState({ remainingBalance: parseFloat(this.state.totalAmount) - parseFloat(value) })
        this.setState({ amountToCharge: value });
    };

    handleRadio = event => {
        const target = event.target;
        this.setState({ StatusMessage: "" });
        if (target.name === "creditcard" && this.state.IsCreditCard == false) {
            this.setState({ IsCreditCard: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: true,
                    ProfileId: {label: "None",value: "none"}
                }
            }));
            this.setState({ lastState: target.name});
            global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) + parseFloat(this.state.ConvFeeAmount)).toFixed(2);
            this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.setState({ totalAmount: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.setState({ amountToCharge: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.state.groupButtonCreditCardStyle = "primary active";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "default";
        }
        else if (target.name === "debitcard") {
            this.setState({ IsDebitCard: true });
            this.setState({ IsCreditCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: true,
                    ProfileId: {label: "None",value: "none"}
                }
            }));
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ totalAmount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ amountToCharge: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "primary active";
            this.state.groupButtonACHStyle= "default";
        }
        else if(target.name === "ach") {
            this.setState({ IsACH: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsCreditCard: false });
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ totalAmount: parseFloat(global.InvoiceAmount).toFixed(2) });
                this.setState({ amountToCharge: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            if (this.state.Customer.AmountValid === true) {
                this.setState(prevState => ({
                    Customer: {
                        ...prevState.Customer,
                        FormValid: true,
                        ProfileId: {label: "None",value: "none"}
                    }
                }));
            }
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "primary active";
        }

        this.setState({isProfileSelected: false});           
        this.configureCollectJS();
        if (document.getElementById("creditCardErrorMessage")) {
        document.getElementById("creditCardErrorMessage").style.display = "none";
        }
        if (document.getElementById("expErrorMessage")) {
        document.getElementById("expErrorMessage").style.display = "none";
        }            
        if (document.getElementById("cvvErrorMessage")) {
        document.getElementById("cvvErrorMessage").style.display = "none";
        }
        if (document.getElementById("accountNameErrorMessage")) {
        document.getElementById("accountNameErrorMessage").style.display = "none";
        }
        if (document.getElementById("accountNumberErrorMessage")) {
        document.getElementById("accountNumberErrorMessage").style.display = "none";
        }
        if (document.getElementById("routingNumberErrorMessage")) {
        document.getElementById("routingNumberErrorMessage").style.display = "none";
        }

    };

    handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        //alert(charCode);
        //97-122, 32,65-90'
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };

    handleOnBlurInput(e) {
        console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        this.validateField(e.target.name, e.target.value)
        if (e.target.name === "CardExpiry" && this.state.Customer.CardExpiryValid === true) {
            var dt_year = e.target.value.split("/")[1]; //"20"+
            var dt_month = e.target.value.split("/")[0];

            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, GatewayExpiryDate: dt_year + "-" + dt_month
                }
            }), () => console.log(this.state.Customer.GatewayExpiryDate));
        }
    };

    handleProfileChanged(prof) {
        this.setState({ StatusMessage: "" });
        if (prof.value != "none") {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, IsCreateProfile: false
                }
            }));

            this.setState({ isProfileSelected: true });
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer,
                    ProfileId: prof.value,
                    firstName: prof.first_name,
                    lastName: prof.last_name,
                    CardHolderName: prof.cardholder_name,
                    Address: prof.address,
                    Country: prof.country,
                    State: prof.state,
                    City: prof.city,
                    PostalCode: prof.postal_code,

                }
            }));
        }
        else {
            this.setState({ isProfileSelected: false });
            this.configureCollectJS();

            if (document.getElementById("creditCardErrorMessage")) {
                document.getElementById("creditCardErrorMessage").style.display = "none";
            }
            if (document.getElementById("expErrorMessage")) {
                document.getElementById("expErrorMessage").style.display = "none";
            }
            if (document.getElementById("cvvErrorMessage")) {
                document.getElementById("cvvErrorMessage").style.display = "none";
            }
            if (document.getElementById("accountNameErrorMessage")) {
                document.getElementById("accountNameErrorMessage").style.display = "none";
            }
            if (document.getElementById("accountNumberErrorMessage")) {
                document.getElementById("accountNumberErrorMessage").style.display = "none";
            }
            if (document.getElementById("routingNumberErrorMessage")) {
                document.getElementById("routingNumberErrorMessage").style.display = "none";
            }

        }
    }

    handleUserInput(e) {
        console.log('handleUserInput is calling..' + e.target.name);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'firstName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, firstName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case 'lastName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, lastName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case 'CardHolderName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardHolderName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardExpiry":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardExpiry: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardCVV":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardCVV: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Address: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, PostalCode: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                this.setState(prevState => ({
                    Amount:
                    {
                        ...prevState.Amount, Amount: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CreateProfileCheckBox":
                console.log(e.target.checked);
                var cbValue = e.target.checked;
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, IsCreateProfile: cbValue
                    }
                }));
                break;
            case "AccountName":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, RoutingNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountType":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountType: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            default:
                break;
        }

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.Customer.FormErrors;
        let cardHolderNameValid = this.state.Customer.CardHolderNameValid;
        let firstNameValid = this.state.Customer.firstNameValid;
        let lastNameValid = this.state.Customer.lastNameValid;
        let cardNumberValid = this.state.Customer.CardNumberValid;
        let cardExpiryValid = this.state.Customer.CardExpiryValid;
        let cardCVVValid = this.state.Customer.CardCVVValid;

        let accountNumberValid = this.state.Customer.AccountNumberValid;
        let accountNameValid = this.state.Customer.AccountNameValid;
        let routingNumberValid = this.state.Customer.RoutingNumberValid;
        let amountValid = this.state.Customer.AmountValid;
        switch (fieldName) {
            case 'firstName':
                firstNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.firstName = firstNameValid ? '' : ' is invalid';
                this.state.Customer.firstNameValid = firstNameValid ? true : false;
                break;
            case 'lastName':
                lastNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.lastName = lastNameValid ? '' : ' is invalid';
                this.state.Customer.lastNameValid = lastNameValid ? true : false;
                break;
            case 'CardHolderName':
                cardHolderNameValid = true;//value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.CardHolderName = cardHolderNameValid ? '' : ' is invalid';
                this.state.Customer.CardHolderNameValid = cardHolderNameValid ? true : false;
                break;
            case 'CardNumber':
                //  cardNumberValid = global.isCardNumberValid;
                //  this.state.Customer.CardNumberValid = cardNumberValid ? true : false;
                break;
            case 'CardExpiry':
                // cardExpiryValid = global.isCardExpiryValid;
                //  this.state.Customer.CardExpiryValid = cardExpiryValid ? true : false;
                break;
            case 'CardCVV':
                //   cardCVVValid = global.isCardCVVValid;
                //   this.state.Customer.CardCVVValid = cardCVVValid ? true : false;
                break;
            case 'Amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.Customer.AmountValid = amountValid ? true : false;
                this.state.Amount = value;
                break;
            case 'AccountName':
                accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.Customer.AccountNameValid = accountNameValid ? true : false;
                break;
            case 'AccountNumber':
                accountNumberValid = value.match(/^\d{4,17}$/);
                this.state.Customer.AccountNumberValid = accountNumberValid ? true : false;
                break;
            case 'RoutingNumber':
                routingNumberValid = value.match(/^\d{9}$/);
                this.state.Customer.RoutingNumberValid = routingNumberValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            CardHolderNameValid: cardHolderNameValid,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            CardNumberValid: cardNumberValid,
            CardExpiryValid: cardExpiryValid,
            CardCVVValid: cardCVVValid,
            AccountNameValid: accountNameValid,
            AccountNumberValid: accountNumberValid,
            RoutingNumberValid: routingNumberValid,
            AmountValid: amountValid
        }, this.validateForm);

    }

    validateForm() {
        //console.log(this.state.Customer.CardNumberValid);
        if (this.state.IsCreditCard || this.state.IsDebitCard) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: //this.state.IsCreditCard && this.state.Customer.CardCVVValid && this.state.Customer.CardExpiryValid && 
                        this.state.Customer.CardHolderNameValid && this.state.Customer.CardNumberValid && this.state.Customer.AmountValid
                        && this.state.Customer.firstNameValid && this.state.Customer.lastNameValid 
                }
            }), () => console.log())
        }
        else if (this.state.IsACH) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: this.state.Customer.AccountNameValid && this.state.Customer.AccountNumberValid
                        && this.state.Customer.firstNameValid && this.state.Customer.lastNameValid
                        && this.state.Customer.AmountValid && this.state.Customer.RoutingNumberValid
                }
            }), () => console.log())
        }
    }


    handleCancelPayment(e) {
        this.props.history.push('/invoice/invoices');
        e.preventDefault();
    };


    configureCollectJS() {
        try {
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                callback: (token) => {
                    this.finishSubmit(token);                     
                },
                validationCallback: function (field, status, message) {
                    if (status) {
                        // console.log(document.getElementById(field).value);
                        var message = field + " is now OK 1 : " + message;
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "none";
                                document.getElementById("creditCardErrorMessage").innerHTML = "";
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "none";
                                document.getElementById("expErrorMessage").innerHTML = "";
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "none";
                                document.getElementById("cvvErrorMessage").innerHTML = "";
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "none";
                                document.getElementById("accountNameErrorMessage").innerHTML = "";
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "none";
                                document.getElementById("accountNumberErrorMessage").innerHTML = "";
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "none";
                                document.getElementById("routingNumberErrorMessage").innerHTML = "";
                                break;
                        }                        
                    } else {
                        var message = field + " is now Invalid : " + message + "  status=" + status;
                        //  document.getElementById(field).classList.add("invalidCss");
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                                }
                                else {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Invalid card number";
                                }
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                                }
                                else {
                                    document.getElementById("expErrorMessage").innerHTML = "Invalid expiry date";
                                }
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                                }
                                else {
                                    document.getElementById("cvvErrorMessage").innerHTML = "Invalid CVV";
                                }
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                                }
                                else {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Invalid account name";
                                }
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                                }
                                else {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Invalid account number";
                                }
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                                }
                                else {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Invalid routing number";
                                }
                                break;
                        }
                    }
                    console.log(message);
                },
                // variant: 'inline',
                // googleFont: 'Abel',
                invalidCss: {
                    'border-bottom-color': 'red',
                    'border-width': '2px'
                },
                // validCss: {
                //   color: '#13AA73'
                // },
                customCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#AAAAAA',
                    'border-style': 'solid',
                    'border-width': '1px',
                    'font-family': 'sans-serif',
                    'font-size': '18px'
                },
                placeholderCss: {
                    "color": "#AAAAAA",
                    "font-weight": "bold",
                    "font-size": "16px"
                },
                // placeholderCss:{
                //     'font-family': 'sans-serif',
                //     'font-size':'18px',
                //     'ont-weight':'bold'
                // },
                focusCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#0075c1',
                    // 'border-style': 'solid',
                    'border-width': '2px'
                },
                fields: {
                    ccnumber: {
                        // placeholder: 'Card Number',
                        // title: "Account Number",
                        selector: '#ccnumber',
                        //onChange:(e) => { console.log('a'); }
                    },
                    ccexp: {
                        // placeholder: 'MM/YYYY',
                        selector: '#ccexp'
                    },
                    cvv: {
                        // placeholder: 'CVV',
                        selector: '#cvv',
                        //  display: "show"
                    },
                    checkaccount: {
                        selector: "#accountnumber",
                        // title: "Account Number",
                        // placeholder: "Account Number"
                    },
                    checkaba: {
                        selector: "#routingnumber",
                        //title": "Routing Number",
                        //  placeholder: "Routing Number"
                    },
                    checkname: {
                        selector: "#accountname",
                        //title": "Name on Checking Account",
                        // placeholder: "Account Name"
                    }
                }
            });
        } catch (error) {
            this._getActiveProcessorRequest();
        }
    }

    finishSubmit(response) {
        const { isSubmitting, ...formData } = { ...this.state };
        formData.token = response.token;
        console.log(formData);

        this.handleCharged(response);
        //post this form data to server api....
    }

    handleSubmit = (event) => {
        this.setState({ StatusMessage: "" });
        if (this.state.isProfileSelected) {
            //add api proflie sale here. . .
            this.setState({ ShowProgressBar: true });
            setTimeout(() => {
                this.handleCharged(this.state.Customer.ProfileId);
            }, 500);
            
        }
        else {
            this.validateForm();
            var isCCFormValid = true;
            if (this.state.IsCreditCard || this.state.IsDebitCard) {
                if (document.getElementById("creditCardErrorMessage").innerHTML !== "") {
                    document.getElementById("creditCardErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("expErrorMessage").innerHTML !== "") {
                    document.getElementById("expErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("cvvErrorMessage").innerHTML !== "") {
                    document.getElementById("cvvErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
            }
            else if (this.state.IsACH) {
                if (document.getElementById("accountNameErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNameErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("accountNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNumberErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("routingNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("routingNumberErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
            }
            if (!isCCFormValid || !this.state.Customer.FormValid) {
                event.preventDefault();
                return false;
            }
            else {
                this.setState({ ShowProgressBar: true });
                this.setState({ isSubmitting: true });

                event.preventDefault();
                window.CollectJS.startPaymentRequest();
            }
        }
    }

    handleCharged = (value) => {
        const invoice_info = this.state.invoiceData;
        const payment_info = this.state.Customer;
        const amount = this.state.Amount;

        let cardInfo = null;
        let achInfo = null;
        let profileInfo = {
            "ProfileId": null,
            "CreateProfile": payment_info.IsCreateProfile
        }

        if (this.state.isProfileSelected) {
            profileInfo = {
                "ProfileId": value.value,
                "CreateProfile": payment_info.IsCreateProfile,
                "FirstName": payment_info.firstName,
                "LastName": payment_info.lastName,
                "CardHolder": payment_info.CardHolderName,
                "CardType": value.card_type,
            }
        } else if (this.state.IsCreditCard || this.state.IsDebitCard) {
            cardInfo = {
                "FirstName": payment_info.firstName,
                "LastName": payment_info.lastName,
                "CardHolder": payment_info.CardHolderName,
                "CardNumber": value.card.number,
                "Cvv": null,
                "Month": null,
                "Year": null,
                "CardType": value.card.type,
                "Token": value.token
            }
        } else if (this.state.IsACH) {
            achInfo = {
                "FirstName": payment_info.firstName,
                "LastName": payment_info.lastName,
                "AccountHolder": payment_info.AccountName,
                "AccountNumber": payment_info.AccountNumber,
                "RoutingNumber": payment_info.RoutingNumber,
                "AccountType": payment_info.AccountType,
                "Token": value.token,
                "CardType": "ECHK",
            }
        }

        const data = {
            "InvoiceInfo": {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "payment",
                "Invoice": invoice_info
            },
            "SalesReceiptInfo": {
                "Id": [],
                "DocNumber": [],
                "Type": "salesreceipt"
            },
            "PaymentInfo": {
                "CardInfo": cardInfo,
                "AchInfo": achInfo,
                "ProfileInfo": profileInfo,
                "BillingInfo": {
                    "Country": payment_info.Country,
                    "City": payment_info.City,
                    "State": payment_info.State,
                    "Street": payment_info.Address,
                    "PostalCode": payment_info.PostalCode
                },
                "TransactionInfo": {
                    "TransType": this.state.isProfileSelected ? "profile_sale"  : this.state.IsACH ? "ach_sale" : "sale",
                    "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                    "Amount": amount,
                    "ActionType": "invoice",
                    "CurrencyCode": "USD"
                }
            },
            "CustomerRef": {
                "Name": invoice_info.CustomerRef ? invoice_info.CustomerRef.name : null,
                "Value": invoice_info.CustomerRef ? invoice_info.CustomerRef.value : null,
                "Email": invoice_info.BillEmail ? invoice_info.BillEmail.Address : null
            }
        }

        // data = {
        //     "InvoiceInfo": data['InvoiceInfo'],
        //     "SalesReceiptInfo": data['SalesReceiptInfo'],
        //     "PaymentInfo": data["PaymentInfo"],
        //     "CustomerRef": data["CustomerRef"],
        //     "AcceptPaymentInfo": data["AcceptPaymentInfo"]
        // }
        this._postCollectPaymentRequest(data)
    }

    loadNMIScriptTag(tokenizationKey) {
        let script = document.createElement("script");
        script.src = "https://secure.networkmerchants.com/token/Collect.js";
        script.async = true;
        script.setAttribute(
            "data-tokenization-key",
            tokenizationKey
        );
        script.setAttribute(
            "id",
            tokenizationKey
        );
        script.onload = () => {
            setTimeout(() => {
                this.configureCollectJS();
            }, 5000);
        }
        document.head.appendChild(script);
        // setTimeout(() => {
        //     this.configureCollectJS();
        // }, 1000);  
    }

    setProcessorResult = (processor) => {
        processor.map(data => {
            const configuration = data.configuration;
            if (configuration != null || configuration != "undefined") {
                const tokenizationKey = configuration.TokenizationKey;
                if (document.getElementById(tokenizationKey)) {
                    this.configureCollectJS();
                    console.log("tokenization key exists : " + tokenizationKey + "  loading configuration CollectJS");
                }
                else {
                    this.loadNMIScriptTag(tokenizationKey);
                }
            } else {
                this.setState({ StatusCode: 1 });
                this.setState({ StatusMessage: "Your Processor configuration is not valid. Please contact your Reseller and Support team..." });
            }
        });
    }

    setProfileResult = (result) => {
        let profiles = [{
            value: "",
            label: "Select Proflie",
            isDisabled: true
                },
                {
                 value: "none",
                 label: "None" 
                 }
            ]
        let achProfilesArray = [{
            value: "",
                label: "Select Proflie",
                isDisabled: true 
                },
                {
                 value: "none",
                 label: "None" 
                }
        ]  
        result.map(data => {
            if(data.card_type != "ECHK" && data.card_type != "ACH" && data.card_type != "ECHEK")
            {
            profiles.push({
                value: data.token,
                label: data.last_digits,
                cardholder_name: data.cardholder_name,
                first_name: data.first_name,
                last_name: data.last_name,
                address: data.address,
                country: data.country,
                state: data.state,
                city: data.city,
                postal_code: data.postal,
                card_type: data.card_type
            })
            }
            else
            {
                achProfilesArray.push({
                    value: data.token,
                    label: data.last_digits,
                    cardholder_name: data.cardholder_name,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postal_code: data.postal,
                    card_type: data.card_type
                });
            }
        })
        this.setState({ profiles: profiles });
        this.setState({ achProfiles: achProfilesArray})
    }

    getPaymentProfiles(customer_id) {
        const payload = {
            "customer_id": customer_id,
            "realm_id": null
        }

        this._getProfileByIdRequest(payload)
    }
    /**
     * API CALLING
     */

    _postCollectPaymentRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postCollectPaymentRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });

                if (data != null) {
                    console.log(data.statuscode + '  ' + data.statusmessage)
                    this.setState({ StatusCode: data.statuscode });
                    this.setState({ StatusMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        // this.configureCollectJS();
                        if (!this.state.isProfileSelected) {
                            document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                            document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                            document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                            document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                            document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                            document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                             setTimeout(() => {
                                this.configureCollectJS()
                            }, 1000);
                        }
                        var updatedInvoice = this.state.invoiceData;      
                        updatedInvoice.Balance = parseFloat(this.state.remainingBalance).toFixed(2);                                          
                        if (parseFloat(this.state.remainingBalance).toFixed(2) == "0.00") {
                             this.setState({ Amount: 0.00});
                            this.setState({ isPaid: true });
                            updatedInvoice.IsPaid = true;
                        }
                        else {
                            this.setState({ Amount: parseFloat(this.state.remainingBalance).toFixed(2)});
                            this.setState({ totalAmount: parseFloat(this.state.remainingBalance).toFixed(2) });
                            this.setState({ amountToCharge: parseFloat(this.state.remainingBalance).toFixed(2) });
                            this.setState({ remainingBalance: 0.00 });
                        }                        
                        localStorage.setItem("Invoice", JSON.stringify(updatedInvoice)); 
                    }
                    //  if (data.data != null)
                    //     this.setItemsResult(data.data.items);
                } else {
                    this.setState({ StatusCode: -1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getActiveProcessorRequest = () => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getActiveProcessorRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    // this.setState({ StatusCode: data.statuscode });
                    // this.setState({ StatusMessage: data.statusmessage });
                    if (data.statuscode === 0) {

                        let processor = data.data.items;

                        this.setProcessorResult(processor);
                    }
                    //  if (data.data != null)
                    //     this.setItemsResult(data.data.items);
                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getProfileByIdRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postProfileByIdRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });

                if (data != null) {
                    this.setState({ StatusCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this.setProfileResult(data.data.items);
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }


    render() {
        const invalidCss1 = {
            borderBottomColor: 'red',
            borderWidth: '2px'
        };
        return (
            <div className="ii-main-content">
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Card
                    content={
                        <div>
                            <Row>
                                <Col md={7}>
                                    <Card
                                        content={
                                            <div>
                                                <div style={{ color: "white", backgroundColor: "#0075c1", marginLeft: "-15px", marginRight: "-15px", marginTop: "-15px" }}>
                                                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                                                        <Col xs={12} sm={12} md={7}>
                                                            <h3><strong>Invoice # {this.state.invoiceNumber} </strong> </h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={2}>
                                                            <h3><strong> Amount: </strong></h3>
                                                        </Col>
                                                        <Col style={{ textAlign: "right" }} xs={12} sm={12} md={3}>
                                                            <h3><strong>${parseFloat(this.state.amountToCharge).toFixed(2)}</strong></h3>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <br />
                                                {
                                                    (this.state.isPaid || this.state.invoiceData.IsPaid) &&
                                                    <div style={styles.watermark}>
                                                    </div>
                                                }
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Customer : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.customerName}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Email : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.customerEmail}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Invoice Date : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.invoiceDate}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={3}>
                                                        <h5>Due Date : </h5>
                                                    </Col> <Col xs={12} sm={12} md={9}>
                                                        <h5>{this.state.dueDate}</h5>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <br />
                                                <Row>
                                                    <Col xs={12} sm={12} md={5}>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <h5>Total Amount : </h5>
                                                    </Col> <Col style={{ textAlign: "right", marginLeft: "-25px" }} xs={12} sm={12} md={3}>
                                                        <h5>${parseFloat(this.state.totalAmount).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={5}>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <h5>Amount to Charge :</h5>
                                                    </Col> <Col style={{ textAlign: "right" }} xs={12} sm={12} md={3}>
                                                        <FormControl
                                                            value={this.state.amountToCharge}
                                                            disabled={this.state.isPaid || this.state.invoiceData.IsPaid}
                                                            readOnly={this.state.IsCreditCard && this.state.ConvFee != "" }
                                                            type="number"
                                                            name="amountToCharge"
                                                            min="0.00"
                                                            style={{ textAlign: "right" }}
                                                            step="0.01"
                                                            onChange={this.handleAmountChange}
                                                            onKeyPress= {this.handleRateKeyPress}
                                                            onBlur={event => {
                                                                this.setState({ amountToCharge : parseFloat(this.state.amountToCharge).toFixed(2) });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12} md={5}>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <h5>Remaining Balance :</h5>
                                                    </Col> <Col style={{ textAlign: "right", marginLeft: "-25px" }} xs={12} sm={12} md={3}>
                                                        <h5>${parseFloat(this.state.remainingBalance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>

                                            </div>
                                        }
                                    />
                                </Col>
                                <Col md={5}>
                                    <div>
                                        <Row>
                                            <div className="text-center">
                                                <ButtonGroup>
                                                    {this.state.IsCreditCardEnabled &&
                                                        <Button bsStyle={this.state.groupButtonCreditCardStyle} name="creditcard" onClick={e => this.handleRadio(e)}  fill={this.state.IsCreditCard} wd disabled={this.state.isPaid || this.state.invoiceData.IsPaid}>
                                                            <span className="btn-label" style={{marginRight:"10px"}}>
                                                            <i className="fa fa-credit-card" />
                                                            </span>
                                                            Credit Card
                                                        </Button>
                                                    }
                                                    {this.state.IsCreditCardEnabled &&
                                                        <Button bsStyle={this.state.groupButtonDebitCardStyle} name="debitcard" onClick={e => this.handleRadio(e)} fill={this.state.IsDebitCard} wd disabled={this.state.isPaid || this.state.invoiceData.IsPaid}> 
                                                            <span className="btn-label" style={{marginRight:"10px"}}>
                                                            <i className="fa fa-credit-card-alt" />
                                                            </span>Debit Card
                                                        </Button>
                                                    }
                                                    {this.state.IsACHEnabled && 
                                                        <Button bsStyle={this.state.groupButtonACHStyle} name="ach" onClick={e => this.handleRadio(e)} fill={this.state.IsACH} wd disabled={this.state.isPaid || this.state.invoiceData.IsPaid}>
                                                            <span className="btn-label" style={{marginRight:"10px"}}>
                                                            <i className="fa fa-bank" />
                                                            </span>Bank/ACH
                                                        </Button>
                                                    }
                                                </ButtonGroup>
                                            </div>
                                        </Row>
                                        <br />
                                    </div>
                                    {this.state.IsCreditCard && this.state.ConvFee != "" && 
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <InputLabel style={{ color:"black", fontSize: 17, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11}}>
                                                    {this.state.ConvFee.name} &nbsp;&nbsp; {this.state.ConvFee.is_percentage == false && "$"}{parseFloat(this.state.ConvFee.value).toFixed(2)}{this.state.ConvFee.is_percentage == true && "%"}
                                                </InputLabel>
                                            </Col>
                                        </Row>
                                    }      
                                    <form onSubmit={this.handleSubmit}>
                                        <Card
                                            content={
                                                <div>
                                                    {this.state.ShowProgressBar &&
                                                        <div style={{ marginBottom: "2px" }}>
                                                            <CustomProgressBar></CustomProgressBar>
                                                        </div>

                                                    }
                                                    {this.state.StatusCode == 0 &&
                                                        <p style={styles.successResponse}>{this.state.StatusMessage}</p>
                                                    }
                                                    {this.state.StatusCode != 0 &&
                                                        <p style={styles.errorResponse}>{this.state.StatusMessage}</p>
                                                    }
                                                    {this.state.profiles.length > 2 && (this.state.IsCreditCard || this.state.IsDebitCard) &&
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <InputLabel
                                                                    style={styles.dropDownFontStyle}
                                                                >
                                                                    Select Profile
                                                            </InputLabel>
                                                                <Select
                                                                    //  className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="profiles"
                                                                    isDisabled={this.state.isPaid || this.state.invoiceData.IsPaid || this.state.ShowProgressBar}
                                                                    value={this.state.Customer.ProfileId}
                                                                    onChange={value => {
                                                                        this.handleProfileChanged(value)
                                                                        this.setState(prevState => ({
                                                                            Customer:
                                                                            {
                                                                                ...prevState.Customer, ProfileId: value
                                                                            }
                                                                        }));
                                                                    }
                                                                    }
                                                                    options={this.state.profiles}
                                                                    placeholder="Select Profile"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }
                                                    
                                                    {this.state.achProfiles.length > 2 && this.state.IsACH &&
                                                    <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Select Profile
                                                                </InputLabel>
                                                                <Select
                                                                    //  className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="achProfiles"
                                                                    isDisabled={this.state.ShowProgressBar}
                                                                    value={this.state.Customer.ProfileId}
                                                                    onChange={value => {
                                                                            this.handleProfileChanged(value)
                                                                            this.setState(prevState => ({
                                                                            Customer:
                                                                            {
                                                                                ...prevState.Customer, ProfileId: value
                                                                            }
                                                                        }));
                                                                        }
                                                                    }
                                                                    options={this.state.achProfiles}
                                                                    placeholder="Select Profile"
                                                                />
                                                            </Col>
                                                        </Row> 
                                                    }
                                                    <Row>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                First Name
                                                            </InputLabel>
                                                            <CustomInput
                                                                isRequired={true}
                                                                id="firstName"
                                                                name="firstName"
                                                                value={this.state.Customer.firstName}
                                                                showErrorMessage={!this.state.Customer.firstNameValid}
                                                                errorMessage="First name is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                                    //disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                    error: !this.state.Customer.firstNameValid,
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                Last Name
                                                            </InputLabel>
                                                            <CustomInput
                                                                isRequired={true}
                                                                id="lastName"
                                                                name="lastName"
                                                                value={this.state.Customer.lastName}
                                                                showErrorMessage={!this.state.Customer.lastNameValid}
                                                                errorMessage="Last name is invalid"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                                    //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                    error: !this.state.Customer.lastNameValid,
                                                                    onChange: (e) => { this.handleUserInput(e) },
                                                                    onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                    onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {this.state.isProfileSelected == false &&
                                                        <div style={{ display: this.state.IsACH ? "none" : "block" }}> 
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Card Holder's Name
                                                                </InputLabel>
                                                                    <CustomInput
                                                                        isRequired={true}
                                                                        id="CardHolderName"
                                                                        name="CardHolderName"
                                                                        value={this.state.Customer.CardHolderName}
                                                                        showErrorMessage={!this.state.Customer.CardHolderNameValid}
                                                                        errorMessage="Card holder name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,                                                                            
                                                                            error: !this.state.Customer.CardHolderNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            //onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} >
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Card Number
                                                                                    </InputLabel>
                                                                    <div id="ccnumber" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="creditCardErrorMessage" id="creditCardErrorMessage">Card number is required
                                                                        </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        MM/YY
                                                                                    </InputLabel>
                                                                    <div id="ccexp" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="expErrorMessage" id="expErrorMessage">Expiry date is required
                                                                        </div>
                                                                </Col>
                                                                <Col xs={12} sm={12} md={6}>
                                                                    <InputLabel
                                                                        htmlFor="simple-select"
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        CVV
                                                                                    </InputLabel>
                                                                    <div id="cvv" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="cvvErrorMessage" id="cvvErrorMessage">CVV date is required
                                                                        </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    {this.state.isProfileSelected == false &&
                                                        <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Account Name
                                                                                </InputLabel>
                                                                    <div id="accountname" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNameErrorMessage" id="accountNameErrorMessage">Account name is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Account Number
                                                                                </InputLabel>
                                                                    <div id="accountnumber" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNumberErrorMessage" id="accountNumberErrorMessage">Account number is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        Routing Number
                                                                                </InputLabel>
                                                                    <div id="routingnumber" />
                                                                    <div style={{ display: "none", color: "red", fontSize: "12px" }} name="routingNumberErrorMessage" id="routingNumberErrorMessage">Routing number is required
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                Address
                                                            </InputLabel>
                                                            <CustomInput
                                                                //  labelText="Address"
                                                                isRequired={true}
                                                                id="address"
                                                                name="Address"
                                                                value={this.state.Customer.Address}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                City
                                                                </InputLabel>
                                                            <CustomInput
                                                                //  labelText="City"
                                                                id="city"
                                                                name="City"
                                                                value={this.state.Customer.City}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                                    //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                State
                                                            </InputLabel>
                                                            <CustomInput
                                                                //labelText="State"
                                                                id="state"
                                                                name="State"
                                                                value={this.state.Customer.State}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                Postal Code
                                                                </InputLabel>
                                                            <CustomInput
                                                                // labelText="Postal Code"
                                                                id="postal-code"
                                                                name="PostalCode"
                                                                value={this.state.Customer.PostalCode}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <InputLabel
                                                                style={styles.dropDownFontStyle}
                                                            >
                                                                Country
                                                            </InputLabel>
                                                            <CustomInput
                                                                // labelText="Country"
                                                                id="country"
                                                                name="Country"
                                                                value={this.state.Customer.Country}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    disabled: this.state.isPaid || this.state.invoiceData.IsPaid || this.state.isProfileSelected,
                                                                    // disabled: this.state.BalanceDue === "0.00",
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                                inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                labelProps={{ style: { fontWeight: "bold" } }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {this.state.isProfileSelected == false && !(this.state.isPaid || this.state.invoiceData.IsPaid) &&
                                                        <div style={{ marginTop: "10px" }}>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <CustomCheckbox
                                                                        name="CreateProfileCheckBox"
                                                                        checked={this.state.Customer.IsCreateProfile}
                                                                        isDisabled={this.state.isPaid || this.state.invoiceData.IsPaid}
                                                                        labelText={"Create Profile"}
                                                                        labelProps={{ style: { fontSize: "17px" } }}
                                                                        formControlProps={{
                                                                            onChange: (e) => { this.handleUserInput(e) }
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    
                                                    <Row>
                                                        <br />
                                                        <Col xs={12} sm={12} md={6}>
                                                            {/* onClick={this.handleCharged}  */}
                                                            <Button bsStyle="primary" style={{ width: "100%" }} fill type="submit" disabled={this.state.isProfileSelected && !this.state.isPaid ? false : !this.state.Customer.FormValid || this.state.isSubmitting || this.state.isPaid || this.state.invoiceData.IsPaid || this.state.ShowProgressBar}>
                                                                CHARGE
                                                       </Button>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6}>
                                                            <Button style={{ width: "100%" }} onClick={e => this.handleCancelPayment(e)} disabled={this.state.ShowProgressBar}>
                                                              {
                                                                  this.state.isPaid || this.state.invoiceData.IsPaid ? "Go Back" : "Cancel"
                                                              }  
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            }
                                        />
                                    </form>
                                </Col>


                            </Row>

                        </div>
                    }

                />
            </div>
        )
    }
}

export default NMIPayment;    