/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Transactions from "views/Reseller/Transactions.jsx";
import Merchants from "views/Reseller/Merchants.jsx";
import CreateMerchant from "views/Reseller/CreateMerchant.jsx";
import EditMerchant from "views/Reseller/EditMerchant.jsx";
import Settings from "views/Reseller/Settings.jsx";

var ResellerRoutes = [
  {
    path: "/transactions",
    layout: "/reseller",
    name: "Transactions",
    icon: "fa fa-money",
    component: Transactions
  },
  {
    path: "/merchants",
    layout: "/reseller",
    name: "Merchants",
    icon: "fa fa-users",
    component: Merchants
  },  
  {
    path: "/settings",
    layout: "/reseller",
    name: "Settings",
    icon: "fa fa-gears",
    component: Settings
  },
  {
    path: "/createmerchant",
    layout: "/reseller",
    name: "Merchant Boarding",
    component: CreateMerchant
  },
  {
    path: "/editmerchant",
    layout: "/reseller",
    name: "Edit Merchant",
    component: EditMerchant
  }
];

export default ResellerRoutes;
