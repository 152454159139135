import RequestService from 'services/RequestService'
import { BASE_URL } from "utils/constant.js"

class NetworkService {

    getItemListingRequest(app_id) {
        const url = `${BASE_URL}api/v1/items/filter/`
        return RequestService.getRequest(url, app_id)
    }

    getTermsRequest(app_id){
        const url = `${BASE_URL}api/v1/terms`
        return RequestService.getRequest(url, app_id)
    }
    getConvFeeRequest(app_id){
        const url = `${BASE_URL}api/v1/conveniencefee/`
        return RequestService.getRequest(url, app_id)
    }

    getTaxRateRequest(app_id){
        const url = `${BASE_URL}api/v1/taxrate/readonly`
        return RequestService.getRequest(url, app_id)
    }

    getTaxCodeRequest(app_id){
        const url = `${BASE_URL}api/v1/taxcode`
        return RequestService.getRequest(url, app_id)
    }

    getDefaultTermsRequest(app_id){
        const url = `${BASE_URL}api/v1/terms/default`
        return RequestService.getRequest(url, app_id)
    }

    getPreferencesRequest(app_id){
        const url = `${BASE_URL}api/v1/preferences`
        return RequestService.getRequest(url, app_id)
    }

    getClassesRequest(app_id){
        const url = `${BASE_URL}api/v1/classes`
        return RequestService.getRequest(url, app_id)
    }

    getCustomerRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/customer/filter/?`+ params
        return RequestService.getRequest(url, app_id)
    }

    getAccountRequest(app_id){
        const url = `${BASE_URL}api/v1/account/all`
        return RequestService.getRequest(url, app_id)
    }

    getCategoryTypeItemRequest(app_id){
        const url = `${BASE_URL}api/v1/items/category`
        return RequestService.getRequest(url, app_id)
    }

    getPaymentMethodsRequest(app_id){
        const url = `${BASE_URL}api/v1/paymentmethods/`
        return RequestService.getRequest(url, app_id)
    }

    getActiveProcessorRequest(app_id){
        const url = `${BASE_URL}api/v1/active/processor`
        return RequestService.getRequest(url, app_id)
    }

    getAPCSRequest(app_id){
        const url = `${BASE_URL}api/v1/erp/status`
        return RequestService.getRequest(url, app_id)
    }

    getEmailStatsRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/invoice/dashboard/emailstats/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getPaymentStatsRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/invoice/dashboard/paymentstats/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getInvoicePreviewRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/invoice/preview/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getInvoiceAttachmentRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/invoice/attachment/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getVerifyPaymentKeyRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/verify/token/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getInvoicFilterRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/invoice/filter/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getPaymentListRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/payments/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getProfileListRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/profile/`
        return RequestService.getRequest(url, app_id)
    }

    getRecurringPlanListRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/recurring/plan/?` + params;
        return RequestService.getRequest(url, app_id)
    }

    getRecurringSubscriberListRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/recurring/subscribe/plan/?` + params;
        return RequestService.getRequest(url, app_id)
    }

    getIntuitLoginRequest(){
        const url = `${BASE_URL}api/v1/qbo_sso/connect`;
        return RequestService.getRequest(url, null)
    }

    getMemberPaymentListRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/payment/members/?` + params
        return RequestService.getRequest(url, app_id)
    }

    getMemberInvoicesRequest(app_id, params=""){
        const url = `${BASE_URL}api/v1/invoice/member/?` + params
        return RequestService.getRequest(url, app_id)
    }

    
    isASConnectedRequest(){
        const url = `${BASE_URL}api/v1/erp/my`
        return RequestService.getRequest(url, null)
    }


    postCreateInvoiceRequest(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/create/`
        return RequestService.multipartRequest(url, app_id, body)
    }

    postCreateRecurringRequest(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/createreuccring/`
        return RequestService.postRequest(url, app_id, body)
    }

    postCollectPaymentRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/collect/`
        return RequestService.postRequest(url, app_id, body)
    }

    postVTPaymentRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/virtualterminal/`
        return RequestService.postRequest(url, app_id, body)
    }

    postCreateItemRequest(app_id, body){
        const url = `${BASE_URL}api/v1/items/create/`
        return RequestService.postRequest(url, app_id, body)
    }
    
    postUpdateDeleteItemRequest(app_id, body){
        const url = `${BASE_URL}api/v1/items/update/`
        return RequestService.postRequest(url, app_id, body)
    }

    postDeleteItemRequest(app_id, body){
        const url = `${BASE_URL}api/v1/items/delete/`
        return RequestService.postRequest(url, app_id, body)
    }

    getCustomFieldListingRequest(app_id) {
        const url = `${BASE_URL}api/v1/settings/customfield`
        return RequestService.getRequest(url, app_id)
    }

    postCreateCustomFieldSRequest(app_id, body){
        const url = `${BASE_URL}api/v1/settings/customfield`
        return RequestService.postRequest(url, app_id, body)
    }

    postUpdateNMIItemRequest(app_id, body){
        const url = `${BASE_URL}api/v1/items/update/`
        return RequestService.postRequest(url, app_id, body)
    }

    postCreateCustomerRequest(app_id, body){
        const url = `${BASE_URL}api/v1/customer/create/`
        return RequestService.postRequest(url, app_id, body)
    }

    postUpdateDeleteCustomerRequest(app_id, body){
        const url = `${BASE_URL}api/v1/customer/update/`
        return RequestService.postRequest(url, app_id, body)
    }

    postDeleteNMICustomerRequest(app_id, body){
        const url = `${BASE_URL}api/v1/customer/delete/`
        return RequestService.postRequest(url, app_id, body)
    }

    postVoidPaymentRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/void/`
        return RequestService.postRequest(url, app_id, body)
    }

    postDeactivateRecurringRequest(app_id, body){
        const url = `${BASE_URL}api/v1/recurring/subscribe/plan/delete/`
        return RequestService.postRequest(url, app_id, body)
    }

    postRefundPaymentRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/refund/`
        return RequestService.postRequest(url, app_id, body)
    }

    postEmailInvoiceRequest(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/email/`
        return RequestService.postRequest(url, app_id, body)
    }

    postEmailReceiptRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/receipt/`
        return RequestService.postRequest(url, app_id, body)
    }

    postProfileByIdRequest(app_id, body){
        const url = `${BASE_URL}api/v1/profile/get/`
        return RequestService.postRequest(url, app_id, body)
    }

    postDeleteProfileByIdRequest(app_id, body){
        const url = `${BASE_URL}api/v1/profile/delete/`
        return RequestService.postRequest(url, app_id, body)
    }

    postChangePasswordRequest(body){
        const url = `${BASE_URL}api/v1/account/changepassword/`
        return RequestService.postRequest(url, null, body)
    }

    postForgetPasswordRequest(body){
        const url = `${BASE_URL}api/v1/account/forget/`
        return RequestService.postRequest(url, null, body)
    }

    postNMIInvoiceConnectRequest(){
        const url = `${BASE_URL}api/v1/nmi/connect`
        return RequestService.postRequest(url, null)
    }

    postCreateRecurringPlanRequest(app_id, body){
        const url = `${BASE_URL}api/v1/recurring/plan/create/`
        return RequestService.postRequest(url, app_id, body)
    }

    postDeleteRecurringSubscriberRequest(app_id, body){
        const url = `${BASE_URL}api/v1/recurring/subscribe/plan/delete/`
        return RequestService.postRequest(url, app_id, body)
    }

    postCreateRecurringSubscriberRequest(app_id, body){
        const url = `${BASE_URL}api/v1/recurring/subscribe/plan/create/`
        return RequestService.postRequest(url, app_id, body)
    }

    postProcessBoltRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/bolt/`
        return RequestService.postRequest(url, app_id, body)
    }

    postCustomerSignupRequest(body){
        const url = `${BASE_URL}api/v1/account/customer/signup/`
        return RequestService.postRequest(url, null, body)
    }

    postInvoiceTextSMS(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/sms/`
        return RequestService.postRequest(url, app_id, body)
    }

    postCreatePayemntLinkInvoice(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/createpaymentlink/`
        return RequestService.postRequest(url, app_id, body)
    }

    postInvoiceReminder(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/reminder/`
        return RequestService.postRequest(url, app_id, body)
    }

    postNMITerminalRegister(app_id, body){
        const url = `${BASE_URL}api/v1/nmi/terminal/register`
        return RequestService.postRequest(url, app_id, body)
    }

    postNMITerminalUnRegister(app_id, body){
        const url = `${BASE_URL}api/v1/nmi/terminal/unregister`
        return RequestService.postRequest(url, app_id, body)
    }

    postThreeStepPaymentRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/3stepredirect/`
        return RequestService.postRequest(url, app_id, body)
    }

    postThreeStepTokenVerifyRequest(app_id, body){
        const url = `${BASE_URL}api/v1/nmi/3steps/token_verify`
        return RequestService.postRequest(url, app_id, body)
    }

    postCreateProcessorProfile(app_id, body){
        const url = `${BASE_URL}api/v1/processor/profile-create`
        return RequestService.postRequest(url, app_id, body)
    }

    postCloseInvoiceRequest(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/close/`
        return RequestService.postRequest(url, app_id, body)
    }

    postInvoiceLinkRequest(app_id, body){
        const url = `${BASE_URL}api/v1/invoice/link/`
        return RequestService.postRequest(url, app_id, body)
    }

    postGatewayJSPaymentRequest(app_id, body){
        const url = `${BASE_URL}api/v1/payment/gatewayjs/`
        return RequestService.postRequest(url, app_id, body)
    }
    
    // getTopStories() {
    //     var url = `${BASE_URL}/topstories/v2/technology.json${API_KEY}`
    //     return RequestService.getRequest(url)
    // }

    /** ADMIN API'S */
    getMerchantListingRequest(resellerID){
        const url = `${BASE_URL}admin/v1/merchants/?reseller_id=` + resellerID;
        return RequestService.getRequest(url)
    }

    getMerchantListingForResellerRequest(app_id){
        const url = `${BASE_URL}reseller/v1/merchants/`
        return RequestService.getRequest(url, app_id)
    }

    getResellerListingRequest(app_id){
        const url = `${BASE_URL}admin/v1/resellers/`
        return RequestService.getRequest(url, app_id)
    }

    getResellersDropdownListingRequest(app_id){
        const url = `${BASE_URL}admin/v1/reseller/dropdown/`
        return RequestService.getRequest(url, app_id)
    }

    getMerchantDetailRequest(app_id, mid){
        const url = `${BASE_URL}admin/v1/merchant/${mid}`
        return RequestService.getRequest(url, app_id)
    }

    getResellerDetailRequest(app_id, resellerID){
        const url = `${BASE_URL}admin/v1/reseller/${resellerID}`
        return RequestService.getRequest(url, app_id)
    }

    getAdminPaymentListRequest(params=""){
        const url = `${BASE_URL}admin/v1/payments/?` + params
        return RequestService.getRequest(url, null)
    }

    getResellerPaymentListRequest(params=""){
        //const url = `${BASE_URL}reseller/v1/payments/?` + params
        const url = `${BASE_URL}reseller/v1/payments/?` + params
        return RequestService.getRequest(url, null)
    }

    getSubuserRequest(params=""){
        const url = `${BASE_URL}admin/v1/staff/`+params
        return RequestService.getRequest(url, null)
    }

    postMerchantBoardingRequest(body){
        const url = `${BASE_URL}admin/v1/signup/`
        return RequestService.multipartRequest(url, null, body)
    }

    postMerchantBoardingViaResellerRequest(body){
        const url = `${BASE_URL}reseller/v1/createmerchant/`
        return RequestService.multipartRequest(url, null, body)
    }

    postResellerBoardingRequest(body){
        const url = `${BASE_URL}admin/v1/reseller/signup/`
        return RequestService.multipartRequest(url, null, body)
    }

    postMerchantDetailRequest(body){
        const url = `${BASE_URL}admin/v1/merchant/update/`
        return RequestService.multipartRequest(url, null, body)
    }

    postResellerUpdateRequest(body){
        const url = `${BASE_URL}admin/v1/reseller/update/`
        return RequestService.multipartRequest(url, null, body)
    }

    postMerchantImageRequest(body){
        const url = `${BASE_URL}admin/v1/merchant/image/`
        return RequestService.multipartRequest(url, null, body)
    }

    postMerchantWelcomeEmailRequest(body){
        const url = `${BASE_URL}admin/v1/merchant/email/welcome/`
        return RequestService.postRequest(url, null, body)
    }

    postDefaultPasswordEmailRequest(body){
        const url = `${BASE_URL}admin/v1/merchant/email/password/`
        return RequestService.postRequest(url, null, body)
    }

    postConvFeeCreateRequest(app_id, body){
        const url = `${BASE_URL}api/v1/conveniencefee/`
        return RequestService.postRequest(url, app_id, body)
    }

    postConvFeeDeleteRequest(app_id, body){
        const url = `${BASE_URL}api/v1/conveniencefee/delete/`
        return RequestService.postRequest(url, app_id, body)
    }

    postSubUserRequest(body){
        const url = `${BASE_URL}admin/v1/signup/staff/`
        return RequestService.postRequest(url, null, body)
    }

    postSubUserPermissionsRequest(body){
        const url = `${BASE_URL}admin/v1/staff/permissions/`
        return RequestService.postRequest(url, null, body)
    }

    postSelfMerchantBoardingRequest(body){
        const url = `${BASE_URL}admin/v1/self/signup/`
        return RequestService.multipartRequest(url, null, body)
    }

    postSelfResellerBoardingRequest(body){
        const url = `${BASE_URL}admin/v1/self/resellersignup/`
        return RequestService.multipartRequest(url, null, body)
    }

    postMerchantSettingsRequest(body,app_id=null){        
        const url = `${BASE_URL}api/v1/settings/`
        return RequestService.multipartRequest(url, app_id, body)
    }

    getDependentUsersRequest(app_id){
        const url = `${BASE_URL}api/v1/payment/dependent/user/`
        return RequestService.getRequest(url, app_id)
    }
}

export default new NetworkService()