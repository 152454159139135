import React, { Component } from "react";
import {
    Row,
    Col,
    ButtonGroup,
    InputGroup,
    ControlLabel,
    FormGroup,

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AmountInput from 'utils/Amount_Input.js';
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import InstantInvoiceLoader from "assets/img/icons/ii.gif";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import paidimage from "assets/img/paidimagecp.png";
import NetworkService from "services/NetworkService";
import { StorageEnum } from "utils/constant.js";
import Wizard from 'views/Forms/NMIWizard/Wizard';
import Select from "react-select";
import Autocomplete from "react-google-autocomplete";
import { Input } from "@material-ui/core";

const styles = {
    overlay: {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: "2"
    },
    dropDownFontStyle: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
        // color: "#AAAAAA",
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "0px",
        lineHeight: "1.42857"
    },
    successResponse: {
        color: "green",//successColor[2], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //  marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    errorResponse: {
        color: "red",//dangerColor[0], 
        fontSize: "17px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        //   marginTop: "17px",
        marginBottom: "0px",
        lineHeight: "1.8",
    },
    watermark: {
        content: "",
        backgroundImage: `url(${paidimage})`,
        opacity: "0.2",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        position: "absolute"
    },
    amountLabel: {
        // fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginTop: "20px",
        minHeight: "auto",
        marginBottom: "0px",
    },
}

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Customer: {
                CardHolderName: '',
                firstName: '',
                lastName: '',
                CardNumber: '',
                CardExpiry: '',
                GatewayExpiryDate: '',
                CardCVV: '',
                AccountNumber: '',
                AccountType: 'checking',
                AccountHolderType: {value: "personal", label: "Personal"},  
                // SecCode: {value: "PPD", label: "PPD"},
                RoutingNumber: '',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: false,
                firstNameValid: true,
                lastNameValid: true,
                AccountNameValid: true,
                AccountNumberValid: true,
                RoutingNumberValid: true,
                CardNumberValid: true,
                CardExpiryValid: true,
                CardCVVValid: true,
                AmountValid: true,
                CheckBoxChecked: false,
                FormErrors: { firstName: '', lastName: '', AccountName: '' },
                IsPostalCodeAvailable: true,
                IsCityAvailable: true,
            },
            Amount: 0.00,
            remainingBalance: 0.00,
            NMI: true, 
            CardConnect: false,
            AuthorizeNet: false,
            ShowProgressBar: false,
            InvoiceRawHtml: null,
            Invoice: [],
            IsCreditCard: true,
            IsDebitCard: false,
            IsACH: false,
            IsCreditCardEnabled: true,
            IsDebitCardEnabled: true,
            IsACHEnabled: true,
            showHTMLDiv: true,
            URLToken: "",
            showLoader: true,
            IsThreeStepEnabled: false,
            IsThreeStepAuthentication: false,
            IsGatewayJSEnable: false,
            IsThreeDSecureInterface: false,
            threeStepForm: {
                formUrl: '',
                tokenId: '',
                redirectUrl: '',
                step1Completed: false,
                step2Completed: false,
                step3Completed: false,
                remainingBalance: 0.00,
                
            },
            description: '',
            collectCheckoutKey: "",
            ConvFee: "",
            ConvFeeAmount: 0.00,
            groupButtonCreditCardStyle: "primary active",
            groupButtonDebitCardStyle: "default",
            groupButtonACHStyle: "default",
            lastState: "creditcard",
            accounts:[],
            secCodes:[]
        }
        this.handleCharged = this.handleCharged.bind(this);
    }

    componentDidMount() {
        this.state.URLToken = this.extractTokenFromUrl();
        this.getPaymentKeyDetail(this.state.URLToken);                        
        this.ShowGatewayJSForm();
        this.setAccountsAndSecCodes();
    }

    setAccountsAndSecCodes() {                   
        const accountState = [];
        accountState.push({value: "", label: "Select Account", isDisabled: true});
        accountState.push({value: "personal", label: "Personal"});
        accountState.push({value: "business", label: "Business"});
       // newState.push({value: "businessChecking", label: "Business Checking"});        
        this.setState({ accounts: accountState });        

        const secCodeState = [];
        secCodeState.push({value: "", label: "Select Sec Code", isDisabled: true});
        secCodeState.push({value: "PPD", label: "PPD"});
       // secCodeState.push({value: "WEB", label: "WEB"});
       // secCodeState.push({value: "TEL", label: "TEL"});
        secCodeState.push({value: "CCD", label: "CCD"});
        this.setState({ secCodes: secCodeState });         
    }

    GetConvFee(){
        this.setState({ errorMessage: "" });
         const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.getConvFeeRequest(app_id)
            .then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.data != null)
                        this.setConvFeeResult(data.data.items, false);
                } else {
                    this.setState({ showLoader: false });  
                }
            }).catch(error => {
                this.setState({ showLoader: false });  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });    
    }

    setConvFeeResult(convfee) {  
        if(convfee.length > 0)
        {
            let convFeeAmount = 0.00;
            if(convfee[0].is_percentage){
                convFeeAmount = (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) 
                this.setState({ ConvFeeAmount : (parseFloat(parseFloat(this.state.Amount)/100) * parseFloat(convfee[0].value)) })
            }
            else{
                convFeeAmount =  parseFloat(convfee[0].value)
                this.setState({ ConvFeeAmount : parseFloat(convfee[0].value)});
            }            
            this.setState({ ConvFee: convfee[0] }); 
            
            global.InvoiceAmount = parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2);
            this.setState({ Amount: parseFloat(parseFloat(this.state.Amount) + convFeeAmount).toFixed(2) });
        }
        this.setState({ showLoader: false });   
    }

    ShowGatewayJSForm(){
       var body = document.getElementById("body").innerHTML;
       if(body == ""){
           this.setState({IsThreeDSecureInterface : false});
            setTimeout(() => {
                this.ShowGatewayJSForm()
            }, 100);
       }
       else{
           this.setState({IsThreeDSecureInterface : true});
       }
    }

    nmiHandleRadio = (event) => {
        const target = event.target;        
        this.setState({ StatusMessage: "" });
        if (target.name === "creditcard" && this.state.lastState != "creditcard") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: false
                }
            }));
            this.setState({ lastState: target.name});
            global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) + parseFloat(this.state.ConvFeeAmount)).toFixed(2);
            this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            this.state.groupButtonCreditCardStyle = "primary active";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "default";
        }
        else if (target.name === "debitcard") {
            this.setState({ IsDebitCard: true });
            this.setState({ IsCreditCard: false });
            this.setState({ IsACH: false });
            this.setState(prevState => ({
                Customer: {
                    ...prevState.Customer,
                    FormValid: false
                }
            }));
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "primary active";
            this.state.groupButtonACHStyle= "default";
        }
        else if(target.name === "ach") {
            this.setState({ IsACH: true });
            this.setState({ IsDebitCard: false });
            this.setState({ IsCreditCard: false });
            if(this.state.lastState == "creditcard"){
                global.InvoiceAmount = parseFloat(parseFloat(global.InvoiceAmount) - parseFloat(this.state.ConvFeeAmount)).toFixed(2);
                this.setState({ Amount: parseFloat(global.InvoiceAmount).toFixed(2) });
            }
            this.setState({ lastState: target.name});
            if (this.state.Customer.AmountValid === true) {
                this.setState(prevState => ({
                    Customer: {
                        ...prevState.Customer,
                        FormValid: true
                    }
                }));
            }
            this.state.groupButtonCreditCardStyle = "default";
            this.state.groupButtonDebitCardStyle= "default";
            this.state.groupButtonACHStyle= "primary active";
        }
        if(this.state.IsThreeStepAuthentication == false){
            if(this.state.IsGatewayJSEnable){
                this.configureGatewayJS();
            }
            else{
                this.configureCollectJS();
            }                       

            document.getElementById("creditCardErrorMessage").style.display = "none";
            document.getElementById("expErrorMessage").style.display = "none";
            document.getElementById("cvvErrorMessage").style.display = "none";
            document.getElementById("accountNameErrorMessage").style.display = "none";
            document.getElementById("accountNumberErrorMessage").style.display = "none";
            document.getElementById("routingNumberErrorMessage").style.display = "none";
        }
        this.state.Customer.CheckBoxChecked = false;
        //  this.state.Customer.FormValid = false;
    };

    loadNMIScriptTag(tokenizationKey) {
        // let tokenizationKey = "wVX64h-R27cN8-7jEjKP-24qGsj";
        

        let collectJS = document.createElement("script");
        collectJS.src = "https://secure.networkmerchants.com/token/Collect.js";
        collectJS.async = true;
        collectJS.setAttribute(
            "data-tokenization-key",
            tokenizationKey
        );
        collectJS.setAttribute(
            "id",
            tokenizationKey 
        );
        if(!this.state.IsGatewayJSEnable){
            collectJS.onload = () => {
                setTimeout(() => {
                    this.configureCollectJS();
                }, 5000);
            }
        }
        else{
            collectJS.onload = () => {
                this.configureCollectJS();
            }
        }

        document.head.appendChild(collectJS);
        if(this.state.IsGatewayJSEnable) {
            let gatewayJS = document.createElement("script");
            gatewayJS.src = "https://secure.networkmerchants.com/js/v1/Gateway.js";
            gatewayJS.async = true;        
            document.head.appendChild(gatewayJS); 
            gatewayJS.setAttribute(
                "id",
                tokenizationKey + "abc"
            );
            this.SetGatewayJSFunction(gatewayJS);
        }        
    }    

    SetGatewayJSFunction(gatewayJS){  
       if(window.CollectJS == undefined || window.CollectJS == null){
           setTimeout(() => {
            this.SetGatewayJSFunction(gatewayJS);
           }, 1000); 
       }
       else{
            this.configureGatewayJS();
       }
    }

    configureGatewayJS() { 
        try{
            const gateway = window.Gateway.create(this.state.collectCheckoutKey); //('checkout_public_Pr77me42gc3UzQ6JpC2RrG9ake8rm343');
            const threeDS = gateway.get3DSecure();
            window.CollectJS.configure({
                variant: 'inline',
                callback: (e) => {
                    const st = this.state;
                    if(!(st.IsCreditCard && e.card != null && e.card != undefined && (e.card.type == "visa" || e.card.type == "mastercard"))){
                        this.finishSubmit(e);
                        return;
                    }
                    const invoice_info = st.Invoice;
                    const payment_info = st.Customer;
                    const amount = st.Amount;
            
                    let cardInfo = null;
                    let achInfo = null;
            
                    const data = {
                        "InvoiceInfo": {
                            "Id": invoice_info.Id,
                            "DocNumber": [invoice_info.DocNumber],
                            "Type": "payment",
                            "Invoice": invoice_info
                        },
                        "SalesReceiptInfo": {
                            "Id": [],
                            "DocNumber": [],
                            "Type": "salesreceipt"
                        },
                        "PaymentInfo": {
                            "CardInfo": cardInfo,
                            "AchInfo": achInfo,
                            "ProfileInfo": {},
                            "BillingInfo": { },
                            "TransactionInfo": {
                                "TransType": st.IsCreditCard ? "sale" : "ach_sale",
                                "TenderType": st.IsACH ? "ach" : st.IsCreditCard ?  "credit" : "debit",
                                "Amount": amount,
                                "ActionType": "invoice",
                                "CurrencyCode": "USD"
                            }
                        },
                        "CustomerRef": {
                            "Name": invoice_info.CustomerRef ? invoice_info.CustomerRef.name : null,
                            "Value": invoice_info.CustomerRef ? invoice_info.CustomerRef.value : null,
                            "Email": invoice_info.BillEmail ? invoice_info.BillEmail.Address : null                               
                        }
                    }

                    const options = {
                        paymentToken: e.token,
                        currency: 'USD',
                        amount: st.Amount, //data.PaymentInfo.TransactionInfo.Amount,
                        phone: '8008675309',
                        city: st.Customer.City, //data.PaymentInfo.BillingInfo.City,
                        state:  st.Customer.State, //data.PaymentInfo.BillingInfo.State,
                        country: "US",//st.Customer.Country, //data.PaymentInfo.BillingInfo.Country,
                        address1: st.Customer.Address, //data.PaymentInfo.BillingInfo.Street,
                        postalCode: st.Customer.PostalCode,//data.PaymentInfo.BillingInfo.PostalCode,
                        firstName: st.Customer.firstName, //data.CustomerRef.Name,
                        lastName: st.Customer.lastName, //data.CustomerRef.Name,
                        email: st.Invoice.BillEmail ? st.Invoice.BillEmail.Address  : ""//data.CustomerRef.Email,                           
                    };
                
                    const threeDSecureInterface = threeDS.createUI(options);
                    threeDSecureInterface.start('#body');
                    document.getElementById("gatewayJSProgressBar").style.display = "";
                    document.getElementById("gatewayJSSuccessMessage").innerHTML = "Please wait...";
                    console.log('body STARTED...');
                    
                    threeDSecureInterface.on('challenge', function(e) {
                       console.log('Challenged');
                       document.getElementById("gatewayJSProgressBar").style.display = "none";
                       document.getElementById("gatewayJSSuccessMessage").innerHTML = "";
                    });

                    const app_id = sessionStorage.getItem(StorageEnum.APPID);
                    threeDSecureInterface.on('complete', function(e) {
                        console.log(e);
                        document.getElementById("gatewayJSProgressBar").style.display = "";
                         document.getElementById("gatewayJSSuccessMessage").innerHTML = "Verifying, Please wait...";
                        NetworkService.postGatewayJSPaymentRequest(app_id, {
                             ...options,
                            ...data,
                             cavv: e.cavv,
                             xid: e.xid,
                             eci: e.eci,
                             cardHolderAuth: e.cardHolderAuth,
                             threeDsVersion: e.threeDsVersion,
                             directoryServerId: e.directoryServerId,
                        })
                        .then(data => {
                            if (data != null) {  
                                document.getElementById("gatewayJSProgressBar").style.display = "none";                      
                                if (data.statuscode == 0) {
                                    document.getElementById("gatewayJSSuccessMessage").innerHTML = data.statusmessage;   
                                    document.getElementById("gatewayJSWatermarkDiv").style.display = "";
                                }
                                else{
                                     document.getElementById("gatewayJSErrorMessage").innerHTML = "Oops! Something went wrong, please refresh the page and try again.";
                                }                                                                         
                                }
                                document.getElementById("gatewayJSProgressBar").style.display = "none"; 
                            });
                        });

                    threeDSecureInterface.on('failure', function(e) {
                        console.log('failure');
                        console.log(e);
                    });
                
            }
            })
            
            gateway.on('error', function (e) {
                console.log(e);
            })
        } catch(error){
            console.log(error);
        }
    }

    configureCollectJS() {
        try {
            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                callback: (token) => {
                    this.finishSubmit(token)
                },
                validationCallback: function (field, status, message) {
                    if (status) {
                        // console.log(document.getElementById(field).value);
                        var message = field + " is now OK 1 : " + message;
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "none";
                                document.getElementById("creditCardErrorMessage").innerHTML = "";
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "none";
                                document.getElementById("expErrorMessage").innerHTML = "";
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "none";
                                document.getElementById("cvvErrorMessage").innerHTML = "";
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "none";
                                document.getElementById("accountNameErrorMessage").innerHTML = "";
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "none";
                                document.getElementById("accountNumberErrorMessage").innerHTML = "";
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "none";
                                document.getElementById("routingNumberErrorMessage").innerHTML = "";
                                break;
                        }
                    } else {
                        var message = field + " is now Invalid : " + message + "  status=" + status;
                        //  document.getElementById(field).classList.add("invalidCss");
                        switch (field) {
                            case 'ccnumber':
                                document.getElementById("creditCardErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                                }
                                else {
                                    document.getElementById("creditCardErrorMessage").innerHTML = "Invalid card number";
                                }
                                break;
                            case 'ccexp':
                                document.getElementById("expErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                                }
                                else {
                                    document.getElementById("expErrorMessage").innerHTML = "Invalid expiry date";
                                }
                                break;
                            case 'cvv':
                                document.getElementById("cvvErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                                }
                                else {
                                    document.getElementById("cvvErrorMessage").innerHTML = "Invalid CVV";
                                }
                                break;
                            case 'checkname':
                                document.getElementById("accountNameErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                                }
                                else {
                                    document.getElementById("accountNameErrorMessage").innerHTML = "Invalid account name";
                                }
                                break;
                            case 'checkaccount':
                                document.getElementById("accountNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                                }
                                else {
                                    document.getElementById("accountNumberErrorMessage").innerHTML = "Invalid account number";
                                }
                                break;
                            case 'checkaba':
                                document.getElementById("routingNumberErrorMessage").style.display = "block";
                                if (message.indexOf("empty") > -1) {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                                }
                                else {
                                    document.getElementById("routingNumberErrorMessage").innerHTML = "Invalid routing number";
                                }
                                break;
                        }
                    }
                    console.log(message);
                },
                // variant: 'inline',
                // googleFont: 'Abel',
                invalidCss: {
                    'border-bottom-color': 'red',
                    'border-width': '2px'
                },
                // validCss: {
                //   color: '#13AA73'
                // },
                customCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#AAAAAA',
                    'border-style': 'solid',
                    'border-width': '1px',
                    'font-family': 'sans-serif',
                    'font-size': '18px'
                },
                placeholderCss: {
                    "color": "#AAAAAA",
                    "font-weight": "bold",
                    "font-size": "16px"
                },
                // placeholderCss:{
                //     'font-family': 'sans-serif',
                //     'font-size':'18px',
                //     'ont-weight':'bold'
                // },
                focusCss: {
                    'border-top-color': '#ffffff',
                    'border-right-color': '#ffffff',
                    'border-left-color': '#ffffff',
                    'border-bottom-color': '#0075c1',
                    // 'border-style': 'solid',
                    'border-width': '2px'
                },
                fields: {
                    ccnumber: {
                        // placeholder: 'Card Number',
                        // title: "Account Number",
                        selector: '#ccnumber',
                        //onChange:(e) => { console.log('a'); }
                    },
                    ccexp: {
                        // placeholder: 'MM/YYYY',
                        selector: '#ccexp'
                    },
                    cvv: {
                        // placeholder: 'CVV',
                        selector: '#cvv',
                        //  display: "show"
                    },
                    checkaccount: {
                        selector: "#accountnumber",
                        // title: "Account Number",
                        // placeholder: "Account Number"
                    },
                    checkaba: {
                        selector: "#routingnumber",
                        //title": "Routing Number",
                        //  placeholder: "Routing Number"
                    },
                    checkname: {
                        selector: "#accountname",
                        //title": "Name on Checking Account",
                        // placeholder: "Account Name"
                    }
                }
            });
        } catch (error) {
           // this._getActiveProcessorRequest();
        }
    }

    handleSubmit = (event) => {
        this.setState({ StatusMessage: "" });
        if (this.state.IsThreeStepAuthentication) {
           
        } 
        else {
            this.validateForm();
            var isCCFormValid = true;
            if (this.state.IsCreditCard || this.state.IsDebitCard) {
                if (document.getElementById("creditCardErrorMessage").innerHTML !== "") {
                    document.getElementById("creditCardErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("expErrorMessage").innerHTML !== "") {
                    document.getElementById("expErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("cvvErrorMessage").innerHTML !== "") {
                    document.getElementById("cvvErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
            }
            else if (this.state.IsACH) {
                if (document.getElementById("accountNameErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNameErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("accountNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("accountNumberErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
                if (document.getElementById("routingNumberErrorMessage").innerHTML !== "") {
                    document.getElementById("routingNumberErrorMessage").style.display = "block";
                    isCCFormValid = false;
                }
            }
            if (!isCCFormValid || !this.state.Customer.FormValid) {
                event.preventDefault();
                return false;
            }
            else {
                this.setState({ ShowProgressBar: true });
                this.setState({ isSubmitting: true });

                event.preventDefault();
                window.CollectJS.startPaymentRequest();
            }
        }
    }

    finishSubmit(response) {
        const { isSubmitting, ...formData } = { ...this.state };
        formData.token = response.token;
        console.log(formData);
        this.handleCharged(response);
        //post this form data to server api....
    }

    handleCharged = (value) => {
        const invoice_info = this.state.Invoice;
        const payment_info = this.state.Customer;
        const amount = this.state.Amount;

        let cardInfo = null;
        let achInfo = null;
        if (this.state.IsCreditCard || this.state.IsDebitCard) {
            cardInfo = {
                "FirstName": payment_info.firstName,
                "LastName": payment_info.lastName,
                "CardHolder": payment_info.firstName + ' ' + payment_info.lastName,
                "CardNumber": value.card.number,
                "Cvv": null,
                "Month": null,
                "Year": null,
                "CardType": value.card.type,
                "Token": value.token
            }
        } else if (this.state.IsACH) {
            achInfo = {
                "FirstName": payment_info.firstName,
                "LastName": payment_info.lastName,
                "AccountHolder": payment_info.AccountName,
                "AccountNumber": payment_info.AccountNumber,
                "RoutingNumber": payment_info.RoutingNumber,
                "AccountType": payment_info.AccountType,
                "SecCode": payment_info.AccountHolderType.value == "personal" ? "PPD" : "CCD",
                "CardType": "ECHK",
                "Token": value.token
            }
        }
        const data = {
            "InvoiceInfo": {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "payment",
                "Invoice": invoice_info
            },
            "SalesReceiptInfo": {
                "Id": [],
                "DocNumber": [],
                "Type": "salesreceipt"
            },
            "PaymentInfo": {
                "CardInfo": cardInfo,
                "AchInfo": achInfo,
                "ProfileInfo": {
                    "ProfileId": payment_info.ProfileId,
                    "CreateProfile": payment_info.IsCreateProfile
                },
                "BillingInfo": {
                    "Country": payment_info.Country,
                    "City": payment_info.City,
                    "State": payment_info.State,
                    "Street": payment_info.Address,
                    "PostalCode": payment_info.PostalCode
                },
                "TransactionInfo": {
                    "TransType": this.state.IsACH ? "ach_sale" : "sale",
                    "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                    "Amount": amount,
                    "ActionType": "invoice",
                    "CurrencyCode": "USD"
                }
            },
            "CustomerRef": {
                "Name": invoice_info.CustomerRef ? invoice_info.CustomerRef.name : null,
                "Value": invoice_info.CustomerRef ? invoice_info.CustomerRef.value : null,
                "Email": invoice_info.BillEmail ? invoice_info.BillEmail.Address : null
            }
        }

        // data = {
        //     "InvoiceInfo": data['InvoiceInfo'],
        //     "SalesReceiptInfo": data['SalesReceiptInfo'],
        //     "PaymentInfo": data["PaymentInfo"],
        //     "CustomerRef": data["CustomerRef"],
        //     "AcceptPaymentInfo": data["AcceptPaymentInfo"]
        // }
        this.setState({ remainingBalance: parseFloat(global.InvoiceAmount) - parseFloat(amount) })
        //TODO: uncomment below piece of code is transaction perform from credit card  
                
        if(this.state.IsGatewayJSEnable && this.state.IsCreditCard && value.card != null && value.card != undefined && (value.card.type == "visa" || value.card.type == "mastercard")){
            // setTimeout(() => {
            // //TODO: uncomment below piece of code for collect JS
            // // this.configureCollectJS()            
            //     this.configureGatewayJS();
            // }, 1000);
             this.configureGatewayJS();
        }
        else{
            this._postCollectPaymentRequest(data)
        }
        
    }

    handleKeyPressInput(e) { //= event => {
        var charCode = (e.which) ? e.which : e.keyCode;
        //alert(charCode);
        //97-122, 32,65-90'
        if ((charCode >= 97 && charCode <= 122) || charCode === 32 || (charCode >= 65 && charCode <= 90)) {
        }
        else {
            e.preventDefault();
        }
    };

    handleOnBlurInput(e) {
        console.log('handleOnBlurInput is calling..' + e.target.name + ' ' + e.target.value);
        this.validateField(e.target.name, e.target.value)
        if (e.target.name === "CardExpiry" && this.state.Customer.CardExpiryValid === true) {
            var dt_year = e.target.value.split("/")[1]; //"20"+
            var dt_month = e.target.value.split("/")[0];

            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, GatewayExpiryDate: dt_year + "-" + dt_month
                }
            }), () => console.log(this.state.Customer.GatewayExpiryDate));
        }
    };

    handleUserInput(e) {
        console.log('handleUserInput is calling..' + e.target.name);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'firstName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, firstName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case 'lastName':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, lastName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardExpiry":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardExpiry: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "CardCVV":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, CardCVV: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Address: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, PostalCode: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                // this.setState(prevState => ({
                //     Amount:
                //     {
                //         ...prevState.Amount, Amount: value
                //     }
                // }), () => { this.validateField(name, value) })
                
                this.setState(({Amount : value}), () => { this.validateField(name, value) });
                this.setState({remainingBalance: global.InvoiceAmount - value})
                break;
            case "CheckBox":
                this.validateField(name, e.target.checked)
                break;
            case "AccountName":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountName: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, RoutingNumber: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountHolderType":
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, AccountHolderType: value
                    }
                }), () => { this.validateField(name, value) })
                break;
            case "3StepAuthCheckBox":
                var cbValue = e.target.checked;
                this.setState(prevState => ({
                    ...prevState.IsThreeStepAuthentication, IsThreeStepAuthentication: cbValue

                }));
                break;
            default:
                break;
        }

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.Customer.FormErrors;
        let firstNameValid = this.state.Customer.firstNameValid;
        let lastNameValid = this.state.Customer.lastNameValid;
        let cardNumberValid = this.state.Customer.CardNumberValid;
        let cardExpiryValid = this.state.Customer.CardExpiryValid;
        let cardCVVValid = this.state.Customer.CardCVVValid;
        let checkBoxChecked = this.state.Customer.CheckBoxChecked;

        let accountNumberValid = this.state.Customer.AccountNumberValid;
        let accountNameValid = this.state.Customer.AccountNameValid;
        let routingNumberValid = this.state.Customer.RoutingNumberValid;
        let amountValid = this.state.Customer.AmountValid;
        switch (fieldName) {
            case 'firstName':
                firstNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.firstName = firstNameValid ? '' : ' is invalid';
                this.state.Customer.firstNameValid = firstNameValid ? true : false;
                break;
            case 'lastName':
                lastNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.lastName = lastNameValid ? '' : ' is invalid';
                this.state.Customer.lastNameValid = lastNameValid ? true : false;
                break;            
            case 'CardNumber':
                //  cardNumberValid = global.isCardNumberValid;
                //  this.state.Customer.CardNumberValid = cardNumberValid ? true : false;
                break;
            case 'CardExpiry':
                // cardExpiryValid = global.isCardExpiryValid;
                //  this.state.Customer.CardExpiryValid = cardExpiryValid ? true : false;
                break;
            case 'CardCVV':
                //   cardCVVValid = global.isCardCVVValid;
                //   this.state.Customer.CardCVVValid = cardCVVValid ? true : false;
                break;
            case 'CheckBox':
                checkBoxChecked = value;
                this.state.Customer.CheckBoxChecked = value;
                break;
            case 'Amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.Customer.AmountValid = amountValid ? true : false;
                this.state.Amount = value;
                break;
            case 'AccountName':
                accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.Customer.AccountNameValid = accountNameValid ? true : false;
                break;
            case 'AccountNumber':
                accountNumberValid = value.match(/^\d{4,17}$/);
                this.state.Customer.AccountNumberValid = accountNumberValid ? true : false;
                break;
            case 'RoutingNumber':
                routingNumberValid = value.match(/^\d{9}$/);
                this.state.Customer.RoutingNumberValid = routingNumberValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            CardNumberValid: cardNumberValid,
            CardExpiryValid: cardExpiryValid,
            CardCVVValid: cardCVVValid,
            CheckBoxChecked: checkBoxChecked,
            AccountNameValid: accountNameValid,
            AccountNumberValid: accountNumberValid,
            RoutingNumberValid: routingNumberValid,
            AmountValid: amountValid
        }, this.validateForm);

    }

    validateForm() {
        //console.log(this.state.Customer.CardNumberValid);
        if (this.state.IsCreditCard || this.state.IsDebitCard) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: (this.state.IsCreditCard || this.state.IsDebitCard) && this.state.Customer.CardCVVValid && this.state.Customer.CardExpiryValid
                        && this.state.Customer.CardNumberValid && this.state.Customer.AmountValid
                        && this.state.Customer.firstNameValid && this.state.lastNameValid &&
                        this.state.Customer.CheckBoxChecked
                }
            }), () => console.log())
        }
        else if (this.state.IsACH) {
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, FormValid: this.state.Customer.AccountNameValid && this.state.Customer.AccountNumberValid
                        && this.state.Customer.firstNameValid && this.state.lastNameValid
                        && this.state.Customer.AmountValid && this.state.Customer.RoutingNumberValid
                }
            }), () => console.log())
        }
    }

    rawMarkup(value) {
        const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: this.state.InvoiceRawHtml };
    };

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {

            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    getInvoicePreview(invoice_id = "") {
        const params = { "invoice_id": invoice_id }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }


    getPaymentKeyDetail(value) {
        const params = { "key": value }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getVerifyPaymentKeyRequest(query);

    }

    setInvoicePreviewResult = (data) => {
        this.setState({ InvoiceRawHtml: data.data.html || null });
        data.data.invoice.map(data => {
            this.setState({ Invoice: data });
            global.InvoiceAmount = parseFloat(data.Balance).toFixed(2); 
            this.setState({ Amount: parseFloat(data.Balance).toFixed(2) });            
            if (parseFloat(data.Balance).toFixed(2) == "0.00") {
                this.setState({ isPaid: true });
            }
            let ach = data.AllowOnlineACHPayment;
            let creditCard = data.AllowOnlineCreditCardPayment;
            if(ach == true || creditCard == true){
                if(this.state.IsACHEnabled && (this.state.IsCreditCardEnabled || this.state.IsDebitCardEnabled))
                {
                    this.setState({IsACHEnabled: ach, IsCreditCardEnabled: creditCard,
                    IsDebitCardEnabled: this.state.IsDebitCardEnabled});
                }
                if(creditCard == false)
                {
                    this.setState({groupButtonCreditCardStyle: "default"})
                    this.setState({groupButtonACHStyle: "primary active"})
                    this.setState({IsCreditCard: creditCard});

                    this.setState({IsACH: ach })
                }
            }
        })
        const content_type = data.data.content_type
        if (content_type === "application/html") {
            this.setState({ showHTMLDiv: true });            
        } else {
            this.setState({ showHTMLDiv: false })
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas")
        }
        if(this.state.IsCreditCardEnabled){
            this.GetConvFee();
        }
        else{
            this.setState({ showLoader: false });   
        }      
    }


    setPaymentKeyResult = (data) => {
        let uuid = data.access_token;
        let full_name = data.full_name;
        let invoice_id = data.inv_id;
        let app_id = data.app_id;
        //  global.SuccessfulLoggedIn = true;
        let processor = data.processor;
        let tokenize_key = null;
        if (processor != null) {        
            let ach = processor.ProcessorConfig.supported_methods.ach    
            let creditcard = processor.ProcessorConfig.supported_methods.creditcard
            let debitcard = processor.ProcessorConfig.supported_methods.debitcard
            let threestep = processor.ProcessorConfig.supported_methods.threestep == undefined ? false : processor.ProcessorConfig.supported_methods.threestep
            let gatewayJSEnable = processor.ProcessorConfig.supported_methods.gatewayJS == undefined ? false : processor.ProcessorConfig.supported_methods.gatewayJS            
            this.setState({IsACH: ach, IsACHEnabled: ach })
            this.setState({IsCreditCard: creditcard, IsCreditCardEnabled: creditcard, IsDebitCardEnabled: debitcard, IsThreeStepAuthentication: threestep, IsGatewayJSEnable: gatewayJSEnable })           
            this.setState({collectCheckoutKey: processor.ProcessorConfig.CollectCheckoutKey})
            if (processor.ProcessorType == "nmi") {
                tokenize_key = JSON.stringify({ "PPType": processor.ProcessorType, "PPToken": processor.ProcessorConfig.TokenizationKey })
                this.loadNMIScriptTag(processor.ProcessorConfig.TokenizationKey);
            }
            else if (processor.ProcessorType == "cardconnect") {
            }    
            // this.setState(prevState => ({
            //         ...prevState.IsThreeStepAuthentication, IsThreeStepAuthentication: threestep                
            // })); 
            
            if(ach && (creditcard || debitcard)){
                this.setState({IsACH: false })
            }
            if(creditcard == false)
            {
                this.setState({groupButtonCreditCardStyle: "default"})
                if(debitcard){
                    this.setState({groupButtonDebitCardStyle: "primary active"})
                    this.setState({IsDebitCard: debitcard});
                }
                else{
                    this.setState({groupButtonACHStyle: "primary active"})
                    this.setState({IsACH: ach })
                }

            }
        }

        localStorage.setItem(StorageEnum.UUID, uuid);
        localStorage.setItem(StorageEnum.FULLNAME, full_name);
        localStorage.setItem(StorageEnum.PPBUNDLE, tokenize_key);    
        localStorage.setItem(StorageEnum.IS_FROM_CP, "true"); 
        sessionStorage.setItem("INV_ID", invoice_id);
        sessionStorage.setItem(StorageEnum.APPID, app_id);

        this.getInvoicePreview(invoice_id);

        // this.getInvoicFilterRequest(invoice_id);
    }

    /**
     * API Calling
     */

    /** GET request to verify customer payment key */
    _getVerifyPaymentKeyRequest = (params) => {
       // this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.getVerifyPaymentKeyRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        this.setPaymentKeyResult(data.data);
                    }
                    else{
                         this.setState({showLoader: false});
                    }
                } else {
                    this.setState({showLoader: false});
                }
            }).catch(error => {
                //this.setState({ ShowProgressBar: false });
                this.setState({showLoader: false});
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {
        this.setState({ errorMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                if (data != null) {
                    this.setInvoicePreviewResult(data);
                } else {
                    this.setState({showLoader: false});
                }
            }).catch(error => {
                this.setState({showLoader: false});
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    /** POST request for payment through NMI */
    _postCollectPaymentRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.postCollectPaymentRequest(app_id, data)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });

                if (data != null) {
                    console.log(data.statuscode + '  ' + data.statusmessage)
                    this.setState({ StatusCode: data.statuscode });
                    this.setState({ StatusMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        // this.configureCollectJS();
                        document.getElementById("creditCardErrorMessage").innerHTML = "Card number is required";
                        document.getElementById("expErrorMessage").innerHTML = "Expiry date is required";
                        document.getElementById("cvvErrorMessage").innerHTML = "CVV is required";
                        document.getElementById("accountNameErrorMessage").innerHTML = "Account name is required";
                        document.getElementById("accountNumberErrorMessage").innerHTML = "Account number is required";
                        document.getElementById("routingNumberErrorMessage").innerHTML = "Routing number is required";
                        if (parseFloat(this.state.remainingBalance).toFixed(2) == "0.00") {
                            this.setState({ isPaid: true });
                        }
                        else {
                            this.setState({ Amount: parseFloat(this.state.remainingBalance).toFixed(2) });    
                            global.InvoiceAmount =  parseFloat(this.state.remainingBalance).toFixed(2);                         
                        }
                        setTimeout(() => {
                            this.configureCollectJS()
                        }, 1000);
                    }
                    //  if (data.data != null)
                    //     this.setItemsResult(data.data.items);
                } else {
                    this.setState({ StatusCode: -1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    handleWizardCallback = (data, step) => {

        if (step == "step1") {
            const invoice_info = this.state.Invoice;
            const payment_info = this.state.Customer;
            const amount = this.state.Amount;           
            data.amount = amount;
            data.InvoiceInfo = {
                "Id": invoice_info.Id,
                "DocNumber": [invoice_info.DocNumber],
                "Type": "payment",
                "Invoice": invoice_info
            }

            data.SalesReceiptInfo = {
                "Id": [],
                "DocNumber": [],
                "Type": "salesreceipt"
            }

            data.PaymentInfo = {
                "BillingInfo": {
                    "Country": data.country,
                    "City": data.city,
                    "State": data.state,
                    "Street": data.address,
                    "PostalCode": data.postalCode
                },
                "TransactionInfo": {
                    "TransType": this.state.IsACH ? "ach_sale" : "sale",
                    "TenderType": this.state.IsACH ? "ach" : this.state.IsCreditCard ?  "credit" : "debit",
                    "Amount": amount,
                    "ActionType": "invoice",
                    "CurrencyCode": "USD"
                }
            }

            data.CustomerRef = {
                "Name": invoice_info.CustomerRef ? invoice_info.CustomerRef.name : null,
                "Value": invoice_info.CustomerRef ? invoice_info.CustomerRef.value : null,
                "Email": invoice_info.BillEmail ? invoice_info.BillEmail.Address : null
            }

            this._postThreeStepPersonalInfoRequest(data);
        }
        else if (step == "step2") {
            let url = data.formUrl;

            let formData = data.formData;

            // pass form data
            this._postThreeStepPaymentRequest(url, formData);
        }
        else if (step == "step3") {
            // data.Invoice = this.state.invoiceData;
            // this._postThreeStepVerifyTokenRequest(data);
        }
    }

    handleWizardSteps = (value) => {

    }

    _postThreeStepPersonalInfoRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);
        NetworkService.postThreeStepPaymentRequest(app_id, data)
        .then(data => {
            this.setState({ ShowProgressBar: false });
            this.setState({ StatusCode: data.statuscode });

            if (data != null & data.statuscode == 0) {
                if (data.data["result-code"] == "100") {
                    // this.setState({ StatusMessage: data.data["result-text"] });
                    let resp = { ...this.state.threeStepForm }
                    resp.formUrl = data.data["form-url"];
                    resp.step1Completed = true;
                    this.setState({ threeStepForm: resp });
                }
            } else {
                let resp = { ...this.state.threeStepForm }
                resp.step1Completed = false;
                resp.step2Completed = false;
                this.setState({ threeStepForm: resp });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
        });
    }

    _postThreeStepPaymentRequest = (url, data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        fetch(url, {
            method: "POST",
            body: data
        }).then(response => {
            return response.text();
        }).then(data => {
            const response = null;
            this.setState({ ShowProgressBar: false });
            try{
                response = JSON.parser(data);
                if(response != null){
                    this.setState({ StatusCode: response.statuscode });
                    this.setState({ StatusMessage: response.statusmessage });
                }
                if (response != null & response.statuscode == 0) {
                    // let resp = { ...this.state.threeStepForm }
                    // resp.tokenId = data.data["token_id"];
                    // resp.step2Completed = true;
                    // this.setState({ threeStepForm: resp });
    
                    let payload = { "token_id": response.data["token_id"], "invoice": this.state.invoiceData }
                    this._postThreeStepVerifyTokenRequest(payload);
                } else {
                    let resp = { ...this.state.threeStepForm }
                    resp.step1Completed = false;
                    resp.step2Completed = false;
                    this.setState({ threeStepForm: resp });
                }
            }
            catch(error){
                let resp = { ...this.state.threeStepForm }
                resp.step1Completed = true;
                resp.step2Completed = true;
                resp.htmlText = data;
                this.setState({ threeStepForm: resp });
                if (parseFloat(this.state.remainingBalance).toFixed(2) == "0.00") {
                    this.setState({ Amount: 0.00 });
                    this.setState({ isPaid: true });
                }
                else {
                    this.setState({ Amount: parseFloat(this.state.remainingBalance).toFixed(2) });
                    this.setState({ totalAmount: parseFloat(this.state.remainingBalance).toFixed(2) });
                    this.setState({ amountToCharge: parseFloat(this.state.remainingBalance).toFixed(2) });
                    this.setState({ remainingBalance: 0.00 });
                }
            }
            this.setState({ description: data });           
            
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
        });
    }

    _postThreeStepVerifyTokenRequest = (data) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ StatusMessage: "" });

        const app_id = sessionStorage.getItem(StorageEnum.APPID);

        NetworkService.postThreeStepTokenVerifyRequest(app_id, data)
        .then(data => {
            this.setState({ ShowProgressBar: false });
            this.setState({ StatusCode: data.statuscode });
            this.setState({ StatusMessage: data.statusmessage });
            if (data != null & data.statuscode == 0) {
                let resp = { ...this.state.threeStepForm }
                resp.tokenId = "Transaction completed and verified successfully";
                resp.step2Completed = true;
                resp.remainingBalance = this.state.remainingBalance;
                this.setState({ threeStepForm: resp });                
                
                if (parseFloat(this.state.remainingBalance).toFixed(2) == "0.00") {
                    this.setState({ Amount: 0.00 });
                    this.setState({ isPaid: true });
                }
                else {
                    this.setState({ Amount: parseFloat(this.state.remainingBalance).toFixed(2) });
                    this.setState({ totalAmount: parseFloat(this.state.remainingBalance).toFixed(2) });
                    this.setState({ amountToCharge: parseFloat(this.state.remainingBalance).toFixed(2) });
                    this.setState({ remainingBalance: 0.00 });
                }
            } else {
                this.setState({ StatusMessage: data.statusmessage });

            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
        });
    }

    extractTokenFromUrl = () => {
        // First parse and obtain invoice token form request url        
        // let url = BASE_URL + "apps/customerportal/invoice/?q=e4da3b7fbbce2345d7772b0674a318d5";
        const url = window.location.href;
        const path = url.split("=");
        const token = (path[1] || "");
        return token;
    };

    renderCustomerCareForm() {
        return (<div>
            <Row>
                <Col>
                <iframe
                    title='CardPointeTokenizer'
                    id='tokenframe'
                    name='tokenframe'
                    src={"data:text/html," + encodeURIComponent(this.state.description)}
                    frameBorder='0'
                    scrolling='yes'
                    width='100%'
                    height='300'
                    crossorigin="anonymous"
                />
                </Col>
            </Row>
        </div>
        );

    }

    setAddressFields(jsonData) {       
        let isPostalCodeAvailable = false;
        let isCityAvailable = false;
        
        // Loop through the address components
        for (const component of jsonData.address_components) {
          // Extract the first element of the "types" array          
          if (component.types.includes("postal_code")) {
                {
                    isPostalCodeAvailable = true;
                }
            }
            if (component.types.includes("locality")) {
                {
                    isCityAvailable = true;
                }
            }
            
          const firstType = component.types[0];
      
          // Set the variables based on the first type
          switch (firstType) {
            case 'postal_code':
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, PostalCode: component.short_name
                }
            }))
              break;
            case 'locality':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, City: component.short_name
                    }
                }))
              break;
            case 'administrative_area_level_1':              
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, State: component.short_name
                    }
                }))
              break;
            case 'country':
                this.setState(prevState => ({
                    Customer:
                    {
                        ...prevState.Customer, Country: component.short_name
                    }
                }))
              break;
          }
        }
        
        if(!isPostalCodeAvailable){
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, PostalCode: ""
                }
            }))
        }
        if(!isCityAvailable){
            this.setState(prevState => ({
                Customer:
                {
                    ...prevState.Customer, City: ""
                }
            }))
        }

        this.setState(prevState => ({
            Customer:
            {
                ...prevState.Customer, IsPostalCodeAvailable: isPostalCodeAvailable, IsCityAvailable: isCityAvailable
            }
        }))
        
    }

    render() {
        return (
            <div className="ii-main-content">
            {this.state.showLoader &&  
                <div style={styles.overlay}>
                    <div style={{ position: "fixed", top: "calc(50% - 100px)", left: "calc(50% - 142px)"}}>
                        <img src={InstantInvoiceLoader} width="300px" height="105px" />
                    </div>
                </div>
            }
                <Card
                    content={
                        <Row>
                            { !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))  &&
                                <Col md={8}>
                                    <Card
                                        content={
                                            <div>                                                
                                                {
                                                    this.state.showHTMLDiv ? <div dangerouslySetInnerHTML={this.rawMarkup(this.state.Invoice || null)} /> : <div id="pdf-canvas" height="0"> </div>
                                                }
                                                {
                                                    this.state.isPaid && this.state.showHTMLDiv && 
                                                    <div style={styles.watermark}>
                                                    </div>
                                                }  
                                                <div id="gatewayJSWatermarkDiv" style={{display:"none"}} >
                                                <div style={styles.watermark}>
                                                </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                </Col>
                            }
                            <Col md={4}>
                                {this.state.NMI &&
                                    <div>
                                        {this.state.IsThreeStepAuthentication == false && this.state.IsThreeDSecureInterface == false &&  
                                            <div>                                    
                                                <Row>
                                                    <div className="text-center">
                                                        <ButtonGroup>
                                                            {this.state.IsCreditCardEnabled &&
                                                                <Button bsStyle={this.state.groupButtonCreditCardStyle} name="creditcard" onClick={e => this.nmiHandleRadio(e)}  fill={this.state.IsCreditCard} wd>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-credit-card" />
                                                                    </span>
                                                                    Credit Card
                                                                </Button>
                                                            }
                                                            {this.state.IsDebitCardEnabled &&
                                                                <Button bsStyle={this.state.groupButtonDebitCardStyle} name="debitcard" onClick={e => this.nmiHandleRadio(e)} fill={this.state.IsDebitCard} wd>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-credit-card-alt" />
                                                                    </span>Debit Card
                                                                </Button>
                                                            }
                                                            {this.state.IsACHEnabled && 
                                                                <Button bsStyle={this.state.groupButtonACHStyle} name="ach" onClick={e => this.nmiHandleRadio(e)} fill={this.state.IsACH} wd>
                                                                    <span className="btn-label" style={{marginRight:"10px"}}>
                                                                    <i className="fa fa-bank" />
                                                                    </span>Bank/ACH
                                                                </Button>
                                                            }
                                                        </ButtonGroup>
                                                    </div>                                                    
                                                </Row>
                                                {this.state.IsThreeStepEnabled && 
                                                    <Row>
                                                        <Col md={5}>
                                                        </Col>
                                                        <Col md={7}>
                                                            <CustomCheckbox
                                                                name="3StepAuthCheckBox"
                                                                checked={this.state.IsThreeStepAuthentication}
                                                                isDisabled={this.state.isPaid}
                                                                labelText={"3D-Steps Authentication"}
                                                                labelProps={{ style: { fontSize: "17px" } }}
                                                                formControlProps={{
                                                                    onChange: (e) => { this.handleUserInput(e) }
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                                <br />
                                                
                                            </div>
                                        }

                                        

                                        {this.state.IsThreeStepAuthentication == false && this.state.IsThreeDSecureInterface == false && 
                                            <form onSubmit={this.handleSubmit}>
                                                {this.state.IsCreditCard && this.state.ConvFee != "" && 
                                            <Row>
                                                <Col xs={12} sm={12} md={12}>
                                                    <InputLabel style={{ color:"black", fontSize: 17, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11}}>
                                                        {this.state.ConvFee.name} &nbsp;&nbsp; {this.state.ConvFee.is_percentage == false && "$"}{parseFloat(this.state.ConvFee.value).toFixed(2)}{this.state.ConvFee.is_percentage == true && "%"}
                                                    </InputLabel>
                                                </Col>
                                            </Row>
                                        }
                                                <Card
                                                    content={
                                                        <div>
                                                            {this.state.ShowProgressBar &&
                                                                <div style={{ marginBottom: "2px" }}>
                                                                    <CustomProgressBar></CustomProgressBar>
                                                                </div>

                                                            }
                                                            {this.state.StatusCode == 0 &&
                                                                <p style={styles.successResponse}>{this.state.StatusMessage}</p>
                                                            }
                                                            {this.state.StatusCode != 0 &&
                                                                <p style={styles.errorResponse}>{this.state.StatusMessage}</p>
                                                            }
                                                            <Row>
                                                                <Col xs={5} sm={5} md={5}>
                                                                    <h4 style={styles.amountLabel}>Amount ($):</h4>
                                                                </Col>
                                                                <Col xs={7} sm={7} md={7}>
                                                                    <AmountInput
                                                                        name="Amount"
                                                                        id="Amount"
                                                                        value={this.state.Amount}
                                                                        readOnly={this.state.IsCreditCard && this.state.ConvFee != "" }
                                                                        //isDisabled={this.state.IsGatewayJSEnable}
                                                                        //{this.state.IsGatewayJSEnable}
                                                                        labelText=""
                                                                        //className={classes.cardTitle}  
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid, //this.state.Amount == "0.00" && this.state.StatusCode === 0,
                                                                            
                                                                            error: !this.state.Customer.AmountValid,
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) },
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onError: err => console.log(`cvc error: ${err}`)
                                                                        }}
                                                                        inputProps={{ style: { fontSize: 28, fontFamily: "sans-serif", fontWeight: "bold", marginTop: 11 } }} // font size of input text          
                                                                        labelProps={{ style: { fontSize: 20, fontWeight: "bold", marginTop: 6 } }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6} sm={6} md={6}>
                                                                    <InputLabel
                                                                        style={styles.dropDownFontStyle}
                                                                    >
                                                                        First Name
                                                                        </InputLabel>
                                                                    <CustomInput
                                                                        isRequired={true}
                                                                        id="firstName"
                                                                        name="firstName"
                                                                        value={this.state.Customer.firstName}
                                                                        showErrorMessage={!this.state.Customer.firstNameValid}
                                                                        errorMessage="First name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid,
                                                                            //disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                            error: !this.state.Customer.firstNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} sm={6} md={6}>
                                                                    <InputLabel style={styles.dropDownFontStyle} >
                                                                        Last Name
                                                                        </InputLabel>
                                                                    <CustomInput
                                                                        isRequired={true}
                                                                        id="lastName"
                                                                        name="lastName"
                                                                        value={this.state.Customer.lastName}
                                                                        showErrorMessage={!this.state.Customer.lastNameValid}
                                                                        errorMessage="Last name is invalid"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                            disabled: this.state.isPaid,
                                                                            //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                            error: !this.state.Customer.lastNameValid,
                                                                            onChange: (e) => { this.handleUserInput(e) },
                                                                            onKeyPress: (e) => { this.handleKeyPressInput(e) },
                                                                            onBlur: (e) => { this.handleOnBlurInput(e) }
                                                                        }}
                                                                        inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }} // font size of input text 
                                                                        labelProps={{ style: { fontWeight: "bold" } }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            
                                                            
                                                                <div style={{ display: this.state.IsACH ? "none" : "block" }}>                                                                    
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={6} >
                                                                            <InputLabel
                                                                                style={styles.dropDownFontStyle}
                                                                            >
                                                                                Card Number
                                                                                            </InputLabel>
                                                                            <div id="ccnumber" />
                                                                            <div style={{ display: "none", color: "red", fontSize: "12px" }} name="creditCardErrorMessage" id="creditCardErrorMessage">Card number is required
                                                                                </div>
                                                                        </Col>
                                                                        <Col xs={6} sm={6} md={3}>
                                                                            <InputLabel
                                                                                style={styles.dropDownFontStyle}
                                                                            >
                                                                                MM/YY
                                                                                            </InputLabel>
                                                                            <div id="ccexp" />
                                                                            <div style={{ display: "none", color: "red", fontSize: "12px" }} name="expErrorMessage" id="expErrorMessage">Expiry date is required
                                                                                </div>
                                                                        </Col>
                                                                        <Col xs={6} sm={6} md={3}>
                                                                            <InputLabel
                                                                                htmlFor="simple-select"
                                                                                style={styles.dropDownFontStyle}
                                                                            >
                                                                                CVV
                                                                                            </InputLabel>
                                                                            <div id="cvv" />
                                                                            <div style={{ display: "none", color: "red", fontSize: "12px" }} name="cvvErrorMessage" id="cvvErrorMessage">CVV date is required
                                                                                </div>

                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div style={{ display: this.state.IsACH ? "block" : "none" }}>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12}>
                                                                            <InputLabel
                                                                                style={styles.dropDownFontStyle}
                                                                            >
                                                                                Account Name
                                                                                            </InputLabel>
                                                                            <div id="accountname" />
                                                                            <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNameErrorMessage" id="accountNameErrorMessage">Account name is required
                                                                                </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12}>
                                                                            <InputLabel
                                                                                style={styles.dropDownFontStyle}
                                                                            >
                                                                                Account Number
                                                                                            </InputLabel>
                                                                            <div id="accountnumber" />
                                                                            <div style={{ display: "none", color: "red", fontSize: "12px" }} name="accountNumberErrorMessage" id="accountNumberErrorMessage">Account number is required
                                                                                </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12}>
                                                                            <InputLabel  style={styles.dropDownFontStyle} >
                                                                                Routing Number
                                                                            </InputLabel>
                                                                            <div id="routingnumber" />
                                                                            <div style={{ display: "none", color: "red", fontSize: "12px" }} name="routingNumberErrorMessage" id="routingNumberErrorMessage">Routing number is required
                                                                                </div>
                                                                        </Col>
                                                                    </Row>
                                                                     {/* <Row>
                                                                        <Col md={12}>
                                                                            <InputLabel  style={styles.dropDownFontStyle} >
                                                                                Account Type
                                                                            </InputLabel>
                                                                                <Select
                                                                                    className="react-select primary"
                                                                                    classNamePrefix="react-select"
                                                                                    name="accounts"
                                                                                    isDisabled={this.state.ShowProgressBar || this.state.isPaid}
                                                                                    value={this.state.Customer.AccountHolderType}
                                                                                    onChange={value =>
                                                                                        this.setState(prevState => ({
                                                                                            Customer: {
                                                                                                ...prevState.Customer,
                                                                                                AccountHolderType: value
                                                                                            }
                                                                                        }))
                                                                                    }
                                                                                    options={this.state.accounts}
                                                                                    placeholder="Account Type"
                                                                                />
                                                                        </Col>
                                                                       <Col md={6}>
                                                                                <InputLabel style={styles.dropDownFontStyle} >
                                                                                    Sec Code
                                                                                </InputLabel>
                                                                                <Select
                                                                                    className="react-select primary"
                                                                                    classNamePrefix="react-select"
                                                                                    name="secCodes"
                                                                                    isDisabled={this.state.ShowProgressBar || this.state.isPaid}
                                                                                    value={this.state.Customer.SecCode}
                                                                                    onChange={value =>
                                                                                        this.setState(prevState => ({
                                                                                            Customer: {
                                                                                                ...prevState.Customer,
                                                                                                SecCode: value
                                                                                            }
                                                                                        }))
                                                                                    }
                                                                                    options={this.state.secCodes}
                                                                                    placeholder="Sec Code"
                                                                                />
                                                                        </Col> 
                                                                    </Row>  */}
                                                                </div>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <InputLabel style={styles.dropDownFontStyle} >
                                                                            Address
                                                                        </InputLabel>
                                                                        {/* <CustomInput
                                                                            //  labelText="Address"
                                                                            isRequired={true}
                                                                            id="address"
                                                                            name="Address"
                                                                            value={this.state.Customer.Address}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: this.state.isPaid,
                                                                                // disabled: this.state.BalanceDue === "0.00",
                                                                                onChange: (e) => { this.handleUserInput(e) }
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        /> */}
                                                                        <Autocomplete
                                                                            apiKey={"AIzaSyAJHQAxrJ3oCdJA5Fb8odNzNlgX36awEz8"}
                                                                            style={{ width: "100%", fontFamily: "sans-serif", fontSize: "18px", marginTop: "0px", border: 'none', borderBottom:"1px solid #D2D2D2" }}
                                                                            placeholder=""
                                                                            onPlaceSelected={(place) => {                                                                                
                                                                                this.setAddressFields(place);                                        
                                                                                this.setState(prevState => ({
                                                                                    Customer:
                                                                                    {
                                                                                        ...prevState.Customer, Address: place.formatted_address
                                                                                    }
                                                                                }), () => { this.validateField("Address", place.formatted_address) })
                                                                            }}
                                                                            options={{
                                                                                types: ["geocode"],
                                                                                componentRestrictions: { country: "US" },
                                                                            }}
                                                                            defaultValue={this.state.Customer.Address}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={6} sm={6} md={6}>
                                                                        <InputLabel
                                                                            style={styles.dropDownFontStyle}
                                                                        >
                                                                            City
                                                                                </InputLabel>
                                                                        <CustomInput
                                                                            id="city"
                                                                            name="City"
                                                                            value={this.state.Customer.City}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: this.state.isPaid  || this.state.Customer.IsCityAvailable,
                                                                                //  disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                                                onChange: (e) => { this.handleUserInput(e) }
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={6} sm={6} md={6}>
                                                                        <InputLabel
                                                                            style={styles.dropDownFontStyle}
                                                                        >
                                                                            State
                                                                            </InputLabel>
                                                                        <CustomInput
                                                                            id="state"
                                                                            name="State"
                                                                            value={this.state.Customer.State}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: this.state.isPaid || true,
                                                                                // disabled: this.state.BalanceDue === "0.00",
                                                                                onChange: (e) => { this.handleUserInput(e) }
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>                                                                    
                                                                    <Col xs={6} sm={6} md={6}>
                                                                        <InputLabel
                                                                            style={styles.dropDownFontStyle}
                                                                        >
                                                                            Postal Code
                                                                                </InputLabel>
                                                                        <CustomInput
                                                                            // labelText="Postal Code"
                                                                            id="postal-code"
                                                                            name="PostalCode"
                                                                            value={this.state.Customer.PostalCode}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: this.state.isPaid || this.state.Customer.IsPostalCodeAvailable,
                                                                                // disabled: this.state.BalanceDue === "0.00",
                                                                                onChange: (e) => { this.handleUserInput(e) }
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={6} sm={6} md={6}>
                                                                        <InputLabel
                                                                            style={styles.dropDownFontStyle}
                                                                        >
                                                                            Country
                                                                            </InputLabel>
                                                                        <CustomInput
                                                                            // labelText="Country"
                                                                            id="country"
                                                                            name="Country"
                                                                            value={this.state.Customer.Country}
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                disabled: this.state.isPaid || true,
                                                                                // disabled: this.state.BalanceDue === "0.00",
                                                                                onChange: (e) => { this.handleUserInput(e) }
                                                                            }}
                                                                            inputProps={{ style: { fontFamily: "sans-serif", fontSize: "18px", marginTop: "-5px" } }}
                                                                            labelProps={{ style: { fontWeight: "bold" } }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                {(!this.state.IsACH) &&
                                                                    <div style={{ marginTop: "20px" }}>
                                                                        <Row>
                                                                            <Col xs={12} sm={12} md={12}>
                                                                                <CustomCheckbox
                                                                                    name="CheckBox"
                                                                                    checked={this.state.Customer.CheckBoxChecked}
                                                                                    isDisabled={this.state.isPaid}
                                                                                    //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                                                    labelText={"I agree to pay ($" + parseFloat(this.state.Amount).toFixed(2) + ") according to the card issuer agreement."}
                                                                                    labelProps={{ style: { fontSize: "15px" } }}
                                                                                    formControlProps={{
                                                                                        onChange: (e) => { this.handleUserInput(e) }
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }
                                                                <Row>
                                                                    <br />
                                                                    <Col xs={12} sm={12} md={12}>
                                                                        <Button bsStyle="primary" style={{ width: "100%" }} fill type="submit" disabled={!this.state.Customer.FormValid || this.state.isSubmitting || this.state.isPaid || this.state.ShowProgressBar}>
                                                                            CHARGE
                                                                            </Button>
                                                                    </Col>
                                                                </Row>
                                                            
                                                            
                                                        </div>
                                                    }
                                                />
                                            </form>
                                        }

                                        {this.state.IsThreeStepAuthentication == true && this.state.IsThreeDSecureInterface == false && 
                                            <div style={{ display: this.state.IsCreditCard ? "block" : "none" }}>
                                                {this.state.ShowProgressBar &&
                                                    <div style={{ marginBottom: "2px" }}>
                                                        <CustomProgressBar></CustomProgressBar>                                                   
                                                    </div>
                                                }
                                                {this.state.StatusCode == 0 &&
                                                    <div>
                                                        <p style={styles.successResponse}>{this.state.StatusMessage} </p><br/>
                                                    </div>
                                                }
                                                {this.state.StatusCode != 0 &&
                                                    <div>
                                                        <p style={styles.errorResponse}>{this.state.StatusMessage} </p><br/>
                                                    </div>
                                                }                                               
                                                {                                                        
                                                <Wizard
                                                    wizardCallback={this.handleWizardCallback}
                                                    setForm={this.state.threeStepForm}
                                                    amountToCharge={this.state.amountToCharge}
                                                    isActionTypeCC={this.state.IsCreditCard}>
                                                </Wizard>
                                                }
                                                <div style={{ display: "block" }}>
                                                    <h1></h1>

                                                </div>
                                            </div>
                                        }
                                        {/* After the ThreeDSecureUI is started, it will display an interface prompting the user for a password. When completed, the ThreeDSecureUI will emit a complete event */}
                                        
                                        <div id="gatewayJSProgressBar" style={{ display:"none", marginBottom: "2px" }}>
                                            <CustomProgressBar></CustomProgressBar>
                                        </div>  
                                        <p id="gatewayJSSuccessMessage" style={styles.successResponse}></p>
                                        <p id="gatewayJSErrorMessage" style={styles.errorResponse}></p>
                                              
                                        <div id="body"></div>
                                    </div>
                                }                               
                            </Col>
                        </Row>
                    }
                />
            </div>
        );
    }
}

export default Payment;