import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Tab,
    Nav,
    Alert,
    NavItem,
    Tooltip,

} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { BASE_URL, StorageEnum } from "utils/constant.js"
import NetworkService from "services/NetworkService";
import Common from "utils/Common.js";


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormValid: true,
            ShowProgressBar: false,
            errorMessage: "",
            errorCode: "0",
            senderNameErrorMessage: "",
            senderNameState: "",
            senderEmailErrorMessage: "",
            senderEmailState: "",
            subjectErrorMessage: "",
            subjectState: "",
            bodyErrorMessage: "",
            bodyState: "",            
            invoiceTemplate: {
                template_id: 1,
                template_color: "blue",
            },
            Profile: {
                Company: {
                    Name: "",
                    Phone: "",
                    Address: "",
                    Country: "",
                    City: "",
                    State: "",
                    PostalCode: "",
                    WebSite: "",
                    Fax: "",
                },
                User: {
                    Email: "",
                    FirstName: "",
                    LastName: "",
                }
            },
            FirstNameState: "",
            LastNameState: "",
            websiteState: "",
            companynameState: "",
            PhoneNumberState: "",
            FirstNameErrorMessage: "",
            LastNameErrorMessage: "",
            PhoneNumberErrorMessage: "",
            currentPassword: "",
            currentPasswordState: "",
            currentPasswordErrorMessage: "",
            newPassword: "",
            newPasswordState: "",
            newPasswordErrorMessage: "",
            confirmNewPassword: "",
            confirmNewPasswordState: "",
            confirmNewPasswordErrorMessage: "",
            isPasswordFormValid: false,
            addNewTemplateToolTip: <Tooltip id="addNewTemplateToolTip">Add new template</Tooltip>

        };

    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.getProfileRequest();
    }

    //#region Profile
    // GET request to fetch merchant profile
    getProfileRequest() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/merchant/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": access_token
            },
        }).then(response => {
            return response.json()
        }).then(data => {
            if (data != null) {

                this.setState({ ShowProgressBar: false });
                if (data.statuscode === 0) {

                    const companyData = data.data.company;
                    const userData = data.data.user;

                    const company = { ...this.state.Profile.Company };
                    company.Name = companyData.name;
                    company.Phone = companyData.phone_number;
                    company.Address = companyData.address;
                    company.Country = companyData.country;
                    company.City = companyData.city;
                    company.State = companyData.state;
                    company.PostalCode = companyData.postal_code;
                    company.Fax = companyData.fax;
                    company.WebSite = companyData.website;

                    const user = { ...this.state.Profile.User }
                    user.Email = userData.email;
                    user.FirstName = userData.first_name;
                    user.LastName = userData.last_name;

                    this.setState({ Profile: { Company: company, User: user } });
                }
                else {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                }
            }
        }).catch(error => {
            // this.successAlert.bind(this);
            console.log(error);
            this.setState({ ShowProgressBar: false });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });
    }

    handlerProfileSave = (profileInfo) => {
        this.postProfileRequest(profileInfo);
    }

    // POST request for profile update
    postProfileRequest = (profileInfo) => {
        if (this.isProfileValidated()) {
            this.setState({ ShowProgressBar: true });
            this.setState({ errorMessage: "" });
            const auth_token = localStorage.getItem(StorageEnum.UUID);
            const url = BASE_URL + "api/v1/merchant/";

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + auth_token
                },
                body: JSON.stringify({
                    "name": profileInfo.Company.Name,
                    "phone": profileInfo.Company.Phone,
                    "fax": profileInfo.Company.Fax,
                    "website": profileInfo.Company.WebSite,
                    "address": profileInfo.Company.Address,
                    "city": profileInfo.Company.City,
                    "state": profileInfo.Company.State,
                    "country": profileInfo.Company.Country,
                    "postalcode": profileInfo.Company.PostalCode,
                    "first_name": profileInfo.User.FirstName,
                    "last_name": profileInfo.User.LastName,
                })
            }).then(response => {
                return response.json()
            }).then(data => {
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode === 0) {
                        console.log(data.data);
                        this.setState({ errorMessage: "Profile saved successfully." });
                    }
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                console.log(error)
            });
        }

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    profileValueChange(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }

    ValidateProfileEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                EmailErrorMessage: (
                    <small className="text-danger">
                        Invalid Email Address.
                    </small>
                )
            });
            this.setState({
                EmailState: "error"
            });
        }
        else {
            this.setState({ EmailState: "valid" });
            this.setState({ EmailErrorMessage: null });
        }

    }

    AddressChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Address: val
                }
            }
        }))
    }

    selectCountry(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    Country: val
                }
            }
        }))
    }

    selectRegion(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    State: val
                }
            }
        }))     
    }

    CityChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    City: val
                }
            }
        }))
    }

    PostalCodeChange(val) {
        this.setState(prevState => ({
            ...prevState,
            Profile: {
                ...prevState.Profile,
                Company: {
                    ...prevState.Profile.Company,
                    PostalCode: val
                }
            }
        }))
    }

    isProfileValidated() {
        let isValid = true;
        if (this.state.Profile.User.FirstName === ""){
            this.setState({
                FirstNameErrorMessage: (
                    <small className="text-danger">
                        First Name is Required.
                    </small>
                )
            });
            this.setState({ FirstNameState: "error" });
            isValid = false;
        }
        else if(this.state.FirstNameState === "error") {
            this.setState({
                FirstNameErrorMessage: (
                    <small className="text-danger">
                        Invalid First Name.
                    </small>
                )
            });
            isValid = false;
        }
        else {
            this.setState({ FirstNameErrorMessage: null });            
        }

        if (this.state.Profile.User.LastName === "") {
            this.setState({
                LastNameErrorMessage: (
                    <small className="text-danger">
                        Last Name is Required.
                    </small>
                )
            });
            this.setState({ LastNameState: "error" });
            isValid = false;
        }
        else if(this.state.LastNameState === "error"){
            this.setState({
                LastNameErrorMessage: (
                    <small className="text-danger">
                        Invalid Last Name.
                    </small>
                )
            });
            isValid = false;
        }
        else {
            this.setState({ LastNameErrorMessage: null });
        }       

        if (this.state.Profile.Company.Phone === "") {
            this.setState({
                PhoneNumberErrorMessage: (
                    <small className="text-danger">
                        Phone Number is Required.
                    </small>
                )
            });
            this.setState({ PhoneNumberState: "error" });
            isValid = false;
        }
        else if(this.state.PhoneNumberState === "error"){
            this.setState({
                PhoneNumberErrorMessage: (
                    <small className="text-danger">
                        Invalid Phone Number.
                    </small>
                )
            });
            this.setState({ PhoneNumberState: "error" });
            isValid = false;
        }
        else {
            this.setState({ PhoneNumberErrorMessage: null });            
        }

        return isValid;
    }
    //#endregion

    //#region Reset Password
    saveChangePassword() {        
        if (this.ValidatePasswords()) {
            const password_info = {
                current_password: this.state.currentPassword,
                new_password: this.state.newPassword,
                confirm_password: this.state.confirmNewPassword
            };

            this.setState({ ShowProgressBar: true });
            NetworkService.postChangePasswordRequest(password_info)
                .then(data => {
                    if (data != null) {
                        this.setState({ ShowProgressBar: false });
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });   
                        if(data.statuscode === 0){
                            this.setState({currentPassword:""});
                            this.setState({newPassword: ""});
                            this.setState({confirmNewPassword:""});
                        }                    
                    }
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                })
                .catch(error => {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                    setTimeout(() => {
                        this.setState({ errorMessage: "" })
                    }, 5000);
                })
        }
    }

    ValidatePasswords() {  
        this.setState({isPasswordFormValid: true});
        var passwordRE = new RegExp(Common.getPasswordRegex());
        if (passwordRE.test(this.state.currentPassword) === false) {
             this.setState({currentPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
        } 
        else {
            this.setState({ currentPasswordErrorMessage: null});
        }

        if (passwordRE.test(this.state.newPassword) === false) {            
            this.setState({newPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
        }
        else {
            this.setState({ newPasswordErrorMessage: null});            
        }

        if (passwordRE.test(this.state.confirmNewPassword) === false) {            
            this.setState({confirmNewPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
        }
        else {
            this.setState({ confirmNewPasswordErrorMessage: null});            
        }

        if (this.state.isPasswordFormValid && this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState({
                confirmNewPasswordErrorMessage: (
                    <small className="text-danger">
                        New Password and Confirm Password do not match.
                    </small>
                )
            });
            this.state.isPasswordFormValid = false;
        }       
        
        return  this.state.isPasswordFormValid;           
    }
    //#endregion

    handleBodyChange(html, text) {
        // html is the new html content
        // text is the new text content
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                body: html
            }
        }));

        this.setState({ bodyState: html !== "" ? "valid" : "" });
        this.setState({ bodyErrorMessage: html !== "" ? null : <small className="text-danger"> Body is Required. </small> });
    }

    handleEditorReady(editor, bodyHTML) {
        // this is a reference back to the editor if you want to
        // do editing programatically
        var div = document.createElement("div");
        div.innerHTML = bodyHTML;
        var text = div.textContent || div.innerText || "";
        editor.insertString(text);
    }

    ValidateEmailAddress(event) {
        var value = event.target.value;
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                senderEmail: value
            }
        }));

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(event.target.value) === false) {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Invalid email address.
                    </small>
                )
            });
            this.setState({
                senderEmailState: "error"
            });
        }
        else {
            this.setState({ senderEmailState: "valid" });
            this.setState({ senderEmailErrorMessage: null });
        }

    }

    change(event, stateName, type, stateNameEqualTo) {        
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                [stateName]: value
            }
        }));
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    isValidated() {        
        if (this.state.senderNameState === "" || this.state.senderNameState === "error") {
            this.setState({
                senderNameErrorMessage: (
                    <small className="text-danger">
                        Sender Name is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderNameErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.senderEmailState === "" || this.state.senderEmailState === "error") {
            this.setState({
                senderEmailErrorMessage: (
                    <small className="text-danger">
                        Email is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ senderEmailErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.subjectState === "" || this.state.subjectState === "error") {
            this.setState({
                subjectErrorMessage: (
                    <small className="text-danger">
                        Subject is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ subjectErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        if (this.state.bodyState === "" || this.state.bodyState === "error") {
            this.setState({
                bodyErrorMessage: (
                    <small className="text-danger">
                        Body is Required.
                    </small>
                )
            });
            this.setState({ isFormValid: false });
        }
        else {
            this.setState({ bodyErrorMessage: null });
            this.setState({ isFormValid: true });
        }

        return this.state.isFormValid;

    }

    handleDefaultTemplateCheck = event => {
        const target = event.target;
        // this.setState({
        //     isDefaultTemplateChecked: target.checked
        // });
        this.setState(prevState => ({
            Email: {
                ...prevState.Email,
                isDefaultTemplateChecked: target.checked
            }
        }));
    };
    
    handlerEmailSettingsSave = (emailSettings) => {
        if (this.isValidated()) {
            const invoiceContact = {
                sender_name: emailSettings.senderName,
                sender_email: emailSettings.senderEmail,
                bcc_emails: emailSettings.bccEmails,
                cc_emails: emailSettings.ccEmails,
                subject: emailSettings.subject,
                body: emailSettings.body,
                templates: emailSettings.templates
            }
            const setting = { "InvoiceContacts": invoiceContact }

            this.postEmailSettingsRequest(setting);
        }
        else {
            console.log("form not valid");
        }
    }

    handleBccEmailsChange(tags) {
        const bcc = { ...this.state.Email };
        bcc.bccEmails = tags || []
        this.setState({ Email: bcc })
    }

    handleCCEmailsChange(tags) {
        const cc = { ...this.state.Email };
        cc.ccEmails = tags || []
        this.setState({ Email: cc })
    }

    /** API Calling */


    // POST request for invoice email setting
    postEmailSettingsRequest = (settings) => {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const url = BASE_URL + "api/v1/settings/";
        const access_token = "Token " + localStorage.getItem(StorageEnum.UUID);
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        fetch(url, {
            method: "POST",
            headers: {
                "Authorization": access_token,
                "Appid": app_id,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "Setting": settings
            })
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data != null) {
                this.setState({ errorCode: data.statuscode });
                this.setState({ errorMessage: data.statusmessage });
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: data.statuscode });
            } else {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorCode: "1" });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            }
        }).catch(error => {
            this.setState({ ShowProgressBar: false });
            this.setState({ errorCode: "1" });
            this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }


    render() {
        return (
            <div className="ii-main-content">
                <Card
                    ctFullWidth
                    content={
                        <Tab.Container id="tabs-with-dropdown" defaultActiveKey="profliekey">
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav bsStyle="tabs">                                       
                                        <NavItem eventKey="profliekey">
                                            <i className="fa fa-user" /> Profile
                                        </NavItem>
                                         <NavItem eventKey="changepassword">
                                            <i className="fa fa-lock" /> Change Password
                                        </NavItem>  
                                    </Nav>
                                </Col>
                                <Col sm={10}>
                                    <Tab.Content animation>                                        
                                        <Tab.Pane eventKey="profliekey">
                                            <Row>
                                                <Col md={12}>
                                                    {this.state.errorMessage != "" &&
                                                        <Row>
                                                            <Col md={12}>
                                                                <Alert bsStyle={this.state.errorCode === 0 ? "success" : "danger"}>
                                                                    <span>
                                                                        {this.state.errorMessage}
                                                                    </span>
                                                                </Alert>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {this.state.ShowProgressBar &&
                                                        <Row >
                                                            <Col md={12}>
                                                                <CustomProgressBar></CustomProgressBar>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <br />
                                                    <Card
                                                        content={
                                                            <div>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                                                        First Name: <span className="star">*</span>
                                                                                    </div>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="First Name"
                                                                                        name="FirstName"
                                                                                        className={this.state.FirstNameState}
                                                                                        value={this.state.Profile.User.FirstName}
                                                                                        onChange={event => {
                                                                                             this.state.Profile.User.FirstName = event.target.value;
                                                                                            this.profileValueChange(event, "FirstName", "length", 2)
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {this.state.FirstNameErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Last Name: <span className="star">*</span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="Last Name"
                                                                                        autoComplete="off"
                                                                                        name="LastName"
                                                                                        className={this.state.LastNameState}
                                                                                        value={this.state.Profile.User.LastName}
                                                                                        onChange={event => {
                                                                                            this.state.Profile.User.LastName = event.target.value;
                                                                                            this.profileValueChange(event, "LastName", "length", 2)
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {this.state.LastNameErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Company: <span className="star"></span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        disabled={false}
                                                                                        placeholder="Company"
                                                                                        name="companyname"
                                                                                        className={this.state.companynameState}
                                                                                        value={this.state.Profile.Company.Name}
                                                                                        onChange={event =>
                                                                                            {
                                                                                                const val = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    Profile: {
                                                                                                        ...prevState.Profile,
                                                                                                        Company: {
                                                                                                            ...prevState.Profile.Company,
                                                                                                            Name: val
                                                                                                        }
                                                                                                    }
                                                                                                }))
                                                                                            }                                                                                                 
                                                                                        }
                                                                                    />
                                                                                    {this.state.companynameErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Email adress: <span className="star">*</span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="email"
                                                                                        disabled={true}
                                                                                        placeholder="Enter Email"
                                                                                        name="Email"
                                                                                        className={this.state.EmailState}
                                                                                        value={this.state.Profile.User.Email}                                                                                        
                                                                                    />
                                                                                    {this.state.EmailErrorMessage}
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Phone Number: <span className="star"></span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="number"
                                                                                        placeholder="Phone Number"
                                                                                        className={this.state.PhoneNumberState}
                                                                                        name="Phone"
                                                                                        value={this.state.Profile.Company.Phone}
                                                                                        onChange={event => {
                                                                                            this.state.Profile.Company.Phone = event.target.value;
                                                                                            this.profileValueChange(event, "PhoneNumber", "length", 7);
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    {this.state.PhoneNumberErrorMessage}

                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Website:
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="Website"
                                                                                        name="Website"
                                                                                        value={this.state.Profile.Company.WebSite}
                                                                                        onChange={event =>
                                                                                            {
                                                                                                const val = event.target.value;
                                                                                                this.setState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    Profile: {
                                                                                                        ...prevState.Profile,
                                                                                                        Company: {
                                                                                                            ...prevState.Profile.Company,
                                                                                                            WebSite: val
                                                                                                        }
                                                                                                    }
                                                                                                }))
                                                                                            }                                                                                            
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Address:
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="Address"
                                                                                        name="Address"
                                                                                        value={this.state.Profile.Company.Address}
                                                                                        onChange={event => this.AddressChange(event.target.value)}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Country:
                                                                                    </ControlLabel>
                                                                                    <CountryDropdown
                                                                                        value={this.state.Profile.Company.Country}
                                                                                        id="country"
                                                                                        onChange={(val) => this.selectCountry(val)}
                                                                                        classes="form-control"
                                                                                        valueType="short"
                                                                                        whitelist={['GB', 'US', 'CA']}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        State/Region:
                                                                                    </ControlLabel>
                                                                                    <RegionDropdown
                                                                                        country={this.state.Profile.Company.Country}
                                                                                        value={this.state.Profile.Company.State}
                                                                                        defaultOptionLabel="Select State"
                                                                                        countryValueType="short"
                                                                                        id="region"
                                                                                        onChange={(val) => this.selectRegion(val)}
                                                                                        classes="form-control"
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        City:
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="City"
                                                                                        name="City"
                                                                                        value={this.state.Profile.Company.City}
                                                                                        onChange={event => this.CityChange(event.target.value)}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <FormGroup>
                                                                                    <ControlLabel>
                                                                                        Postal Code: <span className="star"></span>
                                                                                    </ControlLabel>
                                                                                    <FormControl
                                                                                        type="number"
                                                                                        placeholder="Postal Code"
                                                                                        name="postalcode"
                                                                                        value={this.state.Profile.Company.PostalCode}
                                                                                        onChange={event => this.PostalCodeChange(event.target.value)}
                                                                                        type="number"
                                                                                    />

                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        <div style={{ textAlign: "left" }}>
                                                                            <br />                                                                                                                                                    
                                                                            <Button bsStyle="primary" fill wd onClick={e => this.handlerProfileSave(this.state.Profile)}>Save Changes</Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="changepassword">
                                            <Card
                                                plain
                                                content={
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            {this.state.errorMessage != "" &&
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Alert bsStyle={this.state.errorCode == 0 ? "success" : "danger"}>
                                                                            <span>
                                                                                {this.state.errorMessage}
                                                                            </span>
                                                                        </Alert>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {this.state.ShowProgressBar &&
                                                                <Row >
                                                                    <Col md={6}>
                                                                        <CustomProgressBar></CustomProgressBar>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <br />
                                                            <Row>
                                                                <Col md={6} >
                                                                    <Card
                                                                        pink
                                                                        content={
                                                                            <div >
                                                                                <form>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>Current Password
                                                                                            <span className="star"> *</span>
                                                                                        </ControlLabel>
                                                                                        <FormControl
                                                                                            value={this.state.currentPassword}
                                                                                            placeholder="Current Password"
                                                                                            autoComplete="off"
                                                                                            name="currentPassword"
                                                                                            type="password"
                                                                                            className={this.state.currentPasswordState}
                                                                                            onChange={event => {
                                                                                                this.setState({ currentPassword: event.target.value });
                                                                                                this.setState({ currentPasswordState: "" });
                                                                                                this.setState({ "currentPasswordErrorMessage": null });
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.currentPasswordErrorMessage}
                                                                                    </FormGroup>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>New Password  <span className="star">*</span></ControlLabel>
                                                                                        <FormControl
                                                                                            value={this.state.newPassword}
                                                                                            placeholder="New Password"
                                                                                            autoComplete="off"
                                                                                            name="newPassword"
                                                                                            type="password"
                                                                                            className={this.state.newPasswordState}
                                                                                            onChange={event => {
                                                                                                this.setState({ newPassword: event.target.value });
                                                                                                this.setState({ newPasswordState: "" });
                                                                                                this.setState({ newPasswordErrorMessage: null });
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.newPasswordErrorMessage}
                                                                                    </FormGroup>
                                                                                    <FormGroup>
                                                                                        <ControlLabel>Confirm Password  <span className="star">*</span></ControlLabel>
                                                                                        <FormControl
                                                                                            value={this.state.confirmNewPassword}
                                                                                            placeholder="Confirm Password"
                                                                                            autoComplete="off"
                                                                                            name="confirmNewPassword"
                                                                                            type="password"
                                                                                            className={this.state.confirmNewPasswordState}
                                                                                            onChange={event => {
                                                                                                this.setState({ confirmNewPassword: event.target.value });
                                                                                                this.setState({ confirmNewPasswordState: "" });
                                                                                                this.setState({ confirmNewPasswordErrorMessage: null });
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {this.state.confirmNewPasswordErrorMessage}
                                                                                    </FormGroup>

                                                                                    <br />
                                                                                    <div style={{ textAlign: "left" }}>
                                                                                        <Button bsStyle="primary" fill wd onClick={e => this.saveChangePassword()}>Save Changes</Button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                }
                                            />
                                        </Tab.Pane>                                        
                                        <Tab.Pane eventKey="template">
                                            <Card
                                                content={
                                                    <div>
                                                    </div>
                                                }

                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    }
                />
            </div>
        );
    }
}


export default Settings;