import React, { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Tooltip,
    OverlayTrigger,
    InputGroup,
    Modal,
     Alert,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import Select from "react-select";
import Datetime from "react-datetime";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import MenuItem from 'react-bootstrap/es/MenuItem';
import SplitButton from 'react-bootstrap/es/SplitButton';

class CreateInvoice extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            showNewItemPopupModal: false,
            showNewCustomerPopupModal: false,
            isFormValid: false,
            errorMessage: "",
            errorCode: 1,
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            customerEmailState: "",
            invoiceMessage: "",
            invoiceNumberState: "",
            invoiceNumberErrorMessage: "",
            customers: [],
            terms: [],
            products: [],
            rows: [
                {
                    product: "",
                    description: "",
                    quantity: 1,
                    rate: 0.00,
                    amount: 0.00,
                    taxable: false,
                }
            ],
            taxRates: [
            ],    
            taxcodes:[                
            ],
            invoiceDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            dueDate: new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()),
            customer: "",
            customerEmail: "",
            customerEmailErrorMessage: "",
            invoiceNumber: "",
            term: "",
            taxrate: "",
            total: 0.00,
            subtotal: 0.00,
            taxableSubtotal: 0.00,
            taxAmount: 0.00,
            showTotalBalanceRow: false,
            customTxnNumbers: true,

            Invoice: {
                TotalAmt: 0,
                TxnDate: "",
                DueDate: "",
                DocNumber: "",
                Line: [
                    {
                        Amount: 0,
                        DetailType: "",
                        Description: "",
                    }
                ],
                BillEmail: {
                    Address: ""
                },
                SalesTermRef: {
                    value: ""
                },
                CustomerMemo: {
                    value: ""
                },
                CustomerRef: {
                    value: "",
                    name: ""
                },
                TxnTaxDetail: {
                    TotalTax:0.00,
                    TxnTaxCodeRef: {
                        value:"",
                        name:""
                    },
                    TaxLine: [
                        {
                            DetailType: "TaxLineDetail", //TaxLineDetail
                            Amount: 0,
                            TaxLineDetail: {
                                TaxRateRef: {
                                  // value: "",
                                  // name: ""
                                   value: 0
                                },
                                PercentBased: true,
                                TaxPercent: 0,
                                NetAmountTaxable: 0
                            }
                        }
                    ]
                },
                SendEmail: false
            },
            customerInfo: {
                customerName: "",
                customerEmail: "",
                customerCompany: "",
                customerPhone: "",
                customerFax: "",
                customerWebsite: "",
                customerNote: "",
                isShippingAddressSameAsBillingAddress: true,
                billingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""
                },
                shippingAddress: {
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    postalCode: ""    
                },
                newCustomerNameState: "",
                newCustomerNameErrorMessage: "",
                newCustomerEmailState: "",
                newCustomerEmailErrorMessage: ""
            },
            isNewCustomerFormValid: false,
            itemInfo:{
                name: "",
                sku: "",
                price: 0.00,
                itemType:[        
                     { value: "", label: "Select Type", isDisabled: true },           
                     { value: "Service", label: "Service" },
                     { value: "NonInventory", label: "Non Inventory" },
                     { value: "Inventory", label: "Inventory" }
                ],
                selectedItemType: { value: "service", label: "Service" },
                category:[],
                selectedCategory: {value: "none", label: "None"},
                salesInformation:"",
                expenseAccount:[],
                selectedExpenseAccount: { value: "", label: "" },
                incomeAccount:[],
                selectedIncomeAccount:  {value: "", label: ""},
                inventoryAssetAccount:[],
                selectedInventoryAssetAccount: {value: "", label: ""},
                quantityOnHand: 0,
                quantityOnHandDate: new Date(),
                quantityOnHandDateServer: "",
                nameState: "",
                nameErrorMessage: "",
                quantityErrorMessage: "",
                quantityState: "",
                isInvenotryItem: false,
            },
            isNewItemFormValid: false,
            isItemTypeInventory: false,
        };

        this.invoiceDateHandler = this.invoiceDateHandler.bind(this);
        this.dueDateHandler = this.dueDateHandler.bind(this);
        this.quantityOnHandDateHandler = this.quantityOnHandDateHandler.bind(this);
    }

    handleOpenNewItemPopup(e) {
     
        this.state.itemInfo.name = "";
        this.state.itemInfo.sku = "";
        this.state.itemInfo.price = 0.00;                
        this.state.itemInfo.selectedItemType = this.state.itemInfo.itemType[0];               
        this.state.itemInfo.selectedCategory = this.state.itemInfo.category[0];
        this.state.itemInfo.salesInformation = "";                
        this.state.itemInfo.quantityOnHand = 0;
        this.state.itemInfo.quantityOnHandDate = new Date();
        this.state.itemInfo.quantityOnHandDateServer = "";
        this.state.itemInfo.nameState = "";
        this.state.itemInfo.nameErrorMessage = "";
        this.state.itemInfo.quantityErrorMessage = "";
        this.state.itemInfo.quantityState = "";
        this.state.itemInfo.isInvenotryItem = false;
        this.state.isNewItemFormValid = false;

        this.setState({showNewItemPopupModal: true});

        this._getAccountRequest();
        // this._getCategoryTypeItemRequest();
    }

    handleOpenNewCustomerPopup(e){        
        this.setState({ShowPopupProgressBar:false});
        this.state.customerInfo.customerName =  "";
        this.state.customerInfo.customerEmail =  "";
        this.state.customerInfo.customerCompany =  "";
        this.state.customerInfo.customerPhone =  "";
        this.state.customerInfo.customerFax =  "";
        this.state.customerInfo.customerWebsite =  "";
        this.state.customerInfo.customerNote =  "";
        this.state.customerInfo.shippingAddress.address =  "";
        this.state.customerInfo.billingAddress.address = "";
        this.state.customerInfo.shippingAddress.country =  "";
        this.state.customerInfo.billingAddress.country =  "";
        this.state.customerInfo.shippingAddress.state =  "";
        this.state.customerInfo.billingAddress.state=  "";
        this.state.customerInfo.shippingAddress.city =  "";
        this.state.customerInfo.billingAddress.city =  "";
        this.state.customerInfo.shippingAddress.postalCode =  "";
        this.state.customerInfo.billingAddress.postalCode =  "";
        this.state.customerInfo.newCustomerNameState = "";
        this.state.customerInfo.newCustomerNameErrorMessage = "";
        this.state.customerInfo.newCustomerEmailState = "";
        this.state.customerInfo.newCustomerEmailErrorMessage = "";
        this.state.isNewCustomerFormValid = false;
        this.setState({showNewCustomerPopupModal: true});
    }

    componentDidMount() {
        document.getElementById("loaderDiv").style.display = "none";
        this.SetInvoiceDates();
        this._getItemListingRequest();
        this._getTermsRequest();
        this._getTaxCodeRequest();               
        this._getPreferencesRequest();
        this._getClassesRequest();
        this._getCustomerRequest();       
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 7000);
        
    }

    /** HANDLER */
    invoiceDateHandler = (date) => {
        this.setState({ invoiceDate: date.format("MM/DD/YYYY") });
        this.setState(prevState => ({
            Invoice: {
                ...prevState.Invoice,
                TxnDate: date.format("YYYY-MM-DD")
            }
        }));
    };

    dueDateHandler = (date) => {
        this.setState({ dueDate: date.format("MM/DD/YYYY") });
        this.setState(prevState => ({
            Invoice: {
                ...prevState.Invoice,
                DueDate: date.format("YYYY-MM-DD")
            }
        }));
    };

    
    quantityOnHandDateHandler = (date) => {
        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                quantityOnHandDate: date.format("MM/DD/YYYY")
            }
        }));
        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                quantityOnHandDateServer: date.format("YYYY-MM-DD")
            }
        }));
    };

    SetInvoiceDates() {
        this.setState(prevState => ({
            Invoice: {
                ...prevState.Invoice,
                TxnDate: this.formatDate(this.state.invoiceDate)
            }
        }));

        this.setState(prevState => ({
            Invoice: {
                ...prevState.Invoice,
                DueDate: this.formatDate(this.state.dueDate)
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                quantityOnHandDateServer: this.formatDate(this.state.itemInfo.quantityOnHandDate)
            }
        }));
    }

    formatDate(date, format) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if(format === undefined || format == null){       
            return [year, month, day].join('-');
        }
        else if(format === "MM/DD/YYYY"){
            return [month, day, year].join('/');
        }
    }

    change(event, stateName, type, stateNameEqualTo) {
        const value = event.target.value;
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "valid" });
                    this.setState({ [stateName + "ErrorMessage"]: null });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }

        this.setState({ [stateName]: value });

    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    handleTermChanged(term){
        var selectedTerm = term.label;
        var date = new Date();
        switch (selectedTerm) {
            case "Due on receipt":   
                date =  new Date(date.getFullYear(), date.getMonth(), date.getDate());
                 this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                 
                });              
                break;
             case "Net 10":   
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 10);                        
                this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                 
                });               
                break;    
            case "Net 15":   
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 15);                        
                this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                 
                });               
                break;
             case "Net 20":   
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 20);                        
                this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                 
                });               
                break;  
            case "Net 25":   
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 25);                        
                this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                 
                });               
                break;      
            case "Net 30":   
                date = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());                        
                this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                 
                });   
                break;
            case "Net 45":   
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 45);                        
                this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                 
                });               
                break;      
            case "Net 60":   
                 date = new Date(date.getFullYear(), date.getMonth() + 2, date.getDate())          
                 this.setState({
                    dueDate: this.formatDate(date, "MM/DD/YYYY")                     
                });     
                break;            
            default:
                break;
        }        
        
    }

    handTaxRateChange(taxRate){

        const rows = [...this.state.rows];
        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(taxRate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        });  

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

         this.state.Invoice.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        var taxRefCode = {
                        value : taxRate.value,
                        name : taxRate.name
                      } 

        const txnTaxDetail = this.state.Invoice.TxnTaxDetail;
        txnTaxDetail.TxnTaxCodeRef = taxRefCode;
        txnTaxDetail.TotalTax = this.state.taxAmount;
        var detailType = txnTaxDetail.TaxLine[0].DetailType; 
        txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
        txnTaxDetail.TaxLine[0][detailType].TaxRateRef = taxRefCode;
        txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(taxRate.taxValue);
        txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

        this.setState({ txnTaxDetail });
        

        const lineItems = [...this.state.Invoice.Line];
        lineItems.forEach(lineItem => {
             var detailType = lineItem.DetailType;
             if(lineItem.DetailType != ""){
                     taxRefCode = { value: "NON" }
                      lineItem[detailType].TaxCodeRef = taxRefCode;
            }
        });    

        lineItems.forEach(lineItem => {
             var detailType = lineItem.DetailType;
            rows.forEach(element => {
                if (lineItem.DetailType != "" && element.taxable && lineItem[detailType].ItemRef.value === element.product.value && parseFloat(element.amount) === parseFloat(lineItem.Amount)) {
                    taxRefCode = { value: "TAX" }                 
                   lineItem[detailType].TaxCodeRef = taxRefCode;
                   return false;
                }
             });           
        });  
       this.setState({ lineItems });
       this.setState({ rows });        
        
    }

    ValidateEmailAddress(event, name) {
        var value = "";
        if(name === "customerEmail" || name === "newCustomerEmail")
        {
            value = event.target.value;
        }
        else if(name === "customers"){
            value = event.PrimaryEmailAddr.Address;
        }

        if(name === "newCustomerEmail"){
             this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        customerEmail:  value
                    }
                }));
        }
        else{
            this.setState(prevState => ({
                ...prevState,
                Invoice: {
                    ...prevState.Invoice,                   
                    BillEmail: {
                        ...prevState.Invoice.BillEmail,
                        Address: value
                    }
                }
                
            }));
            this.setState({ customerEmail: value });
        }

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value) === false) {
            if(name === "newCustomerEmail"){
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailErrorMessage: (
                            <small className="text-danger">
                                Invalid email address.
                            </small>
                        )
                    }
                }));
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState: "error"
                    }
                }));                
               
                this.setState({ isNewCustomerFormValid: false });                            
            }
            else{
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Invalid email address.
                        </small>
                    )
                });
                this.setState({
                    customerEmailState: "error"
                });
                this.setState({ isFormValid: false });
            }
        }
        else {
            if(name === "newCustomerEmail"){
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState:  "valid",
                        newCustomerEmailErrorMessage: ""
                    }
                }));
              if(this.state.customerInfo.newCustomerNameState === "valid"){
                    this.setState({ isNewCustomerFormValid: true });
                }   
            }
            else{
                this.setState({ customerEmailState: "valid" });
                this.setState({ customerEmailErrorMessage: "" });
                this.setState({ isFormValid: true });
            }
        }

    }

    validateNewCustomer(){
        if (this.state.customerInfo.newCustomerEmailErrorMessage === "") {
            if (this.state.customerInfo.newCustomerEmailState === "" || this.state.customerInfo.newCustomerEmailState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailErrorMessage: (
                            <small className="text-danger">
                                Email is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerEmailState:  "valid",
                        newCustomerEmailErrorMessage: ""
                    }
                }));                
            }
        }

        if (this.state.customerInfo.newCustomerNameErrorMessage === "") {
            if (this.state.customerInfo.newCustomerNameState === "" || this.state.customerInfo.newCustomerNameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerNameErrorMessage: (
                            <small className="text-danger">
                                Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewCustomerFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    customerInfo: {
                        ...prevState.customerInfo,
                        newCustomerNameState:  "valid",
                        newCustomerNameErrorMessage: ""
                    }
                }));                
            }
        }       

        if(this.state.customerInfo.newCustomerEmailState === "valid" && this.state.customerInfo.newCustomerNameState === "valid"){
            this.setState({ isNewCustomerFormValid: true });
        }
        else{
            this.setState({ isNewCustomerFormValid: false });
        }
    }

    validateNewItem() {
        if (this.state.itemInfo.nameErrorMessage === "") {
            if (this.state.itemInfo.nameState === "" || this.state.itemInfo.nameState === "error") {              
                 this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameErrorMessage: (
                            <small className="text-danger">
                                Item Name is Required.
                            </small>
                        )
                    }
                }));
                this.setState({ isNewItemFormValid: false });
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    itemInfo: {
                        ...prevState.itemInfo,
                        nameState:  "valid",
                        nameErrorMessage: ""
                    }
                }));                
            }
        }

        if(this.state.itemInfo.selectedItemType.value === "Inventory"){
            if(this.state.itemInfo.quantityOnHand !== "")
            {
                if(parseInt(this.state.itemInfo.quantityOnHand) !== "NAN"){                    
                     this.state.itemInfo.quantityState = "valid";   
                     this.state.itemInfo.quantityErrorMessage = "";                    
                }
                else{
                     this.state.itemInfo.quantityState = "error";   
                     this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Invalid Quantity.</small>;                      
                }
            } 
            else{
                 this.state.itemInfo.quantityState = "error";   
                 this.state.itemInfo.quantityErrorMessage = <small className="text-danger">Quantity is Required.</small>;                 
            }
        }
        else{
             this.state.itemInfo.quantityState = "valid";   
             this.state.itemInfo.quantityErrorMessage = "";
        }

        

        if(this.state.itemInfo.nameState === "valid" && this.state.itemInfo.quantityState === "valid"){
            this.setState({ isNewItemFormValid: true });
        }
        else{
            this.setState({ isNewItemFormValid: false });
        }

    }

    isValidated() {
        // if (this.state.senderNameState === "" || this.state.senderNameState === "error") {
        //     this.setState({
        //         senderNameErrorMessage: (
        //             <small className="text-danger">
        //                 Sender Name is Required.
        //             </small>
        //         )
        //     });
        //     this.setState({ isFormValid: false });
        // }
        // else {
        //     this.setState({ senderNameErrorMessage: null });
        //     this.setState({ isFormValid: true });
        // }

        if (this.state.customerEmailErrorMessage === "") {
            if (this.state.customerEmailState === "" || this.state.customerEmailState === "error") {
                this.setState({
                    customerEmailErrorMessage: (
                        <small className="text-danger">
                            Email is Required.
                        </small>
                    )
                });
                this.setState({ isFormValid: false });
            }
            else {
                this.setState({ customerEmailErrorMessage: null });
                this.setState({ isFormValid: true });
            }
        }
        else if(this.state.customerEmailState === "error")
        {
             this.setState({ isFormValid: false });
        }

        return this.state.isFormValid;

    }

    handleRowProductNameChange = idx => value => {
        const rows = [...this.state.rows];
        rows[idx].product = value;
        rows[idx].rate = value.rate;
        rows[idx].quantity = value.quantity;
        rows[idx].taxable = value.taxable;
        if( rows[idx].rate != undefined && rows[idx].quantity != undefined){
            rows[idx].amount =  (parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)).toFixed(2);
        }
        else{
            rows[idx].amount = 0.00;
        }
        rows[idx].description = value.description;

        var detailType = value.DetailType;
       
        var taxRefCode = {};
        if(value.taxable){
            taxRefCode = { value : "TAX" }
        }
        else{
           taxRefCode = { value: "NON" }
        }
        const lineItems = [...this.state.Invoice.Line];
        lineItems[idx].DetailType = detailType;
        lineItems[idx][detailType] = {
            ItemRef: { value: value.value, name: value.label },
            // Qty: "1",
            // UnitPrice: 100,
            TaxCodeRef: taxRefCode
        };

        if (lineItems[idx].DetailType != "" && rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            lineItems[idx].Amount = parseFloat(rows[idx].amount);
            lineItems[idx][detailType].Qty = parseInt(rows[idx].quantity);
            lineItems[idx][detailType].UnitPrice = parseFloat(parseFloat(rows[idx].rate).toFixed(2));
        }

        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                if(!isNaN(this.state.taxrate.taxValue)){
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                }    
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        }); 

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

        this.state.Invoice.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        if (this.state.total > 0) {
            this.setState({
                showTotalBalanceRow: true
            });
        }
        else {
            this.setState({
                showTotalBalanceRow: false
            });
        }

        if(this.state.taxRates.length > 1){
            const txnTaxDetail = this.state.Invoice.TxnTaxDetail;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;        
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
        }

        this.setState({ lineItems });
        this.setState({ rows });
    }

    handleRowDescriptionChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx].description = value;

        const lineItems = [...this.state.Invoice.Line];
        lineItems[idx].Description = value;

        this.setState({ lineItems });
        this.setState({ rows });
    }
    
    handleAddRow = () => {
        const rowItem = {
            product: "",
            description: "",
            quantity: "",
            rate: "",
            amount: "",
            taxable: false,
        };
        this.setState({
            rows: [...this.state.rows, rowItem]
        });

        const lineItem = {
            Amount: 0,
            DetailType: "",
            Description: "",
        }

        this.setState(prevState => ({
            ...prevState,
            Invoice: {
                ...prevState.Invoice,
                Line: [...this.state.Invoice.Line, lineItem]
            }
        }));

    };

    handleRemoveRow = () => {
        if (this.state.rows.length > 1) {
            this.setState({
                rows: this.state.rows.slice(0, -1)
            });

            this.setState(prevState => ({
                ...prevState,
                Invoice: {
                    ...prevState.Invoice,
                    Line: this.state.Invoice.Line.slice(0, -1)
                }
            }));
        }
    };

    handleRowValueChange = idx => e => {
        let { name, value } = e.target;        
        const rows = [...this.state.rows];
        if(name === "TaxCheckBox"){
            value = e.target.checked;
            rows[idx].taxable = value;
        }
        rows[idx][name] = value;
        if (rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            rows[idx].amount = (parseFloat(rows[idx].rate) * parseInt(rows[idx].quantity)).toFixed(2);
        }

        this.state.taxableSubtotal = 0.00;
        this.state.subtotal = 0.00;
        this.state.taxAmount = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)  && element.taxable) {
                this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                if(!isNaN(this.state.taxrate.taxValue)){
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                }
            } 
            else if(!isNaN(element.amount)){
                 this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
            }   
        });            

        this.state.total = 0.00;
        rows.forEach(element => {
            if (!isNaN(element.amount)) {
                this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
            }
        });

         this.state.Invoice.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));

        if (this.state.total > 0) {
            this.setState({
                showTotalBalanceRow: true
            });
        }
        else {
            this.setState({
                showTotalBalanceRow: false
            });
        }
        var taxRefCode = {};
         if( rows[idx].taxable){
             taxRefCode = { value: "TAX" }
        }
        else{
           taxRefCode = { value: "NON" }
        }

        const lineItems = [...this.state.Invoice.Line];
        if (lineItems[idx].DetailType != "" && rows[idx].rate != undefined && rows[idx].quantity != undefined) {
            var detailType = lineItems[idx].DetailType;
            lineItems[idx].Amount = parseFloat(rows[idx].amount);
            lineItems[idx][detailType].Qty = parseInt(rows[idx].quantity);
            lineItems[idx][detailType].UnitPrice = parseFloat(parseFloat(rows[idx].rate).toFixed(2));

            lineItems[idx][detailType].TaxCodeRef = taxRefCode;
        }

        if(this.state.taxRates.length > 1){
            const txnTaxDetail = this.state.Invoice.TxnTaxDetail;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;        
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;
            this.setState({ txnTaxDetail });
        }

        this.setState({ lineItems });

        this.setState({ rows });

    };

    handleRemoveSpecificRow = idx => () => {
        if (this.state.rows.length > 1) {
            const rows = [...this.state.rows];
            rows.splice(idx, 1);
            const lineItems = [...this.state.Invoice.Line];
            lineItems.splice(idx, 1);
            this.setState(prevState => ({
                ...prevState,
                Invoice: {
                    ...prevState.Invoice,
                    Line: lineItems
                }
            }));
           
            this.state.taxableSubtotal = 0.00;
            this.state.subtotal = 0.00;
            this.state.taxAmount = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)  && element.taxable) {
                    this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                    this.state.taxableSubtotal =  parseFloat((parseFloat(this.state.taxableSubtotal) + parseFloat(element.amount)).toFixed(2));
                    this.state.taxAmount =   parseFloat(parseFloat(parseFloat(this.state.taxAmount) +  ((parseFloat(this.state.taxrate.taxValue))/100) * parseFloat(element.amount)).toFixed(2));
                } 
                else if(!isNaN(element.amount)){
                    this.state.subtotal = parseFloat((parseFloat(this.state.subtotal) + parseFloat(element.amount)).toFixed(2));
                }   
            });  

            this.state.total = 0.00;
            rows.forEach(element => {
                if (!isNaN(element.amount)) {
                    this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(element.amount)).toFixed(2));
                }
            });

            this.state.Invoice.TotalAmt =  this.state.total = parseFloat((parseFloat(this.state.total) + parseFloat(this.state.taxAmount)).toFixed(2));
            
            var taxRefCode = {
                            value : this.state.taxrate.value,
                            name : this.state.taxrate.name
                        } 

            const txnTaxDetail = this.state.Invoice.TxnTaxDetail;
            txnTaxDetail.TxnTaxCodeRef = taxRefCode;
            txnTaxDetail.TotalTax = this.state.taxAmount;
            var detailType = txnTaxDetail.TaxLine[0].DetailType; 
            txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;
            txnTaxDetail.TaxLine[0][detailType].TaxRateRef = taxRefCode;
            txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(this.state.taxrate.taxValue);
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;

            this.setState({ txnTaxDetail });
            

            //const lineItems = [...this.state.Invoice.Line];
            lineItems.forEach(lineItem => {
                var detailType = lineItem.DetailType;
                if(lineItem.DetailType != ""){
                        taxRefCode = { value: "NON" }
                        lineItem[detailType].TaxCodeRef = taxRefCode;
                }
            });    

            lineItems.forEach(lineItem => {
                var detailType = lineItem.DetailType;
                rows.forEach(element => {
                    if (lineItem.DetailType != "" && element.taxable && lineItem[detailType].ItemRef.value === element.product.value && parseFloat(element.amount) === parseFloat(lineItem.Amount)) {
                        taxRefCode = { value: "TAX" }                 
                    lineItem[detailType].TaxCodeRef = taxRefCode;
                    return false;
                    }
                });           
            });  
        this.setState({ lineItems });
        this.setState({ rows });   
        }
    };

    createInvoiceSubmit = (invoice, isSendEmail) => {
        //Need to create this invoice api...
        // const intent = {
        //     "TxnDate":"",
        //     "DueDate":"",
        //     "Line":"",
        //     "TxnTaxDetails":"",
        //     "CustomerRef":"",
        //     "CustomerMemo":"",
        //     "SalesTermRef":"",
        //     "BillEmail":"",
        //     "TotalAmt":parseFloat(parseFloat(`${this.state.total}`).toFixed(2)),
        //     "DocNumber":"",
        //     "FilePath":""
        // }

        invoice.SendEmail= isSendEmail;

        this._postCreateInvoiceRequest(invoice);
    }

    createCustomerSubmit = (customer) =>
    {
        if(customer.isShippingAddressSameAsBillingAddress)
        {
            customer.shippingAddress.address = customer.billingAddress.address;
            customer.shippingAddress.country = customer.billingAddress.country;
            customer.shippingAddress.state = customer.billingAddress.state;
            customer.shippingAddress.city = customer.billingAddress.city;
            customer.shippingAddress.postalCode = customer.billingAddress.postalCode;
        }

        const payload = {
            "FullyQualifiedName": null, 
            "PrimaryEmailAddr": {
              "Address": customer.customerEmail
            }, 
            "DisplayName": customer.customerName, 
            "Suffix": null, 
            "Title": null, 
            "MiddleName": null, 
            "Notes": customer.customerNote, 
            "FamilyName": null, 
            "PrimaryPhone": {
              "FreeFormNumber": customer.customerPhone
            }, 
            "CompanyName": customer.customerCompany, 
            "BillAddr": {
              "CountrySubDivisionCode": null, 
              "City": customer.billingAddress.city, 
              "PostalCode": customer.billingAddress.postalCode, 
              "Line1": customer.billingAddress.address, 
              "Country": customer.billingAddress.country
            },
            "ShipAddr": {
                "CountrySubDivisionCode": null, 
                "City": customer.shippingAddress.city, 
                "PostalCode": customer.shippingAddress.postalCode, 
                "Line1": customer.shippingAddress.address, 
                "Country": customer.shippingAddress.country
              }, 
            "GivenName": null,
            "WebAddr":{
                "URI":customer.customerWebsite
            }
          }

        this._postCreateCustomerRequest(payload);

        // //after creating customer uncomment below 
        // this.setState({ showNewCustomerPopupModal: false });
        // this.setState({ShowPopupProgressBar: false });
    }

    createItemSubmit = (item) =>
    {
        const payload = {
            "TrackQtyOnHand": this.state.itemInfo.isInvenotryItem?true:false, 
            "Name": item.name, 
            "UnitPrice": item.price,
           // "QtyOnHand": item.price, 
            "SKU": item.sku,
            "IncomeAccountRef": item.selectedIncomeAccount, 
            "AssetAccountRef": item.selectedInventoryAssetAccount, 
            "Type": item.selectedItemType.value, 
            "ExpenseAccountRef": item.selectedExpenseAccount,
            "QtyOnHand": item.quantityOnHand,
            "InvStartDate": item.quantityOnHandDate,
            "Description": item.salesInformation
          }

        this._postCreateItemRequest(payload);
        
    }

    handleCancelCreateInvoice(e) {
        this.props.history.push('/standalone/invoices');
        e.preventDefault();
    }

    /**
     * Manipulate api's response
     */

    setCustomerResult(customers) {
        const newState = [{
            value: "AddNew",
            label: <Button simple icon onClick={e => this.handleOpenNewCustomerPopup(e)}>
                <div className="spinner-grow text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Customer</i>
            </Button>,

            isDisabled: true
        }]

        customers.map(data => {
            try {
                const x = {
                    value: data["Id"],
                    label: data["DisplayName"],
                    PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"]["Address"] }
                }
                newState.push(x);
            }
            catch (why) {
                console.log(why)
            }

        });
        this.setState({ customers: newState });
    }

    setNewCustomerResult(customer){
        customer.map(data => {
            try {
                const c = {
                    value: data["Id"],
                    label: data["DisplayName"],
                    PrimaryEmailAddr: { Address: data["PrimaryEmailAddr"]["Address"] }
                }
                this.setState(prevState => ({
                    customers: [
                        ...prevState.customers,
                        c,
                    ]
                }));
            }
            catch (why) {
                console.log(why)
            }
        });        
    }

    setTermsResult(terms) {
        const newState = [];
        
        newState.push({value: "", label: "Select Term", isDisabled: true});
        terms.map(data => {
            const x = { value: data["Id"], label: data["Name"] }
            newState.push(x);
        });   
        newState.map(newTerm => 
        {
          if(newTerm.label == 'Net 30')
          {
               this.setState({ term: newTerm });
          }
        });
        if(this.state.term == "" && newState.length >= 1)
        {
            this.setState({ term: newState[1] });        
        }
        this.setState({ terms: newState });
    }

    setTaxCodesResult(taxcodes){
        const newState = [];
          taxcodes.map(data => {            
            newState.push(data);
        });  

        this.setState({ taxcodes: newState });
        this._getTaxRateRequest(); 
    }

    setTaxRatesResult(taxRates){
        const newState = [];
        newState.push({value: "", label: "Select Tax", name: "",  taxValue: "", isDisabled: true});
         
        this.state.taxcodes.map(tc =>{
             if(tc.Active && tc.SalesTaxRateList != null){
                let rv = 0
                tc.SalesTaxRateList.TaxRateDetail.map(strl =>{
                    var tr = taxRates.find( ({ Id }) => Id ===  strl.TaxRateRef.value );
                    if(tr != null){
                        rv += tr.RateValue
                    }
                })
                var id = tc.Id;
                var label = tc.Name + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +  (rv + "%") ;
                var taxValue =  rv;
                var name = tc.Name;
                const x = { value: id, label: label, name: name , taxValue: taxValue }; 
                newState.push(x);
            }
        })
        this.setState({ taxRates: newState });

        // taxRates.map(data => {
        //       
        //     var taxCode = this.state.taxcodes.find( ({ Id }) => Id ===  data["Id"] );  
        //     if(taxCode != undefined && taxCode.Active === true){
        //         var id = data["Id"];
        //         var label = taxCode.SalesTaxRateList.TaxRateDetail[0].TaxRateRef.name + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +  taxCode.SalesTaxRateList.TaxRateDetail[0].TaxRateRef.value + "%" ;
        //         var taxValue =  taxCode.SalesTaxRateList.TaxRateDetail[0].TaxRateRef.value;
        //         var name = taxCode.SalesTaxRateList.TaxRateDetail[0].TaxRateRef.name;
        //         const x = { value: id, label: label, name: name , taxValue: taxValue }; //, 
        //         newState.push(x);
        //     }
        // });   
        
        // this.setState({ taxRates: newState });
        const txnTaxDetail = this.state.Invoice.TxnTaxDetail;
        if(this.state.taxRates.length > 1){
            this.setState({ taxrate: this.state.taxRates[1] });
            txnTaxDetail.TxnTaxCodeRef = {value: this.state.taxRates[1].value, name: this.state.taxRates[1].name};
        }

        txnTaxDetail.TotalTax = this.state.taxAmount;
        var detailType = txnTaxDetail.TaxLine[0].DetailType; 
        txnTaxDetail.TaxLine[0].Amount = this.state.taxAmount;        
        if(this.state.taxRates.length > 1){
            txnTaxDetail.TaxLine[0][detailType].TaxRateRef = {value: this.state.taxRates[1].value, name: this.state.taxRates[1].name};
            txnTaxDetail.TaxLine[0][detailType].TaxPercent = parseFloat(this.state.taxRates[1].taxValue);        
            txnTaxDetail.TaxLine[0][detailType].NetAmountTaxable = this.state.taxableSubtotal;
        }

        this.setState({ txnTaxDetail });
    }

    setItemsResult(items) {
        const newState = [{
            value: "AddNew",
            label: <Button simple icon onClick={e => this.handleOpenNewItemPopup(e)}>
                <i className="fa fa-plus" style={{ color: "#0075c1" }}> Add Product/Service</i>
            </Button>,

            isDisabled: true
        }]

        items.map(data => {
            const product = {
                value: data["Id"],
                label: data["Name"],
                DetailType: "SalesItemLineDetail",
                taxable: data["Taxable"],
                quantity: 1,
                rate: data["UnitPrice"],
                description: data["Description"]
            }
            newState.push(product);
        });

        this.setState({ products: newState });
    }

    setNewItemResult(item){
        item.map(data => {
            const product = {
                value: data["Id"],
                label: data["Name"],
                DetailType: "SalesItemLineDetail",
                taxable: data["Taxable"],
                quantity: 1,
                rate: data["UnitPrice"],
                description: data["Description"]
            }
            this.setState(prevState => ({
                products: [
                    ...prevState.products,
                    product,
                ]
            }));
        });        
    }

    setPreferenceResult(preferences) {

        preferences.map(data => {
            this.setState({ customTxnNumbers: data["SalesFormsPrefs"]["CustomTxnNumbers"] })
        });
    }

    setAccountResult(accounts){
        const newState = []
        accounts.map(data => {
            newState.push({value:data.Id, label:data.Name +"|"+ data.AccountType});
        });

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                incomeAccount: newState
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                expenseAccount: newState
            }
        }));

        this.setState(prevState => ({
            itemInfo: {
                ...prevState.itemInfo,
                inventoryAssetAccount: newState
            }
        }));        
    }

    setCategoryTypeItemResult(categories){
        categories.map(data => {
            this.setState(prevState => ({
                itemInfo: {
                    ...prevState.itemInfo,
                    selectedCategory: data
                }
            }));
        });
    }
    /**
     * API Callig
     */
    _getItemListingRequest() {

        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getItemListingRequest(app_id)
            .then(data => {
               // this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                //    this.setState({ errorMessage: data.statusmessage });

                    if (data.data != null)
                        this.setItemsResult(data.data.items);
                } else {
                }
            }).catch(error => {
               // this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _postCreateItemRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateItemRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewItemPopupModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {                      
                        if (data.data != null) {                     
                            this.setNewItemResult(data.data.items);
                         }
                    }
                    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
            
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    _getTermsRequest() {
      //  this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTermsRequest(app_id)
            .then(data => {
              //  this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                //    this.setState({ errorMessage: data.statusmessage });
                    if (data.data != null)
                        this.setTermsResult(data.data.items);

                } else {
                }
            }).catch(error => {
             //   this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getTaxRateRequest() {
      //  this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTaxRateRequest(app_id)
            .then(data => {
             //   this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                 //   this.setState({ errorMessage: data.statusmessage });
                    if(data.data != null){
                        this.setTaxRatesResult(data.data.items);
                    }

                } else {
                }
            }).catch(error => {
             //   this.setState({ ShowProgressBar: false });
              //  this.setTaxRatesResult([]);
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getTaxCodeRequest() {
      //  this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getTaxCodeRequest(app_id)
            .then(data => {
             //   this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                 //   this.setState({ errorMessage: data.statusmessage });
                    if(data.data != null){
                        this.setTaxCodesResult(data.data.items);
                    }

                } else {
                }
            }).catch(error => {
             //   this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getPreferencesRequest() {
      //  this.setState({ ShowProgressBar: true });
      //  this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getPreferencesRequest(app_id)
            .then(data => {
              //  this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                  //  this.setState({ errorMessage: data.statusmessage });

                    if (data.data.Preferences != null)
                        this.setPreferenceResult(data.data.Preferences)

                } else {
                }
            }).catch(error => {
             //   this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getClassesRequest() {
      //  this.setState({ ShowProgressBar: true });
      //  this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getClassesRequest(app_id)
            .then(data => {
             //   this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });

                } else {
                }
            }).catch(error => {
             //   this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getCustomerRequest() {
      //  this.setState({ ShowProgressBar: true });
      //  this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getCustomerRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });

                    if (data.data != null) {
                        this.setCustomerResult(data.data.items);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _postCreateCustomerRequest(body) {
        this.setState({ShowPopupProgressBar: true});
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateCustomerRequest(app_id, body)
            .then(data => {

                this.setState({ ShowPopupProgressBar: false });
                this.setState({showNewCustomerPopupModal: false});

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        if (data.data != null) {
                            this.setNewCustomerResult(data.data.items);
                         }
                    }
                    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
                this.setState({showNewCustomerPopupModal: false});
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    _postCreateInvoiceRequest(payload) {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postCreateInvoiceRequest(app_id, payload)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if(data.statuscode == 0)
                    {
                        this.props.history.push({
                            pathname: '/standalone/invoices',
                            state: { errorMessage: data.statusmessage, errorCode: 0 }
                        });
                    }
                    if (data.data != null) {
                       // this.setCustomerResult(data.data.items);
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    _getAccountRequest(){        
        this.setState({ errorMessage: "" });
         this.setState({ShowPopupProgressBar:true});
        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getAccountRequest(app_id)
            .then(data => {
                 this.setState({ShowPopupProgressBar:false});    
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });
                   if(data.statuscode == 0){
                        const account = data.data.items;
                        this.setAccountResult(account);
                   }


                } else {
                }
            }).catch(error => {
                this.setState({ShowPopupProgressBar:false});  
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    _getCategoryTypeItemRequest(){
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getCategoryTypeItemRequest(app_id)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                   // this.setState({ errorMessage: data.statusmessage });
                   const categories = data.items;
                   this.setCategoryTypeItemResult(categories);

                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
    }

    render() {
        const totalLabelStyle = {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "35px"
        };

        const abc={
        width:"100px!important"
        }
        const deleteButtonToolTip = <Tooltip id="refundToolTip">Delete</Tooltip>;
        const termsToolTip = (<Tooltip style={{ fontSize: 12 }} id="terms_tooltip">Terms show how many days a customer has to pay you. You can change this number before you send the invoice. </Tooltip>);
        return (
            <div className="ii-main-content">
                {this.state.ShowProgressBar &&
                    <div style={{marginBottom:"5px"}}>
                        <CustomProgressBar></CustomProgressBar>
                    </div>
                }
                <Card
                    //  ctFullWidth
                    textCenter
                    title="New Invoice"
                    content={
                        <div>
                            {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Customer: <span className="star">*</span>
                                        </div>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="customers"
                                            isDisabled={this.state.ShowProgressBar}
                                            value={this.state.customer}
                                            onChange={value => {
                                                this.setState({ customer: value });
                                                this.setState({ customerEmail: value.PrimaryEmailAddr.Address })
                                                if (value.PrimaryEmailAddr.Address != "" && value.PrimaryEmailAddr.Address != undefined) {
                                                    this.setState({ customerEmailState: "valid" });
                                                }
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    Invoice: {
                                                        ...prevState.Invoice,
                                                        CustomerRef: {
                                                            ...prevState.Invoice.CustomerRef,
                                                            value: value.value, name: value.label
                                                        },
                                                        BillEmail: {
                                                            ...prevState.Invoice.BillEmail,
                                                            Address: value.PrimaryEmailAddr.Address
                                                        }
                                                    }
                                                    
                                                }));
                                                 this.ValidateEmailAddress(value, "customers");
                                            }
                                            }
                                            options={this.state.customers}
                                            placeholder="Select Customer"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Customer Email: <span className="star">*</span>
                                        </div>
                                        <FormControl
                                            value={this.state.customerEmail}
                                            placeholder="Customer Email"
                                            disabled={this.state.ShowProgressBar}
                                            name="customerEmail"
                                            type="email"
                                            className={this.state.customerEmailState}
                                            onChange={event =>
                                                this.ValidateEmailAddress(event, "customerEmail")
                                            }
                                            onBlur={e => this.isValidated()}
                                        />
                                        {this.state.customerEmailErrorMessage}
                                    </FormGroup>
                                </Col>
                                {this.state.customTxnNumbers &&
                                    <Col md={2}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Invoice Number: <span className="star">*</span>
                                            </div>
                                            <FormControl
                                                value={this.state.invoiceNumber}
                                                type="text"
                                                disabled={this.state.ShowProgressBar}
                                                placeholder="Invoice Number"
                                                name="invoiceNumber"
                                                className={this.state.invoiceNumberState}
                                                onChange={event => {
                                                    this.change(event, "invoiceNumber", "length", 2);
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        Invoice: {
                                                            ...prevState.Invoice,
                                                            DocNumber: value
                                                        }
                                                    }));
                                                }
                                                }
                                                onBlur={e => this.isValidated()}
                                            />
                                            {this.state.invoiceNumberErrorMessage}
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <FormGroup>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Terms:
                                            <OverlayTrigger placement="right" overlay={termsToolTip}>
                                                <i className="fa fa-question-circle"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="terms"
                                            isDisabled={this.state.ShowProgressBar}
                                            value={this.state.term}
                                            onChange={value => {
                                                this.setState({ term: value });
                                                this.handleTermChanged(value)
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    Invoice: {
                                                        ...prevState.Invoice,
                                                        SalesTermRef: {
                                                            ...prevState.Invoice.SalesTermRef,
                                                            value: value.value
                                                        }
                                                    }
                                                }));
                                            }
                                            }
                                            options={this.state.terms}
                                            placeholder="Select Term"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Invoice Date:
                                        </div>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="invoiceDate"
                                                timeFormat={false}
                                                disabled={this.state.ShowProgressBar}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "Invoice Date" }}
                                                onChange={value => this.invoiceDateHandler}
                                                value={this.state.invoiceDate}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Due Date:
                                        </div>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="dueDate"
                                                timeFormat={false}
                                                disabled={this.state.ShowProgressBar}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "Due Date" }}
                                                onChange={this.dueDateHandler}
                                                value={this.state.dueDate}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <br />
                                    <table
                                        className="table table-bordered table-hover"
                                        id="tab_logic"
                                    >
                                        <thead>
                                            <tr style={{ backgroundColor: "#eeeeee" }}>
                                                <th className="text-center" style={{ width: 35, color: "#727272", fontWeight: "bold" }}> # </th>
                                                <th className="text-center" style={{ width: 300, color: "#727272", fontWeight: "bold" }}> PRODUCT/SERVICE </th>
                                                <th className="text-center" style={{ color: "#727272", fontWeight: "bold" }}> DESCRIPTION </th>
                                                <th className="text-center" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>QTY</th>
                                                <th className="text-center" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>RATE</th>
                                                <th className="text-center" style={{ width: 120, color: "#727272", fontWeight: "bold" }}>AMOUNT</th>
                                                <th className="text-center" style={{ width: 35, color: "#727272", fontWeight: "bold" }}>TAX</th>
                                                {/* <th className="text-center"> Mobile </th> */}
                                                <th style={{ width: 30 }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.rows.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td className="text-right">{idx + 1}</td>
                                                    <td>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="product"
                                                            isDisabled={this.state.ShowProgressBar}
                                                            value={this.state.rows[idx].product}
                                                            onChange={this.handleRowProductNameChange(idx)}
                                                            // onChange={value => {
                                                            //     const rows = [...this.state.rows];
                                                            //     rows[idx].product = value;
                                                            //     this.setState({ rows });
                                                            // }
                                                            // }
                                                            options={this.state.products}
                                                            placeholder="Select Product"
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].description}
                                                            type="text"
                                                            disabled={this.state.ShowProgressBar}
                                                            placeholder="Description"
                                                            name="description"
                                                            onChange={this.handleRowDescriptionChange(idx)}
                                                        // onChange={event => {
                                                        //     const rows = this.state.rows;
                                                        //     rows[idx].description = event.target.value;
                                                        //     this.setState({ rows });
                                                        // }
                                                        //}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            value={this.state.rows[idx].quantity}
                                                            type="number"
                                                            disabled={this.state.ShowProgressBar}
                                                            placeholder="Quantity"
                                                            name="quantity"
                                                            style={{ textAlign: "center" }}
                                                            min="1"
                                                            step="1"
                                                            onChange={this.handleRowValueChange(idx)}
                                                        />
                                                    </td>
                                                    <td>
                                                    <InputGroup>
                                                        <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                        <FormControl
                                                            value={parseFloat(this.state.rows[idx].rate).toFixed(2)}
                                                            type="number"
                                                            placeholder="Rate"
                                                            name="rate"
                                                            disabled={this.state.ShowProgressBar}
                                                            min="0.00"
                                                            style={{ textAlign: "center", padding: "8px 6px" }}
                                                            step="0.01"
                                                            onChange={this.handleRowValueChange(idx)}
                                                        />
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup>  
                                                        <InputGroup.Addon style={{padding:"6px 0px",border: "1px solid #ccc", backgroundColor:"#f5f5f5"}}><i className="fa fa-dollar" /></InputGroup.Addon>
                                                        <FormControl
                                                            value={this.state.rows[idx].amount}
                                                            type="number"
                                                            placeholder="0.00"
                                                            name="amount"
                                                            min="0.00"
                                                            style={{ textAlign: "right", padding: "8px 6px" }}
                                                            readOnly={true}
                                                        />
                                                      </InputGroup>  
                                                    </td>
                                                    <td>
                                                        <CustomCheckbox
                                                                    name="TaxCheckBox"
                                                                    checked={this.state.rows[idx].taxable}
                                                                    isDisabled= {this.state.ShowProgressBar  || this.state.taxRates.length <= 1} 
                                                                    formControlProps={{
                                                                        onChange: this.handleRowValueChange(idx)
                                                                        
                                                                    }}
                                                                />
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger placement="top" overlay={deleteButtonToolTip}>
                                                            <Button round twitter onClick={this.handleRemoveSpecificRow(idx)}>
                                                                <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Row>
                                        <Col md={7}>
                                            <Button bsSize="sm" bsStyle="primary" fill style={{ marginTop: "10px" }} onClick={this.handleAddRow}>
                                                <i className="fa fa-plus"></i> Add Line
                                            </Button>
                                        </Col><div style={{ textAlign: "right" }}>
                                        <Col md={3}>
                                                    <p style={totalLabelStyle}>Subtotal</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.subtotal}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                                </div>
                                    </Row>
                                    <Row>
                                         <Col md={4}>
                                           <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em"}}>
                                              Message on Invoice:
                                            </div>
                                            <FormGroup controlId="formControlsTextarea">
                                                <FormControl
                                                    rows="2"
                                                    value={this.state.invoiceMessage}
                                                    componentClass="textarea"
                                                    bsClass="form-control"
                                                    disabled={this.state.ShowProgressBar}
                                                    placeholder="Message to display on invoice"
                                                    maxLength="500"
                                                    onChange={event => {
                                                        const msg = event.target.value;
                                                        this.setState({ invoiceMessage: msg });
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            Invoice: {
                                                                ...prevState.Invoice,
                                                                CustomerMemo: {
                                                                    ...prevState.Invoice.CustomerMemo,
                                                                    value: msg
                                                                }
                                                            }
                                                        }));
                                                    }}
                                                />
                                            </FormGroup>
                                         </Col>
                                         <Col md={3}>
                                         </Col>
                                         {this.state.taxRates.length > 1 && 
                                         <div>
                                        <Col md={3}>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em", fontSize:"13px" , textAlign: "right", fontWeight:"bold" }}>
                                                Taxable Subtotal  <span style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.taxableSubtotal}`).toFixed(2)).toFixed(2)}</span>
                                                </div>
                                                <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Select tax rate:                                            
                                            </div>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="taxRates"
                                                isDisabled={this.state.ShowProgressBar}
                                                value={this.state.taxrate}
                                                onChange={value => {
                                                    this.setState({ taxrate: value });
                                                    this.handTaxRateChange(value);                                                    
                                                }
                                                }
                                                options={this.state.taxRates}
                                                placeholder="Select Tax Rate"
                                            />
                                        </FormGroup>
                                        </Col>
                                        
                                            <Col md={1}>
                                                <br/><br/>   <br/>                                                           
                                                  <div style={{ textAlign: "right" }}>                                   
                                                   <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.taxAmount}`).toFixed(2)).toFixed(2)}</p>
                                                  </div>
                                            </Col>
                                            </div>
                                         }
                                        <Col md={1}>
                                         </Col>
                                         
                                    </Row>
                                    {/* {this.state.showTotalBalanceRow && */}
                                        <div style={{ textAlign: "right" }}>
                                            <Row>
                                                <Col md={10}>
                                                    <p style={totalLabelStyle}>Total</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10}>
                                                    <p style={totalLabelStyle}>Balance Due</p>
                                                </Col>
                                                <Col md={1}>
                                                    <p style={totalLabelStyle}>${parseFloat(parseFloat(`${this.state.total}`).toFixed(2)).toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                     {/* }                                     */}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{ textAlign: "right", marginBottom: "20px" }}>
                                        <br /> 
   
                                       
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={e => this.handleCancelCreateInvoice(e)}>Cancel</Button>
                                        <SplitButton onClick={e => this.createInvoiceSubmit(this.state.Invoice, false)} disabled={!this.state.showTotalBalanceRow || this.state.ShowProgressBar || !this.state.isFormValid} id="CreateInvoiceSplitButton" className="btn-fill" bsStyle="primary" title="Create And Exit">
                                          <MenuItem eventKey="1" id="CreateAndSendInvoice" className="btn-fill" bsStyle="primary"  onClick={e => this.createInvoiceSubmit(this.state.Invoice, true)}>Create And Send</MenuItem>
                                        </SplitButton>
                                        {/* <Button bsStyle="primary" fill wd onClick={e => this.createInvoiceSubmit(this.state.Invoice)} disabled={!this.state.showTotalBalanceRow || this.state.ShowProgressBar || !this.state.isFormValid}>Create And Exit</Button> */}
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Modal
                                show={this.state.showNewItemPopupModal}
                                onHide={() => this.setState({ showNewItemPopupModal: false })}
                                bsSize="large"       
                                //dialogClassName="modal-dialog-custom"    
                            >
                                {this.state.ShowPopupProgressBar &&
                                    <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                        <CustomProgressBar></CustomProgressBar>
                                    </div>
                                }
                                <Modal.Header closeButton>
                                    <Modal.Title>Product/Service information</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Item Name: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                        value={this.state.itemInfo.name}
                                                        type="text"
                                                        placeholder="Item Name"
                                                        name="name"
                                                        onChange={event => {
                                                            var value = event.target.value;
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    name: value
                                                                }
                                                            }));
                                                            if(this.verifyLength(value, 2))
                                                            {
                                                                this.setState(prevState => ({
                                                                ...prevState,
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    nameState: "valid",
                                                                    nameErrorMessage: ""
                                                                }
                                                                }));
                                                                this.state.itemInfo.nameState = "valid"
                                                                this.validateNewItem();
                                                            }
                                                            else{
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        nameErrorMessage: (
                                                                            <small className="text-danger">
                                                                                Invalid Item Name.
                                                                            </small>
                                                                        )
                                                                    }
                                                                }));
                                                                this.setState({ isNewItemFormValid: false });
                                                            }                                                    
                                                        }
                                                        }
                                                        onBlur={e => this.validateNewItem()}
                                                />
                                                {this.state.itemInfo.nameErrorMessage}
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    SKU: 
                                                </div>
                                                <FormControl
                                                            value={this.state.itemInfo.sku}
                                                            type="text"
                                                            placeholder="SKU"
                                                            name="sku"
                                                            onChange={event => {
                                                                var value = event.target.value;
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    itemInfo: {
                                                                        ...prevState.itemInfo,
                                                                        sku : value
                                                                    }
                                                                }));  
                                                            }
                                                            }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Price/Rate: 
                                                </div>
                                                <FormControl
                                                            value={parseFloat(this.state.itemInfo.price).toFixed(2) }
                                                            type="number"
                                                            placeholder="Price/Rate"
                                                            name="rate"
                                                            min="0.00"
                                                            style={{ textAlign: "center" }}
                                                            step="0.01"
                                                            onChange={event => {
                                                                var value = event.target.value;
                                                                            this.setState(prevState => ({
                                                                            itemInfo: {
                                                                                ...prevState.itemInfo,
                                                                                price: parseFloat(value).toFixed(2)                                                                            
                                                                        }}));                                                                                                                
                                                                }
                                                            }
                                                        /> 
                                                 </FormGroup>                                                      
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Item Type: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="itemtype"
                                                        value={this.state.itemInfo.selectedItemType}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedItemType: value
                                                                }
                                                            }));
                                                            if(value.value === "Inventory"){
                                                                this.state.itemInfo.isInvenotryItem = true;
                                                                this.state.itemInfo.selectedInventoryAssetAccount = this.state.itemInfo.inventoryAssetAccount[1];
                                                                this.state.itemInfo.quantityOnHand = 0;
                                                                this.state.itemInfo.quantityErrorMessage = "";
                                                            }
                                                            else{
                                                                this.state.itemInfo.isInvenotryItem = false;
                                                                this.state.itemInfo.quantityOnHand = null;
                                                                // this.state.itemInfo.selectedInventoryAssetAccount = null;
                                                                this.state.itemInfo.quantityErrorMessage = "";
                                                            }
                                                         }
                                                        }
                                                        options={this.state.itemInfo.itemType}
                                                        placeholder="Select Item Type"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Category:
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="category"
                                                        value={this.state.itemInfo.selectedCategory}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedCategory: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.category}
                                                        placeholder="Select Category"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.state.itemInfo.isInvenotryItem && 
                                        <Row>
                                            <Col md={3}>
                                              <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        QtyOnHand: <span className="star">*</span>
                                                    </div>
                                                        <FormControl
                                                            value={this.state.itemInfo.quantityOnHand}
                                                            type="number"
                                                            placeholder="Qty on Hand"
                                                            name="quantityOnHand"
                                                            style={{ textAlign: "center" }}
                                                            step="1"
                                                            onChange={event => {
                                                                        var value = event.target.value;
                                                                        this.state.itemInfo.quantityOnHand = value;                                                                
                                                                        this.validateNewItem();
                                                                    }                                                                                                                      
                                                                } 
                                                                onBlur={e => this.validateNewItem()}                                                          
                                                        />
                                                        {this.state.itemInfo.quantityErrorMessage} 
                                                 </FormGroup>        
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    InvStartDate: <span className="star">*</span>
                                                    </div>
                                                    <InputGroup>
                                                        <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                                        <Datetime
                                                            timeFormat={false}
                                                            closeOnSelect={true}
                                                            className="react-calendar"
                                                            inputProps={{ placeholder: "As of Date" }}
                                                            onChange={this.quantityOnHandDateHandler}
                                                            value={this.state.itemInfo.quantityOnHandDate}
                                                        // dateFormat={'YYYY-MM-DD'}
                                                        />
                                                        <InputGroup.Addon></InputGroup.Addon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Asset Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="inventoryAssetAccount"
                                                        value={this.state.itemInfo.selectedInventoryAssetAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedInventoryAssetAccount: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.inventoryAssetAccount}
                                                        placeholder="Select Asset Account"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        }
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Expense Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="expenseAccount"
                                                        value={this.state.itemInfo.selectedExpenseAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedExpenseAccount: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.expenseAccount}
                                                        placeholder="Select Expense Account"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                        Income Account: <span className="star">*</span>
                                                    </div>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="incomeAccount"
                                                        value={this.state.itemInfo.selectedIncomeAccount}
                                                        onChange={value => {
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    selectedIncomeAccount: value
                                                                }
                                                            }));
                                                         }
                                                        }
                                                        options={this.state.itemInfo.incomeAccount}
                                                        placeholder="Select Income Account"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                             Sales Information:
                                            </div>
                                            <FormGroup controlId="formControlsTextarea">

                                                    <FormControl
                                                        rows="2"
                                                        value={this.state.itemInfo.salesInformation}
                                                        componentClass="textarea"
                                                        bsClass="form-control"
                                                        placeholder="Sales Information"
                                                        maxLength="200"
                                                        onChange={event => {
                                                            const msg = event.target.value;
                                                            this.setState(prevState => ({
                                                                itemInfo: {
                                                                    ...prevState.itemInfo,
                                                                    salesInformation: msg
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </FormGroup>
                                            
                                            </Col>
                                            <Col md={6}>

                                            </Col>
                                        </Row>

                                    </div>
                                </Modal.Body>
                                <Modal.Footer>                                   
                                    <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewItemPopupModal: false })}>Close</Button>
                                    <Button bsStyle="primary" fill wd onClick={e => this.createItemSubmit(this.state.itemInfo)} disabled={!this.state.isNewItemFormValid}>Save</Button>
                                </Modal.Footer>
                            </Modal>                         

                            <Modal
                                show={this.state.showNewCustomerPopupModal}
                                onHide={() => this.setState({ showNewCustomerPopupModal: false })}
                               // bsSize="large"       
                                dialogClassName="modal-dialog-custom"                   
                            >
                             {this.state.ShowPopupProgressBar &&
                                <div style={{marginBottom:"2px",marginTop:"5px"}}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                 
                                <Modal.Header closeButton>
                                    <Modal.Title>Customer Information</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div>
                                    <Row>
                                        <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Customer Name: <span className="star">*</span>
                                            </div>
                                            <FormControl
                                                value={this.state.customerInfo.customerName}
                                                type="text"
                                                placeholder="Customer Name"
                                                name="customerName"
                                                onChange={event => {
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerName: value
                                                        }
                                                    }));
                                                    if(this.verifyLength(value, 2))
                                                    {
                                                        this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            newCustomerNameState: "valid",
                                                            newCustomerNameErrorMessage: ""
                                                        }
                                                    }));
                                                    }
                                                    else{
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            customerInfo: {
                                                                ...prevState.customerInfo,
                                                                newCustomerNameErrorMessage: (
                                                                    <small className="text-danger">
                                                                        Invalid Customer Name.
                                                                    </small>
                                                                )
                                                            }
                                                        }));
                                                        this.setState({ isNewCustomerFormValid: false });
                                                    }                                                    
                                                }
                                                }
                                                onBlur={e => this.validateNewCustomer()}
                                            />
                                             {this.state.customerInfo.newCustomerNameErrorMessage}
                                        </FormGroup>
                                    </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Customer Email: <span className="star">*</span>
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.customerEmail}
                                                    placeholder="Customer Email"
                                                    name="newCustomerEmail"
                                                    type="email"
                                                    className={this.state.customerInfo.newCustomerEmailState}
                                                    onChange={event =>
                                                        this.ValidateEmailAddress(event, "newCustomerEmail")
                                                    }
                                                   onBlur={e => this.validateNewCustomer()}
                                                />
                                                {this.state.customerInfo.newCustomerEmailErrorMessage}
                                            </FormGroup>
                                        </Col>                                    
                                        <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Company Name: 
                                            </div>
                                            <FormControl
                                                value={this.state.customerInfo.customerCompany}
                                                type="text"
                                                placeholder="Company Name"
                                                name="customerCompany"
                                              //  className={this.state.customerInfo.customerNameState}
                                                onChange={event => {
                                                    this.change(event, "customerCompany", "length", 3);
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerCompany: value
                                                        }
                                                    }));
                                                }
                                                }
                                               // onBlur={e => this.isValidated()}
                                            />
                                            {/* {this.state.invoiceNumberErrorMessage} */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Phone Number: 
                                            </div>
                                            <FormControl
                                            type="number"
                                            placeholder="Phone Number"
                                          //  className={this.state.user.phonenumberState}
                                            name="customerPhone"
                                            value={this.state.customerInfo.customerPhone}
                                            onChange={event => {
                                                //this.setState({ user: { phonenumber: event.target.value } })
                                                this.change(event, "customerPhone", "length", 7);
                                                var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerPhone: value
                                                        }
                                                    }));
                                            }
                                            }
                                          //  onBlur={e => this.isValidated()}
                                            />
                                            {/* {this.state.phonenumberErrorMessage} */}
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Fax: 
                                            </div>
                                            <FormControl
                                            type="number"
                                            placeholder="Fax Number"
                                          //  className={this.state.user.phonenumberState}
                                            name="customerFax"
                                            value={this.state.customerInfo.customerFax}
                                            onChange={event => {
                                                //this.setState({ user: { phonenumber: event.target.value } })
                                                this.change(event, "customerFax", "length", 7);
                                                var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerFax: value
                                                        }
                                                    }));
                                            }
                                            }
                                          //  onBlur={e => this.isValidated()}
                                            />
                                            {/* {this.state.phonenumberErrorMessage} */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Website: 
                                            </div>
                                            <FormControl
                                                value={this.state.customerInfo.customerWebsite}
                                                type="text"
                                                placeholder="Website"
                                                name="customerWebsite"
                                              //  className={this.state.customerInfo.customerNameState}
                                                onChange={event => {
                                                    this.change(event, "customerWebsite", "length", 8);
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerWebsite: value
                                                        }
                                                    }));
                                                }
                                                }
                                               // onBlur={e => this.isValidated()}
                                            />
                                            {/* {this.state.invoiceNumberErrorMessage} */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                            Note:
                                            </div>
                                        <FormGroup controlId="formControlsTextarea">

                                            <FormControl
                                                rows="2"
                                                value={this.state.customerInfo.customerNote}
                                                componentClass="textarea"
                                                bsClass="form-control"
                                                placeholder="Note"
                                                maxLength="200"
                                                onChange={event => {
                                                    const note = event.target.value;
                                                     this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,
                                                            customerNote: note
                                                        }
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <legend> </legend> 
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Billing Address: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.address}
                                                    type="text"
                                                    placeholder="Address"
                                                    name="billingAddress"
                                                //  className={this.state.customerInfo.customerNameState}
                                                    onChange={event => {
                                                    // this.change(event, "address", "length", 3);
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                address: value
                                                            }
                                                        }
                                                        
                                                    }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Row>
                                                    <Col md={4}>
                                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                Shippng Address: 
                                               
                                                </div>
                                                    </Col>
                                                    <Col md={8} style={{marginTop: "-32px", marginLeft:"-55px"}}>
                                                     <CustomCheckbox
                                                            name="CheckBox"
                                                            checked={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                            //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                            labelText="Same as billing address"
                                                            labelProps={{style: {fontSize: "16px", marginTop:"12px"}}} 
                                                           formControlProps={{
                                                            onChange:(e) => {
                                                              //  this.handleUserInput(e)
                                                              var value = e.target.checked;
                                                              this.setState(prevState => ({
                                                                    ...prevState,
                                                                    customerInfo: {
                                                                        ...prevState.customerInfo,
                                                                        isShippingAddressSameAsBillingAddress: value
                                                                    }
                                                                }));
                                                              }
                                                           }}  
                                                        />
                                                    </Col>
                                                </Row>
                                                
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.address}
                                                    type="text"
                                                    placeholder="Address"
                                                    name="shippingAddress"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                //  className={this.state.customerInfo.customerNameState}
                                                    onChange={event => {
                                                    // this.change(event, "address", "length", 3);
                                                        var value = event.target.value;
                                                        this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                address: value
                                                            }
                                                        }                                                    
                                                    }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Country: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.country}
                                                    type="text"
                                                    placeholder="Billing Country"
                                                    name="billingCountry"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                country: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                                {/* {this.state.invoiceNumberErrorMessage} */}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    State: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.state}
                                                    type="text"
                                                    placeholder="Billing State"
                                                    name="billingState"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                state: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                                {/* {this.state.invoiceNumberErrorMessage} */}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Country: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.country}
                                                    type="text"
                                                    placeholder="Shipping Country"
                                                    name="shippingCountry"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                country: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                // onBlur={e => this.isValidated()}
                                                />
                                                {/* {this.state.invoiceNumberErrorMessage} */}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    State: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.state}
                                                    type="text"
                                                    placeholder="Shipping State"
                                                    name="shippingState"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                state: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                     <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    City: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.city}
                                                    type="text"
                                                    placeholder="Billing City"
                                                    name="billingCity"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                city: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Postal Code: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.billingAddress.postalCode}
                                                    type="text"
                                                    placeholder="Billing Postal Code"
                                                    name="billingPostalCode"
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            billingAddress: {
                                                                ...prevState.customerInfo.billingAddress,
                                                                postalCode: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    City: 
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.city}
                                                    type="text"
                                                    placeholder="Shipping City"
                                                    name="shippingCity"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                city: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                                    Postal Code:  
                                                </div>
                                                <FormControl
                                                    value={this.state.customerInfo.shippingAddress.postalCode}
                                                    type="text"
                                                    placeholder="Shipping Postal Code"
                                                    name="shippingPostalCode"
                                                    disabled={this.state.customerInfo.isShippingAddressSameAsBillingAddress}
                                                    onChange={event => {
                                                     //   this.change(event, "customerCompany", "length", 3);
                                                        var value = event.target.value;
                                                         this.setState(prevState => ({
                                                        ...prevState,
                                                        customerInfo: {
                                                            ...prevState.customerInfo,                                                      
                                                            shippingAddress: {
                                                                ...prevState.customerInfo.shippingAddress,
                                                                postalCode: value
                                                            }
                                                        }                                                        
                                                     }));
                                                    }
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                </Modal.Body>
                                <Modal.Footer>                                
                                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewCustomerPopupModal: false })}>Close</Button>
                                        <Button bsStyle="primary" fill wd onClick={e => this.createCustomerSubmit(this.state.customerInfo)} disabled={!this.state.isNewCustomerFormValid}>Save</Button>                                   
                                    </Modal.Footer>                             
                             </Modal>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default CreateInvoice;