import React from 'react';
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";
import NetworkService from "services/NetworkService";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { ProgressBar } from 'react-bootstrap';
import Card from 'components/Card/Card.jsx';
import Chart from "react-apexcharts";
import { createMuiTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import SessionTimeout from 'components/SessionTimeout';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ApplicationIDEnum } from "utils/constant.js";


class EmailStatsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 18
            },
        });
        this.state = {
            showEmailStatsModal: false,
            ShowPopupProgressBar: false,
            invoiceId: "",
            data: [],
            app_id: ApplicationIDEnum.QuickBooksOnline,
            columns: [                
                {
                    title: 'Date',
                    field: 'date',
                    type: 'date',
                    cellStyle: {
                        width: 'calc(30%)'
                    },
                },
                {
                    title: 'Email Event',
                    field: 'event',
                    cellStyle: {
                        width: 'calc(40%)'
                    },
                },
                {
                    title: 'Count',
                    field: 'count',
                }
            ],
            options: {
                //selection: true,
                //pageSize: 10,
                //pageSizeOptions: [10, 50, 100],
                paging: false,
                toolbar: false,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,                
            },
            donutEmailOptions: {
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false
                },
                fill: {
                    colors: ["#008ffb", '#00e396', '#feb019', "#775dd0"]
                },
                labels: ["Email Sent", "Processed", "Delivered", "Read"],
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    hover: {
                        filter: {
                            type: 'darken',
                            value: 0.70,
                        }
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '20px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: '#0066a8',
                                    offsetY: -5
                                },
                                value: {
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    offsetY: 5,
                                    color: '#0066a8'
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: false,
                }
            },
            donutEmailData: [0, 0, 0, 0],
            progressBarEmailData: [0, 0, 0, 0],
            progressBarEmailOptions: [],
            isAuthenticated: true,
        }
    }

    componentDidMount() {
        this.handlerEmailStats();
        this.getEmailStats();
    }

    componentWillUnmount() {
        this.setState({ showEmailStatsModal: false });
        this.props.parentCallback(false);
    }

    handlerEmailStats = () => {
        if (this.props != null || this.props != undefined) {
            this.state.showEmailStatsModal = this.props.showEmailStatsModal
            this.state.invoiceId = this.props.Invoice.Id
            this.state.app_id = this.props.AppId
        }
    }

    handlerShowProgressBar = () => {
        this.state.progressBarEmailData.map(data => {
            this.state.progressBarEmailOptions.push(
                <ProgressBar
                    bsStyle="info"
                    now={data}
                    className="progress-line-info"
                    active={true}
                />
            )
        })

    }

    handlerCloseModal = () => {
        // this.props.showEmailStatsModal = false;
        this.props.parentCallback(false);
        this.setState({ showEmailStatsModal: false });
       
    }

    getEmailStats = () => {
        const params = {
            "start_date": null,
            "end_date": null,
            "invoice_id": this.state.invoiceId
        }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getEmailStatsRequest(query);
    };

    onSetEmailStatsResult = (result) => {
        const email_stats = result.email_stats;
        const payment_stats = result.payment_stats;

        const progress = [email_stats.emailsent, email_stats.processed, email_stats.delivered, email_stats.open].reduce((a, b) => a + b, 0)

        this.setState({ donutEmailData: [email_stats.emailsent, email_stats.processed, email_stats.delivered, email_stats.open] })
        //this.setState({ progressBarEmailData: [email_stats.emailsent * progress / 100, email_stats.processed * progress / 100, email_stats.delivered * progress / 100, email_stats.open * progress / 100] })
        this.setState({ progressBarEmailData: [email_stats.emailsent , email_stats.processed , email_stats.delivered , email_stats.open ] })
        
        this.setState({data:payment_stats})
    }

    /**
     * API CALLING
     */

    _getEmailStatsRequest(params = "") {

        const app_id = this.state.app_id;
        NetworkService.getEmailStatsRequest(app_id, params)
            .then(data => {

                if (data != null) {
                    if (data.statuscode == 0) {
                        this.setState({ errorCode: data.statuscode });
                        this.setState({ errorMessage: data.statusmessage });
                        this.onSetEmailStatsResult(data.data);
                        console.log(this.state.progressBarEmailData);
                    }

                } else {
                    this.setState({ StatusCode: 1 });
                    this.setState({ StatusMessage: data.statusmessage });
                }
            }).catch(error => {
                // this.setState({ ShowProgressBar: false });
                this.setState({ isSubmitting: false });
                this.setState({ StatusMessage: "Oops! Something went wrong, please try again." });
            });
    }

    handleClick = () => {
        this.setState({isAuthenticated: !this.state.isAuthenticated});
        this.props.history.push('/account/login');        
    }

    render() {
        const customLegenSize = {
            fontSize: "14px",
            fontWeight: "300px",
        };
        const className1 = !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? "modal-dialog-email-statistics-custom" : "";
        return (
            <div>
                <SessionTimeout isAuthenticated={this.state.isAuthenticated} logOut={this.handleClick} />
                <Row>
                    <Col md={12}>

                        <Modal
                            show={this.state.showEmailStatsModal}
                            onHide={() => this.setState({ showEmailStatsModal: false })}
                          //  bsSize="large"
                          //  dialogClassName="modal-dialog-email-statistics-custom" 
                           dialogClassName= {className1}  
                        >
                            {this.state.ShowPopupProgressBar &&
                                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                            <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                <Modal.Title style={{ color: "white" }}>Email Invoice Statistics</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modal-body text-left" >

                                    <Col xs={12} sm={12} md={4}>
                                        <Card
                                            title="Email Event Statistics"
                                           // category="Email Event Statistics"
                                            content={
                                                <div>

                                                    <p>Email Sent ({this.state.progressBarEmailData[0]})</p>
                                                    <ProgressBar
                                                        min={0}
                                                        max={10}
                                                        bsStyle="progress progress-bar-blue"
                                                        now={this.state.progressBarEmailData[0]}
                                                        className="progress progress-bar-blue"
                                                        label="% Complete"
                                                    />

                                                    <p>Processed ({this.state.progressBarEmailData[1]})</p>
                                                    <ProgressBar
                                                        min={0}
                                                        max={10}
                                                        bsStyle="success"
                                                        now={this.state.progressBarEmailData[1]}
                                                        className="progress-line-success"
                                                        label="% Complete" srOnly
                                                    />

                                                    <p>Delivered ({this.state.progressBarEmailData[2]})</p>
                                                    <ProgressBar
                                                        min={0}
                                                        max={10}
                                                        bsStyle="warning"
                                                        now={this.state.progressBarEmailData[2]}
                                                        className="progress-line-warning"
                                                        label="% Complete" srOnly
                                                    />

                                                    <p>Open ({this.state.progressBarEmailData[3]})</p>
                                                    <ProgressBar
                                                        min={0}
                                                        max={10}
                                                        bsStyle="progress progress-bar-purple"
                                                        now={this.state.progressBarEmailData[3]}
                                                        className="progress progress-bar-purple"
                                                        label="% Complete" srOnly
                                                    />
                                                    {/* <p>Bounced</p>
                                                    <ProgressBar
                                                        bsStyle="info"
                                                        now={60}
                                                        className="progress-line-info"
                                                        label="60% Complete" srOnly
                                                    /> */}

                                                </div>
                                            }
                                            legend={
                                                <div className="text-center">
                                                    <span style={customLegenSize}> <i className="fa fa-circle" style={{ color: "#008ffb" }} /> Email Sent </span>
                                                    <span style={customLegenSize}><i className="fa fa-circle text-success" /> Processed </span>
                                                    <span style={customLegenSize}><i className="fa fa-circle text-warning" /> Delivered </span>
                                                    <span style={customLegenSize}><i className="fa fa-circle" style={{ color: "#775dd0" }} /> Open </span>
                                                </div>
                                            }    
                                        />
                                    </Col>

                                    <Col xs={12} sm={12} md={4}>
                                        <Card
                                            title="Email Statistics"
                                            ctTextCenter
                                            content={
                                                <Chart
                                                    options={this.state.donutEmailOptions}
                                                    series={this.state.donutEmailData}
                                                    type="donut"
                                                />
                                            }
                                            legend={
                                                <div className="text-center">
                                                    <span style={customLegenSize}> <i className="fa fa-circle" style={{ color: "#008ffb" }} /> Email Sent </span>
                                                    <span style={customLegenSize}><i className="fa fa-circle text-success" /> Processed </span>
                                                    <span style={customLegenSize}><i className="fa fa-circle text-warning" /> Delivered </span>
                                                    <span style={customLegenSize}><i className="fa fa-circle" style={{ color: "#775dd0" }} /> Open </span>
                                                </div>
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={4}>    
                                        <MuiThemeProvider theme={this.theme}>
                                            {this.state.ShowProgressBar &&
                                                <div>
                                                    <CustomProgressBar></CustomProgressBar>
                                                    <br/>
                                                </div>

                                            }
                                            <MaterialTable
                                                columns={this.state.columns}
                                                data={this.state.data}   
                                                options={this.state.options}                                             
                                            />
                                        </MuiThemeProvider>
                                    </Col>
                                    
                                     <Row>
                                        <Col> </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.handlerCloseModal()}>Close</Button>
                            </Modal.Footer>

                        </Modal>

                    </Col>
                </Row>
            </div>
        )
    }
}

export default EmailStatsPanel