import React, { Component } from "react";
import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Alert,
    FormGroup
} from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Select from "react-select";
import MaterialTable, { MTableToolbar } from 'material-table';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js"
import NetworkService from "services/NetworkService";

class Merchants extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 19
            },
        });
        this.state = {
            ShowProgressBar: false,
            selectedReseller: {value : 0, label : "All"},
            resellers: [],
            errorMessage: "",
            errorCode: 1,
            data: [

                // {
                //     companyname: 'ABC Company', firstname: "John", lastname: "Doe", merchantname: 'John Doe', email: 'JohnDoe@tes.erc', password: "Instant@123", phonenumber: '3215453455', date: '2020-01-01', quickbooksversion: "QuickBooks Online",
                //     monthlyinvoicevolume: "Less than $25K", website: "www.test123.com", processor_type: "NMI", status: "Active", processor_configuration: {
                //         SecurityKey: "1st NMI Sec Key", TokenizationKey: "1st NMI Token Key", UserName: "1st NMI Username", Password: "1st NMI Password"
                //     }
                // },
            ],
            columns: [
                {
                    title: 'Company',
                    field: 'companyname',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Merchant Name',
                    field: 'merchantname',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Reseller/ISO Name',
                    field: 'resellername',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(25%)'
                    },
                    cellStyle: {
                        width: 'calc(25%)'
                    },                    
                },
                {
                    title: 'Email',
                    field: 'email',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(20%)'
                    },
                    cellStyle: {
                        width: 'calc(20%)'
                    },
                },
                {
                    title: 'Phone No.',
                    field: 'phonenumber',
                    headerStyle: {
                        width: 'calc(20%)',
                        textAlign: "center"
                    },
                    cellStyle: {
                        textAlign: "center",  
                         width: 'calc(20%)'                      
                    },
                },
                {
                    title: 'Status',
                    field: 'status',
                    headerStyle: {
                        textAlign: "center"
                    },
                    cellStyle: {
                        textAlign: "center"                        
                    },
                },
                {
                    title: 'Joining Date',
                    field: 'date',
                    type: 'date',
                    defaultSort: 'desc',
                    headerStyle: {
                        textAlign: "center"
                    },
                    cellStyle: {
                        textAlign: "center"                        
                    },
                },

            ],
            subUsersColumns: [
                {
                    title: 'Subuser Name',
                    field: 'merchantname',
                },
                {
                    title: 'Email',
                    field: 'email',
                },
                {
                    title: 'Phone No.',
                    field: 'phonenumber',
                },
                {
                    title: 'Status',
                    field: 'status',
                },
                {
                    title: 'Joining Date',
                    field: 'date',
                    type: 'date',
                    defaultSort: 'desc',
                },
            ],
            options: {
                //selection: true,
                pageSize: 25,
                pageSizeOptions: [10, 25, 50, 100, 200, 500],
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },
            subUsersOptions: {
                //selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                paging: false,
                toolbar: false,
                sorting: false,
                draggable: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#fafafa' : '#FFF'
                })
            },       
            detailPanel: [
                rowData => ({
                    disabled: !rowData.expandable,
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionExpandToolTip">Expand</Tooltip>}><i className="fa fa-plus" style={{ color: "#0075c1", display: !rowData.expandable ? "none" : "block" }}></i></OverlayTrigger>,
                    openIcon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="transactionCollapseToolTip">Collapse</Tooltip>}><i className="fa fa-minus" style={{ color: "#0075c1" }}></i></OverlayTrigger>,
                    render: () => {
                        return (
                            <Row>
                                <Col md={10} mdOffset={1}>
                                    <MuiThemeProvider theme={this.theme}>
                                        <MaterialTable
                                            columns={this.state.subUsersColumns}
                                            data={rowData.subUsers}
                                            options={this.state.subUsersOptions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        )
                    },
                })
            ],     
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />

                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="merchantBoardingToolTip">Add / Board New Merchant</Tooltip>}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreateMerchant(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>

                        </div>
                    </div>
                ),
            },
            actions: [
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editMerchantToolTip">Resend Welcome Email</Tooltip>}>
                        <i className="fa fa-envelope" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleResendEmail(event, rowData)
                },
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editMerchantToolTip">Set Default Password</Tooltip>}>
                        <i className="fa fa-key" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleDefaultPassword(event, rowData)
                }, 
                {
                    icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="editMerchantToolTip">Edit</Tooltip>}>
                        <i className="fa fa-edit" style={{ color: "#0075c1" }}></i>
                    </OverlayTrigger>,
                    onClick: (event, rowData) => this.handleEditMerchant(event, rowData)
                },     
                           
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="resetPasswordToolTip">Set Default Password</Tooltip>}>
                //         <i className="fa fa-lock" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => this.handleResetDefaultPassword(event, rowData)
                // },
                // {
                //     icon: () => <OverlayTrigger placement="top" overlay={<Tooltip id="deleteMerchantToolTip">Delete</Tooltip>}>
                //         <i className="fa fa-trash" style={{ color: "#0075c1" }}></i>
                //     </OverlayTrigger>,
                //     onClick: (event, rowData) => this.handleDeleteMerchant(event, rowData)
                // }
            ],
        }
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');            
        }
        else if(localStorage.getItem("login_user_type") != "Admin")
        {
            this.props.history.goBack();//go(-1);
        }
        document.getElementById("loaderDiv").style.display = "none";
        this.state.errorMessage = this.props.location.state ? this.props.location.state.errorMessage : "";
        this.state.errorCode = this.props.location.state ? this.props.location.state.errorCode : "";
        this._resellerDropdownListingRequest();
        this._merchantListingRequest();
    }

    _resellerDropdownListingRequest() {        
        this.setState({ ShowProgressBar: true });
        NetworkService.getResellersDropdownListingRequest()
            .then(data => {
                if (data != null) {
                    if (data.statuscode == 0) {
                         this.setState({ ShowProgressBar: false });
                        if (data.data != null) {
                            this.setResellerResult(data.data.items)
                        }
                    }
                } else {
                     this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);    
    }

    setResellerResult(data) {
        const resellerArr = []
        resellerArr.push({value: "", label: "Select Reseller/ISO", isDisabled: true});
        resellerArr.push({value: "", label: "All"});
        data.map(data => {
            resellerArr.push({ value: data.id, label: data.name || "", processorType : data.processor_type });
        });
        if(this.state.selectedReseller == "" && resellerArr.length >= 1)
        {
            this.setState({selectedReseller: resellerArr[1]});       
        }
        this.setState({ resellers: resellerArr });
    }

    handleCreateMerchant(e) {
        this.props.history.push('/admin/createmerchant');
        e.preventDefault();
    }

    handleEditMerchant(e, merchant) {
        this.props.history.push({
            pathname: '/admin/editmerchant',
            state: { merchantData: merchant.id }
        });
        sessionStorage.setItem("_MID", merchant.id)
        e.preventDefault();
    }

    handleResendEmail = (e, merchant) => {
        // this.props.history.push({
        //     pathname: '/admin/editmerchant',
        //     state: { merchantData: merchant.id }
        // });
        e.preventDefault();
        const params = {"id":merchant.id}
        this._welcomeEmailRequest(params);
    }

    handleDefaultPassword = (e, merchant) => {        
        e.preventDefault();
        const params = {"email":merchant.email}
        this._defaultPasswordEmailRequest(params);
    }

    handleResetDefaultPassword(e, merchant) {
        this.setState({ ShowProgressBar: true });
        let url = BASE_URL + "api/v1/account/resetpassowrd/";

        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
            "merchant_info": merchant
            })
        })
            .then(response => {
            return response.json();
            })
            .then(data => {
            if (data != null) {
                this.setState({ ShowProgressBar: false });
                if (data.statuscode === 0) {
                } 
                else{

                }
            }
            })
            .catch(error => {
            this.setState({ ShowProgressBar: false });
            });
    }

    handleDeleteMerchant(e, merchant) {
        //need to delete merchant here..
        e.preventDefault();
    }

    setUserResult(data) {
        const newSeries = [];
        const merchantArr = [];
        const subUserArr = [];
        
        data.map((value, index) => {
            if(value.parent_id === null || value.parent_id === "")
            {           
                merchantArr.push({
                    id: value.id,
                    companyname: value.company_name,
                    merchantname: value.name,
                    resellername: value.reseller_name,
                    email: value.email,
                    phonenumber: value.phone,
                    date: value.joining_date,
                    quickbooksversion: "QuickBooks Online",
                    monthlyinvoicevolume: "Less than $25K",
                    status: value.is_active ? "Active" : "InActive",
                    entityid: value.entity_id,
                    subUsers: [],
                });
            }
        });
        data.map((value, index) => {
            if(value.parent_id !== null || value.parent_id !== "")
            {           
                subUserArr.push({
                    id: value.id,
                    companyname: value.company_name,
                    merchantname: value.name,                    
                    email: value.email,
                    phonenumber: value.phone,
                    date: value.joining_date,
                    quickbooksversion: "QuickBooks Online",
                    monthlyinvoicevolume: "Less than $25K",
                    status: value.is_active ? "Active" : "InActive",
                    entityid: value.entity_id,
                    parentid: value.parent_id
                });
            }
        });
        merchantArr.forEach(function(merchant) {
            subUserArr.forEach(function(subUser){
                if(merchant.entityid === subUser.parentid)
                {
                    merchant.expandable = true;
                    merchant.subUsers.push(subUser);
                }
            });
            newSeries.push(merchant);
        });

        this.setState({ data: newSeries });
    }

    /** API CALLING */

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this._merchantListingRequest();
    }

    _merchantListingRequest() {                 
        this.setState({ ShowProgressBar: true });
        NetworkService.getMerchantListingRequest(this.state.selectedReseller.value)
            .then(data => {
                if (data != null) {
                  //  this.setState({ errorMessage: data.statusmessage });
                  //  this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                         this.setState({ ShowProgressBar: false });
                        if (data.data != null) {
                            this.setUserResult(data.data.items)
                        }
                    }
                } else {
                     this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);    
    }

    _welcomeEmailRequest(body){
        this.setState({ ShowProgressBar: true });
        NetworkService.postMerchantWelcomeEmailRequest(body)
            .then(data => {
                if (data != null) {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                } else {
                     this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);    
    }


    _defaultPasswordEmailRequest(body){
        this.setState({ ShowProgressBar: true });
        NetworkService.postDefaultPasswordEmailRequest(body)
            .then(data => {
                if (data != null) {
                    this.setState({ ShowProgressBar: false });
                    this.setState({ errorMessage: data.statusmessage });
                    this.setState({ errorCode: data.statuscode });
                } else {
                     this.setState({ ShowProgressBar: false });
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 2500);    
    }

    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                             {this.state.errorMessage != "" &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>                                    
                                </div>
                            }
                            {this.state.ShowProgressBar &&
                                <div style={{ marginBottom: "2px" }}>
                                    <CustomProgressBar></CustomProgressBar>
                                </div>
                            }
                             <br />
                            <Row>
                                <Col md={3}>
                                    <span>Reseller/ISO</span>
                                    <FormGroup>                                        
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="resellers"
                                            value={this.state.selectedReseller}
                                            onChange={value =>
                                                this.setState({selectedReseller: value})                                               
                                            }
                                            options={this.state.resellers}
                                            placeholder="Select Receller/ISO"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MuiThemeProvider theme={this.theme}>
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            actions={this.state.actions}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            components={this.state.components}
                                            detailPanel={this.state.detailPanel}
                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Merchants;