import React from 'react';
import ReactToPrint from 'react-to-print';
import Card from "components/Card/Card.jsx";
import { StorageEnum } from "utils/constant.js";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import NetworkService from "services/NetworkService";
import paidimage from "assets/img/paidimage.png";
import { createMuiTheme } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Alert,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";


const styles = {
    watermark: {
        content: "",
        backgroundImage: `url(${paidimage})`,
        opacity: "0.2",
        top: "32%",
        left: "7%",
        bottom: "0",
        right: "0",
        position: "absolute"
    },
}    

// const LazyReactToPrint = lazy(() => import('react-to-print'));

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 18
            },
        });
        this.state = {
            ShowProgressBar: false,
            errorCode: null,
            errorMessage: "",
            BillEmail: {
                Address: ""
            },
            Invoice: {
                CustomerRef: {
                    name: "",
                    value: ""
                },
                Balance: 0,
                DocNumber: "",
                Id: "",
                BillEmail: {
                    Address: ""
                }
            },
            InvoiceRawHtml: null,
            docNumber: "",
            showSendInvoiceModal: false,
            attachmentColumns: [  
                {
                    title: 'File Name',
                    field: 'fileName',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(80%)'
                    },
                    cellStyle: {
                        width: 'calc(80%)'
                    },
                },
                {
                   // title: 'Action',
                    field: 'download',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(20%)'
                    },
                    cellStyle: {
                        width: 'calc(20%)'
                    },
                }
            ],
            attachmentOptions: {
                paging: false,
                toolbar: false,
                showTextRowsSelected: false,
               
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                draggable: false,
                actionsColumnIndex: -1,                
            },
            attachmentData:[],
            showHTMLDiv: true
        }
    }

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }

        var invoiceID = this.getInvoiceIDFromURL();
        if (invoiceID != null) {
            this.getInvoicePreview(invoiceID);
        }

        document.getElementById("loaderDiv").style.display = "none";
    }

    validateEmail(event) {
        let value = event.target.value
        this.setState(prevState => ({
            ...prevState,
            Invoice: {
                ...prevState.Invoice,
                BillEmail: {
                    ...prevState.Invoice.BillEmail,
                    Address: value
                }
            }
        }));

        var re = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
        if (re.test(value) === false) {
            this.setState({
                errorSendInvoiceEmail: (
                    <small className="text-danger">
                        Email is required and format should be <i>john@doe.com</i>.
                    </small>
                )

            });
            this.setState({
                sendInvoiceClassName: "error"
            });

        }
        else {
            this.setState({ errorSendInvoiceEmail: null });
            this.setState({ sendInvoiceClassName: "valid" });
        }
    }

    handleSendInvoiceEmail(e) {
        e.preventDefault();
        const payload = {
            "ToEmails": [this.state.Invoice.BillEmail.Address],
            "Invoices": [this.state.Invoice]
        }
        this._postEmailInvoiceRequest(payload);
    }

    getInvoiceIDFromURL() {
        const url = window.location.href;
        const path = url.split("/");
        const invoiceID = (path[path.length - 1] || "");
        return invoiceID;
    }

    getInvoicePreview(invoice_id) {
        this.setState({ ShowProgressBar: true });
        const params = {
            "invoice_id": invoice_id
        }

        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoicePreviewRequest(query);
    }

    setResult = (data) => {       
        this.setState({ InvoiceRawHtml: data.data.html || null });    
        if(data.data.invoice.length > 0 && data.data.invoice[0].BillEmail == null)
        {
            data.data.invoice[0].BillEmail = this.state.BillEmail;
        }

        data.data.invoice.map(data => {
            this.setState({ Invoice: data })
        });
        
       
        // if(this.state.Invoice.BillEmail == null)
        // {
        //     this.state.Invoice.BillEmail = this.state.BillEmail;
        // }

        // this.setState(prevState => ({
        //     ...prevState,
        //     Invoice: {
        //         ...prevState.Invoice,
        //         BillEmail: this.state.BillEmail
        //     }
        // }));

        // if(data.data.invoice(0).Balance == 0 || data.data.invoice(0).Balance == 0.00 || data.data.invoice(0).Balance == null || data.data.invoice(0).Balance == undefined)
        // {

        // }
        const content_type = data.data.content_type
        if (content_type === "application/html") {
            this.setState({ showHTMLDiv: true })
        } else {
            this.setState({ showHTMLDiv: false })
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas-detail");
            this.renderPDFView(this.state.InvoiceRawHtml || null, "pdf-canvas-detail2");
        }

    }

    /**
     * API Calling
     */
    /** GET request to fetch invoice details from server */
    _getInvoicePreviewRequest = (params = "") => {       
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getInvoicePreviewRequest(app_id, params)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                 this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setResult(data);
                } else {
                }
            }).catch(error => {
                 this.setState({ ShowProgressBar: false });
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);   
        
    }

    /** POST request to Email invoice to customer */
    _postEmailInvoiceRequest(data) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = 2 //localStorage.getItem(StorageEnum.APPID);
        NetworkService.postEmailInvoiceRequest(app_id, data)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showSendInvoiceModal: false });
                if (data != null) {
                     this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });    
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);    
    }

    rawMarkup(value) {
       // const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: this.state.InvoiceRawHtml };
    };

    renderPDFView = (document_data, canvasID) => {
        // Asynchronous download of PDF
        if (document_data) {
            // window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

            var pdfData = atob(document_data)
            var loadingTask = window.pdfjsLib.getDocument({ data: pdfData });

            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                var pageNumber = 1;
                var numPages = pdf.numPages;
                for (var i = 0; i < numPages; i++) {
                    pdf.getPage(pageNumber+i).then(function (page) {

                        var scale = 1.3;
                        var viewport = page.getViewport({ scale: scale });

                        // Prepare canvas using PDF page dimensions
                        var canvas = document.createElement('canvas');//document.getElementById(canvasID);
                        var context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        var renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport
                        });
                        renderTask.promise.then(function () {
                        });
                        document.getElementById(canvasID).appendChild(canvas);
                    });
                }

            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        }
    }

    handleSendInvoice(e) {
        this.setState({ showSendInvoiceModal: true });
    }

    handleAttachmentClick(e) {        
        this.setState({attachmentData:[]});
        this.setState({ showAttachmentModal: true });
        this.getInvoiceAttachment(this.state.Invoice.Id);
    }

    getInvoiceAttachment(invoice_id) {       
        const params = {
            "invoice_id": invoice_id
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getInvoiceAttachmentRequest(query);
    }

    _getInvoiceAttachmentRequest = (params = "") => {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });
        const app_id = 2 
        NetworkService.getInvoiceAttachmentRequest(app_id, params)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                if (data != null && data.data != null && data.data.items.length > 0) {  
                    const newSeries = [];
                    data.data.items.map((item, index) => {
                        const data = {
                            index: index,
                            fileName: item.filename.split('/')[item.filename.split('/').length-1],
                            download: <a  href={item.uri} target='_blank' download={item.filename.split('/')[item.filename.split('/').length-1]}>
                                        Download </a>                           
                        }
                        newSeries.push(data);            
                    });
                    this.setState({ attachmentData: newSeries });
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);

    }

    handlePaymentInvoice(e) {
        e.preventDefault();

        const data_tokenize = JSON.parse(localStorage.getItem(StorageEnum.PPBUNDLE));
                
        if(data_tokenize.PPType.toLowerCase() === "cardconnect"){
            this.props.history.push({
                pathname: '/customerportal/customerpayment',
                 state: { invoiceData: this.state.Invoice }
            });
        }
        else if(data_tokenize.PPType.toLowerCase() === "nmi"){      
            this.props.history.push({
                pathname: '/customerportal/nmipayment',
                 state: { invoiceData: this.state.Invoice }
            });
        }
    }

    handleTemplateClick(e) {

    }

    handleProcessorSetupClick(e) {

    }

    handleGoBackClick(e) {
        this.props.history.push('/customerportal/invoices');
        e.preventDefault();
    }

    render() {
        return (
            <div className="ii-main-content">
                <Card
                    // title={"Invoice # " + this.state.docNumber}
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            {this.state.ShowProgressBar &&
                                <div>
                                    <CustomProgressBar></CustomProgressBar>
                                    <br/>
                                </div>

                            }
                            <Row>
                                <Col md={12}>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                </Col>
                                <Col md={4} style={{ textAlign: "right" }}>
                                    {/* {
                                        this.state.Invoice.Balance != 0 &&
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="sendInvoiceToolTip">Email Invoice</Tooltip>}>
                                            <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleSendInvoice(e)} style={{ marginRight: '15px' }}> <i className="fa fa-envelope" ></i> </Button>
                                        </OverlayTrigger>
                                    } */}
                                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id="invoiceTemplateToolTip">Invoice Template</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleTemplateClick(e)} style={{ marginRight: '15px' }}> <i className="fa fa-file-text" ></i> </Button>
                                    </OverlayTrigger> */}
                                    <ReactToPrint
                                        trigger={() =>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="printInvoiceToolTip">Print Invoice</Tooltip>}>
                                                <Button className="btn btn-primary btn-fill btn-round btn-icon" style={{ marginRight: '15px' }}> <i className="fa fa-print" ></i> </Button>
                                            </OverlayTrigger>
                                        }
                                        content={() => this.componentRef}
                                    />
                                    <div style={{ display: "none" }}> <ComponentToPrint ref={el => (this.componentRef = el)} dataFromParent={this.state.showHTMLDiv ? this.state.InvoiceRawHtml : null} /> </div>

                                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id="defaultProcessorToolTip">Change Default Processor</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleProcessorSetupClick(e)} style={{ marginRight: '15px'}}> <i className="fa fa-list-alt" ></i> </Button>
                                    </OverlayTrigger> */}
                                    {
                                        this.state.Invoice.Balance != 0 &&
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="collectPaymnetToolTip">Collect Payment</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handlePaymentInvoice(e)} style={{ marginRight: '15px' }}> <i className="fa fa-credit-card" ></i> </Button>
                                    </OverlayTrigger>
                                    }
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="viewAttachmentToolTip">View Attachments</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleAttachmentClick(e)} style={{ marginRight: '15px', display: this.state.showInvoiceSendButton }}> <i className="fa fa-chain" ></i> </Button>
                                    </OverlayTrigger> 
                                    
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="collectPaymnetToolTip">Invoices</Tooltip>}>
                                        <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleGoBackClick(e)} style={{ marginRight: '15px' }}> <i className="fa fa-newspaper-o" ></i> </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {
                                        this.state.showHTMLDiv ? 
                                         <div>  
                                            <div dangerouslySetInnerHTML={this.rawMarkup(this.state.Invoice || null)} />
                                             {
                                                    (this.state.Invoice.Balance == 0 || this.state.Invoice.Balance == 0.00
                                                    || this.state.Invoice.Balance == "0" || this.state.Invoice.Balance == "0.00") &&
                                                    <div style={styles.watermark}>
                                                    </div>
                                                }
                                         </div>
                                         : <div id="pdf-canvas-detail" height="0"> </div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showAttachmentModal}
                                        onHide={() => this.setState({  showAttachmentModal: false })}                                    
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Invoice Attachments</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <MuiThemeProvider theme={this.theme}>                                                   
                                                    <MaterialTable
                                                        columns={this.state.attachmentColumns}
                                                        data={this.state.attachmentData}   
                                                        options={this.state.attachmentOptions}                                             
                                                    />
                                                </MuiThemeProvider>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showAttachmentModal: false })}>Close</Button>                                            
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                            </Row>        
                            <Row>
                                <Col md={12}>
                                    <Modal
                                        show={this.state.showSendInvoiceModal}
                                        onHide={() => this.setState({ showSendInvoiceModal: false })}
                                    // style={{ marginTop: "200px" }}
                                    >
                                        {this.state.ShowPopupProgressBar &&
                                            <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                                                <CustomProgressBar></CustomProgressBar>
                                            </div>
                                        }
                                        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                                            <Modal.Title style={{ color: "white" }}>Email Invoice</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body text-center" >
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.Invoice.CustomerRef.name || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={4} className="text-left">
                                                        <h5>Invoice No :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>{this.state.Invoice.DocNumber || null}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Balance :</h5>
                                                    </Col>
                                                    <Col md={8} className="text-left">
                                                        <h5>${parseFloat(this.state.Invoice.Balance).toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <h5>Customer Email :</h5>
                                                    </Col>
                                                    <Col md={8} >
                                                        <FormGroup >
                                                            <FormControl
                                                                value={this.state.Invoice.BillEmail.Address || null}
                                                                placeholder="Email"
                                                                type="email"
                                                                autoComplete="off"
                                                                className={this.state.sendInvoiceClassName}
                                                                onChange={event => this.validateEmail(event)}
                                                            />
                                                            {this.state.errorSendInvoiceEmail}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showSendInvoiceModal: false })}>Close</Button>
                                            <Button bsStyle="primary" fill wd onClick={e => this.handleSendInvoiceEmail(e)}>Send</Button>
                                        </Modal.Footer>
                                        {/* <Col md={4} >
                                                        <Button bsStyle={"primary"} fill onClick={event => { this.handleSendInvoiceEmail(event) }} >
                                                            Send
                                                        </Button>
                                                    </Col> */}

                                    </Modal>
                                </Col>
                            </Row>                          

                        </div>
                    }
                />
            </div>
        )
    }
}

export default Detail;

class ComponentToPrint extends React.Component {
    rawMarkup(value) {
        //const rawMarkup = "<h1>Hello, world! ${value.Balance || null}</h1>";
        return { __html: value };
    };

    render() {
        return (            
            <div>
            { this.props.dataFromParent != null &&
                <div dangerouslySetInnerHTML={this.rawMarkup(this.props.dataFromParent || null)} />
            }
            { this.props.dataFromParent == null &&                
                 <div id="pdf-canvas-detail2" height="0"></div> 
            }            
            </div>
        );
    }
}       