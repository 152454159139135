import React, { Component } from "react";
import {
    Row,
    Col,
    ControlLabel,
    FormGroup,
    Alert,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    Modal,
    FormControl,

} from "react-bootstrap";
import MaterialTable, { MTableToolbar } from 'material-table';
import Select from "react-select";
import Card from "components/Card/Card.jsx";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { StorageEnum, ApplicationIDEnum } from "utils/constant.js";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import Chip from '@material-ui/core/Chip';
import NetworkService from "services/NetworkService";
import Datetime from "react-datetime";

class Plans extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.theme = createMuiTheme({
            palette: {
                primary: {
                    main: "#0075c1",
                },
                secondary: {
                    main: '#0075c1',
                },
            },
            typography: {
                fontSize: 20
            },
        });
        this.state = {
            errorCode: null,
            errorMessage: "",
            ShowProgressBar: false,
            ShowPopupProgressBar: false,
            data: [],
            columns: [
                {
                    title: 'Plan ID	',
                    field: 'planid',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(15%)'
                    },
                    cellStyle: {
                        width: 'calc(15%)'
                    },
                },
                {
                    title: 'Plan',
                    field: 'plan',
                    headerStyle: {
                        textAlign: "left",
                        width: 'calc(30%)'
                    },
                    cellStyle: {
                        width: 'calc(30%)'
                    },
                },
                {
                    title: 'Billing Cycle',
                    field: 'billingcycle',
                    cellStyle: {
                         textAlign: "center",
                        width: 'calc(25%)'
                    },
                },
                {
                    title: 'Amount',
                    field: 'amount',
                    cellStyle: {
                         textAlign: "center",
                    },
                }
            ],
            options: {
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                paging: true,
                toolbar: true,
                //  selection: true,
                showTextRowsSelected: false,
                headerStyle: {
                    backgroundColor: '#0075C1',
                    color: '#ffffff',
                    fontWeight: "bold",
                    zIndex: 4,
                    position: 'sticky',
                    top: 0,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                showTitle: false,
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    // backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#93cefc' : rowData.tableData.id % 2 ? "#f2f2f2" : "#FFF"
                }),
            },
            createProductToolTip: <Tooltip id="createProductToolTip">Create New Plan</Tooltip>,
            components: {
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                        <div style={{ padding: '10px' }}>
                            <OverlayTrigger placement="top" overlay={this.state.createProductToolTip}>
                                <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={e => this.handleCreatePlan(e)} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-plus" ></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                ),
            },
            actions: [
                {
                    icon: 'save',
                    tooltip: 'Save User',
                    onClick: (event, rowData) => alert("You saved " + rowData.name)
                }
            ],
            planInfo: {
                plan_payments: "0",
                plan_name: "",
                plan_amount: "0.00",
                plan_id: "",
                day_frequency: "31",
                month_frequency: "",
                day_of_month: ""
            },
            planAmountState: "",
            planAmountErrorMessage: "",
            planNameState: "",
            planNameErrorMessage: "",
            planIDState: "",
            planIDErrorMessage: "",
            IsDayFrequency: true,
            IsMonthFrequency: false,
            IsChargeUntilCanceled: true,
            IsChargeTheCustomer: false,
            showNewPlanPopupModal: false,
            from_date: new Date(date.getFullYear(), date.getMonth(), 1),
            to_date: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            queryFromDate: "",
            queryToDate: "",
        }

        this.fromDateHandler = this.fromDateHandler.bind(this);
        this.toDateHandler = this.toDateHandler.bind(this);
    };

    componentDidMount() {
        if (localStorage.getItem(StorageEnum.UUID) == null) {
            this.props.history.push('/account/login');
        }
        document.getElementById("loaderDiv").style.display = "none";

        this.SetFromToDates();
        this.filterRecurringPlans();

        if (this.state.errorMessage !== "") {
            setTimeout(() => {
                this.setState({ errorMessage: "" })
            }, 5000);
        }
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    getRecurringPlans() {
        //get request for plans
        this._getRecurringPlanListRequest();
    }

    handleCreatePlan(e) {
        this.state.planInfo.plan_payments = "0";
        this.state.planInfo.plan_name = "";
        this.state.planInfo.plan_amount = "0.00";
        this.state.planInfo.plan_id = "";
        this.state.planInfo.day_frequency = "31";
        this.state.planInfo.month_frequency = "";
        this.state.planInfo.day_of_month = "";
        this.state.planAmountState = "";
        this.state.planAmountErrorMessage = "";
        this.state.planNameState = "";
        this.state.planNameErrorMessage = "";
        this.state.planIDState = "";
        this.state.planIDErrorMessage = "";
        this.state.IsDayFrequency = true;
        this.state.IsMonthFrequency = false;
        this.state.IsChargeUntilCanceled = true;
        this.state.IsChargeTheCustomer = false;
        this.state.showNewPlanPopupModal = false;
        this.setState({ showNewPlanPopupModal: true });
    }

    handleFrequencyRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "dayfrequency") {
            this.setState({ IsDayFrequency: true });
            this.setState({ IsMonthFrequency: false });
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    day_frequency: 31
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    day_of_month: ""
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    month_frequency: ""
                }
            }));
        }
        else {
            this.setState({ IsMonthFrequency: true });
            this.setState({ IsDayFrequency: false });
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    day_of_month: "1"
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    month_frequency: "1"
                }
            }));
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    day_frequency: ""
                }
            }));
        }
    };

    handleChargeTimeRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "chargeuntilcanceled") {
            this.setState({ IsChargeUntilCanceled: true });
            this.setState({ IsChargeTheCustomer: false });
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    plan_payments: "0"
                }
            }));
        }
        else {
            this.setState({ IsChargeTheCustomer: true });
            this.setState({ IsChargeUntilCanceled: false });
            this.setState(prevState => ({
                ...prevState,
                planInfo: {
                    ...prevState.planInfo,
                    plan_payments: "1"
                }
            }));
        }
    }

    handleInvoiceDetailClick(invoice) {
        localStorage.setItem("Invoice", JSON.stringify(invoice));
        this.props.history.push('/invoice/detail/' + invoice.Id);
    }

    SetFromToDates() {
        this.state.queryFromDate = this.formatDate(this.state.from_date);
        this.state.queryToDate = this.formatDate(this.state.to_date);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    fromDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ from_date: date.format("MM/DD/YYYY") });
            this.setState({ queryFromDate: date.format("YYYY-MM-DD") });
        }
    };

    toDateHandler = (date) => {
        if (date._isValid) {
            this.setState({ to_date: date.format("MM/DD/YYYY") });
            this.setState({ queryToDate: date.format("YYYY-MM-DD") });
        }
    };

    handleSearchSubmit() {
        this.setState({ ShowProgressBar: true });
        this.setState({ errorMessage: "" });
        this.filterRecurringPlans();
    }

    validateNewPlan() {
        let isValid = true;
        if (parseFloat(this.state.planInfo.plan_amount).toFixed(2) > 0) {
            this.state.planAmountState = "valid";
            this.state.planAmountErrorMessage = "";
        }
        else {
            this.setState({ planAmountState: "error" });
            this.setState({
                planAmountErrorMessage: <small className="text-danger">
                    Invalid amount.
            </small>
            });
            isValid = false;
        }
        if (this.state.planNameErrorMessage === "") {
            if (this.state.planNameState === "" || this.state.planNameState === "error") {
                this.setState({
                    planNameErrorMessage: (
                        <small className="text-danger">
                            Name is Required.
                        </small>
                    )
                });
                isValid = false;
            }
            else {
                this.setState({
                    planNameState: "valid",
                    planNameErrorMessage: ""
                });
            }
        }

        if (this.state.planIDErrorMessage === "") {
            if (this.state.planIDState === "" || this.state.planIDState === "error") {
                this.setState({
                    planIDErrorMessage: (
                        <small className="text-danger">
                            Plan ID is Required.
                        </small>
                    )
                });
                isValid = false;
            }
            else {
                this.setState({
                    planIDState: "valid",
                    planIDErrorMessage: ""
                });
            }
        }

        if (this.state.planIDState == "valid" && this.state.planNameState == "valid" && this.state.planAmountState == "valid") {
            isValid = true;
        }
        else {
            isValid = false;
        }
        return isValid;
    }

    createPlanSubmit = (plan) => {
        if (this.validateNewPlan()) {
            const payload = {
                "plan_payments": plan.plan_payments,
                "plan_name": plan.plan_name,
                "plan_amount": plan.plan_amount,
                "plan_id": plan.plan_id,
                "day_frequency": plan.day_frequency,
                "month_frequency": plan.month_frequency,
                "day_of_month": plan.day_of_month
            }
            console.log(payload);
            this._postCreatePlanRequest(payload);
        }
        else {
            console.log("123 12");
        }
    }

    filterRecurringPlans = () =>{
        const params = {
            "from_date": this.state.queryFromDate,
            "to_date": this.state.queryToDate,
        }
        let esc = encodeURIComponent;
        let query = Object.keys(params)
            .map(function (k) { if (params[k] != null) { return esc(k) + '=' + esc(params[k]) }; })
            .join('&');

        this._getRecurringPlanListRequest(query);
    }

    /** API CALLING */
    _postCreatePlanRequest(body) {
        this.setState({ ShowPopupProgressBar: true });
        this.setState({ errorMessage: "" });

        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);

        NetworkService.postCreateRecurringPlanRequest(app_id, body)
            .then(data => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ showNewPlanPopupModal: false });

                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    this.setState({ errorMessage: data.statusmessage });
                    if (data.statuscode == 0) {
                        this.getRecurringPlans();
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowPopupProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });

        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }


    onSetResult = (result) => {
        const newSeries = [];

        result.items.map((invoice, index) => {
            const data = {
                index: index,
                id: invoice.Id,
                invoicenumber: invoice.DocNumber,
                balance: '$' + parseFloat(invoice.Balance).toFixed(2),
                total: '$' + parseFloat(invoice.TotalAmt).toFixed(2),
                invoicestatus: invoice.IsPaid ? <Chip style={{ backgroundColor: "#00e396", color: "white", height: 25 }} label="Paid"></Chip> : parseFloat(invoice.Balance).toFixed(2) < parseFloat(invoice.TotalAmt).toFixed(2) ? <Chip style={{ backgroundColor: "#feb019", color: "white", height: 25 }} label="Partially Paid"></Chip> : <Chip style={{ backgroundColor: "red", color: "white", height: 25 }} label="Unpaid"></Chip>,
                action: <OverlayTrigger placement="top" overlay={<Tooltip id="createInvoiceToolTip">View Invoice Detail</Tooltip>}>
                    <Button className="btn btn-primary btn-fill btn-round btn-icon" onClick={this.handleInvoiceDetailClick.bind(this, invoice)}> <i className="fa fa-clipboard" ></i> </Button>
                </OverlayTrigger>
            }
            newSeries.push(data);

        });
        this.setState({ data: newSeries });
    }

    _getRecurringPlanListRequest(params = "") {
        this.setState({ ShowProgressBar: true });
        const app_id = ApplicationIDEnum.NMIInvoicing; //localStorage.getItem(StorageEnum.APPID);
        NetworkService.getRecurringPlanListRequest(app_id, params)
            .then(data => {
                this.setState({ ShowProgressBar: false });
                if (data != null) {
                    this.setState({ errorCode: data.statuscode });
                    if (data.statuscode == 0) {
                        if (data.data != null) {
                            this.setPlanListingResult(data.data.items);
                        }
                    }
                } else {
                }
            }).catch(error => {
                this.setState({ ShowProgressBar: false });
                this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
            });
        setTimeout(() => {
            this.setState({ errorMessage: "" })
        }, 5000);
    }

    setPlanListingResult = (result) => {
        const newSeries = [];

        result.map((data, index) => {
            try {
                const plan = {
                    "index": index,
                    "plan": data.Name,
                    "planid": data.Id,
                    "billingcycle": data.DayFrequency != null ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={`Runs every ${data.DayFrequency} day(s)`}></Chip> : data.MonthFrequency != null ? <Chip style={{ backgroundColor: "#008FFB", color: "white", height: 25 }} label={`Runs every ${data.MonthFrequency} month(s)`}></Chip> : "",
                    "amount": data.Amount
                }
                newSeries.push(plan);
            }
            catch (why) {
                console.log(why)
            }
        });

        this.setState({ data: newSeries });
    }

    render() {
        const customButtonStyle = {
            minWidth: "100px",
            marginTop: "20px"
        };
        return (
            <div className="ii-main-content">
                <Card
                    content={
                        <div>
                            {this.state.errorMessage &&
                                <div>
                                    <Alert bsStyle={this.state.errorCode == "0" ? "success" : "danger"}>
                                        <span>
                                            {this.state.errorMessage}
                                        </span>
                                    </Alert>
                                </div>
                            }
                            <Row>
                                <Col md={3}>
                                    <span>From Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="from_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                className="react-calendar"
                                                inputProps={{ placeholder: "From Date" }}
                                                onChange={this.fromDateHandler}
                                                value={this.state.from_date}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <span>To Date</span>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Addon><i className="fa fa-calendar" /></InputGroup.Addon>
                                            <Datetime
                                                id="to_date"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: "To Date" }}
                                                onChange={this.toDateHandler}
                                                value={this.state.to_date}
                                            />
                                            <InputGroup.Addon></InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleSearchSubmit.bind(this)}>
                                        Search  <i className="fa fa-search"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>

                                    <MuiThemeProvider theme={this.theme}>
                                        {this.state.ShowProgressBar &&
                                            <div>
                                                <CustomProgressBar></CustomProgressBar>
                                                <br />
                                            </div>

                                        }
                                        <MaterialTable
                                            //title="Styling with MuiThemeProvider Preview"
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            options={this.state.options}
                                            onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                                            onSelectionChange={(rows) => {
                                                console.log('You selected ' + rows.length + ' rows');
                                            }
                                            }
                                            components={this.state.components}
                                        // actions={this.state.actions}

                                        />
                                    </MuiThemeProvider>
                                </Col>
                            </Row>

                        </div>
                    }
                />
                <Modal
                    show={this.state.showNewPlanPopupModal}
                    onHide={() => this.setState({ showNewPlanPopupModal: false })}
                    //bsSize="medium"       
                    dialogClassName="modal-dialog-custom2"
                >
                    {this.state.ShowPopupProgressBar &&
                        <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                            <CustomProgressBar></CustomProgressBar>
                        </div>
                    }
                    <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 117, 193, 0.8)" }}>
                        <Modal.Title style={{ color: "white" }}>Plan Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "16px", fontWeight: "bold", lineHeight: "2em" }}>
                                Enter the amount you want to charge the customer each time.
                            </div>
                            <Row>
                                <Col md={4} style={{ marginLeft: "20px" }}>
                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Amount to charge each time: <span className="star">*</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <FormGroup style={{ marginBottom: "0px" }}>
                                        <FormControl
                                            value={this.state.planInfo.plan_amount}
                                            type="number"
                                            placeholder="Amount"
                                            name="plan_amount"
                                            min="0.00"
                                            style={{ textAlign: "center" }}
                                            step="0.01"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    planInfo: {
                                                        ...prevState.planInfo,
                                                        plan_amount: value
                                                    }
                                                }));
                                            }
                                            }
                                            onBlur={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    planInfo: {
                                                        ...prevState.planInfo,
                                                        plan_amount: parseFloat(value).toFixed(2)
                                                    }
                                                }));

                                            }}
                                        />
                                        {this.state.planAmountErrorMessage}
                                    </FormGroup>
                                </Col>

                            </Row>
                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "16px", fontWeight: "bold", lineHeight: "2em" }}>
                                Plan Identification
                            </div>
                            <Row>
                                <Col md={4} style={{ marginLeft: "20px" }}>
                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Plan ID: <span className="star">*</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <FormControl
                                            value={this.state.planInfo.plan_id}
                                            type="text"
                                            placeholder="Plan ID"
                                            name="plan_id"
                                            onChange={event => {
                                                var value = event.target.value;

                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    planInfo: {
                                                        ...prevState.planInfo,
                                                        plan_id: value
                                                    }
                                                }));

                                                if (this.verifyLength(value, 1)) {
                                                    this.setState({
                                                        planIDState: "valid",
                                                        planIDErrorMessage: ""
                                                    });
                                                    this.state.planIDState = "valid"
                                                }
                                                else {
                                                    this.setState({
                                                        planIDErrorMessage: (
                                                            <small className="text-danger">
                                                                Plan ID is required.
                                                            </small>
                                                        )
                                                    });
                                                }
                                            }
                                            }
                                        />
                                        {this.state.planIDErrorMessage}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} style={{ marginLeft: "20px" }}>
                                    <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "2em" }}>
                                        Plan Name/Description: <span className="star">*</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <FormGroup style={{ marginBottom: "0px" }}>
                                        <FormControl
                                            value={this.state.planInfo.plan_name}
                                            type="text"
                                            placeholder="Plan Name"
                                            name="plan_name"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    planInfo: {
                                                        ...prevState.planInfo,
                                                        plan_name: value
                                                    }
                                                }));
                                                if (this.verifyLength(value, 1)) {
                                                    this.setState({
                                                        planNameState: "valid",
                                                        planNameErrorMessage: ""
                                                    });
                                                    this.state.planNameState = "valid";
                                                }
                                                else {
                                                    this.setState({
                                                        planNameErrorMessage: (
                                                            <small className="text-danger">
                                                                Plan Name is required.
                                                            </small>
                                                        )
                                                    });
                                                }
                                            }
                                            }
                                        />
                                        {this.state.planNameErrorMessage}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "16px", fontWeight: "bold", lineHeight: "2em" }}>
                                Set how often you want to charge the customer
                            </div>
                            <Row>
                                <Col md={4} style={{ marginLeft: "20px" }}>
                                    <Radio
                                        number="dayfrequency"
                                        option="dayfrequency"
                                        name="FrequencyRadio"
                                        onChange={this.handleFrequencyRadio}
                                        checked={this.state.IsDayFrequency}
                                        className="PrivateSwitchBase-input-538"
                                        label="Charge the customer every"
                                    />
                                </Col>
                                <Col md={4}>
                                    <InputGroup>
                                        <FormControl
                                            value={this.state.planInfo.day_frequency}
                                            type="number"
                                            disabled={!this.state.IsDayFrequency}
                                            // placeholder="Days"
                                            name="day_frequency"
                                            style={{ textAlign: "center" }}
                                            min="1"
                                            step="1"
                                            max="31"
                                            onChange={event => {
                                                var value = event.target.value;

                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    planInfo: {
                                                        ...prevState.planInfo,
                                                        day_frequency: value
                                                    }
                                                }));
                                            }
                                            }
                                        />
                                        <InputGroup.Addon>Days</InputGroup.Addon>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "13px" }}>
                                <Col md={4} style={{ marginLeft: "20px" }}>
                                    <Radio
                                        number="monthfrequency"
                                        option="monthfrequency"
                                        name="FrequencyRadio"
                                        onChange={this.handleFrequencyRadio}
                                        checked={this.state.IsMonthFrequency}
                                        label="Charge the customer on day"
                                    />
                                </Col>
                                <Col md={3}>
                                    <Row>
                                        <Col md={7}>
                                            <FormControl
                                                value={this.state.planInfo.day_of_month}
                                                type="number"
                                                disabled={!this.state.IsMonthFrequency}
                                                // placeholder="Days"
                                                name="day_of_month"
                                                style={{ textAlign: "center" }}
                                                min="1"
                                                step="1"
                                                max="31"
                                                onChange={event => {
                                                    var value = event.target.value;

                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        planInfo: {
                                                            ...prevState.planInfo,
                                                            day_of_month: value
                                                        }
                                                    }));
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <span style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "3em" }}>
                                                of every
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={3}>
                                    <Row>
                                        <Col md={7}>
                                            <FormControl
                                                value={this.state.planInfo.month_frequency}
                                                type="number"
                                                disabled={!this.state.IsMonthFrequency}
                                                // placeholder="Days"
                                                name="month_frequency"
                                                style={{ textAlign: "center" }}
                                                min="1"
                                                step="1"
                                                max="24"
                                                onChange={event => {
                                                    var value = event.target.value;
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        planInfo: {
                                                            ...prevState.planInfo,
                                                            month_frequency: value
                                                        }
                                                    }));
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <span style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", lineHeight: "3em" }}>
                                                month(s)
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", fontSize: "16px", fontWeight: "bold", lineHeight: "2em" }}>
                                Set how many times you wish to charge the customer?
                            </div>
                            <Row>
                                <Col md={4} style={{ marginLeft: "20px" }}>
                                    <Radio
                                        number="chargeuntilcanceled"
                                        option="chargeuntilcanceled"
                                        name="ChargeTimeRadio"
                                        onChange={this.handleChargeTimeRadio}
                                        checked={this.state.IsChargeUntilCanceled}
                                        className="PrivateSwitchBase-input-538"
                                        label="Charge until canceled"
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4} style={{ marginLeft: "20px" }}>
                                    <Radio
                                        number="chargethecustomer"
                                        option="chargethecustomer"
                                        name="ChargeTimeRadio"
                                        onChange={this.handleChargeTimeRadio}
                                        checked={this.state.IsChargeTheCustomer}
                                        className="PrivateSwitchBase-input-538"
                                        label="Charge the customer"
                                    />
                                </Col>
                                <Col md={4}>
                                    <InputGroup>
                                        <FormControl
                                            value={this.state.planInfo.plan_payments}
                                            type="number"
                                            disabled={!this.state.IsChargeTheCustomer}
                                            // placeholder="Days"
                                            name="plan_payments"
                                            style={{ textAlign: "center" }}
                                            min="1"
                                            step="1"
                                            onChange={event => {
                                                var value = event.target.value;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    planInfo: {
                                                        ...prevState.planInfo,
                                                        plan_payments: value
                                                    }
                                                }));
                                            }
                                            }
                                        />
                                        <InputGroup.Addon>Times</InputGroup.Addon>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" style={{ marginRight: 20 }} wd onClick={() => this.setState({ showNewPlanPopupModal: false })}>Close</Button>
                        <Button bsStyle="primary" fill wd onClick={e => this.createPlanSubmit(this.state.planInfo)} >Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Plans;








