/*!

=========================================================
* Instant Invoice with PRO React - v1.2.0
=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Settings from "views/Settings.jsx";
import Login from "views/Account/Login.jsx";
import AdminLogin from "views/Account/AdminLogin.jsx";
import ResetPassword from "views/Account/ResetPassword.jsx";
import Home from "views/Home.jsx";
import RegisterPage from "views/Account/RegisterPage.jsx";
import ResellerRegistration from "views/Account/ResellerRegistration.jsx";
import CreateStandaloneInvoice from "views/Standalone/CreateInvoice.jsx";
import CreateStandaloneSalesReceipt from "views/Standalone/CreateSalesReceipt.jsx";
import Payment from "views/CustomerPortal/Payment.jsx"
import CCPayment from "views/CustomerPortal/CCPayment.jsx";
import CustomerLogin from "views/CustomerPortal/Login.jsx";

var routes = [  
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: CustomerLogin,
    layout: "/customer"    
  },
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: Login,
    layout: "/account"    
  },
  {
    path: "/adminlogin",
    name: "AdminLogin",    
    component: AdminLogin,
    layout: "/account"    
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    mini: "L",
    component: ResetPassword,
    layout: "/account"    
  },      
  {
    path: "/home",
    name: "Home",
    mini: "L",
    component: Home,
    layout: "/account"    
  },  
  {
    path: "/settings",
    name: "Settings",
    mini: "L",
    component: Settings,
    layout: "/account"    
  },  
  {
    path: "/registration",    
    name: "Register",
    mini: "RP",
    component: RegisterPage,
    layout: "/account"
  },
  {
    path: "/resellerregistration",    
    name: "Register",
    mini: "RP",
    component: ResellerRegistration,
    layout: "/account"
  },
  
  // {
  //   path: "/create",    
  //   name: "Create",
  //   mini: "RP",
  //   component: CreateInvoice,
  //   layout: "/invoice"
  // },
  // {
  //   path: "/salesreceipt",    
  //   name: "Create",
  //   mini: "RP",
  //   component: CreateSalesReceipt,
  //   layout: "/create"
  // },
  {
    path: "/invoice",    
    name: "Create",
    mini: "RP",
    component: CreateStandaloneInvoice,
    layout: "/standalonecreate"
  },
  {
    path: "/salesreceipt",    
    name: "Create",
    mini: "RP",
    component: CreateStandaloneSalesReceipt,
    layout: "/standalonecreate"
  },
  {
    path: "/payment",    
    name: "payment",
    component: Payment,
    layout: "/customer"    
  },
  {
    path: "/ccpayment",    
    name: "ccpayment",
    component: CCPayment,
    layout: "/customer"    
  }      
];
export default routes;
