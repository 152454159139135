import React from 'react';
import Button from "components/CustomButton/CustomButton.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import creditCardIcon from "assets/img/icons/creditcard.png";
import achIcon from "assets/img/icons/ach.png";
import Radio from "components/CustomRadio/CustomRadio.jsx";
import CustomCheckbox from "components/CustomCheckBoxNew/CustomCheckBoxNew.jsx";
import {
    Row,
    Col,

} from "react-bootstrap";

const styles = {   
    dropDownFontStyle:{
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: "#AAAAAA",
    fontSize: "13px",
    marginTop: "2px",
    marginBottom: "-2px",
   // fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "1.42857"
  }
} 

class NMICollectJSInlinePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Customer: {
                CardHolderName: '',
                CardNumber: '',
                CardExpiry: '',
                GatewayExpiryDate: '',
                CardCVV: '',
                AccountNumber: '',
                AccountType: 'checking',
                RoutingNumber: '',
                AccountName: '',
                Address: '',
                Country: '',
                City: '',
                State: '',
                PostalCode: '',
                ProfileId: '',
                IsCreateProfile: false,
                FormValid: false,
                CardHolderNameValid: true,
                AccountNameValid: true,
                AccountNumberValid: true,
                RoutingNumberValid: true,
                CardNumberValid: true,
                CardExpiryValid: true,
                CardCVVValid: true,
                AmountValid: true,
                CheckBoxChecked: false,
                FormErrors: { CardHolderName: '', AccountName: '' },
            },
            // firstName: '',
            // lastName: '',
            // amount: '',
            isSubmitting: false,
            invoiceData: null,
            paymentIcon: creditCardIcon,
            IsCreditCard: true,
            IsACH: false,
           // alertMessage: '',
        };
        this.setState = this.setState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.finishSubmit = this.finishSubmit.bind(this);
    }

    handleRadio = event => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
        if (target.value === "creditcard") {
            this.setState({ IsCreditCard: true });
            this.setState({ IsACH: false });
            this.setState({ paymentIcon: creditCardIcon });
        }
        else {
            this.setState({ IsACH: true });
            this.setState({ IsCreditCard: false });
            this.setState({ paymentIcon: achIcon });
        }
        this.configureCollectJS();
        this.state.Customer.CheckBoxChecked = false;
        this.state.Customer.FormValid = false; 
    };

    handleUserInput(e) {
        console.log('handleUserInput is calling..' + e.target.name);
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'CardHolderName':
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardHolderName: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CardNumber":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardNumber: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CardExpiry":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardExpiry: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CardCVV":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, CardCVV: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "Address":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, Address: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "Country":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, Country: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "City":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, City: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "State":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, State: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "PostalCode":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, PostalCode: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "Amount":
                this.setState(prevState => ({
                    Amount:
                        {
                            ...prevState.Amount, Amount: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "CheckBox":
                this.validateField(name, e.target.checked)
                break;
            case "AccountName":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, AccountName: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountNumber":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, AccountNumber: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "RoutingNumber":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, RoutingNumber: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            case "AccountType":
                this.setState(prevState => ({
                    Customer:
                        {
                            ...prevState.Customer, AccountType: value
                        }
                }), () => { this.validateField(name, value) })
                break;
            default:
                break;
        }

    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.Customer.FormErrors;
        let cardHolderNameValid = this.state.Customer.CardHolderNameValid;
        let cardNumberValid = this.state.Customer.CardNumberValid;
        let cardExpiryValid = this.state.Customer.CardExpiryValid;
        let cardCVVValid = this.state.Customer.CardCVVValid;
        let checkBoxChecked = this.state.Customer.CheckBoxChecked;

        let accountNumberValid = this.state.Customer.AccountNumberValid;
        let accountNameValid = this.state.Customer.AccountNameValid;
        let routingNumberValid = this.state.Customer.RoutingNumberValid;
        let amountValid = this.state.Customer.AmountValid;
        switch (fieldName) {
            case 'CardHolderName':
                cardHolderNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.CardHolderName = cardHolderNameValid ? '' : ' is invalid';
                this.state.Customer.CardHolderNameValid = cardHolderNameValid ? true : false;
                break;
            case 'CardNumber':
                cardNumberValid = global.isCardNumberValid;
                this.state.Customer.CardNumberValid = cardNumberValid ? true : false;
                break;
            case 'CardExpiry':
                cardExpiryValid = global.isCardExpiryValid;
                this.state.Customer.CardExpiryValid = cardExpiryValid ? true : false;
                break;
            case 'CardCVV':
                cardCVVValid = global.isCardCVVValid;
                this.state.Customer.CardCVVValid = cardCVVValid ? true : false;
                break;
            case 'CheckBox':
                checkBoxChecked = value;
                this.state.Customer.CheckBoxChecked = value;
                break;
            case 'Amount':
                amountValid = value.match(/^\d+(\.\d{1,9})?$/);
                this.state.Customer.AmountValid = amountValid ? true : false;
                this.state.Amount = value;
                break;
            case 'AccountName':
                accountNameValid = value.match(/^[a-zA-Z ]{2,30}$/); //add RE for name here..
                fieldValidationErrors.AccountName = accountNameValid ? '' : ' is invalid';
                this.state.Customer.AccountNameValid = accountNameValid ? true : false;
                break;
            case 'AccountNumber':
                accountNumberValid = value.match(/^\d{4,17}$/);
                this.state.Customer.AccountNumberValid = accountNumberValid ? true : false;
                break;
            case 'RoutingNumber':
                routingNumberValid = value.match(/^\d{9}$/);
                this.state.Customer.RoutingNumberValid = routingNumberValid ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            CardHolderNameValid: cardHolderNameValid,
            CardNumberValid: cardNumberValid,
            CardExpiryValid: cardExpiryValid,
            CardCVVValid: cardCVVValid,
            CheckBoxChecked: checkBoxChecked,
            AccountNameValid: accountNameValid,
            AccountNumberValid: accountNumberValid,
            RoutingNumberValid: routingNumberValid,
            AmountValid: amountValid
        }, this.validateForm);

    }

    validateForm() {
        //console.log(this.state.Customer.CardNumberValid);
        if (this.state.IsCreditCard) {
            this.setState(prevState => ({
                Customer:
                    {
                        ...prevState.Customer, FormValid: this.state.IsCreditCard && this.state.Customer.CardCVVValid && this.state.Customer.CardExpiryValid
                            && this.state.Customer.CardHolderNameValid && this.state.Customer.CardNumberValid && this.state.Customer.AmountValid &&
                            this.state.Customer.CheckBoxChecked
                    }
            }), () => console.log())
        }
        else if (this.state.IsACH) {
            this.setState(prevState => ({
                Customer:
                    {
                        ...prevState.Customer, FormValid: this.state.Customer.AccountNameValid && this.state.Customer.AccountNumberValid
                            && this.state.Customer.AmountValid && this.state.Customer.RoutingNumberValid
                    }
            }), () => console.log())
        }
    }

    handleCancelPayment(e) {
        var bu = "http://localhost:3000/";
        //BASE_URL
        window.location.href = bu + "#/invoice/invoices";
        e.preventDefault();
    };

    componentDidMount() {
        this.configureCollectJS();
        // this.state.invoiceData = this.props.location.state ? this.props.location.state.invoiceData : null;
        // console.log(this.state.invoiceData);
    }

    configureCollectJS(){
        window.CollectJS.configure({
            variant: 'inline',
            styleSniffer: true,
            callback: (token) => {                
                // console.log(token);
                this.finishSubmit(token)
            },
            validationCallback : function(field, status, message) {
                if (status) {
                   // console.log(document.getElementById(field).value);
                   console.log(document.querySelector("#ccnumber .CollectJSValid"));
                    var message = field + " is now OK 1 : " + message;
                } else {
                    var message = field + " is now Invalid 1: " + message;
                }
                console.log(message);
            },
            // variant: 'inline',
            // googleFont: 'Abel',
            invalidCss: {
                 'border-bottom-color': 'red',
                 'border-width': '2px'
            },
            // validCss: {
            //   color: '#13AA73'
            // },
            customCss: {
                 'border-top-color': '#ffffff',
                 'border-right-color': '#ffffff',
                 'border-left-color': '#ffffff',
                 'border-bottom-color': '#AAAAAA',
                 'border-style': 'solid',
                 'border-width': '1px',
                 'font-family': 'sans-serif',
                 'font-size': '18px'
            },
            placeholderCss: {
                        "color": "#AAAAAA",
                        "font-weight": "bold",
                        "font-size": "16px"
                    },
            // placeholderCss:{
            //     'font-family': 'sans-serif',
            //     'font-size':'18px',
            //     'ont-weight':'bold'
            // },
            focusCss: {
                'border-top-color': '#ffffff',
                 'border-right-color': '#ffffff',
                 'border-left-color': '#ffffff',
                'border-bottom-color': '#0075c1',
               // 'border-style': 'solid',
                 'border-width': '2px'
            },
  
            fields: {
                ccnumber: {
                     placeholder: 'Card Number',
                    // title: "Account Number",
                     selector: '#ccnumber',
                      //onChange:(e) => { console.log('a'); }
                 },
                ccexp: {
                    placeholder: 'MM/YYYY',
                    selector: '#ccexp'
                },
                cvv: {
                    placeholder: 'CVV',
                    selector: '#cvv',
                    display: "show"
                },
                checkaccount: {
                    selector: "#accountnumber",
                   // title: "Account Number",
                    placeholder: "Account Number"
                },
                checkaba: {
                    selector: "#routingnumber",
                    //title": "Routing Number",
                    placeholder: "Routing Number"
                },
                checkname: {
                    selector: "#accountname",
                    //title": "Name on Checking Account",
                    placeholder: "Account Name"
                }
            }
        });
    }

    finishSubmit(response) {
        const { isSubmitting, ...formData } = { ...this.state };
        formData.token = response.token;        
        console.log(formData);
        this.setState({ isSubmitting: false });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isSubmitting: true });
        window.CollectJS.startPaymentRequest();
    }

    render() {
        return (
            <div>
                {/* {this.state.alertMessage && (
                    <div className="alert">
                        {this.state.alertMessage}
                    </div>
                )} */}
                <form onSubmit={this.handleSubmit}>
                    {/* <div>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            onChange={event => this.setState({ firstName: event.target.value })}
                            value={this.state.firstName}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            onChange={event => this.setState({ lastName: event.target.value })}
                            value={this.state.lastName}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="amount"
                            placeholder="Amount"
                            onChange={event => this.setState({ amount: event.target.value })}
                            value={this.state.amount}
                        />
                    </div> */}
                    <Row>
                                        <Col md={3}>
                                            <img src={this.state.paymentIcon} width="70px" height="42px" alt="..." />
                                        </Col>
                                        <Col md={2}>
                                        {/* <CustomRadioButton/> */}
                                        </Col>
                                        <Col md={3}>
                                            <Radio
                                                number="creditcard"
                                                option="creditcard"
                                                name="radio"
                                                onChange={this.handleRadio}
                                                checked={this.state.IsCreditCard}
                                                className="PrivateSwitchBase-input-538"
                                                label="Credit Card"
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Radio
                                                number="ach"
                                                option="ach"
                                                name="radio"
                                                onChange={this.handleRadio}
                                                checked={this.state.IsACH}
                                                label="eCheck (ACH)"
                                            />
                                        </Col>                                      
                                    </Row>

                    {/* {this.state.IsCreditCard &&                                         */}
                      <div style={{display:"none"}}>
                        <Row>
                        <Col xs={12} sm={12} md={12}> 
                            <InputLabel
                                style={styles.dropDownFontStyle}
                            >
                                Card Number
                            </InputLabel>
                            <div id="ccnumber" />
                            </Col>
                            </Row>
                            <Row>
                        <Col xs={12} sm={12} md={6}> 
                            <InputLabel
                                style={styles.dropDownFontStyle}
                            >
                                MM/YYYY
                            </InputLabel>
                            <div id="ccexp" />
                            </Col>
                            <Col xs={12} sm={12} md={6}> 
                            <InputLabel
                                htmlFor="simple-select"
                                style={styles.dropDownFontStyle}
                            >
                                CVV
                            </InputLabel>
                            <div id="cvv" />
                            </Col>
                            </Row>
                    </div>
                    {/* } */}
                    {/* {this.state.IsACH && */}
                    <div>
                        <div id="accountname" />
                        <br/>
                        <div id="accountnumber" />
                        <br/>
                        <div id="routingnumber" />
                    </div>
                    {/* } */}
                    <Row>
                                    <Col xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Address"
                                        isRequired = {true}
                                        id="address"
                                        name="Address"
                                        value={this.state.Customer.Address}
                                        formControlProps={{
                                            fullWidth: true,
                                           // disabled: this.state.BalanceDue === "0.00",
                                            onChange:(e) => {this.handleUserInput(e)}
                                        }}
                                        inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                        labelProps={{style: {fontWeight: "bold"}}}
                                        />   
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <CustomInput
                                                    labelText="Country"
                                                    id="country"
                                                    name="Country"
                                                    value={this.state.Customer.Country}
                                                    formControlProps={{
                                                    fullWidth: true,
                                                   // disabled: this.state.BalanceDue === "0.00",
                                                    onChange:(e) => {this.handleUserInput(e)}
                                                    }}
                                                    inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}}
                                                    labelProps={{style: {fontWeight: "bold"}}}
                                                />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                             <CustomInput
                                                labelText="State"
                                                id="state"
                                                name="State"
                                                value={this.state.Customer.State}
                                                formControlProps={{
                                                fullWidth: true,
                                               // disabled: this.state.BalanceDue === "0.00",
                                                onChange:(e) => {this.handleUserInput(e)}
                                                }}
                                                inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                labelProps={{style: {fontWeight: "bold"}}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="City"
                                                id="city"
                                                name="City"
                                                value={this.state.Customer.City}
                                                formControlProps={{
                                                fullWidth: true,
                                                disabled: this.state.BalanceDue === "0.00",// && this.state.StatusCode === 0,
                                                onChange:(e) => {this.handleUserInput(e)}
                                                }}
                                                inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                labelProps={{style: {fontWeight: "bold"}}} 
                                            />
                                            </Col>
                                             <Col xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    labelText="Postal Code"
                                                    id="postal-code"
                                                    name="PostalCode"
                                                    value={this.state.Customer.PostalCode}
                                                    formControlProps={{
                                                    fullWidth: true,
                                                    disabled: this.state.BalanceDue === "0.00",
                                                    onChange:(e) => {this.handleUserInput(e)}
                                                    }}
                                                    inputProps={{style: { fontFamily: "sans-serif", fontSize: "18px"}}} 
                                                    labelProps={{style: {fontWeight: "bold"}}} 
                                                />
                                            </Col>
                                            </Row>
                                            
                                            { this.state.IsCreditCard &&   
                                                <Row>
                                                    <Col xs={12} sm={12} md={12}>
                                                        <CustomCheckbox
                                                            name="CheckBox"
                                                            checked={this.state.Customer.CheckBoxChecked}
                                                            //isDisabled= {this.state.BalanceDue === "0.00"}// && this.state.StatusCode === 0}
                                                            labelText={"I agree to pay ($" + parseFloat(this.state.amountToCharge).toFixed(2)+ ") according to the card issuer agreement."}
                                                            labelProps={{style: {fontSize: "15px"}}} 
                                                            formControlProps={{
                                                            onChange:(e) => {this.handleUserInput(e)}
                                                            }}  
                                                        />
                                            
                                                    </Col>
                                                </Row>
                                            }
                                
                                
                                     <br/>                
                                     <Row>
                                         <Col xs={12} sm={12} md={6}>  
                                              <Button bsStyle="primary" style={{width:"100%"}}  fill type="submit">                                                   
                                                CHARGE 
                                              </Button> 
                                              </Col>
                                              <Col xs={12} sm={12} md={6}>  
                                              <Button style={{width:"100%"}} onClick={e => this.handleCancelPayment(e)}>                                                   
                                                Cancel 
                                          </Button> 
                                         </Col>
                                     </Row>
                </form>
            </div>
        );
    }
}

export default NMICollectJSInlinePage;