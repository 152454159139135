import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert
} from "react-bootstrap";

import Card from "components/Card/LoginCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js"
import { minWidth } from "@material-ui/system";

global.successfullRegistration = false;

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      errorCode: 1,
      ShowProgressBar: false,
      email: "",
      password: "",
      emailClassName: "",
      passwordClassName: "",
      emailErrorLogin: null,
      passwordErrorLogin: null,
    };
  }

  linkClick(e, name) {
    this.setState({ emailClassName: "" });
    this.setState({ passwordClassName: "" });
    this.setState({ emailErrorLogin: null });
    this.setState({ passwordErrorLogin: null });
    e.preventDefault();
  }

  registrationClick(e) {
    this.props.history.push('/account/register-page');
    e.preventDefault();
  }

  componentDidMount() {
    document.getElementById("loaderDiv").style.display = "none";
  }

  handleLoginEmail(event) {
    this.setState({
      email: event.target.value
    });
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(event.target.value) === false) {
      this.setState({
        emailErrorLogin: (
          <small className="text-danger">
            Email is required and format should be <i>john@doe.com</i>.
          </small>
        )
      });
      this.setState({
        emailClassName: "error"
      });
    }
    else {
      this.setState({ emailErrorLogin: null });
      this.setState({ emailClassName: "valid" });
    }
  }

  handleLoginPassword(event) {
    this.setState({
      password: event.target.value
    });
    if (event.target.value.length < 6) {
      this.setState({
        passwordErrorLogin: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
          </small>
        )
      });
      this.setState({ passwordClassName: "error" });
    }
    else {
      this.setState({ passwordErrorLogin: null });
      this.setState({ passwordClassName: "valid" });

    }
  }

  handleLoginSubmit() {
    if (this.isValidated()) {
      this.setState({ ShowProgressBar: true });
      localStorage.clear();
      this.postLogin(this.state);
      // this.props.history.push('/admin/dashboard');   
    }
    else {
      console.log('invalid email or password');
    }
  }

  isValidated() {
    let isValid = true;
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(this.state.email) === false) {
      this.setState({
        emailErrorLogin: (
          <small className="text-danger">
            Email is required and format should be <i>john@doe.com</i>.
          </small>
        )
      });
      isValid = false;
    }
    else {
      this.setState({ emailErrorLogin: null });
    }

    if (this.state.password < 6) {
      this.setState({
        passwordErrorLogin: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
          </small>
        )
      });
      isValid = false;
    }
    else {
      this.setState({ passwordErrorLogin: null });
    }

    return isValid;
  }

  handleKeyPressInput(e) { //= event => {
    var charCode = (e.which) ? e.which : e.keyCode;
    //alert(charCode);
    //97-122, 32,65-90'
    if (charCode === 13 && this.isValidated()) {
      this.handleLoginSubmit();
    }
  };

  /** API'S CALLING */
  // POST request of login
  postLogin = (postData) => {
    this.setState({ errorMessage: "" });
    let url = BASE_URL + "api/v1/account/login/";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "email": postData.email,
        "password": postData.password
      })
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data != null) {
          this.setState({ errorMessage: data.statusmessage });
          this.setState({ errorCode: data.statuscode });
          this.setState({ ShowProgressBar: false });

          if (data.statuscode === 0) {

            if (data.data != null) {              
              let admin_ref = data.data.type

              if (admin_ref.value == 4 && admin_ref.name == "Admin") {
                let uuid = data.data.access_token;
                let full_name = data.data.full_name;

                //  global.SuccessfulLoggedIn = true;
                localStorage.setItem(StorageEnum.UUID, uuid);
                localStorage.setItem(StorageEnum.FULLNAME, full_name);
                localStorage.setItem("login_user_type", "Admin");
                this.props.history.push('/admin/transactions');
              } else {
                this.setState({ errorMessage: "Invalid Admin login credentials" });
              }
            } else {
              this.setState({ errorMessage: data.statusmessage });
            }

          } else {
            // this.setState({ isValidUser: false });
          }
        }
      })
      .catch(error => {
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
        this.setState({ ShowProgressBar: false });
      });;
  }

  render() {
    const customButtonStyle = {
      minWidth: "250px",
      marginBottom: "20px"
    };
    const customLinkStyle = {
      color: "#0075c1",
      fontSize: "16px",
    }
    return (
      <Grid>
        {global.successfullRegistration &&
          <Row>
            <Col md={12}>
              <Alert bsStyle="success">
                <span>
                  Account created successfully, a verification email has been sent to your email address. Please verify and login.
                  </span>
              </Alert>
            </Col>
          </Row>
        }
        <Row>
          <Col md={6} sm={6} mdOffset={3} smOffset={3}>
            <form>
              {this.state.ShowProgressBar &&
                <div>
                  <br /><br />
                  <CustomProgressBar></CustomProgressBar>
                </div>
              }
              {this.state.errorMessage != "" &&
                <div>
                  <br /><br />
                  <Alert bsStyle="danger">
                    <span>
                      {this.state.errorMessage}
                    </span>
                  </Alert>
                </div>
              }

              <Card
                textLeft
                title={"Admin Sign In"}
                category={"Sign in with your Instant Invoice Admin Account "}
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email address <span className="star">*</span></ControlLabel>
                      <FormControl
                        value={this.state.email}
                        placeholder="Enter email"
                        type="email"
                        className={this.state.emailClassName}
                        // onChange={event => this.handleLoginEmail(event)}
                        onChange={event => {
                          this.setState({ email: event.target.value });
                          this.setState({ emailErrorLogin: null });
                          this.setState({ emailClassName: "" });
                        }
                        }
                        onBlur={event => this.handleLoginEmail(event)}
                      />
                      {this.state.emailErrorLogin}
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password  <span className="star">*</span></ControlLabel>
                      <FormControl
                        value={this.state.password}
                        placeholder="Password"
                        type="password" autoComplete="off"
                        className={this.state.passwordClassName}
                        onChange={event => this.handleLoginPassword(event)}
                        onBlur={event => this.handleLoginPassword(event)}
                        onKeyPress={event => this.handleKeyPressInput(event)}
                      />
                      {this.state.passwordErrorLogin}
                    </FormGroup>
                  </div>
                }
                legend={
                  <div>
                    <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleLoginSubmit.bind(this)}>
                      SIGN IN
                    </Button>
                  </div>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default AdminLogin;