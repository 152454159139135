
import defaultImage from "assets/img/instantinvoice/instantinvoicelogo.png";

import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
// core components
import Button from "components/CustomButton/CustomButton.jsx";

import NetworkService from "services/NetworkService";


export default function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.isMerchantLogo ? props.merchantLogo : defaultImage
  );
  let fileInput = React.createRef();
  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    props.parentComponent(e.target.files[0]);
    // _postImageUploadRequest(file);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    // e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    fileInput.current.value = null;
  };


  function _postImageUploadRequest(file){
    
    let formData = new FormData();
    formData.append(
      'file',
      file,
      file.name
    );
    
    const content_type="multipart/form-data";
    const body_type="multipart";
    NetworkService.postMerchantImageRequest(formData)
    .then(data =>{
      console.log(data)
    }).catch(error =>{
      console.log(error)
    })
  }

  let { isMerchantLogo, addButtonProps, changeButtonProps, removeButtonProps, merchantLogo } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <Row>
        <Col md={12}>
        {/* <div className={"thumbnail" + (!isMerchantLogo ? "" : "img-circle")}> */}
          <div className={"thumbnail"}>
            <img src={isMerchantLogo ? merchantLogo : imagePreviewUrl} alt="..." />
          </div>
          <div>
            {file === null ? (
              <Button {...addButtonProps} onClick={() => handleClick()}>
                {"Select logo"}
              </Button>
            ) : (
              <span>
                <Button {...changeButtonProps} onClick={() => handleClick()}>
                  Change
                </Button>
                {isMerchantLogo ? <br /> : null}
                <Button {...removeButtonProps} onClick={() => handleRemove()}>
                   Remove
                </Button>
              </span>
            )}
          </div>
        </Col>                
      </Row>      
    </div>
    
  );
}

ImageUpload.propTypes = {
  isMerchantLogo: PropTypes.bool,
  merchantLogo: PropTypes.object,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
