import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
  Alert
} from "react-bootstrap";

import Card from "components/Card/LoginCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomProgressBar from "components/ProgressBar/CustomProgressBar.jsx";
import { BASE_URL, StorageEnum } from "utils/constant.js"
import NetworkService from "services/NetworkService";
import Common from "utils/Common.js";


global.successfullRegistration = false;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      errorCode: 1,
      ShowProgressBar: false,
      newPassword: "",
      newPasswordState: "",
      newPasswordErrorMessage: "",
      confirmNewPassword: "",
      confirmNewPasswordState: "",
      confirmNewPasswordErrorMessage: "",
      isPasswordFormValid: false,
      userId: "",
      userToken: "",
      showNewPassword: false,
      showConfirmPassword: false,
    };
  }



  // POST request of forget password
  _postForgotPassword = (data) => {
    this.setState({ ShowProgressBar: true });
    this.setState({ errorMessage: "" });
  }

  componentDidMount() {
    document.getElementById("loaderDiv").style.display = "none";
    localStorage.clear();
    
    const {uid, token} = this.extractTokenFromUrl();
    this.state.userId = uid;
    this.state.userToken = token;

  }

  extractTokenFromUrl = () => {
    const url = window.location.hash;
    const path = url.split("/");
    const uid = (path[3] || "");
    const token = (path[4] || "");
    return {uid, token};
};

  handleResetPasswordSubmit() {
    if (this.ValidatePasswords()) {
      this.setState({ ShowProgressBar: true });
      this._postResetPasswordRequest(this.state);
    }
  }


  handleKeyPressInput(e) {
    var charCode = (e.which) ? e.which : e.keyCode;
    if (charCode === 13 && this.ValidatePasswords()) {
      this.handleResetPasswordSubmit();
    }
  };

  ValidatePasswords() {
    this.setState({isPasswordFormValid: true});
    var passwordRE = new RegExp(Common.getPasswordRegex());    
    if (passwordRE.test(this.state.newPassword) === false) {            
        this.setState({newPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
    }
    else {
        this.setState({ newPasswordErrorMessage: null});            
    }

    if (passwordRE.test(this.state.confirmNewPassword) === false) {            
        this.setState({confirmNewPasswordErrorMessage: Common.getPasswordErrorMessage(), isPasswordFormValid: false});
    }
    else {
        this.setState({ confirmNewPasswordErrorMessage: null});            
    }

    if (this.state.isPasswordFormValid && this.state.newPassword != this.state.confirmNewPassword) {
        this.setState({
            confirmNewPasswordErrorMessage: (
                <small className="text-danger">
                    New Password and Confirm Password do not match.
                </small>
            )
        });
        this.state.isPasswordFormValid = false;
    }       
        
    return this.state.isPasswordFormValid;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }


  handleClickShowNewPassword = () => {
    this.setState({showNewPassword: !this.state.showNewPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({showConfirmPassword: !this.state.showConfirmPassword });
  };

  /**
   * 
   * @param {*} postData 
   */
  _postResetPasswordRequest = (postData) => {
    this.setState({ errorMessage: "" });
    const password_info = {
      password: this.state.newPassword,
      confirm_password: this.state.confirmNewPassword,
      uid: this.state.userId,
      token: this.state.userToken
    };

    NetworkService.postForgetPasswordRequest(password_info)
      .then(data => {
        if (data != null) {
          this.setState({ ShowProgressBar: false });
          this.setState({ errorMessage: data.statusmessage });
          this.setState({errorCode:  data.statuscode });
          if (data.statuscode === 0) {
            this.setState({ errorMessage: "Your password has been reset successfully." });
            setTimeout(() => {
              window.location.href = BASE_URL + "#/account/login";
            }, 5000);
          }
        }
      })
      .catch(error => {
        this.setState({ ShowProgressBar: false });
        this.setState({ errorMessage: "Oops! Something went wrong, please try again." });
      });
  }


  render() {
    const customButtonStyle = {
      minWidth: "250px",
      marginBottom: "20px"
    };
    const customLinkStyle = {
      color: "#0075c1",
      fontSize: "16px",
    }
    return (
      <Grid>
        <Row>
          <Col md={6} sm={6} mdOffset={3} smOffset={3}>
            <form>
              {this.state.ShowProgressBar &&
                <div>
                  <br /><br />
                  <CustomProgressBar></CustomProgressBar>
                </div>
              }
              {this.state.errorMessage != "" &&
                <div>
                  <br /><br />
                  <Alert bsStyle={this.state.errorCode == "0" || this.state.errorCode == 0 ? "success" : "danger"}>
                    <span>
                      {this.state.errorMessage}
                    </span>
                  </Alert>
                </div>
              }
              <Card
                textLeft
                title={"Reset Password"}
                // category={this.state.IsForgotPassword === false ? "Sign in with your Instant Invoice Account " : "Enter email to recover your password"}
                content={
                  <div>
                    <div>
                      <FormGroup>                        
                          <ControlLabel>New Password  <span className="star">*</span></ControlLabel>
                          <InputGroup>
                            <FormControl
                              value={this.state.newPassword}
                              placeholder="New Password"
                              autoComplete="off"
                              name="newPassword"
                              type={this.state.showNewPassword ? "text" : "password"}
                              className={this.state.newPasswordState}
                              onChange={event => {
                                this.setState({newPassword: event.target.value});
                                if(this.verifyLength(event.target.value, 6))
                                {
                                  this.state.newPasswordState = "valid";
                                  this.state.newPasswordErrorMessage = null;
                                }
                                else{
                                  this.state.newPasswordState = "error";
                                }
                              }
                              }
                            />
                            <InputGroup.Addon><i className={this.state.showNewPassword ? "fa fa-eye-slash fa-lg" : "fa fa-eye fa-lg"}  style={{marginLleft: "-30px", cursor:"pointer"}} onClick={this.handleClickShowNewPassword} /> </InputGroup.Addon>                       
                        </InputGroup>
                        {this.state.newPasswordErrorMessage}
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>Confirme Password  <span className="star">*</span></ControlLabel>
                          <InputGroup>
                              <FormControl
                                value={this.state.confirmNewPassword}
                                placeholder="Confirme Password"
                                autoComplete="off"
                                name="confirmNewPassword"
                                type={this.state.showConfirmPassword ? "text" : "password"}
                                className={this.state.confirmNewPasswordState}
                                onChange={event => {
                                  this.setState({confirmNewPassword: event.target.value});
                                  if(this.verifyLength(event.target.value, 4))
                                  {
                                    this.state.confirmNewPasswordState = "valid";
                                    this.state.confirmNewPasswordErrorMessage = null;
                                  }
                                  else{
                                    this.state.confirmNewPasswordState = "error";
                                  }
                                }
                                }
                              />                       
                            <InputGroup.Addon><i className={this.state.showConfirmPassword ? "fa fa-eye-slash fa-lg" : "fa fa-eye fa-lg"}  style={{marginLleft: "-30px", cursor:"pointer"}} onClick={this.handleClickShowConfirmPassword} /> </InputGroup.Addon>                       
                          </InputGroup>
                         {this.state.confirmNewPasswordErrorMessage}
                      </FormGroup>
                    </div>
                  </div>
                }
                legend={
                  <div>
                    <div>
                      <Button bsStyle="primary" fill style={customButtonStyle} onClick={this.handleResetPasswordSubmit.bind(this)}>
                        SUBMIT
                    </Button>
                      <br />
                    </div>
                  </div>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ResetPassword;