/*!

=========================================================

* Product Page: https://www.instantinvoice.com
* Copyright 2020 Instant Invoice (https://www.instantinvoice.com)

* Coded by Instant Invoice

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Dashboard from "views/POS/Dashboard.jsx";
import Transactions from "views/POS/Transactions.jsx";
import Download from "views/POS/Download.jsx";

var QBPOSRoutes = [
    {
      path: "/dashboard",
      layout: "/qbpos",
      name: "Dashboard",
      icon: "fa fa-dashboard",
      component: Dashboard
    },
    {
      path: "/transactions",
      layout: "/qbpos",
      name: "Transactions",
      icon: "fa fa-money",
      component: Transactions      
    },
    {
      path: "/download",
      layout: "/qbpos",
      name: "Download",
      icon: "fa fa-download",
      component: Download      
    }
];

export default QBPOSRoutes;    